import { AdminSerializedExercise } from '../../../serializers/admin/AdminSerializedExercise';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from '../cms';

const exercisesCmsParams = {
  basePath: '/admin/exercises',
  auth: true,
};

export const useAdminIndexExercisesQuery = generateUseIndexQuery<
  AdminSerializedExercise,
  AdminCmsIndexFilterParams<AdminSerializedExercise>
>(exercisesCmsParams);
export const useAdminShowExerciseQuery = generateUseShowQuery<AdminSerializedExercise>(
  exercisesCmsParams
);
export const useAdminCreateExerciseMutation = generateUseCreateMutation<AdminSerializedExercise>(
  exercisesCmsParams
);
export const useAdminUpdateExerciseMutation = generateUseUpdateMutation<AdminSerializedExercise>(
  exercisesCmsParams
);
