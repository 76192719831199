import { Flex, Link, Tag, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { clientList as listInvoices } from '../actions/invoices';

const InvoiceAwaitingPaymentBanner = (flexProps) => {
  const { memberId } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listInvoices(memberId));
  }, [memberId, dispatch]);

  const { invoices } = useSelector((state) => state.invoices);
  const awaitingPaymentInvoices = invoices.filter(
    (invoice) => invoice.attributes.status === 'awaiting_payment'
  );

  if (awaitingPaymentInvoices.length === 0) return null;

  const totalInvoiceAmount = awaitingPaymentInvoices
    .map((invoice) => invoice.attributes.amount.cents)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <Flex
      width="100%"
      height="3.5rem"
      bgColor="green.900"
      color="white"
      justify="space-around"
      align="center"
      {...flexProps}
    >
      <Link as={RouterLink} to="/pay-invoices">
        <Tag size="md" borderRadius="full" variant="solid" colorScheme="lime">
          {awaitingPaymentInvoices.length} Invoice{awaitingPaymentInvoices.length > 1 ? 's' : ''}
        </Tag>
      </Link>
      <Link as={RouterLink} to="/pay-invoices">
        Pay Invoice{awaitingPaymentInvoices.length > 1 ? 's' : ''}
      </Link>
      <Text data-cy="pay-invoice-banner-amount">${(totalInvoiceAmount / 100).toFixed(2)}</Text>
    </Flex>
  );
};

export default InvoiceAwaitingPaymentBanner;
