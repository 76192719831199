import { adminApiPath } from '../../../lib/api';

const adminEnrollmentsShowApiPath = (id: string) => adminApiPath('enrollments', id);
const adminEnrollmentsUpdateApiPath = (id: string) => adminApiPath('enrollments', id);
const adminEnrollmentsCreateApiPath = () => adminApiPath('enrollments');
const adminEnrollmentsDestroyApiPath = (id: string) => adminApiPath('enrollments', id);

export const getAdminEnrollmentsApiUrl = {
  show: (id: string) => adminEnrollmentsShowApiPath(id),
  update: (id: string) => adminEnrollmentsUpdateApiPath(id),
  create: () => adminEnrollmentsCreateApiPath(),
  destroy: (id: string) => adminEnrollmentsDestroyApiPath(id),
};
