const MIN_AGE = 18;

export const AgeChecker = {
  isMinor: (birthday) => {
    const parsedBday = new Date(birthday);
    const today = new Date();
    const yearsSinceBirth = today.getFullYear() - parsedBday.getFullYear();
    let tooYoung = yearsSinceBirth < MIN_AGE;
    if (yearsSinceBirth === MIN_AGE) {
      today.setYear(parsedBday.getFullYear());
      tooYoung = parsedBday > today;
    }
    return tooYoung;
  },
};
