import { CloseButtonProps, Modal, ModalCloseButton, ModalProps } from '@chakra-ui/react';
import React from 'react';
import { boundChakra } from '../../lib/chakra-ui';

export const TakeoverModal = ({ children, ...modalProps }: ModalProps) => (
  <Modal size="takeover" closeOnEsc={false} scrollBehavior="inside" {...modalProps}>
    {children}
  </Modal>
);

export const TakeoverModalContent = boundChakra.ModalContent('TakeoverModalContent', {
  sx: {
    // Go full-screen
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,

    // Unset Chakra's default max-height
    maxHeight: 'none',
  },
});

export const TakeoverModalBody = boundChakra.ModalBody('TakeoverModalBody', {
  sx: {
    // We leave layout / spacing up to the children.
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,

    // So we may position the Close Button
    position: 'relative',

    // Prevents the content from shifting when the scrollbar appears
    scrollbarGutter: 'stable',
  },
});

export const TakeoverModalCloseButton = ({
  alignMobile = 'left',
  ...rest
}: { alignMobile?: 'left' | 'right' } & CloseButtonProps) => (
  <ModalCloseButton
    zIndex="modalButton"
    variant="coa-main"
    sx={{
      /*
       * Position the Close Button *INSIDE* the modal content
       * so it can scroll with the content on smaller devices
       * where screen real-estate is limited and therefore a fixed
       * position button would be undesirable.
       */
      position: { base: 'absolute', md: 'fixed' },
      top: { base: 4, md: 8 },
      left: { base: alignMobile === 'left' ? 4 : 'auto', md: 'auto' },
      right: { base: alignMobile === 'right' ? 4 : 'auto', md: 8 },
      borderRadius: 10000,
      height: 12,
      width: 12,
    }}
    {...rest}
  />
);

export const TakeoverModalHeading = boundChakra.Heading('TakeoverModalHeading', {
  paddingRight: { base: 16, md: 0 },
});
