export const INTRO_WORKSHOPS_REQUEST = 'INTRO_WORKSHOPS_REQUEST';
export const INTRO_WORKSHOPS_SUCCESS = 'INTRO_WORKSHOPS_SUCCESS';
export const INTRO_WORKSHOPS_FAILURE = 'INTRO_WORKSHOPS_FAILURE';

export function introWorkshopsList() {
  return {
    type: INTRO_WORKSHOPS_REQUEST,
    api: {
      endpoint: 'workshops/intro',
      authenticated: true,
      types: [INTRO_WORKSHOPS_REQUEST, INTRO_WORKSHOPS_SUCCESS, INTRO_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const MY_WORKSHOPS_REQUEST = 'MY_WORKSHOPS_REQUEST';
export const MY_WORKSHOPS_SUCCESS = 'MY_WORKSHOPS_SUCCESS';
export const MY_WORKSHOPS_FAILURE = 'MY_WORKSHOPS_FAILURE';

export function myWorkshopsList() {
  return {
    type: MY_WORKSHOPS_REQUEST,
    api: {
      endpoint: 'workshops/mine',
      authenticated: true,
      types: [MY_WORKSHOPS_REQUEST, MY_WORKSHOPS_SUCCESS, MY_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const SERIES_WORKSHOPS_REQUEST = 'SERIES_WORKSHOPS_REQUEST';
export const SERIES_WORKSHOPS_SUCCESS = 'SERIES_WORKSHOPS_SUCCESS';
export const SERIES_WORKSHOPS_FAILURE = 'SERIES_WORKSHOPS_FAILURE';

export function seriesWorkshopsList() {
  return {
    type: SERIES_WORKSHOPS_REQUEST,
    api: {
      endpoint: 'workshops/series',
      authenticated: true,
      types: [SERIES_WORKSHOPS_REQUEST, SERIES_WORKSHOPS_SUCCESS, SERIES_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const DISCUSSION_WORKSHOPS_REQUEST = 'DISCUSSION_WORKSHOPS_REQUEST';
export const DISCUSSION_WORKSHOPS_SUCCESS = 'DISCUSSION_WORKSHOPS_SUCCESS';
export const DISCUSSION_WORKSHOPS_FAILURE = 'DISCUSSION_WORKSHOPS_FAILURE';

export function discussionWorkshopsList() {
  return {
    type: DISCUSSION_WORKSHOPS_REQUEST,
    api: {
      endpoint: 'workshops/discussion_group',
      authenticated: true,
      types: [
        DISCUSSION_WORKSHOPS_REQUEST,
        DISCUSSION_WORKSHOPS_SUCCESS,
        DISCUSSION_WORKSHOPS_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const ADVANCED_WORKSHOPS_REQUEST = 'ADVANCED_WORKSHOPS_REQUEST';
export const ADVANCED_WORKSHOPS_SUCCESS = 'ADVANCED_WORKSHOPS_SUCCESS';
export const ADVANCED_WORKSHOPS_FAILURE = 'ADVANCED_WORKSHOPS_FAILURE';

export function advancedWorkshopsList() {
  return {
    type: ADVANCED_WORKSHOPS_REQUEST,
    api: {
      endpoint: 'workshops/advanced_series',
      authenticated: true,
      types: [ADVANCED_WORKSHOPS_REQUEST, ADVANCED_WORKSHOPS_SUCCESS, ADVANCED_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const FILTERED_WORKSHOPS_REQUEST = 'FILTERED_WORKSHOPS_REQUEST';
export const FILTERED_WORKSHOPS_SUCCESS = 'FILTERED_WORKSHOPS_SUCCESS';
export const FILTERED_WORKSHOPS_FAILURE = 'FILTERED_WORKSHOPS_FAILURE';

export function filteredWorkshopsList(selectedTags) {
  const searchParams = selectedTags.map((tag) => `tag_ids[]=${tag.id}`).join('&');

  return {
    type: FILTERED_WORKSHOPS_REQUEST,
    api: {
      endpoint: `workshops?${searchParams}`,
      authenticated: true,
      types: [FILTERED_WORKSHOPS_REQUEST, FILTERED_WORKSHOPS_SUCCESS, FILTERED_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const WORKSHOP_DETAILS_REQUEST = 'WORKSHOP_DETAILS_REQUEST';
export const WORKSHOP_DETAILS_SUCCESS = 'WORKSHOP_DETAILS_SUCCESS';
export const WORKSHOP_DETAILS_FAILURE = 'WORKSHOP_DETAILS_FAILURE';

export function workshopDetails(workshopID) {
  return {
    type: WORKSHOP_DETAILS_REQUEST,
    api: {
      payload: workshopID,
      endpoint: `workshops/${workshopID}`,
      authenticated: true,
      types: [WORKSHOP_DETAILS_REQUEST, WORKSHOP_DETAILS_SUCCESS, WORKSHOP_DETAILS_FAILURE],
      method: 'GET',
    },
  };
}

export const LEADERSHIP_WORKSHOP_DETAILS_REQUEST = 'LEADERSHIP_WORKSHOP_DETAILS_REQUEST';
export const LEADERSHIP_WORKSHOP_DETAILS_SUCCESS = 'LEADERSHIP_WORKSHOP_DETAILS_SUCCESS';
export const LEADERSHIP_WORKSHOP_DETAILS_FAILURE = 'LEADERSHIP_WORKSHOP_DETAILS_FAILURE';

export function leadershipWorkshopDetails() {
  return {
    type: LEADERSHIP_WORKSHOP_DETAILS_REQUEST,
    api: {
      endpoint: `workshops/leadership_intro`,
      authenticated: true,
      types: [
        LEADERSHIP_WORKSHOP_DETAILS_REQUEST,
        LEADERSHIP_WORKSHOP_DETAILS_SUCCESS,
        LEADERSHIP_WORKSHOP_DETAILS_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const STANDARD_WORKSHOP_DETAILS_REQUEST = 'STANDARD_WORKSHOP_DETAILS_REQUEST';
export const STANDARD_WORKSHOP_DETAILS_SUCCESS = 'STANDARD_WORKSHOP_DETAILS_SUCCESS';
export const STANDARD_WORKSHOP_DETAILS_FAILURE = 'STANDARD_WORKSHOP_DETAILS_FAILURE';

export function standardWorkshopDetails() {
  return {
    type: STANDARD_WORKSHOP_DETAILS_REQUEST,
    api: {
      endpoint: `workshops/standard_intro`,
      authenticated: true,
      types: [
        STANDARD_WORKSHOP_DETAILS_REQUEST,
        STANDARD_WORKSHOP_DETAILS_SUCCESS,
        STANDARD_WORKSHOP_DETAILS_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const SIBLING_WORKSHOPS_REQUEST = 'SIBLING_WORKSHOPS_REQUEST';
export const SIBLING_WORKSHOPS_SUCCESS = 'SIBLING_WORKSHOPS_SUCCESS';
export const SIBLING_WORKSHOPS_FAILURE = 'SIBLING_WORKSHOPS_FAILURE';

export function siblingWorkshopList(workshopID) {
  return {
    type: SIBLING_WORKSHOPS_REQUEST,
    api: {
      endpoint: `workshops/${workshopID}/siblings`,
      authenticated: true,
      types: [SIBLING_WORKSHOPS_REQUEST, SIBLING_WORKSHOPS_SUCCESS, SIBLING_WORKSHOPS_FAILURE],
      method: 'GET',
    },
  };
}

export const JOINED_WORKSHOP_CLEAROUT = 'JOINED_WORKSHOP_CLEAROUT';

export const JOIN_WORKSHOP_REQUEST = 'JOIN_WORKSHOP_REQUEST';
export const JOIN_WORKSHOP_SUCCESS = 'JOIN_WORKSHOP_SUCCESS';
export const JOIN_WORKSHOP_FAILURE = 'JOIN_WORKSHOP_FAILURE';

export function joinWorkshop(
  workshopID,
  answers,
  promo_code,
  payment_method_id,
  drop_in_workshop_occurrence_id
) {
  return {
    type: JOIN_WORKSHOP_REQUEST,
    api: {
      data: { answers, promo_code, payment_method_id, drop_in_workshop_occurrence_id },
      endpoint: `workshops/${workshopID}/enrollment`,
      authenticated: true,
      types: [JOIN_WORKSHOP_REQUEST, JOIN_WORKSHOP_SUCCESS, JOIN_WORKSHOP_FAILURE],
      method: 'POST',
    },
  };
}

export const GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST =
  'GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST';
export const GET_SELECTED_WORKSHOP_ORDER_SUMMARY_SUCCESS =
  'GET_SELECTED_WORKSHOP_ORDER_SUMMARY_SUCCESS';
export const GET_SELECTED_WORKSHOP_ORDER_SUMMARY_FAILURE =
  'GET_SELECTED_WORKSHOP_ORDER_SUMMARY_FAILURE';

export function getSelectedWorkshopOrderSummary({ workshopID, promoCode } = {}) {
  return {
    type: GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST,
    api: {
      endpoint: `workshops/${workshopID}/order_summary${
        promoCode ? `?promo_code=${window.encodeURI(promoCode)}` : ''
      }`,
      authenticated: true,
      types: [
        GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST,
        GET_SELECTED_WORKSHOP_ORDER_SUMMARY_SUCCESS,
        GET_SELECTED_WORKSHOP_ORDER_SUMMARY_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const DESTROY_SELECTED_WORKSHOP_ORDER_SUMMARY = 'DESTROY_SELECTED_WORKSHOP_ORDER_SUMMARY';

export function destroySelectedWorkshopOrderSummary() {
  return {
    type: DESTROY_SELECTED_WORKSHOP_ORDER_SUMMARY,
  };
}
