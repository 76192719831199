export * from './types';
export * from './useMutationWithTimer';

type DefaultReactQueryArgs = {
  enabled: boolean;
};

// TODO: we should make this exhaustive of what is in react-query
export const defaultReactQueryArgs: DefaultReactQueryArgs = {
  enabled: true,
};

export const createDummyQueryClient = <Request, Response>() => ({
  path: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fn: (_args: Request) => Promise.resolve({}) as Promise<Response>,
});
