import path from 'path';
import { generatePath } from 'react-router';
import { Workshop, WorkshopOccurrence } from '../resources/workshops';
import { createGetRouterUrl } from './utils';

const classesApexRouterPath = path.resolve('classes');
const classesClassDetailsRouterPath = path.resolve(classesApexRouterPath, ':id');
const classesDropInDetailsRouterPath = path.resolve(
  classesClassDetailsRouterPath,
  'drop-ins',
  ':dropInWorkshopOccurrenceId'
);
const classesClassSubscribeRouterPath = path.resolve(classesClassDetailsRouterPath, 'subscribe');
const classesDropInSubscribeRouterPath = path.resolve(classesDropInDetailsRouterPath, 'subscribe');
const classesClassJoinRouterPath = path.resolve(classesClassDetailsRouterPath, 'join');
const classesDropInJoinRouterPath = path.resolve(classesDropInDetailsRouterPath, 'join');
const classesClassJoinCompleteProfileRouterPath = path.resolve(
  classesClassJoinRouterPath,
  'complete-profile'
);
const classesDropInJoinCompleteProfileRouterPath = path.resolve(
  classesDropInJoinRouterPath,
  'complete-profile'
);

const classesClassJoinSuccessRouterPath = path.resolve(classesClassDetailsRouterPath, 'thanks');
const classesDropInJoinSuccessRouterPath = path.resolve(classesDropInDetailsRouterPath, 'thanks');

export const classesRouterPaths = {
  apex: classesApexRouterPath,
  classDetails: classesClassDetailsRouterPath,
  dropInDetails: classesDropInDetailsRouterPath,
  classSubscribe: classesClassSubscribeRouterPath,
  dropInSubscribe: classesDropInSubscribeRouterPath,

  // TODO: We actually don't need drop-in specific join paths do we? Unclear.
  classJoin: classesClassJoinRouterPath,
  dropInJoin: classesDropInJoinRouterPath,
  classJoinCompleteProfile: classesClassJoinCompleteProfileRouterPath,
  dropInJoinCompleteProfile: classesDropInJoinCompleteProfileRouterPath,
  classJoinSuccess: classesClassJoinSuccessRouterPath,
  dropInJoinSuccess: classesDropInJoinSuccessRouterPath,
};

type ClassDetailsRouterPathParams = { id: Workshop['id'] };
type DropInDetailsRouterPathParams = ClassDetailsRouterPathParams & {
  dropInWorkshopOccurrenceId: WorkshopOccurrence['id'];
};
type ClassSubscribeRouterPathParams = ClassDetailsRouterPathParams;
type DropInSubscribeRouterPathParams = DropInDetailsRouterPathParams;
type ClassJoinRouterPathParams = ClassDetailsRouterPathParams;
type DropInJoinRouterPathParams = DropInDetailsRouterPathParams;
type ClassJoinCompleteProfileRouterPathParams = ClassDetailsRouterPathParams;
type DropInJoinCompleteProfileRouterPathParams = DropInDetailsRouterPathParams;
type ClassJoinSuccessRouterPathParams = ClassDetailsRouterPathParams;
type DropInJoinSuccessRouterPathParams = DropInDetailsRouterPathParams;

export type ClassesRouterPathParams = {
  ClassDetails: ClassDetailsRouterPathParams;
  DropInDetails: DropInDetailsRouterPathParams;
  ClassSubscribe: ClassSubscribeRouterPathParams;
  DropInSubscribe: DropInSubscribeRouterPathParams;
  ClassJoin: ClassJoinRouterPathParams;
  DropInJoin: DropInJoinRouterPathParams;
  ClassJoinCompleteProfile: ClassJoinCompleteProfileRouterPathParams;
  DropInJoinCompleteProfile: DropInJoinCompleteProfileRouterPathParams;
  ClassJoinSuccess: ClassJoinSuccessRouterPathParams;
  DropInJoinSuccess: DropInJoinSuccessRouterPathParams;
};

export const getClassesRouterUrl = {
  apex: createGetRouterUrl(classesApexRouterPath),
  classDetails: createGetRouterUrl<ClassesRouterPathParams['ClassDetails']>(
    classesClassDetailsRouterPath
  ),
  dropInDetails: createGetRouterUrl<ClassesRouterPathParams['DropInDetails']>(
    classesDropInDetailsRouterPath
  ),
  classSubscribe: createGetRouterUrl<ClassesRouterPathParams['ClassSubscribe']>(
    classesClassSubscribeRouterPath
  ),
  dropInSubscribe: createGetRouterUrl<ClassesRouterPathParams['DropInSubscribe']>(
    classesDropInSubscribeRouterPath
  ),
  classJoin: createGetRouterUrl<ClassesRouterPathParams['ClassJoin']>(classesClassJoinRouterPath),
  dropInJoin: createGetRouterUrl<ClassesRouterPathParams['DropInJoin']>(
    classesDropInJoinRouterPath
  ),
  classJoinCompleteProfile: createGetRouterUrl<ClassesRouterPathParams['ClassJoinCompleteProfile']>(
    classesClassJoinCompleteProfileRouterPath
  ),
  dropInJoinCompleteProfile: createGetRouterUrl<
    ClassesRouterPathParams['DropInJoinCompleteProfile']
  >(classesDropInJoinCompleteProfileRouterPath),
  classJoinSuccess: createGetRouterUrl<ClassesRouterPathParams['ClassJoinSuccess']>(
    classesClassJoinSuccessRouterPath
  ),
  dropInJoinSuccess: createGetRouterUrl<ClassesRouterPathParams['DropInJoinSuccess']>(
    classesDropInJoinSuccessRouterPath
  ),
};

/*
 * The way we manage URLs for Drop-Ins sorta stinks in that we render
 * much of the same content under both `/classes/:id` and
 * `/classes/:id/drop-ins/:dropInWorkshopOccurrenceId`, and we often need
 * to link to one of these depending on the context.
 *
 * This util should be helpful - it allows us to use one helper to
 * not have to re-write this again and again.
 */

type ClassDetailsUniversalRouterPathParams = ClassDetailsRouterPathParams &
  Partial<Pick<DropInDetailsRouterPathParams, 'dropInWorkshopOccurrenceId'>>;

export const createGetUniversalClassDetailsRouterUrl = <
  RouterPathParams extends ClassDetailsUniversalRouterPathParams
>({
  classesRouterPath,
  dropInRouterPath,
}: {
  classesRouterPath: string;
  dropInRouterPath: string;
}) => (routeParams: RouterPathParams = {} as RouterPathParams) =>
  generatePath(
    // Route to drop-in path if present or classes path otherwise.
    routeParams.dropInWorkshopOccurrenceId ? dropInRouterPath : classesRouterPath,
    routeParams
  );

export const getUniversalClassesRouterUrl = {
  details: createGetUniversalClassDetailsRouterUrl({
    classesRouterPath: classesClassDetailsRouterPath,
    dropInRouterPath: classesDropInDetailsRouterPath,
  }),
  subscribe: createGetUniversalClassDetailsRouterUrl({
    classesRouterPath: classesClassSubscribeRouterPath,
    dropInRouterPath: classesDropInSubscribeRouterPath,
  }),
  join: createGetUniversalClassDetailsRouterUrl({
    classesRouterPath: classesClassJoinRouterPath,
    dropInRouterPath: classesDropInJoinRouterPath,
  }),
  joinCompleteProfile: createGetUniversalClassDetailsRouterUrl({
    classesRouterPath: classesClassJoinCompleteProfileRouterPath,
    dropInRouterPath: classesDropInJoinCompleteProfileRouterPath,
  }),
  success: createGetUniversalClassDetailsRouterUrl({
    classesRouterPath: classesClassJoinSuccessRouterPath,
    dropInRouterPath: classesDropInJoinSuccessRouterPath,
  }),
};
