import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { usePostB2BAccountResendVerifyEmailMutation } from '../resources/member';
import { useSession } from '../resources/sessions';

const ResendEmailVerifyFormSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Email is required'),
});

type ResendVerifyEmailFormValues = {
  email: string;
};
export const AccountResendEmailVerifyForm = ({
  handleClose,
}: {
  handleClose: () => void;
}): JSX.Element => {
  const { logoutWithoutRedirect } = useSession();
  const toast = useToast();
  const [memberNotFound, setMemberNotFound] = useState(false);
  const [emailNotSent, setEmailNotSent] = useState(false);
  const postResendVerifyEmailMutation = usePostB2BAccountResendVerifyEmailMutation();

  const handleSubmit = async ({ email }: ResendVerifyEmailFormValues) => {
    try {
      await postResendVerifyEmailMutation.mutateAsync({ email });
      // TODO: show toast
      setMemberNotFound(false);
      setEmailNotSent(false);
      const duration = 3000;
      setTimeout(() => handleClose(), duration);
      toast({
        title: 'Email Sent!',
        status: 'success',
        duration,
        isClosable: true,
      });
      logoutWithoutRedirect();
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === 'No Member Found') {
          return setMemberNotFound(true);
        }
        setEmailNotSent(true);
      }
    }
  };

  return (
    <Box width="stretch">
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={ResendEmailVerifyFormSchema}
      >
        {() => (
          <Form>
            <Field name="email">
              {({ field, form }) => {
                const isFormInvalid = form.errors.email && form.touched.email && form.dirty;
                const isSubmissionInvalid = memberNotFound || emailNotSent;
                let errorMessage = form.errors.email;
                if (memberNotFound) {
                  errorMessage = 'Member Not found';
                }
                if (emailNotSent) {
                  errorMessage = 'Unable to send email, please try again.';
                }
                return (
                  <FormControl p={1} isInvalid={isFormInvalid || isSubmissionInvalid}>
                    <Input
                      backgroundColor="warm.50"
                      {...field}
                      value={field.value ? field.value : ''}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Please enter your email address"
                    />
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
            <Button
              my={4}
              width={{ base: 'auto', md: '20%' }}
              // isLoading={props.isSubmitting}
              type="submit"
              colorScheme="evergreen"
            >
              Send
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export const AccountResendVerifyEmailModal = (): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button variant="secondary" onClick={onOpen} size="md">
        <Text fontSize="12px">Verify Email</Text>
      </Button>
      <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent paddingX={2}>
          <ModalHeader>Verify Email:</ModalHeader>
          <ModalCloseButton borderRadius="50%" bg="green.50" />
          <ModalBody>
            <VStack alignItems="start">
              <Text fontWeight="bold">Let's verify your email and set up your password!</Text>
              <UnorderedList ml={2} mb={4}>
                <ListItem>Start by entering your email below.</ListItem>
                <ListItem>
                  You will receive an email from Coa to create a password. (Check your spam!)
                </ListItem>
                <ListItem>
                  Once you create a password and are logged in, come back here and continue on
                  setting up your team!
                </ListItem>
              </UnorderedList>

              <AccountResendEmailVerifyForm handleClose={() => onClose()} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
