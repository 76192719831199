import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React, { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../components/Icons';
import { ClassCardKind } from '../../../../components/molecules/ClassCard';
import { classMarketplaceAnalytics } from '../../../../lib/analytics/clients';
import { SwiperStylesProvider } from '../../../../lib/swiper';
import {
  createGetFeaturedWorkshopsPlaceholderData,
  formatWorkshopOccurrenceTimeLabel,
  formatWorkshopTimeLabel,
  getIsEnrolledInWorkshopDropIn,
  getIsEnrollmentApprovedForFullSeries,
  getIsEnrollmentApprovedForPartialSeries,
  getIsSeriesLike,
  getNextAvailableWorkshopOccurrence,
  getWorkshopHasStarted,
  useFeaturedWorkshopsQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../../resources/workshops';
import { getWorkshopRouterUrl } from '../../../../routerPaths/helpers';
import { boxShadowStyles } from '../../../../themes/coaTheme';

type FeaturedCtaButtonProps = {
  id: Workshop['id'];
  meta: Workshop['meta'];
  numberOfOccurrences: number;
  nextAvailableDropIn: WorkshopOccurrence;
};

const FeaturedCtaButton = ({
  id,
  meta,
  numberOfOccurrences,
  nextAvailableDropIn,
}: FeaturedCtaButtonProps) => {
  const workshopRouterUrl = getWorkshopRouterUrl({
    id,
    dropInWorkshopOccurrenceId: nextAvailableDropIn?.id,
  });
  const enrolledDropInsLength = _.size(meta.enrollmentDropInIds);

  // User is enrolled in the entire Series or the displayed Drop-In
  if (
    getIsEnrollmentApprovedForFullSeries({
      enrollmentStatus: meta.enrollmentStatus,
      enrollmentDropInIds: meta.enrollmentDropInIds,
    }) ||
    getIsEnrolledInWorkshopDropIn(meta, nextAvailableDropIn?.id)
  ) {
    return (
      <VStack alignItems={{ base: 'flex-start', lg: 'center' }} w="100%">
        <Button
          variant="ctaRedLight"
          size="lg"
          as={RouterLink}
          to={workshopRouterUrl}
          w="100%"
          onClick={() => {
            classMarketplaceAnalytics.track('Clicked Featured Class', {
              classId: id,
            });
          }}
        >
          Enrolled
        </Button>
      </VStack>
    );
  }

  // Series hasn't started AND User is partially enrolled in the Series
  if (
    getIsEnrollmentApprovedForPartialSeries({
      enrollmentStatus: meta.enrollmentStatus,
      enrollmentDropInIds: meta.enrollmentDropInIds,
    })
  ) {
    return (
      <VStack alignItems={{ base: 'flex-start', lg: 'center' }} w="100%">
        <Text fontSize="sm" lineHeight="16px" textAlign={{ base: 'left', md: 'center' }}>
          Enrolled in {enrolledDropInsLength} of {numberOfOccurrences} classes
        </Text>
        <Button
          colorScheme="red"
          size="lg"
          as={RouterLink}
          to={workshopRouterUrl}
          w="100%"
          onClick={() => {
            classMarketplaceAnalytics.track('Clicked Featured Class', {
              classId: id,
            });
          }}
        >
          View Series
        </Button>
      </VStack>
    );
  }

  // Default: User is not enrolled in the class (Series or Drop-In)
  return (
    <Button
      colorScheme="red"
      size="lg"
      as={RouterLink}
      to={workshopRouterUrl}
      w="100%"
      onClick={() => {
        classMarketplaceAnalytics.track('Clicked Featured Class', {
          classId: id,
        });
      }}
    >
      Save My Spot
    </Button>
  );
};

export const FeaturedClassesSlider = () => {
  const placeholderData = createGetFeaturedWorkshopsPlaceholderData({ length: 4 });
  const featuredClassesQuery = useFeaturedWorkshopsQuery({ placeholderData });
  const { data: featuredClasses, isLoading, isFetched } = featuredClassesQuery;
  const [swiperRef, setSwiperRef] = useState(null);

  const handlePrev = () => {
    swiperRef.slidePrev();
  };

  const handleNext = () => {
    swiperRef.slideNext();
  };

  const slideStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  };

  return (
    <SwiperStylesProvider>
      <Box position="relative" pb={{ base: 6, md: 10 }}>
        <HStack
          position="absolute"
          display="flex"
          alignItems="center"
          right={0}
          bottom={{ base: 3, md: 0 }}
          spacing={4}
          color="brand.charcoal"
        >
          <Box className="swiper-custom-pagination" fontSize={{ base: 'md', md: 'lg' }} />
          <HStack spacing={2}>
            <ChevronLeftIcon onClick={handlePrev} boxSize={{ base: 5, md: 7 }} cursor="pointer" />
            <ChevronRightIcon onClick={handleNext} boxSize={{ base: 5, md: 7 }} cursor="pointer" />
          </HStack>
        </HStack>

        <Swiper
          spaceBetween={30}
          loop
          pagination={{
            type: 'fraction',
            el: '.swiper-custom-pagination',
          }}
          modules={[Pagination]}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          slidesPerView={1}
          breakpoints={{
            736: {
              slidesPerView: 2,
              noSwipingSelector: 'a',
            },
          }}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            paddingTop: '20px',
            paddingBottom: '20px',
            zIndex: 0,
          }}
        >
          {featuredClasses.map((featuredClass: Workshop) => {
            const isSeriesClass = getIsSeriesLike(featuredClass.kind);
            const seriesHasStarted = isSeriesClass && getWorkshopHasStarted(featuredClass);
            const nextAvailableDropIn = getNextAvailableWorkshopOccurrence(featuredClass);
            // We display the Drop-In class instead of the Series if:
            // - the Series has started
            // - there is an available Drop-In to display
            const displayDropIn = seriesHasStarted && nextAvailableDropIn;

            return (
              <SwiperSlide key={featuredClass.id} style={slideStyle}>
                <Skeleton
                  isLoaded={!isLoading && isFetched}
                  w="100%"
                  borderRadius="lg"
                  transition="all 0.2s ease"
                  _hover={{ transform: 'translateY(-0.125rem)' }}
                >
                  <LinkBox
                    h={{ base: '55vh', md: '500px' }}
                    w="100%"
                    position="relative"
                    borderRadius="lg"
                    overflow="hidden"
                    {...boxShadowStyles}
                  >
                    <Image
                      objectFit="cover"
                      objectPosition="top"
                      position="absolute"
                      h="100%"
                      w="100%"
                      src={featuredClass.imageUrl}
                    />
                    <Box
                      position="relative"
                      h="100%"
                      w="100%"
                      p={8}
                      transition="all 0.2s ease"
                      bgGradient={{
                        base:
                          'linear(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(58,58,58,0.65) 60%, #282828 100%)',
                        md:
                          'linear(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(58,58,58,0.65) 70%, #282828 100%)',
                      }}
                      display="flex"
                      flexDir="column"
                      justifyContent="flex-end"
                      color="white"
                    >
                      <VStack alignItems="flex-start" spacing={0}>
                        <LinkOverlay
                          as={RouterLink}
                          to={
                            displayDropIn
                              ? `/classes/${featuredClass.id}/drop-ins/${nextAvailableDropIn.id}`
                              : `/classes/${featuredClass.id}`
                          }
                          cursor="pointer"
                          onClick={() => {
                            classMarketplaceAnalytics.track('Clicked Featured Class', {
                              classId: featuredClass.id,
                            });
                          }}
                        >
                          <ClassCardKind
                            type={displayDropIn ? 'workshop_occurrence' : 'workshop'}
                            kind={featuredClass.kind}
                            numberOfClasses={featuredClass.workshopOccurrences.length}
                          />
                          <Heading size="xl" fontWeight="medium" mt={4}>
                            {displayDropIn ? nextAvailableDropIn.title : featuredClass.title}
                          </Heading>
                        </LinkOverlay>
                        <Flex
                          flexDir={{ base: 'column', lg: 'row' }}
                          alignItems={{ base: 'flex-start', lg: 'flex-end' }}
                          w="100%"
                          justifyContent="space-between"
                        >
                          <Box mr={{ base: 0, md: 0, lg: 2 }} mb={{ base: 4, md: 4, lg: 0 }}>
                            <Text fontSize={{ base: 'xl', lg: '2xl' }}>
                              {featuredClass.therapist.name}
                            </Text>
                            <Text>{featuredClass.marketListingLabel}</Text>
                            <Text>
                              {displayDropIn
                                ? formatWorkshopOccurrenceTimeLabel(nextAvailableDropIn)
                                : formatWorkshopTimeLabel(
                                    featuredClass,
                                    featuredClass.workshopOccurrences
                                  )}
                            </Text>
                          </Box>
                          <Box w={{ base: 'auto', lg: '32%' }}>
                            <FeaturedCtaButton
                              id={featuredClass.id}
                              meta={featuredClass.meta}
                              numberOfOccurrences={featuredClass.workshopOccurrences.length}
                              nextAvailableDropIn={displayDropIn ? nextAvailableDropIn : null}
                            />
                          </Box>
                        </Flex>
                      </VStack>
                    </Box>
                  </LinkBox>
                </Skeleton>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </SwiperStylesProvider>
  );
};

export const FeaturedClassesView = () => (
  <Box w="100%">
    <Heading fontSize={{ base: '2xl', md: '3xl' }}>Trending Classes</Heading>
    <FeaturedClassesSlider />
  </Box>
);
