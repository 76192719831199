import { Flex, IconButton, Input, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CopyIcon } from '../../../../components/Icons';
import { copyToClipboard } from '../../../../utils/Clipboard';

// We can extend this to be a dropdown / select
// to copy the different url's associated w/ each class link
export const AdminWorkshopUrlPicker = ({ initialValue }: { initialValue: string }) => {
  const [urlValue, setUrlValue] = useState(initialValue);
  const toast = useToast();
  const handleCopyUrlToClipboard = async () => {
    await copyToClipboard(urlValue);
    toast({
      title: 'Copied to Clipboard!',
      description: urlValue || 'YOU COPIED NOTHING!',
      status: urlValue ? 'success' : 'error',
      duration: 1200,
      isClosable: true,
    });
  };
  return (
    <Flex width="100%" alignItems="center" marginX="6px">
      <IconButton
        aria-label="Admin Workshop Url Picker"
        colorScheme="warm"
        size="sm"
        marginRight="4px"
        icon={<CopyIcon />}
        onClick={handleCopyUrlToClipboard}
      />
      <Input value={urlValue} onChange={(e) => setUrlValue(e.target.value)} bg="white" />
    </Flex>
  );
};
