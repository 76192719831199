import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { ArrowLeftIcon } from '../../../components/Icons';
import { marketingSiteBaseUrl, marketingSiteDomainName } from '../../../lib/www';

export function MarketingSiteNavigationBreadcrumb(props: ButtonProps) {
  return (
    <Button
      size="sm"
      variant="link"
      leftIcon={<ArrowLeftIcon />}
      as="a"
      href={marketingSiteBaseUrl}
      {...props}
    >
      Back to {marketingSiteDomainName}
    </Button>
  );
}
