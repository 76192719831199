import {
  MutationFunction,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { createAxiosClient, createJsonApiAxiosClient } from '../../../../lib/axios';
import { getSubscriptionsApiUrl } from './apiPaths';
import { GetSubscriptionOrderSummary, GetSubscriptions, PostSubscriptions } from './types';

/*
 * GET /v1/subscriptions
 */

const generateGetSubscriptions = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getSubscriptionsApiUrl.index();

  const fn = async () => {
    const { data } = await client.get<GetSubscriptions.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useGetSubscriptionsQuery = (
  options: UseQueryOptions<GetSubscriptions.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetSubscriptions();
  return useQuery<GetSubscriptions.Response>(queryKey, fn, options);
};

/*
 * POST /v1/subscriptions
 */

const generatePostSubscriptions = () => {
  const client = createAxiosClient({ auth: true, transformPropertyNameCase: true });
  const path = getSubscriptionsApiUrl.index();

  const fn: MutationFunction<
    PostSubscriptions.Response,
    PostSubscriptions.Request['body'] | undefined
  > = async (body?: PostSubscriptions.Request['body']) => {
    const { data } = await client.post<PostSubscriptions.Response>(path, body);
    return data;
  };

  return { path, fn };
};

export const usePostSubscriptionsMutation = () => {
  const { path: postQueryKey, fn: mutationFn } = generatePostSubscriptions();
  const queryClient = useQueryClient();

  return useMutation(postQueryKey, {
    mutationFn,
    onMutate: async () => {
      await queryClient.cancelQueries(getSubscriptionsApiUrl.index());
    },
    onSettled: () => {
      queryClient.invalidateQueries(getSubscriptionsApiUrl.index());
    },
  });
};

/*
 * GET /v1/subscriptions/order_summary
 */

const generateGetSubscriptionOrderSummary = (
  queryParams: GetSubscriptionOrderSummary.Request['queryParams']
) => {
  const client = createJsonApiAxiosClient();
  const path = getSubscriptionsApiUrl.orderSummary(queryParams);

  const fn = async () => {
    const { data } = await client.get<GetSubscriptionOrderSummary.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useGetSubscriptionOrderSummaryQuery = (
  queryParams: GetSubscriptionOrderSummary.Request['queryParams'],
  options: UseQueryOptions<GetSubscriptionOrderSummary.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetSubscriptionOrderSummary(queryParams);
  return useQuery<GetSubscriptionOrderSummary.Response>(queryKey, fn, options);
};

/*
 * GET /v1/subscriptions/b2b_order_summary
 */

const generateGetB2BSubscriptionOrderSummary = (
  queryParams: GetSubscriptionOrderSummary.Request['queryParams']
) => {
  const client = createJsonApiAxiosClient();
  const path = getSubscriptionsApiUrl.b2bOrderSummary(queryParams);

  const fn = async () => {
    const { data } = await client.get<GetSubscriptionOrderSummary.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useGetB2BSubscriptionOrderSummaryQuery = (
  queryParams: GetSubscriptionOrderSummary.Request['queryParams'],
  options: UseQueryOptions<GetSubscriptionOrderSummary.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetB2BSubscriptionOrderSummary(queryParams);
  return useQuery<GetSubscriptionOrderSummary.Response>(queryKey, fn, options);
};
