import { Box, Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import { PlaylistIcon } from '../../../../components/Icons';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import { onDemandAnalytics } from '../../../../lib/analytics/clients';
import { OnDemandVideo, useGetOnDemandVideosQuery } from '../../../../resources/onDemandVideos';
import { createGetOnDemandVideosPlaceholderData } from '../../../../resources/onDemandVideos/apis/placeholder';
import { getRouterUrl } from '../../../../routerPaths';
import { getStableOnDemandVideoBgColor } from '../utils';

const placeholderData = createGetOnDemandVideosPlaceholderData({ length: 12 });

export const BrowseByTopic = () => {
  const searchOnDemandVideosQuery = useGetOnDemandVideosQuery({ placeholderData });

  const { data: onDemandVideos } = searchOnDemandVideosQuery;

  const isLoading =
    searchOnDemandVideosQuery.isLoading || searchOnDemandVideosQuery.isPlaceholderData;

  const trackTopicClicked = (video: OnDemandVideo) => {
    onDemandAnalytics.track('Clicked On-Demand Topic', {
      topic: video.title,
      videoId: video.id,
    });
  };

  return (
    <Box pt={10}>
      <Skeleton isLoaded={!isLoading}>
        <Heading size="md" mb={6}>
          Browse by Topic
        </Heading>
      </Skeleton>
      <Grid
        templateColumns="repeat(12, 1fr)"
        templateRows="repeat(1, 1fr)"
        autoRows="1fr"
        width="100%"
        columnGap={{ base: 6, md: 8 }}
        rowGap={8}
      >
        {onDemandVideos.map((video) => (
          <GridItem colSpan={{ base: 6, md: 4, lg: 3 }} key={video.id}>
            <Skeleton isLoaded={!isLoading} height="100%">
              <TransformUpLink
                as={RouterLink}
                to={getRouterUrl.onDemand.video({ videoId: video.id })}
                height="100%"
                width="100%"
                onClick={() => {
                  trackTopicClicked(video);
                }}
              >
                <Box
                  backgroundColor={getStableOnDemandVideoBgColor(video.title)}
                  display="flex"
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  height="100%"
                  boxShadow="base"
                  borderRadius="base"
                  color="white"
                  py={{ base: 6, md: 10 }}
                  px={{ base: 6, md: 8 }}
                >
                  <Heading fontSize={{ base: 'md', md: 'lg' }} mb={4}>
                    {video.title}
                  </Heading>
                  <Flex alignItems="center">
                    <PlaylistIcon color="whiteAlpha.700" boxSize={4} mr={2} />
                    <Text
                      textStyle="earmark"
                      color="whiteAlpha.700"
                      fontSize={{ base: 'xx-small', md: 'xs' }}
                    >
                      {video.onDemandVideoCheckpoints.length} questions
                    </Text>
                  </Flex>
                </Box>
              </TransformUpLink>
            </Skeleton>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
