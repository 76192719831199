import { Button, Heading, StackProps, VStack } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import { formatProseStartTimeShort } from '@coa/stdlib/string';
import React from 'react';
import { boundChakra } from '../../../../lib/chakra-ui';
import { useSelectedPushup } from './SelectedPushupProvider';

const SuccessText = boundChakra.Text('SuccessStateText', {
  fontSize: { base: 'lg', md: 'xl' },
  maxWidth: 400,
  textAlign: 'center',
});

export function TryPushupSuccess(props: StackProps) {
  const { selectedPushup } = useSelectedPushup();
  return (
    <VStack
      {...props}
      height="100%"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <VStack spacing={4} mb={6}>
        <Heading size="xl">You're in!</Heading>
        <SuccessText>
          We look forward to seeing you on{' '}
          <strong>
            {formatProseStartTimeShort(selectedPushup.workshopOccurrences[0].startsAt)}
          </strong>{' '}
          for <strong>{selectedPushup.title}</strong>.
        </SuccessText>
        <SuccessText>You'll receive an email shortly with more details.</SuccessText>
      </VStack>
      <Button
        as={RouterLink}
        to="/my-coa"
        size="lg"
        variant="secondary"
        data-cy="pushups-success-cta"
      >
        Go to my Push-Up!
      </Button>
    </VStack>
  );
}
