import { createLegacyAuthAxiosClientWithCaseTransform } from '@coa/api/lib/axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { GetPaymentProfile, PutPaymentProfile } from './types';

const generateGetPaymentProfile = ({ id }: GetPaymentProfile.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/clients/${id}/payment_profile`;

  const fn = async () => {
    const { data } = await client.get<GetPaymentProfile.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useGetPaymentProfileQuery = (
  { id }: GetPaymentProfile.Request['pathParams'],
  options: UseQueryOptions<GetPaymentProfile.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetPaymentProfile({ id });
  return useQuery<GetPaymentProfile.Response>(queryKey, fn, options);
};

const generatePutPaymentProfile = ({ id }: PutPaymentProfile.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/clients/${id}/payment_profile`;

  const fn = async (body: PutPaymentProfile.Request['body']) => {
    const { data } = await client.put<PutPaymentProfile.Response>(path, body);
    return data;
  };

  return { path, fn };
};

export const usePutPaymentProfileMutation = ({ id }: PutPaymentProfile.Request['pathParams']) => {
  const { path: postQueryKey, fn } = generatePutPaymentProfile({ id });
  const queryClient = useQueryClient();

  return useMutation(postQueryKey, {
    mutationFn: fn,
    onMutate: async () => {
      await queryClient.cancelQueries(`/v1/clients/${id}/payment_profile`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/clients/${id}/payment_profile`);
    },
  });
};
