export const PASSWORD_RESETS_REQUEST = 'PASSWORD_RESETS_REQUEST';
export const PASSWORD_RESETS_SUCCESS = 'PASSWORD_RESETS_SUCCESS';
export const PASSWORD_RESETS_FAILURE = 'PASSWORD_RESETS_FAILURE';

export function create(data) {
  return {
    type: PASSWORD_RESETS_REQUEST,
    api: {
      data,
      endpoint: 'password_resets',
      authenticated: false,
      types: [PASSWORD_RESETS_REQUEST, PASSWORD_RESETS_SUCCESS, PASSWORD_RESETS_FAILURE],
      method: 'POST',
    },
  };
}
