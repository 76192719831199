import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../containers/routes';
import { BrowseClassesView } from './browse/BrowseClassesView';
import { WorkshopDetailsView } from './details/WorkshopDetailsView';
import { ClassesHubView } from './hub/ClassesHubView';

export const ClassesView = () => (
  <Switch>
    <AuthenticatedRoute
      path="/classes/(series|intros|drop-ins|q-and-a)"
      component={BrowseClassesView}
    />

    {/* Note that subroutes of /classes/:id are rendered within this component. */}
    <AuthenticatedRoute
      path="/classes/:id/drop-ins/:dropInWorkshopOccurrenceId"
      component={WorkshopDetailsView}
    />
    <AuthenticatedRoute path="/classes/:id/exercises/:exerciseId" component={WorkshopDetailsView} />
    <AuthenticatedRoute path="/classes/:id" component={WorkshopDetailsView} />
    <AuthenticatedRoute path="/classes" component={ClassesHubView} />
  </Switch>
);
