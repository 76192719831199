import { createJsonApiAxiosClient } from '@coa/api/lib/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { onDemandVideosApiPaths } from './apiPaths';
import type { GetOnDemandVideo, GetOnDemandVideos } from './types';

/*
 * GET /v1/on_demand_videos/:video_id
 */
const generateGetOnDemandVideo = ({ id }: GetOnDemandVideo.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const apiRoute = onDemandVideosApiPaths.show(id);
  const fn = async () => {
    const { data } = await client.get<GetOnDemandVideo.Response>(apiRoute);
    return data;
  };

  return {
    path: apiRoute,
    fn,
  };
};

export const useGetOnDemandVideoQuery = (
  { id }: GetOnDemandVideo.Request['pathParams'],
  ops: UseQueryOptions<GetOnDemandVideo.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetOnDemandVideo({ id });
  return useQuery<GetOnDemandVideo.Response>(queryKey, fn, ops);
};

/*
 * GET /v1/on_demand_videos
 */
const generateGetOnDemandVideos = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = onDemandVideosApiPaths.index();
  const fn = async () => {
    const { data } = await client.get<GetOnDemandVideos.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetOnDemandVideosQuery = (
  ops: UseQueryOptions<GetOnDemandVideos.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetOnDemandVideos();
  return useQuery<GetOnDemandVideos.Response>(queryKey, fn, ops);
};
