import { Avatar, Box, BoxProps, ButtonProps, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Therapist } from '../../resources/therapists';
import { getIsSeriesLike, Workshop } from '../../resources/workshops';
import { ColorScheme } from '../../themes/coaTheme';
import { ClassListingLabel } from '../atoms/ClassListingLabel';
import { EventCardDescription, EventCardHeading, EventCardSubHeading } from './EventCard';

type ClassListingLabelProps =
  | {
      showClassLabel: boolean;
      workshopAccess: string;
    }
  | {
      showClassLabel?: boolean;
      workshopAccess?: string;
    };

export type ClassListingProps = {
  label: Workshop['marketListingLabel'];
  title: Workshop['title'];
  description?: Workshop['description'];
  date: string;
  instructorName: Therapist['name'];
  instructorImage: Therapist['imageUrl'];
  colorScheme: ColorScheme;
  src: Workshop['imageUrl'];
  numberOfClasses?: number;
  kind?: Workshop['kind'];
} & ClassListingLabelProps &
  BoxProps;

export type FullClassListingProps = ClassListingProps & {
  renderCta: (_props: Partial<ButtonProps>) => React.ReactNode;
};

export const ClassListing = ({
  label,
  title,
  description,
  date,
  instructorName,
  instructorImage,
  colorScheme = 'gray',
  src,
  numberOfClasses,
  kind,
  showClassLabel,
  workshopAccess,
  ...rest
}: ClassListingProps) => (
  <Box
    display="flex"
    height="100%"
    boxShadow="base"
    borderRadius="base"
    flexDirection="row"
    alignItems="stretch"
    backgroundColor="white"
    {...rest}
  >
    <Box w="35%" position="relative">
      <Image objectFit="cover" position="absolute" top="0" left="0" h="100%" w="100%" src={src} />
    </Box>

    <Box
      p={4}
      w="100%"
      display="flex"
      flexDirection="column"
      position="relative" // To anchor price-ribbon
    >
      <Box w="100%" h="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <EventCardSubHeading fontSize="sm" mb={2} mr={14}>
            {date}
          </EventCardSubHeading>
          <EventCardHeading mb={2} color="warm.500">
            {label}
          </EventCardHeading>
          <Heading fontSize="lg" mb={2}>
            {title}
          </Heading>
          {getIsSeriesLike(kind) && numberOfClasses ? (
            <EventCardDescription>{numberOfClasses} classes in this series</EventCardDescription>
          ) : (
            <EventCardDescription>{description}</EventCardDescription>
          )}
        </Box>
        <Flex alignItems="center" mt={2}>
          <Avatar size="xs" name={instructorName} src={instructorImage} mr={2} />
          <Text color={`${colorScheme}.500`} fontSize="md">
            {instructorName}
          </Text>
        </Flex>
        {showClassLabel && workshopAccess ? <ClassListingLabel access={workshopAccess} /> : null}
      </Box>
    </Box>
  </Box>
);

export const FullClassListing = ({
  label,
  title,
  date,
  description,
  instructorName,
  instructorImage,
  colorScheme,
  src,
  renderCta,
}: FullClassListingProps) => (
  <Box
    display="flex"
    height="100%"
    boxShadow="base"
    borderRadius="base"
    overflow="hidden"
    alignItems="stretch"
    backgroundColor="white"
  >
    <Box
      w="10%"
      position="relative"
      backgroundImage={src}
      backgroundPosition="center"
      backgroundSize="cover"
    />

    <Box p={4} w="100%" display="flex" flexDirection="column">
      <Box
        w="100%"
        h="100%"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Box w={{ base: '100%', md: '65%' }} textAlign="left">
          <EventCardSubHeading fontSize="sm" mb={2}>
            {date}
          </EventCardSubHeading>
          <EventCardHeading mb={2} color="warm.500">
            {label}
          </EventCardHeading>
          <Heading fontSize="lg" mb={2}>
            {title}
          </Heading>
          <Box>
            <EventCardDescription
              fontSize="md"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: '4',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {description}
            </EventCardDescription>
          </Box>
          <Flex alignItems="center" mt={4}>
            <Avatar size="xs" name={instructorName} src={instructorImage} mr={2} />
            <Text color={`${colorScheme}.500`} fontSize="md">
              {instructorName}
            </Text>
          </Flex>
        </Box>
        <Box w={{ base: '100%', md: 'auto' }} display="block">
          {renderCta({
            marginTop: { base: 4, md: 0 },
            size: 'md',
          })}
        </Box>
      </Box>
    </Box>
  </Box>
);
