import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonProps,
  CloseButton,
  Collapse,
  FormControl,
  Grid,
  GridItem,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { PostSubscriptions } from '@coa/api/controllers/v1/subscriptions';
import { RouterLink, useInitial } from '@coa/react-utils';
import { AxiosError } from 'axios';
import { Form, Formik, useFormikContext } from 'formik';
import { motion } from 'framer-motion';
import _ from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import * as Yup from 'yup';
import { AlertCircleIcon } from '../../../../components/Icons';
import { PaymentDisplay } from '../../../../components/PaymentDisplay';
import PoweredByStripe from '../../../../images/stripe.svg';
import { useAnalytics } from '../../../../lib/analytics/AnalyticsProvider';
import {
  StripeElementsFontProvider,
  StyledInputStripeElement,
} from '../../../../lib/stripe-elements';
import {
  PaymentMethod,
  PutPaymentProfile,
  useGetPaymentProfileQuery,
  usePutPaymentProfileMutation,
} from '../../../../resources/paymentProfiles';
import { getUserExists, useCreateRegistration } from '../../../../resources/registrations';
import { useSession } from '../../../../resources/sessions';
import { getRouterUrl } from '../../../../routerPaths';
import { FormikQuestion } from '../../join/components/FormQuestion';
import { ExistingEmailPasswordDialog } from './ExistingEmailPasswordDialog';
import { SubscribeModalOrderSummary } from './SubscribeModalOrderSummary';
import { useOrderSummary } from './SubscribeModalOrderSummaryProvider';

type SubscribeFormValues = {
  email?: string;
  firstName?: string;
  lastName?: string;
  stripeCardElComplete?: boolean;
  agreeToTerms: string[];
};

function getSubscribeFormInitialValues({
  name,
  hasPaymentMethod,
  loggedIn,
}: {
  name: string;
  hasPaymentMethod: boolean;
  loggedIn: boolean;
}): SubscribeFormValues {
  return {
    ...(loggedIn
      ? {}
      : {
          email: '',
        }),
    ...(hasPaymentMethod
      ? {}
      : {
          firstName: name ? name.split(' ')[0] : '',
          lastName: name ? name.split(' ').pop() : '',
          stripeCardElComplete: false,
        }),
    agreeToTerms: [],
  };
}

function getSubscribeFormValidationSchema({
  hasPaymentMethod,
  loggedIn,
}: {
  hasPaymentMethod: boolean;
  loggedIn: boolean;
}) {
  return Yup.object().shape({
    ...(loggedIn
      ? {}
      : {
          email: Yup.string().email().required(),
        }),
    ...(hasPaymentMethod
      ? {}
      : {
          firstName: Yup.string().min(1).required(),
          lastName: Yup.string().min(1).required(),
          stripeCardElComplete: Yup.bool().oneOf([true]).required(),
        }),
    agreeToTerms: Yup.array().of(Yup.string()).min(1).required(),
  });
}

function CreditCardFormFields({
  forceRenderInvalidState,
  isDisabled,
}: {
  forceRenderInvalidState?: boolean;
  isDisabled?: boolean;
}) {
  const { loggedIn } = useCreateRegistration();
  const loggedInOnMount = useInitial(loggedIn);
  const { hideOrderSummary } = useOrderSummary();

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4} rowGap={4} width="full">
      {loggedInOnMount ? null : (
        <GridItem colSpan={2}>
          <FormikQuestion
            hideErrMessage
            kind="email"
            placeholder="you@email.com"
            data-cy="subscribe-email"
            name="email"
            isDisabled={isDisabled || loggedIn}
          />
        </GridItem>
      )}
      <GridItem colSpan={1}>
        <FormikQuestion
          hideErrMessage
          kind="short_text"
          placeholder="First Name"
          data-cy="subscribe-first-name"
          name="firstName"
          isDisabled={isDisabled}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <FormControl>
          <FormikQuestion
            hideErrMessage
            kind="short_text"
            placeholder="Last Name"
            data-cy="subscribe-last-name"
            name="lastName"
            isDisabled={isDisabled}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={2}>
        <StyledInputStripeElement
          element={<CardElement />}
          forceRenderInvalidState={forceRenderInvalidState}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <PoweredByStripe />
      </GridItem>
      {hideOrderSummary ? null : (
        <GridItem colSpan={2}>
          <SubscribeModalOrderSummary />
        </GridItem>
      )}
    </Grid>
  );
}

/*
 * Abstracts payment-profile specific data away from the main form
 * for readability.
 */
function usePaymentProfileData() {
  const [initialHasPaymentMethod, setInitialHasPaymentMethod] = useState<boolean | undefined>();
  const { memberId, loggedIn } = useSession();
  const getPaymentProfileQuery = useGetPaymentProfileQuery(
    { id: memberId },
    { enabled: Boolean(memberId) }
  );
  const putPaymentProfileMutation = usePutPaymentProfileMutation({ id: memberId });
  const loggedInOnMount = useInitial(loggedIn);

  // Store the presence of the payment method on initial success so that we
  // don't respond to it in real-time and remove the CC section from the form
  // after the query re-fetches.
  // TODO: Seems like this should be possible using react-query natively.
  useEffect(() => {
    if (getPaymentProfileQuery.isSuccess && _.isUndefined(initialHasPaymentMethod)) {
      const {
        data: { attributes },
      } = getPaymentProfileQuery.data;
      setInitialHasPaymentMethod(Boolean(attributes.defaultPaymentMethod));
    }
  }, [getPaymentProfileQuery.isSuccess]);

  const {
    data: { attributes: { defaultPaymentMethod = '', paymentMethods = [] } = {} } = {},
  } = getPaymentProfileQuery.isSuccess ? getPaymentProfileQuery.data : {};

  const paymentMethod = paymentMethods.find(({ id }) => id === defaultPaymentMethod);

  return {
    getPaymentProfileQuery,
    putPaymentProfileMutation,
    initialHasPaymentMethod: initialHasPaymentMethod && loggedInOnMount,
    paymentMethod,
  };
}

function ExistingCreditCardDisplay({
  paymentMethod,
  isLoading = false,
}: {
  paymentMethod: PaymentMethod;
  isLoading?: boolean;
}) {
  return <PaymentDisplay {...paymentMethod} isLoading={isLoading} />;
}

function SubscribeFormErrorAlerts({
  postSubscriptionsMutation,
  putPaymentProfileMutation,
}: {
  postSubscriptionsMutation: UseMutationResult<PostSubscriptions.Response>;
  putPaymentProfileMutation: UseMutationResult<PutPaymentProfile.Response>;
}) {
  return (
    <>
      <Collapse
        in={postSubscriptionsMutation.isError}
        style={{
          // Collapse renders motion.div under the hood so we use
          // their preferred style API to override width.
          width: '100%',
        }}
      >
        <Alert status="error" mb={0} width="100%" mt={4}>
          <AlertIcon as={AlertCircleIcon} />
          Could not start your subscription.
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={postSubscriptionsMutation.reset}
          />
        </Alert>
      </Collapse>
      <Collapse
        in={putPaymentProfileMutation.isError}
        style={{
          // Collapse renders motion.div under the hood so we use
          // their preferred style API to override width.
          width: '100%',
        }}
      >
        <Alert status="error" mb={0} width="100%" mt={4}>
          <AlertIcon as={AlertCircleIcon} />
          {
            // TODO: It's not clear to me how to best type errors via react-query
            // so for now we just override.
            (putPaymentProfileMutation?.error as AxiosError<PutPaymentProfile.Error>)?.response.data
              .errors.card || 'Error processing your card.'
          }
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={putPaymentProfileMutation.reset}
          />
        </Alert>
      </Collapse>
    </>
  );
}

function SubmitButton({
  onInvalidSubmit,
  ...rest
}: Omit<ButtonProps, 'type' | 'onClick'> & { onInvalidSubmit: () => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formikContext = useFormikContext();
  const { isValid, values } = formikContext;

  useEffect(() => {
    if (isOpen) onClose();
  }, [values]);

  const handleInvalidSubmit = useCallback(() => {
    onOpen();
    onInvalidSubmit();
  }, []);

  return (
    <Box>
      <Button
        type={isValid ? 'submit' : 'button'}
        onClick={isValid ? undefined : handleInvalidSubmit}
        {...rest}
      />
      <Collapse in={isOpen}>
        <Alert status="error" mt={4}>
          <AlertIcon as={AlertCircleIcon} />
          <Text>Please complete all of the provided fields.</Text>
          <CloseButton position="absolute" right={4} top={4} onClick={onClose} />
        </Alert>
      </Collapse>
    </Box>
  );
}

type SubscribeFormSubmittedValuesContextValue = {
  submittedValues: Partial<SubscribeFormValues>;
  setSubmittedValues: (v: Partial<SubscribeFormValues>) => void;
};

const SubscribeFormSubmittedValuesContext = createContext<SubscribeFormSubmittedValuesContextValue>(
  {} as SubscribeFormSubmittedValuesContextValue
);

const SubscribeFormSubmittedValuesProvider = ({ children }: { children: React.ReactNode }) => {
  const [submittedValues, setSubmittedValues] = useState<Partial<SubscribeFormValues>>();
  return (
    <SubscribeFormSubmittedValuesContext.Provider value={{ submittedValues, setSubmittedValues }}>
      {children}
    </SubscribeFormSubmittedValuesContext.Provider>
  );
};

const useSubscribeFormSubmittedValues = () => {
  const { submittedValues, setSubmittedValues } = useContext(SubscribeFormSubmittedValuesContext);
  return { submittedValues, setSubmittedValues };
};

function SubscribeFormContents({
  elements,
  isSubmitting,
}: Pick<ReactStripeElements.InjectedStripeProps, 'elements'> & {
  isSubmitting: boolean;
}) {
  const { loggedIn } = useSession();
  const loggedInOnMount = useInitial(loggedIn);
  const { submitButtonLabel } = useOrderSummary();
  const { setTouched, touched, setFieldValue, values, errors } = useFormikContext<
    SubscribeFormValues
  >();
  const {
    getPaymentProfileQuery,
    initialHasPaymentMethod,
    paymentMethod,
  } = usePaymentProfileData();

  const handleInvalidSubmit = () => {
    setTouched(
      Object.keys(values).reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {}
      )
    );
  };

  useEffect(() => {
    const cardElement = elements.getElement('card');
    cardElement.on('blur', () => {
      if (!touched.stripeCardElComplete) {
        setTouched({
          ...touched,
          stripeCardElComplete: true,
        });
      }
    });
    cardElement.on('change', ({ complete }) => {
      setFieldValue('stripeCardElComplete', complete);
    });
    // Stripe does not expose a `.off()` method, so we can't clean this up
    // which feels not-so-great. My *guess* is that they manage the cleanup
    // themselves, but tbd.
  }, []);

  return (
    <motion.div
      layout
      initial={false}
      transition={{ type: 'tween', duration: 0.125, delay: 0 }}
      style={{ width: '100%' }}
    >
      <Form>
        <VStack mb={4} spacing={4} alignItems="flex-start" width="full">
          <Text textStyle="earmark">Payment Details</Text>

          {initialHasPaymentMethod ? (
            <ExistingCreditCardDisplay
              paymentMethod={paymentMethod}
              isLoading={getPaymentProfileQuery.isLoading}
            />
          ) : (
            <CreditCardFormFields
              forceRenderInvalidState={Boolean(
                touched.stripeCardElComplete && errors.stripeCardElComplete
              )}
              isDisabled={isSubmitting}
            />
          )}
        </VStack>

        <Box my={6}>
          <FormikQuestion
            hideErrMessage
            data-cy="subscribe-agree-to-terms"
            name="agreeToTerms"
            kind="checkbox"
            options={[
              {
                label: "I agree to Coa's Terms of Service and Privacy Policy",
              },
            ]}
          />
        </Box>

        <SubmitButton
          width="100%"
          variant="solid"
          colorScheme="red"
          data-cy="subscribe-submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onInvalidSubmit={handleInvalidSubmit}
        >
          {submitButtonLabel}
        </SubmitButton>
        {!loggedInOnMount ? (
          <Text textAlign="center" mt={8} fontSize="sm">
            Not ready to commit to a free trial?
            <br />
            Try out some of our{' '}
            <Link as={RouterLink} to={getRouterUrl.try.pushups()} variant="underline">
              free classes
            </Link>
            .
          </Text>
        ) : null}
      </Form>
    </motion.div>
  );
}

function SubscribeFormInner({ stripe, elements }: ReactStripeElements.InjectedStripeProps) {
  const { setSubmittedValues, submittedValues } = useSubscribeFormSubmittedValues();
  const [userExists, setUserExists] = useState<boolean>(false);
  const [isSubmittingStripe, setSubmittingStripe] = useState<boolean>(false);
  const {
    getSubscriptionOrderSummaryQuery,
    subscriptionQuantity,
    postSubscriptionsMutation,
  } = useOrderSummary();
  const {
    loggedIn,
    createRegistration,
    registering,
    errors: createRegistrationErrors,
  } = useCreateRegistration();
  const { data: orderSummary = {} } = getSubscriptionOrderSummaryQuery;
  const couponId = orderSummary?.coupon?.id;
  const { safeAnalyticsClient } = useAnalytics();

  const { name } = useSession();
  const loggedInOnMount = useInitial(loggedIn);

  const { putPaymentProfileMutation, initialHasPaymentMethod } = usePaymentProfileData();

  useEffect(() => {
    /*
     * The errors for createRegistration are overwritten in the redux
     * store by createSession. As such, for the sake of simplicity,
     * As soon as the user receives an "Existing member" error, we
     * assume they'll want to login and manage that state manually.
     */
    if (getUserExists(createRegistrationErrors)) {
      setUserExists(true);
    }
  }, [createRegistrationErrors]);

  const isSubmitting =
    putPaymentProfileMutation.isLoading ||
    postSubscriptionsMutation.isLoading ||
    registering ||
    isSubmittingStripe ||
    // On success the page will redirect so we can maintain the loading state until
    // the redirect has resolved.
    postSubscriptionsMutation.isSuccess;

  const handleSubmitPaymentMethod = async ({ firstName, lastName }): Promise<void> => {
    if (!initialHasPaymentMethod) {
      setSubmittingStripe(true);
      let stripeRes = {} as stripe.PaymentMethodResponse;
      const card = elements.getElement('card');
      try {
        stripeRes = await stripe.createPaymentMethod({
          type: 'card',
          card,
          billing_details: { name: [firstName, lastName].join(' ') },
        });
      } catch (err) {
        safeAnalyticsClient.track('Submitted Credit Card Info');
        card.update({ disabled: false });
      }

      setSubmittingStripe(false);

      try {
        await putPaymentProfileMutation.mutateAsync({
          paymentMethodId: stripeRes.paymentMethod.id,
        });
        safeAnalyticsClient.track('Submitted Credit Card Info');
      } catch (err) {
        safeAnalyticsClient.track('Submitted Bad Credit Card', { message: err.message });
        return;
      }
    }
    // TODO: Should manage state for CC inputs to reflect disabled
    // state for submission.
    const postSubscriptionsBody: PostSubscriptions.Request['body'] = {
      quantity: subscriptionQuantity,
    };

    if (couponId) {
      postSubscriptionsBody.coupon = couponId;
    }
    await postSubscriptionsMutation.mutateAsync(postSubscriptionsBody);
    safeAnalyticsClient.track('Purchased Subscription');
  };

  useEffect(() => {
    if (!loggedInOnMount && loggedIn && submittedValues) {
      const { firstName, lastName } = submittedValues;
      handleSubmitPaymentMethod({ firstName, lastName });
    }
  }, [loggedIn]);

  return (
    <>
      <ExistingEmailPasswordDialog
        email={submittedValues?.email}
        isOpen={userExists && !loggedIn}
        handleClose={() => {
          // TODO: Empty out form state too.
          setUserExists(false);
        }}
      />
      <Formik
        initialValues={getSubscribeFormInitialValues({
          name,
          hasPaymentMethod: initialHasPaymentMethod,
          loggedIn: loggedInOnMount,
        })}
        isInitialValid={false}
        validationSchema={getSubscribeFormValidationSchema({
          hasPaymentMethod: initialHasPaymentMethod,
          loggedIn: loggedInOnMount,
        })}
        onSubmit={async ({ firstName, lastName, email }): Promise<void> => {
          const card = elements.getElement('card');
          /*
           * The Card element only exists when there isn't an existing payment profile,
           * so this safety check is to ensure the card exists before we perform any
           * actions on it.
           */
          if (card) {
            card.update({ disabled: true });
          }
          /*
           * This isn't great, but because we don't have access to submtited
           * answers outside of the Formik context, we need to store them so that
           * we can use them, specifically inside handleSubmitPaymentMethod
           * or registration inside the ExistingEmailPasswordDialog.
           */
          setSubmittedValues({ firstName, lastName, email });
          if (!loggedInOnMount) {
            const fullName = [firstName, lastName].join(' ');
            createRegistration({ email, name: fullName, role: 'client' });
            /*
             * This is EXTREMELY hacky, but we can't await createRegistration
             * as it dispatches actions that get handled by the redux store.
             * Instead we fire a side-effect upon a change in loggedIn state
             * above.
             *
             * Preferred refactor steps:
             *    - move createRegistration out of redux and into react-query
             *    - replace its usage with an async mutation
             *    - await the mutation here, allowing for the removal of the reactive
             *      callback above (therefore providing access to the form data).
             *
             * In the meantime we leave it like this, since we're shipping the
             * account-create / card info entry as an experiment anyhow. Who knows
             * maybe all of this gets thrown away.
             */
          } else {
            handleSubmitPaymentMethod({ firstName, lastName });
          }
        }}
      >
        <>
          <SubscribeFormContents elements={elements} isSubmitting={isSubmitting} />
          <SubscribeFormErrorAlerts
            {...{
              putPaymentProfileMutation,
              postSubscriptionsMutation,
            }}
          />
        </>
      </Formik>
    </>
  );
}

const StripeInjectedSubscribeFormInner = injectStripe(SubscribeFormInner);

export const SubscribeForm = () => (
  <StripeElementsFontProvider>
    <SubscribeFormSubmittedValuesProvider>
      <StripeInjectedSubscribeFormInner />
    </SubscribeFormSubmittedValuesProvider>
  </StripeElementsFontProvider>
);
