import { Input, useTheme } from '@chakra-ui/react';
import React, { useState } from 'react';

function useStripeStyle() {
  const theme = useTheme();

  /*
   * Styles here are defined using Stripe's "Style" object.
   *
   * @see https://stripe.com/docs/js/appendix/style
   *
   * We can't use CSS variables in here as Elements are rendered
   * inside an iframe, so we pull the values directly from the
   * core theme.
   */
  const stripeElementsStyleConfig = {
    base: {
      color: theme.colors.green['900'],
      fontFamily: `${theme.fonts.body}, sans-serif`,
      WebkitFontSmoothing: 'antialiased',

      // Stripe Elements assumes use of px and not rem.
      // fontSize: theme.fontSizes.md,
      fontSize: '16px',

      ':disabled': {
        color: theme.colors.grayAlpha['400'],
      },
      '::placeholder': {
        color: theme.colors.warm['300'],
      },
    },
    invalid: {
      color: theme.colors.red['400'],
      iconColor: theme.colors.red['400'],
    },
  };
  return { stripeElementsStyleConfig };
}

/*
 * The Card Element is sorta tricky in that it renders 3 inputs inside a parent <div>.
 * Visually, this <div> appears like one of our normal text inputs. Here, we style it
 * as such, taking extra measures to simulate the focus state.
 */
export function StyledInputStripeElement({
  element,
  forceRenderInvalidState,
}: {
  element: React.ReactElement;
  forceRenderInvalidState?: boolean;
}) {
  const [focus, setFocus] = useState(false);
  const { stripeElementsStyleConfig } = useStripeStyle();

  const handleBlur = (e: unknown) => {
    setFocus(false);
    if (element.props.onBlur) element.props.onBlur(e);
  };
  const handleFocus = (e: unknown) => {
    setFocus(true);
    if (element.props.onFocus) element.props.onFocus(e);
  };

  return (
    <Input
      // Using <Input> is the easiest way for us to inherit styles
      // from the themed Input, but we render as a <div> because Elements
      // renders the <input> itself in the actual Element.
      as="div"
      variant="coa-main"
      // Because Elements is rendering the actual <input>, we have to
      // re-center it inside the parent.
      display="flex"
      flexDirection="column"
      justifyContent="center"
      // Because Elements is rendering the actual <input>, focus states
      // don't appear like they do on the rest of our forms, so here we
      // replicate that.
      // TODO: This value should be pulled from the theme.
      boxShadow={focus ? 'rgb(66 153 225 / 60%) 0px 0px 0px 3px' : undefined}
      isInvalid={forceRenderInvalidState}

      // Note that we don't manage disabled state here explicitly but
      // instead rely on the caller to use `card.update({ disabled: true })`
    >
      {React.cloneElement(element, {
        onFocus: handleFocus,
        onBlur: handleBlur,
        style: stripeElementsStyleConfig,
      })}
    </Input>
  );
}
