import {
  Box,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Tag,
  TagLabel,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { DumbFormErrorMessage } from '../../../../components/ChakraDumbFormErrorMessage';
import { AlertCircleIcon } from '../../../../components/Icons';

type FormikQuestionKind =
  | 'short_text'
  | 'text'
  | 'checkbox'
  | 'select'
  | 'date'
  | 'radio'
  | 'email'
  | 'password';

/*
 * The react/no-unused-prop-types rule is creating false positives
 * here, as these types are CLEARLY being used below. For now we
 * just disable.
 *
 * TODO: Don't disable this if you have the time please. Thanks.
 */
/* eslint-disable react/no-unused-prop-types */
type FormikQuestionFieldProps = {
  name: string;
  kind: FormikQuestionKind;
  question?: string;
  placeholder?: string;
  options?: Array<{ label: string; value?: string }>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  hideErrMessage?: boolean;
};
/* eslint-enable react/no-unused-prop-types */

const getFieldTypeForKind = (kind: FormikQuestionFieldProps['kind']) => {
  if (['date', 'email', 'password'].includes(kind)) return kind;
  return 'text';
};
const TextField = ({
  name,
  placeholder,
  kind,
  isInvalid,
  isDisabled,
}: FormikQuestionFieldProps) => {
  const { values } = useFormikContext();
  return (
    <Field
      name={name}
      variant="coa-main"
      value={values[name]}
      as={kind === 'text' ? Textarea : Input}
      type={getFieldTypeForKind(kind)}
      placeholder={placeholder}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      data-cy={`form-field-text-${_.kebabCase(name)}`}
    />
  );
};

const SelectField = ({
  placeholder,
  options,
  name,
  isInvalid,
  isDisabled,
}: FormikQuestionFieldProps) => {
  const { values } = useFormikContext();

  return (
    <Field
      as={Select}
      name={name}
      variant="coa-main"
      value={values[name]}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      placeholder={placeholder}
      data-cy={`form-field-select-${_.kebabCase(name)}`}
    >
      {options.map(({ label }) => (
        <option key={label} value={label}>
          {label}
        </option>
      ))}
    </Field>
  );
};

export const RadioField = ({ options, name, isInvalid, isDisabled }: FormikQuestionFieldProps) => {
  const { values } = useFormikContext();
  return (
    <RadioGroup variant="coa-main" value={values[name]} width="100%">
      <VStack align="left" width="stretch" justifyContent="start">
        {options.map(({ label, value }) => (
          <Field
            key={label}
            type="radio"
            as={Radio}
            name={name}
            value={value || label}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            data-cy={`form-field-radio-${_.kebabCase(name)}-${_.kebabCase(value || label)}`}
          >
            {label}
          </Field>
        ))}
      </VStack>
    </RadioGroup>
  );
};

const CheckboxField = ({ options, name, isInvalid, isDisabled }: FormikQuestionFieldProps) => {
  const { values } = useFormikContext();
  return (
    <CheckboxGroup variant="coa-main" value={values[name]}>
      <VStack align="left" width="stretch" justifyContent="start">
        {options.map(({ label, value }) => (
          <Field
            key={label}
            type="checkbox"
            as={Checkbox}
            name={name}
            value={value || label}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            data-cy={`form-field-checkbox-${_.kebabCase(name)}-${_.kebabCase(value || label)}`}
          >
            {label}
          </Field>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};

export const FormikQuestion = (questionProps: FormikQuestionFieldProps) => {
  const { question, name, hideErrMessage } = questionProps;
  const { errors, touched } = useFormikContext();

  const errorMessage = errors[name];
  const isInvalid = errorMessage && touched[name];
  const fieldProps = { ...questionProps, isInvalid };

  return (
    <VStack as={FormControl} spacing={2} isInvalid={isInvalid}>
      {question ? (
        <FormLabel width="100%" margin={0}>
          {question}
        </FormLabel>
      ) : null}
      {(() => {
        switch (questionProps.kind) {
          case 'text':
          case 'date':
          case 'short_text':
          case 'email':
          case 'password':
            return <TextField {...fieldProps} />;
          case 'select':
            return <SelectField {...fieldProps} />;
          case 'radio':
            return <RadioField {...fieldProps} />;
          case 'checkbox':
            return <CheckboxField {...fieldProps} />;
          default:
            throw Error('Unable to find component for field');
        }
      })()}
      {hideErrMessage ? null : (
        <Box
          width="100%"
          // We need a surrounding Box so that the Collapse has a block-context
          // in which to occupy a full-width (since we can't affect the width
          // of the Collapse directly).
        >
          <Collapse in={isInvalid} unmountOnExit>
            <DumbFormErrorMessage width="100%">
              <AlertCircleIcon mr={1} /> {errors[name]}
            </DumbFormErrorMessage>
          </Collapse>
        </Box>
      )}
    </VStack>
  );
};

export const NumberedFormikQuestion = ({
  index,
  ...questionProps
}: FormikQuestionFieldProps & { index: number }) => (
  <Flex width="100%">
    <Tag
      size="md"
      boxSize="22px"
      borderRadius="full"
      variant="solid"
      bgColor="warm.50"
      justifyContent="center"
      mr={4}
    >
      <TagLabel fontSize="xs" fontWeight="bold" color="warm.500">
        {index + 1}
      </TagLabel>
    </Tag>
    <Box flexGrow={999}>
      <FormikQuestion {...questionProps} />
    </Box>
  </Flex>
);
