/*
 * We use a custom path-resolving function here since the 'path' package's
 * path.resolve() method has some underlying code that does not play well
 * with our mobile app (process.cwd() is not a function).
 */
export const resolvePath = (...pathSegments: string[]) => {
  const pathSegmentsWithoutSlashes = pathSegments.map((segment) =>
    segment.replace(/^\//, '').replace(/\/$/, '')
  );
  const joinedPathSegment = pathSegmentsWithoutSlashes.join('/');
  const resolvedPath = joinedPathSegment.startsWith('/')
    ? joinedPathSegment
    : `/${joinedPathSegment}`;

  return resolvedPath;
};
