import { AdminSerializedWorkout } from '../../serializers/admin/AdminSerializedWorkout';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from './cms';

const workoutsParams = {
  basePath: '/admin/workouts',
  auth: true,
};

export const useAdminIndexWorkoutsQuery = generateUseIndexQuery<
  AdminSerializedWorkout,
  AdminCmsIndexFilterParams<AdminSerializedWorkout>
>(workoutsParams);
export const useAdminShowWorkoutQuery = generateUseShowQuery<AdminSerializedWorkout>(
  workoutsParams
);
export const useAdminCreateWorkoutMutation = generateUseCreateMutation<AdminSerializedWorkout>(
  workoutsParams
);
export const useAdminUpdateWorkoutMutation = generateUseUpdateMutation<AdminSerializedWorkout>(
  workoutsParams
);
