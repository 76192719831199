import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { BsCircle } from 'react-icons/bs';
import PaymentIcon from 'react-payment-icons';
import { Elements } from 'react-stripe-elements';
import InjectedCreditCardForm from './CreditCardForm';
import { AlertCircleIcon, CheckCircleIcon, TrashIcon } from './Icons';

function SetupPaymentProfile({
  savePaymentMethod,
  destroyPaymentMethod,
  setPaymentMethodAsDefault,
  paymentMethods,
  defaultPaymentMethod,
  showFormIfNeccessary,
  showSaveForLater,
  cta,
  disabled,
  errors,
}) {
  const [showingForm, setShowingForm] = React.useState(
    !!showFormIfNeccessary && paymentMethods.length === 0
  );
  const [error, setError] = React.useState(null);
  const [saveForLater, setSaveForLater] = React.useState(true);
  const fonts = [
    {
      family: 'Founders',
      src: 'url(https://coa-fonts.s3-us-west-1.amazonaws.com/FoundersGroteskWeb-Regular.woff)',
      weight: 'bold',
    },
  ];
  const hideFormAndSavePaymentMethod = (data) => {
    setShowingForm(false);
    savePaymentMethod(data, saveForLater);
  };

  return (
    <Stack width="stretch" borderRadius="4px">
      <Box>
        {(!showFormIfNeccessary || paymentMethods.length > 0) && (
          <Flex>
            <Text textStyle="heading">PAYMENT METHOD</Text>

            <Spacer />
            {!showingForm && (
              <Link onClick={() => setShowingForm(true)}>
                {paymentMethods.length > 0 ? 'New Card' : 'Edit'}
              </Link>
            )}
            {showingForm && <Link onClick={() => setShowingForm(false)}>Close</Link>}
          </Flex>
        )}
        {paymentMethods.length > 0 && <Divider />}
        {paymentMethods.map((paymentMethod) => {
          const isDefault = defaultPaymentMethod === paymentMethod.id;
          const { id, last_four, expiration_month, expiration_year } = paymentMethod;
          return (
            <Flex my={2} align="center" justify="space-between" key={id}>
              <HStack>
                <PaymentIcon style={{ maxWidth: 80 }} id="visa" />
                <Stack>
                  <Text>****_****_****_{last_four}</Text>
                  <Flex align="center" justify="space-between">
                    <Text fontSize="sm">
                      Expires {expiration_month}/{expiration_year}
                    </Text>
                  </Flex>
                </Stack>
              </HStack>

              <HStack>
                {isDefault ? (
                  <HStack mx="12px">
                    <Text>Default Card</Text>
                    <CheckCircleIcon />
                  </HStack>
                ) : (
                  <IconButton
                    variant="ghost"
                    onClick={() => setPaymentMethodAsDefault(paymentMethod)}
                    icon={<BsCircle />}
                  />
                )}
                <IconButton
                  variant="ghost"
                  onClick={() => destroyPaymentMethod(paymentMethod)}
                  icon={<TrashIcon />}
                />
              </HStack>
            </Flex>
          );
        })}
      </Box>
      {showingForm && (
        <Box>
          {!!error && (
            <HStack my={4} p={4} bgColor="red.400" borderRadius="4px">
              <AlertCircleIcon w={5} h={5} />
              <Text width="stretch" fontSize="lg">
                <b>Error saving your card:</b> {error.message}
              </Text>
            </HStack>
          )}
          <Elements fonts={fonts}>
            <InjectedCreditCardForm
              savePaymentMethod={hideFormAndSavePaymentMethod}
              cta={cta}
              showSaveForLater={showSaveForLater}
              setSaveForLater={setSaveForLater}
              disabled={disabled}
              onError={setError}
            />
          </Elements>
        </Box>
      )}
    </Stack>
  );
}

SetupPaymentProfile.propTypes = {
  savePaymentMethod: PropTypes.func.isRequired,
  setPaymentMethodAsDefault: PropTypes.func.isRequired,
  destroyPaymentMethod: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  defaultPaymentMethod: PropTypes.string,
  showFormIfNeccessary: PropTypes.bool,
  showSaveForLater: PropTypes.bool,
  cta: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
};

export default SetupPaymentProfile;
