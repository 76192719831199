import { randomId } from '@coa/stdlib/random';
import { Workshop, WorkshopFormQuestion, WorkshopOccurrence } from './types';

export const createPlaceholderTherapist = () =>
  ({
    name: 'Dr. Coa Therapist',
    email: 'therapist@coa.com',
    imageUrl: '',
    id: randomId(),

    // TODO: This is an artifact from before we deserialized JSON-API responses.
    // We should consider cleaning it up.
    type: 'therapist',
  } as const);

export const createPlaceholderWorkshopOccurrence = (): WorkshopOccurrence =>
  ({
    title: 'Class Title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu mauris sed lorem consequat viverra blandit a arcu. Maecenas quis varius magna. Nunc non consectetur nisi. Duis rutrum scelerisque urna, vulputate volutpat arcu viverra ut. Ut feugiat tempus efficitur. Etiam sit amet posuere elit. Nam enim sapien, vulputate id consectetur et, volutpat a nunc. Quisque pulvinar porta bibendum. Mauris quis laoreet eros, at egestas dui.',
    startsAt: '2021-12-23T13:52:04.089Z',
    id: randomId(),
    durationInMinutes: 90,
    exerciseId: '-1',
    workshopId: '-1',
    position: 0,
    maxPosition: 1,
    skill: 'empathy',
    // TODO: This is an artifact from before we deserialized JSON-API responses.
    // We should consider cleaning it up.
    type: 'workshop_occurrence',
  } as const);

export const createPlaceholderWorkshop = ({
  kind = 'series',
}: Pick<Workshop, 'kind'>): Omit<Workshop, 'workshopOccurrences'> =>
  ({
    access: 'members',
    approvedEnrolleeCount: 1,
    autoApprove: false,
    category: 'emotional_fitness_intro',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu mauris sed lorem consequat viverra blandit a arcu. Maecenas quis varius magna. Nunc non consectetur nisi. Duis rutrum scelerisque urna, vulputate volutpat arcu viverra ut. Ut feugiat tempus efficitur. Etiam sit amet posuere elit. Nam enim sapien, vulputate id consectetur et, volutpat a nunc. Quisque pulvinar porta bibendum. Mauris quis laoreet eros, at egestas dui.',
    featured: false,
    isB2B: false,
    id: randomId(),
    imageUrl: '',
    kind,
    joinUrl: 'https://joincoa.com',
    hasSoldOutSiblings: false,
    marketListingLabel: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    minParticipants: 0,
    maxParticipants: 100,
    priceCents: 10000,
    questions: [] as WorkshopFormQuestion[],
    seriesTimingLabel: 'Tuesdays',
    shortBlurb:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu mauris sed lorem.',
    soldOut: false,
    status: 'visible',
    title: 'Workshop Title',
    requiresMembership: true,

    // TODO: This is an artifact from before we deserialized JSON-API responses.
    // We should consider cleaning it up.
    type: 'workshop',
  } as const);

export const createPlaceholderWorkshopMeta = () =>
  ({
    joinUrl: 'https://zoom.us',
    enrollmentStatus: null,
    enrollmentDropInIds: null,
  } as const);
