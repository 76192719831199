import { CLIENTS_FAILURE, CLIENTS_REQUEST, CLIENTS_SUCCESS } from '../actions/clients';

const DEFAULT_STATE = {
  gettingClients: false,
  existingClients: [],
};

export function clients(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CLIENTS_REQUEST: {
      return { ...state, gettingClients: true };
    }
    case CLIENTS_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, existingClients: data, gettingClients: false };
    }
    case CLIENTS_FAILURE: {
      return { ...state, existingClients: [], gettingClients: false };
    }
    default:
      return state;
  }
}
