import {
  DROP_IN_WORKSHOP_OCCURRENCES_LIST_FAILURE,
  DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST,
  DROP_IN_WORKSHOP_OCCURRENCES_LIST_SUCCESS,
} from '../actions/workshopOccurrences';

const DEFAULT_STATE = {
  gettingDropInWorkshopOccurrences: false,
  dropInWorkshopOccurrences: [],
  dropInWorkshops: [],
};

export function workshopOccurrences(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST: {
      return { ...state, gettingDropInWorkshopOccurrences: true };
    }
    case DROP_IN_WORKSHOP_OCCURRENCES_LIST_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        dropInWorkshopOccurrences: data,
        dropInWorkshops: included,
        gettingDropInWorkshopOccurrences: false,
      };
    }
    case DROP_IN_WORKSHOP_OCCURRENCES_LIST_FAILURE: {
      return { ...state, dropInWorkshopOccurrences: [], gettingDropInWorkshopOccurrences: false };
    }
    default:
      return state;
  }
}
