import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  HeadingProps,
  VStack,
} from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { EditIcon } from '../../../components/Icons';
import { useGetTeamQuery } from '../../../resources/teams';
import { AdminTeamNav, SubTeamsTable, TeamsBreadcrumbs } from '../../admin/teams/AdminTeamView';
import { CreateSubTeamButton } from '../../admin/teams/components/CreateTeamModal';
import { EditTeamNameButton } from '../../admin/teams/components/EditTeamNameModal';
import { TeamMembersTable } from '../../admin/teams/components/TeamMembersTable';
import { TeamWorkshopsTable } from '../../admin/teams/components/TeamWorkshopsTable';
import { useTeamAccessKindRedirectToTable } from './useTeamAccessKindPermissions';

// This is almost the same as AdminTeamHeading, but now that we're building out
// different experiences for Coa-employee admins vs Team Champion 'admins',
// we will be creating separate components for Admin (coa internal) vs Non-Coa admins
export const TeamHeading = ({ id, children, ...rest }: HeadingProps & { id: string }) => {
  const history = useHistory();
  const teamQuery = useGetTeamQuery({ id });
  if (teamQuery.isLoading) return null;
  const { data: { attributes: { title = null, accessKind = 'a_la_carte' } } = { attributes: {} } } =
    teamQuery.data || {};

  return (
    <Flex w="full" justifyContent="space-between">
      <Heading {...rest}>
        <span data-cy="team-heading">{title}</span>
        <EditTeamNameButton id={id} icon={<EditIcon />} aria-label="Edit team name" ml={4} />
      </Heading>
      {accessKind === 'a_la_carte' ? (
        <Button
          variant="secondary"
          onClick={() => history.push(`/team-membership/${id}/payment`)}
          ml="2"
        >
          Purchase Membership
        </Button>
      ) : null}
    </Flex>
  );
};

export function AccountTeamView(): JSX.Element {
  const { id } = useRouteParams<{ id: string }>();

  // redirects to appropriate table route
  useTeamAccessKindRedirectToTable();

  return (
    <Container maxW="container.xl">
      <VStack spacing={8} alignItems="flex-start">
        <TeamsBreadcrumbs id={id} />
        <TeamHeading id={id} />
        <AdminTeamNav id={id} />
      </VStack>
      <Switch>
        <Route
          path="/account/teams/:id/classes"
          render={(props) => <Redirect to={`/account/teams/${props.match.params.id}`} />}
        />
        <Route path="/account/teams/:id/members">
          <TeamMembersTable id={id} />
        </Route>
        <Route path="/account/teams/:id/teams">
          <ButtonGroup justifyContent="flex-end" width="stretch">
            <CreateSubTeamButton id={id} variant="secondary">
              Add Team
            </CreateSubTeamButton>
          </ButtonGroup>
          <SubTeamsTable id={id} />
        </Route>
        <Route path="/account/teams/:id">
          <TeamWorkshopsTable id={id} />
        </Route>
      </Switch>
    </Container>
  );
}
