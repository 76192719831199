import { Box, BoxProps, Flex, Heading, Image, Text, TextProps, VStack } from '@chakra-ui/react';
import React from 'react';
import { Therapist } from '../../resources/therapists';
import { getIsSeriesLike, Workshop, WorkshopOccurrence } from '../../resources/workshops';
import { boxShadowStyles } from '../../themes/coaTheme';
import { ClassListingLabel } from '../atoms/ClassListingLabel';
import { MonitorIcon, SeriesIcon } from '../Icons';

export const classBackgroundColor = ({
  type,
  kind,
}: {
  type: Workshop['type'] | WorkshopOccurrence['type'];
  kind: Workshop['kind'];
}) => {
  if (type === 'workshop_occurrence') {
    return 'green.200';
  }
  if (kind === 'intro') {
    return 'orange.200';
  }
  if (kind === 'q_and_a') {
    return 'gray.600';
  }
  if (getIsSeriesLike(kind)) {
    return 'green.600';
  }
};

export const classCopyColor = ({
  type,
  kind,
}: {
  type: Workshop['type'] | WorkshopOccurrence['type'];
  kind: Workshop['kind'];
}) => {
  if (kind === 'intro' || type === 'workshop_occurrence') {
    return 'gray.900';
  }
  return 'white';
};

const ClassCardWrapper = ({
  children,
  type,
  kind,
}: {
  children: React.ReactNode;
  type: Workshop['type'] | WorkshopOccurrence['type'];
  kind: Workshop['kind'];
}) => (
  <Box backgroundColor={classBackgroundColor({ type, kind })} position="relative" flexGrow={1}>
    {getIsSeriesLike(kind) ? (
      // PE-1897 - Diagonal design block we use for the Series Card design
      <Box
        backgroundColor="green.600"
        position="absolute"
        zIndex={-1}
        top={{ base: '-10%', md: '-12%' }}
        width="100%"
        height="50%"
        transform={{ base: 'skewY(-6deg)', lg: 'skewY(-10deg)' }}
      />
    ) : null}
    <Box
      display="flex"
      flexDir="column"
      height="100%"
      justifyContent="space-between"
      p={{ base: 4, md: 6 }}
      color={classCopyColor({ kind, type })}
    >
      {children}
    </Box>
  </Box>
);

export const ClassCardKind = ({
  type,
  kind,
  numberOfClasses,
}: {
  type: Workshop['type'] | WorkshopOccurrence['type'];
  kind: Workshop['kind'];
  numberOfClasses: number;
}) => {
  let classType = 'Live Class';
  if (type === 'workshop_occurrence') {
    classType = 'Live Drop-In Class';
  } else {
    if (kind === 'intro') {
      classType = 'Live Intro Class';
    }
    if (kind === 'q_and_a') {
      classType = 'Live Q&A Class';
    }
    if (getIsSeriesLike(kind)) {
      classType = `${numberOfClasses}-Class Series`;
    }
  }
  return (
    <Flex alignItems="center" whiteSpace="nowrap">
      {getIsSeriesLike(kind) ? (
        <SeriesIcon boxSize={{ base: 4, md: 5 }} mr={2} />
      ) : (
        <MonitorIcon boxSize={{ base: 4, md: 5 }} mr={2} />
      )}
      <Text fontWeight="bold" fontSize="12px" textTransform="uppercase" letterSpacing="0.5px">
        {classType}
      </Text>
    </Flex>
  );
};

const ClassCardTitle = ({ children, ...rest }: TextProps) => (
  <Heading fontSize="20px" fontWeight="medium" {...rest}>
    {children}
  </Heading>
);

const ClassCardLabel = ({ children, ...rest }: TextProps) => (
  <Text fontSize="sm" lineHeight="1em" fontWeight="semibold" letterSpacing="0.5px" {...rest}>
    {children}
  </Text>
);

const ClassCardInstructorName = ({ children, ...rest }: TextProps) => (
  <Text fontSize="12px" fontWeight="bold" textTransform="uppercase" {...rest}>
    {children}
  </Text>
);

const ClassCardTiming = ({ children, ...rest }: TextProps) => (
  <Text fontSize="xs" {...rest}>
    {children}
  </Text>
);

type ClassCardLabelProps =
  | {
      showClassLabel: boolean;
      workshopAccess: string;
    }
  | {
      showClassLabel?: boolean;
      workshopAccess?: string;
    };

type ClassCardProps = {
  marketListingLabel: Workshop['marketListingLabel'];
  title: Workshop['title'];
  timingLabel: string;
  instructorName: Therapist['name'];
  src: Workshop['imageUrl'];
  numberOfClasses?: number;
  kind?: Workshop['kind'];
  type?: Workshop['type'] | WorkshopOccurrence['type'];
} & ClassCardLabelProps &
  BoxProps;

export const ClassCard = ({
  marketListingLabel,
  title,
  timingLabel,
  instructorName,
  src,
  numberOfClasses,
  kind,
  type,
  showClassLabel,
  workshopAccess,
  ...rest
}: ClassCardProps) => (
  <Box
    display="flex"
    height="100%"
    borderRadius="lg"
    flexDirection="column"
    overflow="hidden"
    position="relative"
    {...boxShadowStyles}
    {...rest}
  >
    <Box minH="200px" w="100%" position="relative">
      <Image
        objectFit="cover"
        objectPosition="top"
        position="absolute"
        h="100%"
        w="100%"
        src={src}
      />
    </Box>
    <ClassCardWrapper type={type} kind={kind}>
      <VStack spacing={5} alignItems="flex-start">
        <ClassCardKind type={type} kind={kind} numberOfClasses={numberOfClasses} />
        <VStack spacing={2} alignItems="flex-start">
          <ClassCardLabel>{marketListingLabel}</ClassCardLabel>
          <ClassCardTitle>{title}</ClassCardTitle>
          <ClassCardInstructorName>{instructorName}</ClassCardInstructorName>
        </VStack>
      </VStack>
      <ClassCardTiming marginTop={5}>{timingLabel}</ClassCardTiming>
    </ClassCardWrapper>
    {showClassLabel && workshopAccess ? (
      <ClassListingLabel access={workshopAccess} right={4} />
    ) : null}
  </Box>
);
