import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  EyeIcon,
  MessageSquareIcon,
  MusicIcon,
  SearchIcon,
  TrendingUpIcon,
  UmbrellaIcon,
  UsersIcon,
} from '../../../../components/Icons';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';

const boxStyles = {
  display: 'flex',
  height: '100%',
  position: 'relative',
  boxShadow: 'lg',
  borderRadius: 'lg',
  color: 'white',
  padding: '32px',
} as React.CSSProperties;

const iconStyles = {
  position: 'absolute',
  bottom: '32px',
  right: '32px',
  width: '32px',
  height: 'auto',
  color: 'whiteAlpha.800',
} as React.CSSProperties;

export const BrowseByTopicStaticView = () => (
  <Grid templateColumns="repeat(12, 1fr)" templateRows="repeat(1, 1fr)" autoRows="1fr" gap={8}>
    <GridItem colSpan={{ base: 12, md: 3 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=self_awareness"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="evergreen.200" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Self Awareness
          </Heading>
          <SearchIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 3 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=play"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="evergreen.300" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Play
          </Heading>
          <MusicIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 3 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=curiosity"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="gray.600" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Curiosity
          </Heading>
          <EyeIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 3 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=empathy"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="evergreen.100" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Empathy
          </Heading>
          <UsersIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 4 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=resilience"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="evergreen.400" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Resilience
          </Heading>
          <TrendingUpIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 4 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=communication"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="gray.700" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Communication
          </Heading>
          <MessageSquareIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>

    <GridItem colSpan={{ base: 12, md: 4 }}>
      <TransformUpLink
        as={RouterLink}
        to="/classes/drop-ins?skills=mindfulness"
        height="100%"
        width="100%"
      >
        <Box backgroundColor="evergreen.200" sx={boxStyles}>
          <Heading display="flex" fontSize={{ base: 'lg', lg: '2xl' }} pb={14}>
            Mindfulness
          </Heading>
          <UmbrellaIcon style={iconStyles} />
        </Box>
      </TransformUpLink>
    </GridItem>
  </Grid>
);
