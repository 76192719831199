import _ from 'lodash';
import {
  createPlaceholderTherapist,
  createPlaceholderWorkshop,
  createPlaceholderWorkshopMeta,
  createPlaceholderWorkshopOccurrence,
} from '../placeholder';
import {
  GetDropInWorkshopOccurrences,
  GetIntroWorkshops,
  GetMyPastWorkshopsSummary,
  GetQAndAWorkshops,
  GetSeriesWorkshops,
  GetUpcomingWorkshopOccurrences,
  GetWorkshop,
} from './types';

type CreateGetWorkshopsPlaceholderDataParams = {
  length: number;
};

export const createGetWorkshopPlaceholderData = _.memoize(
  ({ id }): GetWorkshop.Response => ({
    ...createPlaceholderWorkshop({ kind: 'intro' }),
    meta: createPlaceholderWorkshopMeta(),
    id,
    therapist: createPlaceholderTherapist(),
    // Note: There is only one workshop occurrence as standard for intros
    workshopOccurrences: _.range(1).map(() => ({
      ...createPlaceholderWorkshopOccurrence(),
      workshop: createPlaceholderWorkshop({ kind: 'intro' }),
    })),
  })
);

export const createGetIntroWorkshopsPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetIntroWorkshops.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshop({ kind: 'intro' }),
      therapist: createPlaceholderTherapist(),
      // Note: There is only one workshop occurrence as standard for intros
      workshopOccurrences: _.range(1).map(() => ({
        ...createPlaceholderWorkshopOccurrence(),
        workshop: createPlaceholderWorkshop({ kind: 'intro' }),
      })),
    }))
);

export const createGetFeaturedWorkshopsPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetIntroWorkshops.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshop({ kind: 'series' }),
      featured: true,
      therapist: createPlaceholderTherapist(),
      workshopOccurrences: _.range(8).map(() => ({
        ...createPlaceholderWorkshopOccurrence(),
        workshop: createPlaceholderWorkshop({ kind: 'series' }),
      })),
      meta: createPlaceholderWorkshopMeta(),
    }))
);

export const createGetSeriesWorkshopsPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetSeriesWorkshops.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshop({ kind: 'series' }),
      therapist: createPlaceholderTherapist(),
      // Note: There are 8 workshop occurrences as standard for series
      workshopOccurrences: _.range(8).map(() => ({
        ...createPlaceholderWorkshopOccurrence(),
        workshop: createPlaceholderWorkshop({ kind: 'series' }),
      })),
    }))
);

export const createGetDropInWorkshopOccurrencesPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetDropInWorkshopOccurrences.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshopOccurrence(),
      workshop: createPlaceholderWorkshop({ kind: 'series' }),
      therapist: createPlaceholderTherapist(),
    }))
);

export const createGetWorkshopOccurrencesPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetUpcomingWorkshopOccurrences.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshopOccurrence(),
      workshop: createPlaceholderWorkshop({ kind: 'series' }),
      therapist: createPlaceholderTherapist(),
    }))
);

export const createGetMyPastWorkshopsSummaryPlaceholderData = _.memoize(
  (): GetMyPastWorkshopsSummary.Response => ({
    enrolledDropInWorkshops: [createPlaceholderWorkshopOccurrence()],
    enrolledIntroWorkshops: [createGetWorkshopPlaceholderData({ length: 1 })],
    enrolledSeriesWorkshops: [createGetWorkshopPlaceholderData({ length: 1 })],
  })
);

export const createGetQAndAWorkshopsPlaceholderData = _.memoize(
  ({ length }: CreateGetWorkshopsPlaceholderDataParams): GetQAndAWorkshops.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderWorkshop({ kind: 'q_and_a' }),
      therapist: createPlaceholderTherapist(),
      // Note: There is only one workshop occurrence as standard for q_and_a's
      workshopOccurrences: _.range(1).map(() => ({
        ...createPlaceholderWorkshopOccurrence(),
        workshop: createPlaceholderWorkshop({ kind: 'q_and_a' }),
      })),
    }))
);
