import {
  AspectRatio,
  AspectRatioProps,
  Box,
  FlexProps,
  Image,
  ImageProps,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';
import { useGetWorkshopQuery } from '../../../../resources/workshops';
import { WorkshopDetailsContentProps } from '../types';

const ribbonDimensions = {
  height: '6px',
  width: '100%',
};

export const WorkshopDetailsImageRibbon = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & ImageProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });

  const { imageUrl } = getWorkshopQuery.isLoading ? { imageUrl: '' } : getWorkshopQuery.data;

  return (
    <Skeleton
      display={{ md: 'none' }}
      isLoaded={!getWorkshopQuery.isLoading}
      position="fixed"
      zIndex="sticky"
      left="0"
      {...ribbonDimensions}
    >
      <Image objectFit="cover" src={imageUrl} {...ribbonDimensions} {...rest} />
    </Skeleton>
  );
};

export const WorkshopDetailsImageSection = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & AspectRatioProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { isLoading, data: workshop } = getWorkshopQuery;
  return (
    <AspectRatio maxW="100%" ratio={16 / 9} {...rest}>
      {isLoading ? (
        <Skeleton height="100%" width="100%" />
      ) : (
        <Image borderRadius={4} objectFit="cover" objectPosition="top" src={workshop.imageUrl} />
      )}
    </AspectRatio>
  );
};

export const WorkshopDetailsHeroImage = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & FlexProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { isLoading, data: workshop } = getWorkshopQuery;

  return (
    <Box minH="200px" position="relative" {...rest}>
      {isLoading ? (
        <Skeleton height="100%" width="100%" />
      ) : (
        <Image
          objectFit="cover"
          objectPosition="top"
          position="absolute"
          h="100%"
          w="100%"
          src={workshop.imageUrl}
        />
      )}
    </Box>
  );
};
