import { Box, FormLabel, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import React from 'react';
import { TryWorkshopType } from '../../../resources/workshops';
import { MarketingSiteNavigationBreadcrumb } from '../../shared/fakeModal/MarketingSiteNavigationBreadcrumb';
import { TryPushupStaticCopyList } from '../pushups/components/TryPushupStaticContent';
import { TryQAndAStaticCopyList } from '../q-and-a/components/TryQAndAStaticContent';
import { TryWorkshopContextProps } from '../utils';
import { TryLogoContainer } from './TryWorkshopLogoContainer';
import { TryWorkshopPicker } from './TryWorkshopPicker';

const TRY_WORKSHOP_SHORT_DESCRIPTION: {
  [key in TryWorkshopType]: string;
} = {
  pushups: 'Try a free, 15-minute live class. Your first Coa emotional push-up!',
  q_and_a: 'Your top mental health questions, answered live by a therapist!',
};

const CLASSPASS_DESCRIPTION =
  'Your membership includes unlimited live and on-demand classes on topics like resilience, leadership, confidence, and more.';

const CLASSPASS_HEADING = 'Start your Emotional Fitness Practice with 1 Free Month of Coa';
const GENERIC_HEADING =
  'Your Mental Health Routine Starts Here.';

export function TryWorkshopLandingStaticContent({
  hasUpcomingWorkshop,
  type,
  loading,
  ...props
}: {
  hasUpcomingWorkshop: boolean;
  loading: boolean;
  type: TryWorkshopType;
} & TryWorkshopContextProps) {
  const renderTryWorkshopStaticCopyList = () => {
    switch (type) {
      case 'pushups':
        return <TryPushupStaticCopyList />;
      case 'q_and_a':
        return <TryQAndAStaticCopyList />;
      default:
        return null;
    }
  };
  const { b2bPartner } = useRouteParams<{ b2bPartner: string }>();
  const isClassPass = Boolean(b2bPartner) && b2bPartner.toLowerCase() === 'classpass';
  const heading = isClassPass ? CLASSPASS_HEADING : GENERIC_HEADING;

  const descriptionBase = TRY_WORKSHOP_SHORT_DESCRIPTION[type];
  const description = isClassPass ? `${CLASSPASS_DESCRIPTION} ${descriptionBase}` : descriptionBase;
  return (
    <Box color="white">
      <MarketingSiteNavigationBreadcrumb
        position={{ foo: 'absolute' }}
        bottom="100%"
        left={0}
        pb={{ base: 4, foo: 2 }}
        color="white"
      />
      <TryLogoContainer
        display={{ base: 'block', foo: 'inline-block' }}
        fill="white"
        fillOpacity={0.6}
        // Offset to account for SVG bounding box
        ml="-9px"
        mb={4}
      />
      <VStack spacing={4} alignItems="start" mb={8}>
        <Skeleton isLoaded={!loading}>
          <Heading fontSize={{ base: '2xl', md: '4xl' }}>{heading}</Heading>
        </Skeleton>
        <Skeleton isLoaded={!loading}>
          {hasUpcomingWorkshop ? (
            <>
              <Text fontSize={{ base: 'lg', md: 'xl' }}>{description}</Text>
              <Box w="100%">
                <FormLabel>
                  <strong>Choose your topic:</strong>
                </FormLabel>
                <TryWorkshopPicker type={type} {...props} />
              </Box>
            </>
          ) : null}
        </Skeleton>
      </VStack>
      <Skeleton isLoaded={!loading}>{renderTryWorkshopStaticCopyList()}</Skeleton>
    </Box>
  );
}
