import _ from 'lodash';

export const titleCase = _.flow(_.toLower, _.startCase);

/*
 * We currently use different cases across the client and
 * the server. This is likely a #thingtowatch and we should
 * consider not doing this in the future.
 *
 * For the moment, we use these transform methods, storing
 * them centrally so as to more easily track down the points
 * of fragility in the case of a future refactor.
 */
export const clientCase = <ReturnType = string>(str: string) =>
  (_.camelCase(str) as unknown) as ReturnType;

export const serverCase = <ReturnType = string>(str: string) =>
  (_.snakeCase(str) as unknown) as ReturnType;

export const kebabCase = <ReturnType = string>(str: string) =>
  (_.kebabCase(str) as unknown) as ReturnType;
