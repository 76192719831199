import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { create as createSessionAction, LOG_OUT } from '../../actions/sessions';
import { useAppDispatch, useAppSelector } from '../../store';
import { LoggedInSession } from './types';

export const useSession = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { memberId, loggedIn, role, name, email } = useAppSelector((state) =>
    _.pick(state.session, ['loggedIn', 'role', 'name', 'email', 'memberId'])
  );

  const logOut = () => {
    // The side-effects below will result in the user being redirected
    // if they are on an authenticated route, but will also include a
    // redirect_to query parameter. If the user explicitly logs out,
    // this should not be set, so we explicitly route to the log-in
    // page rather than relying on the side-effect.
    history.push('/log-in');

    // Note that there are several non-reduxy side-effects being executed
    // in our redux logic.
    dispatch({ type: LOG_OUT });
  };

  // This isn't great, but trying to extend logout breaks a lot of current implementations
  const logoutWithoutRedirect = () => {
    dispatch({ type: LOG_OUT });
  };

  return {
    memberId,
    loggedIn,
    role,
    name,
    email,
    logOut,
    logoutWithoutRedirect,
  };
};

type CreateSessionParams = {
  email: LoggedInSession['email'];
  password: string;
};

export const useCreateSession = () => {
  const dispatch = useAppDispatch();
  const { loggingIn, errors } = useAppSelector((state) =>
    _.pick(state.session, ['loggingIn', 'errors'])
  );
  const boundCreateSession = (params: CreateSessionParams) => {
    dispatch(createSessionAction(params));
  };
  return {
    errors,
    loggingIn,
    createSession: boundCreateSession,
  };
};
