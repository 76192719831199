import {
  Box,
  BoxProps,
  Heading,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { ActivityIcon, CoffeeIcon, SeriesIcon, StarIcon } from '../../../components/Icons';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  createGetIntroWorkshopsPlaceholderData,
  createGetMyPastWorkshopsSummaryPlaceholderData,
  createGetSeriesWorkshopsPlaceholderData,
  useDropInWorkshopOccurrencesQuery,
  useIntroWorkshopQuery,
  useMyPastWorkshopsSummaryQuery,
  useSeriesWorkshopsQuery,
} from '../../../resources/workshops';
import { UpcomingDropInsView } from '../../classes/hub/drop-ins/UpcomingDropInsView';
import { UpcomingWorkshop } from '../upcomingClass/upcomingWorkshop';
import { UpcomingWorkshopOccurrence } from '../upcomingClass/upcomingWorkshopOccurrence';

type ClassRecommendationKind = 'intro' | 'dropIn' | 'series' | 'next';

const classRecommendationKindSliderStepOrder: ClassRecommendationKind[] = [
  'intro',
  'dropIn',
  'series',
  'next',
];

type ClassRecommendationSliderProps = {
  activeStep: number;
};

type ClassRecommendationSliderThumbProps = {
  icon: React.FunctionComponent;
  staticStep: number;
} & ClassRecommendationSliderProps &
  BoxProps;

const myPastWorkshopSummaryPlaceholderData = createGetMyPastWorkshopsSummaryPlaceholderData();
const inactiveColor = 'gray.300';
const activeColor = 'evergreen.200';

const ClassRecommendationSliderThumb = ({
  icon,
  activeStep,
  staticStep,
  ...props
}: ClassRecommendationSliderThumbProps) => (
  <SliderThumb
    {...props}
    boxSize={8}
    position="absolute"
    bgColor={activeStep >= staticStep ? activeColor : inactiveColor}
  >
    <Box color="white" as={icon} boxSize={4} />
  </SliderThumb>
);

const ClassRecommendationSlider = ({ activeStep }: ClassRecommendationSliderProps) => {
  const pastWorkshopsSummaryQuery = useMyPastWorkshopsSummaryQuery({
    placeholderData: myPastWorkshopSummaryPlaceholderData,
  });

  const isLoading =
    pastWorkshopsSummaryQuery.isLoading || pastWorkshopsSummaryQuery.isPlaceholderData;

  const sliderMarkOffset = '-24px';

  return (
    <Skeleton isLoaded={!isLoading} height="100%" display={{ base: 'none', md: 'block' }}>
      <Slider
        value={activeStep}
        isReadOnly
        position="relative"
        size="lg"
        min={0}
        max={3}
        mt={2}
        mb={8}
        cursor="default"
      >
        <SliderTrack bg={inactiveColor}>
          <SliderFilledTrack bg={activeColor} />
        </SliderTrack>
        <SliderMark fontSize="sm" value={0} marginTop={sliderMarkOffset} marginLeft={4}>
          Take an Intro Class
        </SliderMark>
        <SliderMark fontSize="sm" value={1} marginTop={sliderMarkOffset} marginLeft={4}>
          Take a Drop-In Skill Class
        </SliderMark>
        <SliderMark fontSize="sm" value={2} marginTop={sliderMarkOffset} marginLeft={4}>
          Take a whole Series
        </SliderMark>

        {/* Static SliderThumbs for checkpoints */}
        <ClassRecommendationSliderThumb
          activeStep={activeStep}
          staticStep={0}
          icon={CoffeeIcon}
          left="-2% !important"
        />
        <ClassRecommendationSliderThumb
          activeStep={activeStep}
          staticStep={1}
          icon={ActivityIcon}
          left="31% !important"
        />
        <ClassRecommendationSliderThumb
          activeStep={activeStep}
          staticStep={2}
          icon={SeriesIcon}
          left="65% !important"
        />
        <ClassRecommendationSliderThumb
          activeStep={activeStep}
          staticStep={3}
          icon={StarIcon}
          left="98% !important"
        />
      </Slider>
    </Skeleton>
  );
};

const ClassRecommendationGeneric = () => (
  <>
    <Heading size="md" mb={6}>
      Recommended Classes
    </Heading>
    <UpcomingDropInsView />
  </>
);

const ClassRecommendationIntro = () => {
  const placeholderData = createGetIntroWorkshopsPlaceholderData({ length: 1 });
  const introsQuery = useIntroWorkshopQuery({}, { placeholderData });

  const { data: intros } = introsQuery;
  const isLoading = introsQuery.isLoading || introsQuery.isPlaceholderData;

  if (!isLoading && intros.length === 0) {
    return <ClassRecommendationGeneric />;
  }
  return (
    <UpcomingWorkshop
      upcomingClasses={intros}
      isLoading={isLoading}
      heading="Take an Intro Class"
    />
  );
};

const ClassRecommendationDropIn = () => {
  const placeholderData = createGetDropInWorkshopOccurrencesPlaceholderData({ length: 1 });
  const dropInsQuery = useDropInWorkshopOccurrencesQuery({}, { placeholderData });

  const { data: dropIns } = dropInsQuery;
  const isLoading = dropInsQuery.isLoading || dropInsQuery.isPlaceholderData;

  if (!isLoading && dropIns.length === 0) {
    return <ClassRecommendationGeneric />;
  }
  return (
    <UpcomingWorkshopOccurrence
      upcomingClasses={dropIns}
      isLoading={isLoading}
      heading="Drop-In to a Skill class"
    />
  );
};

const ClassRecommendationSeries = () => {
  const placeholderData = createGetSeriesWorkshopsPlaceholderData({ length: 1 });
  const seriesQuery = useSeriesWorkshopsQuery({}, { placeholderData });

  const { data: series } = seriesQuery;
  const isLoading = seriesQuery.isLoading || seriesQuery.isPlaceholderData;

  if (!isLoading && series.length === 0) {
    return <ClassRecommendationGeneric />;
  }
  return (
    <UpcomingWorkshop
      upcomingClasses={series}
      isLoading={isLoading}
      heading="Take a whole Series"
    />
  );
};

export const ClassRecommendationSection = () => {
  const pastWorkshopsSummaryQuery = useMyPastWorkshopsSummaryQuery({
    placeholderData: myPastWorkshopSummaryPlaceholderData,
  });

  const introPlaceholderData = createGetIntroWorkshopsPlaceholderData({ length: 1 });
  const introsQuery = useIntroWorkshopQuery({}, { placeholderData: introPlaceholderData });
  const dropInPlaceholderData = createGetDropInWorkshopOccurrencesPlaceholderData({ length: 1 });
  const dropInsQuery = useDropInWorkshopOccurrencesQuery(
    {},
    { placeholderData: dropInPlaceholderData }
  );
  const seriesPlaceholderData = createGetSeriesWorkshopsPlaceholderData({ length: 1 });
  const seriesQuery = useSeriesWorkshopsQuery({}, { placeholderData: seriesPlaceholderData });

  const workshops = {
    intro: introsQuery.data,
    dropIn: dropInsQuery.data,
    series: seriesQuery.data,
  };

  const { data: pastWorkshopsSummary } = pastWorkshopsSummaryQuery;

  const isLoading =
    pastWorkshopsSummaryQuery.isLoading ||
    pastWorkshopsSummaryQuery.isPlaceholderData ||
    introsQuery.isLoading ||
    introsQuery.isPlaceholderData ||
    dropInsQuery.isLoading ||
    dropInsQuery.isPlaceholderData ||
    seriesQuery.isLoading ||
    seriesQuery.isPlaceholderData;

  const classRecommendationKind = !_.isEmpty(pastWorkshopsSummary.enrolledIntroWorkshops)
    ? 'dropIn' // Direct user to take a Drop-In Class
    : !_.isEmpty(pastWorkshopsSummary.enrolledDropInWorkshops)
    ? 'series' // Direct user to take a Series
    : !_.isEmpty(pastWorkshopsSummary.enrolledSeriesWorkshops)
    ? 'next' // Direct user to today's Next Classes
    : 'intro'; // Direct user to take an Intro Class

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box
        width="100%"
        backgroundColor="gray.50"
        p={{ base: 8, md: 10 }}
        data-cy={`class-recommendation-${isLoading ? 'loading' : 'loaded'}`}
      >
        {classRecommendationKind !== 'next' && workshops[classRecommendationKind].length !== 0 ? (
          <ClassRecommendationSlider
            activeStep={
              isLoading
                ? 0
                : classRecommendationKindSliderStepOrder.indexOf(classRecommendationKind)
            }
          />
        ) : null}

        {(() => {
          switch (classRecommendationKind) {
            case 'intro':
              return <ClassRecommendationIntro />;
            case 'dropIn':
              return <ClassRecommendationDropIn />;
            case 'series':
              return <ClassRecommendationSeries />;
            default:
              return <ClassRecommendationGeneric />;
          }
        })()}
      </Box>
    </Skeleton>
  );
};
