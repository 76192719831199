import { AdminSerializedMediaImage } from '../../../serializers/admin/AdminSerializedMediaImage';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from '../cms';

const pollsCmsParams = {
  basePath: '/admin/media_images',
  auth: true,
};

export const useAdminIndexMediaImagesQuery = generateUseIndexQuery<
  AdminSerializedMediaImage,
  AdminCmsIndexFilterParams<AdminSerializedMediaImage>
>(pollsCmsParams);
export const useAdminShowMediaImageQuery = generateUseShowQuery<AdminSerializedMediaImage>(
  pollsCmsParams
);
export const useAdminCreateMediaImageMutation = generateUseCreateMutation<
  AdminSerializedMediaImage
>(pollsCmsParams);
export const useAdminUpdateMediaImageMutation = generateUseUpdateMutation<
  AdminSerializedMediaImage
>(pollsCmsParams);
