import axios from 'axios';
import _ from 'lodash';
import { createAxiosClient, CreateAxiosClientOptions } from './createAxiosClient';
import { authInterceptor } from './interceptors/authInterceptor';
import { caseInterceptor } from './interceptors/caseInterceptor';

// TODO: Consider renaming "createAxiosClientForJsonApiQuery" to align
// with mutation client name below.
export const createJsonApiAxiosClient = (ops: CreateAxiosClientOptions = {}) =>
  createAxiosClient({
    transformPropertyNameCase: true,
    normalizeJsonApi: true,
    raiseCustomExceptions: true,
    ...ops,
  });

export const createAxiosClientForMutation = (ops: CreateAxiosClientOptions = {}) =>
  createAxiosClient({ transformPropertyNameCase: true, ...ops });

/*
 * The functions below are deprecated as we should, instead,
 * favor use of the generic `createAxiosClient` above.
 */

export const createLegacyAuthAxiosClient = _.memoize(() => {
  const client = axios.create();
  client.interceptors.request.use(authInterceptor);
  return client;
});

export const createLegacyAxiosClientWithCaseTransform = _.memoize(() => {
  const client = axios.create();
  client.interceptors.request.use(caseInterceptor);
  return client;
});

export const createLegacyAuthAxiosClientWithCaseTransform = _.memoize(() => {
  const client = axios.create();
  client.interceptors.request.use(_.flow([caseInterceptor, authInterceptor]));
  return client;
});
