import { Container } from '@chakra-ui/react';
import React from 'react';
import { useFullScreenLayout } from '../../shared/layout/components/GlobalLayoutProvider';

type TeamMembershipLayoutProps = {
  children: React.ReactNode;
};
export const TeamMembershipLayout = ({ children }: TeamMembershipLayoutProps) => {
  useFullScreenLayout();
  return (
    <Container
      display="flex"
      flexDir={{ base: 'column', md: 'row' }}
      height="100vh"
      maxWidth="full"
      px={0}
    >
      {children}
    </Container>
  );
};
