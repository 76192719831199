import { AdminCmsIndexFilterParams } from '@coa/api/controllers/admin/cms';
import { useQueryParams, useRouteParams } from '@coa/react-utils';
import { ValueOf } from '@coa/types';
import _ from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { appendQueryParams } from '../../../../lib/url';
import { BaseSerializedResource, useCmsContext } from './useAdminCmsContext';

type CmsIndexFilterContextParams<SerializedResource extends BaseSerializedResource> = {
  params: AdminCmsIndexFilterParams<SerializedResource>;
  setParams: (_params: Partial<AdminCmsIndexFilterParams<SerializedResource>>) => void;
};

export const cmsViewKinds = {
  index: 'index',
  show: 'show',
  create: 'create',
} as const;

type CmsViewKind = ValueOf<typeof cmsViewKinds>;

type CmsViewState =
  | {
      view: Omit<CmsViewKind, 'show'>;
      meta?: never;
    }
  | {
      view: 'show';
      meta: { id: string };
    };

const getCmsViewStateFromRouterPath = ({
  selectedRouterPath,
  baseRouterPath,
  routeParams,
}: {
  selectedRouterPath: string;
  baseRouterPath: string;
  routeParams?: { id: string };
}) => {
  const currentSubPath = selectedRouterPath.replace(baseRouterPath, '');
  if (currentSubPath.match(/\/new/)) return { view: cmsViewKinds.create };
  // TODO: Get ID
  if (currentSubPath.match(/\/\d+/))
    return { view: cmsViewKinds.show, meta: { id: routeParams.id } };
  if (currentSubPath === '') return { view: cmsViewKinds.index };
  throw Error(`Unable to identify view kind from router path: ${selectedRouterPath}`);
};

export type SetCmsViewState = (v: CmsViewState) => void;

const AdminCmsViewStateContext = createContext({});

export const useAdminCmsViewStateContext = <SerializedResource extends BaseSerializedResource>() =>
  useContext(AdminCmsViewStateContext) as CmsIndexFilterContextParams<SerializedResource> & {
    cmsViewState: CmsViewState;
    setCmsViewState: SetCmsViewState;
  };

const getNewParams = <SerializedResource extends BaseSerializedResource>(
  existingParams: AdminCmsIndexFilterParams<SerializedResource>,
  params: AdminCmsIndexFilterParams<SerializedResource>
) =>
  _.pickBy({
    ...existingParams,
    ...params,
  });

// Technically these defaults are set on the backend, but we need to know what
// the defaults are on the client so that we can represent them in the UI.
const defaultParams = {
  order_by: 'updated_at',
  order: 'desc' as const,
  page: '1' as const,
};

export const AdminCmsRouterViewStateContextProvider = <
  SerializedResource extends BaseSerializedResource
>({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { baseRouterPath } = useCmsContext();
  const rawParams = useQueryParams();
  const location = useLocation();
  const history = useHistory();
  const searchQuery = rawParams.get('query');
  const routeParams = useRouteParams<{ id: string }>();
  const params = ({
    ...defaultParams,
    ..._.pickBy({
      order_by: rawParams.get('order_by'),
      order: rawParams.get('order'),
      page: rawParams.get('page'),
    }),
    ...(searchQuery ? { query: searchQuery } : {}),
  } as unknown) as AdminCmsIndexFilterParams<SerializedResource>;

  const setParams = (_params: AdminCmsIndexFilterParams<SerializedResource>) => {
    const newParams = getNewParams<SerializedResource>(params, _params);
    const dest = appendQueryParams(
      location.pathname,
      /*
       * I'm not sure I understand what TS is complaining about here. I've
       * done everything I can to ensure that the params are string indexed,
       * though TS is still telling me they can be symbols / number indexed.
       * I'm ignoring for velocity. -scotty
       */
      (newParams as unknown) as Record<string, string | string[]>
    );
    history.push(dest);
  };

  const cmsViewState = getCmsViewStateFromRouterPath({
    selectedRouterPath: location.pathname,
    baseRouterPath,
    routeParams,
  });

  const setCmsViewState = ({ view, meta }: CmsViewState) => {
    if (view === 'index') {
      history.push(`${baseRouterPath}`);
    } else if (view === 'create') {
      history.push(`${baseRouterPath}/new`);
    } else if (view === 'show') {
      history.push(`${baseRouterPath}/${meta.id}`);
    } else {
      throw Error(`Unable to push to view with kind: ${view}`);
    }
  };

  return (
    <AdminCmsViewStateContext.Provider
      value={{
        params,
        setParams,
        cmsViewState,
        setCmsViewState,
      }}
    >
      {children}
    </AdminCmsViewStateContext.Provider>
  );
};

/*
 * This context is used to maintain the search results in state
 * rather than in the router path, as there are scenarios
 * where this is desirable.
 */
export const AdminCmsManualViewStateContextProvider = <
  SerializedResource extends BaseSerializedResource
>({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [params, setParams] = useState<AdminCmsIndexFilterParams<SerializedResource>>(
    (defaultParams as unknown) as AdminCmsIndexFilterParams<SerializedResource>
  );
  const [cmsViewState, setCmsViewState] = useState<CmsViewState>({ view: cmsViewKinds.index });
  return (
    <AdminCmsViewStateContext.Provider
      value={{
        params,
        setParams,
        cmsViewState,
        setCmsViewState,
      }}
    >
      {children}
    </AdminCmsViewStateContext.Provider>
  );
};
