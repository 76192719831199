export const THERAPY_MATCHES_LIST_REQUEST = 'THERAPY_MATCHES_LIST_REQUEST';
export const THERAPY_MATCHES_LIST_SUCCESS = 'THERAPY_MATCHES_LIST_SUCCESS';
export const THERAPY_MATCHES_LIST_FAILURE = 'THERAPY_MATCHES_LIST_FAILURE';

export function list(path, page, searchQuery) {
  return {
    type: THERAPY_MATCHES_LIST_REQUEST,
    api: {
      endpoint: `therapy_matches/${path || ''}?page=${page}&query=${searchQuery}`,
      authenticated: true,
      admin: true,
      types: [
        THERAPY_MATCHES_LIST_REQUEST,
        THERAPY_MATCHES_LIST_SUCCESS,
        THERAPY_MATCHES_LIST_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const THERAPY_MATCH_SHOW_REQUEST = 'THERAPY_MATCH_SHOW_REQUEST';
export const THERAPY_MATCH_SHOW_SUCCESS = 'THERAPY_MATCH_SHOW_SUCCESS';
export const THERAPY_MATCH_SHOW_FAILURE = 'THERAPY_MATCH_SHOW_FAILURE';

export function show(id) {
  return {
    type: THERAPY_MATCH_SHOW_REQUEST,
    api: {
      endpoint: `therapy_matches/${id}/details`,
      authenticated: true,
      admin: true,
      types: [THERAPY_MATCH_SHOW_REQUEST, THERAPY_MATCH_SHOW_SUCCESS, THERAPY_MATCH_SHOW_FAILURE],
      method: 'GET',
    },
  };
}

export const THERAPY_MATCH_UPDATE_NOTES_REQUEST = 'THERAPY_MATCH_UPDATE_NOTES_REQUEST';
export const THERAPY_MATCH_UPDATE_NOTES_SUCCESS = 'THERAPY_MATCH_UPDATE_NOTES_SUCCESS';
export const THERAPY_MATCH_UPDATE_NOTES_FAILURE = 'THERAPY_MATCH_UPDATE_NOTES_FAILURE';

export function updateNotes(id, notes) {
  return {
    type: THERAPY_MATCH_UPDATE_NOTES_REQUEST,
    api: {
      data: { notes },
      endpoint: `therapy_matches/${id}/notes`,
      authenticated: true,
      admin: true,
      types: [
        THERAPY_MATCH_UPDATE_NOTES_REQUEST,
        THERAPY_MATCH_UPDATE_NOTES_SUCCESS,
        THERAPY_MATCH_UPDATE_NOTES_FAILURE,
      ],
      method: 'PUT',
    },
  };
}

export const THERAPY_MATCH_UPDATE_STATUS_REQUEST = 'THERAPY_MATCH_UPDATE_STATUS_REQUEST';
export const THERAPY_MATCH_UPDATE_STATUS_SUCCESS = 'THERAPY_MATCH_UPDATE_STATUS_SUCCESS';
export const THERAPY_MATCH_UPDATE_STATUS_FAILURE = 'THERAPY_MATCH_UPDATE_STATUS_FAILURE';

export function updateStatus(id, status) {
  return {
    type: THERAPY_MATCH_UPDATE_STATUS_REQUEST,
    api: {
      data: { new_status: status },
      endpoint: `therapy_matches/${id}/status`,
      authenticated: true,
      admin: true,
      types: [
        THERAPY_MATCH_UPDATE_STATUS_REQUEST,
        THERAPY_MATCH_UPDATE_STATUS_SUCCESS,
        THERAPY_MATCH_UPDATE_STATUS_FAILURE,
      ],
      method: 'PUT',
    },
  };
}

export const THERAPY_MATCH_EVENTS_LIST_REQUEST = 'THERAPY_MATCH_EVENTS_LIST_REQUEST';
export const THERAPY_MATCH_EVENTS_LIST_SUCCESS = 'THERAPY_MATCH_EVENTS_LIST_SUCCESS';
export const THERAPY_MATCH_EVENTS_LIST_FAILURE = 'THERAPY_MATCH_EVENTS_LIST_FAILURE';

export function listEvents(therapyMatchId) {
  return {
    type: THERAPY_MATCH_EVENTS_LIST_REQUEST,
    api: {
      endpoint: `therapy_matches/${therapyMatchId}/events`,
      authenticated: true,
      admin: true,
      payload: { therapyMatchId },
      types: [
        THERAPY_MATCH_EVENTS_LIST_REQUEST,
        THERAPY_MATCH_EVENTS_LIST_SUCCESS,
        THERAPY_MATCH_EVENTS_LIST_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const THERAPY_MATCH_TRANSITION_REQUEST = 'THERAPY_MATCH_TRANSITION_REQUEST';
export const THERAPY_MATCH_TRANSITION_SUCCESS = 'THERAPY_MATCH_TRANSITION_SUCCESS';
export const THERAPY_MATCH_TRANSITION_FAILURE = 'THERAPY_MATCH_TRANSITION_FAILURE';

export function transitionTo(therapyMatchId, { status, details }) {
  return {
    type: THERAPY_MATCH_TRANSITION_REQUEST,
    api: {
      data: { status, details },
      endpoint: `therapy_matches/${therapyMatchId}/events`,
      authenticated: true,
      admin: true,
      payload: { therapyMatchId },
      types: [
        THERAPY_MATCH_TRANSITION_REQUEST,
        THERAPY_MATCH_TRANSITION_SUCCESS,
        THERAPY_MATCH_TRANSITION_FAILURE,
      ],
      method: 'POST',
    },
  };
}
