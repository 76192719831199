import { Box, Button, Divider, Flex, Heading, Icon, Image, Text, VStack } from '@chakra-ui/react';
import { useQueryParams } from '@coa/react-utils';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { ActivityIcon, EditIcon, UsersIcon } from '../../../components/Icons';
import AsanaLogo from '../../../images/b2b_customer_icons/asana.svg';
import BloombergLogo from '../../../images/b2b_customer_icons/bloomberg.svg';
import NBCLogo from '../../../images/b2b_customer_icons/nbc.svg';
import SkillShareLogo from '../../../images/b2b_customer_icons/skillshare.svg';
import SpotifyLogo from '../../../images/b2b_customer_icons/spotify.svg';
import CoaLogo from '../../../images/logo.png';
import TeamMembershipImage from '../../../images/team_membership_pallavi_class.png';
import TeamMembershipIllustrtionBG from '../../../images/team_membership_payment_illustration.png';

export const RegistrationFormImage = () => (
  <Flex flexGrow={1} position="relative" justifyContent="center" h="full">
    <Image src={TeamMembershipImage} height="full" width="full" objectFit="cover" />
    <Image src={CoaLogo} width={20} position="absolute" bottom={10} />
  </Flex>
);

export const PaymentFormCopy = () => {
  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');
  const isVGP = campaign?.toLowerCase() === 'vgp';
  const couponCodeIsActive = dayjs().isBefore(dayjs('2022-12-05'));
  return (
    <Flex
      h="full"
      w="full"
      area="img"
      backgroundImage={`url(${TeamMembershipIllustrtionBG})`}
      bgSize="cover"
      alignItems="center"
      justify="center"
    >
      <Flex justify="center" m={4}>
        <Box
          backgroundColor="white"
          borderRadius="lg"
          textAlign="center"
          px={{ sm: 3, md: 12 }}
          py={8}
          maxW={{ md: '75%' }}
        >
          <Heading color="green.900" fontWeight="medium">
            What's Included In Your
          </Heading>
          <Heading color="green.900" fontWeight="medium" mb={4}>
            {' '}
            Team Membership
          </Heading>
          {isVGP && couponCodeIsActive ? (
            <Text color="red.400" fontSize="">
              Use the code <span style={{ fontWeight: 'bold' }}>VGPLAUNCH25</span> to get{' '}
              <span style={{ fontWeight: 'bold' }}>25%</span> off for your first year. Offer now{' '}
              available until 12/4 at midnight
            </Text>
          ) : null}
          <VStack textAlign="start" alignItems="start" my={8}>
            <Flex alignItems="center" mb={2}>
              <Icon as={UsersIcon} boxSize={6} mr={4} color="green.900" />
              <Text fontSize={{ base: 'md', md: 'xl' }}>
                <span style={{ fontWeight: 'bold' }}>Live Classes</span> Access to hundreds of live,
                online classes led by therapists
              </Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={ActivityIcon} boxSize={6} mr={4} color="green.900" />
              <Text fontSize={{ base: 'md', md: 'xl' }}>
                <span style={{ fontWeight: 'bold' }}>On-demand content</span> for your team's top
                mental health questions
              </Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Icon as={EditIcon} boxSize={6} mr={4} color="green.900" />
              <Text fontSize={{ base: 'md', md: 'xl' }}>
                <span style={{ fontWeight: 'bold' }}>Personalized Learning</span> through{' '}
                proprietary digital workbooks and learning prompts
              </Text>
            </Flex>
          </VStack>
          <Divider marginY="8" />
          <Heading color="green.900" fontSize="20px" mb={6}>
            Trusted By Teams at:
          </Heading>
          <Flex
            alignItems="center"
            flexDir="row"
            justifyContent="space-between"
            flexGrow={1}
            flexWrap="wrap"
          >
            <BloombergLogo />
            <AsanaLogo />
            <SpotifyLogo />
            <SkillShareLogo />
            <NBCLogo />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export const TeamMembershipCouponCodePromo = ({ couponCode }: { couponCode: string }) => (
  <Box
    bg="green.50"
    rounded="md"
    alignItems="center"
    display="flex"
    width="full"
    justifyContent="center"
    padding={2}
    textAlign="center"
  >
    <Text>
      <Text as="span" fontWeight="bold">
        Launch Month Discount:
      </Text>{' '}
      Use{' '}
      <Text as="span" fontWeight="bold" color="red.400">
        {couponCode}
      </Text>{' '}
      at checkout to get 25% off.
    </Text>
  </Box>
);

type TeamMembershipSteps = 'personal' | 'company' | 'payment';

const registrationStepToIndex: {
  [key in TeamMembershipSteps]: number;
} = {
  personal: 0,
  company: 1,
  payment: 2,
};

type ProgressStepperProps = {
  membershipStep: TeamMembershipSteps;
};

export const TeamMembershipProgressStepper = ({ membershipStep }: ProgressStepperProps) => {
  const currentStepIndex = registrationStepToIndex[membershipStep];
  const steps: TeamMembershipSteps[] = ['personal', 'company', 'payment'];

  return (
    <Flex width="full">
      {steps.map((step, idx) => (
        <Flex mr={2} my={4} key={step}>
          <Button
            size="xs"
            aria-label={step}
            color="white"
            bg={idx <= currentStepIndex ? 'green.600' : 'gray.400'}
            borderRadius="full"
            mr={1}
          >
            {idx + 1}
          </Button>
          <Text>{_.capitalize(step)}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

type FormHeaderProps = ProgressStepperProps;
export const TeamMembershipRegistrationFormHeader = (props: FormHeaderProps) => (
  <>
    <TeamMembershipProgressStepper {...props} />
    <Heading fontSize="3xl" mb={4}>
      Get Unlimited Access to Live & On-Demand Emotional Fitness for Your Team
    </Heading>
    <Text mb={4}>
      Starting at $95/month for up to 5 employees + $19/month for each additional employee
    </Text>
  </>
);
