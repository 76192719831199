import {
  Button,
  ButtonProps,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { getIsInThePast } from '../../../../lib/time';
import { useSession } from '../../../../resources/sessions';
import {
  useGetWorkshopQuery,
  useGetWorkshopSiblingsQuery,
  Workshop,
} from '../../../../resources/workshops';

export type WorkshopIdProps = {
  workshopId: Workshop['id'];
};

const NextWorkshopButton = ({ workshopId, ...rest }: WorkshopIdProps & ButtonProps) => {
  const getWorkshopSiblingsQuery = useGetWorkshopSiblingsQuery({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  if (getWorkshopSiblingsQuery.isLoading || getWorkshopQuery.isLoading) {
    return (
      <Skeleton>
        <Button {...rest}>Next Workshop</Button>
      </Skeleton>
    );
  }

  const { data: workshopSiblings } = getWorkshopSiblingsQuery;
  const { data: workshop } = getWorkshopQuery;
  const nextWorkshop = workshopSiblings[0];

  if (nextWorkshop) {
    return (
      <Button as={RouterLink} to={`/classes/${nextWorkshop.id}`} {...rest}>
        See Next Upcoming Class
      </Button>
    );
  }
  return (
    <Button
      as={RouterLink}
      to={workshop.kind === 'intro' ? '/classes/intros' : '/classes/series'}
      {...rest}
    >
      {workshop.kind === 'intro' ? 'See Other Intros' : 'See Other Series'}
    </Button>
  );
};

export const WorkshopUnavailableModal = ({ workshopId }: WorkshopIdProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { role } = useSession();

  if (getWorkshopQuery.isLoading || role === 'admin') return null;
  const { data: workshop } = getWorkshopQuery;
  const {
    meta: { enrollmentStatus },
    workshopOccurrences,
    kind,
  } = workshop;

  const { startsAt: lastOccurrenceStartsAt } = workshopOccurrences[workshopOccurrences.length - 1];

  /*
   * We only want to show this modal if the user has not enrolled
   * in this class AND it is in the past.
   */
  const isOpen = !enrollmentStatus && getIsInThePast(lastOccurrenceStartsAt);

  return (
    <>
      <Modal isOpen={isOpen} onClose={_.noop}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Sorry, this {_.startCase(kind)} has already ended.</Heading>
          </ModalHeader>
          <ModalBody>
            <Text>Please choose another date. 😅</Text>
          </ModalBody>
          <ModalFooter>
            <NextWorkshopButton variant="primary" workshopId={workshopId} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
