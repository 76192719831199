import { Container, Heading, useToast } from '@chakra-ui/react';
import { useQueryParams } from '@coa/react-utils';
import React from 'react';
import { useHistory } from 'react-router';
import {
  EnrollmentFormikValues,
  HandleSubmitOps,
  WorkshopEnrollmentForm,
} from '../../../components/admin/forms/WorkshopEnrollmentForm';
import { useMutationWithTimer } from '../../../lib/react-query';
import { useAdminCreateEnrollmentMutation } from '../../../resources/adminEnrollments/apis/hooks';

export const CreateWorkshopEnrollmentChildren = () => {
  const queryParams = useQueryParams();
  const memberId = queryParams.get('memberId');
  const workshopId = queryParams.get('workshopId');
  const toast = useToast();
  const history = useHistory();
  const rawAdminCreateEnrollmentMutation = useAdminCreateEnrollmentMutation();
  const adminCreateEnrollmentMutation = useMutationWithTimer(rawAdminCreateEnrollmentMutation, {
    duration: 500,
  });
  const handleSubmit = async (formValues: EnrollmentFormikValues, ops: HandleSubmitOps) => {
    const dropInIds = formValues.dropInIds.length ? {} : null;
    if (formValues.dropInIds.length) {
      formValues.dropInIds.forEach((id: string) => (dropInIds[id] = true));
    }
    try {
      const params = {
        memberId: formValues.memberId,
        workshopId: formValues.workshopId,
        dropInIds,
        answers: {},
      };
      const { id: enrollmentId } = await adminCreateEnrollmentMutation.mutateAsyncWithTimer(params);
      toast({
        title: 'Enrollment Created',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      history.push(`/admin-dash/enrollments/${enrollmentId}`);
      ops.resetFormFn();
    } catch (err) {
      toast({
        title: err.message || 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const props = {
    memberId,
    workshopId,
  };
  return <WorkshopEnrollmentForm onSubmit={handleSubmit} {...props} />;
};

export const CreateWorkshopEnrollment = () => (
  <Container maxW="container.lg">
    <Heading mb={8}>Create Enrollment:</Heading>
    <CreateWorkshopEnrollmentChildren />
  </Container>
);
