import _ from 'lodash';

export const THERAPISTS_LIST_REQUEST = 'THERAPISTS_LIST_REQUEST';
export const THERAPISTS_LIST_SUCCESS = 'THERAPISTS_LIST_SUCCESS';
export const THERAPISTS_LIST_FAILURE = 'THERAPISTS_LIST_FAILURE';

export function list() {
  return {
    type: THERAPISTS_LIST_REQUEST,
    api: {
      endpoint: 'therapists',
      authenticated: true,
      admin: true,
      types: [THERAPISTS_LIST_REQUEST, THERAPISTS_LIST_SUCCESS, THERAPISTS_LIST_FAILURE],
      method: 'GET',
    },
  };
}

const convertToFormData = (data) =>
  // Transfer data into FormData object, to induce a multipart/form-data POST
  // Also convert price and therapist data into appropriate formats
  _.reduce(
    data,
    (fd, value, key) => {
      fd.append(key, value);
      return fd;
    },
    new FormData()
  );

export const THERAPISTS_CREATE_REQUEST = 'THERAPISTS_CREATE_REQUEST';
export const THERAPISTS_CREATE_SUCCESS = 'THERAPISTS_CREATE_SUCCESS';
export const THERAPISTS_CREATE_FAILURE = 'THERAPISTS_CREATE_FAILURE';

export function create(data) {
  const formData = convertToFormData(data);
  return {
    type: THERAPISTS_CREATE_REQUEST,
    payload: formData,
    api: {
      data: formData,
      endpoint: 'therapists',
      authenticated: true,
      admin: true,
      types: [THERAPISTS_CREATE_REQUEST, THERAPISTS_CREATE_SUCCESS, THERAPISTS_CREATE_FAILURE],
      method: 'POST',
    },
  };
}

export const THERAPISTS_UPDATE_REQUEST = 'THERAPISTS_UPDATE_REQUEST';
export const THERAPISTS_UPDATE_SUCCESS = 'THERAPISTS_UPDATE_SUCCESS';
export const THERAPISTS_UPDATE_FAILURE = 'THERAPISTS_UPDATE_FAILURE';

export function update(id, data) {
  const formData = convertToFormData(data);
  return {
    type: THERAPISTS_UPDATE_REQUEST,
    payload: formData,
    api: {
      data: formData,
      endpoint: `therapists/${id}`,
      authenticated: true,
      admin: true,
      types: [THERAPISTS_UPDATE_REQUEST, THERAPISTS_UPDATE_SUCCESS, THERAPISTS_UPDATE_FAILURE],
      method: 'PUT',
    },
  };
}
