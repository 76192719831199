import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCreateRegistration } from '../../../resources/registrations';

type TryUserExistsContextParams = {
  userExists: boolean;
  setUserExists: (v: boolean) => void;
};
const TryUserExistsContext = createContext<TryUserExistsContextParams>(null);

const getUserExists = (errors: ReturnType<typeof useCreateRegistration>['errors'] = {}) =>
  Boolean((errors.password || '').match(/Existing member/));

export const TryUserExistsProvider = ({ children }: { children: React.ReactNode }) => {
  const [userExists, setUserExists] = useState<boolean>(false);
  const { errors: createRegistrationErrors } = useCreateRegistration();

  useEffect(() => {
    /*
     * The errors for createRegistration are overwritten in the redux
     * store by createSession. As such, for the sake of simplicity,
     * As soon as the user receives an "Existing member" error, we
     * assume they'll want to login and manage that state manually.
     */
    if (getUserExists(createRegistrationErrors)) {
      setUserExists(true);
    }
  }, [createRegistrationErrors]);

  return (
    <TryUserExistsContext.Provider value={{ userExists, setUserExists }}>
      {children}
    </TryUserExistsContext.Provider>
  );
};

export const useTryUserExists = () => useContext(TryUserExistsContext);
