import { Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { ISubmitEvent } from '@rjsf/core';
import React, { useState } from 'react';
import { useMutationWithTimer } from '../../../../lib/react-query/useMutationWithTimer';
import { AdminCmsForm } from './AdminCmsForm';
import { AdminCmsLayout } from './AdminCmsLayout';
import {
  BaseCmsFormData,
  BaseResource,
  BaseSerializedResource,
  useCmsContext,
} from './useAdminCmsContext';
import {
  AdminCmsRouterViewStateContextProvider,
  useAdminCmsViewStateContext,
} from './useAdminCmsViewStateContext';

export const AdminCmsCreate = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => {
  const {
    formSchema,
    formUiSchema,
    useCreateMutation,
    getEntryTitle,
    parseCmsFormDataToSerializedResource,
  } = useCmsContext<Resource, SerializedResource, CmsFormData>();
  const rawCreateMutation = useCreateMutation();
  const createMutation = useMutationWithTimer(rawCreateMutation, {
    duration: 500,
  });
  const [unsavedFormData, setUnsavedFormData] = useState<CmsFormData>();
  const toast = useToast();
  const { setCmsViewState } = useAdminCmsViewStateContext();

  return (
    <>
      <AdminCmsForm<CmsFormData>
        formData={unsavedFormData || ({} as CmsFormData)}
        schema={formSchema}
        uiSchema={formUiSchema}
        onSubmit={async ({ formData: rawFormData }: ISubmitEvent<CmsFormData>) => {
          const data = parseCmsFormDataToSerializedResource(rawFormData);
          // Cache form data so that, in the event of an error, the form
          // doesn't reset and we can pick up where we left off.
          setUnsavedFormData(rawFormData);
          try {
            const response = await createMutation.mutateAsyncWithTimer(data);
            toast({
              title: `Successfully created new entry: ${getEntryTitle(response)}`,
              status: 'success',
              duration: 2000,
              isClosable: true,
            });
            setCmsViewState({ view: 'show', meta: { id: response.id } });
          } catch (err) {
            const { errors } = err.response.data;
            // if there are multiple errors
            const errorMessages = Object.values(errors).join('\n');
            toast({
              title: errorMessages,
              status: 'warning',
              duration: 2000,
              isClosable: true,
            });
          }
        }}
      />
      {createMutation.isLoadingWithTimer ? (
        <Flex mt={4} alignItems="center">
          <Text>Saving...</Text>
          <Spinner size="sm" ml={2} />
        </Flex>
      ) : null}
    </>
  );
};

export const AdminCmsCreateView = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => (
  <AdminCmsRouterViewStateContextProvider<SerializedResource>>
    <AdminCmsLayout>
      <AdminCmsCreate<Resource, SerializedResource, CmsFormData> />
    </AdminCmsLayout>
  </AdminCmsRouterViewStateContextProvider>
);
