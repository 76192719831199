import { SubscriptionCoupon } from '@coa/api/controllers/v1/subscriptions';
import _ from 'lodash';
import { formatPercentInt, formatPriceCentsShort } from '.';

/*
 * For example...
 *    "50% off your first 3 months"
 *    "$25 off your first month"
 *    "100% off"
 */
export const formatCouponLabel = ({
  durationInMonths,
  percentOff,
  amountOff,
}: SubscriptionCoupon) => {
  const amountOffLabel = `${
    _.isNumber(amountOff) ? formatPriceCentsShort(amountOff) : formatPercentInt(percentOff)
  } off`;
  if (!_.isNumber(durationInMonths)) return amountOffLabel;

  const durationInMonthsLabel =
    durationInMonths > 1 ? `your first ${durationInMonths} months` : 'your first month';
  return [amountOffLabel, durationInMonthsLabel].join(' ');
};
