import { Badge, BoxProps, Center } from '@chakra-ui/react';
import React from 'react';
import { Workshop } from '../../resources/workshops/types';
import { LockIcon } from '../Icons';

type ClassListingLabelContentsProps = {
  access: Workshop['access'];
};

// Class Labels for Membership gated content
export const ClassListingLabelContents = ({ access }: ClassListingLabelContentsProps) =>
  access === 'members' ? <LockIcon color="white" boxSize={3} /> : null;

export const ClassListingLabel = ({ access, ...rest }: BoxProps & ClassListingLabelContentsProps) =>
  access === 'members' ? (
    <Badge
      position="absolute"
      top={4}
      right={2}
      bgColor="brand.coral"
      borderRadius="50%"
      p="6px"
      {...rest}
    >
      <Center>
        <ClassListingLabelContents access={access} />
      </Center>
    </Badge>
  ) : null;
