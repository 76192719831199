import _ from 'lodash';
import { GetSubscriptionOrderSummary } from './types';

export const createGetSubscriptionOrderSummaryPlaceholderData = _.memoize(
  (): GetSubscriptionOrderSummary.Response => ({
    id: 'fake-subscription-order-summary-id',
    name: 'Coa Membership',
    priceCents: 4900,
    billingModality: 'monthly',
  })
);
