import { formSchemaEntryTypes } from '../shared/AdminCmsForm';

export const formSchema = {
  type: formSchemaEntryTypes.object,
  required: ['label', 'adminTitle'],
  properties: {
    label: {
      title: 'Label',
      type: formSchemaEntryTypes.string,
    },
    adminTitle: {
      title: 'Admin Title',
      type: formSchemaEntryTypes.string,
    },
    pollOptions: {
      title: 'Poll Options',
      type: formSchemaEntryTypes.array,
      items: {
        type: formSchemaEntryTypes.object,
        required: ['label', 'prepopulatedResponseCount'],
        properties: {
          id: {
            type: formSchemaEntryTypes.string,
          },
          label: {
            title: 'Option Label',
            type: formSchemaEntryTypes.string,
          },
          prepopulatedResponseCount: {
            title: 'Pre-Populated Response Count',
            type: formSchemaEntryTypes.number,
            default: 0,
          },
        },
      },
    },
  },
};

export const formUiSchema = {
  pollOptions: {
    items: {
      id: {
        'ui:widget': 'hidden',
      },
    },
  },
};
