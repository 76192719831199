import { Button, ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon } from '../../../components/Icons';
import { ExerciseStepStatus } from '../../../resources/exercises';

export const ExerciseFormCtas = ({
  backFn,
  prevStepId,
  nextStepId,
  nextStepStatus,
  currentStepStatus,
  weeklyRep,
  ...rest
}: {
  backFn: () => void;
  prevStepId?: string;
  nextStepId?: string;
  nextStepStatus?: ExerciseStepStatus;
  currentStepStatus: ExerciseStepStatus;
  weeklyRep: boolean;
} & ButtonGroupProps): JSX.Element => {
  const isLastActiveStep = !nextStepId || nextStepStatus === 'locked';
  let finalStepLabel = 'Mark as Complete';
  if (nextStepId) {
    finalStepLabel = 'Complete Class Prep';
  } else if (weeklyRep) {
    finalStepLabel = 'Commit to my Weekly Rep';
  }
  return (
    <ButtonGroup {...rest} display={{ base: 'flex', md: 'block' }}>
      {prevStepId ? (
        <>
          <IconButton
            aria-label="back"
            variant="secondary"
            size="lg"
            icon={<ArrowLeftIcon />}
            onClick={backFn}
            flexGrow={1}
            // When on last step, on mobile we wish to show the IconButton rather
            // than the regular button since we wish to hide the text and simply
            // not displaying the text results in spacing that is not up-to-spec.
            // Note that the `undefined` value results in the value being unset,
            // which is what we want.
            display={{ base: isLastActiveStep ? undefined : 'none', md: 'none' }}
          />
          <Button
            variant="secondary"
            size="lg"
            leftIcon={<ArrowLeftIcon />}
            onClick={backFn}
            flexGrow={1}
            // When on last step, on mobile we hide the full Button and show it on
            // larger devices. Note that `inline-flex` is the display value provided
            // by default by the surrounding ButtonGroup, but because we're
            // overriding via `display: none;` we need to reset it.
            display={{ base: isLastActiveStep ? 'none' : undefined, md: 'inline-flex' }}
          >
            Prev
          </Button>
        </>
      ) : null}
      <Button
        type="submit"
        size="lg"
        variant="primary"
        flexGrow={1}
        data-cy="exercise-form-cta-proceed"
        {...(isLastActiveStep
          ? {
              leftIcon: <CheckCircleIcon />,
              children: finalStepLabel,
            }
          : {
              disabled: ['locked', undefined].includes(nextStepStatus),
              rightIcon: <ArrowRightIcon />,
              children: 'Next',
            })}
      />
    </ButtonGroup>
  );
};
