import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { SubscribeModalOrderSummaryProvider } from '../../../classes/subscribe/components/SubscribeModalOrderSummaryProvider';
import { LegalLinks } from '../../../shared/layout/components/LegalLinks';
import { TeamMembershipContextProvider } from '../TeamMembershipContextProvider';
import { TeamMembershipLayout } from '../TeamMembershipLayout';
import { PaymentFormCopy, RegistrationFormImage } from '../TeamMembershipStaticCopy';

const contentContainerProps = {
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: 'full',
};

export const TeamMembershipViewLayout = ({
  children: formComponent,
}: {
  children: React.ReactNode;
}) => {
  const isPaymentForm = useRouteMatch({
    path: '/team-membership/:id/payment',
  });
  const ImageComponent = isPaymentForm ? PaymentFormCopy : RegistrationFormImage;
  return (
    <TeamMembershipContextProvider>
      <SubscribeModalOrderSummaryProvider>
        <TeamMembershipLayout>
          <Flex {...contentContainerProps} h="full">
            <ImageComponent />
          </Flex>
          <Flex
            flex={1}
            height="full"
            flexDir="column"
            {...contentContainerProps}
            justifyContent="space-between"
          >
            <Flex flex={1} paddingX="10%" width="full">
              {formComponent}
            </Flex>
            <LegalLinks height="10%" />
          </Flex>
        </TeamMembershipLayout>
      </SubscribeModalOrderSummaryProvider>
    </TeamMembershipContextProvider>
  );
};
