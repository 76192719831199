import { AnyObject } from '@coa/types';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createApiMiddleware } from '../middleware/apiMiddleware';
import { createRootReducer } from '../reducers';
import { ReduxState } from './types';

export const history = createBrowserHistory();

export const generateConfigureStore = ({ queryClient }) => (
  preloadedState: Partial<ReduxState>
) => {
  const composeEnhancers = (window as AnyObject).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const apiMiddleware = createApiMiddleware();
  const store = createStore(
    createRootReducer({ history, queryClient }),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), apiMiddleware))
  );

  return store;
};
