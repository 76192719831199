import _ from 'lodash';
import {
  createPlaceholderOnDemandVideo,
  createPlaceholderOnDemandVideoCheckpoint,
} from '../placeholder';
import { GetOnDemandVideo, GetOnDemandVideos } from './types';

type CreateGetOnDemandVideosPlaceholderDataParams = {
  length: number;
};

export const createGetOnDemandVideoPlaceholderData = _.memoize(
  ({ id }): GetOnDemandVideo.Response => ({
    ...createPlaceholderOnDemandVideo(),
    id,
    onDemandVideoCheckpoints: _.range(10)
      .map(() => createPlaceholderOnDemandVideoCheckpoint())
      .sort((a, b) => a.timestampSeconds - b.timestampSeconds),
  })
);

export const createGetOnDemandVideosPlaceholderData = _.memoize(
  ({ length }: CreateGetOnDemandVideosPlaceholderDataParams): GetOnDemandVideos.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderOnDemandVideo(),
      onDemandVideoCheckpoints: _.range(8).map(() => ({
        ...createPlaceholderOnDemandVideoCheckpoint(),
      })),
    }))
);
