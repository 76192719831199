import { Flex, Spinner, useToast, VStack } from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { teamMembershipAnalytics } from '../../../../lib/analytics/clients/teamMembershipAnalytics';
import { useGetTeamQuery } from '../../../../resources/teams';
import { SubscribeForm } from '../../../classes/subscribe/components/SubscribeForm';
import { TeamMembershipViewLayout } from '../layouts/TeamMembershipViewLayout';
import { TeamMembershipOrderSummary } from '../TeamMembershipOrderSummary';
import { TeamMembershipProgressStepper } from '../TeamMembershipStaticCopy';

export const TeamMembershipPaymentForm = () => {
  const history = useHistory();
  const { id: teamId } = useRouteParams<{ id: string }>();
  const getTeamQuery = useGetTeamQuery({ id: teamId });
  const { isLoading, error } = getTeamQuery;

  const { data: { attributes: { isCurrentMemberAdmin = true } } = { attributes: {} } } =
    getTeamQuery.data || {};

  useEffect(() => {
    teamMembershipAnalytics.track('View Team Membership - Payment Form');
  }, []);

  const toast = useToast();
  if ((error || !isCurrentMemberAdmin) && !toast.isActive('no_team')) {
    const duration = 2000;
    toast({
      id: 'no_team',
      title: 'Unable to Find Team',
      description: 'Navigating back to your account',
      status: 'error',
      duration,
      isClosable: true,
    });
    setTimeout(() => {
      history.push('/account');
    }, duration);
  }

  return (
    <TeamMembershipViewLayout>
      {isLoading ? (
        <Flex h="full" alignItems="center" justifyContent="center" w="full">
          <Spinner size="sm" color="green.300" ml={2} position="relative" top="2px" />
        </Flex>
      ) : (
        <VStack flex={1} justifyContent="center" w="full">
          <TeamMembershipProgressStepper membershipStep="payment" />
          <TeamMembershipOrderSummary />
          <SubscribeForm />
        </VStack>
      )}
    </TeamMembershipViewLayout>
  );
};
