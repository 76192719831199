import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { StripeProvider } from 'react-stripe-elements';
import AdminDiscountCodes from '../components/admin/AdminDiscountCodes';
import AdminTherapists from '../components/admin/AdminTherapists';
import AdminTherapyMatches from '../components/admin/AdminTherapyMatches';
import AdminTherapyMatchEvents from '../components/admin/AdminTherapyMatchEvents';
import { HubspotTemplateForm } from '../components/admin/HubspotTemplateForm';
import TherapistForm, {
  THERAPIST_FORM_CREATE,
  THERAPIST_FORM_UPDATE,
} from '../components/admin/TherapistForm';
import WorkshopEnrollees from '../components/admin/WorkshopEnrollees';
import WorkshopForm, {
  WORKSHOP_FORM_CREATE,
  WORKSHOP_FORM_UPDATE,
} from '../components/admin/WorkshopForm';
import WorkshopTags from '../components/admin/WorkshopTags';
import { CoaDevTools } from '../components/CoaDevTools';
import EditAccountInfo from '../components/EditAccountInfo';
import { appConfig } from '../config';
import BillingHistoryContainer from '../containers/BillingHistoryContainer';
import ForgotPasswordContainer from '../containers/ForgotPasswordContainer';
import InvoicePaymentContainer from '../containers/InvoicePaymentContainer';
import InvoicesContainer from '../containers/InvoicesContainer';
import PayoutsContainer from '../containers/PayoutsContainer';
import Redirector from '../containers/Redirector';
import { AdminRoute, AuthenticatedRoute } from '../containers/routes';
import SendInvoiceContainer from '../containers/SendInvoiceContainer';
import TherapyMatchingContainer from '../containers/TherapyMatchingContainer';
import { AnalyticsProvider } from '../lib/analytics/AnalyticsProvider';
import { configureCoaAxiosAuthForWeb } from '../lib/axios/configureCoaAxiosAuthForWeb';
import { windowTyped as _window } from '../lib/web';
import { generateConfigureStore, history } from '../store';
import { coaTheme } from '../themes/coaTheme';
import { AccountView } from '../views/account/AccountView';
import { AccountTeamView } from '../views/account/teams/AccountTeamView';
import { AdminClassesView } from '../views/admin/classes/AdminClassesView';
import { AdminCmsRoutes } from '../views/admin/cms/AdminCmsRoutes';
import { AdminCrmView } from '../views/admin/crm/AdminCrmView';
import { CreateWorkshopEnrollment } from '../views/admin/enrollments/CreateWorkshopEnrollment';
import { EditWorkshopEnrollment } from '../views/admin/enrollments/EditWorkshopEnrollment';
import { AdminTeamView } from '../views/admin/teams/AdminTeamView';
import { AdminTopLevelTeamsView } from '../views/admin/teams/AdminTopLevelTeamsView';
import { AuthView } from '../views/auth/AuthView';
import { NewPasswordView } from '../views/auth/NewPasswordView';
import { ClassesView } from '../views/classes/ClassesView';
import { MembershipView } from '../views/membership/MembershipView';
import { MyCoaView } from '../views/my-coa/MyCoaView';
import { OnDemandView } from '../views/on-demand/OnDemandView';
import { AppLayout } from '../views/shared/layout/AppLayout';
import { TeamMembershipView } from '../views/team-membership/TeamMembershipView';
import { TeamsView } from '../views/teams/TeamsView';
import { TryView } from '../views/try/TryView';

const queryClient = new QueryClient();
const configureStore = generateConfigureStore({ queryClient });
const store = configureStore({
  /* Provide initial state, if any. */
});

configureCoaAxiosAuthForWeb();

/*
 * Expose store to Cypress for action injection.
 */
if (_window.Cypress) _window.store = store;

const { SENTRY_DSN, STRIPE_PUBLIC_KEY } = _window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      {appConfig.enableCoaDevTools ? <CoaDevTools /> : null}
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <ChakraProvider resetCSS theme={coaTheme}>
          <ConnectedRouter history={history}>
            <LastLocationProvider>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools
                // No conditionality needed here as React Query will only mount this
                // helper when NODE_ENV='production'
                />
                <AnalyticsProvider>
                  <AppLayout>
                    <Switch>
                      {/* Non-authenticated Routes */}
                      <Route path={['/log-in', '/join']} component={AuthView} />
                      <Route
                        path={['/passwords/new/:token', '/claim-account/:token']}
                        component={NewPasswordView}
                      />
                      <Route path="/forgot-password" component={ForgotPasswordContainer} />

                      <Route path="/teams" component={TeamsView} />

                      <Route path="/try" component={TryView} />

                      <Route path="/membership" component={MembershipView} />

                      <Route path="/team-membership" component={TeamMembershipView} />

                      <Route path="/classes" component={ClassesView} />

                      <Route path="/on-demand" component={OnDemandView} />

                      <AuthenticatedRoute path="/account/teams/:id" component={AccountTeamView} />
                      <AuthenticatedRoute path="/account/edit" component={EditAccountInfo} />
                      <AuthenticatedRoute path="/account" component={AccountView} />
                      <AuthenticatedRoute
                        path="/billing-history"
                        component={BillingHistoryContainer}
                      />
                      <AuthenticatedRoute path="/my-coa" component={MyCoaView} />
                      <AuthenticatedRoute
                        path="/therapy-matching/:question_key"
                        component={TherapyMatchingContainer}
                      />
                      <AuthenticatedRoute
                        path="/therapy-matching"
                        component={TherapyMatchingContainer}
                      />
                      <AuthenticatedRoute
                        path="/pay-invoices"
                        component={InvoicePaymentContainer}
                      />
                      <AuthenticatedRoute path="/invoices" component={InvoicesContainer} />
                      <AuthenticatedRoute path="/clients" component={SendInvoiceContainer} />
                      <AuthenticatedRoute path="/payouts" component={PayoutsContainer} />

                      <AdminRoute path="/admin-dash/crm" component={AdminCrmView} />
                      <AdminRoute path="/admin-dash/cms" component={AdminCmsRoutes} />

                      <AdminRoute
                        path="/admin-dash/therapy-matches/:id"
                        component={AdminTherapyMatchEvents}
                      />
                      <AdminRoute
                        path="/admin-dash/therapy-matches"
                        component={AdminTherapyMatches}
                      />
                      <AdminRoute path="/admin-dash/classes/new">
                        <WorkshopForm mode={WORKSHOP_FORM_CREATE} />
                      </AdminRoute>

                      <AdminRoute path="/admin-dash/enrollments/new">
                        <CreateWorkshopEnrollment />
                      </AdminRoute>
                      <AdminRoute path="/admin-dash/enrollments/:id">
                        <EditWorkshopEnrollment />
                      </AdminRoute>

                      <AdminRoute
                        path="/admin-dash/classes/:id/enrollees"
                        component={WorkshopEnrollees}
                      />
                      <AdminRoute path="/admin-dash/classes/:id/tags" component={WorkshopTags} />
                      <AdminRoute path="/admin-dash/classes/:id">
                        <WorkshopForm mode={WORKSHOP_FORM_UPDATE} />
                      </AdminRoute>

                      <AdminRoute path="/admin-dash/classes" component={AdminClassesView} />
                      <AdminRoute
                        path="/admin-dash/hubspot-templates/new"
                        component={HubspotTemplateForm}
                      />

                      <AdminRoute path="/admin-dash/therapists/new">
                        <TherapistForm mode={THERAPIST_FORM_CREATE} />
                      </AdminRoute>
                      <AdminRoute path="/admin-dash/therapists/:id">
                        <TherapistForm mode={THERAPIST_FORM_UPDATE} />
                      </AdminRoute>
                      <AdminRoute path="/admin-dash/therapists" component={AdminTherapists} />
                      <AdminRoute path="/admin-dash/discounts" component={AdminDiscountCodes} />

                      <AdminRoute path="/admin-dash/teams/:id" component={AdminTeamView} />
                      <AdminRoute path="/admin-dash/teams" component={AdminTopLevelTeamsView} />

                      <Route path="/" component={Redirector} />
                    </Switch>
                  </AppLayout>
                </AnalyticsProvider>
              </QueryClientProvider>
            </LastLocationProvider>
          </ConnectedRouter>
        </ChakraProvider>
      </StripeProvider>
    </Provider>,
    document.getElementById('app')
  );
});
