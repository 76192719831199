import { FormControl, FormLabel, Grid, GridItem, Input, InputProps } from '@chakra-ui/react';
import { WidgetProps } from '@rjsf/core';
import React, { useState } from 'react';

const MintesSecondsInput = (props: InputProps) => {
  const { value, ...rest } = props;
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  return (
    <Input
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      value={hasFocus && value === 0 ? '' : value}
      {...rest}
    />
  );
};

export const AdminCmsMinutesSecondsInputWidget = (props: WidgetProps) => {
  const { onChange, label, value = 0 } = props;
  const minutesValue = Math.floor(value / 60);
  const secondsValue = value % 60;
  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.currentTarget.value;
    const newMinutes = rawValue === '' ? 0 : parseInt(rawValue, 10);
    const newTimestampSeconds = newMinutes * 60 + secondsValue;
    onChange(newTimestampSeconds);
  };
  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.currentTarget.value;
    const newSeconds = rawValue === '' ? 0 : parseInt(rawValue, 10);
    const newTimestampSeconds = minutesValue * 60 + newSeconds;
    onChange(newTimestampSeconds);
  };
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="sm">Minutes</FormLabel>
            <MintesSecondsInput
              type="number"
              min={0}
              value={minutesValue}
              onChange={handleMinutesChange}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="sm">Seconds</FormLabel>
            <MintesSecondsInput
              type="number"
              min={0}
              value={secondsValue}
              onChange={handleSecondsChange}
            />
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};
