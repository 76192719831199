import { formatProseDateShort } from '@coa/stdlib/string';
import React from 'react';
import { Workshop } from '../../resources/workshops';
import { CheckboxField } from '../formik/Checkbox';

export const WorkshopOccurencesCheckboxList = ({
  isDisabled = false,
  workshop,
  fieldName = 'workshopOccurrences',
}: {
  isDisabled: boolean;
  workshop: Workshop;
  fieldName?: string;
}) => {
  const occurenceOptions = workshop
    ? workshop.workshopOccurrences.map((wo) => {
        const dateStr = formatProseDateShort(wo.startsAt);
        const label = `${dateStr} - ${wo.title}`;

        return {
          label,
          value: wo.id,
        };
      })
    : [];
  return <CheckboxField name={fieldName} options={occurenceOptions} isDisabled={isDisabled} />;
};
