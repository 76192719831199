import * as dayjs from 'dayjs';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { UseQueryResult } from 'react-query';
import { useSession } from '../../../../resources/sessions';
import {
  GetMyWorkshops,
  GetPushUpWorkshops,
  useMyWorkshopsQuery,
  usePushUpWorkshopsQuery,
  Workshop,
} from '../../../../resources/workshops';

/*
 * This sort should happen on the backend, but for convenience
 * we manage it on the frontend for now.
 */
function sortPushups(pushups: GetPushUpWorkshops.Response) {
  return _.sortBy(pushups, (pushup) => {
    const firstOccurrence = pushup.workshopOccurrences[0];
    // Transform to Unix Timestamp so we can use arithmetic comparison.
    return dayjs(firstOccurrence.startsAt).unix();
  });
}

// TODO: make generic
function getIsEnrolledInSelectedPushup(
  workshops: GetMyWorkshops.Response,
  selectedPushupId: Workshop['id']
) {
  return Boolean(workshops.find(({ id }) => id === selectedPushupId));
}

type SelectedPushupContext = {
  pushupsQuery: UseQueryResult<GetPushUpWorkshops.Response, unknown>;
  selectedPushup: Workshop;
  setSelectedPushupId: Dispatch<SetStateAction<string>>;
  enrolledInSelectedPushup: boolean;
};

export const SelectedPushupContext = React.createContext<SelectedPushupContext | null>(null);
export const SelectedPushupProvider = ({ children }: { children: React.ReactNode }) => {
  const { loggedIn } = useSession();
  const unsortedPushupsQuery = usePushUpWorkshopsQuery();
  const [rawSelectedPushupId, setSelectedPushupId] = React.useState<string>();
  const myWorkshopsQuery = useMyWorkshopsQuery({ enabled: loggedIn });

  const pushupsQuery = {
    ...unsortedPushupsQuery,
    // For now we limit the number of pushups to 4 while we
    // investigate scrolling issues on the Pushups LP.
    // @see https://linear.app/coa/issue/COA-717
    // TODO: possibly sort on server?
    data: sortPushups(unsortedPushupsQuery.data).slice(0, 4),
  } as UseQueryResult<GetPushUpWorkshops.Response, unknown>;

  const { data: pushups } = pushupsQuery;
  const { data: myWorkshops = [] } = myWorkshopsQuery;

  const selectedPushupId = rawSelectedPushupId || pushups?.[0]?.id;
  const selectedPushup = pushups.find((pushup) => pushup.id === selectedPushupId);
  const enrolledInSelectedPushup = getIsEnrolledInSelectedPushup(myWorkshops, selectedPushupId);

  return (
    <SelectedPushupContext.Provider
      value={{ pushupsQuery, selectedPushup, setSelectedPushupId, enrolledInSelectedPushup }}
    >
      {children}
    </SelectedPushupContext.Provider>
  );
};

export const useSelectedPushup = () => useContext(SelectedPushupContext);
