import { Flex } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React from 'react';
import {
  createGetSeriesWorkshopsPlaceholderData,
  useGetWorkshopsQuery,
  useSeriesWorkshopsQuery,
} from '../../../../resources/workshops';
import { ClassCardGrid } from '../../components/ClassCardGrid';
import { ClassesFilters } from '../../components/ClassesFilters';
import ClassesFiltersContextProvider, {
  useClassesFiltersContext,
} from '../../components/ClassesFilters/ClassesFiltersContextProvider';
import { ClassesFiltersEmptyState } from '../../components/ClassesFilters/FilterComponents';

const placeholderData = createGetSeriesWorkshopsPlaceholderData({ length: 4 });

const FilteredSeriesList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const queryParams = getWorkshopFilter();
  const {
    data: series,
    isLoading: isSeriesLoading,
    isFetched: isSeriesFetched,
    isPlaceholderData,
  } = useGetWorkshopsQuery(queryParams, {
    placeholderData,
  });

  const isLoading = isSeriesLoading || isPlaceholderData || parentIsLoading;
  if (isSeriesFetched && series.length === 0) {
    return <ClassesFiltersEmptyState />;
  }

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={series}
    />
  );
};

const UnfilteredSeriesList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const seriesQuery = useSeriesWorkshopsQuery({}, { placeholderData });
  const { data: series } = seriesQuery;
  const isLoading = seriesQuery.isLoading || seriesQuery.isPlaceholderData || parentIsLoading;

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={series}
    />
  );
};

const SeriesListViewInner = () => {
  const { hasFiltersApplied } = useClassesFiltersContext();
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const props = { hasActiveSubscription, parentIsLoading: getSubscriptionsQuery.isLoading };
  return hasFiltersApplied ? (
    <FilteredSeriesList {...props} />
  ) : (
    <UnfilteredSeriesList {...props} />
  );
};

export const SeriesListView = () => (
  <ClassesFiltersContextProvider kind="series">
    <Flex width="full" mb={4}>
      <ClassesFilters showFilterText={false} />
    </Flex>
    <SeriesListViewInner />
  </ClassesFiltersContextProvider>
);
