import { Flex, Td } from '@chakra-ui/react';
import {
  useAdminCreateOnDemandVideoMutation,
  useAdminIndexOnDemandVideosQuery,
  useAdminShowOnDemandVideoQuery,
  useAdminUpdateOnDemandVideoMutation,
} from '@coa/api/controllers/admin/onDemandVideos';
import { OnDemandVideoRecord } from '@coa/api/models';
import { AdminSerializedOnDemandVideo } from '@coa/api/serializers/admin/AdminSerializedOnDemandVideo';
import { FormProps } from '@rjsf/core';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsMinutesSecondsInputWidget } from '../shared/AdminCmsMinutesSecondsInputWidget';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { onDemandVideosCmsHelperText } from './onDemandVideosCmsContent';
import { AdminCmsOnDemandVideoFormData } from './types';

const formSchema: FormProps<OnDemandVideoRecord>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: ['title', 'description', 'hostedEmbedUrl', 'therapistEmail', 'visibility'],
  properties: {
    title: {
      title: 'Title',
      type: formSchemaEntryTypes.string,
    },
    description: {
      title: 'Description',
      type: formSchemaEntryTypes.string,
    },
    hostedEmbedUrl: {
      title: 'Vimeo URL',
      type: formSchemaEntryTypes.string,
    },
    visibility: {
      title: 'Visibility',
      type: formSchemaEntryTypes.string,
      anyOf: [
        {
          type: 'string',
          title: 'Visible',
          enum: ['visible'],
        },
        {
          type: 'string',
          title: 'Hidden',
          enum: ['hidden'],
        },
      ],
    },
    /*
     * Because we are using the Therapist association (via Member)
     * rather than the Instructor association, we perform an
     * explicit email-mapping until we migrate.
     *
     * @see apps/web/app/controllers/admin/on_demand_videos_controller.rb
     */
    therapistEmail: {
      title: 'Therapist',
      type: formSchemaEntryTypes.string,
      anyOf: [
        {
          type: 'string',
          enum: ['amelia+instructor@joincoa.com'],
          title: 'Amelia Flynn, LFMT',
        },
        {
          type: 'string',
          enum: ['drjamiegoldstein@gmail.com'],
          title: 'Dr. Jamie Goldstein',
        },
        {
          type: 'string',
          enum: ['emily+app@joincoa.com'],
          title: 'Dr. Emily Anhalt',
        },
        {
          type: 'string',
          enum: ['vaneeta+app@joincoa.com'],
          title: 'Dr. Vaneeta Sandhu',
        },
        {
          type: 'string',
          enum: ['karin+app@joincoa.com'],
          title: 'Karin Gold, LMFT',
        },
        {
          type: 'string',
          enum: ['pallavi+app@joincoa.com'],
          title: 'Pallavi Yetur, LMHC',
        },
        {
          type: 'string',
          enum: ['laura@lauradesantistherapy.com'],
          title: 'Laura DeSantis, LPCC',
        },
      ],
    },
    onDemandVideoCheckpoints: {
      title: 'Video Questions',
      type: formSchemaEntryTypes.array,
      items: {
        type: formSchemaEntryTypes.object,
        required: ['title', 'timestampSeconds'],
        properties: {
          // TODO: We need to hide this or disable it or something.
          id: {
            type: formSchemaEntryTypes.string,
          },
          title: {
            title: 'Title',
            type: formSchemaEntryTypes.string,
          },
          timestampSeconds: {
            title: 'Timestamp',
            type: formSchemaEntryTypes.number,
            default: 0,
          },
        },
      },
    },
  },
};

const formUiSchema = {
  title: {
    'ui:placeholder': 'Navigating Burnout',
  },
  description: {
    'ui:placeholder':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac justo interdum, lobortis sapien ut, blandit ante. Nunc venenatis consequat enim eget tincidunt.',
    'ui:widget': 'textarea',
  },
  hostedEmbedUrl: {
    'ui:placeholder': 'https://vimeo.com/669616240',
  },
  onDemandVideoCheckpoints: {
    'ui:options': {
      // Hide the up / down buttons since we should just let
      // the backend sort the checkpoints by timestamp
      orderable: false,
    },
    items: {
      id: {
        // Hide the ID field so the user cannot change it
        // but it is still passed if present.
        'ui:widget': 'hidden',
      },
      timestampSeconds: {
        'ui:widget': AdminCmsMinutesSecondsInputWidget,
      },
    },
  },
};

export const AdminOnDemandVideosCmsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CmsContextProvider<
    OnDemandVideoRecord,
    AdminSerializedOnDemandVideo,
    AdminCmsOnDemandVideoFormData
  >
    value={{
      baseRouterPath: '/admin-dash/cms/on-demand-videos',
      contentTypeTitle: 'On-Demand Video',
      contentHelperText: onDemandVideosCmsHelperText,
      searchPlaceholder: 'Search videos by title, eg "Adulting"',

      formSchema,
      formUiSchema,

      useShowQuery: useAdminShowOnDemandVideoQuery,
      useIndexQuery: useAdminIndexOnDemandVideosQuery,
      useUpdateMutation: useAdminUpdateOnDemandVideoMutation,
      useCreateMutation: useAdminCreateOnDemandVideoMutation,

      getEntryTitle: ({ title }) => title,
      indexThEntries: [{ name: 'title', label: 'Title ' }],
      renderIndexEntryTdContents: function OnDemandVideoIndexEntryTd({
        title,
      }: AdminSerializedOnDemandVideo) {
        return (
          <Td>
            <Flex alignItems="center">
              <strong>{title}</strong>
            </Flex>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminOnDemandVideosView = () => (
  <AdminOnDemandVideosCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/on-demand-videos/new">
        <AdminCmsCreateView<
          OnDemandVideoRecord,
          AdminSerializedOnDemandVideo,
          AdminCmsOnDemandVideoFormData
        > />
      </Route>
      <Route path="/admin-dash/cms/on-demand-videos/:id">
        <AdminCmsShowView<
          OnDemandVideoRecord,
          AdminSerializedOnDemandVideo,
          AdminCmsOnDemandVideoFormData
        > />
      </Route>
      <Route path="/admin-dash/cms/on-demand-videos">
        <AdminCmsIndexView<
          OnDemandVideoRecord,
          AdminSerializedOnDemandVideo,
          AdminCmsOnDemandVideoFormData
        > />
      </Route>
    </Switch>
  </AdminOnDemandVideosCmsContextProvider>
);
