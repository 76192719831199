import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FiRotateCcw, FiSlash } from 'react-icons/fi';
import { MdAccessTime, MdBlock, MdDone, MdQueryBuilder, MdReplay } from 'react-icons/md';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';

const LABELS = {
  awaiting_payment: { label: 'Awaiting Payment', icon: <MdQueryBuilder /> },
  in_process: { label: 'In Process', icon: <MdAccessTime /> },
  card_declined: { label: 'Card Declined', icon: <MdBlock /> },
  paid: { label: 'Paid', icon: <MdDone /> },
  uncollectible: { label: 'Uncollectible', icon: <MdBlock /> },
  void: { label: 'Voided', icon: <MdBlock /> },
  refunded: { label: 'Refunded', icon: <MdReplay /> },
};

function Invoices({
  viewerRole,
  invoices,
  invoiceMembers,
  payInvoice,
  refundInvoice,
  voidInvoice,
}) {
  const getRelevantMembersFromInvoice = (invoice) => {
    if (viewerRole === 'therapist') {
      const clientIds = invoice.relationships.clients.data.map((c) => c.id);
      return invoiceMembers.filter((c) => clientIds.indexOf(c.id) !== -1);
    }
    const therapistId = invoice.relationships.therapist.data.id;
    return invoiceMembers.filter((c) => therapistId === c.id);
  };

  return (
    <Box width="stretch" maxWidth="80%">
      <Heading alignSelf="flex-start" my={6}>
        Invoices
      </Heading>
      <Center>
        <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
          <Table aria-label="Invoices">
            <TableHead>
              <TableRow>
                <TableHeader>{viewerRole === 'therapist' ? 'Client' : 'Therapist'}</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => {
                const status = LABELS[invoice.attributes.status];
                const relevantMembers = getRelevantMembersFromInvoice(invoice);
                return (
                  <TableRow key={invoice.id}>
                    <TableCell>
                      {relevantMembers.map((c) => c.attributes.name).join(' and ')}
                    </TableCell>
                    <TableCell>
                      <Box>{status.label}</Box>
                    </TableCell>
                    <TableCell>
                      Sent on &nbsp;
                      {moment(invoice.attributes.created_at).format('MMM Do, YYYY')}
                    </TableCell>
                    <TableCell>${(invoice.attributes.amount.cents / 100).toFixed(2)}</TableCell>
                    {viewerRole === 'therapist' ? (
                      <TableCell>
                        {invoice.attributes.status === 'paid' && (
                          <Button colorScheme="orange" onClick={() => refundInvoice(invoice)}>
                            Refund
                          </Button>
                        )}
                        {['in_process', 'card_declined', 'awaiting_payment'].indexOf(
                          invoice.attributes.status
                        ) !== -1 && (
                          <Button colorScheme="orange" onClick={() => voidInvoice(invoice)}>
                            Void
                          </Button>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>
                        {invoice.attributes.status === 'awaiting_payment' && (
                          <Button colorScheme="evergreen" onClick={() => payInvoice(invoice)}>
                            Pay Invoice
                          </Button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Center>
      <VStack width="stretch" display={{ sm: 'block', md: 'none' }}>
        {invoices.map((invoice) => {
          const status = LABELS[invoice.attributes.status];
          const relevantMembers = getRelevantMembersFromInvoice(invoice);
          return (
            <Flex w="stretch" key={invoice.id} borderTopWidth="1px" align="center">
              <Stack flexGrow={1}>
                <Text>{relevantMembers.map((c) => c.attributes.name).join(' and ')}</Text>
                <Text>{status.label}</Text>
              </Stack>
              <Stack flexGrow={1} textAlign="right">
                <Text>${(invoice.attributes.amount.cents / 100).toFixed(2)}</Text>
                <Text>
                  Sent on &nbsp;
                  {moment(invoice.attributes.created_at).format('MMM Do, YYYY')}
                </Text>
              </Stack>
              {viewerRole === 'therapist' ? (
                <Box>
                  {invoice.attributes.status === 'paid' && (
                    <IconButton onClick={() => refundInvoice(invoice)} icon={<FiRotateCcw />} />
                  )}
                  {['in_process', 'card_declined', 'awaiting_payment'].indexOf(
                    invoice.attributes.status
                  ) !== -1 && (
                    <IconButton onClick={() => voidInvoice(invoice)} icon={<FiSlash />} />
                  )}
                </Box>
              ) : null}
            </Flex>
          );
        })}
      </VStack>
    </Box>
  );
}

Invoices.propTypes = {
  invoices: PropTypes.array.isRequired,
  invoiceMembers: PropTypes.array.isRequired,
  viewerRole: PropTypes.string.isRequired,
  payInvoice: PropTypes.func.isRequired,
  refundInvoice: PropTypes.func.isRequired,
  voidInvoice: PropTypes.func.isRequired,
};

export default Invoices;
