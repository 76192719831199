import type { SerializedAuthenticatedMember } from '@coa/api/serializers/v1';
import { createLocalStorageClient } from './createLocalStorageClient';

const currentMemberLocalStorageClient = createLocalStorageClient<'currentMember'>('currentMember');

export const sessionLocalStorage = {
  getCurrentMember: (): SerializedAuthenticatedMember | null =>
    currentMemberLocalStorageClient.get(),
  create: (member: SerializedAuthenticatedMember) => {
    currentMemberLocalStorageClient.set(member);
  },
  destroy: () => {
    currentMemberLocalStorageClient.remove();
  },
};
