import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { create } from '../../../actions/sessions';

function LogIn({ email, password, loading, errors, dispatch }) {
  return (
    <VStack>
      <Box width="stretch">
        <Formik
          initialValues={{
            email,
            password,
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            dispatch(create(values)).then((result) => {
              setSubmitting(false);
              if (result.errors) setErrors(result.errors);
            });
          }}
        >
          {() => (
            <Form>
              <Field name="email">
                {({ form, field }) => (
                  <FormControl isInvalid={form.errors?.email && form.touched?.email}>
                    <FormLabel mt={3} htmlFor="email">
                      Email
                    </FormLabel>
                    <Input
                      {...field}
                      type="email"
                      id="email"
                      placeholder="Email"
                      variant="coa-main"
                      data-cy="input-email"
                    />
                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ form, field }) => (
                  <FormControl isInvalid={form.errors?.password && form.touched?.password}>
                    <FormLabel mt={3} htmlFor="password">
                      Password
                    </FormLabel>
                    <Input
                      {...field}
                      type="password"
                      id="password"
                      placeholder="Password"
                      data-cy="input-password"
                      variant="coa-main"
                    />
                    <FormErrorMessage>{errors?.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Stack my={4} align="center" justify="space-between" isInline>
                <Checkbox variant="coa-main">Remember Me</Checkbox>
                <Link color="green.900" as={RouterLink} to="/forgot-password" variant="body2">
                  Forgot Password
                </Link>
              </Stack>
              <Button
                type="submit"
                variant="primary"
                width="full"
                my={4}
                disabled={loading}
                data-cy="submit"
              >
                {loading ? 'Signing in...' : 'Sign in'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
}

LogIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  updateAttr: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LogIn;
