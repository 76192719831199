import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useGetWorkshopOccurrenceSiblingsQuery,
  useGetWorkshopQuery,
  useGetWorkshopSiblingsQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../../resources/workshops';

type AlternateWorkshopOccurrenceProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
  getLinkRoute: (_params: { id: string; dropInWorkshopOccurrenceId: string }) => string;
};

type PickedWorkshopOccurrenceState = Pick<
  AlternateWorkshopOccurrenceProps,
  'workshopId' | 'dropInWorkshopOccurrenceId'
> & {
  linkRoute: string;
};

/*
 * Hook that will pre-load workshop / occurrence data relevant to a particular
 * workshop and then navigate to it. In particular, this is helpful in our
 * various SiblingPicker components, as, when a sibling is chosen, the entire
 * page will go into a loading state prior upon navigation if the data hasn't
 * yet been fetched, which creates sorta a wonky experience.
 *
 * To get around this, manage the fetch / loading / navigation internally here
 * and allow the component to use it.
 */
export const usePickAndNavigateToAlternateWorkshopRouterPath = () => {
  const [
    {
      workshopId: pickedAlternateWorkshopId,
      dropInWorkshopOccurrenceId: pickedAlternateDropInWorkshopOccurrenceId,
      linkRoute: pickedAlternateWorkshopOccurrenceLinkRoute,
    },
    setPickedWorkshopState,
  ] = useState<PickedWorkshopOccurrenceState>({} as PickedWorkshopOccurrenceState);
  const getPickedAlternateWorkshopQuery = useGetWorkshopQuery(
    { id: pickedAlternateWorkshopId },
    { enabled: Boolean(pickedAlternateWorkshopId) }
  );
  const getPickedAlternateWorkshopOccurrenceSiblingsQuery = useGetWorkshopOccurrenceSiblingsQuery(
    { id: pickedAlternateDropInWorkshopOccurrenceId },
    { enabled: Boolean(pickedAlternateDropInWorkshopOccurrenceId) }
  );
  const getPickedAlternateWorkshopSiblingsQuery = useGetWorkshopSiblingsQuery(
    { id: pickedAlternateWorkshopId },
    { enabled: Boolean(pickedAlternateWorkshopId) }
  );

  /*
   * The downstream fetch-siblings logic (i.e. managing loading / success states)
   * does not change regardless of which query we rely on, so we consolidate here.
   */
  const getPickedAlternateSiblingsQuery = pickedAlternateDropInWorkshopOccurrenceId
    ? getPickedAlternateWorkshopOccurrenceSiblingsQuery
    : getPickedAlternateWorkshopSiblingsQuery;

  const pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath = useCallback(
    (params: PickedWorkshopOccurrenceState) => {
      setPickedWorkshopState(params);
    },
    []
  );

  const history = useHistory();
  useEffect(() => {
    if (getPickedAlternateWorkshopQuery.isSuccess && getPickedAlternateSiblingsQuery.isSuccess) {
      history.push(pickedAlternateWorkshopOccurrenceLinkRoute);
    }
  }, [
    pickedAlternateWorkshopId,
    getPickedAlternateWorkshopQuery.isSuccess,
    getPickedAlternateSiblingsQuery.isSuccess,
  ]);

  return {
    pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath,
    getPickedAlternateWorkshopQuery,
    getPickedAlternateSiblingsQuery,
  };
};
