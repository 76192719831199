import { Button, Center, Heading, Image, useToast } from '@chakra-ui/react';
import { Container, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import * as therapistActions from '../../actions/admin/therapists';
import Loading from '../Loading';

export const THERAPIST_FORM_CREATE = 'create';
export const THERAPIST_FORM_UPDATE = 'update';

export const equalIds = (one, other) => one?.id === other?.id;

// export type TherapistFormProps = {
//   workshop?: Workshop,
//   mode?: THERAPIST_FORM_CREATE | THERAPIST_FORM_UPDATE,
// }

const TherapistSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email'),
});

const TherapistForm = ({ mode = THERAPIST_FORM_CREATE }) => {
  const history = useHistory();
  const { id: therapistId } = useParams();

  const dispatch = useDispatch();
  const therapist = useSelector((state) => state.admin.therapists.therapists[therapistId]);

  const imageInputRef = useRef(null);
  const [imagePreviewData, setImagePreviewData] = useState(null);

  const toast = useToast();

  useEffect(() => {
    if (mode === THERAPIST_FORM_UPDATE && !therapist) {
      dispatch(therapistActions.list());
    }
  }, [mode, therapist, dispatch]);

  const therapistAttributes = therapist?.attributes;

  const initialFormValues = useMemo(
    () => ({
      name: therapistAttributes?.name || '',
      email: therapistAttributes?.email || '',
    }),
    [therapistAttributes?.name, therapistAttributes?.email]
  );

  if (mode === THERAPIST_FORM_UPDATE && !therapist)
    return (
      <Center>
        <Loading />
      </Center>
    );

  return (
    <Container maxWidth="sm">
      <Heading my={5}>
        {mode === THERAPIST_FORM_UPDATE ? 'Edit a Therapist' : 'Add a New Therapist'}
      </Heading>
      <Formik
        initialValues={initialFormValues}
        validationSchema={TherapistSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const action = therapist
            ? therapistActions.update(therapist.id, values)
            : therapistActions.create(values);
          dispatch(action)
            .then((result) => {
              if (result.errors) {
                setErrors(result.errors);
                toast({
                  title: 'Failed to save. See errors above.',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              } else {
                toast({
                  title: therapist ? 'Therapist updated!' : 'Therapist created!',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                history.push('/admin-dash/therapists');
              }
            })
            .catch((error) => alert(JSON.stringify(error)))
            .finally(() => setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form>
            <Grid container direction="column" alignItems="stretch" spacing={2} width="auto">
              <Grid item>
                <Field
                  component={TextField}
                  label="Name"
                  id="name"
                  name="name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  component={TextField}
                  label="Email"
                  id="email"
                  name="email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <input
                  type="file"
                  ref={imageInputRef}
                  accept="image/*"
                  hidden
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0];
                    if (file) {
                      formikProps.setFieldValue('headshot', file);
                      const reader = new FileReader();
                      reader.onload = (e) => setImagePreviewData(e.target.result);
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <Image
                  onClick={() => imageInputRef.current?.click()}
                  maxH="100px"
                  minH="100px"
                  minW="100px"
                  objectFit="contain"
                  cursor="pointer"
                  src={
                    imagePreviewData ||
                    therapist?.attributes?.image_url ||
                    'https://ipsumimage.appspot.com/150x100?l=Upload+image...'
                  }
                />
              </Grid>
              <Button
                m={2}
                width={{ base: 'auto', md: '20%' }}
                isLoading={formikProps.isSubmitting}
                type="submit"
                name="create"
                colorScheme="evergreen"
              >
                {mode === THERAPIST_FORM_CREATE ? 'Create' : 'Update'}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TherapistForm;
