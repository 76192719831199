import { DiversitySurveyAnswer } from './types';

const diversitySurveyAnswerKeyToReadableStatement: {
  [s in DiversitySurveyAnswer]: string;
} = {
  bipoc_community: "I'm a member of BIPOC community/communities.",
  disabilities_community: "I'm a member of the disabilities community/communities.",
  lgbtq_community: "I'm a member of the LGBTQ+ community/communities.",
  none: "I'm not a member of any of the above communities.",
  preferred_no_response: "I'd prefer not to answer.",
};

export const getReadableDiversitySurveyAnswer = (key: DiversitySurveyAnswer) =>
  diversitySurveyAnswerKeyToReadableStatement[key];
