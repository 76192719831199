import {
  ENROLLMENT_REQUEST,
  ENROLLMENT_SUCCESS,
  ENROLLMENT_UPDATE_SUCCESS,
} from '../actions/enrollments';

const DEFAULT_STATE = {
  enrollment: null,
  gettingEnrollment: true,
};

export function enrollments(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ENROLLMENT_REQUEST: {
      return DEFAULT_STATE;
    }
    case ENROLLMENT_UPDATE_SUCCESS:
    case ENROLLMENT_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, enrollment: data, gettingEnrollment: false };
    }
    default:
      return state;
  }
}
