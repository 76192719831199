import { ValueOf } from '@coa/types';

export const memberRole = {
  client: 'client',
  therapist: 'therapist',
  admin: 'admin',
} as const;

export const memberStatus = {
  invited: 'invited',
  registered: 'registered',
} as const;

export const diversitySurveyAnswer = {
  bipocCommunity: 'bipoc_community',
  disabilitiesCommunity: 'disabilities_community',
  lgbtqCommunity: 'lgbtq_community',
  none: 'none',
  preferredNoResponse: 'preferred_no_response',
} as const;

export type DiversitySurveyAnswer = ValueOf<typeof diversitySurveyAnswer>;

export type MemberRole = ValueOf<typeof memberRole>;
export type MemberStatus = ValueOf<typeof memberStatus>;

// Alias to leave open for future specification.
export type MemberPronouns = string;

export type MemberRecord = {
  id: string;
  name: string;
  email: string;
  role: MemberRole;
  status: MemberStatus;
  token: string;
  // We should never have to access this on the frontend
  // passwordDigest: string;

  pronouns: MemberPronouns;
  birthday: string;
  city: string;
  phone: string;
  occupation: string;
  channel: string;
  diversity: DiversitySurveyAnswer[];
  completedProfile: boolean;
};

/*
 *  Note that technically there are other properties that live on the Member
 *  but are only serialized on the "Account" resource, so we omit them here.
 *  Ultimately, this - and all other types - should be renamed to "Serialized*"
 *  and should exist alongside a "*Record" type (as above). For the time being,
 *  we just alias.
 */
export type Member = {
  id: string;
  name: string;
  email: string;
  token: string;
  role: MemberRole;
  status: MemberStatus;
  pronouns: MemberPronouns;
  // HUBSPOT MARKETING FIELDS
  signupKind?: string;
};

export type SerializedMember = Member;

export type AdminMemberFilter = {
  query: string;
};
