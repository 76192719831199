import { Flex, Skeleton, useToast } from '@chakra-ui/react';
import { useOnMount } from '@coa/react-utils';
import { Form } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { tryQAndAAnalytics } from '../../../lib/analytics/clients/tryQAndAAnalytics';
import { usePostWorkshopEnrollmentMutation } from '../../../resources/workshops';
import {
  TryLayout,
  TryLayoutMainContent,
  TryLayoutSecondaryContent,
} from '../components/TryWorkshopLayout';
import { TryWorkshopNoUpcomingBtn } from '../components/TryWorkshopNoUpcomingBtn';
import {
  TryWorkshopRegistrationForm,
  TryWorkshopRegistrationFormikContainer,
  TryWorkshopRegistrationFormSubmitButton,
} from '../components/TryWorkshopRegistrationForm';
import { TryWorkshopLandingStaticContent } from '../components/TryWorkshopStaticContent';
import {
  TryUserExistsProvider,
  useTryUserExists,
} from '../components/TryWorkshopUserExistsProvider';
import {
  SelectedQAndALandingProvider,
  useSelectedQAndALanding,
} from './components/SelectedQAndAProvider';
import { TryQAndASuccess } from './components/TryQAndASuccess';

export function TryQAndAViewInner() {
  const {
    enrolledInSelectedQAndA,
    qAndAQuery,
    selectedQAndA,
    setSelectedQAndAId,
  } = useSelectedQAndALanding();
  const toast = useToast();
  const workshopEnrollmentMutation = usePostWorkshopEnrollmentMutation({ id: selectedQAndA?.id });
  const { userExists } = useTryUserExists();

  useOnMount(() => {
    tryQAndAAnalytics.track('Viewed Try Q&A');
  });

  useEffect(() => {
    if (workshopEnrollmentMutation.isSuccess) {
      tryQAndAAnalytics.track('Enrolled in Q&A', { workshopId: selectedQAndA?.id });
    }
  }, [workshopEnrollmentMutation]);

  const handleRegistrationSuccess = useCallback(async () => {
    try {
      await workshopEnrollmentMutation.mutateAsync({
        answers: {},
      });
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);

  const { isLoading } = qAndAQuery;
  const showSuccessState = workshopEnrollmentMutation.isSuccess;
  const hasUpcomingQAndAs = qAndAQuery.data.length > 0;
  return (
    <TryLayout>
      <TryWorkshopRegistrationFormikContainer
        userExists={userExists}
        handleRegistrationSuccess={handleRegistrationSuccess}
      >
        <>
          <TryLayoutMainContent>
            <TryWorkshopLandingStaticContent
              hasUpcomingWorkshop={hasUpcomingQAndAs}
              type="q_and_a"
              tryWorkshopQuery={qAndAQuery}
              selectedTryWorkshopId={selectedQAndA?.id}
              setSelectedTryWorkshopId={setSelectedQAndAId}
              enrolledInSelectedWorkshop={enrolledInSelectedQAndA}
              loading={isLoading}
            />
          </TryLayoutMainContent>
          <TryLayoutSecondaryContent>
            <Skeleton isLoaded={!isLoading}>
              {!hasUpcomingQAndAs ? (
                <TryWorkshopNoUpcomingBtn type="q_and_a" />
              ) : (
                <Flex justifyContent="center" flexDir="column" as={Form}>
                  {showSuccessState ? (
                    <TryQAndASuccess selectedWorkshop={selectedQAndA} />
                  ) : (
                    <>
                      <TryWorkshopRegistrationForm
                        enrolledInSelectedWorkshop={enrolledInSelectedQAndA}
                        workshopType="q_and_a"
                        isB2B={selectedQAndA?.isB2B}
                      />
                      <TryWorkshopRegistrationFormSubmitButton
                        colorScheme="red"
                        type="submit"
                        size="lg"
                        enrolledInSelectedWorkshop={enrolledInSelectedQAndA}
                        workshopType="q_and_a"
                      />
                    </>
                  )}
                </Flex>
              )}
            </Skeleton>
          </TryLayoutSecondaryContent>
        </>
      </TryWorkshopRegistrationFormikContainer>
    </TryLayout>
  );
}

export function TryQAndALandingView() {
  return (
    <SelectedQAndALandingProvider>
      <TryUserExistsProvider>
        <TryQAndAViewInner />
      </TryUserExistsProvider>
    </SelectedQAndALandingProvider>
  );
}
