import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useOrderSummary } from './SubscribeModalOrderSummaryProvider';

/*
 * This input is no longer wrapped in a form
 * We are currently using it as a field within the Subscribe Form,
 * but the submission of this input happens independent of the SubscribeForm <form> submission.
 * Because we cannot nest forms, this is now simply an input that has a button (Apply Promo Code)
 * that fires off an API call. It live's within the SubscribeForm, per current design. - BC (05/31/22)
 */
export const SubscribeFormPromoCodeInput = (props: BoxProps) => {
  const {
    requestedPromoCode,
    setRequestedPromoCode,
    getSubscriptionOrderSummaryQuery,
  } = useOrderSummary();
  const { data: orderSummary, isPreviousData: isSubmitting } = getSubscriptionOrderSummaryQuery;
  const isInvalid = requestedPromoCode && requestedPromoCode === orderSummary.promotionCode?.code;

  const { isOpen: rawIsOpen, onOpen, onClose } = useDisclosure();
  const [{ promoCodeEntry, dirty }, setState] = useState<{
    promoCodeEntry: string;
    dirty: boolean;
  }>({ promoCodeEntry: '', dirty: false });

  // We close the input on blur if there's no value in the input.
  const isInputOpen = rawIsOpen || Boolean(promoCodeEntry);
  const handleSubmit = () => {
    setState((currentState) => ({ ...currentState, dirty: false }));
    setRequestedPromoCode(promoCodeEntry);
  };

  return (
    <Box {...props}>
      <motion.div
        initial={false}
        animate={{ width: isInputOpen ? '100%' : '156px' }}
        transition={{ type: 'tween', duration: 0.125, delay: 0 }}
        style={{ overflow: 'hidden' }}
      >
        <InputGroup>
          <Input
            type="text"
            onFocus={onOpen}
            onBlur={onClose}
            placeholder={isInputOpen ? '' : 'Add a promo code'}
            bg={isInputOpen ? 'grayAlpha.200' : 'whiteAlpha.200'}
            fontWeight={isInputOpen ? 'normal' : 'bold'}
            borderColor="transparent"
            padding={isInputOpen ? null : 0}
            _hover={isInputOpen ? undefined : { borderColor: 'transparent', bg: 'whiteAlpha.300' }}
            _active={isInputOpen ? undefined : { borderColor: 'transparent', bg: 'whiteAlpha.400' }}
            cursor={isInputOpen ? 'auto' : 'pointer'}
            color="gray.800"
            _placeholder={{
              color: isInputOpen ? 'gray.400' : 'evergreen.200',
            }}
            value={isInputOpen ? promoCodeEntry : ''}
            onChange={(event) => {
              const { value } = event.target;
              setState((currentState) => ({
                ...currentState,
                dirty: true,
                promoCodeEntry: value,
              }));
            }}
            data-cy="subscribe-promo-code-input"
          />
          {isInputOpen ? (
            <InputRightElement width="4rem">
              <Button
                type="submit"
                colorScheme="evergreen"
                variant="link"
                _hover={{
                  color: 'evergreen.400',
                  textDecoration: 'none',
                }}
                opacity={Boolean(promoCodeEntry) ? '1' : '0'}
                isLoading={isSubmitting}
                data-cy="subscribe-promo-code-submit"
                onClick={handleSubmit}
              >
                Apply
              </Button>
            </InputRightElement>
          ) : null}
        </InputGroup>
      </motion.div>
      <Collapse in={isInvalid && !dirty}>
        <Text color="grayAlpha.700">This promo code is invalid.</Text>
      </Collapse>
    </Box>
  );
};
