import { Box } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React, { useEffect, useRef } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import { GenericSubscribeSuccessState } from './components/GenericSubscribeModalSuccessState';
import { SubscribeModal } from './components/SubscribeModal';

export const GenericSubscribeModal = () => {
  const prevPathOnModalOpenRef = useRef<Location['pathname']>(`/my-coa`);
  const lastLocation = (useLastLocation() as unknown) as Location;
  const history = useHistory();
  const location = useLocation();

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isOpen = Boolean(
    matchPath(location.pathname, {
      path: '/my-coa/sign-up',
    })
  );

  useEffect(() => {
    if (isOpen && lastLocation) {
      prevPathOnModalOpenRef.current = lastLocation.pathname;
    }
  }, [isOpen]);

  // Account for edge case: If a user with a Membership accidentally
  // navigates to the 'sign-up' url we route them back to the app.
  // It might make sense to route them to the Marketing site once the
  // membership page is built out.
  useEffect(() => {
    if (hasActiveSubscription) {
      history.push(prevPathOnModalOpenRef.current);
    }
  }, [getSubscriptionsQuery.isLoading]);

  return (
    <SubscribeModal
      backRouterUrl={prevPathOnModalOpenRef.current}
      isOpen={isOpen}
      renderSubscribeSuccessState={() => (
        <Box minHeight={{ base: '90vh', md: '70vh' }}>
          <GenericSubscribeSuccessState invertColors />
        </Box>
      )}
    />
  );
};
