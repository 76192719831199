import { useEffect } from 'react';

/*
 * Syntactical sugar around useEffect, since its
 * behavior on mount isn't always intuitive for those
 * less familiar with its API.
 */
export const useOnMount = (fn: () => void) => {
  useEffect(fn, []);
};
