import { Box, Container, Grid, GridItem, Heading, Link, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FullClassListing } from '../../components/molecules/ClassListing';
import { TransformUpLink } from '../../components/molecules/TransformUpLink';
import { WorkshopOccurrenceExerciseCta } from '../../components/WorkshopExerciseCta';
import { classMarketplaceAnalytics } from '../../lib/analytics/clients';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  formatWorkshopOccurrenceTimeLabel,
  getIsSeriesLike,
  useUpcomingEnrolledWorkshopOccurrenceQuery,
  useUpcomingWorkshopOccurrencesQuery,
} from '../../resources/workshops';
import { getWorkshopRouterUrl } from '../../routerPaths/helpers';
import { ClassRecommendationSection } from './classRecommendation/ClassRecommendationSection';
import { UpcomingWorkshopOccurrence } from './upcomingClass/upcomingWorkshopOccurrence';

const getUpcomingWorkshopOccurrencesPlaceholderData = createGetDropInWorkshopOccurrencesPlaceholderData(
  { length: 6 }
);
const getUpcomingEnrolledWorkshopOccurrencePlaceholderData =
  getUpcomingWorkshopOccurrencesPlaceholderData[0];

export const ScheduleView = () => {
  const location = useLocation();
  const pathnameSections = location.pathname.split('/');
  const pathIndex = pathnameSections[1];
  useEffect(() => {
    if (location.pathname === '/my-coa') {
      classMarketplaceAnalytics.track('Viewed My Coa Page', { tab: 'Schedule' });
    }
  }, [pathIndex]);

  const upcomingWorkshopOccurrencesQuery = useUpcomingWorkshopOccurrencesQuery({
    placeholderData: getUpcomingWorkshopOccurrencesPlaceholderData,
  });
  const upcomingEnrolledWorkshopOccurrenceQuery = useUpcomingEnrolledWorkshopOccurrenceQuery({
    placeholderData: getUpcomingEnrolledWorkshopOccurrencePlaceholderData,
  });
  const { data: upcomingWorkshopOccurrences } = upcomingWorkshopOccurrencesQuery;

  const isLoading =
    upcomingEnrolledWorkshopOccurrenceQuery.isLoading ||
    upcomingWorkshopOccurrencesQuery.isLoading ||
    upcomingWorkshopOccurrencesQuery.isPlaceholderData;

  const { data: upcomingEnrolledWorkshopOccurrence } = upcomingEnrolledWorkshopOccurrenceQuery;
  const upcomingEnrolledWorkshopOccurrences = _.filter([upcomingEnrolledWorkshopOccurrence]);

  // Workshop Occurrences with the upcoming enrolled one removed since its display is
  // handled separately.
  const filteredUpcomingWorkshopOccurrences = _.filter(
    upcomingWorkshopOccurrences,
    ({ id }) => id !== upcomingEnrolledWorkshopOccurrence?.id
  );

  return (
    <Container maxW="100%" p="0" mb={10}>
      {upcomingEnrolledWorkshopOccurrences.length > 0 ? (
        <Box data-cy={`my-coa-your-next-class-${isLoading ? 'loading' : 'loaded'}`}>
          <Skeleton isLoaded={!isLoading} width="100%">
            <Heading size="md" mb={6}>
              Your Next Class
            </Heading>
            <Box
              width="auto"
              backgroundColor="gray.50"
              py={{ base: 8, md: 10 }}
              px={{ base: 4, md: 10 }}
              mb={10}
              mx={{ base: -4, md: 0 }}
            >
              <UpcomingWorkshopOccurrence
                upcomingClasses={upcomingEnrolledWorkshopOccurrences}
                isLoading={isLoading}
                displayExerciseCta
              />
            </Box>
          </Skeleton>
        </Box>
      ) : null}

      {upcomingEnrolledWorkshopOccurrences.length === 0 ? (
        <Box mb={10}>
          <Skeleton isLoaded={!isLoading} width="100%">
            <ClassRecommendationSection />
          </Skeleton>
        </Box>
      ) : null}

      <Box>
        <Skeleton isLoaded={!isLoading} width="auto">
          <Heading size="md" mb={6}>
            Your Upcoming Classes
          </Heading>
        </Skeleton>
        <Grid
          templateRows="repeat(1, 1fr)"
          width="100%"
          columnGap={{ base: 0, md: 8 }}
          rowGap={8}
          data-cy={`my-coa-upcoming-classes-${isLoading ? 'loading' : 'loaded'}`}
        >
          {filteredUpcomingWorkshopOccurrences.length > 0 ? (
            filteredUpcomingWorkshopOccurrences.map((occurrence) => {
              const workshopRouterUrl = getWorkshopRouterUrl({
                id: occurrence.workshop.id,
                dropInWorkshopOccurrenceId: getIsSeriesLike(occurrence.workshop.kind)
                  ? occurrence.id
                  : undefined,
              });
              return (
                <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} key={occurrence.id}>
                  <Skeleton isLoaded={!isLoading}>
                    <TransformUpLink as={RouterLink} to={workshopRouterUrl} width="100%">
                      <FullClassListing
                        label={occurrence.workshop.marketListingLabel}
                        title={occurrence.title}
                        date={formatWorkshopOccurrenceTimeLabel(occurrence)}
                        instructorName={occurrence.therapist.name}
                        instructorImage={occurrence.therapist.imageUrl}
                        src={occurrence.workshop.imageUrl}
                        colorScheme="gray"
                        description={occurrence.description}
                        renderCta={(props) => (
                          <WorkshopOccurrenceExerciseCta
                            workshopId={occurrence.workshop.id}
                            exerciseId={occurrence.exerciseId}
                            occurrenceStartsAt={occurrence.startsAt}
                            width={{ base: '100%', lg: 'auto' }}
                            {...props}
                          />
                        )}
                      />
                    </TransformUpLink>
                  </Skeleton>
                </GridItem>
              );
            })
          ) : upcomingEnrolledWorkshopOccurrences.length === 1 ? (
            <Text>You are currently only enrolled in the class above.</Text>
          ) : (
            <Text>
              Your schedule is empty. Browse all classes{' '}
              <Link as={RouterLink} to="/classes" _hover={{ textDecoration: 'underline' }}>
                here.
              </Link>
            </Text>
          )}
        </Grid>
      </Box>
    </Container>
  );
};
