import { deepMapKeys } from '@coa/stdlib/object';
import { serverCase } from '@coa/stdlib/string';
import { AnyObject } from '@coa/types';

type EventAttrs<EventName, EventNameToEventBody> = EventName extends keyof EventNameToEventBody
  ? EventNameToEventBody[EventName]
  : undefined;

type AnalyticsFn<EventName, EventNameToEventBody> = (
  event: EventName,
  eventAttrs?: EventAttrs<EventName, EventNameToEventBody>
) => void;

type BaseEventAttrs = {
  currentAppPath: string;
};

export type SafeAnalyticsClient<EventName extends string, EventNameToEventBody> = {
  identify: AnalyticsFn<EventName, EventNameToEventBody>;
  track: AnalyticsFn<EventName, EventNameToEventBody>;
};

/*
 * Wrap all track / identify calls in try / catch so that in the
 * event that we can't reach Segment, the user is unaffected.
 */
export const createSafeAnalyticsClient = <EventName extends string, EventNameToEventBody>({
  getBaseAttrs = () => ({} as BaseEventAttrs),
}: {
  getBaseAttrs?: () => BaseEventAttrs;
} = {}): SafeAnalyticsClient<EventName, EventNameToEventBody> => {
  const getAllAttrs = (eventAttrs?: EventAttrs<EventName, EventNameToEventBody>) => ({
    ...getBaseAttrs(),
    ...(eventAttrs || {}),
  });

  const identify: AnalyticsFn<EventName, EventNameToEventBody> = (event, eventAttrs) => {
    try {
      /*
       * Note that we convert event case for the sake of consistency
       * since many of these attributes were first issued from
       * the backend.
       */
      const attrs = getAllAttrs(eventAttrs);
      analytics.identify(event, deepMapKeys(attrs, serverCase));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  const track: AnalyticsFn<EventName, EventNameToEventBody> = (event, eventAttrs) => {
    try {
      const attrs = getAllAttrs(eventAttrs);
      analytics.track(event, deepMapKeys(attrs, serverCase));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  return {
    identify,
    track,
  };
};

/*
 * Legacy generic untyped analytics client.
 */
export const SafeAnalytics = createSafeAnalyticsClient<string, AnyObject>();
