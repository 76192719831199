import { Avatar, Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { PlayIcon } from '../../../../../../components/Icons';
import Background from '../../../../../../images/background.png';
import { OnDemandVideoCheckpoint } from '../../../../../../resources/onDemandVideoCheckpoints';

type VideoCheckpointTitleScreenProps = {
  checkpoint: OnDemandVideoCheckpoint;
};

export const VideoCheckpointTitleScreen = ({ checkpoint }: VideoCheckpointTitleScreenProps) => (
  <Box
    position="absolute"
    top="0"
    left="0"
    height="100%"
    width="100%"
    bg="brand.forest"
    pointerEvents="none"
  >
    <Flex flexDir={{ base: 'column-reverse', md: 'row' }} height="100%">
      <VStack
        color="white"
        w={{ base: '100%', md: '60%' }}
        p={{ base: 8, md: 10 }}
        height={{ base: '90%', md: '100%' }}
        alignItems="flex-start"
        justifyContent="center"
        spacing={{ base: 6, md: 12 }}
      >
        <Flex>
          <PlayIcon boxSize={4} mr={2} />
          <Heading fontSize={{ base: 'sm', md: 'md' }}>{checkpoint.onDemandVideo.title}</Heading>
        </Flex>
        <Heading fontSize={{ base: 'md', md: '2xl' }}>{checkpoint.title}</Heading>
        <Flex alignItems="center">
          <Avatar
            boxSize={{ base: '22px', md: '40px' }}
            name={checkpoint.therapist.name}
            src={checkpoint.therapist.imageUrl}
            mr={4}
          />
          <Text fontSize={{ base: 'xs', md: 'md' }} fontWeight="bold">
            {checkpoint.therapist.name}
          </Text>
        </Flex>
      </VStack>
      <Box
        height={{ base: '10%', md: '100%' }}
        w={{ base: '100%', md: '40%' }}
        display="block"
        backgroundImage={Background}
        bgSize="cover"
        bgPosition="center"
      />
    </Flex>
  </Box>
);
