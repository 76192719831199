import {
  Box,
  BoxProps,
  ButtonProps,
  Container,
  Flex,
  Heading,
  Link,
  Skeleton,
  SkeletonText,
  Spacer,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { formatProseInt } from '@coa/stdlib/string';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ClassListingLabel } from '../../../components/atoms/ClassListingLabel';
import { classCopyColor } from '../../../components/molecules/ClassCard';
import {
  WorkshopApplyCta,
  WorkshopFinishApplicationCta,
  WorkshopJoinClassCta,
} from '../../../components/WorkshopCta';
import { WorkshopOccurrenceExerciseCta } from '../../../components/WorkshopExerciseCta';
import { classMarketplaceAnalytics } from '../../../lib/analytics/clients';
import { useThemedBreakpoint } from '../../../lib/chakra-ui';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  createGetWorkshopPlaceholderData,
  getWorkshopHasEnded,
  useGetWorkshopOccurrenceSiblingsQuery,
  useGetWorkshopQuery,
} from '../../../resources/workshops';
import {
  getIsEnrolledInWorkshopDropIn,
  getWorkshopOccurrenceHasEnded,
  getWorkshopOccurrenceHasStarted,
} from '../../../resources/workshops/utils';
import { boxShadowStyles } from '../../../themes/coaTheme';
import { useHideNavMobile } from '../../shared/layout/components/GlobalLayoutProvider';
import { WorkshopsBreadcrumb } from '../WorkshopsBreadcrumb';
import { DropInSiblingsPicker } from './components/DropInSiblingsPicker';
import {
  WorkshopDetailsLayout,
  WorkshopDetailsMainContent,
  WorkshopDetailsMobileStickyFooter,
  WorkshopDetailsScrollableContent,
} from './components/WorkshopDetailsLayout';
import { WorkshopDetailsHeroImage } from './components/WorkshopImageRibbon';
import { WorkshopOccurrenceDetailsContentProps } from './types';
import { WorkshopIconHighlightsSection } from './WorkshopDetailsContent';

const WorkshopOccurrenceDetailsCta = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps & ButtonProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { data: workshop } = getWorkshopQuery;

  const { isLoading } = getWorkshopQuery;
  if (isLoading) return null;

  const {
    meta: { enrollmentStatus, enrollmentDropInIds },
    workshopOccurrences,
  } = workshop;
  const dropInOcc = workshopOccurrences.find(({ id }) => id === dropInWorkshopOccurrenceId);

  const classHasEnded = getWorkshopOccurrenceHasEnded(dropInOcc);
  const classHasStarted = getWorkshopOccurrenceHasStarted(dropInOcc);

  const propsWithDefaults = {
    ...rest,
    workshopId,
    dropInWorkshopOccurrenceId,
    size: 'lg',
  };

  if (enrollmentStatus === 'application_incomplete') {
    return (
      <Skeleton isLoaded={!isLoading}>
        <WorkshopFinishApplicationCta {...propsWithDefaults} width="100%" />
      </Skeleton>
    );
  }

  if (
    enrollmentStatus === 'awaiting_info' ||
    enrollmentStatus === 'pending' ||
    enrollmentStatus === 'rejected' ||
    enrollmentStatus === 'removed'
  ) {
    return null;
  }

  if (
    enrollmentStatus === 'approved' &&
    (_.has(enrollmentDropInIds, dropInWorkshopOccurrenceId) || _.isNull(enrollmentDropInIds)) &&
    !classHasEnded
  ) {
    return <WorkshopJoinClassCta {...propsWithDefaults} width="100%" />;
  }

  if (!enrollmentStatus || (enrollmentStatus === 'approved' && !classHasStarted)) {
    return <WorkshopApplyCta {...propsWithDefaults} width="100%" />;
  }

  return null;
};

const WorkshopOccurrenceDetailsCtaSection = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps & BoxProps) => {
  const workshopPlaceholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: workshopId },
    { placeholderData: workshopPlaceholderData }
  );
  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences, meta: workshopMeta } = workshop;
  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;

  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;

  const dropInWorkshopOccurrence =
    dropInWorkshopOccurrenceId && !getWorkshopQuery.isLoading
      ? workshopOccurrences.find(({ id: _id }) => _id === dropInWorkshopOccurrenceId)
      : null;

  const classHasEnded = isLoading ? false : getWorkshopOccurrenceHasEnded(dropInWorkshopOccurrence);

  if (workshop.status === 'archived') return <Text>This class has been canceled.</Text>;

  return (
    <Flex flexDir={{ base: 'column', md: 'column', lg: 'row' }} {...rest}>
      <WorkshopOccurrenceDetailsCta
        workshopId={workshopId}
        dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
        colorScheme="evergreen"
        {...boxShadowStyles}
      />
      {/* This is a bit of a pain, but different class states/enrollment statuses can mean different buttons will render.
      We add this small space for scenarios when a user's enrollment is 'approved' and the class has not yet ended */}
      {enrollmentStatus === 'approved' && !classHasEnded && dropInWorkshopOccurrence?.exerciseId ? (
        <Spacer mt={{ base: 4, md: 4, lg: 0 }} ml={{ base: 0, md: 0, lg: 4 }} />
      ) : null}
      {dropInWorkshopOccurrence?.exerciseId &&
      enrollmentStatus === 'approved' &&
      (_.has(enrollmentDropInIds, dropInWorkshopOccurrenceId) || _.isNull(enrollmentDropInIds)) ? (
        <>
          <Skeleton isLoaded={!isLoading} w="100%">
            <WorkshopOccurrenceExerciseCta
              size="lg"
              workshopId={workshopId}
              exerciseId={dropInWorkshopOccurrence.exerciseId}
              occurrenceStartsAt={dropInWorkshopOccurrence.startsAt}
              isOnlyOccurrence
              width="100%"
              {...boxShadowStyles}
              data-cy="workshop-exercise-cta"
            />
          </Skeleton>
        </>
      ) : null}
    </Flex>
  );
};

const WorkshopOccurrenceDetailsEnrollmentStatus = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps & BoxProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });

  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;

  const { workshopOccurrences, meta: workshopMeta } = workshop;
  const dropInWorkshopOccurrence =
    dropInWorkshopOccurrenceId && !getWorkshopQuery.isLoading
      ? workshopOccurrences.find(({ id: _id }) => _id === dropInWorkshopOccurrenceId)
      : null;

  const isEnrolledInWorkshopDropIn = getIsEnrolledInWorkshopDropIn(
    workshopMeta,
    dropInWorkshopOccurrenceId
  );

  const isEnrolledInFullSeries = !workshopMeta.enrollmentDropInIds;

  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;

  const enrolledDropInsLength = _.size(enrollmentDropInIds);
  const maybePluralClassNoun = enrolledDropInsLength > 1 ? 'classes' : 'class';
  const hasPartialEnrollment = Boolean(enrollmentDropInIds);
  const proseEnrolledClassSubject =
    enrolledDropInsLength === 1 && enrollmentDropInIds[dropInWorkshopOccurrenceId]
      ? 'this class'
      : hasPartialEnrollment
      ? `${formatProseInt(enrolledDropInsLength)} other ${maybePluralClassNoun} in this series`
      : 'this series';
  const seriesHasEnded = getWorkshopHasEnded(workshop);

  return (
    <VStack
      spacing={4}
      {...rest}
      data-cy={`workshop-details-enrollment-info-${isLoading ? 'loading' : 'loaded'}`}
    >
      <Skeleton isLoaded={!isLoading} w="100%" h="100%">
        {(() => {
          if (workshop.status === 'archived') {
            return null;
          }
          if (!enrollmentStatus) {
            return <Text>Save your spot in this class today.</Text>;
          }

          if (enrollmentStatus === 'application_incomplete' && !seriesHasEnded) {
            return (
              <Text>
                You've started an application to {proseEnrolledClassSubject}. Please finish your
                application application to continue.
              </Text>
            );
          }

          if (enrollmentStatus === 'awaiting_info') {
            return (
              <Text>
                We are reviewing your application to {proseEnrolledClassSubject}. We have sent you
                an email requesting more information. Please check your inbox!
              </Text>
            );
          }
          if (enrollmentStatus === 'pending') {
            return (
              <>
                <Text>
                  We are reviewing your application to {proseEnrolledClassSubject}. You will receive
                  an email within 24 hours with an update on its status.
                </Text>
              </>
            );
          }
          if (enrollmentStatus === 'rejected') {
            return (
              <>
                <Text>
                  We have reviewed your application to {proseEnrolledClassSubject} and are not able
                  to offer you a spot at this time.
                </Text>
                <Text>Please reach out to our support team for further assistance.</Text>
              </>
            );
          }
          if (enrollmentStatus === 'approved') {
            if (getWorkshopOccurrenceHasEnded(dropInWorkshopOccurrence)) {
              if (isEnrolledInWorkshopDropIn || isEnrolledInFullSeries) {
                return (
                  <>
                    <Text>
                      You attended this class - well done!
                      {dropInWorkshopOccurrence.exerciseId ? (
                        <Text>Review your Class Exercises below.</Text>
                      ) : null}
                    </Text>
                  </>
                );
              }
              // Should be a no-op - user shouldn't be able to get to the page.
              return <Text>This class has ended.</Text>;
            }
            if (isEnrolledInWorkshopDropIn || isEnrolledInFullSeries) {
              return <Text>You are booked for this class.</Text>;
            }
            return (
              <Text>
                You are booked for {proseEnrolledClassSubject}. You may save your spot in this class
                as well.
              </Text>
            );
          }
          /*
           * NOTE: The default state here is the "Base" case where a user is not enrolled in the Drop-In
           * In this case we do not display any enrollment details
           */
        })()}
      </Skeleton>
    </VStack>
  );
};

const WorkshopOccurrenceDetailsOtherTimes = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps) => {
  const getWorkshopOccurrenceSiblingsQuery = useGetWorkshopOccurrenceSiblingsQuery({
    id: dropInWorkshopOccurrenceId,
  });
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { isLoading, data: workshop } = getWorkshopQuery;

  const isEnrolledInWorkshopDropIn = getIsEnrolledInWorkshopDropIn(
    workshop.meta,
    dropInWorkshopOccurrenceId
  );

  const headingText =
    !isLoading && (isEnrolledInWorkshopDropIn || !getWorkshopOccurrenceSiblingsQuery.data?.length)
      ? 'Your Date & Instructor'
      : 'Choose Your Date & Instructor';

  return (
    <VStack spacing={4} {...rest}>
      <Skeleton isLoaded={!isLoading} w="100%">
        <Heading size="md">{headingText}</Heading>
      </Skeleton>
      <DropInSiblingsPicker
        my={4}
        width="100%"
        workshopId={workshopId}
        dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
        getLinkRoute={({ id, dropInWorkshopOccurrenceId: occId }) =>
          `/classes/${id}/drop-ins/${occId}`
        }
        {...boxShadowStyles}
      />
    </VStack>
  );
};

const WorkshopOccurrenceDetailsHeroSection = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps & StackProps) => {
  const dropInPlaceholderData = createGetDropInWorkshopOccurrencesPlaceholderData({ length: 1 });
  const workshopPlaceholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: workshopId },
    { placeholderData: workshopPlaceholderData }
  );
  const { data: workshop } = getWorkshopQuery;
  const {
    id: placeholderId,
    title: placeholderTitle,
    workshop: placeholderWorkshop,
  } = dropInPlaceholderData[0];
  const { workshopOccurrences, access } = workshop;
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isLoading =
    getWorkshopQuery.isLoading ||
    getWorkshopQuery.isPlaceholderData ||
    getSubscriptionsQuery.isLoading;

  const dropInWorkshopOccurrence =
    dropInWorkshopOccurrenceId && !getWorkshopQuery.isLoading
      ? workshopOccurrences.find(({ id: _id }) => _id === dropInWorkshopOccurrenceId)
      : null;

  const { id, seriesTitle, title } = isLoading
    ? {
        id: placeholderId,
        seriesTitle: placeholderWorkshop.title,
        title: placeholderTitle,
      }
    : {
        id: dropInWorkshopOccurrence.id,
        seriesTitle: workshop.title,
        title: dropInWorkshopOccurrence.title,
      };

  return (
    <Container
      maxW="100%"
      backgroundColor={isLoading ? '' : 'green.200'}
      color={classCopyColor({ type: 'workshop_occurrence', kind: workshop.kind })}
      position="relative"
      display="flex"
      flexDir={{ base: 'column-reverse', md: 'row' }}
      alignItems="stretch"
      px={0}
      {...rest}
    >
      <Container maxW="container.lg" display="flex">
        <VStack
          position="relative"
          py={{ base: 8, md: 12 }}
          pr={{ base: 0, md: 8 }}
          alignItems="stretch"
          w={{ base: '100%', md: '60%' }}
          spacing={10}
        >
          <WorkshopsBreadcrumb dropInWorkshopOccurrenceId={id} />
          <Box>
            <Skeleton
              isLoaded={!isLoading}
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              w="100%"
              noOfLines={2}
            >
              <Heading size="2xl" lineHeight={1.1} noOfLines={4} maxW="570px" mb={4}>
                {title}
              </Heading>
            </Skeleton>
            <VStack spacing={4}>
              <Skeleton isLoaded={!isLoading} w="100%">
                <Text fontSize="lg">
                  This drop-in class is part of the{' '}
                  <Link
                    as={RouterLink}
                    to={`/classes/${workshopId}`}
                    cursor="pointer"
                    fontWeight="semibold"
                    _hover={{ textDecor: 'underline' }}
                  >
                    {seriesTitle}
                  </Link>
                </Text>
              </Skeleton>
              <WorkshopOccurrenceDetailsEnrollmentStatus
                workshopId={workshopId}
                dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
                w="100%"
                fontSize="lg"
              />
            </VStack>
          </Box>
          <Box>
            <WorkshopOccurrenceDetailsOtherTimes
              workshopId={workshopId}
              dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
            />
          </Box>
          <WorkshopOccurrenceDetailsCtaSection
            workshopId={workshopId}
            dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
            display={{ base: 'none', md: 'flex' }}
          />
          {!hasActiveSubscription && !isLoading ? (
            <ClassListingLabel access={access} position="absolute" />
          ) : null}
        </VStack>
      </Container>
      <Skeleton isLoaded={!isLoading}>
        <WorkshopDetailsHeroImage
          workshopId={workshopId}
          w={{ base: '100%', md: '40%' }}
          position={{ base: 'relative', md: 'absolute' }}
          minHeight={{ base: '40vh', md: 'none' }}
          top={0}
          bottom={0}
          right={0}
        />
      </Skeleton>
    </Container>
  );
};

const WorkshopOccurrenceDetailsAboutSection = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopOccurrenceDetailsContentProps & StackProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;
  const { workshopOccurrences } = workshop;

  const dropInWorkshopOccurrence =
    dropInWorkshopOccurrenceId && !getWorkshopQuery.isLoading
      ? workshopOccurrences.find(({ id: _id }) => _id === dropInWorkshopOccurrenceId)
      : null;

  return (
    <VStack alignItems="start" spacing={4} {...rest} mb={{ base: 4, md: 0 }}>
      <Skeleton isLoaded={!isLoading} w="100%">
        <Heading size="xl">About this Class</Heading>
      </Skeleton>
      <SkeletonText isLoaded={!isLoading} w="100%">
        <Text fontSize="lg" style={{ whiteSpace: 'pre-line' }}>
          {dropInWorkshopOccurrence?.description}
        </Text>
      </SkeletonText>
    </VStack>
  );
};

export const WorkshopOccurrenceDetailsContent = ({
  workshopId,
  dropInWorkshopOccurrenceId,
}: WorkshopOccurrenceDetailsContentProps) => {
  useHideNavMobile();
  const [isBelowMdBreakpoint] = useThemedBreakpoint({ below: 'md' });

  useEffect(() => {
    classMarketplaceAnalytics.track('Viewed Drop-In Details', {
      classId: workshopId,
      dropInId: dropInWorkshopOccurrenceId,
    });
    /*
     * We trigger tracking on dropInWorkshopOccurrenceId change (rather than a simple
     * onMount event) in case the user navigates from one Workshop Occurrence
     * directly to another and the parent component does not unmount.
     */
  }, [dropInWorkshopOccurrenceId]);

  return (
    <WorkshopDetailsLayout>
      <WorkshopDetailsScrollableContent>
        <WorkshopDetailsMainContent>
          <WorkshopOccurrenceDetailsHeroSection
            workshopId={workshopId}
            dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
          />
          <Container maxW="container.lg">
            <VStack spacing={12}>
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: 'column', md: 'row' }}
                mb={{ base: 4, md: 0 }}
              >
                <WorkshopOccurrenceDetailsAboutSection
                  workshopId={workshopId}
                  dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
                  w={{ base: '100%', md: '60%' }}
                />
                <WorkshopIconHighlightsSection
                  workshopId={workshopId}
                  dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
                  w={{ base: '35%', lg: '30%' }}
                  px={{ base: 0, lg: 6 }}
                />
              </Box>
            </VStack>
          </Container>
        </WorkshopDetailsMainContent>
      </WorkshopDetailsScrollableContent>
      {
        // A simple CSS hide here does not suffice as it will still appear in the DOM
        // and be queryable by Cypress.
        isBelowMdBreakpoint ? (
          <WorkshopDetailsMobileStickyFooter>
            <WorkshopOccurrenceDetailsCtaSection
              workshopId={workshopId}
              dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
              p={4}
            />
          </WorkshopDetailsMobileStickyFooter>
        ) : null
      }
    </WorkshopDetailsLayout>
  );
};
