import React from 'react';
import {
  Redirect,
  RedirectProps,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router';
import { useSession } from '../../resources/sessions';

function ConditionalRoute({
  render,
  component,
  condition,
  redirectTo,
  children,
  ...rest
}: RouteProps & { condition: boolean } & { redirectTo: RedirectProps['to'] }) {
  const renderRouteContents = (props: RouteComponentProps) => {
    if (component) return React.createElement(component, props);
    if (render) return render(props);
    if (children) return children;
    throw Error(
      'Cannot render route. Please specify either "compnonent", "render", or "children".'
    );
  };
  return (
    <Route
      {...rest}
      render={(props) => (condition ? renderRouteContents(props) : <Redirect to={redirectTo} />)}
    />
  );
}

/*
 * Here we provide a rough heuristic for whether or not a pathname
 * that a user is attempting to access indicates that they have an
 * existing account.
 *
 * For example, users who are going to `/my-coa` are likely only
 * doing so because they have an account and we sent them an email.
 *
 * This heuristic is far from bulletproof but will do for now.
 */
const getDestinationPathnameIndicatesExistingAccount = (pathname: string): boolean =>
  !(
    (pathname.match(/classes/) &&
      // Make an execption here because exercises are shown under classes.
      !pathname.match(/exercises/)) ||
    pathname.match(/on-demand/)
  );

export function AuthenticatedRoute(props: RouteProps) {
  const { loggedIn } = useSession();
  const location = useLocation();
  const { pathname } = location;
  const redirectToBase = getDestinationPathnameIndicatesExistingAccount(pathname)
    ? '/log-in'
    : '/join';
  return (
    <ConditionalRoute
      {...props}
      condition={loggedIn}
      redirectTo={`${redirectToBase}?redirect_to=${pathname}`}
    />
  );
}

export function AdminRoute(props: RouteProps) {
  const { role } = useSession();
  return <ConditionalRoute {...props} condition={role === 'admin'} redirectTo="/my-coa" />;
}

export function TeamsAuthenticatedRoute(props: RouteProps) {
  const { loggedIn } = useSession();
  const location = useLocation();
  return (
    <ConditionalRoute
      {...props}
      condition={loggedIn}
      redirectTo={`/teams/claim-account/:token?redirect_to=${location.pathname}`}
    />
  );
}
