import { MenuButtonProps } from '@chakra-ui/react';
import React from 'react';
import { EventListingMenu } from '../../../../components/compositions/EventListingMenu';
import {
  formatWorkshopTimeLabel,
  GetWorkshop,
  useGetWorkshopQuery,
  useGetWorkshopSiblingsQuery,
  Workshop,
} from '../../../../resources/workshops';
import { usePickAndNavigateToAlternateWorkshopRouterPath } from '../hooks/usePickAndNavigateToAlternateWorkshopRouterPath';

type WorkshopSiblingsPickerProps = {
  workshopId: Workshop['id'];
  getLinkRoute: ({ id }: { id: string }) => string;
} & MenuButtonProps;

export const WorkshopSiblingsPicker = ({
  workshopId: id,
  getLinkRoute,
  ...rest
}: WorkshopSiblingsPickerProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id });
  const getWorkshopSiblingsQuery = useGetWorkshopSiblingsQuery({ id });

  const {
    pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath,
    getPickedAlternateWorkshopQuery,
    getPickedAlternateSiblingsQuery,
  } = usePickAndNavigateToAlternateWorkshopRouterPath();

  if (getWorkshopQuery.isLoading || getWorkshopSiblingsQuery.isLoading) {
    return <EventListingMenu isLoading {...rest} />;
  }

  const { data: workshop } = getWorkshopQuery;
  const { data: workshopSiblings } = getWorkshopSiblingsQuery;

  const active = {
    label: formatWorkshopTimeLabel(workshop, workshop.workshopOccurrences),
    instructorName: String(workshop.therapist.name),
    instructorImage: String(workshop.therapist.imageUrl),
  };

  const options = workshopSiblings
    ? workshopSiblings.map((siblingWorkshop: GetWorkshop.Response) => {
        const { id: workshopSiblingId } = siblingWorkshop;
        return {
          label: formatWorkshopTimeLabel(siblingWorkshop, siblingWorkshop.workshopOccurrences),
          instructorName: String(siblingWorkshop.therapist.name),
          instructorImage: String(siblingWorkshop.therapist.imageUrl),
          ...(siblingWorkshop.soldOut
            ? {
                disabled: true,
                badgeProps: {
                  colorScheme: 'red',
                  variant: 'solid',
                  children: 'FULL',
                },
              }
            : {
                onClick: () => {
                  pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath({
                    workshopId: workshopSiblingId,
                    linkRoute: getLinkRoute({
                      id: workshopSiblingId,
                    }),
                  });
                },
              }),
        };
      })
    : [];

  return (
    <EventListingMenu
      active={active}
      options={options}
      isPendingNavigation={
        getPickedAlternateWorkshopQuery.isLoading || getPickedAlternateSiblingsQuery.isLoading
      }
      {...rest}
    />
  );
};
