import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSession } from '../resources/sessions';
import { useGetWorkshopQuery } from '../resources/workshops';
import { RouterPathParams } from '../routerPaths';
import { MailIcon } from './Icons';

const JoinWorkshopSuccess = () => {
  const { name } = useSession();

  const {
    id: workshopId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropInWorkshopOccurrenceId,
  } = useRouteParams<RouterPathParams.Classes['DropInJoinSuccess']>();
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const isAutoApprove = getWorkshopQuery.data?.autoApprove;
  // get enrollment to check status of enrollment and change isEnrollmentApprovedHeuristic to use enrollment.status

  /*
   * Per COA-232, there are scenarios in which `name` is not present.
   * Best guess is that this is the result of a race condition on
   * calls to /api/sessions for a user returning to the site on this
   * page.
   *
   * Here we add some defense to ensure that the page can render if
   * name is absent.
   */
  const headingText = name ? `Thank you, ${name.split(' ')[0]}.` : 'Thank you.';
  /*
   * TODO: Replace this heuristic for "is the enrollment approved?"
   * with an explicit check on the status of the enrollment itself.
   * THIS IS THE TODO WE MIGHT NEED TO DO
   */
  const isEnrollmentApprovedHeuristic = isAutoApprove; // if the workshop is set to auto approved
  // check enrollment.status
  return (
    <Center>
      <VStack width="100%" px={4} py={24} maxW="365px" spacing={8}>
        <MailIcon color="orange" boxSize={24} />
        <Box>
          <Heading align="center">{headingText}</Heading>
          {isEnrollmentApprovedHeuristic ? (
            <Text>You're all set - we'll see you in class! 💫</Text>
          ) : (
            <Text align="center">
              Your application is being reviewed by our team! You will receive an email within 24
              hours with an update on the status of your application. (Hint: Please make sure to
              check spam, socials, and promotions folders!) 💫
            </Text>
          )}
        </Box>
        <Button colorScheme="evergreen" as={RouterLink} to="/my-coa">
          Prep for Class
        </Button>
      </VStack>
    </Center>
  );
};

export default JoinWorkshopSuccess;
