export const SESSIONS_REQUEST = 'SESSIONS_REQUEST';
export const SESSIONS_SUCCESS = 'SESSIONS_SUCCESS';
export const SESSIONS_FAILURE = 'SESSIONS_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export function create(data) {
  return {
    type: SESSIONS_REQUEST,
    api: {
      data,
      endpoint: 'sessions',
      authenticated: false,
      types: [SESSIONS_REQUEST, SESSIONS_SUCCESS, SESSIONS_FAILURE],
      method: 'POST',
    },
  };
}
