import { Td } from '@chakra-ui/react';
import {
  useAdminCreateWorkoutScheduleEventMutation,
  useAdminIndexWorkoutScheduleEventsQuery,
  useAdminShowWorkoutScheduleEventQuery,
  useAdminUpdateWorkoutScheduleEventMutation,
} from '@coa/api/controllers/admin/workoutScheduleEvents';
import {
  WorkoutScheduleEvent,
  WorkoutScheduleEventRecord,
} from '@coa/api/models/workoutScheduleEvent';
import { AdminSerializedWorkoutScheduleEvent } from '@coa/api/serializers/admin/AdminSerializedWorkoutScheduleEvent';
import { formatHtmlDateInputValue, formatProseDateLong } from '@coa/stdlib/string';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { formSchema, formUiSchema } from './formSchema';
import { AdminWorkoutScheduleEventFormData } from './types';
import { workoutScheduleEventCmsHelperText } from './workoutScheduleEventCmsContent';

const formatSerializedWorkoutScheduleEventToFormData = (
  workoutScheduleEvent: AdminSerializedWorkoutScheduleEvent
) => {
  // format ISO string to 'YYYY-MM-DD' for date input
  const dateInputValue = formatHtmlDateInputValue(workoutScheduleEvent.scheduledDate);

  return {
    ...workoutScheduleEvent,
    scheduledDate: dateInputValue,
  };
};

const parseFormDataToSerializedWorkoutScheduleEvent = (
  formData: AdminWorkoutScheduleEventFormData
) => ({
  ...formData,
  scheduledDate: formData.scheduledDate,
});

export const AdminWorkoutScheduleEventsCmsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <CmsContextProvider<
    WorkoutScheduleEvent,
    AdminSerializedWorkoutScheduleEvent,
    AdminWorkoutScheduleEventFormData
  >
    value={{
      baseRouterPath: '/admin-dash/cms/workout-schedule-events',
      contentTypeTitle: 'Workout Schedule Event',
      contentHelperText: workoutScheduleEventCmsHelperText,
      /* The search bar for Workout Schedule Events doesn't currently work
       * because the 'label' we're using is formatted from the scheduledAt ISO date string
       * so if we search for 'Mon July 28', that value doesn't exist in the db and will error
       * To fix the error, we altered the AdminWorkoutScheduledEventFilter to alyways return all WorkoutScheduleEvents
       * TODO: we will be refactoring the index to view a Calendar-based view to create a date-centric UI
       */
      searchPlaceholder: "THIS DOESN'T DO ANYTHING",

      formSchema,
      formUiSchema,

      useIndexQuery: useAdminIndexWorkoutScheduleEventsQuery,
      useShowQuery: useAdminShowWorkoutScheduleEventQuery,
      useUpdateMutation: useAdminUpdateWorkoutScheduleEventMutation,
      useCreateMutation: useAdminCreateWorkoutScheduleEventMutation,

      getEntryTitle: ({ scheduledDate }) => formatProseDateLong(scheduledDate),
      indexThEntries: [{ name: 'scheduledDate', label: 'Scheduled Date' }],

      formatSerializedResourceToCmsFormData: formatSerializedWorkoutScheduleEventToFormData,
      parseCmsFormDataToSerializedResource: parseFormDataToSerializedWorkoutScheduleEvent,

      renderIndexEntryTdContents: function WorkoutScheduleEventIndexEntryTd({
        scheduledDate,
      }: AdminSerializedWorkoutScheduleEvent) {
        return (
          <Td>
            <strong>{formatProseDateLong(scheduledDate)}</strong>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminCmsWorkoutScheduleEventsView = () => (
  <AdminWorkoutScheduleEventsCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/workout-schedule-events/new">
        <AdminCmsCreateView<
          WorkoutScheduleEventRecord,
          AdminSerializedWorkoutScheduleEvent,
          AdminWorkoutScheduleEventFormData
        > />
      </Route>
      <Route path="/admin-dash/cms/workout-schedule-events/:id">
        <AdminCmsShowView<
          WorkoutScheduleEventRecord,
          AdminSerializedWorkoutScheduleEvent,
          AdminWorkoutScheduleEventFormData
        > />
      </Route>
      <Route path="/admin-dash/cms/workout-schedule-events">
        <AdminCmsIndexView<
          WorkoutScheduleEventRecord,
          AdminSerializedWorkoutScheduleEvent,
          AdminWorkoutScheduleEventFormData
        > />
      </Route>
    </Switch>
  </AdminWorkoutScheduleEventsCmsContextProvider>
);
