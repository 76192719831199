export const CLIENT_PAYMENT_METHODS_DESTROY_REQUEST = 'CLIENT_PAYMENT_METHODS_DESTROY_REQUEST';
export const CLIENT_PAYMENT_METHODS_DESTROY_SUCCESS = 'CLIENT_PAYMENT_METHODS_DESTROY_SUCCESS';
export const CLIENT_PAYMENT_METHODS_DESTROY_FAILURE = 'CLIENT_PAYMENT_METHODS_DESTROY_FAILURE';

export function destroy(clientId, paymentMethodId) {
  return {
    type: CLIENT_PAYMENT_METHODS_DESTROY_REQUEST,
    api: {
      endpoint: `clients/${clientId}/payment_methods/${paymentMethodId}`,
      authenticated: true,
      types: [
        CLIENT_PAYMENT_METHODS_DESTROY_REQUEST,
        CLIENT_PAYMENT_METHODS_DESTROY_SUCCESS,
        CLIENT_PAYMENT_METHODS_DESTROY_FAILURE,
      ],
      method: 'DELETE',
    },
  };
}

export const CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST =
  'CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST';
export const CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_SUCCESS =
  'CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_SUCCESS';
export const CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_FAILURE =
  'CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_FAILURE';

export function setAsDefault(clientId, paymentMethodId) {
  return {
    type: CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST,
    api: {
      endpoint: `clients/${clientId}/payment_methods/${paymentMethodId}/set_as_default`,
      authenticated: true,
      types: [
        CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST,
        CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_SUCCESS,
        CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_FAILURE,
      ],
      method: 'POST',
    },
  };
}
