import { createAxiosClient, createJsonApiAxiosClient } from '@coa/api/lib/axios';
import {
  QueryFunctionContext,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { createDummyQueryClient, defaultReactQueryArgs } from '../../../lib/react-query';
import { appendQueryParamsWithCaseTransform } from '../../../lib/url';
import { getAdminMembersApiUrl } from './apiPaths';
import {
  AdminCreateMember,
  AdminGetMember,
  AdminGetMembersSearch,
  PostAccountResendVerifyEmail,
  PostB2BAccountResendVerifyEmail,
} from './types';

// Acount Resend Verify Email for the currently logged in user
const generatePostAccountResendVerifyEmail = () => {
  const client = createAxiosClient({ auth: true });
  const path = '/v1/account/resend_verify_email';

  const fn = async () => {
    const { data } = await client.post<PostAccountResendVerifyEmail.Response>(path);
    return data;
  };
  return { path, fn };
};

export const usePostAccountResendVerifyEmailMutation = (
  options: UseMutationOptions<PostAccountResendVerifyEmail.Response> = {}
) => {
  const { path: queryKey, fn } = generatePostAccountResendVerifyEmail();
  return useMutation<PostAccountResendVerifyEmail.Response>(queryKey, fn, options);
};

const generatePostB2BAccountResendVerifyEmail = () => {
  const client = createAxiosClient({ auth: true });
  const path = '/v1/account/b2b_resend_verify_email';

  const fn = async (body: PostB2BAccountResendVerifyEmail.Request['body']) => {
    const { data } = await client.post<PostB2BAccountResendVerifyEmail.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePostB2BAccountResendVerifyEmailMutation = () => {
  const { path: postQueryKey, fn } = generatePostB2BAccountResendVerifyEmail();
  return useMutation<
    PostB2BAccountResendVerifyEmail.Response,
    PostB2BAccountResendVerifyEmail.Error,
    PostB2BAccountResendVerifyEmail.Request['body']
  >(postQueryKey, {
    mutationFn: (body: PostB2BAccountResendVerifyEmail.Request['body']) => fn(body),
    onError: (err) => {
      const { email, base } = err.response.data.errors;
      throw new Error(email || base);
    },
  });
};

/*
 * =============================================
 * Admin
 * =============================================
 */
const generateAdminGetMember = ({ id }: AdminGetMember.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAdminMembersApiUrl.show(id);

  const fn = async () => {
    const { data } = await client.get<AdminGetMember.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useAdminGetMemberQuery = (
  { id }: AdminGetMember.Request['pathParams'],
  ops: UseQueryOptions<AdminGetMember.Response> = defaultReactQueryArgs
) => {
  const { path: queryKey, fn } = ops.enabled
    ? generateAdminGetMember({ id })
    : createDummyQueryClient<never, AdminGetMember.Response>();

  return useQuery<AdminGetMember.Response>(queryKey, fn, ops);
};

const generateAdminCreateMember = () => {
  const client = createAxiosClient({ auth: true });
  const path = getAdminMembersApiUrl.create();

  const fn = async (body: AdminCreateMember.Request['body']) => {
    const { data } = await client.post<AdminCreateMember.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useAdminCreateMemberMutation = () => {
  const { path: postQueryKey, fn } = generateAdminCreateMember();
  const queryClient = useQueryClient();
  return useMutation(postQueryKey, {
    mutationFn: (body: AdminCreateMember.Request['body']) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries(postQueryKey);
    },
    onSettled: () => {
      queryClient.invalidateQueries(postQueryKey);
    },
  });
};

export const generateAdminGetMembersSearch = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = getAdminMembersApiUrl.search();

  const fn = async ({
    queryKey: [, queryParams],
  }: QueryFunctionContext<[string, AdminGetMembersSearch.Request['queryParams']]>) => {
    const { data } = await client.get<AdminGetMembersSearch.Response>(
      appendQueryParamsWithCaseTransform(basePath, queryParams)
    );
    return data;
  };
  return { fn, basePath };
};

export const useAdminGetMembersSearch = (
  queryParams: AdminGetMembersSearch.Request['queryParams'],
  options: UseQueryOptions<AdminGetMembersSearch.Response>
) => {
  const { fn, basePath } = generateAdminGetMembersSearch();
  return useQuery<AdminGetMembersSearch.Response>([basePath, queryParams], fn, options);
};
