import {
  createAxiosClient,
  createAxiosClientForMutation,
  createJsonApiAxiosClient,
  createLegacyAuthAxiosClientWithCaseTransform,
} from '@coa/api/lib/axios';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { appendQueryParams, appendQueryParamsWithCaseTransform } from '../../../lib/url';
import {
  AdminGetWorkshopsSearch,
  GetDropInWorkshopOccurrences,
  GetFeaturedWorkshops,
  GetIntroWorkshops,
  GetLeadershipIntroWorkshop,
  GetMyPastWorkshops,
  GetMyPastWorkshopsSummary,
  GetMyWorkshops,
  GetPastWorkshopOccurrences,
  GetPushUpWorkshops,
  GetQAndAWorkshops,
  GetSeriesWorkshops,
  GetStandardIntroWorkshop,
  GetUpcomingEnrolledWorkshopOccurrence,
  GetUpcomingTeamWorkshopOccurrence,
  GetUpcomingWorkshopOccurrence,
  GetUpcomingWorkshopOccurrences,
  GetWorkshop,
  GetWorkshopEnrollment,
  GetWorkshopOccurrenceSiblings,
  GetWorkshops,
  GetWorkshopSiblings,
  PostWorkshopEnrollment,
  UpdateWorkshopEnrollment,
} from './types';

/*
 * =============================================
 * Single Workshop Operations
 * =============================================
 */

/*
 * GET /v1/workshops/:id
 */

const generateGetWorkshop = ({ id }: GetWorkshop.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshops/${id}`;
  const fn = async () => {
    const { data } = await client.get<GetWorkshop.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetWorkshopQuery = (
  { id }: GetWorkshop.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshop.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshop({ id });
  return useQuery<GetWorkshop.Response>(queryKey, fn, ops);
};

/**
 * GET /v1/workshops/:id/no_auth
 * TODO: not ideal, should combine w/ generateGetWorkshop
 */
const generateGetWorkshopNoAuth = ({ id }: GetWorkshop.Request['pathParams']) => {
  const client = createJsonApiAxiosClient();
  const path = `/v1/workshops/${id}/no_auth`;
  const fn = async () => {
    const { data } = await client.get<GetWorkshop.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetWorkshopNoAuthQuery = (
  { id }: GetWorkshop.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshop.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshopNoAuth({ id });
  return useQuery<GetWorkshop.Response>(queryKey, fn, ops);
};

/*
 * GET /v1/workshops/:id/siblings
 */

const generateGetWorkshopSiblings = ({ id }: GetWorkshopSiblings.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshops/${id}/siblings`;
  const fn = async () => {
    const { data } = await client.get<GetWorkshopSiblings.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetWorkshopSiblingsQuery = (
  { id }: GetWorkshopSiblings.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshopSiblings.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshopSiblings({ id });
  return useQuery<GetWorkshopSiblings.Response>(queryKey, fn, ops);
};

/*
 * GET /v1/workshops/:id/enrollment
 */

const generateGetWorkshopEnrollment = ({ id }: GetWorkshopEnrollment.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshops/${id}/enrollment`;
  const fn = async () => {
    const { data } = await client.get<GetWorkshopEnrollment.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetWorkshopEnrollmentQuery = (
  { id }: GetWorkshopEnrollment.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshopEnrollment.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshopEnrollment({ id });
  return useQuery<GetWorkshopEnrollment.Response>(queryKey, fn, ops);
};

/*
 * PUT /v1/workshops/:id/enrollment
 */

const generateUpdateWorkshopEnrollment = ({ id }: PostWorkshopEnrollment.Request['pathParams']) => {
  const client = createAxiosClientForMutation({ auth: true });
  const path = `/v1/workshops/${id}/enrollment`;
  const fn = async (params: UpdateWorkshopEnrollment.Request['body']) => {
    const { data } = await client.put<UpdateWorkshopEnrollment.Response>(path, params);
    return data;
  };
  return { path, fn };
};

export const useUpdateWorkshopEnrollmentMutation = (
  pathParams: UpdateWorkshopEnrollment.Request['pathParams']
) => {
  const { path: mutationKey, fn } = generateUpdateWorkshopEnrollment(pathParams);
  const queryClient = useQueryClient();
  const queryKey = `/v1/workshops/${pathParams.id}`;
  return useMutation(mutationKey, {
    mutationFn: (body: UpdateWorkshopEnrollment.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.invalidateQueries(queryKey);
    },
    /*
     * There is a known bug wherein, upon the successful completion of a workshop
     * application, a rendered WorkshopCta will not show an updated state without
     * a hard browser refresh. This should not be necessary, as after a successful
     * PUT to this resource, this mutation should resolve and invalidate the
     * queries as indicated below.
     *
     * This, however, is not happening. particularly because the onSuccess handler
     * below is not firing for reasons still unknown. According to various
     * troubleshooting sources, this is because the queryClient we've instantiated
     * at the top of the application is unstable and being replaced with every render
     * (It's not) or because the query-keys don't match (They do.)
     *
     * Please fix this bug.
     */
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

/*
 * POST /v1/workshops/:id/enrollment
 */

const generatePostWorkshopEnrollment = ({ id }: PostWorkshopEnrollment.Request['pathParams']) => {
  const client = createAxiosClientForMutation({ auth: true });
  const path = `/v1/workshops/${id}/enrollment`;
  const fn = async (params: PostWorkshopEnrollment.Request['body']) => {
    const { data } = await client.post<PostWorkshopEnrollment.Response>(path, params);
    return data;
  };
  return { path, fn };
};

export const usePostWorkshopEnrollmentMutation = (
  params: PostWorkshopEnrollment.Request['pathParams']
) => {
  const { path: postQueryKey, fn } = generatePostWorkshopEnrollment(params);
  return useMutation(postQueryKey, {
    mutationFn: (body: PostWorkshopEnrollment.Request['body']) => fn(body),
    onError: () => {
      // TODO
    },
    onSettled: () => {
      // TODO
    },
  });
};

/*
 * =============================================
 * List Workshops
 * =============================================
 */

/*
 * GET /workshops
 */

const generateGetWorkshops = (params: GetWorkshops.Request['queryParams'] = {}) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = `/v1/workshops`;
  const path = appendQueryParams(basePath, params);
  const fn = async () => {
    const { data } = await client.get<GetWorkshops.Response>(path);
    return data;
  };
  return {
    path,
    fn,
  };
};

export const useGetWorkshopsQuery = (
  params: GetSeriesWorkshops.Request['queryParams'] = {},
  ops: UseQueryOptions<GetWorkshops.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshops(params);
  return useQuery<GetWorkshops.Response>(queryKey, fn, ops);
};
/*
 * GET /workshops/mine
 */

const generateGetMyWorkshops = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshops/mine`;
  const fn = async () => {
    const { data } = await client.get<GetMyWorkshops.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useMyWorkshopsQuery = (ops: UseQueryOptions<GetMyWorkshops.Response> = {}) => {
  const { path: queryKey, fn } = generateGetMyWorkshops();
  return useQuery<GetMyWorkshops.Response>(queryKey, fn, ops);
};

/*
 * GET /workshops/series
 */
const generateGetSeriesWorkshops = (params: GetSeriesWorkshops.Request['queryParams'] = {}) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = `/v1/workshops/series`;
  const path = appendQueryParams(basePath, params);
  const fn = async () => {
    const { data } = await client.get<GetSeriesWorkshops.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useSeriesWorkshopsQuery = (
  params: GetSeriesWorkshops.Request['queryParams'] = {},
  ops: UseQueryOptions<GetSeriesWorkshops.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetSeriesWorkshops(params);
  return useQuery<GetSeriesWorkshops.Response>(queryKey, fn, ops);
};

/*
 * GET /workshops/intro
 */
const generateGetIntroWorkshops = (params: GetIntroWorkshops.Request['queryParams'] = {}) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = `/v1/workshops/intro`;
  const path = appendQueryParams(basePath, params);
  const fn = async () => {
    const { data } = await client.get<GetIntroWorkshops.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useIntroWorkshopQuery = (
  params: GetIntroWorkshops.Request['queryParams'] = {},
  options: UseQueryOptions<GetIntroWorkshops.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetIntroWorkshops(params);
  return useQuery<GetIntroWorkshops.Response>(queryKey, fn, options);
};

/*
 * GET /workshops/mine_past
 */

const generateGetMyPastWorkshops = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/workshops/mine_past`;
  const fn = async () => {
    const { data } = await client.get<GetMyPastWorkshops.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useMyPastWorkshopsQuery = () => {
  const { path: queryKey, fn } = generateGetMyPastWorkshops();
  return useQuery<GetMyPastWorkshops.Response>(queryKey, fn);
};

/*
 * GET /workshops/standard_intro
 */

const generateGetStandardIntroWorkshop = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/workshops/standard_intro`;
  const fn = async () => {
    const { data } = await client.get<GetStandardIntroWorkshop.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useStandardIntroWorkshopQuery = () => {
  const { path: queryKey, fn } = generateGetStandardIntroWorkshop();
  return useQuery<GetStandardIntroWorkshop.Response>(queryKey, fn);
};

/*
 * GET /workshops/leadership_intro
 */

const generateGetLeadershipIntroWorkshop = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/workshops/leadership_intro`;
  const fn = async () => {
    const { data } = await client.get<GetLeadershipIntroWorkshop.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useLeadershipIntroWorkshopQuery = () => {
  const { path: queryKey, fn } = generateGetLeadershipIntroWorkshop();
  return useQuery<GetLeadershipIntroWorkshop.Response>(queryKey, fn);
};

/*
 * GET /workshops/push_ups
 */

const generateGetPushUpWorkshops = () => {
  const client = createJsonApiAxiosClient({ auth: false });
  const path = '/v1/workshops/push_ups';
  const fn = async () => {
    const { data } = await client.get<GetPushUpWorkshops.Response>(path);
    return data;
  };
  return { path, fn };
};

export const usePushUpWorkshopsQuery = () => {
  const { path: queryKey, fn } = generateGetPushUpWorkshops();
  return useQuery<GetPushUpWorkshops.Response>(queryKey, fn);
};

/*
 * GET /workshops/q_and_a
 */

const generateGetQAndAWorkshops = () => {
  const client = createJsonApiAxiosClient({ auth: false });
  const path = '/v1/workshops/q_and_a';
  const fn = async () => {
    const { data } = await client.get<GetQAndAWorkshops.Response>(path);
    return data;
  };
  return { path, fn };
};

// use this for Q&A PAge
export const useQAndAWorkshopsQuery = (ops: UseQueryOptions<GetQAndAWorkshops.Response> = {}) => {
  const { path: queryKey, fn } = generateGetQAndAWorkshops();
  return useQuery<GetQAndAWorkshops.Response>(queryKey, fn, ops);
};

/*
 * GET /workshops/featured
 */

const generateGetFeaturedWorkshops = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = '/v1/workshops/featured';
  const fn = async () => {
    const { data } = await client.get<GetFeaturedWorkshops.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useFeaturedWorkshopsQuery = (
  ops: UseQueryOptions<GetFeaturedWorkshops.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetFeaturedWorkshops();
  return useQuery<GetFeaturedWorkshops.Response>(queryKey, fn, ops);
};

/*
 * =============================================
 * Single Workshop Occurrence Operations
 * =============================================
 *
 * TODO: Consider moving under a "workshopOccurrences" resource
 */

const generateGetWorkshopOccurrenceSiblings = ({
  id,
}: GetWorkshopOccurrenceSiblings.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshop_occurrences/${id}/siblings`;
  const fn = async () => {
    const { data } = await client.get<GetWorkshopOccurrenceSiblings.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetWorkshopOccurrenceSiblingsQuery = (
  { id }: GetWorkshopOccurrenceSiblings.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshopOccurrenceSiblings.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshopOccurrenceSiblings({ id });
  return useQuery<GetWorkshopOccurrenceSiblings.Response>(queryKey, fn, ops);
};

/*
 * =============================================
 * List Workshop Occurrences
 * =============================================
 *
 * TODO: Consider moving under a "workshopOccurrences" resource
 */

const generateGetDropInWorkshopOccurrences = (
  params: GetDropInWorkshopOccurrences.Request['queryParams'] = {}
) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = `/v1/workshop_occurrences/drop_ins`;
  const path = appendQueryParams(basePath, params);
  const fn = async () => {
    const { data } = await client.get<GetDropInWorkshopOccurrences.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useDropInWorkshopOccurrencesQuery = (
  params: GetDropInWorkshopOccurrences.Request['queryParams'] = {},
  options: UseQueryOptions<GetDropInWorkshopOccurrences.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetDropInWorkshopOccurrences(params);
  return useQuery<GetDropInWorkshopOccurrences.Response>(queryKey, fn, options);
};

const generateGetUpcomingEnrolledWorkshopOccurrence = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshop_occurrences/upcoming_enrolled`;
  const fn = async () => {
    const { data } = await client.get<GetUpcomingEnrolledWorkshopOccurrence.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useUpcomingEnrolledWorkshopOccurrenceQuery = (
  options: UseQueryOptions<GetUpcomingEnrolledWorkshopOccurrence.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetUpcomingEnrolledWorkshopOccurrence();
  return useQuery<GetUpcomingEnrolledWorkshopOccurrence.Response>(queryKey, fn, options);
};

/*
 * GET /workshop_occurrences/upcoming
 */

const generateGetUpcomingWorkshopOccurrence = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/workshop_occurrences/upcoming`;
  const fn = async () => {
    const { data } = await client.get<GetUpcomingWorkshopOccurrence.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useUpcomingWorkshopOccurrenceQuery = () => {
  const { path: queryKey, fn } = generateGetUpcomingWorkshopOccurrence();
  return useQuery<GetUpcomingWorkshopOccurrence.Response>(queryKey, fn);
};

/*
 * GET /workshop_occurrences/upcoming_team
 */

const generateGetUpcomingTeamWorkshopOccurrence = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/workshop_occurrences/upcoming_team`;
  const fn = async () => {
    const { data } = await client.get<GetUpcomingTeamWorkshopOccurrence.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useUpcomingTeamWorkshopOccurrenceQuery = () => {
  const { path: queryKey, fn } = generateGetUpcomingTeamWorkshopOccurrence();
  return useQuery<GetUpcomingTeamWorkshopOccurrence.Response>(queryKey, fn);
};

/*
 * GET /workshop_occurrences/upcoming_multiple
 */
const generateGetUpcomingWorkshopOccurrences = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshop_occurrences/upcoming_multiple`;
  const fn = async () => {
    const { data } = await client.get<GetUpcomingWorkshopOccurrences.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useUpcomingWorkshopOccurrencesQuery = (
  ops: UseQueryOptions<GetUpcomingWorkshopOccurrences.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetUpcomingWorkshopOccurrences();
  return useQuery<GetUpcomingWorkshopOccurrences.Response>(queryKey, fn, ops);
};

/*
 * GET /workshop_occurrences/past_multiple
 */
const generateGetPastWorkshopOccurrences = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/workshop_occurrences/past_multiple`;
  const fn = async () => {
    const { data } = await client.get<GetPastWorkshopOccurrences.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const usePastWorkshopOccurrencesQuery = (
  ops: UseQueryOptions<GetPastWorkshopOccurrences.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetPastWorkshopOccurrences();
  return useQuery<GetPastWorkshopOccurrences.Response>(queryKey, fn, ops);
};

/*
 * GET /workshops/mine_past_summary
 */

const generateGetMyPastWorkshopsSummary = () => {
  const client = createAxiosClient({ auth: true, transformPropertyNameCase: true });
  const path = '/v1/workshops/mine_past_summary';

  const fn = async () => {
    const { data } = await client.get<GetMyPastWorkshopsSummary.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useMyPastWorkshopsSummaryQuery = (
  ops: UseQueryOptions<GetMyPastWorkshopsSummary.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetMyPastWorkshopsSummary();
  return useQuery<GetMyPastWorkshopsSummary.Response>(queryKey, fn, ops);
};

/*
 * =============================================
 * Admin
 * =============================================
 */

const generateAdminGetWorkshopsSearch = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const basePath = '/admin/workshops/search';
  const fn = async ({
    queryKey: [, queryParams],
  }: QueryFunctionContext<[string, AdminGetWorkshopsSearch.Request['queryParams']]>) => {
    const { data } = await client.get<AdminGetWorkshopsSearch.Response>(
      appendQueryParamsWithCaseTransform(basePath, queryParams)
    );
    return data;
  };
  return { fn, basePath };
};

export const useAdminGetWorkshopsSearch = (
  queryParams: AdminGetWorkshopsSearch.Request['queryParams'],
  options: UseQueryOptions<AdminGetWorkshopsSearch.Response>
) => {
  const { fn, basePath } = generateAdminGetWorkshopsSearch();
  return useQuery<AdminGetWorkshopsSearch.Response>([basePath, queryParams], fn, options);
};
