import { AnyObject, ValueOf } from '@coa/types';
import { Member } from '../member';

// This entire file has moved to @coa/api/models
export const enrollmentStatuses = {
  pending: 'pending',
  approved: 'approved',
  awaitingInfo: 'awaiting_info',
  applicationIncomplete: 'application_incomplete',
  rejected: 'rejected',
  removed: 'removed',
} as const;
export type EnrollmentStatus = ValueOf<typeof enrollmentStatuses>;

/*
 * These statuses correspond to an "applied but not yet removed" state,
 * meaning - generally - we can surface the class in My Coa.
 */
export const notRemovedEnrollmentStatuses: EnrollmentStatus[] = [
  enrollmentStatuses.applicationIncomplete,
  enrollmentStatuses.pending,
  enrollmentStatuses.approved,
  enrollmentStatuses.awaitingInfo,
];
export const removedEnrollmentStatuses: EnrollmentStatus[] = [
  enrollmentStatuses.removed,
  enrollmentStatuses.rejected,
];
export const userActionRequiredEnrollmentStatuses: EnrollmentStatus[] = [
  enrollmentStatuses.awaitingInfo,
  enrollmentStatuses.applicationIncomplete,
];

export const enrollmentFitAssessments = {
  uncategorized: 'uncategorized',
  notAFit: 'not_a_fit',
  maybeAFit: 'maybe_a_fit',
  goodFit: 'good_fit',
} as const;
export type EnrollmentFitAssessment = ValueOf<typeof enrollmentFitAssessments>;

export type EnrollmentApplicationAnswers = AnyObject; // unknown

export type Enrollment = {
  id: string;
  workshopId: string;
  memberId: string;
  status: EnrollmentStatus;
  answers: EnrollmentApplicationAnswers;
  fitAssessment: EnrollmentFitAssessment;
};

export type SerializedEnrollment = Omit<Enrollment, 'memberId' | 'workshopId'> &
  Pick<Member, 'name' | 'pronouns' | 'email'>;

export type TransientEnrollmentFactoryAttributes = {
  dropInOccurrenceIndex: number;
};

export type EnrollmentCreateRequest = Pick<Member, 'email'>;
