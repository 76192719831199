export const CLEAROUT_APPOINTMENT = 'CLEAROUT_APPOINTMENT';

export const APPOINTMENTS_REQUEST = 'APPOINTMENTS_REQUEST';
export const APPOINTMENTS_SUCCESS = 'APPOINTMENTS_SUCCESS';
export const APPOINTMENTS_FAILURE = 'APPOINTMENTS_FAILURE';

export function create(therapistId, data) {
  return {
    type: APPOINTMENTS_REQUEST,
    api: {
      data,
      endpoint: `therapists/${therapistId}/appointments`,
      authenticated: true,
      types: [APPOINTMENTS_REQUEST, APPOINTMENTS_SUCCESS, APPOINTMENTS_FAILURE],
      method: 'POST',
    },
  };
}

export const APPOINTMENTS_DESTROY_REQUEST = 'APPOINTMENTS_DESTROY_REQUEST';
export const APPOINTMENTS_DESTROY_SUCCESS = 'APPOINTMENTS_DESTROY_SUCCESS';
export const APPOINTMENTS_DESTROY_FAILURE = 'APPOINTMENTS_DESTROY_FAILURE';

export function destroy(therapistId, appointmentId) {
  return {
    type: APPOINTMENTS_DESTROY_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/appointments/${appointmentId}`,
      authenticated: true,
      types: [
        APPOINTMENTS_DESTROY_REQUEST,
        APPOINTMENTS_DESTROY_SUCCESS,
        APPOINTMENTS_DESTROY_FAILURE,
      ],
      method: 'DELETE',
    },
  };
}
