// We have a desktop and mobile iteration of the same Marketing Banner for a few reasons:
// - They live in separate places within NavBarParent and rather adjust the flex order I chose to
// position them based on the device size where we hide/show accordingly.
// - Each banner's link directs a user to a different place depending on the device they're on.
import {
  Box,
  Button,
  CloseButton,
  Heading,
  Image,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useCookies } from 'react-cookie';
import { ArrowRightIcon } from '../../../../components/Icons';
import MobileAppImage from '../../../../images/mobile-app.png';
import { useSession } from '../../../../resources/sessions';
import { boxShadowStyles } from '../../../../themes/coaTheme';

// Add number of days to a Date and return that Date
const addDaysToDate = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const DesktopAppMarketingToast = () => {
  const { role } = useSession();
  const cookieName = 'app_toast_alert';
  const [cookies, setCookie] = useCookies([cookieName]);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    const now = new Date();
    const weekFromNow = addDaysToDate(now, 7);
    setCookie(cookieName, 1, { expires: weekFromNow });
    onClose();
  };

  return role === 'client' && isOpen && !cookies.app_toast_alert ? (
    <Box
      display={{ base: 'none', md: 'flex' }}
      position="fixed"
      maxWidth="480px"
      bottom={12}
      overflow="hidden"
      right={{ md: 8, lg: 12 }}
      bgColor="white"
      borderRadius="lg"
      zIndex="banner"
      {...boxShadowStyles}
    >
      <Box px={4} pt={4} bgColor="warm.50">
        <Image src={MobileAppImage} w="110px" />
      </Box>
      <VStack p={4} pr={6} justifyContent="space-between" alignItems="flex-start">
        <Box pr={12}>
          <Heading fontSize="24px" my={2}>
            Coa App on iPhone
          </Heading>
          <Text>Daily emotional push-ups, on the go.</Text>
        </Box>
        <Button
          as={Link}
          href="#CoaAppQRCode"
          variant="solid"
          colorScheme="red"
          rightIcon={<ArrowRightIcon />}
        >
          Download
        </Button>
      </VStack>
      <CloseButton position="absolute" size="sm" right={2} top={2} onClick={handleClose} />
    </Box>
  ) : null;
};

export const MobileAppMarketingBanner = () => {
  const { role } = useSession();
  const cookieName = 'app_toast_alert';
  const [cookies, setCookie] = useCookies([cookieName]);
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    const now = new Date();
    const weekFromNow = addDaysToDate(now, 7);
    setCookie(cookieName, 1, { expires: weekFromNow });
    onClose();
  };

  return role === 'client' && isOpen && !cookies.app_toast_alert ? (
    <Box
      bgColor="lime.200"
      position="relative"
      w="100%"
      display={{ base: 'flex', md: 'none' }}
      flexDir="column"
      textAlign="center"
      pr={4}
      py={3}
      color="brand.charcoal"
    >
      <Text fontSize="18px">📱 Get daily emotional push-ups on the go.</Text>
      <Link
        href="https://apps.apple.com/us/app/coa-mental-health/id1644918032"
        variant="underline"
        isExternal
        fontSize="18px"
        w="auto"
      >
        Download the Coa App
      </Link>
      <CloseButton
        position="absolute"
        size="sm"
        right={0}
        top="50%"
        transform="translate(-50%, -50%)"
        onClick={handleClose}
      />
    </Box>
  ) : null;
};
