import { Flex, Skeleton, useToast } from '@chakra-ui/react';
import { useOnMount, useRouteParams } from '@coa/react-utils';
import { Form } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { tryPushupsAnalytics } from '../../../lib/analytics/clients/tryPushupsAnalytics';
import { usePostWorkshopEnrollmentMutation } from '../../../resources/workshops';
import { LegalLinks } from '../../shared/layout/components/LegalLinks';
import {
  TryLayout,
  TryLayoutMainContent,
  TryLayoutSecondaryContent,
} from '../components/TryWorkshopLayout';
import { TryWorkshopNoUpcomingBtn } from '../components/TryWorkshopNoUpcomingBtn';
import {
  TryWorkshopRegistrationForm,
  TryWorkshopRegistrationFormikContainer,
  TryWorkshopRegistrationFormSubmitButton,
} from '../components/TryWorkshopRegistrationForm';
import { TryWorkshopLandingStaticContent } from '../components/TryWorkshopStaticContent';
import {
  TryUserExistsProvider,
  useTryUserExists,
} from '../components/TryWorkshopUserExistsProvider';
import { SelectedPushupProvider, useSelectedPushup } from './components/SelectedPushupProvider';
import { TryPushupSuccess } from './components/TryPushupSuccess';

export function TryPushupsViewInner() {
  const {
    selectedPushup,
    setSelectedPushupId,
    enrolledInSelectedPushup,
    pushupsQuery,
  } = useSelectedPushup();
  const history = useHistory();
  const toast = useToast();
  const workshopEnrollmentMutation = usePostWorkshopEnrollmentMutation({ id: selectedPushup?.id });
  const { userExists } = useTryUserExists();

  const { b2bPartner } = useRouteParams<{ b2bPartner: string }>();
  const isClassPass = Boolean(b2bPartner) && b2bPartner.toLowerCase() === 'classpass';

  useOnMount(() => {
    tryPushupsAnalytics.track('Viewed Try Pushups');
  });

  useEffect(() => {
    if (workshopEnrollmentMutation.isSuccess) {
      tryPushupsAnalytics.track('Enrolled in Pushup', { workshopId: selectedPushup?.id });
    }
  }, [workshopEnrollmentMutation]);

  const handleRegistration = useCallback(async () => {
    try {
      await workshopEnrollmentMutation.mutateAsync({
        answers: {},
      });
      // Don't gate this with the credit card form
      // let membershipUrl = '/membership?pushup=true';
      // if (isClassPass) membershipUrl += '&b2bPartner=classpass';
      // history.push(membershipUrl);

      history.push('/my-coa');
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);

  const { isLoading } = pushupsQuery;
  const showSuccessState = workshopEnrollmentMutation.isSuccess;
  const hasUpcomingPushups = pushupsQuery.data.length > 0;

  // This isn't create, but the TryMain / TrySecondary layouts
  // have a set height for Desktop at 660px
  // the ClassPass partnership copy is longer than the generic
  // so we have 750px as height
  const contentSectionHeight = isClassPass ? 750 : undefined;
  return (
    <TryLayout>
      <TryWorkshopRegistrationFormikContainer
        userExists={userExists}
        handleRegistrationSuccess={handleRegistration}
      >
        <>
          <TryLayoutMainContent height={contentSectionHeight}>
            <TryWorkshopLandingStaticContent
              hasUpcomingWorkshop={hasUpcomingPushups}
              type="pushups"
              tryWorkshopQuery={pushupsQuery}
              selectedTryWorkshopId={selectedPushup?.id}
              setSelectedTryWorkshopId={setSelectedPushupId}
              enrolledInSelectedWorkshop={enrolledInSelectedPushup}
              loading={isLoading}
            />
          </TryLayoutMainContent>
          <TryLayoutSecondaryContent height={contentSectionHeight}>
            <Skeleton
              isLoaded={!isLoading}
              data-cy={`secondary-content-${isLoading ? 'loading' : 'loaded'}`}
            >
              {!hasUpcomingPushups ? (
                <TryWorkshopNoUpcomingBtn type="pushups" />
              ) : (
                <Flex justifyContent="center" flexDir="column" as={Form}>
                  {showSuccessState ? (
                    <TryPushupSuccess />
                  ) : (
                    <>
                      <TryWorkshopRegistrationForm
                        isB2B={selectedPushup?.isB2B}
                        enrolledInSelectedWorkshop={enrolledInSelectedPushup}
                        workshopType="pushups"
                      />
                      <TryWorkshopRegistrationFormSubmitButton
                        colorScheme="red"
                        type="submit"
                        size="lg"
                        enrolledInSelectedWorkshop={enrolledInSelectedPushup}
                        workshopType="pushups"
                      />
                      <LegalLinks
                        fontSize="sm"
                        justifyContent="center"
                        mt={16}
                        display={{ base: 'flex', md: 'none' }}
                        flexDir="row"
                      />
                    </>
                  )}
                </Flex>
              )}
            </Skeleton>
          </TryLayoutSecondaryContent>
        </>
      </TryWorkshopRegistrationFormikContainer>
    </TryLayout>
  );
}

export function TryPushupsView() {
  return (
    <SelectedPushupProvider>
      <TryUserExistsProvider>
        <TryPushupsViewInner />
      </TryUserExistsProvider>
    </SelectedPushupProvider>
  );
}
