import { AnyObject } from '@coa/types';
import _ from 'lodash';

/*
 * Creates helpers to pick props to preserve types - helpful when
 * props need to be filtered when spreading from one parent type
 * to another.
 */
const createPickSharedProps = (propList: string[]) => <Props extends AnyObject = AnyObject>(
  props: Props & AnyObject
) => _.pick(props, propList);

/*
 * Note that the list(s) below are not exhaustive and should not
 * be treated as such - modify as necessary.
 */

const sharedLayoutProps = [
  'm',
  'mx',
  'my',
  'mt',
  'mr',
  'mb',
  'ml',
  'margin',
  'marginX',
  'marginY',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'p',
  'px',
  'py',
  'pt',
  'pr',
  'pb',
  'pl',
  'padding',
  'paddingX',
  'paddingY',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'h',
  'height',
  'w',
  'width',
];

export const pickSharedLayoutProps = createPickSharedProps(sharedLayoutProps);
