import { AdminSerializedOnDemandVideo } from '../../../serializers/admin/AdminSerializedOnDemandVideo';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from '../cms';

const onDemandVideosCmsParams = {
  basePath: '/admin/on_demand_videos',
  auth: true,
};

export const useAdminIndexOnDemandVideosQuery = generateUseIndexQuery<
  AdminSerializedOnDemandVideo,
  AdminCmsIndexFilterParams<AdminSerializedOnDemandVideo>
>(onDemandVideosCmsParams);
export const useAdminShowOnDemandVideoQuery = generateUseShowQuery<AdminSerializedOnDemandVideo>(
  onDemandVideosCmsParams
);
export const useAdminCreateOnDemandVideoMutation = generateUseCreateMutation<
  AdminSerializedOnDemandVideo
>(onDemandVideosCmsParams);
export const useAdminUpdateOnDemandVideoMutation = generateUseUpdateMutation<
  AdminSerializedOnDemandVideo
>(onDemandVideosCmsParams);
