import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export function useQueryParams() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

/*
 * By convention, we typically rename these via `as` on import
 * so we consolidate here for easier reuse.
 */
export const RouterLink = Link; // Differentiate from Link via @chakra-ui
export const useRouteParams = useParams; // Differentiate from useQueryParams (above)

export * from './hooks';
