import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { TeamAccessKind } from '@coa/api/controllers/v1/teams/types';
import React, { ReactElement } from 'react';
import { useAdminGetTopLevelTeamsQuery } from '../../../resources/teams';
import { CreateTopLevelTeamButton } from './components/CreateTeamModal';
import { TeamsTable } from './components/TeamsTable';

function TableEmptyState(): JSX.Element {
  return (
    <Box bg="gray.50" borderRadius="base" px={4} py={16} textAlign="center">
      <Heading size="lg">There's nothing here.</Heading>
      <Text>Create an Organization to get started.</Text>
      <CreateTopLevelTeamButton variant="primary" mt={4}>
        Create Organization
      </CreateTopLevelTeamButton>
    </Box>
  );
}

function TopLevelTeamsTable() {
  const { data, isLoading } = useAdminGetTopLevelTeamsQuery();
  if (isLoading) return null;

  const { data: teams = [] } = data;
  if (teams.length === 0) return <TableEmptyState />;

  const handleGetTo = (id: string, accessKind: TeamAccessKind) => {
    let url = `/admin-dash/teams/${id}`;
    if (accessKind === 'membership') {
      url += '/members';
    }
    return url;
  };

  return (
    <>
      <Flex justifyContent="space-between" flexDir="row-reverse">
        <CreateTopLevelTeamButton variant="secondary">Create Organization</CreateTopLevelTeamButton>
      </Flex>

      <TeamsTable getTo={handleGetTo} teams={teams} />
    </>
  );
}

export function AdminTopLevelTeamsView(): ReactElement | null {
  return (
    <Container maxW="container.xl">
      <Flex justifyContent="space-between" mb={9}>
        <Heading>Organizations</Heading>
      </Flex>
      <TopLevelTeamsTable />
    </Container>
  );
}
