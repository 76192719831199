import { Box } from '@chakra-ui/react';
import React from 'react';
import { CardElement } from 'react-stripe-elements';

const STYLE = {
  base: {
    color: '#0C383A',
    fontFamily: `Founders, sans-serif`,
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      // @Victor what color is placeholder text on other inputs?
      // @added here, but not sure yet this is appearing as intended, color is ref is dashboard.theme
      color: '#D1AE91',
    },
  },
  invalid: {
    color: '#EB8072',
    iconColor: '#EB8072',
  },
};

class CardSection extends React.Component {
  render() {
    return (
      <Box padding="12px" borderRadius="4px" my="8px" bg="warm.50">
        <CardElement style={STYLE} />
      </Box>
    );
  }
}

export default CardSection;
