import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

export default function SimpleTextField({
  type,
  label,
  name,
  placeholder,
  updateAttr,
  value,
  autoFocus,
  errors = {},
  ...rest
}) {
  return (
    <FormControl isInvalid={!!errors[name]}>
      <FormLabel htmlFor="name">{label}</FormLabel>
      <FormErrorMessage>{errors[name]}</FormErrorMessage>
      <Input
        required
        variant="outlined"
        padding="12px"
        borderRadius="4px"
        mb="8px"
        backgroundColor="warm.50"
        css={{ '::placeholder': { color: '#D1AE91' } }}
        placeholder={placeholder || label}
        label={label}
        name={name}
        type={type || 'text'}
        autoComplete={name}
        onChange={(e) => updateAttr(e, name)}
        value={value}
        autoFocus={!!autoFocus}
        {...rest}
      />
    </FormControl>
  );
}

SimpleTextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updateAttr: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
};
