import {
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  IconButtonProps,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../../lib/react-query';
import { GetTeam, Team, useGetTeamQuery, usePutTeamMutation } from '../../../../resources/teams';

const editTeamNameFormValidationSchema = Yup.object().shape({
  title: Yup.string().min(1).required(),
});

const EditTeamNameModal = ({
  isOpen,
  onClose,
  id,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & {
  id: string;
}) => {
  const rawPutTeamMutation = usePutTeamMutation({ id });
  const putTeamMutation = useMutationWithTimer(rawPutTeamMutation, {
    duration: 500,
  });
  const { isLoadingWithTimer } = putTeamMutation;
  const teamQuery = useGetTeamQuery({ id });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    putTeamMutation.resetWithTimer();
  }, []);

  const { data: { attributes: teamAttrs = {} as GetTeam.Response['data']['attributes'] } = {} } =
    teamQuery.data || {};

  const handleSubmit = useCallback(async ({ title }) => {
    try {
      await putTeamMutation.mutateAsyncWithTimer({ title });
      handleClose();
      toast({
        title: 'Success!',
        description: 'Team name successfully updated!',
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something went wrong.',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  }, []);

  if (teamQuery.isLoading) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md" variant="coa-main">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Edit Team Name</Heading>
        </ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <Formik
          initialValues={{ title: teamAttrs.title }}
          validationSchema={editTeamNameFormValidationSchema}
          onSubmit={handleSubmit}
          isInitialValid
        >
          {({ isValid }) => (
            <Form>
              <ModalBody>
                <Field as={Input} variant="coa-main" size="lg" name="title" />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    isLoading={isLoadingWithTimer}
                    isDisabled={isLoadingWithTimer || !isValid}
                  >
                    Update Team Name
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export const EditTeamNameButton = ({ id, ...rest }: IconButtonProps & { id: Team['id'] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton onClick={onOpen} {...rest} />
      <EditTeamNameModal id={id} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
