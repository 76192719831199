/* eslint-disable react/prop-types */
import { Box, Flex, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { show as accountShow } from '../actions/accounts';
import { show as clientShow } from '../actions/clientPaymentProfiles';
import { clientList, markAsVoid, pay, refund, therapistList } from '../actions/invoices';
import BillingHistoryDetails from '../components/BillingHistory';
import Loading from '../components/Loading';
import { sessionLocalStorage } from '../lib/localStorage';
import PaymentProfileContainer from './PaymentProfileContainer';

const LIST_FUNCS = {
  therapist: therapistList,
  client: clientList,
};

class InvoicePaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.setShow = this.setShow.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.payInvoice = this.payInvoice.bind(this);
    this.voidInvoice = this.voidInvoice.bind(this);
    this.refundInvoice = this.refundInvoice.bind(this);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    if (currentMember) {
      const { role } = currentMember;
      this.getInvoices(role);
      dispatch(accountShow());
      dispatch(clientShow(currentMember.id));
    }
  }

  setShow() {
    this.setState((state) => ({ ...state, show: !state.show }));
  }

  getInvoices(role) {
    const { dispatch, memberId } = this.props;
    dispatch(LIST_FUNCS[role](memberId));
  }

  payInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to charge this invoice to your payment method on file?')) {
      dispatch(pay(memberId, invoice.id));
    }
  }

  refundInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to refund this invoice?')) {
      dispatch(refund(memberId, invoice.id));
    }
  }

  voidInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to void this invoice?')) {
      dispatch(markAsVoid(memberId, invoice.id));
    }
  }

  render() {
    const { dispatch, loading, invoices, invoiceMembers, paymentProfile } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();

    if (loading || !currentMember) {
      return <Loading />;
    }
    const hasPaymentMethod = paymentProfile.payment_methods.length > 0;
    return (
      <Flex align="center" width="stretch" direction="column">
        <Heading m={4} p={4}>
          Your Invoices
        </Heading>
        {!hasPaymentMethod && <Heading fontSize="md">Set up your Payment Method</Heading>}
        {!hasPaymentMethod && (
          <Box borderColor="green.900" borderWidth={5} width={{ sm: '90%', md: '50%' }} padding={5}>
            <PaymentProfileContainer
              dispatch={dispatch}
              memberId={currentMember.id}
              paymentProfile={paymentProfile}
              showFormIfNeccessary
            />
          </Box>
        )}
        <BillingHistoryDetails
          viewerRole={currentMember.role}
          invoices={invoices}
          invoiceMembers={invoiceMembers}
          payInvoice={this.payInvoice}
          refundInvoice={this.refundInvoice}
          voidInvoice={this.voidInvoice}
          close={this.setShow}
          canPay={hasPaymentMethod}
        />
      </Flex>
    );
  }
}

InvoicePaymentContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { loading, paymentProfile } = state.clientPaymentProfiles;

  const { invoices, invoiceMembers, gettingInvoices } = state.invoices;

  return {
    loading,
    paymentProfile,
    invoices,
    invoiceMembers,
    gettingInvoices,
  };
};

export default connect(mapStateToProps)(InvoicePaymentContainer);
