import { CircularProgress, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

function Loading({ fillPage }) {
  if (fillPage)
    return (
      <Stack minH="80vh" pt="20vh">
        <CircularProgress isIndeterminate color="green.900" />
      </Stack>
    );
  return <CircularProgress isIndeterminate color="green.900" />;
}

Loading.propTypes = {
  fillPage: PropTypes.bool,
};

export default Loading;
