import { Box, Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import React from 'react';
import { useMultiPseudoStyleConfig } from '../../lib/chakra-ui';
import { IconBadge, IconBadgeProps } from '../atoms/IconBadge';

type IconListItemProps = FlexProps &
  IconBadgeProps & {
    disabled?: boolean;
  };

type IconListItemContext = Pick<IconListItemProps, 'disabled'>;

const [IconListItemProvider, useIconListItem] = createContext<IconListItemContext>({
  strict: true,
  name: 'IconListItemContext',
  errorMessage:
    'useIconListItem: `context` is undefined. Seems you forgot to wrap modal components in `<IconListItemProvider />`',
});

export const IconListItem = ({
  children,
  icon,
  size,
  colorScheme,
  disabled,
  ...rest
}: IconListItemProps) => (
  <Flex {...rest}>
    <IconBadge icon={icon} size={size} colorScheme={colorScheme} disabled={disabled} />
    <IconListItemProvider value={{ disabled }}>
      <Box ml={2} mt="-3px">
        {children}
      </Box>
    </IconListItemProvider>
  </Flex>
);

export const IconListItemTitle = ({ children, ...rest }: TextProps) => {
  const { disabled } = useIconListItem();
  const { _disabled: disabledStyles, ...baseStyles } = useMultiPseudoStyleConfig(
    'IconListItem',
    rest
  ).title;
  const styles = {
    ...baseStyles,
    ...(disabled ? disabledStyles : {}),
  };
  return (
    <Text sx={styles} {...rest}>
      {children}
    </Text>
  );
};

export const IconListItemSubtitle = ({ children, ...rest }: TextProps) => {
  const { disabled } = useIconListItem();
  const { _disabled: disabledStyles, ...baseStyles } = useMultiPseudoStyleConfig(
    'IconListItem',
    rest
  ).subtitle;
  const styles = {
    ...baseStyles,
    ...(disabled ? disabledStyles : {}),
  };
  return (
    <Text sx={styles} {...rest}>
      {children}
    </Text>
  );
};
