import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import {
  PostHubspotTemplates,
  usePostHubspotTemplatesMutation,
} from '../../resources/hubspotTemplates';

const hubspotTemplateSchema = Yup.object().shape({
  label: Yup.string().typeError('Please enter a valid label').required('Label is required'),
  hubspotEmailId: Yup.number()
    .typeError('Please enter a valid number')
    .required('Hubspot Email ID is required'),
});

type HubspotTemplateFormValues = PostHubspotTemplates.Request['body'];

export const HubspotTemplateForm = () => {
  const toast = useToast();
  const history = useHistory();
  const postHubspotTemplatesMutation = usePostHubspotTemplatesMutation();
  return (
    <Container maxWidth="container.sm">
      <Heading my={5}>Add a Hubspot Email Template</Heading>
      <Formik
        initialValues={{} as HubspotTemplateFormValues}
        isInitialValid={false}
        validationSchema={hubspotTemplateSchema}
        onSubmit={async (rawValues) => {
          const values = {
            ...rawValues,
            // For some reason, Yup is converting this value to a string
            // at validation time. We should really fix the core issue,
            // but for the sake of velocity, we just bandaid it by manually
            // converting back to a number.
            hubspotEmailId: parseInt((rawValues.hubspotEmailId as unknown) as string),
          };
          try {
            await postHubspotTemplatesMutation.mutateAsync(values);
            history.push('/admin-dash/classes');
            toast({
              title: 'Success!',
              description: 'Template successfully added',
              duration: 2000,
              isClosable: true,
              status: 'success',
            });
          } catch (err) {
            toast({
              title: 'Unable to create Hubspot Template',
              description: `${JSON.stringify(err.response.data, null, 2)}`,
              duration: 2000,
              isClosable: true,
              status: 'error',
            });
          }
        }}
      >
        {({ isValid }) => (
          <Form>
            <FormControl>
              <FormLabel>Label</FormLabel>
              <Field
                as={Input}
                type="text"
                variant="coa-main"
                name="label"
                placeholder="Mental Wellness Reminder"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Hubspot Email ID</FormLabel>
              <Field
                as={Input}
                type="number"
                name="hubspotEmailId"
                variant="coa-main"
                placeholder="123455"
              />
            </FormControl>
            <Button
              variant="primary"
              type="submit"
              isDisabled={!isValid || postHubspotTemplatesMutation.isLoading}
              isLoading={postHubspotTemplatesMutation.isLoading}
              my={4}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
