import { Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import NewPasswordContainer from './containers/NewPasswordContainer';
import { AuthLayout } from './layouts/AuthLayout';

export function NewPasswordView(): JSX.Element {
  return (
    <AuthLayout>
      <NewPasswordContainer />

      <Route path="/passwords/new/:token">
        <Stack align="center">
          <Text>Remember your password?</Text>
          <Link color="green.900" as={RouterLink} to="/log-in" variant="body2">
            Sign in
          </Link>
        </Stack>
      </Route>
    </AuthLayout>
  );
}
