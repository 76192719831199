import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RouterLink, useInitial, useIsMounted } from '@coa/react-utils';
import { Field, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { acknowledgementOfClinicalContext } from '../../../lib/copy';
import { useCreateRegistration } from '../../../resources/registrations';
import { useCreateSession, useSession } from '../../../resources/sessions';
import { TryWorkshopType } from '../../../resources/workshops';
import { getTryWorkshopDisplayNameFromType } from '../utils';
import { useTryUserExists } from './TryWorkshopUserExistsProvider';

type CreateValidationSchemaParams = { loggedIn: boolean; userExists: boolean };
const createValidationSchema = ({ loggedIn, userExists }: CreateValidationSchemaParams) =>
  Yup.object().shape({
    ...(loggedIn
      ? {} // If logged in, we hide the fields, so no need to validate.
      : {
          name: Yup.string().min(1).required('Please type your full name.'),
          email: Yup.string().email().required('Please provide a valid email address.'),
          company_name: Yup.string(),
          ...(userExists
            ? {
                password: Yup.string().min(1).required('Please provide a valid password'),
              }
            : {}),
        }),
    acceptTerms: Yup.boolean().oneOf([true]).required('This field must be checked.'),
  });

export function TryWorkshopRegistrationFormikContainer({
  children,
  handleRegistrationSuccess = _.noop,
  handleRegisteredOnMount = _.noop,
  userExists,
}: {
  children: React.ReactNode;
  handleRegistrationSuccess?: () => void;
  handleRegisteredOnMount?: () => void;
  userExists: boolean;
}) {
  const { createRegistration, loggedIn } = useCreateRegistration();
  const { createSession } = useCreateSession();
  const { isMounted } = useIsMounted();
  const initialValues: RegistrationFormValues = { name: '', email: '', company_name: '' };
  /*
   *  We can't simply await `createRegistration()` here because it
   *  isn't async. :/ Once auth is moved into react-query, we'll
   *  have that for free.
   *
   *  In the meantime, we manually detect a change in the registration
   *  state and execute the side-effect.
   */
  useEffect(() => {
    if (loggedIn) {
      if (!isMounted) {
        handleRegisteredOnMount();
      } else {
        handleRegistrationSuccess();
      }
    }
  }, [loggedIn]);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={createValidationSchema({ loggedIn, userExists })}
      onSubmit={({ name, email, password, company_name }) => {
        if (loggedIn) {
          // If user is already logged in, there's no registration
          // necessary, so we just skip it.
          handleRegistrationSuccess();
        } else if (userExists) {
          // Otherwise, if the user exists, we log the user in
          // rather than registering them.
          createSession({ email, password });
        } else {
          createRegistration({ name, email, company_name, role: 'client' });
        }
      }}
    >
      {children}
    </Formik>
  );
}

function LoggedInState({
  enrolledInSelectedWorkshop,
  ...props
}: { enrolledInSelectedWorkshop: boolean } & StackProps) {
  const { email } = useSession();
  return (
    <VStack spacing={8} w="100%" {...props}>
      <Box bg="lime.100" textAlign="center" py={8} w="100%" borderRadius="base">
        <Text textColor="lime.900">
          You are logged in as
          <br />
          <strong>{email}</strong>.
        </Text>
      </Box>
      {enrolledInSelectedWorkshop ? <Text>You are already enrolled in this class!</Text> : null}
    </VStack>
  );
}

function ExistingUserErrorMessage() {
  return (
    <>
      A user already exists with that email. Login to continue.{' '}
      <Link variant="underline" as={RouterLink} to="/forgot-password" display="inline">
        Forgot Password?
      </Link>
    </>
  );
}

type RegistrationFormValues = {
  name: string;
  email: string;
  password?: string;
  company_name?: string;
};
type TryWorkshopRegistrationFormProps = {
  enrolledInSelectedWorkshop: boolean;
  isB2B: boolean;
  workshopType: TryWorkshopType;
};

export function TryWorkshopRegistrationForm({
  enrolledInSelectedWorkshop,
  isB2B,
  workshopType,
}: TryWorkshopRegistrationFormProps) {
  const { errors: createRegistrationErrors = {}, loggedIn } = useCreateRegistration();
  const { touched, errors: formikErrors = {} } = useFormikContext<RegistrationFormValues>();
  const { userExists } = useTryUserExists();

  /*
   * We can't rely on using `loggedIn` as the user is (if necessary) using
   * this form to register, meaning that `loggedIn` will change value and
   * cause a content flash.
   *
   * Instead, we cache the initial value of loggedIn to prevent the flash.
   */
  const loggedInOnMount = useInitial(loggedIn);

  return (
    <VStack mb={8} spacing={4} width="100%">
      {loggedInOnMount ? (
        <LoggedInState enrolledInSelectedWorkshop={enrolledInSelectedWorkshop} />
      ) : (
        <>
          <FormControl
            isInvalid={createRegistrationErrors.name && touched.name}
            isDisabled={userExists}
          >
            <FormLabel htmlFor="name">Full Name</FormLabel>
            <Field
              disabled={userExists}
              name="name"
              type="name"
              as={Input}
              variant="coa-main"
              placeholder="Your first and last name"
              data-cy={`${workshopType}-form-name`}
            />
            <FormErrorMessage>{createRegistrationErrors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={createRegistrationErrors.email && touched.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Field
              name="email"
              type="email"
              as={Input}
              variant="coa-main"
              placeholder="you@email.com"
              data-cy={`${workshopType}-form-email`}
            />
            <FormErrorMessage>{createRegistrationErrors.email}</FormErrorMessage>
          </FormControl>
          {isB2B ? (
            <FormControl isInvalid={formikErrors.company_name && touched.company_name}>
              <FormLabel htmlFor="email">Company</FormLabel>
              <Field
                name="company_name"
                type="company_name"
                as={Input}
                variant="coa-main"
                placeholder="Your Company"
                data-cy={`${workshopType}-form-company-name`}
              />
              <FormErrorMessage>{formikErrors.company_name}</FormErrorMessage>
            </FormControl>
          ) : null}
          <Collapse in={userExists} style={{ width: '100%' }} unmountOnExit>
            <FormControl isInvalid={Boolean(createRegistrationErrors.password)}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Field
                name="password"
                type="password"
                as={Input}
                variant="coa-main"
                placeholder="A secure password"
                data-cy={`${workshopType}-form-password`}
              />
              <FormErrorMessage display="block">
                {userExists ? <ExistingUserErrorMessage /> : createRegistrationErrors.password}
              </FormErrorMessage>
            </FormControl>
          </Collapse>
        </>
      )}
      {enrolledInSelectedWorkshop ? null : (
        <FormControl>
          <Field
            type="checkbox"
            name="acceptTerms"
            as={Checkbox}
            variant="coa-main"
            mt={2}
            data-cy={`${workshopType}-form-accept-terms`}
          >
            <Text fontSize="sm">{acknowledgementOfClinicalContext}</Text>
          </Field>
        </FormControl>
      )}
    </VStack>
  );
}

export function TryWorkshopRegistrationFormSubmitButton({
  enrolledInSelectedWorkshop,
  workshopType,
  ...props
}: { enrolledInSelectedWorkshop: boolean; workshopType: TryWorkshopType } & ButtonProps) {
  const { isValid } = useFormikContext();
  const { registering } = useCreateRegistration();
  const workshopDisplayName = getTryWorkshopDisplayNameFromType(workshopType);

  const ctaEnrollText = workshopType === 'pushups' ? 'Schedule my Workout' : 'Save My Spot';
  const ctaText = enrolledInSelectedWorkshop ? `Go to my ${workshopDisplayName}` : ctaEnrollText;

  return (
    <Button
      data-cy={`${workshopType}-form-submit`}
      {...(enrolledInSelectedWorkshop
        ? {
            // If enrolled, we want a link to point the person to My Coa
            as: RouterLink,
            to: '/my-coa',
          }
        : {
            // If not enrolled, the button should reflect form-state
            // and submit onClick
            isLoading: registering,
            isDisabled: !isValid || registering, // TODO: Should this also include the enrollment mutation?
            type: 'submit',
          })}
      {...props}
    >
      {ctaText}
    </Button>
  );
}
