import { Box, Flex, FlexProps, Heading } from '@chakra-ui/react';
import React from 'react';
import { Route } from 'react-router';
import HeaderImage from '../../../components/HeaderImage';
import LogInContainer from '../../auth/containers/LogInContainer';
import NewPasswordContainer from '../../auth/containers/NewPasswordContainer';

function TeamsResponsiveWelcomeText({ children }: { children: React.ReactNode }) {
  /*
   * Heading's `size` prop isn't responsive for some reason, so we
   * simply show / hide two responsive versions of it.
   */
  return (
    <>
      <Box display={{ base: 'block', md: 'none' }}>
        <Heading size="sm" textAlign="center" mb={8}>
          {children}
        </Heading>
      </Box>
      <Box display={{ base: 'none', md: 'block' }}>
        <Heading size="md" textAlign="center" mx={{ md: -16 }} mb={8} px={{ md: 4 }}>
          {children}
        </Heading>
      </Box>
    </>
  );
}

export function TeamsAuthView(props: FlexProps): JSX.Element {
  return (
    <Flex direction="column" width="stretch" maxW={360} p={4} {...props}>
      <HeaderImage />
      <TeamsResponsiveWelcomeText>
        <Route path="/teams/claim-account/:token">
          You've been invited join your team at Coa. Set up your account to get started.
        </Route>
        <Route path="/teams/log-in">
          You've been enrolled in classes with your team at Coa. Log in to continue.
        </Route>
      </TeamsResponsiveWelcomeText>
      <Route path="/teams/claim-account/:token" component={NewPasswordContainer} />
      <Route path="/teams/log-in" component={LogInContainer} />
    </Flex>
  );
}
