export const EXERCISES_LIST_REQUEST = 'EXERCISES_LIST_REQUEST';
export const EXERCISES_LIST_SUCCESS = 'EXERCISES_LIST_SUCCESS';
export const EXERCISES_LIST_FAILURE = 'EXERCISES_LIST_FAILURE';

export function list() {
  return {
    type: EXERCISES_LIST_REQUEST,
    api: {
      endpoint: `exercises?per_page=500`,
      authenticated: true,
      admin: true,
      types: [EXERCISES_LIST_REQUEST, EXERCISES_LIST_SUCCESS, EXERCISES_LIST_FAILURE],
      method: 'GET',
    },
  };
}
