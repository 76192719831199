/* eslint-disable react/prop-types */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { create } from '../../../actions/passwords';
import { LOG_OUT } from '../../../actions/sessions';
import { sessionLocalStorage } from '../../../lib/localStorage';
import { DefaultRoute } from '../../../utils/DefaultRoute';
// import { LogOutRequiredToVerifyModal } from '../components/LogOutRequiredToVerifyModal';
import NewPassword from '../components/NewPassword';

class NewPasswordContainer extends Component {
  constructor(props) {
    super(props);
    const { match, loggedIn } = props;
    const { token } = match.params;
    this.state = {
      token,
      password: '',
      loggedInOnMount: loggedIn,
      attemptedPasswordCreate: false,
      redirecting: false,
    };
    this.updateAttr = this.updateAttr.bind(this);
    this.createPassword = this.createPassword.bind(this);
  }

  componentDidMount() {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn) {
      dispatch({ type: LOG_OUT });
    }
  }

  componentDidUpdate() {
    const { history, loggedIn } = this.props;
    const { attemptedPasswordCreate, redirecting } = this.state;
    if (loggedIn && attemptedPasswordCreate && !redirecting) {
      this.setState((prevState) => ({ ...prevState, redirecting: true }));
      history.push(this.getRedirectTo());
    }
  }

  getRedirectTo() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect_to');
    if (redirectTo) return redirectTo;

    const currentMember = sessionLocalStorage.getCurrentMember();
    if (currentMember) return DefaultRoute.for(currentMember);
  }

  // TODO: KEEP until we know the new solution is correct
  // getRequireLogout() {
  //   const { loggedIn } = this.props;
  //   const { loggedInOnMount, attemptedPasswordCreate } = this.state;
  //   /*
  //    * We currently assume that the user is logged out when they see this
  //    * component. (Without this assumption, we're unable to redirect upon
  //    * a successful password reset, as loggedIn-state does not change).
  //    *
  //    * In a world where authentication and registration are managed
  //    * explicitly (rather than using asynchronous actions and relying on
  //    * coordinated side-effects), this is not necessary, but for now we
  //    * force this requirement.
  //    *
  //    * We force the user to logOut if...
  //    *   - they were logged in when the page mounted
  //    *   - they are logged in (i.e. they have not yet logged out)
  //    */
  //   return (
  //     // User was logged in when the page mounted
  //     loggedInOnMount &&
  //     // User has not yet logged out
  //     loggedIn &&
  //     //   User has not yet engaged with the form, and therefore the
  //     //   "on mount" state is the latest and most relevant.
  //     !attemptedPasswordCreate
  //   );
  // }

  updateAttr(e, attr) {
    const newState = {};
    newState[attr] = e.target.value;
    this.setState(newState);
  }

  createPassword(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { token, password } = this.state;
    dispatch(create({ token, password }));
    this.setState((prevState) => ({ ...prevState, attemptedPasswordCreate: true }));
  }

  render() {
    const { creatingPassword, errors, match } = this.props;
    const { password } = this.state;

    const claimingAccount = match.path.indexOf('claim-account') !== -1;
    return (
      <>
        <NewPassword
          password={password}
          claimingAccount={claimingAccount}
          updateAttr={this.updateAttr}
          createPassword={this.createPassword}
          loading={creatingPassword}
          errors={errors}
        />
        {/* <LogOutRequiredToVerifyModal isOpen={this.getRequireLogout()} /> */}
      </>
    );
  }
}

NewPasswordContainer.propTypes = {
  history: PropTypes.object.isRequired,
  creatingPassword: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { creatingPassword, errors, loggedIn } = state.session;
  return { creatingPassword, errors, loggedIn };
};

export default _.flow([connect(mapStateToProps), withRouter])(NewPasswordContainer);
