import { FormProps } from '@rjsf/core';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';
import {
  AdminCmsInstructorIdReferenceWidget,
  AdminCmsPollIdReferenceWidget,
  AdminCmsRasterMediaImageIdReferenceWidget,
  AdminCmsVectorMediaImageIdReferenceWidget,
} from '../shared/AdminCmsReferenceIdWidget';
import { AdminCmsRichTextFieldWidget } from '../shared/AdminCmsRichTextFieldWidget';
import { AdminWorkoutCmsFormData } from './types';

const baseFormScreenOrSheetFormSchemaObjectProperties = {
  questions: {
    title: 'Questions',
    type: formSchemaEntryTypes.array,
    items: {
      type: formSchemaEntryTypes.object,
      required: ['kind', 'label'],
      properties: {
        kind: {
          title: 'Question Kind',
          type: formSchemaEntryTypes.string,
          anyOf: [
            {
              type: formSchemaEntryTypes.string,
              title: 'Long Text',
              enum: ['long_text'],
            },
          ],
          // For now we default to Long Text because we're not supporting other
          // question types in the CMS.
          default: 'long_text',
        },
        id: {
          title: 'ID',
          type: formSchemaEntryTypes.string,
        },
        label: {
          title: 'Label',
          type: formSchemaEntryTypes.string,
        },
      },
      dependencies: {
        kind: {
          oneOf: [
            {
              properties: {
                kind: {
                  enum: ['long_text'],
                },
                placeholder: {
                  title: 'Input Placeholder Text',
                  type: formSchemaEntryTypes.string,
                },
              },
            },
          ],
        },
      },
    },
  },
};

export const formSchema: FormProps<AdminWorkoutCmsFormData>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: [
    'title',
    'adminTitle',
    'description',
    'instructorId',
    'emotionalTrait',
    'estimatedDuration',
    'illustrationMediaImageId',
    'coverPhotoMediaImageId',
  ],
  properties: {
    title: {
      title: 'Public Title (External, user-facing)',
      type: formSchemaEntryTypes.string,
    },
    adminTitle: {
      title: 'Admin Title (Internal)',
      type: formSchemaEntryTypes.string,
    },
    description: {
      title: 'Description',
      type: formSchemaEntryTypes.string,
    },
    instructorId: {
      title: 'Instructor',
      type: formSchemaEntryTypes.string,
    },
    estimatedDuration: {
      title: 'Estimated Duration (in minutes)',
      type: formSchemaEntryTypes.number,
      minimum: 1,
    },
    emotionalTrait: {
      title: 'Trait',
      type: formSchemaEntryTypes.string,
      anyOf: [
        {
          type: formSchemaEntryTypes.string,
          title: 'Self-Awareness',
          enum: ['self_awareness'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Empathy',
          enum: ['empathy'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Mindfulness',
          enum: ['mindfulness'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Curiosity',
          enum: ['curiosity'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Play',
          enum: ['play'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Communication',
          enum: ['communication'],
        },
        {
          type: formSchemaEntryTypes.string,
          title: 'Resilience',
          enum: ['resilience'],
        },
      ],
    },
    illustrationMediaImageId: {
      title: 'Illustration',
      type: formSchemaEntryTypes.string,
    },
    coverPhotoMediaImageId: {
      title: 'Cover Photo',
      type: formSchemaEntryTypes.string,
    },
    screens: {
      title: 'Screens',
      type: formSchemaEntryTypes.array,
      items: {
        type: formSchemaEntryTypes.object,
        required: ['kind'],
        properties: {
          kind: {
            title: 'Screen Kind',
            type: formSchemaEntryTypes.string,
            anyOf: [
              {
                type: formSchemaEntryTypes.string,
                title: 'Base Form Screen',
                enum: ['base_form_screen'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Instructor Cover',
                enum: ['instructor_cover'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Full Screen Video',
                enum: ['full_screen_video'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Poll Entry',
                enum: ['poll_entry'],
              },
              {
                type: formSchemaEntryTypes.string,

                title: 'Poll Results',
                enum: ['poll_results'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Rich Text',
                enum: ['rich_text'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Complete Rating',
                enum: ['complete_rating'],
              },
              {
                type: formSchemaEntryTypes.string,
                title: 'Copy Prompt',
                enum: ['copy_prompt'],
              },
            ],
          },
          id: {
            title: 'ID',
            type: formSchemaEntryTypes.string,
          },
          hasSheet: {
            title: 'Include sheet?',
            type: formSchemaEntryTypes.boolean,
          },
        },
        dependencies: {
          kind: {
            oneOf: [
              {
                properties: {
                  kind: {
                    enum: ['full_screen_video'],
                  },
                  videoUrl: {
                    title: 'Video URL',
                    type: formSchemaEntryTypes.string,
                  },
                },
                required: ['videoUrl'],
              },
              {
                properties: {
                  kind: {
                    enum: ['base_form_screen'],
                  },
                  ...baseFormScreenOrSheetFormSchemaObjectProperties,
                },
              },
              {
                properties: {
                  kind: {
                    enum: ['instructor_cover'],
                  },
                  backgroundMediaImageId: {
                    title: 'Background Image',
                    type: formSchemaEntryTypes.string,
                  },
                },
                required: ['backgroundMediaImageId'],
              },
              {
                properties: {
                  kind: {
                    enum: ['poll_entry', 'poll_results'],
                  },
                  pollId: {
                    title: 'Poll',
                    type: formSchemaEntryTypes.string,
                  },
                },
                required: ['pollId'],
              },
              {
                properties: {
                  kind: {
                    enum: ['rich_text'],
                  },
                  richTextContent: {
                    title: 'Rich Text Content',
                    type: formSchemaEntryTypes.string,
                    default: '',
                  },
                },
                required: ['richTextContent'],
              },
              {
                properties: {
                  kind: {
                    enum: ['complete_rating'],
                  },
                },
              },
              {
                properties: {
                  kind: {
                    enum: ['copy_prompt'],
                  },
                  label: {
                    title: 'Label',
                    type: formSchemaEntryTypes.string,
                  },
                  textToCopy: {
                    title: 'Text to Copy',
                    type: formSchemaEntryTypes.string,
                  },
                },
                required: ['label', 'textToCopy'],
              },
            ],
          },
          hasSheet: {
            oneOf: [
              {
                properties: {
                  /*
                   * Note that these dependency configs _should_ just work by
                   * setting `hasSheet: true` (or `false`), but they do not
                   * operate bi-directionally without the setting as an enum
                   * value. :shrug:
                   */
                  hasSheet: {
                    enum: [true],
                  },
                  sheet: {
                    title: 'Screen Sheet',
                    type: formSchemaEntryTypes.object,
                    properties: {
                      kind: {
                        title: 'Sheet Kind',
                        type: formSchemaEntryTypes.string,
                        anyOf: [
                          {
                            type: formSchemaEntryTypes.string,
                            title: 'Base Form Sheet',
                            enum: ['base_form_sheet'],
                          },
                          {
                            type: formSchemaEntryTypes.string,
                            title: 'Rich Text Sheet',
                            enum: ['rich_text_sheet'],
                          },
                        ],
                      },
                    },
                    dependencies: {
                      kind: {
                        oneOf: [
                          {
                            properties: {
                              kind: {
                                enum: ['base_form_sheet'],
                              },
                              ...baseFormScreenOrSheetFormSchemaObjectProperties,
                            },
                          },
                          {
                            properties: {
                              kind: {
                                enum: ['rich_text_sheet'],
                              },
                              richTextContent: {
                                title: 'Rich Text Content',
                                type: formSchemaEntryTypes.string,
                                default: '',
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              },
              {
                properties: {
                  hasSheet: {
                    enum: [false],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
};

export const formUiSchema = {
  title: {
    'ui:placeholder': 'Overcoming Adversity',
  },
  adminTitle: {
    'ui:placeholder': '',
  },
  instructorId: {
    'ui:widget': AdminCmsInstructorIdReferenceWidget,
  },
  description: {
    'ui:widget': 'textarea',
  },
  illustrationMediaImageId: {
    'ui:widget': AdminCmsVectorMediaImageIdReferenceWidget,
  },
  coverPhotoMediaImageId: {
    'ui:widget': AdminCmsRasterMediaImageIdReferenceWidget,
  },
  screens: {
    items: {
      id: {
        'ui:widget': 'hidden',
      },
      pollId: {
        'ui:widget': AdminCmsPollIdReferenceWidget,
      },
      richTextContent: {
        'ui:widget': AdminCmsRichTextFieldWidget,
      },
      backgroundMediaImageId: {
        'ui:widget': AdminCmsRasterMediaImageIdReferenceWidget,
      },
      questions: {
        items: {
          id: { 'ui:widget': 'hidden' },
        },
      },
      sheet: {
        richTextContent: {
          'ui:widget': AdminCmsRichTextFieldWidget,
        },
        questions: {
          items: {
            id: {
              'ui:widget': 'hidden',
            },
          },
        },
      },
    },
  },
};
