import _ from 'lodash';
import { OnDemandVideo } from '../../../resources/onDemandVideos';

const bgColors = [
  'brand.sand',
  'gray.600',
  'gray.700',
  'evergreen.100',
  'evergreen.200',
  'evergreen.300',
  'evergreen.400',
];

/*
 * This is a wild solution in order to give some persistent background
 * colors to cards in the BrowseByTopic section. This will likely change
 * in the near future when we have a better design direction.
 */

export const getStableOnDemandVideoBgColor = _.memoize(
  (title: OnDemandVideo['title']) => bgColors[title.length % bgColors.length]
);
