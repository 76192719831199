import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ClassCard } from '../../../../components/molecules/ClassCard';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import {
  createGetSeriesWorkshopsPlaceholderData,
  formatWorkshopTimeLabel,
  useSeriesWorkshopsQuery,
} from '../../../../resources/workshops';

export const UpcomingSeriesCardView = () => {
  const numberOfResults = 4;
  const seriesPlaceholderData = createGetSeriesWorkshopsPlaceholderData({
    length: numberOfResults,
  });
  const seriesQuery = useSeriesWorkshopsQuery({}, { placeholderData: seriesPlaceholderData });
  const { data: series } = seriesQuery;

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isLoading =
    seriesQuery.isLoading || seriesQuery.isPlaceholderData || getSubscriptionsQuery.isLoading;

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      columnGap={{ base: 4, md: 6, lg: 8 }}
      rowGap={{ base: 4, md: 6, lg: 8 }}
    >
      {series.slice(0, numberOfResults).map((individualSeries) => (
        <GridItem colSpan={{ base: 6, lg: 3 }} key={individualSeries.id}>
          <Skeleton isLoaded={!isLoading} height="100%" borderRadius="lg" fadeDuration={0}>
            <TransformUpLink
              as={RouterLink}
              to={`/classes/${individualSeries.id}`}
              height="100%"
              width="100%"
              data-cy="upcoming-series-class-listing"
            >
              <ClassCard
                marketListingLabel={individualSeries.marketListingLabel}
                title={individualSeries.title}
                timingLabel={formatWorkshopTimeLabel(
                  individualSeries,
                  individualSeries.workshopOccurrences
                )}
                instructorName={individualSeries.therapist.name}
                src={individualSeries.imageUrl}
                kind={individualSeries.kind}
                numberOfClasses={individualSeries.workshopOccurrences.length}
                showClassLabel={!hasActiveSubscription}
                workshopAccess={individualSeries.access}
              />
            </TransformUpLink>
          </Skeleton>
        </GridItem>
      ))}
    </Grid>
  );
};
