// @flow
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Checkbox,
  Heading,
  IconButton,
  Image,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { titleCase } from '@coa/stdlib/string';
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { createSelector } from 'reselect';
import * as Yup from 'yup';
import * as exerciseActions from '../../actions/admin/exercises';
import * as therapistActions from '../../actions/admin/therapists';
import * as workshopActions from '../../actions/admin/workshops';
import * as workshopTemplateActions from '../../actions/admin/workshopTemplates';
import { workshopDetails } from '../../actions/workshops';
import { useGetHubspotTemplatesQuery } from '../../resources/hubspotTemplates';
import { workshopSkills, workshopTopicLabels } from '../../resources/workshops';
import { TrashIcon } from '../Icons';
import Loading from '../Loading';

export const WORKSHOP_FORM_CREATE = 'create';
export const WORKSHOP_FORM_UPDATE = 'update';

export const equalIds = (one, other) => one?.id === other?.id;

const sortedTherapistsSelector = createSelector(
  (state) => state.admin.therapists.therapists,
  (therapists) => _.chain(therapists).values().sortBy('attributes.name').value()
);

const sortedWorkshopTemplatesSelector = createSelector(
  (state) => state.admin.workshopTemplates.workshopTemplates,
  (workshopTemplates) => _.chain(workshopTemplates).values().sortBy('attributes.title').value()
);

const WorkshopSchema = Yup.object().shape({
  kind: Yup.mixed().oneOf(['series', 'q_and_a', 'advanced_series', 'discussion_group', 'intro']),
  category: Yup.mixed().oneOf([
    'leadership_series',
    'advanced_leadership_series',
    'emotional_fitness_intro',
    'leadership_intro',
    'emotional_fitness_series',
    'female_leadership_series',
    'bipoc_leadership_series',
    'founder_leadership_series',
    'job_change_series',
    'setting_boundaries_series',
    'product_manager_leadership_series',
    'romantic_relationships_series',
    'managing_burnout_series',
    'confidence_imposter_syndrome_series',
    'facing_self_criticism_series',
    'push_up',
    'question_and_answer',
  ]),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  min_participants: Yup.number()
    .required('Min participants is required')
    .integer('Min participants must be an integer')
    .min(0, 'Min participants can’t be negative'),
  max_participants: Yup.number()
    .required('Max participants is required')
    .integer('Max participants must be an integer')
    .min(0, 'Max participants can’t be negative')
    .min(Yup.ref('min_participants'), 'Max participants can’t be less than min participants'),
  join_url: Yup.string().url(),
  price: Yup.number().required('Price is required').min(0, 'Price can’t be negative'),
  therapist: Yup.mixed().required('Therapist is required'),
});

const EMPTY_OCCURRENCE = {
  title: '',
  description: '',
  starts_at: '',
  duration_in_minutes: 60,
  skill: null,
};

const WorkshopForm = ({ mode = WORKSHOP_FORM_CREATE }) => {
  const history = useHistory();
  const { id: workshopId } = useParams();

  const dispatch = useDispatch();
  const therapists = useSelector(sortedTherapistsSelector);
  const workshopTemplates = useSelector(sortedWorkshopTemplatesSelector);
  const workshop = useSelector((state) => state.admin.workshops.workshops[workshopId]);
  const workshopOccurrences = useSelector(
    (state) => state.admin.workshops.workshopOccurrences[workshopId]
  );
  const { exercises } = useSelector((state) => state.admin.exercises);

  const imageInputRef = useRef(null);
  const [imagePreviewData, setImagePreviewData] = useState(null);

  const getHubspotTemplatesQuery = useGetHubspotTemplatesQuery();

  const [copying, setCopying] = useState(false);

  const toast = useToast();

  const {
    isOpen: isConfirmSoldOutDialogOpen,
    onOpen: onConfirmSoldOutDialogOpen,
    onClose: onConfirmSoldOutDialogClose,
  } = useDisclosure();

  useEffect(() => {
    // Fetch workshops if we don't have any and we're expecting to update one
    if (mode === WORKSHOP_FORM_UPDATE && !workshop) {
      dispatch(workshopDetails(workshopId));
    }
  }, [dispatch, workshopId, mode, workshop]);

  useEffect(() => {
    // Refresh therapists and templates lists
    dispatch(therapistActions.list());
    dispatch(workshopTemplateActions.list());
    dispatch(exerciseActions.list());
    if (workshopId) dispatch(workshopActions.listOccurrences(workshopId));
  }, [dispatch, workshopId]);

  const workshopAttributes = workshop?.attributes || {};
  const {
    kind = 'series',
    category = 'emotional_fitness_series',
    status = 'visible',
    access = 'members',
    featured = false,
    is_b2b = false,
    sold_out = false,
    auto_approve = false,
    title = '',
    description = '',
    short_blurb = '',
    min_participants = '',
    max_participants = '',
    price_cents = 0,
    join_url = '',
    series_timing_label = '',
    market_listing_label = '',
    topic = '',
    welcome_email_template_id = null,
  } = workshopAttributes;

  const price = price_cents / 100.0;

  const initialFormValues = useMemo(
    () => ({
      kind,
      category,
      status,
      access,
      featured,
      is_b2b,
      sold_out,
      auto_approve,
      title,
      description,
      short_blurb,
      min_participants,
      max_participants,
      price,
      join_url,
      series_timing_label,
      market_listing_label,
      welcome_email_template_id,
      topic,
      workshop_occurrences: workshopOccurrences
        ? workshopOccurrences.map((o) => {
            const attrs = { id: o.id, ...o.attributes };
            if (attrs.starts_at) {
              const utcDate = new Date(`${attrs.starts_at.replace('T', ' ')} UTC`);
              const offset = utcDate.getTimezoneOffset();
              utcDate.setMinutes(utcDate.getMinutes() - offset);
              attrs.starts_at = utcDate.toISOString().replace('Z', '');
            }
            return attrs;
          })
        : [EMPTY_OCCURRENCE],
      therapist:
        therapists?.find((t) => t.id === workshop?.relationships?.therapist?.data?.id) || null,
      workshop_template:
        workshopTemplates?.find(
          (t) => t.id === workshop?.relationships?.workshop_template?.data?.id
        ) || null,
    }),
    [
      workshop,
      therapists,
      workshopTemplates,
      workshopOccurrences,
      description,
      kind,
      status,
      access,
      featured,
      is_b2b,
      sold_out,
      auto_approve,
      join_url,
      max_participants,
      min_participants,
      price,
      series_timing_label,
      market_listing_label,
      welcome_email_template_id,
      topic,
      short_blurb,
      title,
    ]
  );

  if (
    mode === WORKSHOP_FORM_UPDATE &&
    (!workshop || !workshopOccurrences || _.isEmpty(therapists) || _.isEmpty(workshopTemplates))
  ) {
    // We don't have enough data to populate the Formik form for update
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  return (
    <Container maxWidth="sm">
      <Heading my={5}>{mode === WORKSHOP_FORM_UPDATE ? 'Edit a Class' : 'Add a New Class'}</Heading>
      <Formik
        initialValues={initialFormValues}
        validationSchema={WorkshopSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const updating = workshop && !copying;
          const action = updating
            ? workshopActions.update(workshop.id, values)
            : workshopActions.create(values);
          dispatch(action)
            .then((result) => {
              if (result.errors) {
                setErrors(result.errors);
                toast({
                  title: 'Failed to save. See errors above.',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              } else {
                toast({
                  title: updating ? 'Workshop updated!' : 'Workshop created!',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                history.push('/admin-dash/classes');
              }
            })
            .catch((error) => alert(JSON.stringify(error)))
            .finally(() => setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form>
            <Grid container direction="column" alignItems="stretch" spacing={2} width="auto">
              <Grid item>
                <Field
                  component={TextField}
                  label="Title"
                  id="title"
                  name="title"
                  data-cy="field-workshop-title"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={Autocomplete}
                  name="workshop_template"
                  id="workshop_template"
                  options={workshopTemplates}
                  getOptionLabel={(template) => template.attributes?.title}
                  getOptionSelected={equalIds}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      error={
                        formikProps.touched.workshop_template &&
                        !!formikProps.errors.workshop_template
                      }
                      helperText={formikProps.errors.workshop_template}
                      label="Template"
                      placeholder="<create new>"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Field
                  label="Kind"
                  component={TextField}
                  id="kind"
                  name="kind"
                  data-cy="field-workshop-kind"
                  variant="outlined"
                  select
                  inputProps={{
                    id: 'kind',
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="intro">Intro</MenuItem>
                  <MenuItem value="series">Series</MenuItem>
                  <MenuItem value="q_and_a">Q&A</MenuItem>
                  <MenuItem value="advanced_series">Advanced Series</MenuItem>
                  <MenuItem value="discussion_group">Discussion Group</MenuItem>
                </Field>
              </Grid>
              <Grid item>
                <Field
                  label="Category"
                  component={TextField}
                  id="category"
                  name="category"
                  data-cy="field-workshop-category"
                  variant="outlined"
                  select
                  inputProps={{
                    id: 'category',
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="leadership_series">Leadership Series</MenuItem>
                  <MenuItem value="advanced_leadership_series">Advanced Leadership Series</MenuItem>
                  <MenuItem value="emotional_fitness_intro">Emotional Fitness Intro</MenuItem>
                  <MenuItem value="leadership_intro">Leadership Intro</MenuItem>
                  <MenuItem value="emotional_fitness_series">Emotional Fitness Series</MenuItem>
                  <MenuItem value="female_leadership_series">Female Leadership Series</MenuItem>
                  <MenuItem value="bipoc_leadership_series">BIPOC Leadership Series</MenuItem>
                  <MenuItem value="founder_leadership_series">Founder Leadership Series</MenuItem>
                  <MenuItem value="job_change_series">Job Change Series</MenuItem>
                  <MenuItem value="confidence_imposter_syndrome_series">
                    Confidence & Imposter Syndrome Series
                  </MenuItem>
                  <MenuItem value="facing_self_criticism_series">
                    Facing Self-Criticism Series
                  </MenuItem>
                  <MenuItem value="managing_burnout_series">Managing Burnout Series</MenuItem>
                  <MenuItem value="product_manager_leadership_series">
                    Product Manager Leadership Series
                  </MenuItem>
                  <MenuItem value="romantic_relationships_series">
                    Romantic Relationships Series
                  </MenuItem>
                  <MenuItem value="setting_boundaries_series">Setting Boundaries Series</MenuItem>

                  <MenuItem value="push_up">Push Up</MenuItem>
                  <MenuItem value="question_and_answer">Q&A</MenuItem>
                </Field>
              </Grid>
              {formikProps.values.kind.includes('series') && (
                <Grid item>
                  <Heading fontSize="xl">Series</Heading>
                </Grid>
              )}
              <Grid item>
                <Field
                  component={TextField}
                  label="Timing Description (e.g 'Tuesdays')"
                  id="series_timing_label"
                  name="series_timing_label"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <FieldArray
                name="workshop_occurrences"
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {formikProps.values.workshop_occurrences.length === 0 && (
                      <Button
                        colorScheme="evergreen"
                        m={2}
                        onClick={() => arrayHelpers.push(EMPTY_OCCURRENCE)}
                      >
                        Add Instance of Series
                      </Button>
                    )}
                    {formikProps.values.workshop_occurrences.map((occurrence, index) => (
                      <React.Fragment key={index}>
                        {formikProps.values.kind.includes('series') ? (
                          <>
                            <Grid item>
                              <Heading fontSize="md">Class #{index + 1}</Heading>
                            </Grid>
                            <Grid item>
                              <Field
                                label="Title"
                                component={TextField}
                                variant="outlined"
                                name={`workshop_occurrences.${index}.title`}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <Field
                                label="Description"
                                component={TextField}
                                variant="outlined"
                                name={`workshop_occurrences.${index}.description`}
                                fullWidth
                                multiline
                                rows={4}
                              />
                            </Grid>
                          </>
                        ) : null}
                        <Grid item>
                          <Field
                            component={TextField}
                            type="datetime-local"
                            data-cy={`field-occ-${index}-starts-at`}
                            variant="outlined"
                            name={`workshop_occurrences.${index}.starts_at`}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            label="Duration (in minutes)"
                            component={TextField}
                            variant="outlined"
                            name={`workshop_occurrences.${index}.duration_in_minutes`}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <Field
                            label="Exercise"
                            component={TextField}
                            name={`workshop_occurrences.${index}.exercise_id`}
                            variant="outlined"
                            select
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value={null}>No Exercise</MenuItem>
                            {exercises.map((exercise) => (
                              <MenuItem value={exercise.id}>
                                {exercise.attributes.admin_title}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item>
                          <Field
                            label="Skill"
                            component={TextField}
                            name={`workshop_occurrences.${index}.skill`}
                            variant="outlined"
                            select
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value={null}>No Skill</MenuItem>
                            {Object.values(workshopSkills).map((skill) => (
                              <MenuItem key={skill} value={skill}>
                                {titleCase(skill)}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        {!getHubspotTemplatesQuery.isLoading && (
                          <Grid item>
                            <Field
                              label="Reminder Email"
                              component={TextField}
                              name={`workshop_occurrences.${index}.reminder_email_template_id`}
                              variant="outlined"
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <MenuItem value={null}>No Reminder Email</MenuItem>
                              {getHubspotTemplatesQuery.data.map((template) => (
                                <MenuItem
                                  key={template.hubspotEmailId}
                                  value={template.hubspotEmailId}
                                >
                                  {template.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        )}
                        {!getHubspotTemplatesQuery.isLoading && (
                          <Grid item>
                            <Field
                              label="Follow-Up Email"
                              component={TextField}
                              name={`workshop_occurrences.${index}.follow_up_email_template_id`}
                              variant="outlined"
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <MenuItem value={null}>No Follow-Up Email</MenuItem>
                              {getHubspotTemplatesQuery.data.map((template) => (
                                <MenuItem
                                  key={template.hubspotEmailId}
                                  value={template.hubspotEmailId}
                                >
                                  {template.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        )}
                        <Grid item>
                          <Field
                            component={TextField}
                            label="Feedback Survey URL"
                            name={`workshop_occurrences.${index}.customer_feedback_survey_url`}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        {formikProps.values.kind.includes('series') && (
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <IconButton
                              colorScheme="orange"
                              m={2}
                              onClick={() => arrayHelpers.remove(index)}
                              aria-label="Delete Instance of Series"
                              icon={<TrashIcon />}
                            />
                            {index === formikProps.values.workshop_occurrences.length - 1 && (
                              <Button
                                colorScheme="evergreen"
                                m={2}
                                onClick={() => arrayHelpers.push(EMPTY_OCCURRENCE)}
                              >
                                + Add Instance of Series
                              </Button>
                            )}
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              />
              <Grid item>
                <Field
                  component={Autocomplete}
                  name="therapist"
                  id="therapist"
                  data-cy="field-workshop-therapist"
                  options={therapists}
                  getOptionLabel={(therapist) => therapist.attributes?.name}
                  getOptionSelected={equalIds}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      error={formikProps.touched.therapist && !!formikProps.errors.therapist}
                      helperText={formikProps.errors.therapist}
                      label="Therapist"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Description"
                  id="description"
                  name="description"
                  data-cy="field-workshop-description"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Short Blurb (for list view)"
                  id="short_blurb"
                  name="short_blurb"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Listing Label (for list view)"
                  id="market_listing_label"
                  name="market_listing_label"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  label="Topic (for filtering)"
                  component={TextField}
                  id="topic"
                  name="topic"
                  variant="outlined"
                  select
                  inputProps={{
                    id: 'topic',
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value={null}>No Topic</MenuItem>
                  {Object.keys(workshopTopicLabels).map((key) => (
                    <MenuItem key={key} value={key}>
                      {workshopTopicLabels[key]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              {!getHubspotTemplatesQuery.isLoading && (
                <Grid item>
                  <Field
                    label="Welcome Email"
                    component={TextField}
                    id="welcome_email_template_id"
                    name="welcome_email_template_id"
                    variant="outlined"
                    select
                    inputProps={{
                      id: 'welcome_email_template_id',
                    }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value={null}>No Welcome Email</MenuItem>
                    {getHubspotTemplatesQuery.data.map((template) => (
                      <MenuItem key={template.hubspotEmailId} value={template.hubspotEmailId}>
                        {template.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              )}

              <Grid item>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <input
                  type="file"
                  ref={imageInputRef}
                  accept="image/*"
                  hidden
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0];
                    if (file) {
                      formikProps.setFieldValue('image', file);
                      const reader = new FileReader();
                      reader.onload = (e) => setImagePreviewData(e.target.result);
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <Image
                  onClick={() => imageInputRef.current?.click()}
                  maxH="100px"
                  minH="100px"
                  minW="100px"
                  objectFit="contain"
                  cursor="pointer"
                  src={
                    imagePreviewData ||
                    workshop?.attributes?.image_url ||
                    /*
                     * This image isn't loading in E2E tests and the entire
                     * test hangs on it, so we disable.
                     */
                    (window.Cypress
                      ? undefined
                      : 'https://ipsumimage.appspot.com/150x100?l=Upload+image...')
                  }
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Min. participants"
                  id="min_participants"
                  name="min_participants"
                  data-cy="field-workshop-min-participants"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Max. participants"
                  id="max_participants"
                  name="max_participants"
                  data-cy="field-workshop-max-participants"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Join URL"
                  id="join_url"
                  name="join_url"
                  data-cy="field-workshop-join-url"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  label="Price"
                  id="price"
                  name="price"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  label="Status"
                  component={TextField}
                  id="status"
                  name="status"
                  variant="outlined"
                  select
                  inputProps={{
                    id: 'status',
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="visible">Visible</MenuItem>
                  <MenuItem value="exclusive">Exclusive</MenuItem>
                  <MenuItem value="hidden">Hidden</MenuItem>
                  <MenuItem value="archived">Archived</MenuItem>
                </Field>
                <Box mt={2}>
                  <Field
                    label="Access"
                    component={TextField}
                    id="access"
                    name="access"
                    variant="outlined"
                    select
                    inputProps={{
                      id: 'access',
                    }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="everyone">Everyone</MenuItem>
                    <MenuItem value="members">Members Only</MenuItem>
                  </Field>
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Field
                        id="featured"
                        name="featured"
                        component={Switch}
                        type="checkbox"
                        color="primary"
                      />
                    }
                    label="Featured Class"
                    labelPlacement="start"
                  />
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Field
                        id="is_b2b"
                        name="is_b2b"
                        component={Switch}
                        type="checkbox"
                        color="primary"
                      />
                    }
                    label="Is B2B"
                    labelPlacement="start"
                  />
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Field
                        id="auto_approve"
                        name="auto_approve"
                        component={Switch}
                        type="checkbox"
                        color="primary"
                      />
                    }
                    label="Auto-approve submitted applications"
                    labelPlacement="start"
                  />
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Field
                        id="sold_out"
                        name="sold_out"
                        component={Switch}
                        type="checkbox"
                        color="primary"
                        onChange={(event) => {
                          const { checked } = event.target;
                          if (checked) {
                            onConfirmSoldOutDialogOpen();
                          } else {
                            formikProps.setFieldValue('sold_out', event.target.checked);
                          }
                        }}
                      />
                    }
                    label="Sold Out"
                    labelPlacement="start"
                  />
                </Box>
                <AlertDialog
                  isOpen={isConfirmSoldOutDialogOpen}
                  onClose={onConfirmSoldOutDialogClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader>Mark as Sold Out</AlertDialogHeader>
                      <AlertDialogBody>
                        Are you sure you wish to mark this Class as sold out? Members will no longer
                        be able to join this Class.
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button
                          onClick={() => {
                            formikProps.setFieldValue('sold_out', false);
                            onConfirmSoldOutDialogClose();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            formikProps.setFieldValue('sold_out', true);
                            onConfirmSoldOutDialogClose();
                          }}
                          ml={2}
                        >
                          Continue
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Grid>
              {mode === WORKSHOP_FORM_UPDATE && (
                <Grid item>
                  <Checkbox isChecked={copying} onChange={(e) => setCopying(e.target.checked)}>
                    Copy as New Class
                  </Checkbox>
                </Grid>
              )}
              <Button
                type="submit"
                data-cy="submit"
                name="create"
                m={2}
                width={{ base: 'auto', md: '20%' }}
                isLoading={formikProps.isSubmitting}
                colorScheme="evergreen"
              >
                {mode === WORKSHOP_FORM_CREATE || copying ? 'Create' : 'Update'}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default WorkshopForm;
