export const DISCOUNTS_LIST_REQUEST = 'DISCOUNTS_LIST_REQUEST';
export const DISCOUNTS_LIST_SUCCESS = 'DISCOUNTS_LIST_SUCCESS';
export const DISCOUNTS_LIST_FAILURE = 'DISCOUNTS_LIST_FAILURE';

export function list() {
  return {
    type: DISCOUNTS_LIST_REQUEST,
    api: {
      endpoint: `discounts`,
      authenticated: true,
      admin: true,
      types: [DISCOUNTS_LIST_REQUEST, DISCOUNTS_LIST_SUCCESS, DISCOUNTS_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export const DISCOUNTS_CREATE_REQUEST = 'DISCOUNTS_CREATE_REQUEST';
export const DISCOUNTS_CREATE_SUCCESS = 'DISCOUNTS_CREATE_SUCCESS';
export const DISCOUNTS_CREATE_FAILURE = 'DISCOUNTS_CREATE_FAILURE';

export function create({ code, kind, discount }) {
  return {
    type: DISCOUNTS_CREATE_REQUEST,
    api: {
      data: { code, kind, discount_ratio: discount / 100 },
      endpoint: `discounts`,
      authenticated: true,
      admin: true,
      types: [DISCOUNTS_CREATE_REQUEST, DISCOUNTS_CREATE_SUCCESS, DISCOUNTS_CREATE_FAILURE],
      method: 'POST',
    },
  };
}

export const DISCOUNTS_DESTROY_REQUEST = 'DISCOUNTS_DESTROY_REQUEST';
export const DISCOUNTS_DESTROY_SUCCESS = 'DISCOUNTS_DESTROY_SUCCESS';
export const DISCOUNTS_DESTROY_FAILURE = 'DISCOUNTS_DESTROY_FAILURE';

export function destroy(id) {
  return {
    type: DISCOUNTS_DESTROY_REQUEST,
    api: {
      endpoint: `discounts/${id}`,
      authenticated: true,
      admin: true,
      types: [DISCOUNTS_DESTROY_REQUEST, DISCOUNTS_DESTROY_SUCCESS, DISCOUNTS_DESTROY_FAILURE],
      method: 'DELETE',
    },
  };
}
