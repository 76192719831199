import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Workshop, WorkshopOccurrence } from '../../../resources/workshops';
import {
  classesRouterPaths,
  getUniversalClassesRouterUrl,
} from '../../../routerPaths/classesRouterPaths';
import { SubscribeModal } from './components/SubscribeModal';
import { SubscribeSuccessState } from './components/SubscribeModalSuccessState';

export const WorkshopSubscribeModal = ({
  id,
  dropInWorkshopOccurrenceId,
}: {
  id: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
}) => {
  const backRouterUrl = getUniversalClassesRouterUrl.details({
    id,
    dropInWorkshopOccurrenceId,
  });
  const location = useLocation();
  const isOpen = Boolean(
    matchPath(location.pathname, {
      path: [classesRouterPaths.classSubscribe, classesRouterPaths.dropInSubscribe],
      exact: true,
    })
  );

  return (
    <SubscribeModal
      backRouterUrl={backRouterUrl}
      isOpen={isOpen}
      renderSubscribeSuccessState={() => (
        <SubscribeSuccessState id={id} dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId} />
      )}
    />
  );
};
