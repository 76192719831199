import _ from 'lodash';
import * as Yup from 'yup';

export const addTeamMembersFormValidationSchema = Yup.object()
  .shape({
    members: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().when('name', {
            // Note use of _.isEmpty here as either Formik or Yup is casting
            // empty-string to undefined.
            is: _.isEmpty,
            then: Yup.string().oneOf(['']),
            otherwise: Yup.string().email().required(),
          }),
        })
      )
      .min(1),
  })
  .test(
    'has-at-least-one',
    // eslint-disable-next-line no-template-curly-in-string
    'Please add at least one email / name combination.',
    ({ members = [] }) => {
      if (members.length > 1) return true;
      const { name = '', email = '' } = members[0] || {};
      return ![name, email].includes('');
    }
  );
