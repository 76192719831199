import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sessionLocalStorage } from '../lib/localStorage/sessionLocalStorage';
import { DefaultRoute } from '../utils/DefaultRoute';

class Redirector extends Component {
  componentDidMount() {
    const { history } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    history.push(DefaultRoute.for(currentMember));
  }

  render() {
    return <h1>Loading...</h1>;
  }
}

Redirector.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Redirector);
