import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TeamMembershipCreateTeamForm } from './components/forms/TeamMembershipCreateTeamForm';
import { TeamMembershipPaymentForm } from './components/forms/TeamMembershipPaymentForm';
import { TeamMembershipRegistrationForm } from './components/forms/TeamMembershipRegistrationForm';

export const TeamMembershipView = () => (
  <Switch>
    <Route path="/team-membership/registration" component={TeamMembershipRegistrationForm} />
    <Route path="/team-membership/create-team" component={TeamMembershipCreateTeamForm} />
    <Route path="/team-membership/:id/payment" component={TeamMembershipPaymentForm} />
  </Switch>
);
