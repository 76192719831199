import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbProps } from '@chakra-ui/react';
import { RouterLink, useRouteParams } from '@coa/react-utils';
import { titleCase } from '@coa/stdlib/string';
import { camelCase } from 'lodash';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { ChevronRightIcon } from '../../components/Icons';
import { useGetWorkshopQuery, WorkshopOccurrence } from '../../resources/workshops';

type WorkshopsBreadcrumbProps = {
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
};

const workshopKindToBrowseRoute = {
  intro: '/classes/intros',
  series: '/classes/series',
  dropIn: '/classes/drop-ins',
  qAndA: '/classes/q-and-a',
};

export const WorkshopsBreadcrumb = ({
  dropInWorkshopOccurrenceId,
  ...props
}: WorkshopsBreadcrumbProps & BreadcrumbProps) => {
  const { id: workshopId } = useRouteParams<{ id?: string; dropInWorkshopOccurrenceId?: string }>();
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: workshopId },
    { enabled: Boolean(workshopId) }
  );

  const classesHubRouteMatch = useRouteMatch({ path: '/classes', exact: true });
  const classesBrowseRouteMatch = useRouteMatch({
    path: '/classes/(intros|series|drop-ins|q-and-a)',
  });
  const classDetailsRouteMatch = useRouteMatch({ path: '/classes/:id' });

  if (classesHubRouteMatch) return null;

  /*
   * While loading, return an empty breadcrumb to preserve spacing
   */
  if (getWorkshopQuery.isLoading) {
    return (
      <Breadcrumb opacity={0} {...props}>
        <BreadcrumbItem>
          <BreadcrumbLink>Placeholder Breadcrumb</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  const { data: workshop } = getWorkshopQuery;

  return (
    <Breadcrumb
      separator={
        <ChevronRightIcon
          // Offset to adjust bad spacing - eyeballed.
          marginTop="-2px"
        />
      }
      alignItems="start"
      {...props}
    >
      {!classesHubRouteMatch ? (
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/classes">
            Classes Home
          </BreadcrumbLink>
        </BreadcrumbItem>
      ) : null}
      {classDetailsRouteMatch && !classesBrowseRouteMatch ? (
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={
              dropInWorkshopOccurrenceId
                ? workshopKindToBrowseRoute.dropIn
                : workshopKindToBrowseRoute[camelCase(workshop.kind)]
            }
          >
            All{' '}
            {dropInWorkshopOccurrenceId
              ? 'Drop-In'
              : workshop.kind === 'q_and_a'
              ? 'Q&A'
              : titleCase(workshop.kind)}{' '}
            Classes
          </BreadcrumbLink>
        </BreadcrumbItem>
      ) : null}
    </Breadcrumb>
  );
};
