export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENTS_FAILURE = 'CLIENTS_FAILURE';

export function list(therapistId) {
  return {
    type: CLIENTS_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/clients`,
      authenticated: true,
      types: [CLIENTS_REQUEST, CLIENTS_SUCCESS, CLIENTS_FAILURE],
      method: 'GET',
    },
  };
}
