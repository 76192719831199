import { Box, Center, Heading, Link, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { InvoiceIcon } from './Icons';

const OAUTH_BASE = 'https://connect.stripe.com/express/oauth/authorize';

const { BASE_URL, STRIPE_CLIENT_ID } = window;

function SetupBillingProfile({ memberId }) {
  const oauthUrl = [
    OAUTH_BASE,
    '?redirect_uri=',
    BASE_URL,
    '/oauth/stripe&client_id=',
    STRIPE_CLIENT_ID,
    '&state=',
    memberId,
  ].join('');
  return (
    <Box maxWidth="80%" width="stretch">
      <Heading my={4}>Welcome to Coa!</Heading>
      <Center width="stretch" borderWidth="1px" minHeight="xs">
        <Center>
          <VStack spacing={8}>
            <Box>
              <InvoiceIcon boxSize={12} />
            </Box>
            <Center maxW="lg">
              In order to bill clients via Coa, you must set up a Billing Profile. Ready?
            </Center>
            <Link color="green.900" href={oauthUrl}>
              Let's Get Started!
            </Link>
          </VStack>
        </Center>
      </Center>
    </Box>
  );
}

SetupBillingProfile.propTypes = {
  memberId: PropTypes.string,
};

export default SetupBillingProfile;
