import {
  createGetSubscriptionOrderSummaryPlaceholderData,
  SubscriptionPromotionCode,
  useGetB2BSubscriptionOrderSummaryQuery,
  useGetSubscriptionOrderSummaryQuery,
  usePostSubscriptionsMutation,
} from '@coa/api/controllers/v1/subscriptions';
import { useQueryParams, useRouteParams } from '@coa/react-utils';
import React, { createContext, useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useGetTeamQuery } from '../../../../resources/teams';

const OrderSummaryContext = createContext(null);
type SubmitButtonLabel = 'Subscribe' | 'Start Free Trial';

export const SubscribeModalOrderSummaryProvider = ({ children }: { children: React.ReactNode }) => {
  // we want to use a centralized postSubscriptionMutation hook to tap into onSuccess
  // originally, this was just in the SubscribeForm, but passing it via this context
  // helps us expose the isSuccess boolean to components in TeamMembership
  const queryParams = useQueryParams();
  const b2bPartnerParam = queryParams.get('b2bPartner');
  const postSubscriptionsMutation = usePostSubscriptionsMutation();

  const initialPromoCode = Boolean(b2bPartnerParam && b2bPartnerParam === 'classpass')
    ? 'classpass'
    : '';
  const [requestedPromoCode, setRequestedPromoCode] = useState<SubscriptionPromotionCode['code']>(
    initialPromoCode
  );

  // hacky, but needed to increase layout flexibility within SubscribeForm
  const teamMembershipRegistrationMatch = useRouteMatch({
    path: '/team-membership',
  });
  const params = useRouteParams<{ id?: string }>();
  const getTeamQuery = useGetTeamQuery(
    { id: params.id },
    { enabled: Boolean(teamMembershipRegistrationMatch) && Boolean(params.id) }
  );
  const { data: { attributes: { numberOfSeats = 0 } } = { attributes: {} } } =
    getTeamQuery.data || {};

  // also sorta hacky, but we need to use this Context both within the B2C subscription flow &&
  // the new B2B Team membershipFlow. This logic will conditionally let us hit
  // - v1/subscriptions/order_summary
  // - v1/subcriptions/b2b_order_summary
  const orderSummaryHook = teamMembershipRegistrationMatch
    ? useGetB2BSubscriptionOrderSummaryQuery
    : useGetSubscriptionOrderSummaryQuery;

  const getSubscriptionOrderSummaryQuery = orderSummaryHook(
    requestedPromoCode ? { promoCode: requestedPromoCode } : undefined,
    {
      keepPreviousData: Boolean(requestedPromoCode),
      placeholderData: createGetSubscriptionOrderSummaryPlaceholderData(),
    }
  );

  const inTeamMembershipFlow = Boolean(teamMembershipRegistrationMatch);

  const submitButtonLabel: SubmitButtonLabel = inTeamMembershipFlow
    ? 'Subscribe'
    : 'Start Free Trial';

  return (
    <OrderSummaryContext.Provider
      value={{
        requestedPromoCode,
        setRequestedPromoCode,
        getSubscriptionOrderSummaryQuery,
        hideOrderSummary: inTeamMembershipFlow,
        submitButtonLabel,
        postSubscriptionsMutation,
        subscriptionQuantity: inTeamMembershipFlow ? numberOfSeats : 1,
      }}
    >
      {children}
    </OrderSummaryContext.Provider>
  );
};

export const useOrderSummary = () => useContext(OrderSummaryContext);
