import {
  isBaseFormScreen,
  isNonBaseFormScreen,
  WorkoutScreen,
} from '@coa/api/models/workout/screens';
import { isBaseFormSheet, WorkoutSheet } from '@coa/api/models/workout/sheets';
import { AdminSerializedWorkout } from '@coa/api/serializers/admin/AdminSerializedWorkout';
import { clientCase } from '@coa/stdlib/string';
import { ValueOf } from '@coa/types';
import {
  AdminWorkoutCmsFormData,
  AdminWorkoutScreenCmsFormData,
  CmsFormDataSheet,
  CmsFormDataSheetParams,
} from '../types';

const formatSerializedWorkoutScreenSheetParams = (sheet: WorkoutSheet): CmsFormDataSheet => {
  if (!isBaseFormSheet(sheet)) {
    // There's no difference, so we can just cast.
    return sheet as CmsFormDataSheet;
  }
  const { questionSequence, questions, ...sheetRest } = sheet;
  return {
    ...sheetRest,
    questions: questionSequence.map((id) => {
      const question = questions[clientCase(id)];
      return { id, ...question };
    }),
  };
};

const formatSerializedWorkoutScreenSheet = ({
  sheet,
}: Pick<ValueOf<AdminSerializedWorkout['screens']>, 'sheet'>): CmsFormDataSheetParams => {
  if (!sheet) return { hasSheet: false as const };
  return {
    sheet: formatSerializedWorkoutScreenSheetParams(sheet),
    hasSheet: true as const,
  };
};

const formatSerializedWorkoutQuestionParams = (screen: WorkoutScreen<'base_form_screen'>) => {
  const { questionSequence, questions } = screen;
  return {
    questions: questionSequence.map((id) => {
      const question = questions[clientCase(id)];
      return question;
    }),
  };
};

const formatSerializedWorkoutScreen = (screen: WorkoutScreen): AdminWorkoutScreenCmsFormData => {
  if (isBaseFormScreen(screen)) {
    const {
      kind,
      questionSequence,
      questions: _questions,
      sheet: _sheet,
      ...baseFormScreenRest
    } = screen;
    const result = {
      kind,
      ...baseFormScreenRest,
      ...formatSerializedWorkoutScreenSheet(screen),
      ...formatSerializedWorkoutQuestionParams(screen),
      // The return value of the two formatters above widens
      // this type, so we assert to re-narrow.
    } as AdminWorkoutScreenCmsFormData<'base_form_screen'>;
    return result;
  }
  if (isNonBaseFormScreen(screen)) {
    const { kind, sheet, ...nonBaseFormScreenRest } = screen;
    return {
      kind,
      ...nonBaseFormScreenRest,
      ...formatSerializedWorkoutScreenSheet(screen),
      // The type of "kind" here has been widened somehow, so we
      // assert to re-narrow.
    } as AdminWorkoutScreenCmsFormData<typeof kind>;
  }
  throw Error(`Unable to format serialized workout screen with kind: "${screen.kind}"`);
};

const formatSerializedWorkoutScreens = ({
  screens,
  screenSequence,
}: Pick<AdminSerializedWorkout, 'screens' | 'screenSequence'>): Pick<
  AdminWorkoutCmsFormData,
  'screens'
> => ({
  screens: screenSequence.map((id) => {
    const screen = screens[clientCase(id)];
    return formatSerializedWorkoutScreen(screen);
  }),
});

export const formatSerializedWorkoutToCmsFormData = (
  serializedWorkout: AdminSerializedWorkout
): AdminWorkoutCmsFormData => {
  const { screenSequence, screens, ...rest } = serializedWorkout;
  return {
    ...rest,
    ...formatSerializedWorkoutScreens({ screenSequence, screens }),
  };
};
