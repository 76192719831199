import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useRef } from 'react';
import { ArrowLeft } from 'react-feather';
import { SlidersIcon } from '../../../../components/Icons';
import { classMarketplaceAnalytics } from '../../../../lib/analytics/clients';
import {
  workshopDays,
  WorkshopFilter,
  workshopSkills,
  workshopTimings,
} from '../../../../resources/workshops';
import {
  ClassesFilterType,
  ClassesFilterValues,
  useClassesFiltersContext,
} from './ClassesFiltersContextProvider';
import {
  ClassesFiltersButtonToggleProps,
  ClassesFiltersClearFilterButton,
  filterTitlesToSubtitles,
  getFilterToggleButtonProps,
  getIconFromFilterLabel,
} from './FilterComponents';

type MobileFilterToggleGroupProps = {
  filterType: ClassesFilterType;
  values: ClassesFilterValues[];
  activeValues: ClassesFilterValues[];
  onToggle: (type: ClassesFilterType, value: ClassesFilterValues) => () => void;
  title: string;
};

export const ClassesFilterMobileButtonToggle = ({
  isActive,
  icon,
  subtitle,
  title,
  toggleActive,
  ...rest
}: ClassesFiltersButtonToggleProps) => (
  <Button
    {...getFilterToggleButtonProps(isActive, toggleActive)}
    height="48px"
    width="100%"
    {...rest}
  >
    <HStack height="full" spacing={0} width="100%">
      <Flex height="50%" alignItems="center" width="10%" mr={4}>
        {icon}
      </Flex>
      <Flex
        height="50%"
        flexDir="column"
        mt={0}
        flexGrow={1}
        justifyContent="center"
        alignItems="start"
      >
        <Text fontWeight="normal">{_.capitalize(`${title}`).split('_').join(' ')}</Text>
        {subtitle ? (
          <Text fontSize="12px" fontWeight="normal">
            {subtitle}
          </Text>
        ) : null}
      </Flex>
    </HStack>
  </Button>
);

export const MobileFiltersDrawerFilterToggleGroup = ({
  filterType,
  values,
  activeValues,
  onToggle,
  title,
}: MobileFilterToggleGroupProps) => (
  <VStack mb={4} alignItems="start">
    <Heading size="md">{title}</Heading>
    <Grid templateColumns="1fr 1fr" width="full" justifyContent="center" gap={2}>
      {values.map((val) => {
        let subtitle = '';
        if (filterType === 'timings') {
          subtitle = filterTitlesToSubtitles[val];
        }
        return (
          <GridItem key={val} textAlign="center" colSpan={1}>
            <ClassesFilterMobileButtonToggle
              isActive={activeValues.includes(val)}
              toggleActive={onToggle(filterType, val)}
              title={val}
              icon={getIconFromFilterLabel(val)}
              subtitle={subtitle}
            />
          </GridItem>
        );
      })}
    </Grid>
  </VStack>
);

export const ClassesFiltersMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const { days, timings, skills, toggleFilter, hasFiltersApplied } = useClassesFiltersContext();

  const timingsLabels = Object.values(workshopTimings) as WorkshopFilter['timings'];
  const daysLabels = Object.values(workshopDays) as WorkshopFilter['days'];
  const skillsLabels = Object.values(workshopSkills) as WorkshopFilter['skills'];

  let filterDrawerBtnLabel = 'Filters';
  const appliedFilters = hasFiltersApplied ? [...timings, ...days, ...skills] : [];
  const firstAppliedFilter = appliedFilters[0];
  const numAdditionalFiltersApplied = appliedFilters.length - 1;
  if (hasFiltersApplied) {
    filterDrawerBtnLabel = _.capitalize(firstAppliedFilter).split('_').join(' ');
    if (numAdditionalFiltersApplied > 0) {
      filterDrawerBtnLabel += ` + ${numAdditionalFiltersApplied} more`;
    }
  }

  const handleOnOpen = () => {
    onOpen();
    classMarketplaceAnalytics.track('Toggled Mobile Classes Filters', {
      openFilter: true,
      classKind: null,
    });
  };
  const handleOnClose = () => {
    onClose();
    classMarketplaceAnalytics.track('Toggled Mobile Classes Filters', {
      openFilter: false,
      classKind: null,
    });
  };

  return (
    <>
      <VStack width="full" textAlign="center" mt={4}>
        <Button
          ref={btnRef}
          color={hasFiltersApplied ? 'green.900' : 'blackAlpha.800'}
          colorScheme="green"
          variant="outline"
          _focus={{ outline: 'none' }}
          isActive={hasFiltersApplied}
          onClick={handleOnOpen}
          borderColor={hasFiltersApplied ? 'green' : 'inherit'}
        >
          {filterDrawerBtnLabel}
          <SlidersIcon ml={2} fontSize="sm" />
        </Button>
        {hasFiltersApplied ? <ClassesFiltersClearFilterButton /> : null}
      </VStack>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={handleOnClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="blackAlpha.400"
          >
            <HStack position="relative">
              <IconButton
                icon={<ArrowLeft />}
                aria-label="class-filter-drawer-closer"
                onClick={onClose}
                variant="ghost"
                position="absolute"
                _focus={{ outline: 'none' }}
              />
              <Heading size="lg" flexGrow={1} textAlign="center">
                Filters
              </Heading>
              <Box position="absolute" right={0}>
                <ClassesFiltersClearFilterButton />
              </Box>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <MobileFiltersDrawerFilterToggleGroup
              filterType="timings"
              title="Class Time"
              values={timingsLabels}
              activeValues={timings}
              onToggle={toggleFilter}
            />
            <MobileFiltersDrawerFilterToggleGroup
              filterType="days"
              title="Day of Week"
              values={daysLabels}
              activeValues={days}
              onToggle={toggleFilter}
            />
            <MobileFiltersDrawerFilterToggleGroup
              filterType="skills"
              title="Skill"
              values={skillsLabels}
              activeValues={skills}
              onToggle={toggleFilter}
            />
            {/* 
                this empty div is so the Filter buttons dont get cut off tyring to scroll all the way to the bottom.
                We're using this div to provide enough scrollable height for mobile browsers to show all drawer filter buttons
                This seems to be an issue with Chakra Drawers in general, but as we're on an outdated version of Chakra at 
                the time of this comment (2/16/23), we don't have the time to update chakra across the codebase - BC
            */}
            <div style={{ height: '100px' }} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
