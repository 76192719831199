import { WorkoutRecord } from '@coa/api/models/workout';
import { FormWorkoutScreenKind, WorkoutScreen } from '@coa/api/models/workout/screens';
import { WorkoutSheet } from '@coa/api/models/workout/sheets';
import { AdminSerializedWorkout } from '@coa/api/serializers/admin/AdminSerializedWorkout';
import { clientCase } from '@coa/stdlib/string';
import {
  AdminWorkoutCmsFormData,
  AdminWorkoutScreenCmsFormData,
  CmsFormDataSheet,
  isBaseFormScreenCmsFormData,
  isBaseFormSheetCmsFormData,
} from '../types';

const getRandomScreenOrQuestionId = ({ kind }: { kind: string }) =>
  `${kind}_${String(Math.random() * 1000000000).split('.')[0]}`;

const parseScreenCmsFormDataSheetParams = (sheet: CmsFormDataSheet): WorkoutSheet => {
  if (!isBaseFormSheetCmsFormData(sheet)) {
    return sheet as WorkoutSheet;
  }
  const { questions, ...sheetRest } = sheet;
  return {
    ...sheetRest,
    ...questions.reduce(
      (acc, question) => {
        const { id = getRandomScreenOrQuestionId(question) } = question;
        return {
          ...acc,
          questionSequence: [...acc.questionSequence, id],
          questions: {
            ...acc.questions,
            [clientCase(id)]: { id, ...question },
          },
        };
      },
      {
        questionSequence: [],
        questions: {},
      } as Pick<WorkoutSheet<'base_form_sheet'>, 'questions' | 'questionSequence'>
    ),
  };
};

const parseScreenCmsFormDataSheet = (screenCmsFormData: AdminWorkoutScreenCmsFormData) => {
  const { hasSheet, sheet } = screenCmsFormData;
  return hasSheet ? { sheet: parseScreenCmsFormDataSheetParams(sheet) } : {};
};

const parseScreenCmsFormDataQuestionParams = (
  screenCmsFormData: AdminWorkoutScreenCmsFormData<FormWorkoutScreenKind>
) => {
  const { questions } = screenCmsFormData;
  return {
    ...questions.reduce(
      (acc, question) => {
        const { id = getRandomScreenOrQuestionId(question) } = question;
        return {
          ...acc,
          questionSequence: [...acc.questionSequence, id],
          questions: {
            ...acc.questions,
            [clientCase(id)]: { id, ...question },
          },
        };
      },
      {
        questionSequence: [],
        questions: {},
      } as Pick<WorkoutScreen<'base_form_screen'>, 'questions' | 'questionSequence'>
    ),
  };
};

const parseScreenCmsFormData = (screen: AdminWorkoutScreenCmsFormData): WorkoutScreen => {
  if (isBaseFormScreenCmsFormData(screen)) {
    const {
      questions,
      sheet: _sheet,
      hasSheet: _hasSheet,
      ...baseFormScreenCmsFormDataRest
    } = screen;
    return {
      ...baseFormScreenCmsFormDataRest,
      ...parseScreenCmsFormDataQuestionParams(screen),
      ...parseScreenCmsFormDataSheet(screen),
    };
  }
  const { sheet, hasSheet, ...nonBaseFormScreenCmsFormDataRest } = screen;
  return {
    ...nonBaseFormScreenCmsFormDataRest,
    ...parseScreenCmsFormDataSheet(screen),
  };
};

const parseScreensCmsFormData = ({
  screens,
}: Pick<AdminWorkoutCmsFormData, 'screens'>): Pick<
  AdminSerializedWorkout,
  'screens' | 'screenSequence'
> =>
  screens.reduce(
    (acc, screen) => {
      const { id = getRandomScreenOrQuestionId(screen) } = screen;
      return {
        screenSequence: [...acc.screenSequence, id],
        screens: {
          ...acc.screens,
          [clientCase(id)]: {
            id,
            ...parseScreenCmsFormData(screen),
          },
        },
      };
    },
    {
      screenSequence: [] as WorkoutRecord['screenSequence'],
      screens: {} as WorkoutRecord['screens'],
    }
  );

export const parseCmsFormDataToSerializedWorkout = (
  formattedSerializedWorkout: AdminWorkoutCmsFormData
): AdminSerializedWorkout => {
  const { screens, ...rest } = formattedSerializedWorkout;
  return {
    ...rest,
    ...parseScreensCmsFormData({ screens }),
  };
};
