import { Heading, StackProps, VStack } from '@chakra-ui/react';
import { formatProseStartTimeShort } from '@coa/stdlib/string';
import React from 'react';
import { boundChakra } from '../../../../lib/chakra-ui';
import { Workshop } from '../../../../resources/workshops';
import { TryQAndAQuestionSubmissionForm } from './TryQAndAQuestionSubmissionForm';

const SuccessText = boundChakra.Text('SuccessStateText', {
  fontSize: { base: 'lg', md: 'xl' },
  maxWidth: 400,
  textAlign: 'center',
});

export function TryQAndASuccess({
  selectedWorkshop,
  ...props
}: { selectedWorkshop: Workshop } & StackProps) {
  return (
    <VStack
      {...props}
      height="100%"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <VStack spacing={4} mb={6}>
        <Heading size="xl">You're in!</Heading>
        <SuccessText>
          We look forward to seeing you on{' '}
          <strong>
            {formatProseStartTimeShort(selectedWorkshop.workshopOccurrences[0].startsAt)}
          </strong>{' '}
          for <strong>{selectedWorkshop.title}</strong>.
        </SuccessText>
        <SuccessText>You'll receive an email shortly with more details.</SuccessText>
        <TryQAndAQuestionSubmissionForm qAndAId={selectedWorkshop.id} />
      </VStack>
    </VStack>
  );
}
