import { resetTimeout, Timeout } from '@coa/stdlib/timeout';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimer = () => {
  const [timerElapsed, setElapsed] = useState<boolean>();
  const timeoutRef = useRef<Timeout | undefined>();

  const execSetElapsed = useCallback((elapsed) => {
    setElapsed(elapsed);
  }, []);

  const startTimer = useCallback(
    (duration: number) => {
      timeoutRef.current = timeoutRef.current
        ? resetTimeout(timeoutRef.current, () => execSetElapsed(true), duration)
        : setTimeout(() => execSetElapsed(true), duration);
    },
    [execSetElapsed]
  );

  const startTimerAsync = useCallback(
    (duration: number) =>
      new Promise((resolve) => {
        timeoutRef.current = timeoutRef.current
          ? resetTimeout(
              timeoutRef.current,
              () => {
                execSetElapsed(true);
                resolve(null);
              },
              duration
            )
          : setTimeout(() => {
              execSetElapsed(true);
              resolve(null);
            }, duration);
      }),
    [execSetElapsed]
  );

  const resetTimer = useCallback(() => {
    if (timeoutRef.current) {
      execSetElapsed(false);
      clearTimeout(timeoutRef.current);
    }
  }, [execSetElapsed]);

  // On unmount, reset the timer if necessary.
  useEffect(() => resetTimer, [resetTimer]);

  return {
    startTimer,
    startTimerAsync,
    resetTimer,
    timerElapsed,
  };
};
