import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRemoveGlobalPaddingTop } from '../shared/layout/components/GlobalLayoutProvider';
import { TryPushupsView } from './pushups/TryPushupsView';
import { TryQAndAInstanceView } from './q-and-a/TryQAndAInstanceView';
import { TryQAndALandingView } from './q-and-a/TryQAndALandingView';

export function TryView(): JSX.Element {
  useRemoveGlobalPaddingTop();
  return (
    <Switch>
      <Route path="/try/pushups/:b2bPartner" component={TryPushupsView} />
      <Route path="/try/pushups" component={TryPushupsView} />
      <Route path="/try/q-and-a/:workshopSlug" component={TryQAndAInstanceView} />
      <Route path="/try/q-and-a" component={TryQAndALandingView} />
    </Switch>
  );
}
