import { useRouteParams } from '@coa/react-utils';
import { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { useGetTeamQuery, useGetTeamWorkshopsQuery } from '../../../resources/teams';

// Access to either classes or teammates tabs are now conditional based on team.accessKind
// Because the tabs are tied to specific routes, in the event a user somehow navigates to a route
// that they dont have access to, we want to redirect them to the
export const useTeamAccessKindRedirectToTable = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { id } = useRouteParams<{ id: string }>();
  const getTeamQuery = useGetTeamQuery({ id });
  const getTeamWorkshopsQuery = useGetTeamWorkshopsQuery({ id });

  const { data: workshops = [] } = getTeamWorkshopsQuery.data || {};
  const { data: { attributes: { accessKind = 'a_la_carte' } } = { attributes: {} } } =
    getTeamQuery.data || {};

  useEffect(() => {
    switch (accessKind) {
      case 'a_la_carte': {
        // a la carte teams can access both membership and classes tabs once
        // they have signed up for a private class
        const hasWorkshops = workshops.length > 0;
        if (hasWorkshops) {
          break;
        }
        const isAlreadyViewingClasses = Boolean(
          matchPath(pathname, { path: `/account/teams/${id}`, exact: true })
        );
        // if they're not already viewing classes tab, redirect
        if (!isAlreadyViewingClasses) {
          history.replace(`/account/teams/${id}`);
        }
        break;
      }
      // currently, accessKind === membership can only access the Teammates tab
      case 'membership': {
        const isAlreadyViewingMembers = Boolean(matchPath(pathname, { path: '*/members' }));
        // if they're not already viewing members tab, redirect
        if (!isAlreadyViewingMembers) {
          history.replace(`/account/teams/${id}/members`);
        }
        break;
      }
      default:
        break;
    }
  }, [accessKind]);
};
