import { Td } from '@chakra-ui/react';
import {
  useAdminCreateWorkoutMutation,
  useAdminIndexWorkoutsQuery,
  useAdminShowWorkoutQuery,
  useAdminUpdateWorkoutMutation,
} from '@coa/api/controllers/admin/workouts';
import { Workout } from '@coa/api/models';
import { AdminSerializedWorkout } from '@coa/api/serializers/admin/AdminSerializedWorkout';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { formSchema, formUiSchema } from './formSchema';
import { AdminWorkoutCmsFormData } from './types';
import { formatSerializedWorkoutToCmsFormData, parseCmsFormDataToSerializedWorkout } from './util';
import { workoutsCmsHelperText } from './workoutCmsContent';

export const AdminWorkoutCmsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <CmsContextProvider<Workout, AdminSerializedWorkout, AdminWorkoutCmsFormData>
    value={{
      baseRouterPath: '/admin-dash/cms/workouts',
      contentTypeTitle: 'Workout',
      contentHelperText: workoutsCmsHelperText,
      searchPlaceholder: 'Search workout by name, eg "Overcoming Adversity"',

      formSchema,
      formUiSchema,

      formatSerializedResourceToCmsFormData: formatSerializedWorkoutToCmsFormData,
      parseCmsFormDataToSerializedResource: parseCmsFormDataToSerializedWorkout,

      useShowQuery: useAdminShowWorkoutQuery,
      useIndexQuery: useAdminIndexWorkoutsQuery,
      useUpdateMutation: useAdminUpdateWorkoutMutation,
      useCreateMutation: useAdminCreateWorkoutMutation,

      getEntryTitle: ({ adminTitle }) => adminTitle,
      indexThEntries: [{ name: 'adminTitle', label: 'Admin Title ' }],
      renderIndexEntryTdContents: function WorkoutIndexEntryTd({
        adminTitle,
      }: AdminSerializedWorkout) {
        return (
          <Td>
            <strong>{adminTitle}</strong>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminWorkoutsView = () => (
  <AdminWorkoutCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/workouts/new">
        <AdminCmsCreateView<Workout, AdminSerializedWorkout, AdminWorkoutCmsFormData> />
      </Route>
      <Route path="/admin-dash/cms/workouts/:id">
        <AdminCmsShowView<Workout, AdminSerializedWorkout, AdminWorkoutCmsFormData> />
      </Route>
      <Route path="/admin-dash/cms/workouts">
        <AdminCmsIndexView<Workout, AdminSerializedWorkout, AdminWorkoutCmsFormData> />
      </Route>
    </Switch>
  </AdminWorkoutCmsContextProvider>
);
