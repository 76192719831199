import { createLegacyAuthAxiosClientWithCaseTransform } from '@coa/api/lib/axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import {
  AdminGetTopLevelTeams,
  AdminPostTeam,
  AdminPostTeamWorkshop,
  BulkPostTeamMembers,
  DeleteTeamMembers,
  DeleteTeamWorkshops,
  GetTeam,
  GetTeamMembers,
  GetTeamTeams,
  GetTeamWorkshops,
  PostTeam,
  PostTeamMembers,
  PostTeamMembersResendInvite,
  PostTeamTeams,
  PutTeam,
  PutTeamMembers,
} from './types';

// TODO: this whole file should eventually be moved to @coa/api

/*
 * =============================================
 * Admin
 * =============================================
 */

/*
 * GET /admin/teams/top_level
 */

const generateAdminGetTopLevelTeams = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = '/admin/teams/top_level';
  const fn = async () => {
    const { data } = await client.get<AdminGetTopLevelTeams.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useAdminGetTopLevelTeamsQuery = (
  options: UseQueryOptions<AdminGetTopLevelTeams.Response> = {}
) => {
  const { path: queryKey, fn } = generateAdminGetTopLevelTeams();
  return useQuery<AdminGetTopLevelTeams.Response>(queryKey, fn, options);
};

/*
 * POST /admin/teams
 */

const generateAdminPostTeam = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = '/admin/teams';
  const fn = async (body: AdminPostTeam.Request['body']) => {
    const { data } = await client.post<AdminPostTeam.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useAdminPostTeamMutation = () => {
  const { path: putQueryKey, fn } = generateAdminPostTeam();
  const queryClient = useQueryClient();

  // TODO: We probably want to expose options here.
  return useMutation(putQueryKey, {
    mutationFn: (body: AdminPostTeam.Request['body']) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries('/admin/teams/top_level');
    },
    onSettled: () => {
      queryClient.invalidateQueries('/admin/teams/top_level');
    },
  });
};

const generateAdminPostTeamWorkshop = ({ id }: AdminPostTeamWorkshop.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/admin/teams/${id}/workshops`;
  const fn = async (body: AdminPostTeamWorkshop.Request['body']) => {
    const { data } = await client.post<AdminPostTeamWorkshop.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useAdminPostTeamWorkshopMutation = ({
  id,
}: AdminPostTeamWorkshop.Request['pathParams']) => {
  const { path: postQueryKey, fn } = generateAdminPostTeamWorkshop({ id });
  const queryClient = useQueryClient();

  // TODO: We probably want to expose options here.
  return useMutation(postQueryKey, {
    mutationFn: (body: AdminPostTeamWorkshop.Request['body']) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries(`/v1/teams/${id}/workshops`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/workshops`);
    },
  });
};

/*
 * =============================================
 * Member
 * =============================================
 */

/*
 * POST /v1/teams
 */
const generateCreateTeam = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams`;
  const fn = async (body: PutTeam.Request['body']) => {
    const { data } = await client.post<PostTeam.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useCreateTeamMutation = () => {
  const { path: queryKey, fn } = generateCreateTeam();
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PostTeam.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams`);
    },
  });
};

/*
 * GET /v1/teams/:id
 */
const generateGetTeam = ({ id }: GetTeam.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}`;
  const fn = async () => {
    const { data } = await client.get<GetTeam.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetTeamQuery = (
  params: GetTeam.Request['pathParams'],
  options: UseQueryOptions<GetTeam.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetTeam(params);
  return useQuery<GetTeam.Response>(queryKey, fn, options);
};

/*
 * PUT /v1/teams/:id
 */
const generatePutTeam = ({ id }: PutTeam.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}`;
  const fn = async (body: PutTeam.Request['body']) => {
    const { data } = await client.put<PutTeam.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePutTeamMutation = ({ id }: PutTeam.Request['pathParams']) => {
  const { path: queryKey, fn } = generatePutTeam({ id });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PutTeam.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}`);
    },
  });
};

/*
 * GET /v1/teams/:id/members
 */

const generateGetTeamMembers = ({ id }: GetTeamMembers.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/members`;
  const fn = async () => {
    const { data } = await client.get<GetTeamMembers.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetTeamMembersQuery = (
  params: GetTeamMembers.Request['pathParams'],
  options: UseQueryOptions<GetTeamMembers.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetTeamMembers(params);
  return useQuery<GetTeamMembers.Response>(queryKey, fn, options);
};

/*
 * POST /v1/teams/:id/members
 */

const generatePostTeamMembers = ({ id }: PostTeamMembers.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/members`;
  const fn = async (body: PostTeamMembers.Request['body']) => {
    const { data } = await client.post<PostTeamMembers.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePostTeamMembersMutation = ({ id }: PostTeamMembers.Request['pathParams']) => {
  const { path: queryKey, fn } = generatePostTeamMembers({ id });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PostTeamMembers.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/members`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/members`);
    },
  });
};

/*
 * POST /admin/teams/:id/members/bulk_add
 */

// *** ADMIN ROUTE
const generateBulkPostTeamMembers = ({ id }: PostTeamMembers.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/admin/teams/${id}/members/bulk_add`;
  const fn = async (body: BulkPostTeamMembers.Request['body']) => {
    const { data } = await client.post<BulkPostTeamMembers.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useBulkPostTeamMembersMutation = ({ id }: PostTeamMembers.Request['pathParams']) => {
  const { path: mutationPath, fn } = generateBulkPostTeamMembers({ id });
  const queryClient = useQueryClient();
  return useMutation(mutationPath, {
    mutationFn: (body: BulkPostTeamMembers.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/members`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/members`);
    },
  });
};

/*
 * PUT /v1/teams/:id/members/:memberId
 */

const generatePutTeamMembers = ({ id, memberId }: PutTeamMembers.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/members/${memberId}`;
  const fn = async (body: PutTeamMembers.Request['body']) => {
    const { data } = await client.put<PostTeamMembers.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePutTeamMembersMutation = ({
  id,
  memberId,
}: PutTeamMembers.Request['pathParams']) => {
  const { path: queryKey, fn } = generatePutTeamMembers({ id, memberId });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PutTeamMembers.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/members`);
      queryClient.cancelQueries(`/v1/teams/${id}/members/${memberId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/members`);
      queryClient.invalidateQueries(`/v1/teams/${id}/members/${memberId}`);
    },
  });
};

/*
 * DELETE /v1/teams/:id/members/:memberId
 */

const generateDeleteTeamMembers = ({ id, memberId }: DeleteTeamMembers.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/members/${memberId}`;
  const fn = async () => {
    const { data } = await client.delete<DeleteTeamMembers.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useDeleteTeamMembersMutation = ({
  id,
  memberId,
}: DeleteTeamMembers.Request['pathParams']) => {
  const { path: queryKey, fn } = generateDeleteTeamMembers({ id, memberId });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: fn,
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/members`);
      queryClient.cancelQueries(`/v1/teams/${id}/members/${memberId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/members`);
      queryClient.invalidateQueries(`/v1/teams/${id}/members/${memberId}`);
    },
  });
};

/*
 * POST /v1/teams/:id/members/:memberId/resend_invite
 */

const generatePostTeamMembersResendInvite = ({
  id,
  memberId,
}: PostTeamMembersResendInvite.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/members/${memberId}/resend_invite`;
  const fn = async () => {
    const { data } = await client.post<PostTeamMembersResendInvite.Response>(path, {
      /* empty request body */
    });
    return data;
  };
  return { path, fn };
};

export const usePostTeamMembersResendInviteMutation = ({
  id,
  memberId,
}: PostTeamMembersResendInvite.Request['pathParams']) => {
  const { path: queryKey, fn } = generatePostTeamMembersResendInvite({ id, memberId });
  return useMutation(queryKey, { mutationFn: fn });
};

/*
 * GET /v1/teams/:id/workshops
 */

const generateGetTeamWorkshops = ({ id }: GetTeamWorkshops.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/workshops`;
  const fn = async () => {
    const { data } = await client.get<GetTeamWorkshops.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetTeamWorkshopsQuery = (
  params: GetTeamWorkshops.Request['pathParams'],
  options: UseQueryOptions<GetTeamWorkshops.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetTeamWorkshops(params);
  return useQuery<GetTeamWorkshops.Response>(queryKey, fn, options);
};

/*
 * GET /v1/teams/:id/teams
 */

const generateGetTeamTeams = ({ id }: GetTeamTeams.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/teams`;
  const fn = async () => {
    const { data } = await client.get<GetTeamTeams.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetTeamTeamsQuery = (
  params: GetTeamTeams.Request['pathParams'],
  options: UseQueryOptions<GetTeamTeams.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetTeamTeams(params);
  return useQuery<GetTeamTeams.Response>(queryKey, fn, options);
};

/*
 * POST /v1/teams/:id/teams
 */

const generatePostTeamTeams = ({ id }: PostTeamTeams.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/v1/teams/${id}/teams`;
  const fn = async (body: PostTeamTeams.Request['body']) => {
    const { data } = await client.post<PostTeamTeams.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePostTeamTeamsMutation = ({ id }: PostTeamTeams.Request['pathParams']) => {
  const { path: queryKey, fn } = generatePostTeamTeams({ id });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PostTeamTeams.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/teams`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/teams`);
    },
  });
};

/*
 * DELETE /admin/teams/:id/workshops/:workshop_id
 */

const generateDeleteTeamWorkshops = ({
  id,
  workshopId,
}: DeleteTeamWorkshops.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = `/admin/teams/${id}/workshops/${workshopId}`;
  const fn = async () => {
    const { data } = await client.delete<DeleteTeamWorkshops.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useDeleteTeamWorkshopsMutation = ({
  id,
  workshopId,
}: DeleteTeamWorkshops.Request['pathParams']) => {
  const { path: queryKey, fn } = generateDeleteTeamWorkshops({ id, workshopId });
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: fn,
    onMutate: async () => {
      queryClient.cancelQueries(`/v1/teams/${id}/workshops`);
      queryClient.cancelQueries(`/v1/teams/${id}/workshops/${workshopId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/v1/teams/${id}/workshops`);
      queryClient.invalidateQueries(`/v1/teams/${id}/workshops/${workshopId}`);
    },
  });
};
