import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { Member } from '@coa/api/models';
import { useQueryParams } from '@coa/react-utils';
import dayjs from 'dayjs';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSession } from '../../../resources/sessions';
import { Team, usePutTeamMutation } from '../../../resources/teams';

export type TeamMembershipRegistrationSuccess = {
  companyTitle: Team['title'];
  numberOfSeats: Team['numberOfSeats'];
  memberId: Member['id'];
};

type TeamMembershipContext = {
  registrationComplete: boolean;
  onRegistrationSuccess: (teamId: Team['id'], teamAdminMemberId: Member['id']) => Promise<void>;
  isRegistrationFormSubmitting: boolean;
  setIsRegistrationFormSubmitting: Dispatch<React.SetStateAction<boolean>>;
  userExistsError: boolean;
  setUserExistsError: Dispatch<React.SetStateAction<boolean>>;
  teamAdminMemberId: string;
  setTeamAdminMemberId: Dispatch<React.SetStateAction<string>>;
  teamId: Team['id'];
  isVGP: boolean; // Not great solution if we have multiple coupon codes active
  couponCodeIsActive: boolean;
};

export const TeamMembershipRegistrationContext = React.createContext<TeamMembershipContext | null>(
  null
);

export const TeamMembershipContextProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const { loggedIn, memberId: loggedInMemberId } = useSession();
  const [teamAdminMemberId, setTeamAdminMemberId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [isRegistrationFormSubmitting, setIsRegistrationFormSubmitting] = useState(false);
  const [userExistsError, setUserExistsError] = useState<boolean>(false);

  const getSubscriptionsQuery = useGetSubscriptionsQuery({
    enabled: Boolean(teamId) && loggedIn && loggedInMemberId === teamAdminMemberId,
  });
  const hasSubscription = getHasActiveSubscriptionFromQueryResponse(getSubscriptionsQuery.data);
  const putTeamMutation = usePutTeamMutation({ id: teamId });

  // we can encapsulate the newly created teamId
  // and use it to history.push('/account/teams/${teamId}') to finish the flow
  const onRegistrationSuccess = async (newTeamId: Team['id']) => {
    setTeamId(newTeamId);
  };

  // Once the new registration + team has been created,
  // we wait for the subscription to be created via the stripe form
  // then we update the team accessKind to 'membership'
  // and finally navigate to the AccountTeamView
  // This might be the only thing we keep in this context, everything else is form specific
  const registrationComplete = Boolean(teamId);
  useEffect(() => {
    async function updateTeamAccessKind() {
      if (registrationComplete && hasSubscription && teamId) {
        await putTeamMutation.mutateAsync({ accessKind: 'membership' });
        history.push(`/account/teams/${teamId}`);
      }
    }
    updateTeamAccessKind();
  }, [hasSubscription, teamId]);

  // TODO: consider a separate context for coupon codes if we have to support multiple
  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');
  const isVGP = campaign?.toLowerCase() === 'vgp';
  const couponCodeIsActive = dayjs().isBefore(dayjs('2022-12-05'));

  return (
    <TeamMembershipRegistrationContext.Provider
      value={{
        registrationComplete,
        onRegistrationSuccess,
        isRegistrationFormSubmitting,
        setIsRegistrationFormSubmitting,
        userExistsError,
        setUserExistsError,
        teamId,
        setTeamAdminMemberId,
        teamAdminMemberId,
        isVGP,
        couponCodeIsActive,
      }}
    >
      {children}
    </TeamMembershipRegistrationContext.Provider>
  );
};

export const useTeamMembershipRegistrationContext = () =>
  useContext<TeamMembershipContext>(TeamMembershipRegistrationContext);
