import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TeamsAuthView } from './auth/TeamsAuthView';
import { TeamsWelcomeView } from './welcome/TeamsWelcomeView';

export function TeamsView(): JSX.Element {
  return (
    <Switch>
      <Route
        path={[
          // The ordering of these path is important, as the Router
          // will match the first one. If the path with the URL param
          // does not appear before the path without, the one without
          // will match and the param will not be available.
          '/teams/welcome/:token',
          '/teams/welcome',
        ]}
        component={TeamsWelcomeView}
      />
      <Route path={['/teams/log-in', '/teams/claim-account/:token']} component={TeamsAuthView} />
    </Switch>
  );
}
