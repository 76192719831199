import {
  WORKSHOP_TAGS_ADD_SUCCESS,
  WORKSHOP_TAGS_LIST_FAILURE,
  WORKSHOP_TAGS_LIST_REQUEST,
  WORKSHOP_TAGS_LIST_SUCCESS,
  WORKSHOP_TAGS_REMOVE_SUCCESS,
} from '../../actions/admin/workshopTags';

const DEFAULT_STATE = {
  gettingWorkshopTags: null,
  workshopTags: [],
};

export function workshopTags(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case WORKSHOP_TAGS_LIST_REQUEST: {
      return { ...state, workshopTags: [], gettingWorkshopTags: true };
    }
    case WORKSHOP_TAGS_ADD_SUCCESS:
    case WORKSHOP_TAGS_REMOVE_SUCCESS:
    case WORKSHOP_TAGS_LIST_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, workshopTags: data, gettingWorkshopTags: false };
    }
    case WORKSHOP_TAGS_LIST_FAILURE: {
      return { ...state, gettingWorkshopTags: false };
    }
    default:
      return state;
  }
}
