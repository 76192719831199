import { Box, Container, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { useOnMount } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import InvoiceAwaitingPaymentBanner from '../../../components/InvoiceAwaitingPaymentBanner';
import ClassesFiltersBackground from '../../../images/browse_classes_bg.png';
import { classMarketplaceAnalytics } from '../../../lib/analytics/clients/classMarketplaceAnalytics';
import {
  createGetIntroWorkshopsPlaceholderData,
  useDropInWorkshopOccurrencesQuery,
  useGetWorkshopsQuery,
  WorkshopOccurrenceResponse,
  WorkshopResponse,
} from '../../../resources/workshops';
import { ClassCardGrid } from '../components/ClassCardGrid';
import { ClassesFilters } from '../components/ClassesFilters';
import ClassesFiltersContextProvider, {
  useClassesFiltersContext,
} from '../components/ClassesFilters/ClassesFiltersContextProvider';
import { ClassesFiltersEmptyState } from '../components/ClassesFilters/FilterComponents';
import { UpcomingCardViewPreviewContainer } from '../components/UpcomingClassCardPreview';
import { BrowseByTopicStaticView } from './browseByTopic/BrowseByTopicStaticView';
import { UpcomingDropInsCardView } from './drop-ins/UpcomingDropInsView';
import { FeaturedClassesView } from './featured/FeaturedClassesView';
import { UpcomingIntrosCardView } from './intros/UpcomingIntrosView';
import { UpcomingQAndAsCardView } from './q-and-a/UpcomingQAndAsView';
import { UpcomingSeriesCardView } from './series/UpcomingSeriesView';

// Type guards specific to these components
const isWorkshop = (
  workshopOrOccurrence: WorkshopResponse | WorkshopOccurrenceResponse
): workshopOrOccurrence is WorkshopResponse => workshopOrOccurrence.type === 'workshop';

const isWorkshopOccurrence = (
  workshopOrOccurrence: WorkshopResponse | WorkshopOccurrenceResponse
): workshopOrOccurrence is WorkshopOccurrenceResponse =>
  workshopOrOccurrence.type === 'workshop_occurrence';

const sortClassesDataByScheduledDate = (
  classesData: (WorkshopResponse | WorkshopOccurrenceResponse)[]
) =>
  _.sortBy(classesData, (cl) => {
    if (isWorkshop(cl)) {
      return cl.workshopOccurrences[0].startsAt;
    }
    if (isWorkshopOccurrence(cl)) {
      return cl.startsAt;
    }
  });

// We show this component only when there are filters applied
// This component pulls in all of the kinds Workshops + DropIns
export const ClassesHubFilteredClasses = () => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const queryParams = getWorkshopFilter();
  const placeholderData = createGetIntroWorkshopsPlaceholderData({ length: 4 });
  const {
    data: workshopsData,
    isLoading: workshopsIsLoading,
    isFetched: workshopsIsFetched,
    isPlaceholderData,
  } = useGetWorkshopsQuery(queryParams, {
    placeholderData,
  });
  const dropInsQuery = useDropInWorkshopOccurrencesQuery(queryParams);
  const {
    data: dropInsData,
    isLoading: dropInsIsLoading,
    isFetched: dropInsIsFetched,
  } = dropInsQuery;
  const isLoading = workshopsIsLoading || isPlaceholderData || dropInsIsLoading;
  const isFetched = workshopsIsFetched && dropInsIsFetched;
  const classesData = isLoading ? [...workshopsData] : [...workshopsData, ...dropInsData];

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );
  // we're sorting on the frontend, rather than server,
  // because we have 2 distinct queries here: Workshops & DropIns
  const sortedClassesData = sortClassesDataByScheduledDate(classesData);
  if (isFetched && classesData.length === 0) {
    return <ClassesFiltersEmptyState />;
  }
  return (
    <VStack>
      <Flex width="full" alignItems="center">
        {isLoading ? null : <Text mr={2}> {sortedClassesData.length} Results</Text>}
      </Flex>
      <ClassCardGrid
        classes={sortedClassesData}
        isLoading={isLoading}
        hasActiveSubscription={hasActiveSubscription}
      />
      ;
    </VStack>
  );
};

// We show this component when there are no filters applied
const ClassesHubUpcomingClassRows = () => (
  <>
    <UpcomingCardViewPreviewContainer linkUrl="/classes/drop-ins" heading="Upcoming Drop-Ins">
      <UpcomingDropInsCardView />
    </UpcomingCardViewPreviewContainer>
    <UpcomingCardViewPreviewContainer linkUrl="/classes/intros" heading="Upcoming Intros">
      <UpcomingIntrosCardView />
    </UpcomingCardViewPreviewContainer>
    <UpcomingCardViewPreviewContainer linkUrl="/classes/series" heading="Upcoming Series">
      <UpcomingSeriesCardView />
    </UpcomingCardViewPreviewContainer>
    <UpcomingCardViewPreviewContainer linkUrl="/classes/q-and-a" heading="Upcoming Q&As">
      <UpcomingQAndAsCardView />
    </UpcomingCardViewPreviewContainer>
  </>
);

const ClassesHubClassesContainer = () => {
  const { hasFiltersApplied } = useClassesFiltersContext();
  return hasFiltersApplied ? <ClassesHubFilteredClasses /> : <ClassesHubUpcomingClassRows />;
};

export const ClassesHubView = () => {
  useOnMount(() => {
    classMarketplaceAnalytics.track('Viewed Class Marketplace');
  });

  return (
    <>
      <InvoiceAwaitingPaymentBanner />
      <Container maxW="container.lg" py={{ base: 4, md: 8 }} px={{ base: 4, md: 8, xl: 0 }}>
        <FeaturedClassesView />
      </Container>
      <ClassesFiltersContextProvider>
        <Flex
          w="full"
          maxW="100%"
          justify="center"
          alignItems="end"
          bgSize="cover"
          style={{
            background: `linear-gradient(rgba(0,0,0,0), #fff), url(${ClassesFiltersBackground}`,
            backgroundSize: 'cover',
          }}
          pt={12}
          px={{ base: 0, md: 8 }}
        >
          <Box
            roundedTop="lg"
            bgColor="white"
            w={{ base: '80%', md: '80%' }}
            pt={{ base: 8, md: 12 }}
            px={2}
          >
            <Container maxW="100%" p="0" mb={4}>
              <Heading size="xl" textAlign="center">
                Browse Classes
              </Heading>
              <ClassesFilters />
            </Container>
          </Box>
        </Flex>
        <Container maxW="container.lg" px={{ base: 4, md: 8, xl: 0 }}>
          <Flex flexDirection="column">
            <ClassesHubClassesContainer />
            <Container maxW="100%" p="0" mb={10}>
              <Heading size="lg" pt={8} mb={6}>
                Browse by Skills
              </Heading>
              <Text fontSize="lg" mb={4}>
                The following skills are represented by the 7 Traits of Emotional Fitness. Click
                <Link target="_blank" href="https://www.joincoa.com/emotional-fitness" px={1}>
                  here
                </Link>
                to learn more.
              </Text>
              <BrowseByTopicStaticView />
            </Container>
          </Flex>
        </Container>
      </ClassesFiltersContextProvider>
    </>
  );
};
