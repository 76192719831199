import { Flex } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React from 'react';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  useDropInWorkshopOccurrencesQuery,
} from '../../../../resources/workshops';
import { ClassCardGrid } from '../../components/ClassCardGrid';
import { ClassesFilters } from '../../components/ClassesFilters';
import ClassesFiltersContextProvider, {
  useClassesFiltersContext,
} from '../../components/ClassesFilters/ClassesFiltersContextProvider';
import { ClassesFiltersEmptyState } from '../../components/ClassesFilters/FilterComponents';

const placeholderData = createGetDropInWorkshopOccurrencesPlaceholderData({ length: 9 });

const FilteredDropInsList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const queryParams = getWorkshopFilter();
  const {
    data: dropIns,
    isLoading: isDropInsLoading,
    isFetched: isDropInsFetched,
    isPlaceholderData,
  } = useDropInWorkshopOccurrencesQuery(queryParams, {
    placeholderData,
  });

  const isLoading = isDropInsLoading || isPlaceholderData || parentIsLoading;
  if (isDropInsFetched && dropIns.length === 0) {
    return <ClassesFiltersEmptyState />;
  }

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={dropIns}
    />
  );
};
const UnfilteredDropInsList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const {
    data: dropIns,
    isLoading: isDropInsLoading,
    isFetched: isDropInsFetched,
    isPlaceholderData,
  } = useDropInWorkshopOccurrencesQuery(
    {},
    {
      placeholderData,
    }
  );
  const isLoading = isDropInsLoading || isPlaceholderData || parentIsLoading;
  if (isDropInsFetched && dropIns.length === 0) {
    return <ClassesFiltersEmptyState />;
  }

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={dropIns}
    />
  );
};

const DropInsListViewInner = () => {
  const { hasFiltersApplied } = useClassesFiltersContext();
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const props = { hasActiveSubscription, parentIsLoading: getSubscriptionsQuery.isLoading };
  return hasFiltersApplied ? (
    <FilteredDropInsList {...props} />
  ) : (
    <UnfilteredDropInsList {...props} />
  );
};

export const DropInsListView = () => (
  <ClassesFiltersContextProvider kind="intro">
    <Flex width="full" mb={4}>
      <ClassesFilters showFilterText={false} />
    </Flex>
    <DropInsListViewInner />
  </ClassesFiltersContextProvider>
);
