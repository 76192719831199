import * as Yup from 'yup';
import {
  ExerciseStepQuestion,
  exerciseStepQuestionKind,
  ExerciseStepQuestionKind,
} from '../../../resources/exercises';

export const DUMMY_ERR_MESSAGE = 'invalid';

const formQuestionKindToValidationSchema: {
  [key in ExerciseStepQuestionKind]: Yup.StringSchema<string> | Yup.ArraySchema<string> | null;
} = {
  [exerciseStepQuestionKind.radio]: Yup.string(),
  [exerciseStepQuestionKind.checkbox]: Yup.array().of(Yup.string()),
  [exerciseStepQuestionKind.longText]: Yup.string(),

  // No explicit input, so we can assume empty string
  [exerciseStepQuestionKind.instruction]: Yup.string(),
  [exerciseStepQuestionKind.weeklyRepRecap]: Yup.string(),
};

export const getFieldValidationFn = (kind: ExerciseStepQuestion['kind']) => {
  const schema = formQuestionKindToValidationSchema[kind];
  return async (value?: string | string[]) => {
    try {
      await schema.validate(value, { strict: true });
    } catch (err) {
      if (err.name === 'ValidationError') {
        // This return value usually becomes the error message, but
        // because we're not showing error messages here we simply
        // return dummy.
        return DUMMY_ERR_MESSAGE;
      }
      throw err;
    }
  };
};
