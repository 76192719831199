/* eslint-disable react/prop-types */
import { CloseButton, Flex, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clientList, markAsVoid, pay, refund, therapistList } from '../actions/invoices';
import BillingHistoryDetails from '../components/BillingHistory';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { sessionLocalStorage } from '../lib/localStorage';

const LIST_FUNCS = {
  therapist: therapistList,
  client: clientList,
  admin: clientList,
};

const EMPTY_STATE_MESSAGES = {
  therapist:
    'You haven’t sent an invoice yet! When you send an invoice to a client you’ll be able to track the status of it here.',
  client:
    'Your Payment Profile is all set up, but you haven’t received any invoices yet! When your therapist sends an invoice you’ll see it here.',
};

class BillingHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.getInvoices = this.getInvoices.bind(this);
    this.payInvoice = this.payInvoice.bind(this);
    this.voidInvoice = this.voidInvoice.bind(this);
    this.refundInvoice = this.refundInvoice.bind(this);
  }

  componentDidMount() {
    const { role } = sessionLocalStorage.getCurrentMember();
    this.getInvoices(role);
    this.setState({ role, loading: false });
  }

  getInvoices(role) {
    const { dispatch, memberId } = this.props;
    dispatch(LIST_FUNCS[role](memberId));
  }

  payInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to charge this invoice to your payment method on file?')) {
      dispatch(pay(memberId, invoice.id));
    }
  }

  refundInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to refund this invoice?')) {
      dispatch(refund(memberId, invoice.id));
    }
  }

  voidInvoice(invoice) {
    const { dispatch, memberId } = this.props;
    const { confirm } = window;
    if (confirm('Are you sure you want to void this invoice?')) {
      dispatch(markAsVoid(memberId, invoice.id));
    }
  }

  render() {
    const { gettingInvoices, invoices, invoiceMembers, close } = this.props;
    const { role, loading } = this.state;
    if (loading || gettingInvoices) {
      return <Loading />;
    }
    if (invoices.length === 0) {
      return <EmptyState message={EMPTY_STATE_MESSAGES[role]} />;
    }
    return (
      <>
        <Flex m={4} align="center" justify="space-evenly" width="stretch">
          <CloseButton mr="auto" visibility="hidden" />
          <Heading>Billing History</Heading>
          <CloseButton ml="auto" visibility="hidden" />
        </Flex>
        <BillingHistoryDetails
          viewerRole={role}
          invoices={invoices}
          invoiceMembers={invoiceMembers}
          payInvoice={this.payInvoice}
          refundInvoice={this.refundInvoice}
          voidInvoice={this.voidInvoice}
          close={close}
        />
      </>
    );
  }
}

BillingHistoryContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  memberId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { invoices, invoiceMembers, gettingInvoices } = state.invoices;
  return { invoices, invoiceMembers, gettingInvoices };
};

export default connect(mapStateToProps)(BillingHistoryContainer);
