import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, setAsDefault } from '../actions/clientPaymentMethods';
import { update } from '../actions/clientPaymentProfiles';
import SetupPaymentProfile from '../components/SetupPaymentProfile';

class PaymentProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.savePaymentMethod = this.savePaymentMethod.bind(this);
    this.destroyPaymentMethod = this.destroyPaymentMethod.bind(this);
    this.setPaymentMethodAsDefault = this.setPaymentMethodAsDefault.bind(this);
  }

  setPaymentMethodAsDefault(paymentMethod) {
    const { dispatch, memberId } = this.props;
    dispatch(setAsDefault(memberId, paymentMethod.id));
  }

  destroyPaymentMethod(paymentMethod) {
    const { dispatch, memberId } = this.props;
    dispatch(destroy(memberId, paymentMethod.id));
  }

  async savePaymentMethod({ paymentMethod }, saveForLater) {
    const { dispatch, memberId, onSuccess } = this.props;
    if (saveForLater) {
      await dispatch(update(memberId, { payment_method_id: paymentMethod.id }));
    }
    if (onSuccess) onSuccess(paymentMethod.id);
  }

  render() {
    const {
      paymentProfile,
      showFormIfNeccessary,
      showSaveForLater,
      cta,
      disabled,
      errors,
    } = this.props;

    return (
      <SetupPaymentProfile
        showWelcome={paymentProfile.payment_methods.length === 0}
        paymentMethods={paymentProfile.payment_methods}
        defaultPaymentMethod={paymentProfile.default_payment_method}
        therapistName={paymentProfile.therapist_name}
        savePaymentMethod={this.savePaymentMethod}
        destroyPaymentMethod={this.destroyPaymentMethod}
        setPaymentMethodAsDefault={this.setPaymentMethodAsDefault}
        showFormIfNeccessary={showFormIfNeccessary}
        showSaveForLater={showSaveForLater}
        cta={cta}
        disabled={disabled}
        errors={errors}
      />
    );
  }
}

PaymentProfileContainer.propTypes = {
  memberId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  paymentProfile: PropTypes.object,
  showFormIfNeccessary: PropTypes.bool,
  showSaveForLater: PropTypes.bool,
  onSuccess: PropTypes.func,
  cta: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { paymentProfile, errors } = state.clientPaymentProfiles;
  return { paymentProfile, errors };
};

export default connect(mapStateToProps)(PaymentProfileContainer);
