import { Box, Center, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ClockIcon, InvoiceIcon, PayoutsIcon } from './Icons';

function EmptyState({ message, type }) {
  const renderIcon = () => {
    if (type === 'payments') return <PayoutsIcon boxSize={12} />;
    if (type === 'invoices') return <InvoiceIcon boxSize={12} />;
    return <ClockIcon boxSize={12} />;
  };

  return (
    <Center width="stretch" borderWidth="1px" minHeight="xs">
      <Center>
        <VStack spacing={8} px={5}>
          <Box>{renderIcon()}</Box>
          <Center maxW="lg">{message}</Center>
        </VStack>
      </Center>
    </Center>
  );
}

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default EmptyState;
