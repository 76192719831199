import { deepMapKeys } from '@coa/stdlib/object';
import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { addTransformRequest, addTransformResponse } from './util/addTransform';

/**
 * An interceptor that transforms request / response casing
 * to account for stylistic differences between our Rails
 * backend and the TS frontend.
 *
 * In a future state, we should be able to configure our
 * serializers on the backend to return the proper casing,
 * but we use this in the meantime.
 */
export const caseInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  addTransformResponse(config, (data) =>
    // Note use of isObject, as isPlainObject returns falsy for array cases.
    _.isObject(data) ? deepMapKeys(data, _.camelCase) : data
  );
  addTransformRequest(config, (rawData) => {
    // TODO: We need to actually add case transform here,
    // but for the time being this is fine.
    const isFormData = rawData instanceof FormData;
    if (isFormData) return rawData;

    // Axios provides data as a String at this stage, so we cast
    // to and from.
    const data = _.isString(rawData) ? JSON.parse(rawData) : rawData;
    const result = _.isPlainObject(data) ? deepMapKeys(data, _.snakeCase) : data;
    return JSON.stringify(result);
  });
  return config;
};
