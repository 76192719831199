import { Button, ButtonGroup, CloseButton } from '@chakra-ui/react';
import { UnpackArray } from '@coa/types';
import React, { useState } from 'react';
import {
  AdminGetWorkshopsSearch,
  useAdminGetWorkshopsSearch,
  Workshop,
} from '../../resources/workshops';
import { getUniversalAdminDashRouterUrl } from '../../routerPaths/adminDashRouterPaths';
import { getUniversalClassesRouterUrl } from '../../routerPaths/classesRouterPaths';
import { ChakraReactSelect } from '../ChakraReactSelect';
import { ArrowRightIcon, EditIcon, SearchIcon } from '../Icons';
import { EventCardDescription, EventCardHeading, SecondaryEventCard } from '../molecules/EventCard';

const AdminWorkshopSearchResult = ({
  workshop,
  resetSearch,
  isResettable,
}: {
  workshop: Workshop;
  resetSearch: () => void;
  isResettable?: boolean;
}) => (
  <SecondaryEventCard
    src={workshop.imageUrl}
    mt={8}
    bg="gray.50"
    borderRadius="base"
    padding={4}
    position="relative"
  >
    {isResettable ? (
      <CloseButton position="absolute" top={2} right={2} onClick={resetSearch} />
    ) : null}
    <EventCardHeading>{workshop.title}</EventCardHeading>
    <EventCardDescription mb={2}>{workshop.shortBlurb}</EventCardDescription>

    <ButtonGroup>
      <Button
        rightIcon={<ArrowRightIcon />}
        size="sm"
        aria-label="Edit Class"
        // Open on a new page to preserve the search.
        as="a"
        // TODO: Prefer use of routerPath
        href={getUniversalClassesRouterUrl.details({ id: workshop.id })}
        target="_blank"
      >
        Class Details
      </Button>
      <Button
        rightIcon={<EditIcon />}
        size="sm"
        aria-label="Edit Class"
        // Open on a new page to preserve the search.
        as="a"
        href={getUniversalAdminDashRouterUrl.classes({ id: workshop.id })}
        target="_blank"
      >
        Edit Details
      </Button>
      <Button
        rightIcon={<SearchIcon />}
        size="sm"
        aria-label="Edit Class"
        // Open on a new page to preserve the search.
        as="a"
        href={getUniversalAdminDashRouterUrl.classEnrollees({ id: workshop.id })}
        target="_blank"
      >
        Class Enrollments
      </Button>
    </ButtonGroup>
  </SecondaryEventCard>
);

export function WorkshopSearchField({
  onSelect,
  value,
  isDisabled = false,
  isResettable = false,
}: {
  onSelect: (selectedWorkshop: Workshop) => void;
  value: Workshop;
  isDisabled?: boolean;
  isResettable?: boolean;
}) {
  // state for search value in select field
  const [searchQuery, setSearchQuery] = useState('');
  const adminGetWorkshopsSearchQuery = useAdminGetWorkshopsSearch(
    { query: searchQuery, notEnded: true },
    { enabled: searchQuery.length > 0, keepPreviousData: searchQuery.length > 0 }
  );
  const workshopSearchResults = adminGetWorkshopsSearchQuery.data || [];

  const handleInputChange = (inputValue: string) => {
    setSearchQuery(inputValue);
  };
  const resetWorkshopSearch = () => {
    onSelect(null);
    setSearchQuery('');
  };

  const handleChange = ({ id: workshopId }: UnpackArray<AdminGetWorkshopsSearch.Response>) => {
    const selectedWorkshop = workshopSearchResults.find(
      ({ id: workshopEntryId }) => workshopId === workshopEntryId
    );
    onSelect(selectedWorkshop);
    setSearchQuery(selectedWorkshop.title);
  };

  return (
    <>
      <ChakraReactSelect
        isDisabled={isDisabled}
        variant="coa-main"
        options={workshopSearchResults}
        onInputChange={handleInputChange}
        onChange={handleChange}
        blurInputOnSelect
        placeholder="Search for a class"
        noOptionsMessage={() => (searchQuery ? 'No class found' : 'Search classes by title')}
        value={value}
        inputValue={searchQuery}
        isLoading={adminGetWorkshopsSearchQuery.isLoading}
        getOptionLabel={({ id, title }) => `${id} - ${title}`}
      />
      {value ? (
        <AdminWorkshopSearchResult
          workshop={value}
          resetSearch={resetWorkshopSearch}
          isResettable={isResettable}
        />
      ) : null}
    </>
  );
}
