import { FormLabel, useDisclosure } from '@chakra-ui/react';
import { MediaImageRecord } from '@coa/api/models/mediaImage';
import { WidgetProps } from '@rjsf/core';
import React, { ReactElement, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import { ReactQuillStylesProvider } from '../../../../lib/quill';
import { AdminCmsMediaImageIdModal } from './AdminCmsReferenceIdWidget';

const reactQuillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'height',
  'width',
  'style',
  'image',
  'align',
];

export const AdminCmsRichTextFieldWidgetEditor = ({
  onChange: onCmsValueChange,
  value: rjsfWidgetValue,
}: WidgetProps): ReactElement => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const quillEditorRef = useRef(null);

  const imageHandler = () => {
    onOpen();
  };

  const onReactQuillValueChange = (htmlValue: string) => {
    onCmsValueChange(htmlValue);
  };

  const onCmsImageSelectionChange = (mediaImageId: MediaImageRecord['id']) => {
    const { current: quillObj } = quillEditorRef;
    // With the launch of the modal, the quill-editor will have lost focus.
    // As such, in order to properly access the selection range, we need
    // to re-focus so that we have the accurate cursor position.
    quillObj.focus();
    const range = quillObj.getEditor().getSelection();
    quillObj
      .getEditor()
      .insertEmbed(range.index, 'image', `/v1/media_images/${mediaImageId}/asset`);
    // Advance the cursor past the inserted image
    quillObj.getEditor().setSelection(range.index + 1, 0);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [false] }],
          ['bold', 'italic', 'underline'],
          ['image'],
          [{ align: '' }, { align: 'center' }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <ReactQuillStylesProvider>
      <ReactQuill
        ref={(el) => {
          quillEditorRef.current = el;
        }}
        defaultValue={rjsfWidgetValue}
        modules={modules}
        formats={reactQuillFormats}
        onChange={onReactQuillValueChange}
      />
      <AdminCmsMediaImageIdModal
        isOpen={isOpen}
        onClose={onClose}
        onChange={(mediaImageId: MediaImageRecord['id']) => {
          onCmsImageSelectionChange(mediaImageId);
          onClose();
        }}
      />
    </ReactQuillStylesProvider>
  );
};

export const AdminCmsRichTextFieldWidget = (props: WidgetProps) => {
  const { label, schema, id } = props;
  return (
    <>
      <FormLabel>{schema.title || label || id.replace(/^root_/, '')}</FormLabel>
      <AdminCmsRichTextFieldWidgetEditor {...props} />
    </>
  );
};
