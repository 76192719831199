import { Flex, HStack, Link, StackProps, Text, TextProps } from '@chakra-ui/react';
import React from 'react';
import { useGlobalLayoutConfig } from './GlobalLayoutProvider';

export const LegalLinks = ({
  color = 'gray.500',
  ...props
}: StackProps & { color?: TextProps['color'] }) => (
  <HStack spacing={3} {...props}>
    <Text color={color}>© {new Date().getFullYear()} Coa</Text>
    <Link
      color={color}
      target="_blank"
      href="https://www.joincoa.com/app-terms-of-service"
      variant="body2"
    >
      Terms of Use
    </Link>
    <Link
      color={color}
      target="_blank"
      href="https://www.joincoa.com/app-privacy-policy"
      variant="body2"
    >
      Privacy Policy
    </Link>
  </HStack>
);

export const LegalLinksParent = ({ children }: { children: React.ReactNode }) => {
  const { globalLayoutConfig } = useGlobalLayoutConfig();
  const { hideGlobalLegalLinks } = globalLayoutConfig;
  return !hideGlobalLegalLinks ? (
    <Flex
      display={{ sm: 'none', md: 'flex' }}
      align="center"
      justify="center"
      flexGrow={1}
      flexShrink={1}
    >
      {children}
    </Flex>
  ) : null;
};
