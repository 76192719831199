import {
  AdminDashRouterPathParams,
  adminDashRouterPaths,
  getAdminDashRouterUrl,
} from './adminDashRouterPaths';
import {
  ClassesRouterPathParams,
  classesRouterPaths,
  getClassesRouterUrl,
} from './classesRouterPaths';
import {
  getOnDemandRouterUrl,
  OnDemandRouterPathParams,
  onDemandRouterPaths,
} from './onDemandRouterPaths';
import { getTryRouterUrl, TryRouterPathParams, tryRouterPaths } from './tryRouterPaths';

export const routerPaths = {
  adminDash: adminDashRouterPaths,
  classes: classesRouterPaths,
  onDemand: onDemandRouterPaths,
  try: tryRouterPaths,
};

export declare namespace RouterPathParams {
  type AdminDash = AdminDashRouterPathParams;
  type Classes = ClassesRouterPathParams;
  type OnDemand = OnDemandRouterPathParams;
  type Try = TryRouterPathParams;
}

export const getRouterUrl = {
  adminDash: getAdminDashRouterUrl,
  classes: getClassesRouterUrl,
  onDemand: getOnDemandRouterUrl,
  try: getTryRouterUrl,
};
