import { Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import { ClassListing } from '../../../components/molecules/ClassListing';
import { TransformUpLink } from '../../../components/molecules/TransformUpLink';
import { WorkshopApplyCta, WorkshopCta } from '../../../components/WorkshopCta';
import { WorkshopOccurrenceExerciseCta } from '../../../components/WorkshopExerciseCta';
import {
  createGetWorkshopPlaceholderData,
  GetSeriesWorkshops,
  useGetWorkshopQuery,
} from '../../../resources/workshops';
import { formatWorkshopTimeLabel, getWorkshopHasStarted } from '../../../resources/workshops/utils';

export type UpcomingWorkshopProps = {
  upcomingClasses: GetSeriesWorkshops.Response;
  isLoading: boolean;
  displayExerciseCta?: boolean;
  heading?: string;
};

export const UpcomingWorkshop = ({
  upcomingClasses,
  displayExerciseCta,
  heading,
}: UpcomingWorkshopProps) => {
  const firstUpcomingWorkshopId = upcomingClasses?.[0].id;
  const placeholderData = createGetWorkshopPlaceholderData({ id: firstUpcomingWorkshopId });
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: firstUpcomingWorkshopId },
    { placeholderData }
  );
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;
  const {
    meta: { enrollmentStatus },
  } = workshop;

  const seriesHasStarted = getWorkshopHasStarted(workshop);

  const ctaProps = {
    size: 'md',
    width: { base: '100%', lg: 'auto' },
    variant: 'primary',
  };

  return (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
      width="100%"
      backgroundColor="gray.50"
    >
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <Skeleton isLoaded={!isLoading} height="100%">
          <TransformUpLink
            as={RouterLink}
            to={`/classes/${workshop.id}`}
            height="100%"
            width="100%"
          >
            <ClassListing
              label={workshop.marketListingLabel}
              title={workshop.title}
              date={formatWorkshopTimeLabel(workshop, workshop.workshopOccurrences)}
              src={workshop.imageUrl}
              colorScheme="gray"
              instructorName={workshop.therapist.name}
              instructorImage={workshop.therapist.imageUrl}
            />
          </TransformUpLink>
        </Skeleton>
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 6, lg: 8 }} pl={{ base: 0, md: 8 }}>
        <Skeleton
          isLoaded={!isLoading}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          data-cy={`upcoming-workshop-${isLoading ? 'loading' : 'loaded'}`}
        >
          {heading ? (
            <Heading size="md" mb={{ base: 0, md: 6 }} mt={{ base: 6, md: 0 }}>
              {heading}
            </Heading>
          ) : null}
          <Text fontSize="md" mb={8} mt={{ base: 6, md: 0 }}>
            {workshop.description}
          </Text>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            justifyContent="flex-end"
          >
            {displayExerciseCta ? (
              <WorkshopOccurrenceExerciseCta
                // This component manages its own loading state, so we can just render
                workshopId={workshop.id}
                exerciseId={workshop.workshopOccurrences[0].exerciseId}
                occurrenceStartsAt={workshop.workshopOccurrences[0].startsAt}
                variant="outline"
                size="md"
                p={2}
                mr={{ base: 0, lg: 4 }}
                mb={{ base: 4, lg: 0 }}
                width={{ base: '100%', lg: 'auto' }}
              />
            ) : null}

            {!enrollmentStatus && !seriesHasStarted ? (
              <WorkshopApplyCta workshopId={workshop.id} {...ctaProps} />
            ) : (
              <WorkshopCta
                // This component manages its own loading state, so we can just render
                isLoading={isLoading}
                variant="primary"
                workshopId={workshop.id}
                size="md"
                p={2}
                justifyContent="center"
                width={{ base: '100%', lg: 'auto' }}
              />
            )}
          </Flex>
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
