import React from 'react';
import { Elements } from 'react-stripe-elements';

const fonts = [
  {
    family: 'Founders',
    src: 'url(https://coa-fonts.s3-us-west-1.amazonaws.com/FoundersGroteskWeb-Regular.woff)',
    weight: 'bold',
  },
];

export function StripeElementsFontProvider({ children }: { children: JSX.Element }) {
  return <Elements fonts={fonts}>{children}</Elements>;
}
