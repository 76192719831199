import { Box, Button, ButtonGroup, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { teamMemberRole } from '@coa/api/controllers/v1/teamMemberships/types';
import Papa from 'papaparse';
import React, { Reducer, useReducer, useRef } from 'react';
import * as Yup from 'yup';
import { useBulkPostTeamMembersMutation } from '../../../../resources/teams';

type AddTeamMembersCsvRow = {
  Name: string;
  Email: string;
};

type ReducerState = {
  csvRowData: AddTeamMembersCsvRow[] | null;
  csvRowErrors: string[] | null;
  submitting: boolean;
  submitError: boolean;
  submitSuccess: boolean;
};

type ReducerAction =
  | { type: 'SET_CSV_ROW_DATA'; csvRowData: ReducerState['csvRowData'] }
  | { type: 'SET_CSV_ROW_ERRORS'; csvRowErrors: ReducerState['csvRowErrors'] }
  | { type: 'SUBMIT_INIT' }
  | { type: 'SUBMIT_SUCCESS' }
  | { type: 'SUBMIT_ERROR' };

const INITIAL_STATE: ReducerState = {
  csvRowData: null,
  csvRowErrors: null,
  submitting: false,
  submitSuccess: false,
  submitError: false,
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'SET_CSV_ROW_DATA': {
      return {
        ...state,
        csvRowData: action.csvRowData,
      };
    }
    case 'SET_CSV_ROW_ERRORS': {
      return {
        ...INITIAL_STATE,
        csvRowErrors: action.csvRowErrors,
      };
    }
    case 'SUBMIT_INIT':
      return {
        ...state,
        submitting: true,
        submitError: false,
        submitSuccess: false,
      };
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
      };
    case 'SUBMIT_ERROR':
      return {
        ...state,
        submitting: false,
        submitError: true,
      };
    default:
      return state;
  }
};

const TeamMemberValidationSchema = Yup.object().shape({
  Name: Yup.string().required(),
  Email: Yup.string().email().required(),
});
const BulkUploadTeamMembersValidationSchema = Yup.array().of(TeamMemberValidationSchema);

const validateTeamMembersCsvData = (csvData: ReducerState['csvRowData']) => {
  try {
    return BulkUploadTeamMembersValidationSchema.validateSync(csvData, {
      abortEarly: false,
    });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return {
        errors: error.errors,
      };
    }
  }
};

const formatTeamMemberCsvRowErrorStr = (errorStr: string) => {
  const [errorRow, errorDesc] = errorStr.split('.');
  const errorRowIndexStr = errorRow.replace(/[|[\]+,]/g, ''); // strip off []
  const errorRowIndex = Number(errorRowIndexStr);
  return `Row: ${errorRowIndex + 1}: ${errorDesc}`;
};

export const TeamMembersCsvUploadInput = ({
  onCancel,
  id,
}: {
  id: string;
  onCancel: () => void;
}) => {
  const bulkPostTeamMembersMutation = useBulkPostTeamMembersMutation({ id });
  const csvInputRef = useRef(null);
  const [state, dispatch] = useReducer<Reducer<ReducerState, ReducerAction>>(
    reducer,
    INITIAL_STATE
  );
  const { csvRowData, csvRowErrors, submitting, submitError, submitSuccess } = state;

  const handleOpenFileSelect = () => {
    if (csvInputRef?.current) {
      csvInputRef.current.click();
    }
  };

  const handleParseComplete = ({ data }: { data: ReducerState['csvRowData'] }) => {
    const result = validateTeamMembersCsvData(data);
    if (!Array.isArray(result) && result.errors) {
      dispatch({ type: 'SET_CSV_ROW_ERRORS', csvRowErrors: result.errors });
      return;
    }
    dispatch({ type: 'SET_CSV_ROW_DATA', csvRowData: data });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.files.length) {
      const file = e.currentTarget.files[0];
      if (!file) {
        throw new Error('no file');
      }

      Papa.parse(file, {
        complete: handleParseComplete,
        header: true,
      });
    }
  };

  const handleSubmitCsvRowData = async () => {
    dispatch({ type: 'SUBMIT_INIT' });
    try {
      const csvRowDataForMemberCreation = csvRowData.map(({ Name, Email }) => ({
        name: Name,
        email: Email,
        role: teamMemberRole.basic,
        signupKind: 'corporate',
      }));
      await bulkPostTeamMembersMutation.mutateAsync({
        rows: csvRowDataForMemberCreation,
      });
      dispatch({ type: 'SUBMIT_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'SUBMIT_ERROR' });
    }
  };

  return (
    <VStack width="full">
      <Flex justify="end" width="full">
        <Button onClick={handleOpenFileSelect}>Select CSV File</Button>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <input
          hidden
          ref={csvInputRef}
          type="file"
          accept=".csv"
          name="members"
          onChange={handleChange}
        />
      </Flex>
      {csvRowData ? (
        <Flex width="full" flexDir="column">
          <Heading fontSize="md">Results: {csvRowData.length} members to be Invited</Heading>
          <Flex
            justifyContent="start"
            maxH="250px"
            overflowY="scroll"
            px={8}
            mt={2}
            bgColor="green.100"
            rounded="lg"
          >
            <ol>
              {csvRowData.map(({ Name, Email }) => (
                <li key={Name}>
                  {Name} / {Email}
                </li>
              ))}
            </ol>
          </Flex>
          <Flex mt={8} width="full" flexDir="column">
            <ButtonGroup width="full" justifyContent="end" display="flex">
              <Button
                variant="primary"
                onClick={handleSubmitCsvRowData}
                isDisabled={submitting || submitSuccess}
                isLoading={submitting}
              >
                Invite Teammates
              </Button>
              <Button variant="secondary" onClick={onCancel}>
                {submitSuccess ? 'Close' : 'Cancel'}
              </Button>
            </ButtonGroup>
            {submitSuccess ? (
              <Box height="full" rounded="lg" bg="green.100" p={4} mt={2}>
                <Text fontSize="md">Bulk Upload of Teammate Queued Successfully!</Text>
                <Text fontSize="sm">
                  Please see Slack Channel #admin-cms-notifications for further details.
                </Text>
              </Box>
            ) : null}
            {submitError ? (
              <Box height="full" rounded="lg" bg="red.100" p={4} mt={2}>
                <Text fontSize="md">Error in Bulk Upload</Text>
                <Text fontSize="sm">Please see reach out to tech for assistance.</Text>
              </Box>
            ) : null}
          </Flex>
        </Flex>
      ) : null}
      {csvRowErrors ? (
        <Flex width="full" flexDir="column">
          <Heading fontSize="md">Errors: {csvRowErrors.length} fixes needed</Heading>
          <Flex
            justifyContent="start"
            maxH="250px"
            overflowY="scroll"
            px={8}
            mt={2}
            bgColor="red.100"
            rounded="lg"
          >
            <ol>
              {csvRowErrors.map((errorStr: string) => (
                <li key={errorStr}>{formatTeamMemberCsvRowErrorStr(errorStr)}</li>
              ))}
            </ol>
          </Flex>
          <Flex width="full" justifyContent="end">
            <Button mt={8} variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </VStack>
  );
};
