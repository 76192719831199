import { TeamAccessKind } from '@coa/api/controllers/v1/teams/types';
import { ValueOf } from '@coa/types';
import { Member } from '../member';

export type Team = {
  type: 'team';
  id: string;
  title: string;
  parentTeamId: string | null;
  numberOfSeats?: number;
  accessKind: TeamAccessKind;
  isCurrentMemberAdmin: boolean;
  marketingCampaign?: string;
};

export type SerializedTeam = Team;

export type TopLevelTeam = Team & {
  parentTeamId: null;
};

export type SubTeam = Team & {
  parentTeamId: string;
};

export const teamMemberRole = {
  admin: 'admin',
  basic: 'basic',
} as const;

export const teamMembershipStatus = {
  invited: 'invited',
  registered: 'registered',
} as const;

export type TeamMemberRole = ValueOf<typeof teamMemberRole>;
export type TeamMembershipStatus = ValueOf<typeof teamMembershipStatus>;

export type TeamMembership = {
  id: string;
  role: TeamMemberRole;
  memberId: Member['id'];
  teamId: Team['id'];
};
export type TeamMember = Pick<Member, 'name' | 'email' | 'id' | 'signupKind'> &
  Omit<TeamMembership, 'id' | 'teamId' | 'memberId'> & { status: TeamMembershipStatus };
