import {
  Box,
  Flex,
  Heading,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueryParams, useRouteParams } from '@coa/react-utils';
import { formatPriceCents } from '@coa/stdlib/string';
import { formatCouponLabel } from '@coa/stdlib/string/labels';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { TagIcon } from '../../../components/Icons';
import CoaLogo from '../../../images/logo_coa_c_filled_green.svg';
import { teamMembershipAnalytics } from '../../../lib/analytics/clients/teamMembershipAnalytics';
import { useSession } from '../../../resources/sessions';
import { useGetTeamQuery, usePutTeamMutation } from '../../../resources/teams';
import { SubscribeFormPromoCodeInput } from '../../classes/subscribe/components/SubscribeFormPromoCodeInput';
import { useOrderSummary } from '../../classes/subscribe/components/SubscribeModalOrderSummaryProvider';

export const TeamMembershipOrderSummary = () => {
  const { name, email } = useSession();
  const { id } = useRouteParams<{ id: string }>();
  const history = useHistory();
  const getTeamQuery = useGetTeamQuery({ id });
  const { data: { attributes: { numberOfSeats = 0 } } = { attributes: {} } } =
    getTeamQuery.data || {};
  const {
    getSubscriptionOrderSummaryQuery,
    setRequestedPromoCode,
    postSubscriptionsMutation,
  } = useOrderSummary();
  const { data: orderSummary } = getSubscriptionOrderSummaryQuery;

  const pricePerSeat = 19;
  const minNumberOfSeats = 5;

  let correctedNumberOfSeats = numberOfSeats;
  if (numberOfSeats < minNumberOfSeats) {
    correctedNumberOfSeats = minNumberOfSeats;
  }
  const total = correctedNumberOfSeats * pricePerSeat;
  const yearTotal = total * 12; // total is monthly price, we currently bill yearly

  const renderPriceDescription = () => {
    const basePrice = `${pricePerSeat * minNumberOfSeats}`;
    const priceStrArr = [`$${basePrice}`];
    const billingSchedule = ' monthly (billed yearly)';
    if (numberOfSeats > minNumberOfSeats) {
      const numSeatsOverFive = numberOfSeats - minNumberOfSeats;
      const additionalSeatsDisplayStr = `${numSeatsOverFive}*$${pricePerSeat}`;
      priceStrArr.push(additionalSeatsDisplayStr);
    }
    return `${priceStrArr.join(' + ')} ${billingSchedule}`;
  };

  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');
  const isVGP = campaign?.toLowerCase() === 'vgp';
  const couponCodeIsActive = dayjs().isBefore(dayjs('2022-12-05'));

  const discountAmount = orderSummary.discount?.amountOff * -1 * correctedNumberOfSeats;
  const totalWithDiscount = _.isUndefined(orderSummary.discount)
    ? orderSummary.priceCents * correctedNumberOfSeats
    : orderSummary.discount.temporaryPriceCents * correctedNumberOfSeats;

  const putTeamMutation = usePutTeamMutation({ id });
  useEffect(() => {
    const updateTeamAccessKind = async () => {
      await putTeamMutation.mutateAsync({ accessKind: 'membership' });
      history.push(`/account/teams/${id}`);
    };
    if (postSubscriptionsMutation.isSuccess) {
      teamMembershipAnalytics.track('Submitted Team Membership - Payment Form', {
        teamId: id,
        totalPayment: totalWithDiscount,
        email,
        name,
      });
      updateTeamAccessKind();
    }
  }, [postSubscriptionsMutation.isSuccess]);

  // TODO: figure out what to do for invalid teamId's
  return (
    <VStack mb={4} width="full" alignItems="flex-start" mt={4}>
      <Heading size="md">Become a Member</Heading>
      <Text fontWeight="bold">Pricing</Text>
      <Text marginTop={0}>$95 for teams of up to 5 employees + $19 per additional employee</Text>
      <HStack width="full">
        <CoaLogo height={50} width={50} />
        <HStack flex={1} justify="space-between">
          <VStack alignItems="flex-start">
            <Text fontSize="24px" lineHeight="24px">
              {correctedNumberOfSeats} Memberships
            </Text>
            <Text fontSize="16px" lineHeight="24px">
              {renderPriceDescription()}
            </Text>
          </VStack>
          <Text fontWeight="bold">${yearTotal}.00</Text>
        </HStack>
      </HStack>
      {isVGP && couponCodeIsActive ? (
        <Text as="span" fontWeight="bold" mb={2}>
          Use{' '}
          <Text as="span" fontWeight="bold" color="red.400">
            VGPLAUNCH25
          </Text>{' '}
          at checkout to get 25% off.
          <br />
          <Text as="i" fontWeight="normal">
            Offer now available until 12/4 at midnight!
          </Text>
        </Text>
      ) : null}
      {orderSummary.promotionCode?.active ? (
        <Flex alignItems="space-between" mt={4} w="full">
          <Box flexGrow={999}>
            <Tag bg="evergreen.300" p={2} variant="solid">
              <TagLeftIcon as={TagIcon} />
              <TagLabel color="white" fontSize="md">
                {orderSummary.promotionCode.code}
              </TagLabel>
              <TagCloseButton
                onClick={() => {
                  setRequestedPromoCode(undefined);
                }}
              />
            </Tag>
            <Text color="grayAlpha.700">{formatCouponLabel(orderSummary.coupon)}</Text>
          </Box>
          <Text letterSpacing="0.5px" color="graphAlpha.700">
            {formatPriceCents(discountAmount)}
          </Text>
        </Flex>
      ) : (
        <SubscribeFormPromoCodeInput />
      )}
      <HStack w="full" alignItems="space-between">
        {orderSummary.promotionCode?.active ? (
          <>
            <Box flexGrow={999}>
              <Text fontSize="lg">
                <strong>Total: </strong>
              </Text>
            </Box>
            <Box>
              <Text fontSize="lg" letterSpacing="0.5px">
                <strong>{formatPriceCents(totalWithDiscount)}</strong>
              </Text>
            </Box>
          </>
        ) : null}
      </HStack>
    </VStack>
  );
};
