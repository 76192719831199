import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { titleCase } from '@coa/stdlib/string';
import { EmptyObject } from '@coa/types';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../../lib/react-query';
import {
  TeamMember,
  teamMemberRole,
  useGetTeamQuery,
  usePutTeamMembersMutation,
} from '../../../../resources/teams';

export const ChangeTeamMemberRoleModal = ({
  isOpen,
  onClose,
  member = {},
  id,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & {
  id: string;
  member?: TeamMember | EmptyObject | undefined;
}) => {
  const rawPutTeamMembersMutation = usePutTeamMembersMutation({ id, memberId: member.id });
  const putTeamMembersMutation = useMutationWithTimer(rawPutTeamMembersMutation, {
    duration: 500,
  });
  const { isLoadingWithTimer } = putTeamMembersMutation;
  const teamQuery = useGetTeamQuery({ id });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    // If the user re-launches, the state will be clean.
    putTeamMembersMutation.resetWithTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (teamQuery.isLoading) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" variant="coa-main">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Change Team Member Role</Heading>
        </ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <Formik
          initialValues={{
            role: member.role,
          }}
          validationSchema={Yup.object().shape({
            role: Yup.string().oneOf(
              Object.values(teamMemberRole).filter((role) => role !== member.role)
            ),
          })}
          isInitialValid={false}
          onSubmit={async ({ role }) => {
            try {
              await putTeamMembersMutation.mutateAsyncWithTimer({ role });
              handleClose();
              toast({
                title: 'Success!',
                description: `Team member updated successfully.`,
                duration: 2000,
                isClosable: true,
                status: 'success',
              });
            } catch (err) {
              toast({
                title: 'Something went wrong.',
                description: 'Please try again.',
                duration: 2000,
                isClosable: true,
                status: 'error',
              });
            }
          }}
        >
          {({ isValid, values }) => (
            <Form>
              <ModalBody>
                <RadioGroup value={values.role} variant="coa-main" width="stretch">
                  <VStack align="left" width="stretch" justifyContent="start">
                    {Object.values(teamMemberRole).map((roleKind) => (
                      <Field type="radio" as={Radio} name="role" value={roleKind}>
                        {titleCase(roleKind)}
                      </Field>
                    ))}
                  </VStack>
                </RadioGroup>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    isLoading={isLoadingWithTimer}
                    isDisabled={!isValid}
                    type="submit"
                  >
                    Update Role
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
