import { Flex, useToast } from '@chakra-ui/react';
import { useOnMount } from '@coa/react-utils';
import { Form } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { tryQAndAAnalytics } from '../../../lib/analytics/clients/tryQAndAAnalytics';
import {
  getCanYouEnrollInWorkshop,
  usePostWorkshopEnrollmentMutation,
} from '../../../resources/workshops';
import { TryWorkshopInstanceStaticContent } from '../components/TryWorkshopInstanceStaticContent';
import {
  TryLayout,
  TryLayoutMainContent,
  TryLayoutSecondaryContent,
} from '../components/TryWorkshopLayout';
import {
  TryWorkshopRegistrationForm,
  TryWorkshopRegistrationFormikContainer,
  TryWorkshopRegistrationFormSubmitButton,
} from '../components/TryWorkshopRegistrationForm';
import {
  TryUserExistsProvider,
  useTryUserExists,
} from '../components/TryWorkshopUserExistsProvider';
import {
  SelectedQAndAInstanceProvider,
  useSelectedQAndAInstance,
} from './components/SelectedQAndAProvider';
import { TryQAndASuccess } from './components/TryQAndASuccess';

export function TryQAndAViewInner() {
  const toast = useToast();
  const contextProps = useSelectedQAndAInstance();
  const { selectedQAndA, qAndAQuery, enrolledInSelectedQAndA } = contextProps;
  const workshopEnrollmentMutation = usePostWorkshopEnrollmentMutation({ id: selectedQAndA?.id });
  const { userExists } = useTryUserExists();

  useOnMount(() => {
    tryQAndAAnalytics.track('Viewed Try Q&A (instance)');
  });

  useEffect(() => {
    if (workshopEnrollmentMutation.isSuccess) {
      tryQAndAAnalytics.track('Enrolled in Q&A', { workshopId: selectedQAndA?.id });
    }
  }, [workshopEnrollmentMutation]);

  const handleRegistrationSuccess = useCallback(async () => {
    try {
      await workshopEnrollmentMutation.mutateAsync({
        answers: {},
      });
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);

  if (qAndAQuery.isLoading) return null;
  const showSuccessState = workshopEnrollmentMutation.isSuccess;
  const workshop = qAndAQuery.data;
  const qAndAIsValid = getCanYouEnrollInWorkshop(workshop, { workShopType: 'q_and_a' });
  const layoutHeight = qAndAIsValid ? 700 : 660;

  return (
    <TryLayout>
      <TryLayoutMainContent height={layoutHeight}>
        <TryWorkshopInstanceStaticContent
          workshop={workshop}
          type="q_and_a"
          workshopIsValid={qAndAIsValid}
        />
      </TryLayoutMainContent>
      <TryLayoutSecondaryContent height={layoutHeight}>
        {qAndAIsValid ? (
          <Flex justifyContent="center" flexDir="column">
            {showSuccessState ? (
              <TryQAndASuccess selectedWorkshop={workshop} />
            ) : (
              <TryWorkshopRegistrationFormikContainer
                userExists={userExists}
                handleRegistrationSuccess={handleRegistrationSuccess}
              >
                <Flex as={Form} justifyContent="center" flexDir="column">
                  <TryWorkshopRegistrationForm
                    isB2B={workshop.isB2B}
                    enrolledInSelectedWorkshop={enrolledInSelectedQAndA}
                    workshopType="q_and_a"
                  />
                  <TryWorkshopRegistrationFormSubmitButton
                    colorScheme="red"
                    type="submit"
                    size="lg"
                    enrolledInSelectedWorkshop={enrolledInSelectedQAndA}
                    workshopType="q_and_a"
                  />
                </Flex>
              </TryWorkshopRegistrationFormikContainer>
            )}
          </Flex>
        ) : null}
      </TryLayoutSecondaryContent>
    </TryLayout>
  );
}

export function TryQAndAInstanceView() {
  return (
    <SelectedQAndAInstanceProvider>
      <TryUserExistsProvider>
        <TryQAndAViewInner />
      </TryUserExistsProvider>
    </SelectedQAndAInstanceProvider>
  );
}
