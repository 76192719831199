import _ from 'lodash';
import {
  WORKSHOPS_CREATE_SUCCESS,
  WORKSHOPS_LIST_FAILURE,
  WORKSHOPS_LIST_REQUEST,
  WORKSHOPS_LIST_SUCCESS,
  WORKSHOPS_UPDATE_SUCCESS,
  WORKSHOP_OCCURRENCES_LIST_SUCCESS,
} from '../../actions/admin/workshops';
import { WORKSHOP_DETAILS_SUCCESS } from '../../actions/workshops';

// type ReducerShape = {
//   gettingWorkshops: ?boolean,
//   workshops: {
//     [id]: Workshop,
//   },
//   workshopOccurrences: {
//     [id]: Array<WorkshopOccurrence>,
//   },
//   workshopPages: Number,
// }

const DEFAULT_STATE = {
  gettingWorkshops: null,
  workshops: {},
  workshopOccurrences: {},
  workshopPages: 0,
};

export function workshops(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case WORKSHOPS_LIST_REQUEST: {
      return { ...state, gettingWorkshops: true };
    }
    case WORKSHOPS_LIST_SUCCESS: {
      const { workshopOccurrences } = state;
      const {
        response: { data, included, meta },
      } = action;
      included.forEach((i) => {
        if (i.type === 'workshop_occurrence') {
          const wId = i.attributes.workshop_id;
          workshopOccurrences[wId] = workshopOccurrences[wId] || [];
          if (_.findIndex(workshopOccurrences[wId], (o) => o.id === i.id) === -1) {
            workshopOccurrences[wId].push(i);
          }
        }
      });
      return {
        ...state,
        workshopOccurrences,
        workshops: _.keyBy(data, 'id'),
        workshopPages: meta.total_pages,
        gettingWorkshops: false,
      };
    }
    case WORKSHOP_DETAILS_SUCCESS: {
      const {
        response: { data },
      } = action;
      const { workshops: workshopsState } = state;
      workshopsState[data.id] = data;
      return { ...state, workshops: workshopsState };
    }
    case WORKSHOPS_LIST_FAILURE: {
      return { ...state, gettingWorkshops: false };
    }
    case WORKSHOPS_UPDATE_SUCCESS:
    case WORKSHOPS_CREATE_SUCCESS: {
      const { included } = action.response;
      const { workshops: workshopsState, workshopOccurrences } = state;
      workshopsState[action.response.data.id] = action.response.data;
      workshopOccurrences[action.response.data.id] = _.chain(included)
        .filter((x) => x.type === 'workshop_occurrence')
        .value();
      return { ...state, workshops: workshopsState, workshopOccurrences };
    }
    case WORKSHOP_OCCURRENCES_LIST_SUCCESS: {
      const { included } = action.response;
      const { workshops: workshopsState, workshopOccurrences } = state;
      workshopsState[action.response.data.id] = action.response.data;
      workshopOccurrences[action.response.data.id] = _.chain(included)
        .filter((x) => x.type === 'workshop_occurrence')
        .value();
      return { ...state, workshops: workshopsState, workshopOccurrences };
    }
    default:
      return state;
  }
}
