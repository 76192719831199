import { randomId, randomInt } from '@coa/stdlib/random';
import { OnDemandVideoCheckpoint } from '../onDemandVideoCheckpoints';
import { createPlaceholderTherapist } from '../workshops';
import { OnDemandVideo } from './types';

export const createPlaceholderOnDemandVideo = (): OnDemandVideo =>
  ({
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu mauris sed lorem consequat viverra blandit a arcu. Maecenas quis varius magna. Nunc non consectetur nisi. Duis rutrum scelerisque urna, vulputate volutpat arcu viverra ut. Ut feugiat tempus efficitur. Etiam sit amet posuere elit. Nam enim sapien, vulputate id consectetur et, volutpat a nunc. Quisque pulvinar porta bibendum.',
    hostedEmbedUrl: 'https://vimeo.com/',
    id: randomId(),
    title: 'Adulting',
    thumbnailUrl: '',
    therapist: createPlaceholderTherapist(),
  } as const);

export const createPlaceholderOnDemandVideoCheckpoint = (): OnDemandVideoCheckpoint =>
  ({
    id: randomId(),
    onDemandVideo: createPlaceholderOnDemandVideo(),
    title: 'Why does being an adult feel so hard?',
    timestampSeconds: randomInt(),
    therapist: createPlaceholderTherapist(),
  } as const);
