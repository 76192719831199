import { ValueOf } from '@coa/types';
import { Member } from '../member';
import { Team } from '../teams/types';

export const teamMemberRole = {
  admin: 'admin',
  basic: 'basic',
} as const;

export const teamMembershipStatus = {
  invited: 'invited',
  registered: 'registered',
} as const;

export type TeamMemberRole = ValueOf<typeof teamMemberRole>;
export type TeamMembershipStatus = ValueOf<typeof teamMembershipStatus>;

export type TeamMembership = {
  id: string;
  role: TeamMemberRole;
  memberId: Member['id'];
  teamId: Team['id'];
};
export type TeamMember = Pick<Member, 'name' | 'email' | 'id'> &
  Omit<TeamMembership, 'id' | 'teamId' | 'memberId'> & { status: TeamMembershipStatus };
