export const REGISTRATIONS_REQUEST = 'REGISTRATIONS_REQUEST';
export const REGISTRATIONS_SUCCESS = 'REGISTRATIONS_SUCCESS';
export const REGISTRATIONS_FAILURE = 'REGISTRATIONS_FAILURE';

export function create(data) {
  return {
    type: REGISTRATIONS_REQUEST,
    api: {
      data,
      endpoint: 'registrations',
      authenticated: false,
      types: [REGISTRATIONS_REQUEST, REGISTRATIONS_SUCCESS, REGISTRATIONS_FAILURE],
      method: 'POST',
    },
  };
}
