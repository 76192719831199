import { Box, Container, Flex, Heading, Tab, TabList, Tabs } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { matchPath, Route, useLocation } from 'react-router';
import InvoiceAwaitingPaymentBanner from '../../components/InvoiceAwaitingPaymentBanner';
import { GenericSubscribeModal } from '../classes/subscribe/GenericSubscribeModal';
import { WorkshopsBreadcrumb } from '../classes/WorkshopsBreadcrumb';
import { PastClassesView } from './PastClassesView';
import { ScheduleView } from './ScheduleView';

export const MyCoaView = () => {
  const { pathname } = useLocation();

  const navEntries = _.filter([
    { children: 'Schedule', path: '/my-coa' },
    { children: 'Past Classes', path: '/my-coa/past-classes' },
  ]);

  return (
    <Box w="100%" pt={{ base: 2, md: 8 }}>
      <InvoiceAwaitingPaymentBanner mb={4} />
      <Container maxWidth="container.xl" px={{ base: 4, md: 8, xl: 0 }}>
        <WorkshopsBreadcrumb mb={4} mt={{ base: 2, md: 0 }} />
        <Box>
          <Heading>My Coa</Heading>
          <Tabs
            display="flex"
            index={_.findIndex(navEntries, ({ path }) =>
              Boolean(matchPath(pathname, { path, exact: true }))
            )}
            mb={6}
            size="lg"
          >
            <TabList width="100%">
              {navEntries.map((tab, index) => (
                <Tab
                  mr={index === navEntries.length - 1 ? 0 : 8}
                  px={0}
                  fontSize={{ base: 'md', md: 'lg' }}
                  key={tab.children}
                  as={RouterLink}
                  to={tab.path}
                  data-cy={`my-coa-tab-${_.kebabCase(tab.children)}`}
                >
                  {tab.children}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Box>

        <Flex minHeight="60vh" display="flex" flexDirection="column" alignItems="center">
          <Route exact path={['/my-coa', '/my-coa/sign-up']} component={ScheduleView} />
          <Route path="/my-coa/past-classes" component={PastClassesView} />
        </Flex>
      </Container>
      <GenericSubscribeModal />
    </Box>
  );
};
