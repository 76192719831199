const ROUTES_BY_ROLE = {
  therapist: '/clients',
  client: '/classes',
  admin: '/admin-dash/classes',
};
export const DefaultRoute = {
  for: (member) => {
    if (!member) return '/join';
    return ROUTES_BY_ROLE[member.role];
  },
};
