import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import { useGetTeamMembershipsQuery } from '@coa/api/controllers/v1/teamMemberships';
import { RouterLink, useRouteParams } from '@coa/react-utils';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { ArrowRightIcon } from '../../../components/Icons';
import { teamMembershipAnalytics } from '../../../lib/analytics/clients/teamMembershipAnalytics';
import { useSession } from '../../../resources/sessions';

function ConditionalTeamsRedirect({ children }: Pick<RouteProps, 'children'>) {
  const { loggedIn } = useSession();
  const routeParams = useRouteParams<{ token?: string }>();
  const { token } = routeParams;

  if (loggedIn) return <>{children}</>;

  return (
    <Redirect
      to={
        token
          ? `/teams/claim-account/${token}?redirect_to=/teams/welcome`
          : `/teams/log-in?redirect_to=/teams/welcome`
      }
    />
  );
}

function TeamsWelcomeDescriptionText(props: TextProps): JSX.Element {
  const { name, email } = useSession();
  const getTeamMembershipsQuery = useGetTeamMembershipsQuery();

  const { data: teamMemberships = [] } = getTeamMembershipsQuery;
  const teams = teamMemberships.map(({ team }) => team);
  const teamsById = _.keyBy(teams, 'id');

  const topLevelMemberships = teamMemberships.filter(
    ({ team: { id } }) => !teamsById[id].parentTeamId
  );

  useEffect(() => {
    if (getTeamMembershipsQuery.isSuccess) {
      if (topLevelMemberships.length === 1) {
        const teamMembership = topLevelMemberships[0];
        const { team } = teamMembership;
        teamMembershipAnalytics.track('Team Membership - Team-mate Invitation Claimed', {
          name,
          email,
          teamId: team.id,
          teamName: team.title,
        });
      } else {
        // TODO: Tell Sentry something because we assume that there's only one
        // eslint-disable-next-line no-console
        console.error('Only one team membership is supported');
      }
    }
  }, [getTeamMembershipsQuery, topLevelMemberships]);

  if (getTeamMembershipsQuery.isLoading) return null;

  const [
    {
      team: { id: topLevelTeamId },
    },
  ] = topLevelMemberships;
  const topLevelTeam = teamsById[topLevelTeamId];

  return (
    <Text {...props}>
      You've been enrolled in Coa classes with your team at <strong>{topLevelTeam.title}</strong>.
    </Text>
  );
}

function TeamsWelcomeWhatIsCoa(): JSX.Element {
  return (
    <Container maxW="container.lg" px={{ base: 4, md: 8, xl: 0 }} mt={{ base: 4, md: 0 }}>
      <Grid templateColumns="repeat(5, 1fr)" gap={{ md: 8, xl: 16 }} width="100%">
        <GridItem colSpan={5} textAlign="center">
          <VStack spacing={{ base: 4, md: 8 }} mb={{ base: 8, md: 0 }}>
            <Heading textAlign="center" size="xl">
              Welcome to Coa, your gym for mental health.
            </Heading>

            <TeamsWelcomeDescriptionText fontSize="xl" />

            <Button
              size="lg"
              variant="primary"
              rightIcon={<ArrowRightIcon />}
              as={RouterLink}
              to="/my-coa"
              data-cy="go-to-my-coa"
            >
              My Classes
            </Button>
          </VStack>
        </GridItem>

        <GridItem colSpan={{ base: 5, md: 2 }} mb={{ base: 8, md: 0 }}>
          <Box position="relative">
            <Box>
              <Image
                src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f84f29b192ca05f4af5fcb4_ipad-frame-black.svg"
                position="absolute"
                top={0}
                left={0}
              />
              <Box padding="3.5% 4% 2%">
                <video
                  src="https://coa-video-bucket.s3-us-west-1.amazonaws.com/COA_Video_Final_.mp4"
                  width="100%"
                  loop
                  muted
                  controlsList="nodownload"
                  playsInline
                  autoPlay
                  aria-label="Coa virtual class demo"
                />
              </Box>
            </Box>
            <Image
              src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f84dfc21a80eac1d47d0655_iphone.png"
              position="absolute"
              maxWidth="25%"
              width="150px"
              left="calc(100% - 75px)"
              top="26%"
              display={{ base: 'none', md: 'block' }}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 5, md: 3 }} mb={{ base: 8, md: 0 }}>
          <VStack spacing={4}>
            <Flex alignItems="center">
              <Image
                src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f7d21105dbdd3b8acc36dd0_doodle-faces.svg"
                mr={4}
              />
              <Box>
                <Heading size="lg">Live classes led by therapists</Heading>
                <Text>
                  This isn’t just another online lecture. Our classes are full of interactive
                  exercises and small-group discussions.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="center">
              <Image
                src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f7d21105dbdd393fec36ddd_doodle-notebook.svg"
                mr={4}
              />
              <Box>
                <Heading size="lg">Interactive exercises</Heading>
                <Text>
                  This isn’t just another online lecture. Our classes are full of interactive
                  exercises and small-group discussions.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="center">
              <Image
                src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f7d21105dbdd3f299c36de7_doodle-coffee.svg"
                mr={4}
              />
              <Box>
                <Heading size="lg">Practical takeaways</Heading>
                <Text>
                  This isn’t just another online lecture. Our classes are full of interactive
                  exercises and small-group discussions.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="center">
              <Image
                src="https://assets.website-files.com/5f7d21105dbdd33d29c36d87/5f7d5d8943ac4d98b05c7f27_doodle-home.svg"
                mr={4}
              />
              <Box>
                <Heading size="lg">A community to grow with</Heading>
                <Text>
                  You don’t have to do it alone! Connect with a supportive community of people who
                  also care about emotional fitness.
                </Text>
              </Box>
            </Flex>
          </VStack>
        </GridItem>
      </Grid>
    </Container>
  );
}

export function TeamsWelcomeView() {
  return (
    <ConditionalTeamsRedirect>
      <TeamsWelcomeWhatIsCoa />
    </ConditionalTeamsRedirect>
  );
}
