/*
 * see MDN for navigator:
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard
 * there's feature detection here because navigator.clipboard is non-standard across browsers
 * */
export async function copyToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
}
