import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { show as accountShow } from '../actions/accounts';
import {
  createTherapyMatch,
  listTherapyMatchingAnswers,
  listTherapyMatchingQuestions,
  markTherapyMatchComplete,
  updateTherapyMatchingAnswer,
} from '../actions/therapyMatchingQuestions';
import AccountInfoForm from '../components/AccountInfoForm';
import Loading from '../components/Loading';
import TherapyMatchingQuestion from '../components/TherapyMatchingQuestion';
import TooYoungForTherapyMatching from '../components/TooYoungForTherapyMatching';
import Background from '../images/background.png';
import { SafeAnalytics } from '../lib/analytics';
import { AgeChecker } from '../utils/AgeChecker';

class TherapyMatchingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allQuestionAnswers: {},
      previousQuestionKey: null,
      currentQuestion: null,
      answerValue: null,
      answerValues: [],
      surveyStarted: false,
      tooYoung: false,
      updatedAccountInfo: false,
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.save = this.save.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.updateAnswers = this.updateAnswers.bind(this);
    this.startSurvey = this.startSurvey.bind(this);
    this.renderSurveyStartPage = this.renderSurveyStartPage.bind(this);
  }

  componentDidMount() {
    const { dispatch, memberId } = this.props;
    if (memberId) {
      dispatch(accountShow());
      dispatch(listTherapyMatchingQuestions());
      dispatch(listTherapyMatchingAnswers(memberId));
      window.onpopstate = this.prev;
      SafeAnalytics.track('Viewed Therapy Matching Intro');
      SafeAnalytics.identify(memberId, { viewed_therapy_matching_intro: true });
    }
  }

  componentDidUpdate() {
    const { currentQuestion } = this.state;
    const { questions } = this.props;
    if (questions && questions[0] && !currentQuestion) {
      this.setState({ currentQuestion: questions[0] });
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.onpopstate = () => {};
  }

  save() {
    const {
      currentQuestion,
      previousQuestionKey,
      answerValue,
      answerValues,
      allQuestionAnswers,
    } = this.state;
    const { dispatch, memberId, currentMatch } = this.props;
    const value = currentQuestion.kind === 'checkbox' ? answerValues : answerValue;
    if (value) {
      dispatch(updateTherapyMatchingAnswer(memberId, currentMatch.id, currentQuestion.key, value));
      allQuestionAnswers[currentQuestion.key] = {
        answerValue,
        answerValues,
        previousQuestionKey,
      };
      this.setState({ allQuestionAnswers });
      let nextQuestionKey;
      if (currentQuestion.answers) {
        const answersByKey = _.keyBy(currentQuestion.answers, 'key');
        if (answersByKey[value] && answersByKey[value].next_question) {
          nextQuestionKey = answersByKey[value].next_question;
        }
      }
      this.next(nextQuestionKey);
    }
  }

  updateAnswer(answerValue) {
    this.setState({ answerValue });
  }

  updateAnswers(answerValues) {
    this.setState({ answerValues });
  }

  next(answerSpecificQuestionKey) {
    const { questions, history, dispatch, memberId, currentMatch } = this.props;
    const questionsByKey = _.keyBy(questions, 'key');
    const { currentQuestion, allQuestionAnswers } = this.state;
    const nextQuestionKey = answerSpecificQuestionKey || currentQuestion.next_question;
    const nextQuestion = questionsByKey[nextQuestionKey];
    const answers = allQuestionAnswers[nextQuestionKey];
    this.setState({
      previousQuestionKey: currentQuestion.key,
      currentQuestion: nextQuestion,
      answerValue: answers ? answers.answerValue : null,
      answerValues: answers ? answers.answerValues : [],
    });
    history.push(`/therapy-matching/${nextQuestionKey}`);
    SafeAnalytics.track('Answered Therapy Matching Question');
    if (nextQuestion.kind === 'exit') {
      dispatch(markTherapyMatchComplete(memberId, currentMatch.id, nextQuestion.key));
      SafeAnalytics.track('Completed Therapy Matching', { exit_question: nextQuestion.key });
    }
  }

  prev() {
    const { history } = this.props;
    const { previousQuestionKey, allQuestionAnswers } = this.state;
    if (previousQuestionKey) {
      const { questions } = this.props;
      const answers = allQuestionAnswers[previousQuestionKey];
      this.setState({
        currentQuestion: _.keyBy(questions, 'key')[previousQuestionKey],
        previousQuestionKey: answers.previousQuestionKey,
        answerValues: answers.answerValues,
        answerValue: answers.answerValue,
      });
      history.push(`/therapy-matching/${previousQuestionKey}`);
    }
  }

  startSurvey() {
    const {
      account: {
        attributes: { birthday },
      },
    } = this.props;
    if (AgeChecker.isMinor(birthday)) {
      this.setState({ tooYoung: true });
    } else {
      const { dispatch, memberId } = this.props;
      dispatch(createTherapyMatch(memberId));
      this.setState({ surveyStarted: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderSurveyStartPage() {
    return (
      <Flex width="stretch" maxW={{ md: '80%' }} minHeight={{ sm: '50rem', md: 'auto' }}>
        <Box
          m={4}
          p={4}
          width="stretch"
          flexGrow="1"
          backgroundImage={`url(${Background})`}
          bgSize="cover"
          display={{ sm: 'none', md: 'grid' }}
        />
        <Box flexGrow="2" m={4} p={4}>
          <Heading>Therapy Matching</Heading>
          <Box
            height={200}
            width="stretch"
            flexGrow="1"
            backgroundImage={`url(${Background})`}
            bgSize="cover"
            display={{ sm: 'block', md: 'none' }}
          />
          <Text fontSize="2xl" mt={2}>
            Apologies for the Delay
          </Text>
          <Text my={4}>
            We are currently revamping our Therapy Matching system, and want to ensure the process
            is smooth before re-opening match requests. Please reach out to hello@joincoa.com with
            any questions, and thank you for your patience!
          </Text>
        </Box>
      </Flex>
    );
  }

  render() {
    const {
      currentQuestion,
      answerValue,
      answerValues,
      surveyStarted,
      tooYoung,
      updatedAccountInfo,
    } = this.state;
    const {
      loadingQuestions,
      loadingMatches,
      questions,
      dispatch,
      account,
      gettingAccount,
      currentMatch,
    } = this.props;
    if (loadingQuestions || gettingAccount || loadingMatches) return <Loading fillPage />;
    if (!surveyStarted) return this.renderSurveyStartPage();
    if (tooYoung) return <TooYoungForTherapyMatching />;
    const { name, pronouns, email, phone, birthday, city } = account.attributes;
    const anyMissingAttributes = !name || !pronouns || !email || !phone || !birthday || !city;
    if (anyMissingAttributes && !updatedAccountInfo)
      return (
        <Grid
          templateColumns={{ md: '3fr 2fr' }}
          templateRows={{ md: '3fr 1fr' }}
          templateAreas={`"main img" "footer img"`}
          align="stretch"
          justify="stretch"
          width="stretch"
          minHeight={{ sm: '50rem', md: 'auto' }}
        >
          <Box mx={9}>
            <Heading fontSize="md" mt={{ sm: 4, md: 0 }}>
              Please tell us a little about yourself so we know how to communicate with you.
            </Heading>
            <AccountInfoForm
              account={account}
              dispatch={dispatch}
              beforeTherapySurvey
              onSubmission={() => this.setState({ updatedAccountInfo: true })}
            />
          </Box>
          <Grid area="img" backgroundImage={`url(${Background})`} bgSize="cover" />
        </Grid>
      );
    if (!currentMatch) return <Loading fillPage />;
    return (
      <TherapyMatchingQuestion
        question={currentQuestion}
        idx={questions.indexOf(currentQuestion)}
        length={questions.length}
        answerValue={answerValue}
        answerValues={answerValues}
        updateAnswer={this.updateAnswer}
        updateAnswers={this.updateAnswers}
        save={this.save}
        prev={this.prev}
      />
    );
  }
}

TherapyMatchingContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
  }),
  currentMatch: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
  }),
  gettingAccount: PropTypes.bool.isRequired,
  questions: PropTypes.array.isRequired,
  loadingQuestions: PropTypes.bool.isRequired,
  loadingMatches: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  matches: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { memberId } = state.session;
  const { questions, matches, loadingQuestions, loadingMatches, currentMatch } = state.matching;
  const { account, gettingAccount } = state.account;

  return {
    memberId,
    questions,
    matches,
    loadingQuestions,
    loadingMatches,
    currentMatch,
    account,
    gettingAccount,
  };
};
export default connect(mapStateToProps)(TherapyMatchingContainer);
