import { Avatar, Badge, BadgeProps, Flex, FlexProps, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { ColorScheme } from '../../themes/coaTheme';

export type EventListingProps = {
  label: React.ReactNode;
  instructorName: string;
  instructorImage: string;
  colorScheme?: ColorScheme;
  badgeProps?: BadgeProps;
} & FlexProps;

export const EventListing = ({
  label,
  instructorName,
  instructorImage,
  colorScheme = 'gray',
  badgeProps = {},
  ...rest
}: EventListingProps) => (
  <Flex direction="row" alignItems="center" justifyContent="center" {...rest}>
    <Avatar boxSize={8} name={instructorName} src={instructorImage} mr={2} />
    <Flex flexDir="column" alignItems="flex-start" width="100%">
      <Text textAlign="left" color={`${colorScheme}.900`} mb={1} fontWeight="bold">
        {label}
      </Text>
      <Text color={`${colorScheme}.900`} fontWeight="normal">
        {instructorName}
      </Text>
      {!_.isEmpty(badgeProps) ? <Badge ml="auto" {...badgeProps} /> : null}
    </Flex>
  </Flex>
);
