import {
  AspectRatio,
  Box,
  Fade,
  Flex,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  SliderTrackProps,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer, { VimeoPlayerProps } from 'react-player/vimeo';
import {
  TakeoverModal,
  TakeoverModalBody,
  TakeoverModalContent,
} from '../../../../../components/compositions/Takeover';
import {
  MaximizeIcon,
  MinimizeIcon,
  PauseIcon,
  PlayIcon,
  RotateCcwIcon,
} from '../../../../../components/Icons';
import { onDemandAnalytics } from '../../../../../lib/analytics/clients';
import { useFullScreen } from '../../../../../lib/fullScreen';
import { boundPercentInt, createInclusiveBound } from '../../../../../lib/math';
import { FormattedTimeText } from './components/FormattedTimeText';
import { FullPlayerTogglePlay } from './components/FullPlayerTogglePlay';
import { VideoPlayerControlButton } from './components/VideoPlayerControlButton';
import { VolumeButton } from './components/VolumeButton';

type VimeoPlayer = ReactPlayer;

const maxVolume = 1;
const minVolume = 0;
const boundVolume = createInclusiveBound(minVolume, maxVolume);
const useVolume = (initialVolume = 0.7) => {
  const [volume, setRawVolume] = useState<number>(initialVolume);
  const [muted, setMuted] = useBoolean(false);
  const setVolume = (rawTargetVolume: number) => {
    if (muted) setMuted.on();
    setRawVolume(boundVolume(rawTargetVolume));
  };
  const toggleMuted = () => {
    if (volume === 0 && !muted) {
      /*
       * Because muted and volume are managed independently, when
       * the user slides the volume down to 0 manually (rather than
       * just muting), we toggle the volume back on as if they
       * were previously muted, choosing an arbitrary new volume
       * for them.
       */
      setRawVolume(initialVolume);
    } else {
      setMuted.toggle();
    }
  };
  return {
    setVolume,
    volume,
    muted,
    toggleMuted,
  };
};

const LoadedSliderFilledTrack = ({ value, ...rest }: SliderTrackProps & { value: number }) => (
  <SliderTrack
    width={
      // The value of this track is being controlled of the Slider Parent in which it's rendered
      // (because we're rendering multiple tracks). Both of the tracks inherit the same width
      // value, so we use !important to override.
      `${value}% !important`
    }
    bg="whiteAlpha.700"
    {...rest}
  />
);

/*
 * TODO: Use the keyboard to navigate left and right on the slider and
 * everything gets all screwed up.
 */

type VideoPlayerProgressParams = Pick<
  Parameters<VimeoPlayerProps['onProgress']>[0],
  'playedSeconds' | 'loadedSeconds'
>;

type VideoSegmentDurationProps = {
  videoSegmentStartSeconds: number;
  videoSegmentDurationSeconds: number;
};

const useSeek = ({
  playerElement,
  videoSegmentDurationSeconds,
  videoSegmentStartSeconds,
  segmentId,
  progressIntervalSeconds,
}: {
  playerElement: MutableRefObject<VimeoPlayer>;
} & Pick<VideoPlayerProps, 'segmentId' | 'progressIntervalSeconds'> &
  VideoSegmentDurationProps) => {
  const [progress, setProgress] = useState<VideoPlayerProgressParams>(
    {} as VideoPlayerProgressParams
  );
  const [seeking, setSeeking] = useBoolean(false);

  /*
   * The segmentStarted helps us define whether or not the nextVideoCheckpoint
   * component will render at the end of a segment. A bug we were experiencing was when navigating
   * backwards through the checkpoints, segmentEnded was inconsistent - but more importantly unreliable.
   *
   * - By default, segmentStarted is false.
   * - It's then set to true on a handleProgress() callback (i.e. after it's started, which makes sense).
   * - It's then set to false on a useEffect() call when the segment is changing (which makes sense).
   * - Lastly, we use it to infer segmentEnded, and segmentEnded can't be true if the segment never started!
   *
   * This is far from a perfect solution, however we can see that segmentEnded seems a more accurate
   * representation, and is behaving as intended through the use of this additional variable.
   */
  const [segmentStarted, setSegmentStarted] = useBoolean(false);

  const handleSeekChange = (_sliderPercentInt: number) => {
    const newPlayedSeconds =
      (_sliderPercentInt / 100) * videoSegmentDurationSeconds + videoSegmentStartSeconds;
    setProgress((_progress) => ({ ...progress, playedSeconds: newPlayedSeconds }));
  };

  const handleSeekChangeEnd = (_sliderPercentInt: number) => {
    setSeeking.off();
    const newPlayedSeconds =
      (_sliderPercentInt / 100) * videoSegmentDurationSeconds + videoSegmentStartSeconds;
    playerElement.current.seekTo(newPlayedSeconds);
  };

  const handleSeekChangeStart = () => {
    setSeeking.on();
  };

  const handleProgressChange: VimeoPlayerProps['onProgress'] = ({
    /*
     * Technically, react-player exposes other properties here too,
     * but since we don't use them, we explicitly omit them to avoid
     * any confusion.
     */
    playedSeconds: newPlayedSeconds,
    loadedSeconds: newLoadedSeconds,
  }) => {
    if (seeking) return;
    setProgress({ playedSeconds: newPlayedSeconds, loadedSeconds: newLoadedSeconds });
    setSegmentStarted.on();
  };

  useEffect(() => {
    /*
     * If the underlying video segment changes:
     * - Set segmentStarted to false so we do not render the
     * screen for the next checkpoint.
     * - Reset progress so that the segment will start at 0.
     */
    if (playerElement.current && !_.isEmpty(segmentId)) {
      setSegmentStarted.off();
      playerElement.current.seekTo(videoSegmentStartSeconds);
    }
  }, [playerElement.current, segmentId]);

  const { playedSeconds, loadedSeconds } = progress;
  const unboundedVideoSegmentPlayedSeconds = Math.max(
    playedSeconds - videoSegmentStartSeconds || 0,
    0
  );
  const videoSegmentPlayedSeconds =
    unboundedVideoSegmentPlayedSeconds > videoSegmentDurationSeconds
      ? 0
      : unboundedVideoSegmentPlayedSeconds;

  // TODO: This might be wrong?
  const videoSegmentLoadedSeconds = Math.max(loadedSeconds - videoSegmentStartSeconds || 0, 0);

  const sliderPercentInt = playerElement.current
    ? boundPercentInt((videoSegmentPlayedSeconds / videoSegmentDurationSeconds) * 100)
    : 0;
  const loadedSliderPercentInt = playerElement.current
    ? boundPercentInt((videoSegmentLoadedSeconds / videoSegmentDurationSeconds) * 100)
    : 0;

  const segmentEnded =
    playerElement.current &&
    unboundedVideoSegmentPlayedSeconds >= videoSegmentDurationSeconds &&
    segmentStarted &&
    /*
     * The unbounded played value can exceed the duration of the current segment,
     * but only to the extent allowed by the progress interval. If the delta exceeds
     * the progress interval, then the segment has not actually ended but is in
     * the process of "changing" and the video player needs a moment to catch up.
     *
     * This is garbage! Ultimately we've taken on wayyyy too much complexity to
     * handle the VideoSegmentPlayer nonsense and we should intend to simplify
     * the data model to not handle video segments. In the meantime, this "hack"
     * will allow us to confidently assess whether or not a segment has ended.
     *
     * :sigh:
     */
    unboundedVideoSegmentPlayedSeconds < videoSegmentDurationSeconds + progressIntervalSeconds;

  return {
    handleSeekChange,
    handleSeekChangeStart,
    handleSeekChangeEnd,
    handleProgressChange,
    progress,
    setProgress,
    seeking,
    setSeeking,
    sliderPercentInt,
    loadedSliderPercentInt,
    segmentEnded,
    videoSegmentPlayedSeconds,
    videoSegmentLoadedSeconds,
  };
};

const usePlayPause = ({
  playerElement,
  setSeeking,
  sliderPercentInt,
  videoSegmentStartSeconds,
}) => {
  const [playing, setPlaying] = useBoolean(false);

  const handleRestart = () => {
    setSeeking.on();
    const totalVideoDuration = playerElement.current.getDuration();
    const newPlayed = videoSegmentStartSeconds / totalVideoDuration;
    playerElement.current.seekTo(newPlayed);
    setSeeking.off();
    setPlaying.on();
  };

  const handleTogglePlay = () => {
    if (playing) {
      onDemandAnalytics.track('Paused On-Demand Video');
      setPlaying.off();
      return;
    }

    if (sliderPercentInt <= 0) handleRestart();
    onDemandAnalytics.track('Played On-Demand Video');
    setPlaying.on();
  };

  return {
    playing,
    setPlaying,
    handleRestart,
    handleTogglePlay,
  };
};

type VideoPlayerProps = {
  url: string;
  segmentId: string;
  renderSegmentStart?: () => React.ReactNode;
  renderSegmentComplete?: () => React.ReactNode;
  renderVideoComplete?: () => React.ReactNode;
  isLoading: boolean;
  // This prop aligns directly with the `progressInterval` prop from
  // react-player, though we cast to seconds for consistency.
  progressIntervalSeconds?: number;

  preventPlayCondition?: boolean;
  handlePlayPrevented?: () => void;
} & Partial<VideoSegmentDurationProps>;

export const VideoPlayer = ({
  url,
  segmentId,
  videoSegmentStartSeconds = 0,
  videoSegmentDurationSeconds: rawVideoSegmentDurationSeconds,
  renderSegmentStart = () => null,
  renderSegmentComplete = () => null,
  renderVideoComplete = () => null,
  isLoading,
  progressIntervalSeconds = 1,
  preventPlayCondition,
  handlePlayPrevented = _.noop,
}: VideoPlayerProps) => {
  const {
    isOpen: isControlsPanelVisible,
    onOpen: onControlsPanelOpen,
    onClose: onControlsPanelClose,
  } = useDisclosure();

  const playerElement = useRef<ReactPlayer>(null);

  // If we don't explicitly identify a duration, then we assume it's the rest of
  // the video.
  const videoSegmentDurationSeconds =
    _.isUndefined(rawVideoSegmentDurationSeconds) && playerElement.current
      ? playerElement.current.getDuration() - videoSegmentStartSeconds
      : rawVideoSegmentDurationSeconds;

  const {
    handleSeekChange,
    handleSeekChangeStart,
    handleSeekChangeEnd,
    handleProgressChange,
    setSeeking,
    seeking,
    sliderPercentInt,
    loadedSliderPercentInt,
    segmentEnded,
    videoSegmentPlayedSeconds,
    progress,
  } = useSeek({
    playerElement,
    videoSegmentStartSeconds,
    videoSegmentDurationSeconds,
    segmentId,
    progressIntervalSeconds,
  });

  const {
    isOpen: isMobileFullScreenOpen,
    onOpen: onMobileFullScreenOpen,
    onClose: onMobileFullScreenClose,
  } = useDisclosure({});

  const [videoEnded, setVideoEnded] = useState(false);
  const { volume, muted, toggleMuted, setVolume } = useVolume();
  const {
    toggleFullScreen,
    isFullScreen,
    fullScreenParentElementRef,
    supportsFullScreen,
  } = useFullScreen();
  const { handleRestart, handleTogglePlay, playing, setPlaying } = usePlayPause({
    setSeeking,
    playerElement,
    sliderPercentInt,
    videoSegmentStartSeconds,
  });

  const segmentInStartingState = !playing && _.isEqual(sliderPercentInt, 0);

  const videoInEndedState =
    videoEnded && _.isEqual(progress.playedSeconds, progress.loadedSeconds) && !playing;

  const handleVideoEnded = useCallback(() => {
    setVideoEnded(true);
    setPlaying.off();
  }, []);

  const handleMobileFullScreenClose = () => {
    handleVideoEnded();
    onMobileFullScreenClose();
  };

  if (isLoading) {
    return (
      <Skeleton>
        <AspectRatio position="relative" ratio={640 / 360} w="100%">
          {/* // AspectRatio requires a single element child to render */}
          <Box />
        </AspectRatio>
      </Skeleton>
    );
  }

  const conditionalHandleTogglePlay = preventPlayCondition ? handlePlayPrevented : handleTogglePlay;

  return (
    <>
      <AspectRatio
        ref={fullScreenParentElementRef}
        position="relative"
        ratio={640 / 360}
        w="100%"
        bgColor="black"
      >
        <Box
          height="100%"
          width="100%"
          position="relative"
          onMouseEnter={onControlsPanelOpen}
          onMouseLeave={onControlsPanelClose}
          onTouchStart={onMobileFullScreenOpen}
        >
          {/* We only want to render the following when the user's device supports full screen */}
          {supportsFullScreen ? (
            <>
              {/* We only want to render the videoplayer if the user has an active subscription
              This fixes a bug where the video player could sometimes play behind the membership wall */}
              {!preventPlayCondition ? (
                <ReactPlayer
                  ref={playerElement}
                  url={url}
                  playing={playing && !segmentEnded}
                  volume={volume}
                  muted={muted}
                  onProgress={handleProgressChange}
                  /*
                   * We use playsinline to prevent mobile browsers from showing the VideoPlayer
                   * in fullscreen but default and preferring our controls. This is necessary
                   * because we are playing video SEGMENTS rather than the entire video, which
                   * would result in the entire video (not just a SEGMENT) being played if
                   * native controls were used.
                   */
                  playsinline
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  onEnded={handleVideoEnded}
                  progressInterval={progressIntervalSeconds * 1000}
                />
              ) : null}
              <FullPlayerTogglePlay
                handleClick={conditionalHandleTogglePlay}
                playing={playing}
                segmentInStartingState={segmentInStartingState}
              />
              {segmentEnded ? renderSegmentComplete() : null}
              {videoInEndedState ? renderVideoComplete() : null}
            </>
          ) : null}

          {/* Since we do not even render the video player when the device doesn't support native
          full screen, we simply show the "VideoCheckpointTitleScreen" as a placeholder */}
          {segmentInStartingState || !supportsFullScreen ? renderSegmentStart() : null}

          <Box
            position="absolute"
            bottom={0}
            width="100%"
            display={supportsFullScreen ? 'block' : 'none'}
          >
            <Fade
              in={isControlsPanelVisible}
              transition={{
                enter: { type: 'tween', duration: 0.25 },
                exit: { type: 'tween', delay: 2, duration: 0.5 },
              }}
            >
              <Box px={4}>
                <Slider
                  aria-label="slider-ex-1"
                  value={sliderPercentInt}
                  onChange={handleSeekChange}
                  onChangeStart={handleSeekChangeStart}
                  onChangeEnd={handleSeekChangeEnd}
                  /*
                   * By default SliderThumb will receive focus whenever value changes.
                   * This will cause the Slider to change when a user uses the keyboard's
                   * arrow keys to change the value. We want to disable this behavior.
                   *
                   * This is a solution to prevent this behavior, but we may revisit
                   * this in the future to add arrow key navigation to the video player.
                   */
                  focusThumbOnChange={false}
                  size="sm"
                >
                  <SliderTrack backgroundColor="whiteAlpha.400">
                    <LoadedSliderFilledTrack value={loadedSliderPercentInt} />
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>
              <Flex alignItems="center" height="40px">
                <Flex>
                  {segmentEnded || videoInEndedState ? (
                    <VideoPlayerControlButton
                      label="Replay"
                      onClick={handleRestart}
                      icon={<RotateCcwIcon />}
                    />
                  ) : (
                    <VideoPlayerControlButton
                      label={playing ? 'Pause' : 'play'}
                      onClick={conditionalHandleTogglePlay}
                      icon={playing ? <PauseIcon /> : <PlayIcon />}
                    />
                  )}
                  <FormattedTimeText
                    display="inline-flex"
                    color="white"
                    playedSeconds={videoSegmentPlayedSeconds}
                    durationSeconds={videoSegmentDurationSeconds}
                  />
                  <VolumeButton
                    color="white"
                    muted={muted}
                    volume={volume}
                    setVolume={setVolume}
                    label="Mute"
                    onClick={toggleMuted}
                  />
                </Flex>
                <Flex width="100%" justifyContent="flex-end">
                  {supportsFullScreen ? (
                    <VideoPlayerControlButton
                      label={isFullScreen ? 'Minimize' : 'Maximize'}
                      icon={isFullScreen ? <MinimizeIcon /> : <MaximizeIcon />}
                      onClick={toggleFullScreen}
                    />
                  ) : (
                    <>
                      {/* Full screen button to trigger non-native full screen in modal */}
                      <VideoPlayerControlButton
                        label="Maximize"
                        icon={<MaximizeIcon />}
                        onClick={onMobileFullScreenOpen}
                      />
                      <TakeoverModal
                        isOpen={isMobileFullScreenOpen}
                        onClose={handleMobileFullScreenClose}
                        closeOnEsc
                      >
                        <TakeoverModalContent backgroundColor="black">
                          <TakeoverModalBody>
                            {isMobileFullScreenOpen ? (
                              <>
                                {/* Duplicated video player from above is used here */}
                                <ReactPlayer
                                  ref={playerElement}
                                  url={url}
                                  playing={playing && !segmentEnded}
                                  volume={volume}
                                  muted={muted}
                                  onProgress={handleProgressChange}
                                  playsinline
                                  width="100%"
                                  height="100%"
                                  style={{ position: 'absolute', top: 0, left: 0 }}
                                  onEnded={handleVideoEnded}
                                  progressInterval={progressIntervalSeconds * 1000}
                                />
                                <FullPlayerTogglePlay
                                  handleClick={conditionalHandleTogglePlay}
                                  playing={playing}
                                  segmentInStartingState={segmentInStartingState}
                                />
                                {segmentInStartingState ? renderSegmentStart() : null}
                                {segmentEnded ? renderSegmentComplete() : null}
                                {videoInEndedState ? renderVideoComplete() : null}
                                {/* Duplicated video player controls from above is used here
                                  with a few UI adjustments for mobile */}
                                <Box position="absolute" bottom="0" width="100%">
                                  <Box px={8}>
                                    <Slider
                                      aria-label="slider-ex-1"
                                      value={sliderPercentInt}
                                      onChange={handleSeekChange}
                                      onChangeStart={handleSeekChangeStart}
                                      onChangeEnd={handleSeekChangeEnd}
                                      focusThumbOnChange={false}
                                      size="sm"
                                    >
                                      <SliderTrack backgroundColor="whiteAlpha.400">
                                        <LoadedSliderFilledTrack value={loadedSliderPercentInt} />
                                        <SliderFilledTrack />
                                      </SliderTrack>
                                      <SliderThumb boxSize={seeking ? 6 : 4} />
                                    </Slider>
                                  </Box>
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    height="40px"
                                    px={2}
                                  >
                                    <Flex>
                                      {segmentEnded || videoInEndedState ? (
                                        <VideoPlayerControlButton
                                          label="Replay"
                                          onClick={handleRestart}
                                          icon={<RotateCcwIcon />}
                                          size="lg"
                                        />
                                      ) : (
                                        <VideoPlayerControlButton
                                          label={playing ? 'Pause' : 'play'}
                                          onClick={conditionalHandleTogglePlay}
                                          icon={playing ? <PauseIcon /> : <PlayIcon />}
                                          size="lg"
                                        />
                                      )}
                                      <FormattedTimeText
                                        display="inline-flex"
                                        color="white"
                                        playedSeconds={videoSegmentPlayedSeconds}
                                        durationSeconds={videoSegmentDurationSeconds}
                                      />
                                      <VolumeButton
                                        color="white"
                                        muted={muted}
                                        volume={volume}
                                        setVolume={setVolume}
                                        label="Mute"
                                        onClick={toggleMuted}
                                        size="lg"
                                      />
                                    </Flex>
                                    <Flex justifyContent="flex-end">
                                      <VideoPlayerControlButton
                                        label="Minimize"
                                        icon={<MinimizeIcon />}
                                        onClick={handleMobileFullScreenClose}
                                        size="lg"
                                      />
                                    </Flex>
                                  </Flex>
                                </Box>
                              </>
                            ) : null}
                          </TakeoverModalBody>
                        </TakeoverModalContent>
                      </TakeoverModal>
                    </>
                  )}
                </Flex>
              </Flex>
            </Fade>
          </Box>
        </Box>
      </AspectRatio>
    </>
  );
};
