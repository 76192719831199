import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EditIcon } from '../../../components/Icons';
import { IconListItem, IconListItemTitle } from '../../../components/molecules/IconListItem';
import { useGetWorkshopWeeklyRepRecapQuery } from '../../../resources/exercises';
import { Workshop } from '../../../resources/workshops';
import { formatQuestionId } from '../utils';

function ExerciseStepRecapQuestion({
  questionId,
  stepIndex,
}: {
  questionId: string;
  stepIndex: number;
}) {
  const { id: workshopId } = useRouteParams<{ id: Workshop['id'] }>();
  const getWorkshopWeeklyRepRecapQuery = useGetWorkshopWeeklyRepRecapQuery({ id: workshopId });

  const { data: exerciseSteps } = getWorkshopWeeklyRepRecapQuery.data;
  const exerciseStepAttrs = exerciseSteps[stepIndex].attributes;
  const formQuestion = exerciseStepAttrs.formQuestions[formatQuestionId(questionId)];

  const { headline } = exerciseStepAttrs;
  const { label, answer } = formQuestion;

  // TODO: Comment about the fact that we don't currently support follow up questions

  return (
    // TODO: Centralize these styles in a component
    <Box w="100%">
      <Heading size="sm" mb={2}>
        {headline}
      </Heading>
      <Text fontSize="sm" mb={2}>
        {label}
      </Text>
      <Box borderRadius="base" bg="gray.50" p={2} w="100%">
        <Text size="sm" color={_.isUndefined(answer) ? 'gray.300' : undefined}>
          <em>{answer || "You didn't answer this question."}</em>
        </Text>
      </Box>
    </Box>
  );
}

function ExerciseStepRecap({ stepIndex }: { stepIndex: number }) {
  const { id: workshopId } = useRouteParams<{ id: Workshop['id'] }>();
  const getWorkshopWeeklyRepRecapQuery = useGetWorkshopWeeklyRepRecapQuery({ id: workshopId });

  const { data: exerciseSteps, included } = getWorkshopWeeklyRepRecapQuery.data;
  const { attributes: exerciseStepAttrs, id: exerciseStepId } = exerciseSteps[stepIndex];
  const { formQuestionSequence } = exerciseStepAttrs;
  const exerciseId = exerciseSteps[stepIndex].relationships.exercise.data.id;
  const { attributes: exerciseAttrs } = _.find(
    included,
    ({ type, id }) => type === 'exercise' && id === exerciseId
  );

  return (
    <Box
      // TODO: Centralize these styles in a component
      borderRadius="base"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="gray.50"
      w="100%"
      p={4}
    >
      <VStack spacing={2}>
        <IconListItem
          colorScheme="green"
          icon={<EditIcon />}
          w="100%"
          {...{
            as: RouterLink,
            to: `/classes/${workshopId}/exercises/${exerciseId}/steps/${exerciseStepId}?context=recap`,
          }}
        >
          <IconListItemTitle>{exerciseAttrs.title}</IconListItemTitle>
        </IconListItem>
        {formQuestionSequence.map((questionId) => (
          <ExerciseStepRecapQuestion
            key={questionId}
            questionId={questionId}
            stepIndex={stepIndex}
          />
        ))}
      </VStack>
    </Box>
  );
}

export function WeeklyRepRecap() {
  // TODO: Consider moving this to a routing lib
  const { id: workshopId } = useRouteParams<{ id: Workshop['id'] }>();
  const getWorkshopWeeklyRepRecapQuery = useGetWorkshopWeeklyRepRecapQuery({ id: workshopId });

  if (getWorkshopWeeklyRepRecapQuery.isLoading) return null;

  const { data: steps } = getWorkshopWeeklyRepRecapQuery.data;

  return (
    <VStack spacing={8}>
      {steps.map((step, stepIndex) => (
        <ExerciseStepRecap key={step.id} stepIndex={stepIndex} />
      ))}
    </VStack>
  );
}
