import {
  Box,
  Collapse,
  Flex,
  Heading,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { SubscriptionOrderSummary } from '@coa/api/controllers/v1/subscriptions';
import { formatPriceCentsShort } from '@coa/stdlib/string/money';
import _ from 'lodash';
import React from 'react';
import {
  ActivityIcon,
  ClockIcon,
  GiftIcon,
  LockIcon,
  UsersIcon,
} from '../../../../components/Icons';
import { useThemedBreakpoint } from '../../../../lib/chakra-ui';
import { MarketingSiteNavigationBreadcrumb } from '../../../shared/fakeModal/MarketingSiteNavigationBreadcrumb';
import { useOrderSummary } from './SubscribeModalOrderSummaryProvider';

export const billingModalityToBillingModalityLabel: {
  [s in SubscriptionOrderSummary['billingModality']]: string;
} = {
  monthly: 'per month',
};

export const SubscribeModalStaticContent = ({
  title = 'Try 7 days of free online classes at your gym for mental health',
}: {
  title?: React.ReactNode;
}) => {
  const { getSubscriptionOrderSummaryQuery } = useOrderSummary();
  const [isLargerThanMdBreakpoint] = useThemedBreakpoint({ above: 'md' });

  const {
    data: orderSummary,
    isLoading: rawIsLoading,
    isPlaceholderData,
  } = getSubscriptionOrderSummaryQuery;
  const isLoading = rawIsLoading || isPlaceholderData;

  return (
    <>
      <Box color="white">
        <MarketingSiteNavigationBreadcrumb
          position={{ foo: 'absolute' }}
          bottom="100%"
          left={0}
          pb={{ base: 4, foo: 2 }}
          color="white"
        />

        <Heading mb={4} fontSize={{ base: '2xl', md: '4xl' }}>
          {title}
        </Heading>
        {isLoading ? (
          <>
            <Skeleton isLoaded={!isLoading} height={4} maxW="200px" mb={4} />
            <Skeleton
              isLoaded={!isLoading}
              maxW="200px"
              height={9} // spacing 9 = fontSize 4xl
              mb={8}
            />
          </>
        ) : (
          <Flex alignItems="stretch" justifyContent="space-between">
            <Box>
              <Text fontSize="lg" color="whiteAlpha.600">
                <strong>Your Coa Membership</strong>
              </Text>
              <Text mt={-2}>
                <Text as="strong" fontSize="4xl">
                  {formatPriceCentsShort(orderSummary.priceCents)}
                </Text>{' '}
                <Text as="strong" fontSize="sm" color="whiteAlpha.600">
                  {billingModalityToBillingModalityLabel[orderSummary.billingModality]}
                </Text>
              </Text>
            </Box>
          </Flex>
        )}
        <Box>
          {isLargerThanMdBreakpoint ? (
            <Collapse in={isLargerThanMdBreakpoint}>
              <Box color="whiteAlpha.800">
                {isLoading ? (
                  <>
                    {_.range(5).map((index: number) => (
                      <Flex mb={4} key={index}>
                        <SkeletonCircle mr={4} />
                        <Skeleton flexGrow={999} height={8} />
                      </Flex>
                    ))}
                    <SkeletonText mt={8} noOfLines={4} spacing={3} />
                  </>
                ) : (
                  <>
                    <Flex alignItems="center" mb={4}>
                      <UsersIcon boxSize={6} mr={4} color="whiteAlpha.500" />
                      <Text fontSize={{ base: 'md', md: 'lg' }}>
                        Unlimited access to live, therapist-led group classes.
                      </Text>
                    </Flex>
                    <Flex alignItems="center" mb={4}>
                      <ClockIcon boxSize={6} mr={4} color="whiteAlpha.500" />
                      <Text fontSize={{ base: 'md', md: 'lg' }}>
                        Drop-in to classes or join a weekly cohort.
                      </Text>
                    </Flex>
                    <Flex alignItems="center" mb={4}>
                      <ActivityIcon boxSize={6} mr={4} color="whiteAlpha.500" />
                      <Text fontSize={{ base: 'md', md: 'lg' }}>
                        On-demand content for your top mental health questions.
                      </Text>
                    </Flex>
                    <Flex alignItems="center" mb={4}>
                      <GiftIcon boxSize={6} mr={4} color="whiteAlpha.500" />
                      <Text fontSize={{ base: 'md', md: 'lg' }}>
                        Weekly emotional push-ups and members-only events.
                      </Text>
                    </Flex>
                    <Flex mb={8} display={{ base: 'none', md: 'flex' }}>
                      <LockIcon mr={2} />
                      <Text
                        fontSize="sm"
                        mt="-1px" // Slight offset for layout
                        mb={0}
                      >
                        <strong>100% Refund Guarantee:</strong> We are confident you’ll have an
                        amazing experience with Coa, but if you attend 3 classes and decide it’s not
                        for you, we’ll give you your money back, no questions asked.
                      </Text>
                    </Flex>
                  </>
                )}{' '}
              </Box>
            </Collapse>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
