import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { UnpackArray } from '@coa/types';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowRightIcon, MoreVerticalIcon } from '../../../../components/Icons';
import {
  EventCardDescription,
  EventCardHeading,
  SecondaryEventCard,
} from '../../../../components/molecules/EventCard';
import { useGetTeamWorkshopsQuery } from '../../../../resources/teams';
import { GetTeamWorkshops } from '../../../../resources/teams/apis/types';
import { formatWorkshopTimeLabel, useGetWorkshopQuery } from '../../../../resources/workshops';
import { useTeamsRole } from '../utils';
import { AddTeamWorkshopButton } from './AddTeamWorkshopModal';
import { RemoveTeamWorkshopModal } from './RemoveTeamWorkshopModal';

const ARBITRARY_EVENT_CARD_WIDTH = '450px';

function TableEmptyState({ id, ...rest }: { id: string } & BoxProps): JSX.Element {
  const { isCoaAdmin } = useTeamsRole({ id });

  return (
    <Box bg="gray.50" borderRadius="base" px={4} py={16} textAlign="center" {...rest}>
      {isCoaAdmin ? (
        <>
          <Heading size="lg">Next, this team needs a class.</Heading>
          <Text>Give this team access to a class to continue.</Text>
          <AddTeamWorkshopButton id={id} variant="primary" mt={4}>
            Add Class
          </AddTeamWorkshopButton>
        </>
      ) : (
        <>
          <Heading size="lg">Your team doesn't have a class yet.</Heading>
          <Text>Please contact your Coa Account Manager to get set up.</Text>
        </>
      )}
    </Box>
  );
}

export function TeamWorkshopsTableRow({
  id,
  onRemoveWorkshopButtonClick,
}: {
  id: string;
  onRemoveWorkshopButtonClick: () => void;
}): JSX.Element {
  const getWorkshopQuery = useGetWorkshopQuery({ id });
  const { isCoaAdmin } = useTeamsRole({ id });

  if (getWorkshopQuery.isLoading) return null;

  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences, therapist } = workshop;

  const timeLabel = formatWorkshopTimeLabel(workshop, workshopOccurrences);

  return (
    <Tr>
      <Td>
        <SecondaryEventCard src={workshop.imageUrl}>
          <EventCardHeading>{workshop.title}</EventCardHeading>
          <EventCardDescription>
            {workshop.shortBlurb || workshop.description || ''}
          </EventCardDescription>
        </SecondaryEventCard>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Avatar boxSize="22px" name={therapist.name} src={therapist.imageUrl} mr={2} />
          <Text>{therapist.name}</Text>
        </Flex>
      </Td>
      <Td>
        <Text>{timeLabel}</Text>
      </Td>
      <Td>
        <Text>{workshop.approvedEnrolleeCount} Enrollees</Text>
      </Td>
      <Td>
        <HStack>
          <Menu>
            <MenuButton as={IconButton} icon={<MoreVerticalIcon />} bg="transparent" />
            <MenuList>
              {isCoaAdmin ? (
                <MenuItem onClick={onRemoveWorkshopButtonClick}>Remove Workshop</MenuItem>
              ) : null}
            </MenuList>
          </Menu>
          <IconButton
            as={RouterLink}
            to={`/classes/${id}`}
            icon={<ArrowRightIcon />}
            aria-label="View Class Details"
            bg="transparent"
          />
        </HStack>
      </Td>
    </Tr>
  );
}

export const TeamWorkshopsTable = ({ id }: { id: string }) => {
  const getTeamWorkshopsQuery = useGetTeamWorkshopsQuery({ id });
  const { isCoaAdmin } = useTeamsRole({ id });

  const {
    isOpen: isRemoveTeamWorkshopModalOpen,
    onOpen: onRemoveTeamWorkshopModalOpen,
    onClose: onRemoveTeamWorkshopModalClose,
  } = useDisclosure();

  const [focusedWorkshop, setWorkshopToFocus] = useState<
    UnpackArray<GetTeamWorkshops.Response['data']> | undefined
  >();
  if (getTeamWorkshopsQuery.isLoading) return null;

  const { data: workshops = [] } = getTeamWorkshopsQuery.data || {};

  if (_.isEmpty(workshops)) return <TableEmptyState id={id} />;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end">
        <Heading size="md">This team can attend the following classes:</Heading>
        <AddTeamWorkshopButton id={id} variant="secondary">
          Add Class
        </AddTeamWorkshopButton>
      </Flex>
      <Table variant="simple-hover">
        <Thead>
          <Tr>
            <Th width={ARBITRARY_EVENT_CARD_WIDTH} />
            <Th />
            <Th />
            <Th />
            <Th width="1%" />
          </Tr>
        </Thead>
        <Tbody>
          {workshops.map(({ attributes, id: workshopId }) => {
            const handleRemoveWorkshopButtonClick = () => {
              /*
               * Indicate which workshop we intend to remove and
               * launch a modal to remove it. We centralize this
               * with useState as it'd be more performant than
               * rendering a separate modal instance for each row.
               */
              setWorkshopToFocus({ id: workshopId, attributes });
              onRemoveTeamWorkshopModalOpen();
            };
            return (
              <TeamWorkshopsTableRow
                key={workshopId}
                id={workshopId}
                onRemoveWorkshopButtonClick={handleRemoveWorkshopButtonClick}
              />
            );
          })}
        </Tbody>
      </Table>
      {isCoaAdmin ? (
        <RemoveTeamWorkshopModal
          isOpen={isRemoveTeamWorkshopModalOpen}
          onClose={onRemoveTeamWorkshopModalClose}
          id={id}
          workshop={focusedWorkshop}
        />
      ) : null}
    </>
  );
};
