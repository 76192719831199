import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace TryQAndAAnalytics {
  type EventName =
    | 'Enrolled in Q&A'
    | 'Selected Q&A'
    | 'Viewed Try Q&A'
    | 'Viewed Try Q&A (instance)';

  type EventNameToEventBody = {
    'Enrolled in Q&A': { workshopId: string };
    'Selected Q&A': { workshopId: string };
    'Viewed Try Q&A': never;
    'Viewed Try Q&A (instance)': never;
  };
}

export const tryQAndAAnalytics = createSafeAnalyticsClient<
  TryQAndAAnalytics.EventName,
  TryQAndAAnalytics.EventNameToEventBody
>();
