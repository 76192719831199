import {
  chakra,
  FormErrorMessageProps,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  useFormControlContext,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import React from 'react';

/**
 * Used to provide feedback about an invalid input,
 * and suggest clear instructions on how to fix it.
 *
 * An alternative to FormErrorMessage which is - by default - "smart" and shows
 * and hides itself based on error messages in the form-state. Doing so does not
 * allow us to customize the show/hide at all - for example, to wrap components
 * in a transition.
 *
 * This component is not "smart" but instead "dumb". It just renders regardless
 * of whether or not an error is present, allowing us to customize the show/hide.
 */
export const DumbFormErrorMessage = forwardRef<FormErrorMessageProps, 'div'>((props, ref) => {
  const styles = useMultiStyleConfig('FormError', props);
  const ownProps = omitThemingProps(props);
  const field = useFormControlContext();

  /*
   * Here's the "dumb" part. Everything else if copied directly from Chakra source.
   */
  // if (!field?.isInvalid) return null;

  return (
    <StylesProvider value={styles}>
      <chakra.div
        {...field?.getErrorMessageProps(ownProps, ref)}
        className={cx('chakra-form__error-message', props.className)}
        __css={{
          display: 'flex',
          alignItems: 'center',
          ...styles.text,
        }}
      />
    </StylesProvider>
  );
});
