import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import React from 'react';
import { useThemedMediaQueryRuleStr } from '../../../lib/chakra-ui';
import { GlobalLayoutProvider, useGlobalLayoutConfig } from './components/GlobalLayoutProvider';
import { GlobalStylesProvider, useGlobalStylesConfig } from './components/GlobalStylesProvider';
import { LegalLinks, LegalLinksParent } from './components/LegalLinks';
import { DesktopAppMarketingToast, MobileAppMarketingBanner } from './components/MarketingBanner';
import { NavBar } from './components/NavBar';
import { ScrollToTop } from './components/ScrollToTop';

function LayoutParent(props: FlexProps) {
  const { globalStyleConfig } = useGlobalStylesConfig();
  return <Flex filter={globalStyleConfig.appBlur ? 'blur(8px)' : undefined} {...props} />;
}

function NavBarParent({ children, ...rest }: BoxProps) {
  const { globalLayoutConfig } = useGlobalLayoutConfig();
  const { hideNavMobile: hideNav } = globalLayoutConfig;
  return (
    <Box
      width="100%"
      // Static flex constraints. On mobile, this will only
      // occupy as much aspace as it needs, Allowing for the
      // main content to occupy the remaining space and overflow
      // via scroll.
      flexGrow={1}
      flexShrink={1}
      position={{ md: 'sticky' }}
      zIndex={{ md: 'sticky', sm: 100 }}
      top={{ md: 0 }}
      left={{ md: 0 }}
      /*
       * For now, we use a simple show/hide for simplicity,
       * though the UX may be lacking. It's worth considering
       * a better treatment here via an animation.
       */
      display={{ base: hideNav ? 'none' : undefined, md: 'block' }}
      {...rest}
    >
      {children}
    </Box>
  );
}

const ContentParent = ({ children }: { children: React.ReactNode }) => (
  <Flex
    width="100%"
    height={{ base: '100%', md: 'auto' }}
    minHeight={{ md: '100vh' }}
    overflow={{
      base: 'auto',
      // Note that we explicitly need overflow to be unset at desktop
      // so that we can use position: sticky throughout the app, since
      // it defines a scrolling ancestor using overflow.
      md: 'visible',
    }}
    flexDirection="column"
    alignItems="center"
  >
    <ScrollToTop />
    {children}
  </Flex>
);

export function AppLayout({ children }: { children: React.ReactNode }): JSX.Element {
  const belowMdMediaQuery = useThemedMediaQueryRuleStr({ below: 'md' });

  /*
   * On mobile devices - particularly mobile-Safari - stacking contexts
   * of position: "fixed" elements don't always render z-indices reliably.
   * As such, we avoid rendering mutiple fixed-position elements on the
   * screen simultaneously for fear of them stacking incorrectly.
   *
   * The most relevant component here is the Nav, which is mostly
   * omnipresent. Rather than using position: "fixed", we wrap the app
   * in a Flex layout, with the Nav occupying a statically-flexed child
   * and the main content flexing to fill the remaining space and
   * scrolling to overflow. (Funnily enough this layout paradigm is
   * more similar to what is used in native mobile environments than
   * the typical browser-centric box-model, so it plays fairly well.)
   *
   * @see https://linear.app/coa/issue/COA-491
   */
  return (
    <>
      <Global
        styles={{
          // In order for nested elements to be able to inherit height
          // from their parents, we need to ensure that all of their
          // ancestors have the desired height. We only care about
          // this at mobile so we use a mediaQuery to reset at desktop.
          'html, body, #app': {
            [belowMdMediaQuery]: {
              height: '100%',
            },
          },
        }}
      />
      <GlobalStylesProvider>
        <GlobalLayoutProvider>
          <LayoutParent
            // Nav appears on the bottom on mobile and on the top
            // on desktop.
            direction={{ base: 'column-reverse', md: 'column' }}
            height="100%"
            align="center"
            justify="center"
            width="stretch"
            zIndex={100}
          >
            <NavBarParent>
              <MobileAppMarketingBanner />
              <NavBar />
            </NavBarParent>
            <ContentParent>{children}</ContentParent>
            <LegalLinksParent>
              <LegalLinks my={12} />
            </LegalLinksParent>
            <DesktopAppMarketingToast />
          </LayoutParent>
        </GlobalLayoutProvider>
      </GlobalStylesProvider>
    </>
  );
}
