import { createIcon, Icon, IconProps } from '@chakra-ui/react';
import React from 'react';
import {
  Activity as FeatherActivity,
  AlertCircle as FeatherAlertCircle,
  ArrowDown as FeatherArrowDown,
  ArrowLeft as FeatherArrowLeft,
  ArrowRight as FeatherArrowRight,
  ArrowUp as FeatherArrowUp,
  Calendar as FeatherCalendar,
  Check as FeatherCheck,
  CheckCircle as FeatherCheckCircle,
  ChevronDown as FeatherChevronDown,
  ChevronLeft as FeatherChevronLeft,
  ChevronRight as FeatherChevronRight,
  ChevronsLeft as FeatherChevronsLeft,
  ChevronsRight as FeatherChevronsRight,
  ChevronUp as FeatherChevronUp,
  Clipboard as FeatherClipboard,
  Clock as FeatherClock,
  Coffee as FeatherCoffee,
  Copy as FeatherCopy,
  DollarSign as FeatherDollarSign,
  Eye as FeatherEye,
  FastForward as FeatherFastForward,
  FileText as FeatherFileText,
  Filter as FeatherFilter,
  Gift as FeatherGift,
  Grid as FeatherGrid,
  Heart as FeatherHeart,
  Link as FeatherLink,
  Lock as FeatherLock,
  LogIn as FeatherLogIn,
  Mail as FeatherMail,
  Maximize as FeatherMaximize,
  MessageCircle as FeatherMessageCircle,
  MessageSquare as FeatherMessageSquare,
  Minimize as FeatherMinimize,
  Monitor as FeatherMonitor,
  Moon as FeatherMoon,
  MoreHorizontal as FeatherMoreHorizontal,
  MoreVertical as FeatherMoreVertical,
  Music as FeatherMusic,
  Pause as FeatherPause,
  Phone as FeatherPhone,
  Play as FeatherPlay,
  PlayCircle as FeatherPlayCircle,
  PlusSquare as FeatherPlusSquare,
  RotateCcw as FeatherRotateCcw,
  Search as FeatherSearch,
  SkipBack as FeatherSkipBack,
  SkipForward as FeatherSkipForward,
  Star as FeatherStar,
  Sun as FeatherSun,
  Sunrise as FeatherSunrise,
  Sunset as FeatherSunset,
  Tag as FeatherTag,
  Trash2 as FeatherTrash2,
  TrendingUp as FeatherTrendingUp,
  Umbrella as FeatherUmbrella,
  User as FeatherUser,
  Users as FeatherUsers,
  Video as FeatherVideo,
  Volume as FeatherVolume,
  Volume1 as FeatherVolume1,
  Volume2 as FeatherVolume2,
  VolumeX as FeatherVolumeX,
  X as FeatherX,
} from 'react-feather';
import { BsSliders as BootstrapSliders } from 'react-icons/bs';
import { CiDumbbell } from 'react-icons/ci';

const viewBox = '0 0 24 24';

/*
 * Helper that creates wrapper components around third-party
 * icons using Chakra's <Icon as={Component} /> pattern.
 */
const createIconFromComponent = ({
  displayName,
  as: IconComponent,
}: {
  displayName: string;
  as: React.FunctionComponent;
}) => {
  const result = (props: IconProps) => <Icon as={IconComponent} {...props} />;
  result.displayName = displayName;
  return result;
};

/*
 * FEATHER ICONS
 * ################################################
 *
 * The following icons are pulled from a react-wrapper
 * around Feather Icons.
 *
 * At time of writing we are using `react-feather` v2.0.9
 * which employs Feather Icons `v4.28.0`.
 *
 * For available icons...
 * @see https://feathericons.com/?query=add
 */

export const ActivityIcon = createIconFromComponent({
  displayName: 'ActivityIcon',
  as: FeatherActivity,
});
export const AlertCircleIcon = createIconFromComponent({
  displayName: 'AlertCircleIcon',
  as: FeatherAlertCircle,
});
export const ArrowDownIcon = createIconFromComponent({
  displayName: 'ArrowDownIcon',
  as: FeatherArrowDown,
});
export const ArrowLeftIcon = createIconFromComponent({
  displayName: 'ArrowLeftIcon',
  as: FeatherArrowLeft,
});
export const ArrowRightIcon = createIconFromComponent({
  displayName: 'ArrowRightIcon',
  as: FeatherArrowRight,
});
export const ArrowUpIcon = createIconFromComponent({
  displayName: 'ArrowUpIcon',
  as: FeatherArrowUp,
});
export const CalendarIcon = createIconFromComponent({
  displayName: 'CalendarIcon',
  as: FeatherCalendar,
});
export const CheckIcon = createIconFromComponent({
  displayName: 'CheckIcon',
  as: FeatherCheck,
});
export const CheckCircleIcon = createIconFromComponent({
  displayName: 'CheckCircleIcon',
  as: FeatherCheckCircle,
});
export const ChevronLeftIcon = createIconFromComponent({
  displayName: 'ChevronLeftIcon',
  as: FeatherChevronLeft,
});
export const ChevronsLeftIcon = createIconFromComponent({
  displayName: 'ChevronsLeftIcon',
  as: FeatherChevronsLeft,
});
export const ChevronRightIcon = createIconFromComponent({
  displayName: 'ChevronRightIcon',
  as: FeatherChevronRight,
});
export const ChevronsRightIcon = createIconFromComponent({
  displayName: 'ChevronsRightIcon',
  as: FeatherChevronsRight,
});
export const ChevronUpIcon = createIconFromComponent({
  displayName: 'ChevronUpIcon',
  as: FeatherChevronUp,
});
export const ChevronDownIcon = createIconFromComponent({
  displayName: 'ChevronDownIcon',
  as: FeatherChevronDown,
});
export const ClockIcon = createIconFromComponent({
  displayName: 'ClockIcon',
  as: FeatherClock,
});
export const ClipboardIcon = createIconFromComponent({
  displayName: 'ClipboardIcon',
  as: FeatherClipboard,
});
export const CoffeeIcon = createIconFromComponent({
  displayName: 'CoffeeIcon',
  as: FeatherCoffee,
});
export const CopyIcon = createIconFromComponent({
  displayName: 'CopyIcon',
  as: FeatherCopy,
});
export const DollarSignIcon = createIconFromComponent({
  displayName: 'DollarSignIcon',
  as: FeatherDollarSign,
});
export const DumbellIcon = createIconFromComponent({
  displayName: 'DumbellIcon',
  as: CiDumbbell,
});
export const EyeIcon = createIconFromComponent({
  displayName: 'EyeIcon',
  as: FeatherEye,
});
export const FastForwardIcon = createIconFromComponent({
  displayName: 'FastForward',
  as: FeatherFastForward,
});
export const FileTextIcon = createIconFromComponent({
  displayName: 'FileTextIcon',
  as: FeatherFileText,
});
export const FilterIcon = createIconFromComponent({
  displayName: 'FilterIcon',
  as: FeatherFilter,
});
export const GiftIcon = createIconFromComponent({
  displayName: 'GiftIcon',
  as: FeatherGift,
});
export const GridIcon = createIconFromComponent({
  displayName: 'GridIcon',
  as: FeatherGrid,
});
export const HeartIcon = createIconFromComponent({
  displayName: 'HeartIcon',
  as: FeatherHeart,
});
export const LinkIcon = createIconFromComponent({
  displayName: 'LinkIcon',
  as: FeatherLink,
});
export const LockIcon = createIconFromComponent({
  displayName: 'LockIcon',
  as: FeatherLock,
});
export const MailIcon = createIconFromComponent({
  displayName: 'MailIcon',
  as: FeatherMail,
});
export const MaximizeIcon = createIconFromComponent({
  displayName: 'Maximize',
  as: FeatherMaximize,
});
export const MessageCircleIcon = createIconFromComponent({
  displayName: 'MessageCircleIcon',
  as: FeatherMessageCircle,
});
export const MessageSquareIcon = createIconFromComponent({
  displayName: 'MessageSquareIcon',
  as: FeatherMessageSquare,
});
export const MinimizeIcon = createIconFromComponent({
  displayName: 'Minimize',
  as: FeatherMinimize,
});
export const MonitorIcon = createIconFromComponent({
  displayName: 'MonitorIcon',
  as: FeatherMonitor,
});
export const MoonIcon = createIconFromComponent({
  displayName: 'MoonIcon',
  as: FeatherMoon,
});
export const MoreHorizontalIcon = createIconFromComponent({
  displayName: 'MoreHorizontalIcon',
  as: FeatherMoreHorizontal,
});
export const MoreVerticalIcon = createIconFromComponent({
  displayName: 'MoreVerticalIcon',
  as: FeatherMoreVertical,
});
export const MusicIcon = createIconFromComponent({
  displayName: 'MusicIcon',
  as: FeatherMusic,
});
export const PlayIcon = createIconFromComponent({
  displayName: 'Play',
  as: FeatherPlay,
});
export const PlayCircleIcon = createIconFromComponent({
  displayName: 'PlayCircle',
  as: FeatherPlayCircle,
});
export const PauseIcon = createIconFromComponent({
  displayName: 'Pause',
  as: FeatherPause,
});
export const PayoutsIcon = createIconFromComponent({
  displayName: 'PayoutsIcon',
  as: FeatherLogIn, // Note deliberate naming mismatch
});
export const PhoneIcon = createIconFromComponent({
  displayName: 'PhoneIcon',
  as: FeatherPhone,
});
export const PlusSquareIcon = createIconFromComponent({
  displayName: 'PlusSquareIcon',
  as: FeatherPlusSquare,
});
export const RotateCcwIcon = createIconFromComponent({
  displayName: 'RotateCcwIcon',
  as: FeatherRotateCcw,
});
export const SearchIcon = createIconFromComponent({
  displayName: 'SearchIcon',
  as: FeatherSearch,
});
export const SlidersIcon = createIconFromComponent({
  displayName: 'SlidersIcon',
  as: BootstrapSliders,
});
export const SkipBackIcon = createIconFromComponent({
  displayName: 'SkipBack',
  as: FeatherSkipBack,
});
export const SkipForwardIcon = createIconFromComponent({
  displayName: 'SkipForward',
  as: FeatherSkipForward,
});
export const StarIcon = createIconFromComponent({
  displayName: 'StarIcon',
  as: FeatherStar,
});
export const SunIcon = createIconFromComponent({
  displayName: 'SunIcon',
  as: FeatherSun,
});
export const SunriseIcon = createIconFromComponent({
  displayName: 'SunriseIcon',
  as: FeatherSunrise,
});
export const SunsetIcon = createIconFromComponent({
  displayName: 'SunsetIcon',
  as: FeatherSunset,
});
export const TagIcon = createIconFromComponent({
  displayName: 'TagIcon',
  as: FeatherTag,
});
export const TrashIcon = createIconFromComponent({
  displayName: 'TrashIcon',
  as: FeatherTrash2,
});
export const TrendingUpIcon = createIconFromComponent({
  displayName: 'TrendingUpIcon',
  as: FeatherTrendingUp,
});
export const UmbrellaIcon = createIconFromComponent({
  displayName: 'UmbrellaIcon',
  as: FeatherUmbrella,
});
export const UserIcon = createIconFromComponent({
  displayName: 'UserIcon',
  as: FeatherUser,
});
export const UsersIcon = createIconFromComponent({
  displayName: 'UsersIcon',
  as: FeatherUsers,
});
export const VideoIcon = createIconFromComponent({
  displayName: 'VideoIcon',
  as: FeatherVideo,
});
export const VolumeIcon = createIconFromComponent({
  displayName: 'Volume',
  as: FeatherVolume,
});
export const Volume1Icon = createIconFromComponent({
  displayName: 'Volume1',
  as: FeatherVolume1,
});
export const Volume2Icon = createIconFromComponent({
  displayName: 'Volume2',
  as: FeatherVolume2,
});
export const VolumeXIcon = createIconFromComponent({
  displayName: 'VolumeX',
  as: FeatherVolumeX,
});
export const XIcon = createIconFromComponent({
  displayName: 'XIcon',
  as: FeatherX,
});

/*
 * CUSTOM ICONS
 * ################################################
 */

/*
 * Pulled from Chakra-UI's default Icon Set.
 */
export const AddSmallIcon = createIcon({
  displayName: 'AddSmallIcon',
  viewBox,
  path: (
    <path
      fill="currentColor"
      d="M14 9h-3V6c0-.55-.45-1-1-1s-1 .45-1 1v3H6c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1z"
      fillRule="evenodd"
    />
  ),
});

/*
 * Pulled from Chakra-UI's default Icon Set.
 */
export const CaretDownIcon = createIcon({
  displayName: 'CaretDownIcon',
  viewBox,
  d:
    'M12.277 14.7522L8.45062 10.5007C8.27687 10.3076 8.41388 10 8.67361 10H16.3264C16.5861 10 16.7231 10.3076 16.5494 10.5007L12.723 14.7522C12.6038 14.8846 12.3962 14.8846 12.277 14.7522Z',
});

/*
 * Custom designed icon based on Feather Icons
 */
export const SofaIcon = createIcon({
  displayName: 'SofaIcon',
  viewBox,
  d:
    'M2 7.17071V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V7.17071C23.1652 7.58254 24 8.69378 24 10V16C24 17.3062 23.1652 18.4175 22 18.8293V21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21V19H4V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V18.8293C0.834808 18.4175 0 17.3062 0 16V10C0 8.69378 0.834808 7.58254 2 7.17071ZM4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V7.17071C18.8348 7.58254 18 8.69378 18 10V12H6V10C6 8.69378 5.16519 7.58254 4 7.17071V5ZM3 9C2.44772 9 2 9.44772 2 10V16C2 16.5523 2.44772 17 3 17H21C21.5523 17 22 16.5523 22 16V10C22 9.44772 21.5523 9 21 9C20.4477 9 20 9.44772 20 10V13C20 13.5523 19.5523 14 19 14H5C4.44772 14 4 13.5523 4 13V10C4 9.44772 3.55228 9 3 9Z',
});

/*
 * Custom designed icon based on Feather Icons
 */
export const InvoiceIcon = createIcon({
  displayName: 'InvoiceIcon',
  viewBox,
  d:
    'M2.52814 21.8817C2.20298 21.7077 2 21.3688 2 21V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V21C22 21.3688 21.797 21.7077 21.4719 21.8817C21.1467 22.0557 20.7522 22.0366 20.4453 21.8321L18 20.2019L15.5547 21.8321C15.2188 22.056 14.7812 22.056 14.4453 21.8321L12 20.2019L9.5547 21.8321C9.2188 22.056 8.7812 22.056 8.4453 21.8321L6 20.2019L3.5547 21.8321C3.24784 22.0366 2.8533 22.0557 2.52814 21.8817ZM4 19.1315L5.4453 18.1679C5.7812 17.944 6.2188 17.944 6.5547 18.1679L9 19.7981L11.4453 18.1679C11.7812 17.944 12.2188 17.944 12.5547 18.1679L15 19.7982L17.4453 18.1679C17.7812 17.944 18.2188 17.944 18.5547 18.1679L20 19.1315V5C20 4.44772 19.5523 4 19 4H5C4.44772 4 4 4.44772 4 5V19.1315ZM7 8C7 7.44772 7.44772 7 8 7H16C16.5523 7 17 7.44772 17 8C17 8.55229 16.5523 9 16 9H8C7.44772 9 7 8.55229 7 8ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44771 15 8 15L16 15C16.5523 15 17 14.5523 17 14C17 13.4477 16.5523 13 16 13L8 13Z',
});

/*
 * Variant of Edit Icon of unknown origin.
 * (Maybe an older version of Feather?)
 */
export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox,
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2929 1.29289C13.6834 0.902369 14.3166 0.902369 14.7071 1.29289L18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L7.70711 17.7071C7.51957 17.8946 7.26522 18 7 18H3C2.44772 18 2 17.5523 2 17V13C2 12.7348 2.10536 12.4804 2.29289 12.2929L13.2929 1.29289ZM4 13.4142V16H6.58579L16.5858 6L14 3.41421L4 13.4142Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 22C2 21.4477 2.44772 21 3 21H21C21.5523 21 22 21.4477 22 22C22 22.5523 21.5523 23 21 23H3C2.44772 23 2 22.5523 2 22Z"
        fill="currentColor"
      />
    </>
  ),
});

/*
 * Custom Series icon designed internally
 */
export const SeriesIcon = createIcon({
  displayName: 'SeriesIcon',
  viewBox,
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM2.442 9.052a3.001 3.001 0 0 0 0 5.896 10.023 10.023 0 0 0 6.61 6.61 3.001 3.001 0 0 0 5.896 0 10.023 10.023 0 0 0 6.61-6.61 3 3 0 0 0 0-5.896 10.023 10.023 0 0 0-6.61-6.61 3 3 0 0 0-5.896 0 10.023 10.023 0 0 0-6.61 6.61zm2 .317a3 3 0 0 1 0 5.262 8.022 8.022 0 0 0 4.927 4.926 3 3 0 0 1 5.262 0 8.022 8.022 0 0 0 4.927-4.926 3 3 0 0 1 0-5.262 8.022 8.022 0 0 0-4.927-4.926 3 3 0 0 1-5.262 0 8.022 8.022 0 0 0-4.926 4.926zM21 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-9 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM2 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
      fill="currentColor"
    />
  ),
});

export const PlaylistIcon = createIcon({
  displayName: 'PlaylistIcon',
  viewBox,
  path: (
    <path
      d="m10 10 .555-.832A1 1 0 0 0 9 10h1zm5 3.333.555.832a1 1 0 0 0 0-1.664l-.555.832zm-5 3.334H9a1 1 0 0 0 1.555.832L10 16.667zM20 5a1 1 0 1 0 0-2v2zM4 3a1 1 0 0 0 0 2V3zm14-1a1 1 0 1 0 0-2v2zM6 0a1 1 0 0 0 0 2V0zM3.444 8h17.112V6H3.444v2zm17.112 0c.527 0 .956.128 1.22.284.285.169.224.258.224.16h2c0-.895-.608-1.528-1.207-1.882C22.17 6.195 21.378 6 20.556 6v2zM22 8.444v10.112h2V8.444h-2zm0 10.112c0-.098.06-.009-.225.16-.263.155-.692.284-1.22.284v2c.823 0 1.616-.195 2.238-.562.599-.354 1.207-.987 1.207-1.882h-2zM20.556 19H3.444v2h17.112v-2zM3.444 19c-.527 0-.956-.128-1.22-.284-.285-.169-.224-.258-.224-.16H0c0 .895.608 1.528 1.207 1.882.622.367 1.415.562 2.237.562v-2zM2 18.556V8.444H0v10.112h2zM2 8.444c0 .098-.06.009.225-.16.263-.156.692-.284 1.22-.284V6c-.823 0-1.616.195-2.238.562C.608 6.916 0 7.55 0 8.444h2zm7.445 2.388 5 3.333 1.11-1.664-5-3.333-1.11 1.664zm5 1.67-5 3.333 1.11 1.664 5-3.334-1.11-1.664zM11 16.666V10H9v6.667h2zM20 3H4v2h16V3zm-2-3H6v2h12V0z"
      fill="currentColor"
      strokeWidth="2"
    />
  ),
});
