import { FormikEffectValuesDiff } from '@coa/formik-utils';
import { serverCase } from '@coa/stdlib/string';
import _ from 'lodash';
import { ExerciseStep } from '../../../resources/exercises';

/*
 * Helper that formats our events.
 */
const formatTrackExerciseEventParams = ({ values, questionId, formQuestions }) => ({
  questionId: serverCase(questionId),
  questionKind: formQuestions[questionId]?.kind,
  answerPresent: !_.isUndefined(values[questionId]),
});

/*
 * Given the current and previous answers, we determine what event properties
 * we wish to send over the wire.
 */
export const getTrackExerciseEventParams = ({
  values,
  prevValues,
  formQuestions,
}: Pick<FormikEffectValuesDiff, 'values' | 'prevValues'> & {
  formQuestions: ExerciseStep['formQuestions'];
}) => {
  // If an answer was added.
  const newAnswerDiff = _.difference(_.keys(values), _.keys(prevValues));
  if (newAnswerDiff.length) {
    const [questionId] = newAnswerDiff;
    return formatTrackExerciseEventParams({ questionId, values, formQuestions });
  }

  // If an answer was removed
  const removedAnswerDiff = _.difference(_.keys(prevValues), _.keys(values));
  if (removedAnswerDiff.length) {
    const [questionId] = removedAnswerDiff;
    return formatTrackExerciseEventParams({ questionId, values, formQuestions });
  }

  // If an answer to a question was changed
  const updatedAnswerQuestionId = _.findKey(values, (v, key) => v !== prevValues[key]);
  if (updatedAnswerQuestionId) {
    return formatTrackExerciseEventParams({
      questionId: updatedAnswerQuestionId,
      values,
      formQuestions,
    });
  }

  // Otherwise, we are unable to determine the event type, which will
  // halt the execution flow downstream with a falsy return value.
};
