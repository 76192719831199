import {
  APPOINTMENTS_DESTROY_REQUEST,
  APPOINTMENTS_FAILURE,
  APPOINTMENTS_REQUEST,
  APPOINTMENTS_SUCCESS,
  CLEAROUT_APPOINTMENT,
} from '../actions/appointments';

const DEFAULT_STATE = {
  creatingAppointment: false,
  appointment: null,
  appointmentErrors: {},
};

export function appointments(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case APPOINTMENTS_REQUEST: {
      return { ...state, appointmentErrors: {}, creatingAppointment: true };
    }
    case APPOINTMENTS_SUCCESS: {
      const {
        response: {
          data: { id, attributes },
        },
      } = action;
      const appointment = { id, ...attributes };
      return { ...state, appointment, creatingAppointment: false };
    }
    case APPOINTMENTS_FAILURE: {
      const { errors } = action;
      return { ...state, appointmentErrors: errors, creatingAppointment: false };
    }
    case APPOINTMENTS_DESTROY_REQUEST:
    case CLEAROUT_APPOINTMENT: {
      return { ...state, appointment: null };
    }
    default:
      return state;
  }
}
