import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Middleware } from 'redux';
import { LOG_OUT } from '../actions/sessions';
import { ReduxState } from '../store/types';
import { ApiWrapper } from '../utils/ApiWrapper';

export const createApiMiddleware = (): Middleware<unknown, ReduxState> => (store) => (next) => (
  action
) => {
  const callApi = action.api;

  if (_.isUndefined(callApi)) return next(action);

  const { types, endpoint, payload, method, data, authenticated, admin } = callApi;

  const [requestType, successType, errorType] = types;

  store.dispatch({ type: requestType, payload });

  return ApiWrapper.call({ endpoint, payload, method, data, authenticated, admin })
    .then((response: AxiosResponse) =>
      next({
        payload,
        response: response.data,
        type: successType,
      })
    )
    .catch((err: AxiosError) => {
      const { response } = err;
      if (response) {
        const unauthorized =
          response.data.errors && response.data.errors.base === 'Invalid API token';
        if (unauthorized) {
          store.dispatch({ type: LOG_OUT });
        }
        return next({
          errors: response.data.errors,
          meta: response.data.meta,
          statusCode: response.status,
          type: errorType,
        });
      }
      if (err.code === 'ECONNABORTED') {
        alert('You are no longer connected to the internet.');
      } else {
        throw err;
      }
    });
};
