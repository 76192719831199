import _ from 'lodash';

export const WORKSHOPS_LIST_REQUEST = 'WORKSHOPS_LIST_REQUEST';
export const WORKSHOPS_LIST_SUCCESS = 'WORKSHOPS_LIST_SUCCESS';
export const WORKSHOPS_LIST_FAILURE = 'WORKSHOPS_LIST_FAILURE';

export function list(page) {
  return {
    type: WORKSHOPS_LIST_REQUEST,
    api: {
      endpoint: `workshops?page=${page}`,
      authenticated: true,
      admin: true,
      types: [WORKSHOPS_LIST_REQUEST, WORKSHOPS_LIST_SUCCESS, WORKSHOPS_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export const WORKSHOP_OCCURRENCES_LIST_REQUEST = 'WORKSHOP_OCCURRENCES_LIST_REQUEST';
export const WORKSHOP_OCCURRENCES_LIST_SUCCESS = 'WORKSHOP_OCCURRENCES_LIST_SUCCESS';
export const WORKSHOP_OCCURRENCES_LIST_FAILURE = 'WORKSHOP_OCCURRENCES_LIST_FAILURE';

export function listOccurrences(id) {
  return {
    type: WORKSHOP_OCCURRENCES_LIST_REQUEST,
    api: {
      endpoint: `workshops/${id}/occurrences`,
      authenticated: true,
      admin: true,
      types: [
        WORKSHOP_OCCURRENCES_LIST_REQUEST,
        WORKSHOP_OCCURRENCES_LIST_SUCCESS,
        WORKSHOP_OCCURRENCES_LIST_FAILURE,
      ],
      method: 'GET',
    },
  };
}

const convertToFormData = (data) =>
  // Transfer data into FormData object, to induce a multipart/form-data POST
  // Also convert price and therapist data into appropriate formats
  _.reduce(
    data,
    (fd, value, key) => {
      switch (key) {
        case 'therapist':
          fd.append('therapist_id', value.id);
          break;
        case 'price':
          fd.append('price_cents', value * 100);
          break;
        case 'workshop_template':
          fd.append('workshop_template_id', value?.id);
          break;
        case 'workshop_occurrences':
          value.forEach((occurrence, index) => {
            Object.keys(occurrence).forEach((attr) => {
              let val = occurrence[attr];
              if (attr === 'starts_at') val = new Date(val).toISOString();
              fd.append(`workshop_occurrences[${index}]${attr}`, val || '');

              /*
               * In non-series Workshops, we don't require occurrence
               * titles or descriptions and instead we inherit from the
               * Workshop itself.
               *
               * We're currently hiding these on in the form, but there's
               * a future in which we actually want to give the admin
               * the ability to add separate titles, so we maintain this
               * functionality on the frontend.
               */
              if (index === 0 && attr === 'title' && !val) {
                fd.append(`workshop_occurrences[${index}]${attr}`, data.title);
              }
              if (index === 0 && attr === 'description' && !val) {
                fd.append(`workshop_occurrences[${index}]${attr}`, data.description);
              }
            });
          });
          break;
        default:
          fd.append(key, value);
      }
      return fd;
    },
    new FormData()
  );

export const WORKSHOPS_CREATE_REQUEST = 'WORKSHOPS_CREATE_REQUEST';
export const WORKSHOPS_CREATE_SUCCESS = 'WORKSHOPS_CREATE_SUCCESS';
export const WORKSHOPS_CREATE_FAILURE = 'WORKSHOPS_CREATE_FAILURE';

export function create(data) {
  const formData = convertToFormData(data);
  return {
    type: WORKSHOPS_CREATE_REQUEST,
    payload: formData,
    api: {
      data: formData,
      endpoint: 'workshops',
      authenticated: true,
      admin: true,
      types: [WORKSHOPS_CREATE_REQUEST, WORKSHOPS_CREATE_SUCCESS, WORKSHOPS_CREATE_FAILURE],
      method: 'POST',
    },
  };
}

export const WORKSHOPS_UPDATE_REQUEST = 'WORKSHOPS_UPDATE_REQUEST';
export const WORKSHOPS_UPDATE_SUCCESS = 'WORKSHOPS_UPDATE_SUCCESS';
export const WORKSHOPS_UPDATE_FAILURE = 'WORKSHOPS_UPDATE_FAILURE';

export function update(id, data) {
  const formData = convertToFormData(data);
  return {
    type: WORKSHOPS_UPDATE_REQUEST,
    payload: formData,
    api: {
      data: formData,
      endpoint: `workshops/${id}`,
      authenticated: true,
      admin: true,
      types: [WORKSHOPS_UPDATE_REQUEST, WORKSHOPS_UPDATE_SUCCESS, WORKSHOPS_UPDATE_FAILURE],
      method: 'PUT',
    },
  };
}
