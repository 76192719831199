export type Timeout = ReturnType<typeof setTimeout>;

/*
 * Helper that allows for easy "reset" of timeout. As this
 * isn't technically possible using existing web APIs, this
 * wrapper simply clears the existing one and replaces it.
 */
export const resetTimeout = (
  timeout: Timeout,
  fn: (params: unknown) => void,
  duration: number
): Timeout => {
  clearTimeout(timeout);
  return setTimeout(fn, duration);
};
