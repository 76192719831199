export const PAYOUTS_LIST_REQUEST = 'PAYOUTS_LIST_REQUEST';
export const PAYOUTS_LIST_SUCCESS = 'PAYOUTS_LIST_SUCCESS';
export const PAYOUTS_LIST_FAILURE = 'PAYOUTS_LIST_FAILURE';

export function list(therapistId) {
  return {
    type: PAYOUTS_LIST_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/payouts`,
      authenticated: true,
      types: [PAYOUTS_LIST_REQUEST, PAYOUTS_LIST_SUCCESS, PAYOUTS_LIST_FAILURE],
      method: 'GET',
    },
  };
}
