import {
  Box,
  Divider,
  Flex,
  SkeletonText,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { formatPriceCents, formatProseDateShort } from '@coa/stdlib/string';
import { formatCouponLabel } from '@coa/stdlib/string/labels';
import _ from 'lodash';
import React from 'react';
import { TagIcon } from '../../../../components/Icons';
import { SubscribeFormPromoCodeInput } from './SubscribeFormPromoCodeInput';
import { useOrderSummary } from './SubscribeModalOrderSummaryProvider';

export const SubscribeModalOrderSummary = () => {
  const { setRequestedPromoCode, getSubscriptionOrderSummaryQuery } = useOrderSummary();
  const {
    data: orderSummary,
    isLoading: rawIsLoading,
    isPlaceholderData,
  } = getSubscriptionOrderSummaryQuery;
  const isLoading = rawIsLoading || isPlaceholderData;

  let billingFrequency = 7;
  let totalDueText = `in ${billingFrequency} days`;
  const { coupon } = orderSummary;
  if (coupon) {
    const { percentOff, duration } = coupon;
    if (percentOff === 100 && duration === 'once') {
      billingFrequency = 30;
      totalDueText = 'now';
    }
  }

  return (
    <Box flexGrow={999}>
      {isLoading ? (
        <>
          <SkeletonText width="100%" noOfLines={2} mb={8} mt={2} />
          <SkeletonText width="100%" noOfLines={2} mb={8} />
          <Divider borderColor="whiteAlpha.400" mb={8} />
          <SkeletonText width="100%" noOfLines={2} />
        </>
      ) : (
        <>
          <Flex mb={1}>
            <Box flexGrow={999}>
              <Text>Coa Membership</Text>
              <Text fontSize="sm" color="grayAlpha.700">
                Billed monthly, starting in {billingFrequency.toString()} days.
              </Text>
              <Text fontSize="sm" color="grayAlpha.700">
                Cancel anytime!
              </Text>
            </Box>
            <Text letterSpacing="0.5px">{formatPriceCents(orderSummary.priceCents)}</Text>
          </Flex>
          {orderSummary.promotionCode?.active ? (
            <Flex alignItems="space-between" mt={4}>
              <Box flexGrow={999}>
                <Tag bg="evergreen.300" p={2} variant="solid">
                  <TagLeftIcon as={TagIcon} />
                  <TagLabel color="white" fontSize="md">
                    {orderSummary.promotionCode.code}
                  </TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      setRequestedPromoCode(undefined);
                    }}
                  />
                </Tag>
                <Text color="grayAlpha.700">{formatCouponLabel(orderSummary.coupon)}</Text>
              </Box>
              <Text letterSpacing="0.5px" color="graphAlpha.700">
                {formatPriceCents(orderSummary.discount.amountOff * -1)}
              </Text>
            </Flex>
          ) : (
            <SubscribeFormPromoCodeInput />
          )}
          {orderSummary.promotionCode?.active ? (
            <Flex alignItems="space-between">
              <Box flexGrow={999}>
                <Text fontSize="lg">
                  <strong>Total due {totalDueText}</strong>
                </Text>
              </Box>
              <Box>
                <Text fontSize="lg" letterSpacing="0.5px">
                  <strong>
                    {formatPriceCents(
                      _.isUndefined(orderSummary.discount)
                        ? orderSummary.priceCents
                        : orderSummary.discount.temporaryPriceCents
                    )}
                  </strong>
                </Text>
              </Box>
            </Flex>
          ) : null}
          {orderSummary.discount ? (
            <Box textAlign="right" mt={2}>
              <Text color="whiteAlpha.700" fontSize="sm">
                {Boolean(orderSummary.discount.temporaryPriceEndsAt) ? (
                  <em>
                    You will be charged {formatPriceCents(orderSummary.priceCents)} starting{' '}
                    {formatProseDateShort(orderSummary.discount.temporaryPriceEndsAt)}.
                  </em>
                ) : null}
              </Text>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};
