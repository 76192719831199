import { createJsonApiAxiosClient } from '@coa/api/lib/axios';
import { useMutation, useQueryClient } from 'react-query';
import { AdminCreateEnrollee } from './types';

const generateCreateEnrollment = (workshopId: string) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/admin/workshops/${workshopId}/enrollees`;

  const fn = async (body: AdminCreateEnrollee.Request['body']) => {
    const { data } = await client.post<AdminCreateEnrollee.Response>(path, body);
    return data;
  };

  return { path, fn };
};

export const useCreateEnrollmentMutation = (workshopId: string) => {
  const { path: postQueryKey, fn } = generateCreateEnrollment(workshopId);
  const queryClient = useQueryClient();

  return useMutation<
    AdminCreateEnrollee.Response,
    AdminCreateEnrollee.Error,
    AdminCreateEnrollee.Request['body']
  >(postQueryKey, {
    mutationFn: (body) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries(postQueryKey);
    },
    onSettled: () => queryClient.invalidateQueries(postQueryKey),
    onError: (err) => {
      throw new Error(err.response.data.errors.base);
    },
  });
};
