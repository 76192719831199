import { Question } from '@coa/api/models/workout/questions';
import {
  FormWorkoutScreenKind,
  NonFormWorkoutScreenKind,
  WorkoutScreen,
  workoutScreenKinds,
  WorkoutScreenKindToWorkoutScreenParams,
} from '@coa/api/models/workout/screens';
import {
  WorkoutSheet,
  workoutSheetKinds,
  WorkoutSheetKindToWorkoutSheetParams,
} from '@coa/api/models/workout/sheets';
import { AdminSerializedWorkout } from '@coa/api/serializers/admin/AdminSerializedWorkout';

export type CmsFormDataSheet<
  Kind extends keyof WorkoutSheetKindToWorkoutSheetParams = keyof WorkoutSheetKindToWorkoutSheetParams
> = Kind extends typeof workoutSheetKinds.baseFormSheet
  ? Omit<WorkoutSheet<Kind>, 'questions' | 'questionSequence'> & { questions: Array<Question> }
  : WorkoutSheet<Kind>;

export type CmsFormDataSheetParams =
  | {
      sheet?: never;
      hasSheet: false;
    }
  | {
      sheet: CmsFormDataSheet;
      hasSheet: true;
    };

export type AdminWorkoutScreenCmsFormData<
  Kind extends keyof WorkoutScreenKindToWorkoutScreenParams = keyof WorkoutScreenKindToWorkoutScreenParams
> = (Kind extends FormWorkoutScreenKind
  ? Omit<WorkoutScreen<Kind>, 'questions' | 'questionSequence'> & {
      questions: Array<Question>;
    }
  : Kind extends NonFormWorkoutScreenKind
  ? WorkoutScreen<Kind>
  : never) &
  CmsFormDataSheetParams;

export type AdminWorkoutCmsFormData = Omit<AdminSerializedWorkout, 'screens' | 'screenSequence'> & {
  screens: Array<AdminWorkoutScreenCmsFormData>;
};

export const isBaseFormScreenCmsFormData = (
  screenCmsFormData: AdminWorkoutScreenCmsFormData
): screenCmsFormData is AdminWorkoutScreenCmsFormData<'base_form_screen'> =>
  screenCmsFormData.kind === workoutScreenKinds.baseFormScreen;

export const isBaseFormSheetCmsFormData = (
  screenCmsFormDataSheet: CmsFormDataSheet
): screenCmsFormDataSheet is CmsFormDataSheet<'base_form_sheet'> =>
  screenCmsFormDataSheet.kind === workoutSheetKinds.baseFormSheet;
