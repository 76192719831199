import { AdminSerializedExercise } from '@coa/api/serializers/admin/AdminSerializedExercise';
import { clientCase } from '@coa/stdlib/string';
import { UnpackArray } from '@coa/types';
import { AdminCmsExerciseFormData } from '../types';

export const formatSerializedExerciseStepToCmsFormData = (
  serializedExerciseStep: UnpackArray<AdminSerializedExercise['exerciseSteps']>
) => {
  const { formQuestions, formQuestionSequence, ...rest } = serializedExerciseStep;
  return {
    ...rest,
    formQuestions: formQuestionSequence.map((questionId) => {
      const question = formQuestions[clientCase(questionId)];
      return {
        id: questionId,
        ...question,
        ...(question.options
          ? {
              options: question.options.map(({ followupQuestions, ...optionRest }) => ({
                ...optionRest,
                ...(followupQuestions
                  ? {
                      followupQuestions: followupQuestions.map((followUpQuestionId) => ({
                        id: followUpQuestionId,
                        ...formQuestions[clientCase(followUpQuestionId)],
                      })),
                    }
                  : {}),
              })),
            }
          : {}),
      };
    }),
  };
};

export const formatSerializedExerciseToCmsFormData = (
  serializedExercise: AdminSerializedExercise
): AdminCmsExerciseFormData => {
  const { exerciseSteps, ...rest } = serializedExercise;
  return {
    ...rest,
    exerciseSteps: (exerciseSteps.map(
      formatSerializedExerciseStepToCmsFormData
      /*
       * It's unclear why this is failing, though it appears
       * to be a mismatch related to the answer property. Uncertain.
       * Regardless, that property isn't relevant to the CMS
       * so we assert for velocity.
       */
    ) as unknown) as AdminCmsExerciseFormData['exerciseSteps'],
  };
};
