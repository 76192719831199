import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace TryPushupsAnalytics {
  type EventName = 'Viewed Try Pushups' | 'Selected Pushup' | 'Enrolled in Pushup';

  type EventNameToEventBody = {
    'Viewed Try Pushups': never;
    'Selected Pushup': { workshopId: string };
    'Enrolled in Pushup': { workshopId: string };
  };
}

export const tryPushupsAnalytics = createSafeAnalyticsClient<
  TryPushupsAnalytics.EventName,
  TryPushupsAnalytics.EventNameToEventBody
>();
