import { Flex, Td } from '@chakra-ui/react';
import {
  useAdminCreatePollMutation,
  useAdminIndexPollsQuery,
  useAdminShowPollQuery,
  useAdminUpdatePollMutation,
} from '@coa/api/controllers/admin/polls';
import { PollRecord } from '@coa/api/models/poll';
import { AdminSerializedPoll } from '@coa/api/serializers/admin/AdminSerializedPoll';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { formSchema, formUiSchema } from './formSchema';
import { pollsCmsHelperText } from './pollsCmsContent';
import { AdminCmsPollFormData } from './types';

/*
 * We infer the sortIndex here and then rely on the backend's
 * use of it as a default scope to re-order the entries in
 * the response.
 */
const parseCmsFormDataToSerializedPoll = (pollFormData: AdminCmsPollFormData) => {
  const { pollOptions: pollOptionsWithoutSortIndex } = pollFormData;
  const pollOptions = pollOptionsWithoutSortIndex.map((pollOption, sortIndex) => ({
    ...pollOption,
    sortIndex,
  }));
  return { ...pollFormData, pollOptions };
};

export const AdminPollsCmsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <CmsContextProvider<PollRecord, AdminSerializedPoll, AdminCmsPollFormData>
    value={{
      baseRouterPath: '/admin-dash/cms/polls',
      contentTypeTitle: 'Poll',
      contentHelperText: pollsCmsHelperText,
      searchPlaceholder: 'Search polls by title, eg. "Does it feel hard to maintain friendships?"',

      formSchema,
      formUiSchema,

      parseCmsFormDataToSerializedResource: parseCmsFormDataToSerializedPoll,

      useShowQuery: useAdminShowPollQuery,
      useIndexQuery: useAdminIndexPollsQuery,
      useUpdateMutation: useAdminUpdatePollMutation,
      useCreateMutation: useAdminCreatePollMutation,

      getEntryTitle: ({ label }) => label,
      indexThEntries: [{ name: 'label', label: 'Label ' }],
      renderIndexEntryTdContents: function PollIndexEntryTd({ label }: AdminSerializedPoll) {
        return (
          <Td>
            <Flex alignItems="center">
              <strong>{label}</strong>
            </Flex>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminPollsView = () => (
  <AdminPollsCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/polls/new">
        <AdminCmsCreateView<PollRecord, AdminSerializedPoll, AdminCmsPollFormData> />
      </Route>
      <Route path="/admin-dash/cms/polls/:id">
        <AdminCmsShowView<PollRecord, AdminSerializedPoll, AdminCmsPollFormData> />
      </Route>
      <Route path="/admin-dash/cms/polls">
        <AdminCmsIndexView<PollRecord, AdminSerializedPoll, AdminCmsPollFormData> />
      </Route>
    </Switch>
  </AdminPollsCmsContextProvider>
);
