import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace MoneyAnalytics {
  type EventName =
    | 'Submitted Credit Card Info'
    | 'Submitted Bad Credit Card'
    | 'Purchased Subscription'
    | 'Viewed Membership Modal'
    | 'Clicked Membership from Profile Notification';

  type EventNameToEventBody = {
    'Submitted Credit Card Info': never;
    'Submitted Bad Credit Card': {
      message: string;
    };
    'Purchased Subscription': never;
    'Viewed Membership Modal': { feature: string };
    'Clicked Membership from Profile Notification': never;
  };
}

export const moneyAnalytics = createSafeAnalyticsClient<
  MoneyAnalytics.EventName,
  MoneyAnalytics.EventNameToEventBody
>();
