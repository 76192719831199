import { Workout } from '@coa/api/models';
import { AdminSerializedWorkout } from '@coa/api/serializers/admin/AdminSerializedWorkout';
import { WidgetProps } from '@rjsf/core';
import React from 'react';
import { AdminWorkoutCmsContextProvider } from '../../workouts/AdminCmsWorkoutsView';
import { AdminWorkoutCmsFormData } from '../../workouts/types';
import { AdminCmsReferenceIdWidget } from '../AdminCmsReferenceIdWidget';

export const AdminCmsWorkoutIdReferenceWidget = (props: WidgetProps) => (
  <AdminWorkoutCmsContextProvider>
    <AdminCmsReferenceIdWidget<Workout, AdminSerializedWorkout, AdminWorkoutCmsFormData>
      {...props}
    />
  </AdminWorkoutCmsContextProvider>
);
