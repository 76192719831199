import { clientCase } from '@coa/stdlib/string';
import _ from 'lodash';
import { ExerciseStep, ExerciseStepAnswer, getIsExerciseStepQuestionWithOptions } from '../types';

// Note accounting for odd case where _.isEmpty(number) returns true.
export const isEmptyAnswer = (v: ExerciseStepAnswer) => _.isEmpty(v) && !_.isNumber(v);

/*
 * Checks if an Exercise Step is complete given the answers
 * provided by recursively traversing its children.
 *
 * This is simly a DFS whose interface is modified to support
 * the needs of our Exercise forms. In the future we may wish
 * to move some of this functionality to a more generic util
 * under lib/graph.
 *
 * =============================================
 * !!!!! CLIENT/SERVER DUPLICATION WARNING !!!!!
 * =============================================
 * @see ./app/services/exercise_checker.rb
 */
export const getIsExerciseStepIncomplete = (
  formQuestionSequence: ExerciseStep['formQuestionSequence'],
  formQuestions: ExerciseStep['formQuestions'],
  result?: boolean
): boolean =>
  result ||
  formQuestionSequence.reduce((acc: boolean, questionId) => {
    if (acc) return acc;
    const question = formQuestions[clientCase(questionId)];
    if (isEmptyAnswer(question.answer)) {
      return true;
    }
    if (getIsExerciseStepQuestionWithOptions(question)) {
      const { answer: answersArr, options } = question;
      return options.reduce((_acc, { label, followupQuestions = [] }) => {
        if (_acc) return _acc;
        if (answersArr.includes(label)) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          return getIsExerciseStepIncomplete(followupQuestions, formQuestions, false);
        }
        return _acc;
      }, false);
    }
    // If it doesn't have options, a simple emptiness check suffices
    // so we can assume validity and, therefore, completeness.
    return false;
  }, false);
