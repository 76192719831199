import { Box, Button, Checkbox, FormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import PoweredByStripe from '../images/stripe.svg';
import { moneyAnalytics } from '../lib/analytics/clients';
import CardSection from './CardSection';
import NameSection from './NameSection';

class CreditCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { firstName: '', lastName: '', submitting: false };
    this.updateFirstName = this.updateFirstName.bind(this);
    this.updateLastName = this.updateLastName.bind(this);
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ submitting: true });
    const { firstName, lastName } = this.state;
    const { stripe, elements, savePaymentMethod, onError } = this.props;
    const cardElement = elements.getElement('card');
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { name: [firstName, lastName].join(' ') },
      })
      .then((d) => {
        this.setState({ submitting: false });
        if (d.error) {
          onError(d.error);
          moneyAnalytics.track('Submitted Bad Credit Card', { message: d.error.message });
        } else {
          savePaymentMethod(d);
        }
      });
    moneyAnalytics.track('Submitted Credit Card Info');
  };

  updateFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  updateLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  render() {
    const { firstName, lastName, submitting } = this.state;
    const { cta, showSaveForLater, setSaveForLater, disabled } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box />
        <NameSection
          firstName={firstName}
          updateFirstName={this.updateFirstName}
          lastName={lastName}
          updateLastName={this.updateLastName}
        />
        <FormLabel htmlFor="card">Your Credit Card</FormLabel>
        <CardSection />
        <Box />
        <Box my="16px">
          <PoweredByStripe />
        </Box>
        {showSaveForLater && (
          <Box mb={4}>
            <Checkbox
              defaultIsChecked
              onChange={(e) => setSaveForLater(e.target.checked)}
              colorScheme="evergreen"
              borderColor="green.500"
            >
              Save card for future classes
            </Checkbox>
          </Box>
        )}
        <Button
          disabled={disabled || submitting}
          colorScheme="evergreen"
          type="submit"
          data-cy="cc-cta"
        >
          {cta || 'Save Card'}
        </Button>
      </form>
    );
  }
}

CreditCardForm.propTypes = {
  savePaymentMethod: PropTypes.func,
  setSaveForLater: PropTypes.func,
  showSaveForLater: PropTypes.bool,
  cta: PropTypes.string,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  elements: PropTypes.object,
  stripe: PropTypes.object,
};

export default injectStripe(CreditCardForm);
