import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { dateFormats, titleCase } from '@coa/stdlib/string';
import { formatProseDateShort } from '@coa/stdlib/string/time';
import * as dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import * as enrolleeActions from '../../actions/admin/enrollees';
import * as workshopActions from '../../actions/admin/workshops';
import { workshopDetails } from '../../actions/workshops';
import { getReadableDiversitySurveyAnswer } from '../../resources/member';
import { formatLegacySnakeJsonApiWorkshopTimeLabel } from '../../resources/workshops';
import { EditIcon } from '../Icons';
import Loading from '../Loading';

const STATUS_COLORS = {
  pending: 'gray',
  approved: 'green',
  rejected: 'red',
  application_incomplete: 'orange',
  awaiting_info: 'orange',
  canceled: 'gray',
};

const FIT_TABS = [
  { filterKey: 'all', label: 'All', color: 'gray' },
  { filterKey: 'uncategorized', label: 'Uncategorized', color: 'gray' },
  { filterKey: 'not_a_fit', label: 'Not a Fit', color: 'red' },
  { filterKey: 'maybe_a_fit', label: 'Maybe a Fit', color: 'yellow' },
  { filterKey: 'good_fit', label: 'Good Fit', color: 'lime' },
];

const TransferWorkshopForm = ({ isOpen, onClose, workshopId, enrolleeId }) => {
  const dispatch = useDispatch();
  const { gettingWorkshops, workshopOccurrences, workshopPages } = useSelector(
    (state) => state.admin.workshops
  );

  const toast = useToast();

  const workshops = useSelector((state) =>
    _.chain(state.admin.workshops.workshops)
      .values()
      .filter((w) => w.id !== workshopId)
      .sortBy(
        (w) => workshopOccurrences[w.id][0] && workshopOccurrences[w.id][0].attributes.starts_at
      )
      .value()
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [chargeTheDifference, setChargeTheDifference] = React.useState(true);

  // Range is exclusive, so we add 1.
  const workshopPageNumbers = _.range(1, workshopPages + 1);

  useEffect(() => {
    dispatch(workshopActions.list(currentPage));
  }, [dispatch, currentPage]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={{ toWorkshopId: '' }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(
              enrolleeActions.transfer(
                workshopId,
                enrolleeId,
                values.toWorkshopId,
                chargeTheDifference
              )
            )
              .then((result) => {
                if (result.errors) {
                  const errorMessage = result.errors.to_workshop_id
                    ? 'Please pick a class.'
                    : result.errors.message;
                  toast({
                    title: errorMessage,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: 'Enrollee successfully transferred!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  onClose();
                  dispatch(enrolleeActions.list(workshopId));
                }
              })
              .catch((error) => alert(JSON.stringify(error)))
              .finally(() => setSubmitting(false));
          }}
        >
          {(formikProps) => (
            <Form>
              <ModalHeader>
                <Heading>Transfer Class</Heading>
              </ModalHeader>
              <ModalCloseButton borderRadius="50%" bg="green.50" />
              <ModalBody>
                {gettingWorkshops ? (
                  <Center w="stretch">
                    <Loading />
                  </Center>
                ) : (
                  <Field name="toWorkshopId">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel mt={3} htmlFor="toWorkshopId">
                          To which Class should they be transfered?
                        </FormLabel>
                        <Select {...field} placeholder="Pick a Class">
                          {workshops.map((workshop) => (
                            <option key={workshop.id} value={workshop.id}>
                              ({workshop.id}) {workshop.attributes.title}{' '}
                              {formatLegacySnakeJsonApiWorkshopTimeLabel(
                                workshop,
                                workshopOccurrences[workshop.id]
                              )}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                )}
                <Checkbox
                  my={2}
                  isChecked={chargeTheDifference}
                  onChange={(e) => setChargeTheDifference(e.target.checked)}
                >
                  If the prices are different, try to charge the difference
                </Checkbox>
              </ModalBody>
              <ModalFooter justifyContent="space-between">
                <ButtonGroup size="sm" isAttached py={4}>
                  {workshopPageNumbers.map((index) => (
                    <Button
                      key={index}
                      colorScheme="warm"
                      disabled={currentPage === index}
                      onClick={() => setCurrentPage(index)}
                    >
                      {index}
                    </Button>
                  ))}
                </ButtonGroup>
                <Button isLoading={formikProps.isSubmitting} type="submit" colorScheme="evergreen">
                  {formikProps.isSubmitting ? 'Transferring' : 'Transfer'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

TransferWorkshopForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  workshopId: PropTypes.string.isRequired,
  enrolleeId: PropTypes.string,
};

const getFormatProfileAnswer = (key) => {
  switch (key) {
    case 'birthday': {
      return (value) => {
        const utcBirthday = dayjs(value).utc();
        const formattedBirthday = utcBirthday.format(dateFormats.proseDateLong);
        return formattedBirthday;
      };
    }
    case 'diversity':
      return getReadableDiversitySurveyAnswer;
    default:
      return _.identity;
  }
};

const getIsUnder18YearsOld = (isoBirthdateStr) => {
  const minDate = dayjs().subtract(18, 'year');
  const birthDate = dayjs(isoBirthdateStr);
  return minDate.diff(birthDate) < 0;
};

const WorkshopEnrollees = () => {
  const { id: workshopId } = useParams();
  const {
    isOpen: isTransferWorkshopModalOpen,
    onOpen: onTransferWorkshopModalOpen,
    onClose: onTransferWorkshopModalClose,
  } = useDisclosure();

  const dispatch = useDispatch();

  const { enrollees, gettingEnrollees } = useSelector((state) => state.admin.enrollees);

  const { selectedWorkshop, gettingSelectedWorkshop, includedWorkshopOccurrences } = useSelector(
    (state) => state.workshops
  );

  const [transferringEnrolleeId, setTransferringEnrolleeId] = useState(null);

  useEffect(() => {
    dispatch(workshopDetails(workshopId));
    dispatch(enrolleeActions.list(workshopId));
  }, [dispatch, workshopId]);

  const [fitAssessmentFilterKey, setFitAssessmentFilterKey] = useState('all');
  const setFitAssessmentFilterKeyFromIndex = (index) => {
    const tab = FIT_TABS[index];
    setFitAssessmentFilterKey(tab.filterKey);
  };
  const allEnrollees = Object.values(enrollees);
  const groupedEnrollees = {
    all: allEnrollees,
    ..._.groupBy(allEnrollees, (e) => e.attributes.fit_assessment),
  };
  const filteredEnrollees = groupedEnrollees[fitAssessmentFilterKey] || [];
  const updateFitAssessment = (enrolleeId, newFitAssessment) => {
    dispatch(enrolleeActions.update(workshopId, enrolleeId, newFitAssessment));
  };

  // TODO: this is not sustainable, refactor away from redux
  const getEnrollmentType = (enrollment) => {
    if (selectedWorkshop === null) return;
    const { drop_in_ids } = enrollment.attributes;
    let enrollmentType = 'Entire Series';
    if (drop_in_ids && Object.keys(drop_in_ids)) {
      const dropInIds = Object.keys(drop_in_ids);
      const workshopOccurences = includedWorkshopOccurrences[selectedWorkshop?.id];
      const dropInStartDates = dropInIds.map((id) => {
        const workshopOccurence = workshopOccurences.find((wo) => wo.id === id);
        return workshopOccurence.attributes.starts_at;
      });
      const dropInDatesStr = dropInStartDates.map((startDate) => formatProseDateShort(startDate));
      enrollmentType = `Drop In (${dropInDatesStr.join(', ')})`;
    }
    return enrollmentType;
  };

  return (
    <Container maxWidth="container.xl">
      <Flex justify="space-between">
        <Heading>
          Enrollees in {!gettingSelectedWorkshop && selectedWorkshop.attributes.title}
        </Heading>
        <Link as={RouterLink} to={`/admin-dash/enrollments/new?workshopId=${workshopId}`}>
          <Button variant="secondary">+ Enroll User</Button>
        </Link>
      </Flex>
      <Flex my={6} alignItems="center" justify="center" width="stretch">
        <Tabs
          defaultIndex={0}
          variant="soft-rounded"
          colorScheme="green"
          size="sm"
          onChange={setFitAssessmentFilterKeyFromIndex}
        >
          <TabList>
            {FIT_TABS.map((tab) => (
              <Tab color={`${tab.color}.500`} key={tab.label}>
                {tab.label} ({groupedEnrollees[tab.filterKey]?.length || 0})
              </Tab>
            ))}
          </TabList>
        </Tabs>
        {!gettingSelectedWorkshop && (
          <HStack>
            <Text>
              {formatLegacySnakeJsonApiWorkshopTimeLabel(
                selectedWorkshop,
                includedWorkshopOccurrences[selectedWorkshop.id]
              )}
            </Text>
            <Badge colorScheme="green">
              {_.filter(enrollees, (e) => e.attributes.status === 'approved').length} Approved
            </Badge>
          </HStack>
        )}
      </Flex>
      <Center>
        {gettingEnrollees ? (
          <Loading />
        ) : (
          <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
            <Stack>
              {filteredEnrollees.map((enrollee) => (
                <Stack
                  key={enrollee.id}
                  borderColor="gray"
                  borderWidth={1}
                  p={5}
                  borderRadius={5}
                  spacing={4}
                >
                  <Stack>
                    <HStack>
                      <Heading fontSize="md" width="stretch">
                        {enrollee.attributes.name} {enrollee.attributes.pronouns}
                      </Heading>

                      <Text>{enrollee.attributes.email}</Text>
                      <Badge colorScheme={STATUS_COLORS[enrollee.attributes.status]}>
                        {enrollee.attributes.status}
                      </Badge>
                      <ButtonGroup justifyContent="flex-end" width="stretch" isAttached>
                        {enrollee.attributes.status !== 'rejected' && (
                          <Button
                            colorScheme="warm"
                            onClick={() => {
                              setTransferringEnrolleeId(enrollee.id);
                              onTransferWorkshopModalOpen();
                            }}
                          >
                            Transfer
                          </Button>
                        )}
                        {(enrollee.attributes.status === 'pending' ||
                          enrollee.attributes.status === 'awaiting_info') && (
                          <>
                            {enrollee.attributes.status !== 'awaiting_info' && (
                              <Button
                                colorScheme="lime"
                                onClick={() =>
                                  dispatch(enrolleeActions.requestInfo(workshopId, enrollee.id))
                                }
                              >
                                Request Info
                              </Button>
                            )}
                            <Button
                              colorScheme="evergreen"
                              onClick={() =>
                                dispatch(enrolleeActions.approve(workshopId, enrollee.id))
                              }
                            >
                              Approve
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() =>
                                dispatch(enrolleeActions.reject(workshopId, enrollee.id))
                              }
                            >
                              Reject
                            </Button>
                          </>
                        )}
                        {enrollee.attributes.status === 'approved' && (
                          <>
                            <Button
                              colorScheme="lime"
                              onClick={() =>
                                dispatch(enrolleeActions.remove(workshopId, enrollee.id))
                              }
                            >
                              Remove
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() =>
                                dispatch(enrolleeActions.removeWithRefund(workshopId, enrollee.id))
                              }
                            >
                              Remove w/ Refund
                            </Button>
                          </>
                        )}
                      </ButtonGroup>
                      <IconButton
                        as={RouterLink}
                        to={`/admin-dash/enrollments/${enrollee.id}`}
                        colorScheme="gray"
                        icon={<EditIcon />}
                      />
                    </HStack>
                    <Text>Enrollment Type: {getEnrollmentType(enrollee)}</Text>
                    <RadioGroup
                      defaultValue={enrollee.attributes.fit_assessment}
                      onChange={(val) => updateFitAssessment(enrollee.id, val)}
                    >
                      <Stack direction="row" mb={4}>
                        {FIT_TABS.filter((o) => o.filterKey !== 'all').map((o) => (
                          <Radio key={o.filterKey} colorScheme={o.color} value={o.filterKey}>
                            {o.label}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </Stack>
                  <Heading size="sm">Application Answers</Heading>
                  <Grid templateColumns="repeat(3, 1fr)" gap={2} mb={4}>
                    {Object.keys(enrollee.attributes.answers || {}).map((key) => {
                      const value = enrollee.attributes.answers[key];
                      const questionAttrs = selectedWorkshop?.attributes.questions.find(
                        ({ key: _key }) => key === _key
                      );
                      const questionLabel =
                        questionAttrs?.admin_question || questionAttrs?.question;

                      return (
                        <Box key={key} bgColor="gray.50" w="100%" px={3} py={2} borderRadius={5}>
                          <Text fontWeight="bold">{questionLabel}</Text>
                          {Array.isArray(value) ? (
                            <>
                              {value.map((answer) => (
                                <Text key={answer}>
                                  <em>
                                    {value.length > 1 ? '• ' : ''}
                                    {answer}
                                  </em>
                                </Text>
                              ))}
                            </>
                          ) : (
                            <Text key={key}>
                              <em>{value}</em>
                            </Text>
                          )}
                        </Box>
                      );
                    })}
                  </Grid>
                  <Heading size="sm">Profile</Heading>
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {Object.keys(enrollee.attributes.member?.attributes || {}).map((key) => {
                      const value = enrollee.attributes.member?.attributes[key];
                      const formatProfileAnswer = getFormatProfileAnswer(key);
                      if (key === 'birthday' && getIsUnder18YearsOld(value)) {
                        return (
                          <Box
                            key={key}
                            bgColor="red.400"
                            color="white"
                            w="100%"
                            px={3}
                            py={2}
                            borderRadius={5}
                          >
                            <Text fontWeight="bold">{titleCase(key)}</Text>
                            <Text>
                              <em>{formatProfileAnswer(value)}</em>
                            </Text>
                            <Text>
                              This member is under the age of 18. Please do not approve their
                              enrollment.
                            </Text>
                          </Box>
                        );
                      }
                      return (
                        <Box key={key} bgColor="gray.50" w="100%" px={3} py={2} borderRadius={5}>
                          <Text fontWeight="bold">{titleCase(key)}</Text>
                          {Array.isArray(value) ? (
                            <>
                              {value.map((answer) => (
                                <Text key={answer}>
                                  <em>
                                    {value.length > 1 ? '• ' : ''}
                                    {formatProfileAnswer(answer)}
                                  </em>
                                </Text>
                              ))}
                            </>
                          ) : (
                            <Text key={key}>
                              <em>{formatProfileAnswer(value)}</em>
                            </Text>
                          )}
                        </Box>
                      );
                    })}
                  </Grid>
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
      </Center>
      <TransferWorkshopForm
        isOpen={isTransferWorkshopModalOpen}
        onClose={onTransferWorkshopModalClose}
        workshopId={workshopId}
        enrolleeId={transferringEnrolleeId}
      />
    </Container>
  );
};

export default WorkshopEnrollees;
