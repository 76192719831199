import {
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useOnMount, useQueryParams } from '@coa/react-utils';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { SearchIcon } from '../../../components/Icons';
import { onDemandAnalytics } from '../../../lib/analytics/clients';
import { getOnDemandRouterUrl } from '../../../routerPaths/onDemandRouterPaths';
import { BrowseByTopic } from './browseByTopic/BrowseByTopic';
import { SearchResults } from './searchResults/SearchResults';

const OnDemandSearchBar = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const searchParams = queryParams.get('q') || '';
  const [searchTerm, setSearchTerm] = useState(searchParams);

  useOnMount(() => {
    onDemandAnalytics.track('Viewed On-Demand Homepage');
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!_.isEmpty(searchTerm)) {
      history.push(getOnDemandRouterUrl.search({ searchQuery: searchTerm }));
    } else {
      history.push(getOnDemandRouterUrl.apex());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl display="flex" w="100%">
        <InputGroup>
          <Input
            variant="coa-main"
            type="text"
            aria-label="Search Input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search all questions, eg. "anxiety", "relationships"'
            size="lg"
          />
          <InputRightElement height="100%" width="4rem">
            <Button
              aria-label="Search Submit"
              type="submit"
              borderLeftRadius={0}
              colorScheme="warm"
              variant="link"
              height="100%"
              width="100%"
            >
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </form>
  );
};

export const OnDemandHubView = () => (
  <Container maxW="container.lg" px={{ base: 4, md: 8, xl: 0 }} py={{ base: 4, md: 8 }}>
    <Heading size="xl" mb={6}>
      Coa On-Demand
    </Heading>
    <Text fontSize="lg" mb={8}>
      Your questions answered by Coa Instructors, whenever you need them.
    </Text>
    <OnDemandSearchBar />
    <SearchResults />
    <BrowseByTopic />
  </Container>
);
