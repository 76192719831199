import { RouterLink } from '@coa/react-utils';
import * as Sentry from '@sentry/react';
import React from 'react';
import { IconProps } from 'react-feather';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '../../../components/chakra/custom/ToggleButton';
import { CheckCircleIcon, EditIcon, LockIcon } from '../../../components/Icons';
import {
  exerciseStepStatus,
  ExerciseStepStatus,
  GetWorkshopExerciseSteps,
} from '../../../resources/exercises';

const stepStatusToIcon: {
  [key in ExerciseStepStatus]: React.FunctionComponent;
} = {
  [exerciseStepStatus.locked]: LockIcon,
  [exerciseStepStatus.active]: EditIcon,
  [exerciseStepStatus.complete]: CheckCircleIcon,
};

const StepIcon = ({
  status,
  ...rest
}: {
  status: ExerciseStepStatus;
} & IconProps) => {
  let IconComponent = stepStatusToIcon[status];
  if (!IconComponent) {
    // Notify and provide a smart default so that the user isn't disrupted.
    Sentry.captureException(
      new Error(`Could not identify IconComponent for step with status: "${status}"`)
    );
    IconComponent = stepStatusToIcon[exerciseStepStatus.active];
  }
  return <IconComponent {...rest} />;
};

export const ExerciseNav = ({
  steps,
  workshopId,
  exerciseId,
  currentStepId,
  ...rest
}: {
  steps: GetWorkshopExerciseSteps.Response['data'];
  workshopId: string;
  exerciseId: string;
  currentStepId: string;
} & ToggleButtonGroupProps) => (
  <ToggleButtonGroup as="nav" {...rest}>
    {steps.map(({ id: _stepId, attributes: { title: stepTitle, status } }) => {
      const selected = _stepId === currentStepId;

      return (
        <ToggleButton
          selected={selected}
          disabled={status === 'locked'}
          key={_stepId}
          leftIcon={<StepIcon status={status} />}
          data-cy={`exercise-nav-entry${selected ? '-selected' : ''}`}
          {...(status === 'locked'
            ? {}
            : {
                as: RouterLink,
                to: `/classes/${workshopId}/exercises/${exerciseId}/steps/${_stepId}`,
              })}
        >
          {stepTitle}
        </ToggleButton>
      );
    })}
  </ToggleButtonGroup>
);
