import { createJsonApiAxiosClient } from '@coa/api/lib/axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { getAdminEnrollmentsApiUrl } from './apiPaths';
import {
  AdminCreateEnrollment,
  AdminDeleteEnrollment,
  AdminGetEnrollment,
  AdminUpdateEnrollment,
} from './types';

const generateAdminGetEnrollment = ({ id }: AdminGetEnrollment.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAdminEnrollmentsApiUrl.show(id);

  const fn = async () => {
    const { data } = await client.get<AdminGetEnrollment.Response>(path);
    return data;
  };

  return { path, fn };
};
export const useAdminGetEnrollmentQuery = (
  { id }: AdminGetEnrollment.Request['pathParams'],
  ops: UseQueryOptions<AdminGetEnrollment.Response> = {}
) => {
  const { path: queryKey, fn } = generateAdminGetEnrollment({ id });

  return useQuery<AdminGetEnrollment.Response>(queryKey, fn, ops);
};

const generateAdminCreateEnrollment = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAdminEnrollmentsApiUrl.create();

  const fn = async (body: AdminCreateEnrollment.Request['body']) => {
    const { data } = await client.post<AdminCreateEnrollment.Response>(path, body);
    return data;
  };

  return { path, fn };
};

export const useAdminCreateEnrollmentMutation = () => {
  const { path: postQueryKey, fn } = generateAdminCreateEnrollment();
  const queryClient = useQueryClient();

  return useMutation<
    AdminCreateEnrollment.Response,
    AdminCreateEnrollment.Error,
    AdminCreateEnrollment.Request['body']
  >(postQueryKey, {
    mutationFn: (body) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries(postQueryKey);
    },
    onSettled: () => queryClient.invalidateQueries(postQueryKey),
    onError: (err) => {
      throw new Error(err.response.data.errors.base);
    },
  });
};

export const generateAdminUpdateEnrollment = ({
  id,
}: AdminUpdateEnrollment.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAdminEnrollmentsApiUrl.update(id);

  const fn = async (body: AdminUpdateEnrollment.Request['body']) => {
    const { data } = await client.patch(path, body);
    return data;
  };

  return { path, fn };
};

export const useAdminEnrollmentUpdateMutation = ({
  id,
}: AdminUpdateEnrollment.Request['pathParams']) => {
  const { path: patchQuery, fn } = generateAdminUpdateEnrollment({ id });
  const queryClient = useQueryClient();

  return useMutation<
    AdminUpdateEnrollment.Response,
    AdminUpdateEnrollment.Error,
    AdminUpdateEnrollment.Request['body']
  >(patchQuery, {
    mutationFn: (body) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries(patchQuery);
    },
    onSettled: () => queryClient.invalidateQueries(patchQuery),
    onError: (err) => {
      throw new Error(err.response.data.errors.base);
    },
  });
};

export const generateAdminDeleteEnrollment = ({
  id,
}: AdminDeleteEnrollment.Request['pathParams']) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAdminEnrollmentsApiUrl.destroy(id);

  const fn = async () => {
    const { data } = await client.delete(path);
    return data;
  };

  return { path, fn };
};

export const useAdminEnrollmentDeleteMutation = ({
  id,
}: AdminDeleteEnrollment.Request['pathParams']) => {
  const { path: queryKey, fn: mutationFn } = generateAdminDeleteEnrollment({ id });
  const queryClient = useQueryClient();

  return useMutation<AdminDeleteEnrollment.Response, AdminDeleteEnrollment.Error>(
    // TODO: The naming here is sorta tricky, but this queryKey is the same as the corresponding
    // mutationKey. Should figure out a better way to represent this.
    queryKey,
    {
      mutationFn,
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);
      },
      onSettled: () => queryClient.invalidateQueries(queryKey),
      onError: (err) => {
        throw new Error(err.response.data.errors.base);
      },
    }
  );
};
