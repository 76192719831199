import { Member } from '../../../../resources/member';
import { Workshop } from '../../../../resources/workshops';

type ReducerState = {
  isSubmitting: boolean;
  selectedMember: Member | null;
  selectedWorkshop: Workshop | null;
};

type Action =
  | { type: 'SET_SELECTED_WORKSHOP'; workshop: Workshop }
  | { type: 'SET_SELECTED_MEMBER'; member: Member }
  | { type: 'SUBMITTING' }
  | { type: 'SUBMIT_SUCCESS' }
  | { type: 'RESET_FORM' };

export const getInitialReducerValues = () => ({
  isSubmitting: false,
  selectedMember: null,
  selectedWorkshop: null,
});

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  switch (action.type) {
    case 'SET_SELECTED_WORKSHOP':
      return {
        ...state,
        selectedWorkshop: action.workshop,
      };
    case 'SET_SELECTED_MEMBER':
      return {
        ...state,
        selectedMember: action.member,
      };
    case 'SUBMITTING':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        selectedMember: null,
        selectedWorkshop: null,
      };
    case 'RESET_FORM':
      return {
        ...state,
        isSubmitting: false,
        selectedMember: null,
        selectedWorkshop: null,
      };
    default:
      throw new Error('Invalid Action Type');
  }
};
