export const CLEAROUT_INVOICE = 'CLEAROUT_INVOICE';

export const INVOICES_REQUEST = 'INVOICES_REQUEST';
export const INVOICES_SUCCESS = 'INVOICES_SUCCESS';
export const INVOICES_FAILURE = 'INVOICES_FAILURE';

export function create(therapistId, appointmentId, data) {
  return {
    type: INVOICES_REQUEST,
    api: {
      data,
      endpoint: `therapists/${therapistId}/appointments/${appointmentId}/invoices`,
      authenticated: true,
      types: [INVOICES_REQUEST, INVOICES_SUCCESS, INVOICES_FAILURE],
      method: 'POST',
    },
  };
}

export const INVOICES_LIST_REQUEST = 'INVOICES_LIST_REQUEST';
export const INVOICES_LIST_SUCCESS = 'INVOICES_LIST_SUCCESS';
export const INVOICES_LIST_FAILURE = 'INVOICES_LIST_FAILURE';

export function therapistList(therapistId) {
  return {
    type: INVOICES_LIST_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/invoices`,
      authenticated: true,
      types: [INVOICES_LIST_REQUEST, INVOICES_LIST_SUCCESS, INVOICES_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export function clientList(clientId) {
  return {
    type: INVOICES_LIST_REQUEST,
    api: {
      endpoint: `clients/${clientId}/invoices`,
      authenticated: true,
      types: [INVOICES_LIST_REQUEST, INVOICES_LIST_SUCCESS, INVOICES_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export const INVOICES_PAY_REQUEST = 'INVOICES_PAY_REQUEST';
export const INVOICES_PAY_SUCCESS = 'INVOICES_PAY_SUCCESS';
export const INVOICES_PAY_FAILURE = 'INVOICES_PAY_FAILURE';

export function pay(clientId, invoiceId) {
  return {
    type: INVOICES_PAY_REQUEST,
    api: {
      endpoint: `clients/${clientId}/invoices/${invoiceId}/pay`,
      authenticated: true,
      types: [INVOICES_PAY_REQUEST, INVOICES_PAY_SUCCESS, INVOICES_PAY_FAILURE],
      method: 'POST',
    },
  };
}

export const INVOICES_VOID_REQUEST = 'INVOICES_VOID_REQUEST';
export const INVOICES_VOID_SUCCESS = 'INVOICES_VOID_SUCCESS';
export const INVOICES_VOID_FAILURE = 'INVOICES_VOID_FAILURE';

export function markAsVoid(therapistId, invoiceId) {
  return {
    type: INVOICES_VOID_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/invoices/${invoiceId}/mark_as_void`,
      authenticated: true,
      types: [INVOICES_VOID_REQUEST, INVOICES_VOID_SUCCESS, INVOICES_VOID_FAILURE],
      method: 'POST',
    },
  };
}

export const INVOICES_REFUND_REQUEST = 'INVOICES_REFUND_REQUEST';
export const INVOICES_REFUND_SUCCESS = 'INVOICES_REFUND_SUCCESS';
export const INVOICES_REFUND_FAILURE = 'INVOICES_REFUND_FAILURE';

export function refund(therapistId, invoiceId) {
  return {
    type: INVOICES_REFUND_REQUEST,
    api: {
      endpoint: `therapists/${therapistId}/invoices/${invoiceId}/refund`,
      authenticated: true,
      types: [INVOICES_REFUND_REQUEST, INVOICES_REFUND_SUCCESS, INVOICES_REFUND_FAILURE],
      method: 'POST',
    },
  };
}
