import { createAxiosClient } from '@coa/api/lib/axios';

const CURRENT_API_VERSION = 'v1';

export const ApiWrapper = {
  call: ({ endpoint, method, data, authenticated: auth, admin }) => {
    const args = { method, data, headers: { 'Content-Type': 'application/json' } };
    const client = createAxiosClient({ auth });

    const namespace = admin ? 'admin' : CURRENT_API_VERSION;
    const { BASE_URL } = window;
    args.url = [BASE_URL, namespace, endpoint].join('/');

    return client(args);
  },
};
