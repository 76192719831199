import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideFade,
} from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { useBackFn } from '@coa/react-utils';
import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../../../../lib/analytics/AnalyticsProvider';
import { SubscribeForm } from './SubscribeForm';
import { SubscribeModalOrderSummaryProvider } from './SubscribeModalOrderSummaryProvider';
import { SubscribeModalStaticContent } from './SubscribeModalStaticContent';

type SubscribeModalProps = {
  isOpen: boolean;
  renderSubscribeSuccessState: () => React.ReactNode;
  backRouterUrl: string;
  title?: React.ReactNode;
};

export const SubscribeModal = ({
  isOpen,
  renderSubscribeSuccessState,
  backRouterUrl,
  title,
}: SubscribeModalProps): ReactElement | null => {
  const location = useLocation();
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const { safeAnalyticsClient } = useAnalytics();
  const { backFn } = useBackFn({
    path: backRouterUrl,
    exact: true,
  });

  useEffect(() => {
    const pathnameSections = location.pathname.split('/');
    const pathIndex = pathnameSections[1];
    if (isOpen) {
      /*
       * Note: The use of the `feature` key below predates the availability of router
       * path information on global analytics events. Now that that exists, we should
       * be able to get rid of it.
       *
       * For the time being, we leave it in case this would break existing dashboards.
       */
      safeAnalyticsClient.track('Viewed Membership Modal', { feature: pathIndex });
    }
  }, [isOpen]);

  if (getSubscriptionsQuery.isLoading) return null;
  const hasSubscription = getHasActiveSubscriptionFromQueryResponse(getSubscriptionsQuery.data);

  return (
    <Modal isOpen={isOpen} onClose={backFn} size="5xl">
      <ModalOverlay />
      <ModalContent
        // Prevent inner div from flowing outside the border-radius.
        overflow={{ md: 'hidden' }}
        // Appear as takeover on mobile devices
        marginY={{ base: 0, md: '3.75rem' }}
        borderRadius={{ base: 0, md: 'md' }}
        maxHeight={{ base: 'none', md: undefined }}
      >
        <SubscribeModalOrderSummaryProvider>
          <Flex
            transitionProperty="opacity"
            transitionDuration="300ms"
            transitionTimingFunction="ease"
            display={hasSubscription ? 'none' : 'flex'}
            pointerEvents={hasSubscription ? 'none' : undefined}
            position="relative"
            /*
             * On open, we wish for the initial cursor focus to hit the First Name
             * text input, and therefore it needs to be the first input to appear
             * in the DOM. The PromoCode input is rendered to the left, so in order
             * to manage focus as desired, we re-orient the direction of the children.
             */
            flexDirection={{ base: 'column-reverse', md: 'row-reverse' }}
          >
            <Flex
              width={{ md: '40%' }}
              py={{ base: 8, lg: 12 }}
              px={{ base: 8, md: 8, lg: 12 }}
              minHeight="100%"
              flexDirection="column"
              justifyContent="center"
            >
              <SubscribeForm />
            </Flex>
            <Box
              py={{ base: 8, lg: 16, xl: 20 }}
              px={{ base: 8, md: 8, lg: 12, xl: 20 }}
              bg="green.600"
              width={{ md: '60%' }}
            >
              <SubscribeModalStaticContent title={title} />
            </Box>
          </Flex>
          <SlideFade
            in={hasSubscription}
            style={{
              display: hasSubscription ? 'block' : 'none',
            }}
          >
            {renderSubscribeSuccessState()}
          </SlideFade>
          <ModalCloseButton variant="coa-main" />
        </SubscribeModalOrderSummaryProvider>
      </ModalContent>
    </Modal>
  );
};
