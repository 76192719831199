import { ACCOUNT_UPDATE_SUCCESS } from '../actions/accounts';
import { PASSWORDS_FAILURE, PASSWORDS_REQUEST, PASSWORDS_SUCCESS } from '../actions/passwords';
import {
  REGISTRATIONS_FAILURE,
  REGISTRATIONS_REQUEST,
  REGISTRATIONS_SUCCESS,
} from '../actions/registrations/create';
import { LOG_OUT, SESSIONS_FAILURE, SESSIONS_REQUEST, SESSIONS_SUCCESS } from '../actions/sessions';
import { SafeAnalytics } from '../lib/analytics';
import { safeAttribution } from '../lib/attribution';
import { sessionLocalStorage } from '../lib/localStorage/sessionLocalStorage';

const createDefaultSessionState = () => {
  const currentMember = sessionLocalStorage.getCurrentMember();
  return {
    registering: false,
    loggingIn: false,
    creatingPassword: false,
    errors: {},
    loggedIn: !!currentMember,
    memberId: currentMember ? currentMember.id : null,
    role: currentMember ? currentMember.role : null,
    name: currentMember ? currentMember.name : null,
    email: currentMember ? currentMember.email : null,
  };
};

const ANALYTICS_EVENTS = {
  [PASSWORDS_SUCCESS]: 'Reset Password',
  [SESSIONS_SUCCESS]: 'Signed In',
  [REGISTRATIONS_SUCCESS]: 'Joined Coa',
};

export function session(state = createDefaultSessionState(), action) {
  switch (action.type) {
    case SESSIONS_REQUEST: {
      return { ...state, errors: {}, loggingIn: true };
    }
    case REGISTRATIONS_REQUEST: {
      return { ...state, errors: {}, registering: true };
    }
    case PASSWORDS_REQUEST: {
      return { ...state, errors: {}, creatingPassword: true };
    }
    case PASSWORDS_SUCCESS:
    case SESSIONS_SUCCESS:
    case REGISTRATIONS_SUCCESS: {
      const {
        response: {
          data: { id, attributes },
        },
      } = action;
      const member = { id, ...attributes };
      // TODO: This side-effect shouldn't be managed via redux.
      sessionLocalStorage.create(member);
      const { name, email, role } = attributes;
      const params = safeAttribution.save();
      SafeAnalytics.identify(id, { name, email, role, ...params.query });

      SafeAnalytics.track(ANALYTICS_EVENTS[action.type], {
        name,
        email,
        role,
        /*
         * Per Segment's Hubspot integration docs, an Enterprise account
         * is required to use analytics.identify(). As such, we want to
         * pass along identifying info under `context.traits` to be
         * extra safe.
         *
         * @see https://linear.app/coa/issue/COA-382#comment-ac66323f
         */
        context: {
          traits: {
            email,
            ...params.query,
          },
        },
      });

      return {
        ...state,
        name,
        email,
        role,
        registering: false,
        loggingIn: false,
        loggedIn: true,
        creatingPassword: false,
        memberId: member.id,
      };
    }
    case ACCOUNT_UPDATE_SUCCESS: {
      const {
        response: { data },
      } = action;
      const { name, email } = data.attributes;
      SafeAnalytics.track('Updated Account Info');
      return { ...state, name, email };
    }
    case PASSWORDS_FAILURE:
    case SESSIONS_FAILURE:
    case REGISTRATIONS_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        errors,
        loggingIn: false,
        registering: false,
        creatingPassword: false,
        memberId: null,
        role: null,
        name: null,
        email: null,
      };
    }
    case LOG_OUT: {
      return createDefaultSessionState();
    }
    default:
      return state;
  }
}
