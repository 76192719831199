import { AdminSerializedWorkoutScheduleEvent } from '../../serializers/admin/AdminSerializedWorkoutScheduleEvent';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from './cms';

const workoutScheduleEventsParams = {
  basePath: '/admin/workout_schedule_events',
  auth: true,
};

export const useAdminIndexWorkoutScheduleEventsQuery = generateUseIndexQuery<
  AdminSerializedWorkoutScheduleEvent,
  AdminCmsIndexFilterParams<AdminSerializedWorkoutScheduleEvent>
>(workoutScheduleEventsParams);

export const useAdminShowWorkoutScheduleEventQuery = generateUseShowQuery<
  AdminSerializedWorkoutScheduleEvent
>(workoutScheduleEventsParams);

export const useAdminCreateWorkoutScheduleEventMutation = generateUseCreateMutation<
  AdminSerializedWorkoutScheduleEvent
>(workoutScheduleEventsParams);

export const useAdminUpdateWorkoutScheduleEventMutation = generateUseUpdateMutation<
  AdminSerializedWorkoutScheduleEvent
>(workoutScheduleEventsParams);
