import { TransientWorkshopAttributes } from '../../../models/workshop';
import { AdminSerializedWorkshop } from '../../../serializers/admin/AdminSerializedWorkshop';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from '../cms';

const adminWorkshopCmsParams = {
  basePath: '/admin/workshops',
  auth: true,
};

// we need to add 'firstOccStarts as part of the workshop because we, by default,
// order the workshops by their first workshopOccurrence.startsAt, which is a nested property
type AdminIndexSerializedWorkshop = AdminSerializedWorkshop & TransientWorkshopAttributes;
export const useAdminIndexWorkshopsQuery = generateUseIndexQuery<
  AdminIndexSerializedWorkshop,
  AdminCmsIndexFilterParams<AdminSerializedWorkshop>
>(adminWorkshopCmsParams);
export const useAdminShowWorkshopsQuery = generateUseShowQuery<AdminSerializedWorkshop>(
  adminWorkshopCmsParams
);
export const useAdminCreateWorkshopMutation = generateUseCreateMutation<AdminSerializedWorkshop>(
  adminWorkshopCmsParams
);
export const useAdminUpdateWorkshopMutation = generateUseUpdateMutation<AdminSerializedWorkshop>(
  adminWorkshopCmsParams
);
