import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ActivityIcon, CoffeeIcon, HeartIcon } from '../../../../components/Icons';

export function TryQAndAStaticCopyList() {
  return (
    <VStack spacing={4} alignItems="start" mb={8}>
      <Flex alignItems="center">
        <Icon as={HeartIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>Your questions answered live by a therapist</Text>
      </Flex>
      <Flex alignItems="center">
        <Icon as={ActivityIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>Actionable takeaways and fun exercises</Text>
      </Flex>
      <Flex alignItems="center">
        <Icon as={CoffeeIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>
          Listen in from your phone or tune in from your desk
        </Text>
      </Flex>
    </VStack>
  );
}
