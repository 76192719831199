export const CLIENT_PAYMENT_PROFILES_SHOW_REQUEST = 'CLIENT_PAYMENT_PROFILES_SHOW_REQUEST';
export const CLIENT_PAYMENT_PROFILES_SHOW_SUCCESS = 'CLIENT_PAYMENT_PROFILES_SHOW_SUCCESS';
export const CLIENT_PAYMENT_PROFILES_SHOW_FAILURE = 'CLIENT_PAYMENT_PROFILES_SHOW_FAILURE';

export function show(id) {
  return {
    type: CLIENT_PAYMENT_PROFILES_SHOW_REQUEST,
    api: {
      endpoint: `clients/${id}/payment_profile`,
      authenticated: true,
      types: [
        CLIENT_PAYMENT_PROFILES_SHOW_REQUEST,
        CLIENT_PAYMENT_PROFILES_SHOW_SUCCESS,
        CLIENT_PAYMENT_PROFILES_SHOW_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST = 'CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST';
export const CLIENT_PAYMENT_PROFILES_UPDATE_SUCCESS = 'CLIENT_PAYMENT_PROFILES_UPDATE_SUCCESS';
export const CLIENT_PAYMENT_PROFILES_UPDATE_FAILURE = 'CLIENT_PAYMENT_PROFILES_UPDATE_FAILURE';

export function update(id, data) {
  return {
    type: CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST,
    api: {
      data,
      endpoint: `clients/${id}/payment_profile`,
      authenticated: true,
      types: [
        CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST,
        CLIENT_PAYMENT_PROFILES_UPDATE_SUCCESS,
        CLIENT_PAYMENT_PROFILES_UPDATE_FAILURE,
      ],
      method: 'PUT',
    },
  };
}
