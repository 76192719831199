import { Box, BoxProps, Container } from '@chakra-ui/react';
import React from 'react';

export type SplitIllustrationLayoutProps = BoxProps & {
  backgroundComponent: React.ReactNode;
};

/*
 * TODO: Break this into compound components maybe?
 */
export const SplitIllustrationLayout = ({
  children,
  backgroundComponent,
}: SplitIllustrationLayoutProps) => (
  <Box width="100%" height="100%" position="relative" overflow="hidden">
    <Box
      width="50%"
      display={{
        base: 'none',
        foo: 'block',
      }}
      height="100%"
      position="absolute"
      top={0}
      left={{
        foo: '54em',
        // 9em = 54em - 45em
        //  * 54em = size of left content container
        //  * 45em = 50% of max-width - 90em / 1440px
        xxl: 'calc(50% + 9em)',
      }}
    >
      {backgroundComponent}
    </Box>
    <Container height="100%" maxW="container.xxl" px={0} overflow="auto">
      <Box width="100%" maxWidth="54em" height="100%">
        {children}
      </Box>
    </Container>
  </Box>
);
