import { AnyObject } from '@coa/types';
import _ from 'lodash';

type Token = string | number;

export const setDeepImmutable = (
  obj: AnyObject,
  path: Token | Token[],
  valueOrTransformFn: unknown
) => {
  const transformFn = _.isFunction(valueOrTransformFn)
    ? valueOrTransformFn
    : // If a static value is provided, we create a dummy
      // transform that simply returns the static value.
      () => valueOrTransformFn;
  const newValue = transformFn(_.get(obj, path));
  return _.setWith(
    _.clone(obj),
    path,
    newValue,
    // This "customizer" will be invoked for every entry
    // in `path`, so we only clone along the path rather
    // than cloning the entire object again.
    _.clone
  );
};
