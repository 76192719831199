import { useRouteParams } from '@coa/react-utils';
import React from 'react';
import { useSession } from '../../../resources/sessions';
import { WorkshopExercisesModal } from '../exercises/WorkshopExercisesModal';
import { JoinWorkshopModal } from '../join/JoinWorkshopModal';
import { WorkshopSubscribeModal } from '../subscribe/WorkshopSubscribeModal';
import { AdminWorkshopActions } from './components/AdminWorkshopActions';
import { WorkshopArchivedModal } from './components/WorkshopArchivedModal';
import { WorkshopOccurrenceUnavailableModal } from './components/WorkshopOccurrenceUnavailableModal';
import { WorkshopUnavailableModal } from './components/WorkshopUnavailableModal';
import { WorkshopDetailsContent } from './WorkshopDetailsContent';
import { WorkshopOccurrenceDetailsContent } from './WorkshopOccurrenceDetailsContent';

export const WorkshopDetailsView = () => {
  const { id: workshopId, dropInWorkshopOccurrenceId } = useRouteParams<{
    id: string;
    dropInWorkshopOccurrenceId?: string;
  }>();
  const { role } = useSession();

  return (
    <>
      {role === 'admin' ? (
        <AdminWorkshopActions
          workshopId={workshopId}
          dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
          mb={4}
          maxW="container.lg"
        />
      ) : null}
      {dropInWorkshopOccurrenceId ? (
        <WorkshopOccurrenceDetailsContent
          workshopId={workshopId}
          dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
        />
      ) : (
        <WorkshopDetailsContent workshopId={workshopId} />
      )}
      <WorkshopExercisesModal id={workshopId} />
      <JoinWorkshopModal id={workshopId} dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId} />
      <WorkshopSubscribeModal
        id={workshopId}
        dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
      />
      {dropInWorkshopOccurrenceId ? (
        <WorkshopOccurrenceUnavailableModal
          workshopId={workshopId}
          dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
        />
      ) : (
        <WorkshopUnavailableModal workshopId={workshopId} />
      )}
      <WorkshopArchivedModal workshopId={workshopId} />
    </>
  );
};
