/* eslint-disable react/prop-types */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useGetAccountQuery } from '@coa/api/controllers/v1/accounts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { FiFacebook, FiInstagram, FiLinkedin, FiMail, FiTwitter } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import PaymentProfileContainer from '../../../containers/PaymentProfileContainer';
import { useMutationWithTimer } from '../../../lib/react-query';
import { usePostAccountResendVerifyEmailMutation } from '../../../resources/member';
import { useNotificationsQueries } from '../../../utils/notifications';
import { AccountInfoSubscription } from './AccountInfoSubscription';
import { AccountInfoTeams } from './AccountInfoTeams';

dayjs.extend(utc);

const ResendVerifyEmailButton = (props /* ButtonProps */) => {
  const getAccountQuery = useGetAccountQuery();
  const toast = useToast();

  const rawPostAccountResendVerifyEmailMutation = usePostAccountResendVerifyEmailMutation();
  const postAccountResendVerifyEmailMutation = useMutationWithTimer(
    rawPostAccountResendVerifyEmailMutation,
    { duration: 500 }
  );

  // TODO: Loading state
  if (getAccountQuery.isLoading) return null;
  const { email } = getAccountQuery.data;

  return (
    <Button
      onClick={async () => {
        try {
          await postAccountResendVerifyEmailMutation.mutateAsyncWithTimer();
          toast({
            title: `Verification email sent to ${email}`,
            description: `Please follow the link provided to verify your email and begin taking classes!`,
            duration: 5000,
            isClosable: true,
            status: 'warning',
          });
        } catch (err) {
          toast({
            title: 'Something went wrong.',
            description: 'Please try again',
            duration: 5000,
            isClosable: true,
            status: 'error',
          });
        }
      }}
      isLoading={postAccountResendVerifyEmailMutation.isLoadingWithTimer}
      {...props}
    />
  );
};

const VerifyEmailPrompt = (props /* BoxProps */) => {
  const notificationsQueries = useNotificationsQueries();
  if (notificationsQueries.isLoading) return null;
  const { notifications } = notificationsQueries;
  if (!notifications.requireVerifyEmail.active) return null;
  return (
    <Box {...props}>
      <Alert status="warning" flexDirection="column" alignItems="start">
        <Flex mb={2}>
          <AlertIcon />
          <AlertTitle>Your email is unverified.</AlertTitle>
          <AlertDescription>Please verify your email to attend Coa classes!</AlertDescription>
        </Flex>
        <ResendVerifyEmailButton colorScheme="orange">
          Resend Verification Email
        </ResendVerifyEmailButton>
      </Alert>
    </Box>
  );
};
const AccountInfo = (props) => {
  const getAccountQuery = useGetAccountQuery();
  // TODO: Loading state
  if (getAccountQuery.isLoading) return null;

  const { data: account } = getAccountQuery;
  const { name, pronouns, birthday, city, email, phone } = account;

  // TODO: Move this to formatters
  const utcBirthday = dayjs(birthday).utc();
  const formattedBirthday = utcBirthday.format('MM/DD/YYYY');

  return (
    <Box {...props} width="fill-available">
      <VerifyEmailPrompt m={3} width="fill-available" />
      <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
        <Flex>
          <Text textStyle="earmark">MY INFO</Text>
          <Spacer />
          <Link as={RouterLink} color="green.900" to="/account/edit">
            Edit
          </Link>
        </Flex>
        <Divider />
        <Box my={2}>
          <Text>
            {name} {pronouns}
          </Text>
          <Text>{birthday && formattedBirthday}</Text>
          <Text>{city}</Text>
          <Text>{email}</Text>
          <Text>{phone}</Text>
        </Box>
      </Box>
    </Box>
  );
};

const SocialLinks = () => (
  <Center p={4} m={3} width="100%" maxW={{ md: '352px' }} borderWidth="1px" borderRadius="4px">
    <VStack spacing={4}>
      <Text fontSize="xl">Build Community</Text>
      <Text textAlign="center" p={2}>
        Connect with our communities to meet likeminded users and discover new points of connection
      </Text>
      <HStack my={2}>
        <IconButton
          as="a"
          href="mailto:hello@joincoa.com"
          variant="link"
          colorScheme="orange"
          icon={<FiMail size="1.5em" />}
        />
        <IconButton
          as="a"
          href="https://www.facebook.com/joincoa"
          variant="link"
          colorScheme="orange"
          icon={<FiFacebook size="1.5em" />}
          target="blank"
        />
        <IconButton
          as="a"
          href="https://twitter.com/joincoa"
          variant="link"
          colorScheme="orange"
          icon={<FiTwitter size="1.5em" />}
          target="blank"
        />
        <IconButton
          as="a"
          href="https://www.instagram.com/joincoa/"
          variant="link"
          colorScheme="orange"
          icon={<FiInstagram size="1.5em" />}
          target="blank"
        />
        <IconButton
          as="a"
          href="https://www.linkedin.com/company/joincoa/"
          variant="link"
          colorScheme="orange"
          icon={<FiLinkedin size="1.5em" />}
          target="blank"
        />
      </HStack>
    </VStack>
  </Center>
);

const SupportLink = () => (
  <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
    <Flex>
      <Text textStyle="heading">SUPPORT</Text>
      <Spacer />
      <Link color="green.900" href="mailto:hello@joincoa.com">
        Contact Us
      </Link>
    </Flex>
  </Box>
);

const BillingHistory = () => (
  <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
    <Flex>
      <Text textStyle="heading">BILLING HISTORY</Text>
      <Spacer />
      <Link color="green.900" as={RouterLink} to="/billing-history">
        View
      </Link>
    </Flex>
  </Box>
);

const Account = (props) => {
  const { dispatch, memberId, role, paymentProfile, logOut } = props;

  return (
    <>
      <Heading display={{ sm: 'none', md: 'block' }} m={4} mt={6} mb={2}>
        Account
      </Heading>
      <Flex
        align={{ sm: 'center', md: 'flex-start' }}
        justify="center"
        width="stretch"
        direction={{ sm: 'column', md: 'row' }}
      >
        <Flex direction="column" align="center" width="stretch">
          <Heading display={{ sm: 'auto', md: 'none' }} m={4} mt={6} mb={2} alignSelf="flex-start">
            Account
          </Heading>
          <AccountInfo />
          {role === 'client' ? (
            <>
              <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
                <PaymentProfileContainer
                  dispatch={dispatch}
                  memberId={memberId}
                  paymentProfile={paymentProfile}
                />
              </Box>
              <AccountInfoTeams />
            </>
          ) : null}
          <AccountInfoSubscription />
          <BillingHistory />
          <SupportLink />
        </Flex>
        <Flex>
          <SocialLinks />
        </Flex>
      </Flex>
      <Button m={3} colorScheme="evergreen" onClick={logOut} display={{ sm: 'block', md: 'none' }}>
        Log Out
      </Button>
    </>
  );
};

export default Account;
