import * as Yup from 'yup';

export const PersonalInfoValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Please type your full name.'),
  jobTitle: Yup.string().min(1).required('Please provide your company Role / Title.'),
  email: Yup.string().email().required('Please provide a valid email address.'),
  password: Yup.string()
    .min(6, 'Passwords must be at least 6 characters')
    .required('Please provide a valid password'),
});

// we separate the company info schema from the great TeamRegistrationValidationSchema
// because we don't want them to move to the next form step if company info isn't filled out
export const CompanyInfoValidationSchema = Yup.object().shape({
  companyTitle: Yup.string().min(1).required('Please provide your company name.'),
  numberOfSeats: Yup.number()
    .typeError('Please enter a number.')
    .min(1)
    .required('You need at least one employee to register your team.'),
});
