import { Box } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { PauseIcon, PlayIcon } from '../../../../../../components/Icons';

const iconStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
} as React.CSSProperties;

/*
 * Video Player "button" that toggles play/pause on click of the screen
 */
export const FullPlayerTogglePlay = ({
  handleClick,
  playing,
  segmentInStartingState,
}: {
  handleClick: () => void;
  playing: boolean;
  segmentInStartingState: boolean;
}) => (
  <Box
    onClick={handleClick}
    position="absolute"
    top={0}
    left={0}
    height="100%"
    width="100%"
    cursor="pointer"
    bgColor={!playing ? 'blackAlpha.300' : 'none'}
  >
    {/*
     * AnimatePresence enables "exit animations".
     * It allows components to defer unmounting until after an animation has occured
     * https://www.framer.com/docs/animate-presence/
     * */}
    <AnimatePresence>
      {playing ? (
        <motion.div
          key="play"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ type: 'spring', duration: 2.5 }}
        >
          <PlayIcon boxSize={12} style={iconStyles} />
        </motion.div>
      ) : null}
      {!playing && !segmentInStartingState ? (
        <motion.div
          key="pause"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ type: 'spring', duration: 2.5 }}
        >
          <PauseIcon boxSize={12} style={iconStyles} />
        </motion.div>
      ) : null}
    </AnimatePresence>
  </Box>
);
