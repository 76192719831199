import { AxiosRequestConfig } from 'axios';
import { coaAxiosAuthConfig, validateCoaAxiosAuthConfig } from '../coaAxiosAuthConfig';

type AuthHeaders = {
  Authorization?: string;
};

const getAuthHeaders = async (): Promise<AuthHeaders> => {
  // TODO: This doesn't feel like the best place to put this validation logic yet.
  validateCoaAxiosAuthConfig();
  const authParams = await coaAxiosAuthConfig.getAuthParams();
  /*
   * If we don't have auth params to pass, then don't try to pass them
   * and just continue, letting the backend provide feedback that
   * we haven't supplied adequate auth information.
   */
  if (!authParams) return {};

  const { id, token } = authParams;
  const stringifiedAuthParams = [id, token].join(':');
  const encodedStringifiedAuthParams = coaAxiosAuthConfig.encodeStringifiedAuthParams(
    stringifiedAuthParams
  );
  return {
    Authorization: `Basic ${encodedStringifiedAuthParams}`,
  };
};

/*
 * Gets auth data from the runtime's relevant storage mechanism
 * and injects the corresponding auth-headers into the request.
 */
export const authInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const authHeaders = await getAuthHeaders();
  config.headers = {
    ...(config.headers || {}),
    ...authHeaders,
  };
  return config;
};
