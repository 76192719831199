import {
  CLEAROUT_INVOICE,
  INVOICES_FAILURE,
  INVOICES_LIST_FAILURE,
  INVOICES_LIST_REQUEST,
  INVOICES_LIST_SUCCESS,
  INVOICES_PAY_SUCCESS,
  INVOICES_REFUND_SUCCESS,
  INVOICES_REQUEST,
  INVOICES_SUCCESS,
  INVOICES_VOID_SUCCESS,
} from '../actions/invoices';

const DEFAULT_STATE = {
  creatingInvoice: false,
  invoice: null,
  invoiceErrors: {},
  invoices: [],
  invoiceMembers: [],
  gettingInvoices: false,
};

export function invoices(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case INVOICES_REQUEST: {
      return { ...state, invoiceErrors: {}, creatingInvoice: true };
    }
    case INVOICES_SUCCESS: {
      const {
        response: {
          data: { id, attributes },
        },
      } = action;
      const invoice = { id, ...attributes };
      return { ...state, invoice, creatingInvoice: false };
    }
    case INVOICES_FAILURE: {
      const { errors } = action;
      return { ...state, invoiceErrors: errors, creatingInvoice: false };
    }
    case INVOICES_LIST_REQUEST: {
      return { ...state, invoices: [], invoiceMembers: [], gettingInvoices: true };
    }
    case INVOICES_LIST_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return { ...state, invoices: data, invoiceMembers: included, gettingInvoices: false };
    }
    case INVOICES_LIST_FAILURE: {
      return { ...state, invoices: [], invoiceMembers: [], gettingInvoices: false };
    }
    case INVOICES_REFUND_SUCCESS:
    case INVOICES_VOID_SUCCESS:
    case INVOICES_PAY_SUCCESS: {
      const updatedInvoice = action.response.data;
      const updatedInvoices = state.invoices.slice(0);
      updatedInvoices.forEach((invoice, i, a) => {
        if (updatedInvoice.id === invoice.id) a[i] = updatedInvoice;
      });
      return { ...state, invoices: updatedInvoices };
    }
    case CLEAROUT_INVOICE: {
      return { ...state, invoice: null };
    }
    default:
      return state;
  }
}
