export const THERAPY_MATCHING_QUESTIONS_REQUEST = 'THERAPY_MATCHING_QUESTIONS_REQUEST';
export const THERAPY_MATCHING_QUESTIONS_SUCCESS = 'THERAPY_MATCHING_QUESTIONS_SUCCESS';
export const THERAPY_MATCHING_QUESTIONS_FAILURE = 'THERAPY_MATCHING_QUESTIONS_FAILURE';

export function listTherapyMatchingQuestions() {
  return {
    type: THERAPY_MATCHING_QUESTIONS_REQUEST,
    api: {
      endpoint: 'therapist_matching_questions',
      authenticated: true,
      types: [
        THERAPY_MATCHING_QUESTIONS_REQUEST,
        THERAPY_MATCHING_QUESTIONS_SUCCESS,
        THERAPY_MATCHING_QUESTIONS_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const THERAPY_MATCHING_ANSWERS_REQUEST = 'THERAPY_MATCHING_ANSWERS_REQUEST';
export const THERAPY_MATCHING_ANSWERS_SUCCESS = 'THERAPY_MATCHING_ANSWERS_SUCCESS';
export const THERAPY_MATCHING_ANSWERS_FAILURE = 'THERAPY_MATCHING_ANSWERS_FAILURE';

export function listTherapyMatchingAnswers(clientId) {
  return {
    type: THERAPY_MATCHING_ANSWERS_REQUEST,
    api: {
      endpoint: `clients/${clientId}/therapy_matches`,
      authenticated: true,
      types: [
        THERAPY_MATCHING_ANSWERS_REQUEST,
        THERAPY_MATCHING_ANSWERS_SUCCESS,
        THERAPY_MATCHING_ANSWERS_FAILURE,
      ],
      method: 'GET',
    },
  };
}

export const CREATE_THERAPY_MATCH_REQUEST = 'CREATE_THERAPY_MATCH_REQUEST';
export const CREATE_THERAPY_MATCH_SUCCESS = 'CREATE_THERAPY_MATCH_SUCCESS';
export const CREATE_THERAPY_MATCH_FAILURE = 'CREATE_THERAPY_MATCH_FAILURE';

export function createTherapyMatch(clientId) {
  return {
    type: CREATE_THERAPY_MATCH_REQUEST,
    api: {
      endpoint: `clients/${clientId}/therapy_matches`,
      authenticated: true,
      types: [
        CREATE_THERAPY_MATCH_REQUEST,
        CREATE_THERAPY_MATCH_SUCCESS,
        CREATE_THERAPY_MATCH_FAILURE,
      ],
      method: 'POST',
    },
  };
}

export const MARK_THERAPY_MATCH_COMPLETE_REQUEST = 'MARK_THERAPY_MATCH_COMPLETE_REQUEST';
export const MARK_THERAPY_MATCH_COMPLETE_SUCCESS = 'MARK_THERAPY_MATCH_COMPLETE_SUCCESS';
export const MARK_THERAPY_MATCH_COMPLETE_FAILURE = 'MARK_THERAPY_MATCH_COMPLETE_FAILURE';

export function markTherapyMatchComplete(clientId, matchId, exitQuestion) {
  return {
    type: MARK_THERAPY_MATCH_COMPLETE_REQUEST,
    api: {
      data: { exit_question: exitQuestion },
      endpoint: `clients/${clientId}/therapy_matches/${matchId}/mark_complete`,
      authenticated: true,
      types: [
        MARK_THERAPY_MATCH_COMPLETE_REQUEST,
        MARK_THERAPY_MATCH_COMPLETE_SUCCESS,
        MARK_THERAPY_MATCH_COMPLETE_FAILURE,
      ],
      method: 'POST',
    },
  };
}

export const UPDATE_THERAPY_MATCHING_ANSWER_REQUEST = 'UPDATE_THERAPY_MATCHING_ANSWER_REQUEST';
export const UPDATE_THERAPY_MATCHING_ANSWER_SUCCESS = 'UPDATE_THERAPY_MATCHING_ANSWER_SUCCESS';
export const UPDATE_THERAPY_MATCHING_ANSWER_FAILURE = 'UPDATE_THERAPY_MATCHING_ANSWER_FAILURE';

export function updateTherapyMatchingAnswer(clientId, matchId, key, value) {
  return {
    type: UPDATE_THERAPY_MATCHING_ANSWER_REQUEST,
    api: {
      data: { key, value },
      endpoint: `clients/${clientId}/therapy_matches/${matchId}`,
      authenticated: true,
      types: [
        UPDATE_THERAPY_MATCHING_ANSWER_REQUEST,
        UPDATE_THERAPY_MATCHING_ANSWER_SUCCESS,
        UPDATE_THERAPY_MATCHING_ANSWER_FAILURE,
      ],
      method: 'PUT',
    },
  };
}
