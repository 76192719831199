import { Avatar, Box, Button, Center, Flex, Heading, IconButton, Stack } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createSelector } from 'reselect';
import * as therapistActions from '../../actions/admin/therapists';
import { EditIcon } from '../Icons';
import Loading from '../Loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';

const sortedTherapistsSelector = createSelector(
  (state) => state.admin.therapists.therapists,
  (therapists) => _.chain(therapists).values().sortBy('attributes.name').value()
);

const AdminTherapists = () => {
  const dispatch = useDispatch();
  const therapists = useSelector(sortedTherapistsSelector);
  const gettingTherapists = useSelector((state) => state.admin.therapists.gettingTherapists);

  useEffect(() => {
    dispatch(therapistActions.list());
  }, [dispatch]);
  return (
    <Box width="stretch" maxWidth="80%">
      <Flex my={6} alignItems="center" justify="space-between" width="stretch">
        <Heading>Therapists</Heading>
        <Button as={RouterLink} to="/admin-dash/therapists/new">
          + New Therapist
        </Button>
      </Flex>
      <Center>
        {gettingTherapists ? (
          <Loading />
        ) : (
          <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
            <Table aria-label="Therapists">
              <TableHead>
                <TableRow>
                  <TableHeader>Name</TableHeader>
                  <TableHeader>Email</TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {therapists.map((therapist) => (
                  <TableRow key={therapist.id}>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <Avatar
                          name={therapist.attributes.name}
                          src={therapist.attributes.image_url}
                        />
                        <Heading fontSize="md">{therapist.attributes.name}</Heading>
                      </Stack>
                    </TableCell>
                    <TableCell>{therapist.attributes.email}</TableCell>
                    <TableCell>
                      <Flex justifyContent="flex-end">
                        <IconButton
                          as={RouterLink}
                          to={`/admin-dash/therapists/${therapist.id}`}
                          borderRadius="50%"
                          color="gray"
                          bgColor="green.50"
                          variant="outline"
                          icon={<EditIcon />}
                        />
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Center>
    </Box>
  );
};

export default AdminTherapists;
