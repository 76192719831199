import {
  Workshop,
  WorkshopFilter,
  WorkshopKind,
  WorkshopOccurrence,
} from '../../../resources/workshops';
import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace ClassMarketplaceAnalytics {
  type EventName =
    | 'Clicked Featured Class'
    | 'Viewed Class Marketplace'
    | 'Filtered Class Marketplace'
    | 'Toggled Drop-In Classes Filter'
    | 'Toggled Mobile Classes Filters'
    | 'Viewed Class Details'
    | 'Viewed Drop-In Details'
    | 'Viewed Browse Classes Page'
    | 'Viewed Application'
    | 'Viewed Complete Profile'
    | 'Viewed My Coa Page'
    | 'Answered Application Question'
    | 'Answered Profile Question';

  type EventNameToEventBody = {
    'Clicked Featured Class': { classId: Workshop['id'] };
    'Viewed Class Marketplace': never;
    'Filtered Class Marketplace': { filters: WorkshopFilter };
    // this is going to be deprecated when we add new filters to all /classes/:kind pages
    'Toggled Drop-In Classes Filter': { openFilter: boolean };
    'Toggled Mobile Classes Filters': {
      openFilter: boolean;
      classKind: WorkshopKind | 'drop-in' | null; // null is for /classes view
    };
    'Viewed Class Details': {
      classId: Workshop['id'];
    };
    'Viewed Drop-In Details': {
      classId: Workshop['id'];
      dropInId: WorkshopOccurrence['id'];
    };
    'Viewed Browse Classes Page': {
      path: string;
    };
    'Viewed Application': {
      classId: Workshop['id'];
      dropInId?: WorkshopOccurrence['id'];
    };
    'Viewed Complete Profile': {
      classId: Workshop['id'];
      dropInId?: WorkshopOccurrence['id'];
    };
    'Viewed My Coa Page': {
      tab: 'Schedule' | 'Past Classes';
    };
    'Answered Application Question': {
      classId: Workshop['id'];
      dropInId?: WorkshopOccurrence['id'];
      question: string;
      answer: string;
    };
    'Answered Profile Question': {
      classId: Workshop['id'];
      dropInId?: WorkshopOccurrence['id'];
      question: string;
      answer: string | string[];
    };
  };
}

export const classMarketplaceAnalytics = createSafeAnalyticsClient<
  ClassMarketplaceAnalytics.EventName,
  ClassMarketplaceAnalytics.EventNameToEventBody
>();
