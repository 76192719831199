import { useEffect, useRef } from 'react';

/*
 * Stores a previous value so it can be used in between renders. Useful
 * for comparisons _similar_ to functionality that was provided by
 * "componentDidUpdate" or "componentWillReceiveProps" in older versions
 * of the React render lifecycle.
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
