import type { AppLocalStorage, AppLocalStorageKey } from './types';

/*
 * Creates a safely-typed localStorage client for
 * a particular localStorage state slice.
 */
export function createLocalStorageClient<Key extends AppLocalStorageKey>(key: Key) {
  const { localStorage } = window;
  return {
    get: (): AppLocalStorage[Key] | null => {
      const resultJsonStr = localStorage.getItem(key);
      if (!resultJsonStr) return null;
      return JSON.parse(resultJsonStr) as AppLocalStorage[Key];
    },
    set: (value: AppLocalStorage[Key]) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    remove: () => {
      localStorage.removeItem(key);
    },
  };
}
