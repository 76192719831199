import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { create } from '../../../actions/registrations/create';
import { SafeAnalytics } from '../../../lib/analytics';
import { sessionLocalStorage } from '../../../lib/localStorage';
import { DefaultRoute } from '../../../utils/DefaultRoute';
import { Redirector } from '../../../utils/Redirector';
import Registration from '../components/Registration';

class RegistrationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', password: '', role: 'client' };
    this.updateAttr = this.updateAttr.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    if (currentMember) {
      history.push(DefaultRoute.for(currentMember));
    } else {
      SafeAnalytics.track('Viewed Join Coa', { redirectPath: Redirector.path() });
    }
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/prop-types
    const { history, loggedIn } = this.props;
    if (loggedIn) {
      const path = Redirector.path() || '/classes';
      history.push(path);
    }
  }

  updateAttr(e, attr) {
    const newState = {};
    newState[attr] = e.target.value;
    this.setState(newState);
  }

  register(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(create(this.state));
  }

  render() {
    const { dispatch, registering, errors } = this.props;
    const { name, email, role, password } = this.state;
    return (
      <Registration
        dispatch={dispatch}
        name={name}
        email={email}
        role={role}
        password={password}
        register={this.register}
        loading={registering}
        errors={errors}
        redirectPath={Redirector.path()}
      />
    );
  }
}

RegistrationContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  registering: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { registering, errors, loggedIn } = state.session;
  return { registering, errors, loggedIn };
};

export default _.flow([connect(mapStateToProps), withRouter])(RegistrationContainer);
