import path from 'path';
import { generatePath } from 'react-router';
import { createGetRouterUrl } from './utils';

const adminDashApexRouterPath = path.resolve('admin-dash');

// admin-dash/enrollments
const adminDashEnrollmentsRouterPath = path.resolve(adminDashApexRouterPath, 'enrollments');
const adminDashCreateEnrollmentPath = path.resolve(adminDashEnrollmentsRouterPath, 'new');
const adminDashEnrollmentRouterPath = path.resolve(adminDashEnrollmentsRouterPath, ':id');

// admin-dash/classes
const adminDashClassesRouterPath = path.resolve(adminDashApexRouterPath, 'classes');
const adminDashClassRouterPath = path.resolve(adminDashClassesRouterPath, ':id');
const adminDashClassEnrolleesRouterPath = path.resolve(adminDashClassRouterPath, 'enrollees');

export const adminDashRouterPaths = {
  apex: adminDashApexRouterPath,
  createEnrollment: adminDashCreateEnrollmentPath,
  enrollments: adminDashEnrollmentsRouterPath,
  enrollment: adminDashEnrollmentRouterPath,

  classes: adminDashClassesRouterPath,
  class: adminDashClassRouterPath,
  classEnrollees: adminDashClassEnrolleesRouterPath,
};

export const getAdminDashRouterUrl = {
  apex: createGetRouterUrl(adminDashApexRouterPath),
  createEnrollment: createGetRouterUrl(adminDashCreateEnrollmentPath),
  enrollments: createGetRouterUrl(adminDashEnrollmentsRouterPath),
  enrollment: createGetRouterUrl(adminDashEnrollmentRouterPath),

  classes: createGetRouterUrl(adminDashClassesRouterPath),
  class: createGetRouterUrl(adminDashClassRouterPath),
  classEnrollees: createGetRouterUrl(adminDashClassEnrolleesRouterPath),
};

type AdminDashEnrollmentRouterPathParams = { id: string };
type AdminDashClassRouterPathParams = AdminDashEnrollmentRouterPathParams;

export type AdminDashRouterPathParams = {
  Enrollment: AdminDashEnrollmentRouterPathParams;
  Class: AdminDashClassRouterPathParams;
};

const createGetUniversalAdminDashRouterUrl = <
  RouterPathParams extends AdminDashEnrollmentRouterPathParams
>({
  adminDashRouterPath,
  adminDashInstanceRouterPath,
}: {
  adminDashRouterPath?: string;
  adminDashInstanceRouterPath?: string;
}) => (routeParams: RouterPathParams = {} as RouterPathParams) =>
  generatePath(routeParams.id ? adminDashInstanceRouterPath : adminDashRouterPath, routeParams);

export const getUniversalAdminDashRouterUrl = {
  enrollments: createGetUniversalAdminDashRouterUrl({
    adminDashRouterPath: adminDashEnrollmentsRouterPath,
    adminDashInstanceRouterPath: adminDashEnrollmentRouterPath,
  }),
  classes: createGetUniversalAdminDashRouterUrl({
    adminDashRouterPath: adminDashClassesRouterPath,
    adminDashInstanceRouterPath: adminDashClassRouterPath,
  }),
  classEnrollees: createGetUniversalAdminDashRouterUrl({
    adminDashInstanceRouterPath: adminDashClassEnrolleesRouterPath,
  }),
};
