import { Flex } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React from 'react';
import {
  createGetQAndAWorkshopsPlaceholderData,
  useGetWorkshopsQuery,
  useQAndAWorkshopsQuery,
} from '../../../../resources/workshops';
import { ClassCardGrid } from '../../components/ClassCardGrid';
import { ClassesFilters } from '../../components/ClassesFilters';
import ClassesFiltersContextProvider, {
  useClassesFiltersContext,
} from '../../components/ClassesFilters/ClassesFiltersContextProvider';
import { ClassesFiltersEmptyState } from '../../components/ClassesFilters/FilterComponents';

const placeholderData = createGetQAndAWorkshopsPlaceholderData({ length: 4 });

const FilteredQAndAsList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const queryParams = getWorkshopFilter();
  const {
    data: qAndAs,
    isLoading: isQAndAsLoading,
    isFetched: isQAndAsFetched,
    isPlaceholderData,
  } = useGetWorkshopsQuery(queryParams, {
    placeholderData,
  });

  const isLoading = isQAndAsLoading || isPlaceholderData || parentIsLoading;
  if (isQAndAsFetched && qAndAs.length === 0) {
    return <ClassesFiltersEmptyState />;
  }

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={qAndAs}
    />
  );
};

const UnfilteredQAndAsList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const qAndAsQuery = useQAndAWorkshopsQuery({ placeholderData });
  const { data: qAndAs } = qAndAsQuery;
  const isLoading = qAndAsQuery.isLoading || qAndAsQuery.isPlaceholderData || parentIsLoading;

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={qAndAs}
    />
  );
};

const QAndAsListViewInner = () => {
  const { hasFiltersApplied } = useClassesFiltersContext();
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const props = { hasActiveSubscription, parentIsLoading: getSubscriptionsQuery.isLoading };
  return hasFiltersApplied ? (
    <FilteredQAndAsList {...props} />
  ) : (
    <UnfilteredQAndAsList {...props} />
  );
};

export const QAndAsListView = () => (
  <ClassesFiltersContextProvider kind="q_and_a">
    <Flex width="full" mb={4}>
      <ClassesFilters showFilterText={false} />
    </Flex>
    <QAndAsListViewInner />
  </ClassesFiltersContextProvider>
);
