import {
  UseCreateMutation,
  UseIndexQuery,
  UseShowQuery,
  UseUpdateMutation,
} from '@coa/api/controllers/admin/cms';
import { AnyObject, IsoTime } from '@coa/types';
import { FormProps } from '@rjsf/core';
import _ from 'lodash';
import React, { createContext, useContext } from 'react';

export type BaseResource = AnyObject;
export type BaseSerializedResource = {
  id: string;
  type: string;
  createdAt: IsoTime;
  updatedAt: IsoTime;
};
export type BaseCmsFormData = AnyObject;

type BaseCmsContextParams<
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
> = {
  contentTypeTitle: string;
  contentHelperText: string;
  baseRouterPath: string;
  searchPlaceholder?: string;
  staticSearchParams?: AnyObject;

  useIndexQuery: UseIndexQuery<SerializedResource>;
  useShowQuery: UseShowQuery<SerializedResource>;
  useCreateMutation: UseCreateMutation<SerializedResource>;
  useUpdateMutation: UseUpdateMutation<SerializedResource>;

  formSchema: FormProps<Resource>['schema'];
  formUiSchema?: FormProps<Resource>['uiSchema'];

  formatSerializedResourceToCmsFormData?: (_r: SerializedResource) => CmsFormData;
  parseCmsFormDataToSerializedResource?: (_fd: CmsFormData) => SerializedResource;

  getEntryTitle: (entry: SerializedResource) => string;
  renderIndexEntryTdContents: (entry: SerializedResource) => React.ReactNode;
  indexThEntries: Array<{ name: keyof Resource; label: React.ReactNode }>;
};

export type CmsContextParams<
  Resource extends AnyObject,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
> = BaseCmsContextParams<Resource, SerializedResource, CmsFormData> & {
  indexRouterPath: string;
  createRouterPath: string;
  showRouterPath: string;
};

const CmsContext = createContext({});
export const useCmsContext = <
  Resource extends BaseResource = BaseResource,
  SerializedResource extends BaseSerializedResource = BaseSerializedResource,
  CmsFormData extends BaseCmsFormData = BaseCmsFormData
>() => useContext(CmsContext) as CmsContextParams<Resource, SerializedResource, CmsFormData>;

const getDefaultContextParams = <
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => ({
  staticSearchParams: {},
  formatSerializedResourceToCmsFormData: (_.identity as unknown) as (
    _r: SerializedResource
  ) => CmsFormData,
  parseCmsFormDataToSerializedResource: (_.identity as unknown) as (
    _fd: CmsFormData
  ) => SerializedResource,
});

export const CmsContextProvider = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>({
  children,
  value: rawContextParams,
}: {
  value: BaseCmsContextParams<Resource, SerializedResource, CmsFormData>;
  children: React.ReactNode;
}) => {
  const { baseRouterPath } = rawContextParams;
  const contextParams = {
    ...getDefaultContextParams<SerializedResource, CmsFormData>(),
    ...rawContextParams,
    indexRouterPath: baseRouterPath,
    createRouterPath: `${baseRouterPath}/new`,
    showRouterPath: `${baseRouterPath}/:id`,
  };
  return <CmsContext.Provider value={contextParams}>{children}</CmsContext.Provider>;
};
