import { Button, Heading, HStack, VStack } from '@chakra-ui/react';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../components/Icons';
import { getFilterToggleButtonProps } from '../../../classes/components/ClassesFilters/FilterComponents';
import {
  AdminClassesQueryParams,
  useAdminClassesFiltersContext,
} from './AdminClassesFiltersContextProvider';

const getMinMaxDateValuesFromPossiblyMissingDateParams = (
  dateParams: AdminClassesQueryParams['dateRange']
) => {
  const [start, end] = dateParams;
  const maxStartValue = end || Infinity;
  const minEndValue = start || -Infinity;
  return [maxStartValue, minEndValue];
};
export const DateRange = () => {
  const { filters, setDateRange } = useAdminClassesFiltersContext();
  const [start, end] = filters.dateRange;

  const [maxStartValue, minEndValue] = getMinMaxDateValuesFromPossiblyMissingDateParams(
    filters.dateRange
  );
  const handleDateChange = (field: 'start' | 'end') => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(field, e.target.value);
  };
  return (
    <VStack alignItems="start">
      <Heading fontSize="md">Date Range:</Heading>
      <HStack>
        <TextField
          fullWidth
          type="date"
          name="start"
          inputProps={{ max: maxStartValue }}
          onChange={handleDateChange('start')}
          value={start}
        />
        <p>to</p>
        <TextField
          fullWidth
          type="date"
          name="end"
          inputProps={{ min: minEndValue }}
          onChange={handleDateChange('end')}
          value={end}
        />
      </HStack>
    </VStack>
  );
};

type AdminOrderOptions = AdminClassesQueryParams['order'];
const orderOptions: AdminOrderOptions[] = ['asc', 'desc'];
const orderOptionToIcon: {
  [key in AdminOrderOptions]: React.ReactNode;
} = {
  asc: <ArrowUpIcon />,
  desc: <ArrowDownIcon />,
};
export const OrderRadioGroup = () => {
  const { filters, toggleSortOrder } = useAdminClassesFiltersContext();

  const handleChange = (order: AdminClassesQueryParams['order']) => () => {
    toggleSortOrder(order);
  };

  return (
    <VStack alignItems="start">
      <Heading fontSize="md">Timing:</Heading>
      <HStack ml={2}>
        {orderOptions.map((value: AdminClassesQueryParams['order']) => (
          <Button
            key={value}
            {...getFilterToggleButtonProps(filters.order === value, handleChange(value))}
            size="sm"
          >
            {orderOptionToIcon[value]}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};
