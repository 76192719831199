export const STATUS_COLORS = {
  brand_new: 'green',
  awaiting_consult_schedule: 'lime',
  consult_scheduled: 'gray',
  consult_completed: 'gray',
  match_found: 'green',
  planning_to_start_session: 'green',
  in_session: 'green',
  dismissed: 'orange',
  consult_missed: 'orange',
  no_match: 'warm',
  fit_rejected: 'warm',
  churned: 'orange',
};

export const StatusLabel = {
  for: (status) => {
    const words = status.toLowerCase().split('_');
    let i = 0;
    while (i < words.length) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      i += 1;
    }
    return words.join(' ');
  },
};
