import { Global } from '@emotion/react';
import React from 'react';
import { swiperCss } from './swiperCss';

export const SwiperStylesProvider = ({ children }: { children: React.ReactNode }) => (
  <>
    <Global styles={swiperCss} />
    {children}
  </>
);
