import { Box, BoxProps, Flex, FlexProps, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import { navOffsetPx } from '../../../../themes/coaTheme';

export const WorkshopDetailsLayout = (props: FlexProps) => (
  <Flex
    width="100%"
    maxWidth="100%"
    display="flex"
    flexDir="column"
    height={{ base: '100%', md: 'auto' }}
    {...props}
  />
);

export const WorkshopDetailsScrollableContent = (props: BoxProps) => (
  <Flex
    direction={{ base: 'column', md: 'row-reverse' }}
    flexWrap="wrap"
    height={{ base: '100%', md: 'auto' }}
    overflow={{
      base: 'auto',
      // Note that we explicitly need overflow to be unset at desktop
      // so that we can use position: sticky throughout the app, since
      // it defines a scrolling ancestor using overflow.
      md: 'visible',
    }}
    pb={{ base: 6, md: 0 }}
    {...props}
  />
);

export const WorkshopDetailsSidebar = ({ children, ...rest }: BoxProps) => (
  <Box
    width="100%"
    maxW={{ md: '352px' }}
    flexGrow={{ md: 1 }}
    flexBasis={{ md: '352px' /* Width pulled from design-spec */ }}
    position={{ md: 'sticky' }}
    ml={{ md: 8 }}
    height={{
      md:
        // For some reason, Chakra adds a height: 100% as the default
        // for flex containers. I've not yet figured out a way to
        // override this safely, so for now we manually compute the
        // height. This stinks.
        '427px',
    }}
    top={{ md: navOffsetPx }}
    {...rest}
  >
    <Stack spacing={8}>{children}</Stack>
  </Box>
);

export const WorkshopDetailsMainContent = ({ children, ...rest }: BoxProps) => (
  <Box width="100%" flexBasis={0} flexGrow={999 /* Occupy remaining space */} {...rest}>
    <VStack spacing={12}>{children}</VStack>
  </Box>
);

export const WorkshopDetailsMobileStickyFooter = (props: BoxProps) => (
  <Box flexGrow={1} flexShrink={1} width="100%" position="relative" boxShadow="2xl" {...props} />
);
