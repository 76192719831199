import { Box, Heading, HStack, IconButton, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAdminClassesFiltersContext } from '../../views/admin/classes/components/AdminClassesFiltersContextProvider';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon } from '../Icons';

type PaginationAction = 'start' | 'back' | 'forward' | 'end';
type PaginationControlsProps = {
  maxPages: number;
  isLoading: boolean;
};

export const PaginationControls = ({ maxPages, isLoading }: PaginationControlsProps) => {
  const { filters, setCurrentPage } = useAdminClassesFiltersContext();
  const currentPage = filters.page;

  const [maxPagesValue, setMaxPagesValue] = useState(maxPages);
  useEffect(() => {
    if (maxPages) {
      setMaxPagesValue(maxPages);
    }
  });

  // TODO: should we move these rules for currentPage action into filters context?
  // is it the context's responsibility to know what is a valid page number, or should
  // it just yolo and set whatever is sent to it?
  const handleClick = (action: PaginationAction) => () => {
    let nextPageNumber = currentPage;
    switch (action) {
      case 'start':
        nextPageNumber = 1;
        break;
      case 'back':
        if (currentPage > 1) {
          nextPageNumber = currentPage - 1;
        }
        break;
      case 'forward':
        if (currentPage < maxPages + 1) {
          nextPageNumber = currentPage + 1;
        }
        break;
      case 'end':
        nextPageNumber = maxPages;
        break;
      default:
        return;
    }
    setCurrentPage(nextPageNumber);
  };

  const navBackDisabled = currentPage <= 1 || isLoading;
  const navForwardDisabled = currentPage === maxPages;
  return (
    <VStack alignItems="start">
      <Heading fontSize="md">Page:</Heading>
      <HStack>
        <IconButton
          disabled={navBackDisabled}
          onClick={handleClick('start')}
          aria-label="pagination-start-btn"
          colorScheme="green"
          icon={<ChevronsLeftIcon />}
          size="sm"
        />
        <IconButton
          disabled={navBackDisabled}
          onClick={handleClick('back')}
          aria-label="pagination-back-btn"
          colorScheme="green"
          icon={<ChevronLeftIcon />}
          size="sm"
        />
        <Box mx={2}>
          <Heading fontSize="md">
            {currentPage} of {maxPagesValue || '??'}
          </Heading>
        </Box>
        <IconButton
          disabled={navForwardDisabled}
          onClick={handleClick('forward')}
          aria-label="pagination-forward-btn"
          colorScheme="green"
          icon={<ChevronRightIcon />}
          size="sm"
        />
        <IconButton
          disabled={navForwardDisabled}
          onClick={handleClick('end')}
          aria-label="pagination-end-btn"
          colorScheme="green"
          icon={<ChevronsRightIcon />}
          size="sm"
        />
      </HStack>
    </VStack>
  );
};
