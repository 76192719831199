export const WORKSHOP_TEMPLATES_LIST_REQUEST = 'WORKSHOP_TEMPLATES_LIST_REQUEST';
export const WORKSHOP_TEMPLATES_LIST_SUCCESS = 'WORKSHOP_TEMPLATES_LIST_SUCCESS';
export const WORKSHOP_TEMPLATES_LIST_FAILURE = 'WORKSHOP_TEMPLATES_LIST_FAILURE';

export function list() {
  return {
    type: WORKSHOP_TEMPLATES_LIST_REQUEST,
    api: {
      endpoint: 'workshop_templates',
      authenticated: true,
      admin: true,
      types: [
        WORKSHOP_TEMPLATES_LIST_REQUEST,
        WORKSHOP_TEMPLATES_LIST_SUCCESS,
        WORKSHOP_TEMPLATES_LIST_FAILURE,
      ],
      method: 'GET',
    },
  };
}
