import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { joinTimeLabels } from '@coa/stdlib/string';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ClassCard } from '../../../../components/molecules/ClassCard';
import { ClassListing } from '../../../../components/molecules/ClassListing';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  formatSingleClassTimeLabel,
  formatWorkshopOccurrenceTimeLabel,
  useDropInWorkshopOccurrencesQuery,
} from '../../../../resources/workshops';

export const UpcomingDropInsView = () => {
  const numberOfResults = 2;
  const dropInsPlaceholderData = createGetDropInWorkshopOccurrencesPlaceholderData({
    length: numberOfResults,
  });
  const dropInsQuery = useDropInWorkshopOccurrencesQuery(
    {},
    { placeholderData: dropInsPlaceholderData }
  );
  const { data: dropIns } = dropInsQuery;

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isLoading =
    dropInsQuery.isLoading || dropInsQuery.isPlaceholderData || getSubscriptionsQuery.isLoading;

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      columnGap={{ base: 0, md: 8 }}
      rowGap={8}
    >
      {dropIns.slice(0, numberOfResults).map((dropIn) => (
        <GridItem colSpan={{ base: 12, md: 6 }} key={dropIn.id}>
          <Skeleton isLoaded={!isLoading} height="100%">
            <TransformUpLink
              as={RouterLink}
              to={`/classes/${dropIn.workshopId}/drop-ins/${dropIn.id}`}
              height="100%"
              width="100%"
            >
              <ClassListing
                label={dropIn.workshop.marketListingLabel}
                title={dropIn.title}
                date={formatWorkshopOccurrenceTimeLabel(dropIn)}
                instructorName={dropIn.therapist.name}
                instructorImage={dropIn.therapist.imageUrl}
                src={dropIn.workshop.imageUrl}
                colorScheme="gray"
                showClassLabel={!hasActiveSubscription}
                workshopAccess={dropIn.workshop.access}
              />
            </TransformUpLink>
          </Skeleton>
        </GridItem>
      ))}
    </Grid>
  );
};

export const UpcomingDropInsCardView = () => {
  const numberOfResults = 4;
  const dropInsCardsPlaceholderData = createGetDropInWorkshopOccurrencesPlaceholderData({
    length: numberOfResults,
  });
  const dropInsQuery = useDropInWorkshopOccurrencesQuery(
    {},
    { placeholderData: dropInsCardsPlaceholderData }
  );
  const { data: dropIns } = dropInsQuery;

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isLoading =
    dropInsQuery.isLoading || dropInsQuery.isPlaceholderData || getSubscriptionsQuery.isLoading;

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      columnGap={{ base: 4, md: 6, lg: 8 }}
      rowGap={{ base: 4, md: 6, lg: 8 }}
    >
      {dropIns.slice(0, numberOfResults).map((dropIn) => {
        const timingLabel = joinTimeLabels(
          formatSingleClassTimeLabel(dropIn),
          `${dropIn.durationInMinutes}min`
        );
        return (
          <GridItem colSpan={{ base: 6, lg: 3 }} key={dropIn.id}>
            <Skeleton isLoaded={!isLoading} height="100%" borderRadius="lg" fadeDuration={0}>
              <TransformUpLink
                as={RouterLink}
                to={`/classes/${dropIn.workshopId}/drop-ins/${dropIn.id}`}
                height="100%"
                width="100%"
              >
                <ClassCard
                  marketListingLabel={dropIn.workshop.marketListingLabel}
                  title={dropIn.title}
                  timingLabel={timingLabel}
                  instructorName={dropIn.therapist.name}
                  src={dropIn.workshop.imageUrl}
                  type={dropIn.type}
                  showClassLabel={!hasActiveSubscription}
                  workshopAccess={dropIn.workshop.access}
                />
              </TransformUpLink>
            </Skeleton>
          </GridItem>
        );
      })}
    </Grid>
  );
};
