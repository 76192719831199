import { Button, Checkbox, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { formatPossiblyRecurringEventTimeLabel } from '@coa/stdlib/string';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import React, { ChangeEvent, useEffect, useReducer } from 'react';
import * as Yup from 'yup';
import { Member, useAdminGetMemberQuery } from '../../../../resources/member';
import { useGetWorkshopQuery, Workshop } from '../../../../resources/workshops';
import { MemberSearchField } from '../../MemberSearchField';
import { WorkshopOccurencesCheckboxList } from '../../WorkshopOccurencesCheckboxList';
import { WorkshopSearchField } from '../../WorkshopSearchField';
import { getInitialReducerValues, reducer } from './reducer';

const getSubscribeFormValidationSchema = Yup.object({
  dropInIds: Yup.array().of(Yup.string()),
  fullSeries: Yup.boolean().when('dropInIds', (dropInIds: string[]) => {
    if (!dropInIds.length) {
      return Yup.boolean().oneOf([true]).required();
    }
  }),
  memberId: Yup.string().required(),
  workshopId: Yup.string().required(),
});

export type EnrollmentFormikValues = {
  dropInIds: string[];
  fullSeries: boolean;
  memberId: string;
  workshopId: string;
};

type EnrollmentFormProps = FormikProps<EnrollmentFormikValues>;
type SetFieldValue = EnrollmentFormProps['setFieldValue'];
type SetValues = EnrollmentFormProps['setValues'];

export type HandleSubmitOps = {
  resetFormFn: () => void;
};

const getWorkshopEnrollmentFormikValues = ({
  memberId = null,
  workshopId = null,
  dropInIds = [],
  fullSeries = false,
}: {
  memberId?: string;
  workshopId?: string;
  dropInIds?: string[];
  fullSeries?: boolean;
}): EnrollmentFormikValues => ({
  fullSeries,
  memberId,
  workshopId,
  dropInIds,
});

export function WorkshopEnrollmentForm({
  dropInIds = [],
  memberId = null,
  workshopId = null,
  onSubmit,
  submitBtnLabel = 'Submit',
  disableSelectMember = false,
  disableSelectWorkshop = false,
}: {
  dropInIds?: string[];
  memberId?: string;
  workshopId?: string;
  onSubmit: (params: EnrollmentFormikValues, ops: HandleSubmitOps) => Promise<void>;
  submitBtnLabel?: string;
  disableSelectMember?: boolean;
  disableSelectWorkshop?: boolean;
}) {
  // code for reducer in ./reducer.ts
  const initiaReducerValues = getInitialReducerValues();
  const [state, dispatch] = useReducer(reducer, initiaReducerValues);
  const { isSubmitting, selectedMember, selectedWorkshop } = state;

  const initialFormikValues = getWorkshopEnrollmentFormikValues({
    dropInIds,
    fullSeries: dropInIds.length === 0,
    workshopId,
    memberId,
  });

  // POPULATE workshop if workshopId query param is present
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: selectedWorkshop?.id || workshopId },
    {
      enabled: Boolean(selectedWorkshop || workshopId),
    }
  );
  const { data: workshopWithIncludedOccurences } = getWorkshopQuery;
  useEffect(() => {
    if (Boolean(workshopWithIncludedOccurences && !selectedWorkshop)) {
      dispatch({ type: 'SET_SELECTED_WORKSHOP', workshop: workshopWithIncludedOccurences });
    }
  }, [getWorkshopQuery, selectedWorkshop]);

  // POPULATE member if memberId query param is present
  const enabled = Boolean(selectedMember?.id || memberId);
  const memberQueryId = selectedMember?.id || memberId?.toString();
  const getAdminMemberQuery = useAdminGetMemberQuery(
    { id: memberQueryId },
    {
      enabled,
    }
  );
  useEffect(() => {
    if (Boolean(getAdminMemberQuery.data && !selectedMember)) {
      dispatch({ type: 'SET_SELECTED_MEMBER', member: getAdminMemberQuery.data });
    }
  }, [getAdminMemberQuery, selectedMember]);

  // callback to pass to handleSubmit to (optionally) reset form
  // on successfull submit from parent component
  const getHandleResetCallback = (
    resetFormFn: (nextState: Partial<FormikState<EnrollmentFormikValues>>) => void
  ) => () => {
    resetFormFn({ values: initialFormikValues });
    dispatch({ type: 'RESET_FORM' });
  };

  const handleSubmit = async (
    values: EnrollmentFormikValues,
    ops: FormikHelpers<EnrollmentFormikValues>
  ) => {
    const { resetForm } = ops;
    const resetFormCallback = getHandleResetCallback(resetForm);

    await onSubmit(values, { resetFormFn: resetFormCallback });
  };

  const handleSelectWorkshop = (setValues: SetValues) => (workshop: Workshop) => {
    // when we select a new workshop: reset dropInIds + fullSeries
    setValues(
      getWorkshopEnrollmentFormikValues({
        memberId: selectedMember?.id || null,
        workshopId: workshop?.id || null,
        dropInIds: [],
      })
    );
    dispatch({ type: 'SET_SELECTED_WORKSHOP', workshop });
  };

  const handleSelectMember = (setFieldValue: SetFieldValue) => (member: Member) => {
    setFieldValue('memberId', member?.id);
    dispatch({ type: 'SET_SELECTED_MEMBER', member });
  };

  const handleToggleEntireSeries = ({ form }: FieldProps) => (e: ChangeEvent<HTMLInputElement>) => {
    const entireSeriesIsChecked = e.target.checked;
    const { setFieldValue } = form;
    if (entireSeriesIsChecked) {
      setFieldValue('dropInIds', []);
    }
    setFieldValue('fullSeries', entireSeriesIsChecked);
  };

  // Formatting UI Component Text
  const startAts = workshopWithIncludedOccurences?.workshopOccurrences.map(
    ({ startsAt }) => startsAt
  );
  const dateStr = formatPossiblyRecurringEventTimeLabel(startAts || []);
  const entireSeriesCheckboxLabel = `Entire Series - ${dateStr}`;

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormikValues}
      validationSchema={getSubscribeFormValidationSchema}
      isInitialValid={false}
    >
      {({ setFieldValue, setValues, isValid, values }: FormikProps<EnrollmentFormikValues>) => {
        const submitDisabled = !isValid;
        const { fullSeries } = values;
        const isDropInIdsCheckboxListDisabled = fullSeries;
        return (
          <Form>
            <Grid templateColumns="repeat(5, 1fr)" gap={{ md: 8, xl: 16 }} width="100%">
              <GridItem colSpan={2}>
                <Text fontSize="lg">Select User:</Text>
                <MemberSearchField
                  value={selectedMember}
                  onSelect={handleSelectMember(setFieldValue)}
                  isDisabled={disableSelectMember}
                  isResettable={!disableSelectMember}
                />
              </GridItem>
              <GridItem colSpan={3}>
                <Text fontSize="lg">Search Workshop & Select Dates:</Text>
                <WorkshopSearchField
                  value={selectedWorkshop}
                  onSelect={handleSelectWorkshop(setValues)}
                  isDisabled={disableSelectWorkshop}
                  isResettable={!disableSelectWorkshop}
                />
                {workshopWithIncludedOccurences && (
                  <Flex flexDir="column" alignItems="center" mt={2}>
                    <Flex width="100%">
                      {/*
                           Passing a custom onChange to the Entire Series Checkbox,
                           if we toggle this specific checkbox, we want to toggle enabled
                          state and clear workshop occurences checkbox list
                        */}
                      <Field label={entireSeriesCheckboxLabel} name="fullSeries">
                        {(fieldProps: FieldProps) => (
                          <Checkbox
                            onChange={handleToggleEntireSeries(fieldProps)}
                            name="fullSeries"
                            isChecked={fieldProps.field.value}
                            variant="coa-main"
                          >
                            {entireSeriesCheckboxLabel}
                          </Checkbox>
                        )}
                      </Field>
                    </Flex>
                    <Divider width="50%" my={2} />
                    <WorkshopOccurencesCheckboxList
                      fieldName="dropInIds"
                      isDisabled={isDropInIdsCheckboxListDisabled}
                      workshop={workshopWithIncludedOccurences}
                    />
                  </Flex>
                )}
              </GridItem>
            </Grid>
            <Flex width="100%" justifyContent="end" mt={4}>
              <Button
                disabled={submitDisabled}
                type="submit"
                size="md"
                mb={2}
                variant="secondary"
                data-cy="q_and_a-success-cta"
                isDisabled={isSubmitting}
              >
                {submitBtnLabel}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}
