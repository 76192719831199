import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';
import React, { useRef } from 'react';
import { Volume1Icon, Volume2Icon, VolumeXIcon } from '../../../../../../components/Icons';
import {
  VideoPlayerControlButton,
  VideoPlayerControlButtonProps,
} from './VideoPlayerControlButton';

/*
 * Dynamic volume icon component so we can show the
 * user how much they've PUMPED IT UP.
 */
const getVolumeIconComponent = (volume: number) => {
  if (volume === 0) return VolumeXIcon;
  if (volume < 1 / 2) return Volume1Icon;
  return Volume2Icon;
};

const HorizontalCollapse = ({
  in: isIn,
  inWidth,
  children,
  style,
  ...rest
}: MotionProps & { in: boolean; children: React.ReactNode; inWidth: string | number }) => (
  <motion.div
    initial={false}
    animate={{
      width: isIn ? inWidth : 0,
      opacity: isIn ? 1 : 0,
    }}
    transition={{ type: 'tween', duration: 0.25, delay: isIn ? 0 : 1 }}
    style={{
      overflow: 'hidden',
      ...style,
    }}
    {...rest}
  >
    {children}
  </motion.div>
);

export const VolumeButton = ({
  muted,
  volume: rawVolume,
  setVolume,
  ...rest
}: VideoPlayerControlButtonProps & {
  muted: boolean;
  volume: number;
  setVolume: (v: number) => void;
}) => {
  const initialFocusRef = useRef();
  const {
    isOpen: volumeSliderVisible,
    onOpen: showVolumeSlider,
    onClose: hideVolumeSlider,
  } = useDisclosure();
  const volume = muted ? 0 : rawVolume;
  const chakraSpace3StyleToken = useToken('space', '3');
  return (
    <Flex onMouseEnter={showVolumeSlider} onMouseLeave={hideVolumeSlider}>
      <VideoPlayerControlButton
        label="Mute"
        icon={React.createElement(getVolumeIconComponent(volume))}
        {...rest}
      />
      <HorizontalCollapse
        in={volumeSliderVisible}
        inWidth="90px"
        style={{ marginLeft: `-${chakraSpace3StyleToken}` }}
      >
        {/* We responsively hide this on mobile because the volume control slider doesn't reflect the
        native devices volume - as a result users will change their volume via the device on mobile */}
        <Box px={3} display={{ base: 'none', md: 'block' }}>
          <Slider
            value={
              // TODO: Rename this to volumePercent
              volume * 100
            }
            onChange={(volumePercent: number) => setVolume(volumePercent / 100)}
            size="sm"
          >
            <SliderTrack bgColor="whiteAlpha.400">
              <SliderFilledTrack bgColor="white" />
            </SliderTrack>
            <SliderThumb ref={initialFocusRef} bgColor="white" />
          </Slider>
        </Box>
      </HorizontalCollapse>
    </Flex>
  );
};
