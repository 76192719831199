import { Box } from '@chakra-ui/react';
import React from 'react';
import Logo from '../images/logo.svg';

function HeaderImage() {
  return (
    <Box
      padding="32px 16px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Logo width="200px" height="65px" />
    </Box>
  );
}

export default HeaderImage;
