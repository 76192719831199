import axios from 'axios';
import _ from 'lodash';
import { coaAxiosAuthConfig } from './coaAxiosAuthConfig';
import { authInterceptor } from './interceptors/authInterceptor';
import { caseInterceptor } from './interceptors/caseInterceptor';
import { customExceptionInterceptor } from './interceptors/customExceptionInterceptor';
import { generateJsonApiInterceptor } from './interceptors/jsonApiInterceptor';

export type CreateAxiosClientOptions = {
  auth?: boolean;
  transformPropertyNameCase?: boolean;
  normalizeJsonApi?: boolean;
  paginate?: boolean;
  raiseCustomExceptions?: boolean;
};

/*
 * Central tool for axios client creation that aims to standardize
 * ordering of our various interceptors.
 */
export const createAxiosClient = _.memoize(
  ({
    auth,
    transformPropertyNameCase,
    normalizeJsonApi,
    paginate,
    raiseCustomExceptions,
  }: CreateAxiosClientOptions = {}) => {
    const client = axios.create({ baseURL: coaAxiosAuthConfig.getBaseApiUrl() });
    const requestInterceptors = _.filter([
      // Note that normalization should happen prior to case transform.
      normalizeJsonApi && generateJsonApiInterceptor({ paginate }),
      transformPropertyNameCase && caseInterceptor,
      raiseCustomExceptions && customExceptionInterceptor,
      auth && authInterceptor,
    ]);
    client.interceptors.request.use(_.flow(requestInterceptors));
    return client;
  }
);
