import { Container, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { show as accountShow } from '../../../actions/accounts';
import { show as clientShow } from '../../../actions/clientPaymentProfiles';
import { LOG_OUT } from '../../../actions/sessions';
import Loading from '../../../components/Loading';
import { sessionLocalStorage } from '../../../lib/localStorage/sessionLocalStorage';
import Account from '../components/Account';

class AccountContainer extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    const { dispatch, paymentProfile } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    if (currentMember) {
      dispatch(accountShow());
      if (paymentProfile) return;
      dispatch(clientShow(currentMember.id));
    }
  }

  logOut() {
    const { dispatch } = this.props;
    dispatch({ type: LOG_OUT });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { loading, paymentProfile, account, gettingAccount, dispatch } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    if (loading || !currentMember || gettingAccount) {
      return <Loading fillPage />;
    }
    return (
      <Container maxWidth="container.xl">
        <Flex direction="column">
          <Account
            account={account}
            dispatch={dispatch}
            memberId={currentMember.id}
            role={currentMember.role}
            paymentProfile={paymentProfile}
            logOut={this.logOut}
          />
        </Flex>
      </Container>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loggedIn } = state.session;
  const { loading, paymentProfile } = state.clientPaymentProfiles;
  const { account, gettingAccount } = state.account;

  return {
    loggedIn,
    loading,
    paymentProfile,
    account,
    gettingAccount,
  };
};

AccountContainer.propTypes = {
  loading: PropTypes.bool,
  paymentProfile: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStatetoProps)(AccountContainer);
