import { WorkshopFilter, WorkshopFilterKind, WorkshopFilterValue } from '@coa/api/models/workshop';
import { useQueryParams } from '@coa/react-utils';
import _ from 'lodash';
import React, { createContext, ReactNode, useContext } from 'react';
import { useHistory } from 'react-router';

export interface AdminClassesQueryParams extends WorkshopFilter {
  page: number;
  accordion: number;
  order: 'asc' | 'desc';
  dateRange: [start: string, end: string];
}

const getAdminClassesQueryParams = (
  newFilters: Partial<AdminClassesQueryParams>,
  oldFilters: Partial<AdminClassesQueryParams>
) => {
  const filters = {
    ...oldFilters,
    ...newFilters,
  };
  return new URLSearchParams(
    // The URLSearchParams types don't currently accept objects
    // with an array value so we override.
    (filters as unknown) as Record<string, string>
  );
};

interface FiltersContext {
  filters: AdminClassesQueryParams;
  hasActiveFilters: boolean;
  clearFilters: () => void;
  setCurrentPage: (newPage: number) => void;
  setDateRange: (field: 'start' | 'end', value: string) => void;
  setUrlParams: (kind: WorkshopFilterKind, values: WorkshopFilterValue) => void;
  toggleAccordion: () => void;
  toggleSortOrder: (val: AdminClassesQueryParams['order']) => void;
}

const emptyFunc = () => {};
const INITIAL_URL_PARAMS: Partial<AdminClassesQueryParams> = {
  page: 1,
  order: 'asc',
  accordion: 1,
  dateRange: ['', ''],
};
export const AdminClassesFiltersContext = createContext<FiltersContext>({
  setUrlParams: emptyFunc,
  clearFilters: emptyFunc,
  setCurrentPage: emptyFunc,
  setDateRange: emptyFunc,
  toggleSortOrder: () => {},
  toggleAccordion: emptyFunc,
  hasActiveFilters: false,
  filters: { ...INITIAL_URL_PARAMS } as AdminClassesQueryParams,
});

const ADMIN_DASH_DEFAULT_URL = '/admin-dash/classes';

export const AdminClassesFiltersContextProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const page = queryParams.get('page') ? Number(queryParams.get('page')) : 1;
  const accordion = Number.isNaN(Number(queryParams.get('accordion')))
    ? INITIAL_URL_PARAMS.accordion
    : Number(queryParams.get('accordion'));
  const order = queryParams.get('order') || 'asc';

  const dateRangeValues = queryParams.get('dateRange');
  const dateRange = dateRangeValues
    ? dateRangeValues.split(',')
    : [...INITIAL_URL_PARAMS.dateRange];

  const filters = {
    page: page ? Number(page) : 1,
    order,
    accordion,
    dateRange,
  } as AdminClassesQueryParams;

  const pushNewQueryParams = (
    newParams: Partial<AdminClassesQueryParams>,
    oldParams: Partial<AdminClassesQueryParams>
  ) => {
    const params = getAdminClassesQueryParams(newParams, oldParams);
    history.push(`${ADMIN_DASH_DEFAULT_URL}?${params.toString()}`);
  };

  const clearFilters = () => {
    pushNewQueryParams({ ...INITIAL_URL_PARAMS }, {});
  };

  const setCurrentPage = (newPage: number) => {
    pushNewQueryParams({ page: newPage }, filters);
  };

  const setUrlParams = (kind: WorkshopFilterKind, values: WorkshopFilterValue) => {
    pushNewQueryParams({ [kind]: values }, filters);
  };

  const toggleAccordion = () => {
    pushNewQueryParams({ accordion: Number(!accordion) }, filters);
  };

  const toggleSortOrder = (newOrder: AdminClassesQueryParams['order']) => {
    pushNewQueryParams({ order: newOrder, page: INITIAL_URL_PARAMS.page }, filters);
  };

  const setDateRange = (field: 'start' | 'end', newValue: string) => {
    const paramIndex = field === 'start' ? 0 : 1;
    const updatedDateRange: AdminClassesQueryParams['dateRange'] = [...filters.dateRange];

    updatedDateRange[paramIndex] = newValue;
    pushNewQueryParams({ dateRange: updatedDateRange, page: INITIAL_URL_PARAMS.page }, filters);
  };

  const hasActiveFilters = Object.entries(filters).some(
    ([key, value]) => !_.isEqual(value, INITIAL_URL_PARAMS[key])
  );

  return (
    <AdminClassesFiltersContext.Provider
      value={{
        filters,
        hasActiveFilters,
        clearFilters,
        setCurrentPage,
        setDateRange,
        setUrlParams,
        toggleAccordion,
        toggleSortOrder,
      }}
    >
      {children}
    </AdminClassesFiltersContext.Provider>
  );
};

export const useAdminClassesFiltersContext = () => useContext(AdminClassesFiltersContext);
