import { Box, BoxProps, CloseButton, Text } from '@chakra-ui/react';
import React from 'react';
import { Member } from '../../resources/member';

export const AdminMemberDetailsCard = ({
  member,
  resetSearch,
  isResettable,
  ...rest
}: {
  member: Member;
  isResettable?: boolean;
  resetSearch: () => void;
} & BoxProps) => {
  const { email, name } = member;
  return (
    <Box p={4} backgroundColor="gray.50" borderRadius="base" position="relative" {...rest}>
      <Text>
        <strong>Name:</strong> {name}
      </Text>
      <Text>
        <strong>Email:</strong> {email}
      </Text>
      {isResettable ? (
        <CloseButton onClick={resetSearch} position="absolute" top={2} right={2} />
      ) : null}
    </Box>
  );
};
