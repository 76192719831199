import { CloseButtonProps, ModalCloseButton } from '@chakra-ui/react';
import React from 'react';

/*
 * In the current version of Chakra we are using, we are unable
 * to modify the top / right values of a ModalCloseButton. For
 * some reason they've decided to hardcode them. (See link below.)
 *
 * At the time of writing we are using @chakra-ui/core v1.0.0-rc.8.
 * This issue is resolved in a future stable version of Chakra, but
 * until we upgrade we use this instead.
 *
 * @see https://github.com/chakra-ui/chakra-ui/blob/de4cffb1638e8d10cd74515b0289d70eb94931be/packages/modal/src/modal.tsx#L495-L496
 */
export const ChakraFwdModalCloseButton = ({ sx, ...rest }: CloseButtonProps) => (
  <ModalCloseButton
    {...rest}
    sx={{
      top: 8,
      right: 8,
      ...sx,
    }}
  />
);
