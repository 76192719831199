import { adminApiPath } from '../../../lib/api';

const adminMembersShowApiPath = (id: string) => adminApiPath('members', id);
const adminMembersCreateApiPath = () => adminApiPath('members');
const adminMembersSearchApiPath = () => adminApiPath('members', 'search');

export const getAdminMembersApiUrl = {
  show: (id: string) => adminMembersShowApiPath(id),
  create: () => adminMembersCreateApiPath(),
  search: () => adminMembersSearchApiPath(),
};
