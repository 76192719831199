import _ from 'lodash';

export type AuthParams = {
  id: string;
  token: string;
};

/*
 * This acts as a bag of methods that differ on an app-by-app
 * basis depending on where auth credentials are stored.
 * For example, in web we use the localStorage / btoa web APIs for
 * storage and base64 token encoding, but neither of these APIs
 * are available in react-native.
 */
type CoaAxiosAuthConfig = {
  getAuthParams: () => Promise<AuthParams>;
  setAuthParams: (params: AuthParams) => Promise<void>;
  encodeStringifiedAuthParams: (str: string) => string;
  getBaseApiUrl: () => string;
};
export const coaAxiosAuthConfig = {} as CoaAxiosAuthConfig;

/*
 * Simply assigns config options to the bag of methods above.
 * To be called at client configuration time. (i.e. App.tsx, etc.)
 */
export const configureCoaAxiosAuth = (config: CoaAxiosAuthConfig) => {
  // We mutate so as to not redeclare the const above.
  Object.keys(config).forEach((key) => {
    coaAxiosAuthConfig[key] = config[key];
  });
};

/*
 * Very very very dumb helper that should assist in triaging
 * configuration issues, since the stuff discussed above isn't
 * too discoverable.
 */
export const validateCoaAxiosAuthConfig = () => {
  if (_.isEqual(coaAxiosAuthConfig, {})) {
    throw Error("You have not yet set up auth configuration for this application's axios clients.");
  }
};
