import {
  Box,
  BoxProps,
  ButtonProps,
  Circle,
  Container,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spacer,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { RouterLink } from '@coa/react-utils';
import { formatProseInt, formatProseStartTimeShort } from '@coa/stdlib/string';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ClassListingLabel } from '../../../components/atoms/ClassListingLabel';
import {
  ActivityIcon,
  ClockIcon,
  EditIcon,
  EyeIcon,
  GridIcon,
  MessageCircleIcon,
  MonitorIcon,
  MusicIcon,
  SearchIcon,
  SeriesIcon,
  TrendingUpIcon,
  UmbrellaIcon,
  UsersIcon,
} from '../../../components/Icons';
import { classBackgroundColor, classCopyColor } from '../../../components/molecules/ClassCard';
import {
  WorkshopApplyCta,
  WorkshopFinishApplicationCta,
  WorkshopJoinClassCta,
} from '../../../components/WorkshopCta';
import { WorkshopOccurrenceExerciseCta } from '../../../components/WorkshopExerciseCta';
import { classMarketplaceAnalytics } from '../../../lib/analytics/clients';
import { useThemedBreakpoint } from '../../../lib/chakra-ui';
import {
  createGetWorkshopPlaceholderData,
  useGetWorkshopQuery,
  useGetWorkshopSiblingsQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../resources/workshops';
import {
  getIsEnrolledInWorkshopDropIn,
  getIsEnrollmentApprovedForFullSeries,
  getIsEnrollmentApprovedForPartialSeries,
  getIsSeriesLike,
  getNextAvailableWorkshopOccurrence,
  getWorkshopHasEnded,
  getWorkshopHasStarted,
  getWorkshopOccurrenceHasEnded,
} from '../../../resources/workshops/utils';
import { getWorkshopRouterUrl } from '../../../routerPaths/helpers';
import { boxShadowStyles } from '../../../themes/coaTheme';
import { useHideNavMobile } from '../../shared/layout/components/GlobalLayoutProvider';
import { WorkshopsBreadcrumb } from '../WorkshopsBreadcrumb';
import {
  WorkshopDetailsLayout,
  WorkshopDetailsMainContent,
  WorkshopDetailsMobileStickyFooter,
  WorkshopDetailsScrollableContent,
} from './components/WorkshopDetailsLayout';
import { WorkshopDetailsHeroImage } from './components/WorkshopImageRibbon';
import { WorkshopSiblingsPicker } from './components/WorkshopSiblingsPicker';

export type WorkshopDetailsContentProps = {
  workshopId: Workshop['id'];
};

const WorkshopDetailsEnrollmentsNextClassText = ({
  workshopOccurrenceId,
  workshopId,
  ...rest
}: {
  workshopId: Workshop['id'];
  workshopOccurrenceId: WorkshopOccurrence['id'];
} & TextProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  // TODO: We may wish to consider a proper loading state
  if (getWorkshopQuery.isLoading) return null;

  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences, meta } = workshop;
  const workshopOccurrence = workshopOccurrences.find(({ id }) => id === workshopOccurrenceId);
  const { enrollmentStatus } = meta;

  const enrollmentCopyPrefix = _.filter([
    enrollmentStatus !== 'approved' ? 'Once approved,' : null,
    workshopOccurrences.length > 1 ? 'your next class' : 'it',
    'will',
    workshopOccurrences.length > 1 ? 'be' : 'meet',
  ]).join(' ');

  return (
    <Text {...rest}>
      {_.capitalize(enrollmentCopyPrefix)}{' '}
      {(() => {
        if (workshopOccurrences.length === 1) return null;
        <Text
          as={RouterLink}
          to={getWorkshopRouterUrl({
            id: workshopId,
            dropInWorkshopOccurrenceId: workshopOccurrenceId,
          })}
          textDecoration="underline"
          cursor="pointer"
        >
          <strong>{workshopOccurrence.title}</strong>
        </Text>;
      })()}{' '}
      on {formatProseStartTimeShort(workshopOccurrence.startsAt)}.
    </Text>
  );
};

const WorkshopDetailsEnrollmentStatus = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & StackProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;
  const { workshopOccurrences, meta: workshopMeta } = workshop;
  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;

  const hasPartialEnrollment = Boolean(enrollmentDropInIds);
  const seriesHasStarted = getWorkshopHasStarted(workshop);
  const seriesHasEnded = getWorkshopHasEnded(workshop);

  const hasExercises = Boolean(
    workshopOccurrences.find(
      ({ id, exerciseId }) =>
        (!enrollmentDropInIds || enrollmentDropInIds[id]) && Boolean(exerciseId)
    )
  );

  const nextAvailableWorkshopOccurrence = getNextAvailableWorkshopOccurrence(workshop);

  const enrolledDropInsLength = _.size(enrollmentDropInIds);
  const maybePluralClassNoun = enrolledDropInsLength > 1 ? 'classes' : 'class';
  const proseClassSubject =
    workshopOccurrences.length === 1
      ? 'this class'
      : hasPartialEnrollment
      ? `${formatProseInt(enrolledDropInsLength)} ${maybePluralClassNoun} in this series`
      : 'this series';

  return (
    <VStack
      spacing={4}
      {...rest}
      data-cy={`workshop-details-enrollment-info-${isLoading ? 'loading' : 'loaded'}`}
    >
      <Skeleton isLoaded={!isLoading} w="100%" h="100%">
        {(() => {
          if (workshop.status === 'archived') {
            return null;
          }
          if (!enrollmentStatus) {
            if (workshopOccurrences.length > 1 && seriesHasStarted && !seriesHasEnded) {
              return (
                <Text>
                  This series has begun but you can still book the remaining classes. Select from
                  the classes below to drop-in.
                </Text>
              );
            }
            return null;
          }
          if (enrollmentStatus === 'application_incomplete' && !seriesHasEnded) {
            return (
              <Text>
                You've started an application to {proseClassSubject}. Please finish your application
                to continue.
              </Text>
            );
          }
          if (enrollmentStatus === 'awaiting_info') {
            return (
              <Text>
                We are reviewing your application to {proseClassSubject}. We have sent you an email
                requesting more information. Please check your inbox!
              </Text>
            );
          }
          if (enrollmentStatus === 'pending') {
            return (
              <>
                <Text>
                  We are reviewing your application to {proseClassSubject}. You will receive an
                  email within 24 hours with an update on its status.
                </Text>
                {nextAvailableWorkshopOccurrence ? (
                  <WorkshopDetailsEnrollmentsNextClassText
                    workshopId={workshopId}
                    workshopOccurrenceId={nextAvailableWorkshopOccurrence.id}
                    w="100%"
                  />
                ) : null}
              </>
            );
          }
          if (enrollmentStatus === 'rejected') {
            return (
              <>
                <Text>
                  We have reviewed your application to {proseClassSubject} and are not able to offer
                  you a spot at this time.
                </Text>
                <Text>Please reach out to our support team for further assistance.</Text>
              </>
            );
          }

          if (enrollmentStatus === 'approved') {
            if (seriesHasEnded) {
              // TODO: Likely still need the ability to add more classes if
              // the series has not yet ended.
              return (
                <>
                  <Text>You attended {proseClassSubject} - well done!</Text>
                  {hasExercises ? <Text>Review your Class Exercises below.</Text> : null}
                </>
              );
            }
            return (
              <>
                <Text>You are booked for {proseClassSubject}.</Text>
                {nextAvailableWorkshopOccurrence ? (
                  <WorkshopDetailsEnrollmentsNextClassText
                    workshopId={workshopId}
                    workshopOccurrenceId={nextAvailableWorkshopOccurrence.id}
                    mt={4}
                  />
                ) : null}
              </>
            );
          }
        })()}
      </Skeleton>
    </VStack>
  );
};

const WorkshopDetailsOtherTimes = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & StackProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const getWorkshopSiblingsQuery = useGetWorkshopSiblingsQuery({ id: workshopId });
  const isLoading = getWorkshopQuery.isLoading || getWorkshopSiblingsQuery.isLoading;
  const { data: workshop } = getWorkshopQuery;
  const { enrollmentStatus } = workshop?.meta || {};

  const headingText =
    !isLoading && getWorkshopSiblingsQuery.data.length
      ? enrollmentStatus
        ? 'Alternate Dates & Instructors'
        : 'Choose Your Date & Instructor'
      : 'Your Date & Instructor';

  return (
    <VStack spacing={2} w="100%" {...rest}>
      <Skeleton isLoaded={!isLoading} w="100%">
        <Heading size="md">{headingText}</Heading>
      </Skeleton>
      <WorkshopSiblingsPicker
        my={4}
        width="100%"
        workshopId={workshopId}
        getLinkRoute={({ id }) => `/classes/${id}`}
        {...boxShadowStyles}
      />
    </VStack>
  );
};

const buttonVariantByClassKind = ({ kind }: { kind: Workshop['kind'] }) => {
  if (kind === 'intro') {
    return 'ctaOrange';
  }
  if (kind === 'q_and_a') {
    return 'ctaGray';
  }
  if (getIsSeriesLike(kind)) {
    return 'primary';
  }
};

const WorkshopDetailsCta = ({ workshopId, ...rest }: WorkshopDetailsContentProps & ButtonProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { data: workshop } = getWorkshopQuery;

  const { isLoading } = getWorkshopQuery;
  if (isLoading) return null;

  const {
    meta: { enrollmentStatus, enrollmentDropInIds },
    kind,
  } = workshop;

  const seriesHasEnded = getWorkshopHasEnded(workshop);
  const isEnrolledInPartialSeries = getIsEnrollmentApprovedForPartialSeries({
    enrollmentStatus,
    enrollmentDropInIds,
  });

  const propsWithDefaults = {
    ...rest,
    size: 'lg',
  };

  const getWorkshopHasFutureOccurrencesUserIsNotEnrolledIn = () => {
    if (enrollmentDropInIds === null) return false;

    const now = new Date();
    const getWorkshopStuff = workshop.workshopOccurrences.map((occurrence) => {
      const startsAtDate = new Date(occurrence.startsAt);
      const inTheFuture = startsAtDate > now;
      const enrolledInOccurrence = enrollmentDropInIds[occurrence.id] || false;
      if (inTheFuture && !enrolledInOccurrence) {
        return true;
      }
      return false;
    });

    return getWorkshopStuff.includes(true);
  };

  if (enrollmentStatus === 'application_incomplete') {
    return (
      <WorkshopFinishApplicationCta
        workshopId={workshopId}
        variant={buttonVariantByClassKind({ kind })}
        {...propsWithDefaults}
        width="100%"
      />
    );
  }

  // We only want to show the ApplyCTA under these conditions:
  // - Class has not yet ended AND either:
  // - There is no enrollment status (the user is not enrolled)
  // - The user is partially enrolled in the Series and there are future
  //   available drop-ins that they are not enrolled in
  if (
    !seriesHasEnded &&
    (!enrollmentStatus || getWorkshopHasFutureOccurrencesUserIsNotEnrolledIn())
  ) {
    if (getIsSeriesLike(kind)) {
      if (isEnrolledInPartialSeries) {
        return (
          <>
            <WorkshopJoinClassCta
              workshopId={workshopId}
              variant={buttonVariantByClassKind({ kind })}
              {...propsWithDefaults}
              width="100%"
            />
            <Spacer mt={{ base: 4, md: 4, lg: 0 }} ml={{ base: 0, md: 0, lg: 4 }} />
            <WorkshopApplyCta workshopId={workshopId} {...propsWithDefaults} width="100%" />
          </>
        );
      }
      return <WorkshopApplyCta workshopId={workshopId} {...propsWithDefaults} width="100%" />;
    }
    return (
      <WorkshopApplyCta
        workshopId={workshopId}
        variant={buttonVariantByClassKind({ kind })}
        {...propsWithDefaults}
        width="100%"
      />
    );
  }

  if (enrollmentStatus === 'approved' && !seriesHasEnded) {
    return (
      <WorkshopJoinClassCta
        workshopId={workshopId}
        variant={buttonVariantByClassKind({ kind })}
        {...propsWithDefaults}
        width="100%"
      />
    );
  }
  return null;
};

const WorkshopDetailsCtaSection = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & BoxProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;
  const { workshopOccurrences, meta: workshopMeta } = workshop;
  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;

  const hasExercises = Boolean(
    workshopOccurrences.find(
      ({ id, exerciseId }) =>
        (!enrollmentDropInIds || enrollmentDropInIds[id]) && Boolean(exerciseId)
    )
  );

  const classHasStarted = isLoading ? false : getWorkshopHasStarted(workshop);
  const classHasEnded = isLoading ? false : getWorkshopHasEnded(workshop);

  const isSeriesKindAndStillInProgress = isLoading
    ? false
    : workshopOccurrences.length > 1 && classHasStarted && !classHasEnded;

  if (workshop.status === 'archived') return <Text>This class has been canceled.</Text>;

  return (
    <Flex
      flexDir={{ base: 'column', md: 'column', lg: 'row' }}
      bgColor={{
        base: enrollmentStatus !== 'approved' && isSeriesKindAndStillInProgress ? 'warm.50' : null,
        md: 'transparent',
      }}
      {...rest}
    >
      {enrollmentStatus !== 'approved' && isSeriesKindAndStillInProgress ? (
        <Text
          size="md"
          display={{
            base: 'block',
            md: 'none',
          }}
        >
          <Text as="span" fontWeight="semibold">
            This series has begun but you can still book from the remaining classes.{' '}
          </Text>
          Select a class to drop-in.
        </Text>
      ) : null}
      <WorkshopDetailsCta workshopId={workshopId} {...boxShadowStyles} />
      {/* This is a bit of a pain, but different class states/enrollment statuses can mean different buttons will render.
      We add this small space for scenarios when a user's enrollment is 'approved' and the class has not yet ended */}
      {enrollmentStatus === 'approved' &&
      workshopOccurrences.length === 1 &&
      hasExercises &&
      !classHasEnded ? (
        <Spacer mt={{ base: 4, md: 4, lg: 0 }} ml={{ base: 0, md: 0, lg: 4 }} />
      ) : null}
      {enrollmentStatus === 'approved' && workshopOccurrences.length === 1 && hasExercises ? (
        <>
          <Skeleton isLoaded={!isLoading} w="100%">
            {workshop.kind === 'intro' ? (
              <WorkshopOccurrenceExerciseCta
                size="lg"
                variant="ctaOrangeLight"
                workshopId={workshop.id}
                exerciseId={workshopOccurrences[0].exerciseId}
                occurrenceStartsAt={workshopOccurrences[0].startsAt}
                isOnlyOccurrence
                width="100%"
                {...boxShadowStyles}
                data-cy="workshop-details-main-exercise-cta"
              />
            ) : (
              <WorkshopOccurrenceExerciseCta
                size="lg"
                workshopId={workshop.id}
                exerciseId={workshopOccurrences[0].exerciseId}
                occurrenceStartsAt={workshopOccurrences[0].startsAt}
                isOnlyOccurrence
                width="100%"
                {...boxShadowStyles}
                data-cy="workshop-details-main-exercise-cta"
              />
            )}
          </Skeleton>
        </>
      ) : null}
    </Flex>
  );
};

const WorkshopDetailsHeroSection = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & StackProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;

  return (
    <Container
      maxW="100%"
      backgroundColor={
        isLoading ? '' : classBackgroundColor({ type: workshop.type, kind: workshop.kind })
      }
      color={classCopyColor({ type: workshop.type, kind: workshop.kind })}
      position="relative"
      display="flex"
      flexDir={{ base: 'column-reverse', md: 'row' }}
      alignItems="stretch"
      px={0}
      {...rest}
    >
      <Container maxW="container.lg" display="flex">
        <VStack
          position="relative"
          py={{ base: 8, md: 12 }}
          pr={{ base: 0, md: 8 }}
          alignItems="stretch"
          w={{ base: '100%', md: '60%' }}
          spacing={10}
        >
          <WorkshopsBreadcrumb />
          <Box>
            <Skeleton
              isLoaded={!isLoading}
              display="flex"
              flexDir="column"
              alignItems="flex-start"
              w="100%"
              noOfLines={2}
            >
              <Heading size="2xl" lineHeight={1.1} noOfLines={4} maxW="570px" mb={4}>
                {workshop.title}
              </Heading>
            </Skeleton>
            <WorkshopDetailsEnrollmentStatus workshopId={workshopId} fontSize="lg" />
          </Box>
          <Box>
            <WorkshopDetailsOtherTimes workshopId={workshopId} />
          </Box>
          <WorkshopDetailsCtaSection
            workshopId={workshopId}
            display={{ base: 'none', md: 'flex' }}
          />
          {!hasActiveSubscription && !getSubscriptionsQuery.isLoading ? (
            <ClassListingLabel access={workshop.access} position="absolute" />
          ) : null}
        </VStack>
      </Container>
      <Skeleton isLoaded={!isLoading}>
        <WorkshopDetailsHeroImage
          workshopId={workshopId}
          w={{ base: '100%', md: '40%' }}
          position={{ base: 'relative', md: 'absolute' }}
          minHeight={{ base: '40vh', md: 'none' }}
          top={0}
          bottom={0}
          right={0}
        />
      </Skeleton>
    </Container>
  );
};

const WorkshopDetailsAboutSection = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & StackProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;
  return (
    <VStack alignItems="start" spacing={4} {...rest} mb={{ base: 4, md: 0 }}>
      <Skeleton isLoaded={!isLoading} w="100%">
        <Heading size="xl">
          About this {getIsSeriesLike(workshop.kind) ? 'Series' : 'Class'}
        </Heading>
      </Skeleton>
      <SkeletonText isLoaded={!isLoading} w="100%">
        {workshop.shortBlurb ? (
          <Text fontSize="lg" fontWeight="semibold" style={{ whiteSpace: 'pre-line' }} mb="1.5em">
            {workshop.shortBlurb}
          </Text>
        ) : null}
        <Text fontSize="lg" style={{ whiteSpace: 'pre-line' }}>
          {workshop.description}
        </Text>
      </SkeletonText>
    </VStack>
  );
};

export const WorkshopIconHighlightsSection = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopDetailsContentProps & {
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
} & StackProps) => {
  const placeholderData = createGetWorkshopPlaceholderData({ id: workshopId });
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { placeholderData });
  const { data: workshop } = getWorkshopQuery;
  const isLoading = getWorkshopQuery.isLoading || getWorkshopQuery.isPlaceholderData;

  const { kind, type } = workshop;
  const numberOfClasses = workshop.workshopOccurrences.length;
  const maybePluralClassNoun =
    numberOfClasses > 1 && !dropInWorkshopOccurrenceId ? 'classes' : 'class';
  const durationLabel = `${workshop.workshopOccurrences[0].durationInMinutes}-min ${maybePluralClassNoun} `;

  return (
    <VStack alignItems="baseline" spacing={4} {...rest}>
      {/* While still loading, return 3 icon features for Skeleton loading state */}
      {isLoading ? (
        <>
          {_.times(3, (index) => (
            <Flex alignItems="center" w="100%" key={index}>
              <SkeletonCircle size="35px" mr={4} />
              <SkeletonText noOfLines={1} w="70%" />
            </Flex>
          ))}
        </>
      ) : (
        <>
          {numberOfClasses > 1 && !dropInWorkshopOccurrenceId ? (
            <Flex alignItems="center">
              <Circle
                size="35px"
                bgColor={classBackgroundColor({
                  kind,
                  type,
                })}
                mr={4}
              >
                <SeriesIcon color={classCopyColor({ kind, type })} boxSize={4} />
              </Circle>
              <Text fontSize="lg" whiteSpace="nowrap">
                {numberOfClasses}-class series
              </Text>
            </Flex>
          ) : null}
          {getIsSeriesLike(kind) || kind === 'q_and_a' ? (
            <Flex alignItems="center">
              <Circle
                size="35px"
                bgColor={classBackgroundColor({
                  kind,
                  type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                })}
                mr={4}
              >
                <ClockIcon
                  color={classCopyColor({
                    kind,
                    type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                  })}
                  boxSize={4}
                />
              </Circle>
              <Text fontSize="lg" whiteSpace="nowrap">
                {durationLabel}
              </Text>
            </Flex>
          ) : kind === 'intro' ? (
            <>
              <Flex alignItems="center">
                <Circle
                  size="35px"
                  bgColor={classBackgroundColor({
                    kind,
                    type,
                  })}
                  mr={4}
                >
                  <MonitorIcon color={classCopyColor({ kind, type })} boxSize={4} />
                </Circle>
                <Text fontSize="lg" whiteSpace="nowrap">
                  Live Intro Class
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Circle
                  size="35px"
                  bgColor={classBackgroundColor({
                    kind,
                    type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                  })}
                  mr={4}
                >
                  <ClockIcon
                    color={classCopyColor({
                      kind,
                      type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                    })}
                    boxSize={4}
                  />
                </Circle>
                <Text fontSize="lg" whiteSpace="nowrap">
                  {workshop.workshopOccurrences[0].durationInMinutes}-min emotional push-up
                </Text>
              </Flex>
            </>
          ) : null}
          <Flex alignItems="center">
            <Circle
              size="35px"
              bgColor={classBackgroundColor({
                kind,
                type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
              })}
              mr={4}
            >
              <EditIcon
                color={classCopyColor({
                  kind,
                  type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                })}
                boxSize={4}
              />
            </Circle>
            <Text fontSize="lg" whiteSpace="nowrap">
              Digital exercises and prompts
            </Text>
          </Flex>
          {getIsSeriesLike(kind) ? (
            <Flex alignItems="center">
              <Circle
                size="35px"
                bgColor={classBackgroundColor({
                  kind,
                  type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                })}
                mr={4}
              >
                <UsersIcon
                  color={classCopyColor({
                    kind,
                    type: dropInWorkshopOccurrenceId ? 'workshop_occurrence' : type,
                  })}
                  boxSize={4}
                />
              </Circle>
              <Text fontSize="lg" whiteSpace="nowrap">
                Breakout room discussions
              </Text>
            </Flex>
          ) : null}
          {kind === 'q_and_a' ? (
            <Flex alignItems="center">
              <Circle
                size="35px"
                bgColor={classBackgroundColor({
                  kind,
                  type,
                })}
                mr={4}
              >
                <MessageCircleIcon color={classCopyColor({ kind, type })} boxSize={4} />
              </Circle>
              <Text fontSize="lg" whiteSpace="nowrap">
                Ask your instructor questions
              </Text>
            </Flex>
          ) : null}
        </>
      )}
    </VStack>
  );
};

const RenderIconBasedOnOccurrenceSkill = ({
  skill,
  title,
  color = 'brand.charcoal',
}: {
  skill: WorkshopOccurrence['skill'];
  title: WorkshopOccurrence['title'];
  color: string;
}) => {
  const iconStyles = { boxSize: '34px', color };
  return title.toLowerCase().includes('reflection') ? (
    <ActivityIcon {...iconStyles} />
  ) : skill === 'communication' ? (
    <MessageCircleIcon {...iconStyles} />
  ) : skill === 'curiosity' ? (
    <EyeIcon {...iconStyles} />
  ) : skill === 'empathy' ? (
    <UsersIcon {...iconStyles} />
  ) : skill === 'mindfulness' ? (
    <UmbrellaIcon {...iconStyles} />
  ) : skill === 'play' ? (
    <MusicIcon {...iconStyles} />
  ) : skill === 'resilience' ? (
    <TrendingUpIcon {...iconStyles} />
  ) : skill === 'self_awareness' ? (
    <SearchIcon {...iconStyles} />
  ) : (
    <GridIcon {...iconStyles} />
  );
};

const WorkshopDetailsOccurrence = ({
  workshopOccurrence,
  workshop,
  isUpcoming,
}: {
  isUpcoming?: boolean;
  workshopOccurrence: WorkshopOccurrence;
  workshop: Workshop;
}) => {
  const { enrollmentStatus, enrollmentDropInIds } = workshop.meta || {};

  const {
    title: occurrenceTitle,
    description: occurrenceDescription,
    startsAt,
    durationInMinutes,
    exerciseId,
    id,
    skill,
  } = workshopOccurrence;

  const startTime = dayjs(startsAt);
  const formattedStartTime = startTime.format('MMM D • h:mma');
  let seenUpcomingOccurrence = false;
  let isUpcomingOccurrence = false;
  if (
    workshop.joinUrl &&
    !getWorkshopOccurrenceHasEnded(workshopOccurrence) &&
    !seenUpcomingOccurrence
  ) {
    isUpcomingOccurrence = true;
    seenUpcomingOccurrence = true;
  }

  const isEnrolledInWorkshopDropIn = getIsEnrolledInWorkshopDropIn(workshop.meta, id);

  const occurrenceHasEnded = getWorkshopOccurrenceHasEnded(workshopOccurrence);

  const linkToDropInsProps = {
    as: RouterLink,
    to: `/classes/${workshop.id}/drop-ins/${workshopOccurrence.id}`,
    cursor: 'pointer',
    'data-cy': 'workshop-details-drop-in-link',
  };

  const nextOccurrencePrimaryColor = isUpcoming ? 'brand.evergreen' : 'brand.charcoal';

  const dateUpcomingStyles = {
    fontWeight: 'semibold',
    // bgColor conversion to rgba green.50 with 0.6 opacity
    bgColor: isUpcoming ? 'brand.evergreen' : 'rgba(233, 242, 236, 0.6)',
    color: isUpcoming ? 'white' : 'brand.charcoal',
    borderRadius: 'md',
    padding: 2,
  };

  const datePastStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
    padding: 1,
  };

  const exerciseCtaUpcomingStyles = {
    ...boxShadowStyles,
  };

  const exerciseCtaPastStyles = {
    bgColor: 'transparent',
    color: 'brand.evergreen',
    _hover: { bgColor: 'transparent', color: 'green.700' },
    _active: { bgColor: 'transparent', color: 'green.700' },
    p: { base: 0, md: 4 },
    justifyContent: { base: 'flex-start', md: 'center' },
  };

  return (
    <LinkBox
      as="article"
      display="flex"
      my={2}
      w="100%"
      borderRadius="lg"
      p={{ base: 4, md: 8 }}
      borderLeft={isUpcoming ? '#0C383A 16px solid' : null}
      transition="all 0.2s ease"
      _hover={{ transform: 'scale(1.02)' }}
      {...boxShadowStyles}
    >
      {RenderIconBasedOnOccurrenceSkill({
        skill,
        title: occurrenceTitle,
        color: nextOccurrencePrimaryColor,
      })}
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        ml={{ base: 4, md: 0 }}
        w="100%"
      >
        <VStack mx={{ base: 0, md: 4 }} mb={{ base: 8, md: 4 }} alignItems="baseline">
          <LinkOverlay {...linkToDropInsProps}>
            <Heading size="lg" display="flex" color={nextOccurrencePrimaryColor}>
              {occurrenceTitle}
            </Heading>
          </LinkOverlay>
          <Text
            lineHeight={1}
            padding={3}
            {...(getWorkshopOccurrenceHasEnded(workshopOccurrence)
              ? datePastStyles
              : dateUpcomingStyles)}
          >
            {formattedStartTime} • {`${durationInMinutes} Minutes`}
          </Text>
          {getWorkshopOccurrenceHasEnded(workshopOccurrence) ? null : (
            <Text whiteSpace="pre-line">{occurrenceDescription}</Text>
          )}
        </VStack>

        <VStack alignItems="stretch" justifyContent="center">
          {isEnrolledInWorkshopDropIn && !getWorkshopOccurrenceHasEnded(workshopOccurrence) ? (
            <Text
              whiteSpace="nowrap"
              fontSize="sm"
              fontWeight="semibold"
              textAlign={{ base: 'left', md: 'center' }}
            >
              You are registered for this class
            </Text>
          ) : null}
          {(isEnrolledInWorkshopDropIn ||
            getIsEnrollmentApprovedForFullSeries({ enrollmentStatus, enrollmentDropInIds })) &&
          getWorkshopOccurrenceHasEnded(workshopOccurrence) ? (
            <Text
              whiteSpace="nowrap"
              fontSize="sm"
              color="brand.evergreen"
              textAlign={{ base: 'left', md: 'center' }}
            >
              Class Completed
            </Text>
          ) : null}
          {(exerciseId &&
            getIsEnrollmentApprovedForFullSeries({
              enrollmentStatus,
              enrollmentDropInIds,
            })) ||
          isEnrolledInWorkshopDropIn ? (
            <>
              <WorkshopOccurrenceExerciseCta
                workshopId={workshop.id}
                exerciseId={exerciseId}
                occurrenceStartsAt={startsAt}
                isUpcoming={isUpcomingOccurrence}
                my={{ base: 4, md: 0 }}
                isDisabled={!isUpcoming && !occurrenceHasEnded}
                size="md"
                height="auto"
                padding={4}
                data-cy={`workshop-details-occ-exercise-cta-${exerciseId}`}
                {...(getWorkshopOccurrenceHasEnded(workshopOccurrence)
                  ? { ...exerciseCtaPastStyles }
                  : { ...exerciseCtaUpcomingStyles })}
              />
              {!occurrenceHasEnded && isUpcoming ? (
                <WorkshopJoinClassCta
                  workshopId={workshop.id}
                  variant={buttonVariantByClassKind({ kind: workshop.kind })}
                  width="100%"
                  mt={{ base: 0, md: 2 }}
                  height="auto"
                  padding={4}
                  {...boxShadowStyles}
                />
              ) : null}
            </>
          ) : null}
        </VStack>
      </Flex>
    </LinkBox>
  );
};

const WorkshopDetailsOccurrencesSection = ({
  workshopId,
  ...rest
}: WorkshopDetailsContentProps & StackProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  if (getWorkshopQuery.isLoading) return null;

  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences = [], meta: workshopMeta } = workshop;
  const seriesHasEnded = getWorkshopHasEnded(workshop);
  const seriesHasStarted = getWorkshopHasStarted(workshop);
  if (workshopOccurrences.length < 2) return null;

  const nextOccurrence = workshopOccurrences.find(
    (occurrence) =>
      getIsEnrolledInWorkshopDropIn(workshopMeta, occurrence.id) &&
      !getWorkshopOccurrenceHasEnded(occurrence)
  );

  const upcomingOccurrences = workshopOccurrences.filter(
    (occurrence) => !getWorkshopOccurrenceHasEnded(occurrence)
  );

  const pastOccurrences = workshopOccurrences.filter((occurrence) =>
    getWorkshopOccurrenceHasEnded(occurrence)
  );

  const isEnrolledInFullSeries = getIsEnrollmentApprovedForFullSeries({
    enrollmentStatus: workshopMeta.enrollmentStatus,
    enrollmentDropInIds: workshopMeta.enrollmentDropInIds,
  });

  return (
    <VStack w="100%" spacing={10} {...rest}>
      {workshopMeta.enrollmentStatus === 'approved' &&
      !seriesHasEnded &&
      (nextOccurrence || isEnrolledInFullSeries) ? (
        <Box w="100%">
          <Heading size="xl">Your Next Class</Heading>
          <VStack spacing={4} mt={4}>
            <WorkshopDetailsOccurrence
              workshopOccurrence={nextOccurrence || upcomingOccurrences[0]}
              workshop={workshop}
              isUpcoming
            />
          </VStack>
        </Box>
      ) : null}

      {/* We do not want to show this entire "Upcoming Classes" section under the following conditons:
      - the Series has ended
      OR
      - there is only 1 upcoming Class in the Series AND
        the user is either enrolled in that Class OR the user is enrolled in the entire Series */}
      {seriesHasEnded ||
      (upcomingOccurrences.length === 1 &&
        (getIsEnrolledInWorkshopDropIn(workshopMeta, nextOccurrence?.id) ||
          isEnrolledInFullSeries)) ? null : (
        <Box w="100%">
          <Heading size="xl">Upcoming Classes in this Series</Heading>
          <VStack spacing={4} mt={4}>
            {upcomingOccurrences.map((occurrence, idx: number) => {
              const isEnrolledInWorkshopDropIn = getIsEnrolledInWorkshopDropIn(
                workshopMeta,
                occurrence.id
              );

              /*
               * We do not want to show duplicates in "Upcoming Classes" section above section in "Your Next Class"
               * if the first Upcoming Class happens to be the users "Next Class".
               * We check if below two are TRUE, if so we do not render an occurrence tile
               * - is the user enrolled in the entire series OR enrolled in this particular occurrence
               * - is it the next upcoming occurrence (idx === 0)
               *
               * If either is true we do not render the occurrence tile because we're already rendering it in the above
               * "Your Next Class" section, otherwise we render the tiles per usual.
               */
              return (isEnrolledInFullSeries || isEnrolledInWorkshopDropIn) && idx === 0 ? null : (
                <WorkshopDetailsOccurrence
                  key={idx}
                  workshopOccurrence={occurrence}
                  workshop={workshop}
                />
              );
            })}
          </VStack>
        </Box>
      )}

      {seriesHasStarted ? (
        <Box w="100%">
          <Heading size="xl">Past</Heading>
          <VStack spacing={4} mt={4}>
            {pastOccurrences.map((occurrence, idx: number) => (
              <WorkshopDetailsOccurrence
                key={idx}
                workshopOccurrence={occurrence}
                workshop={workshop}
              />
            ))}
          </VStack>
        </Box>
      ) : null}
    </VStack>
  );
};

export const WorkshopDetailsContent = ({ workshopId }: WorkshopDetailsContentProps) => {
  const { pathname } = useLocation();
  const [isAboveMdBreakpoint] = useThemedBreakpoint({ above: 'md' });

  useHideNavMobile();

  useEffect(() => {
    const pathsToIgnore = ['application', 'join', 'subscribe'];
    const isClassDetailsPage = !pathsToIgnore.some((path) => pathname.includes(path));

    if (isClassDetailsPage)
      classMarketplaceAnalytics.track('Viewed Class Details', { classId: workshopId });

    /*
     * We trigger tracking on workshopId and pathname change (rather than a simple
     * onMount event) in case the user navigates from one Workshop
     * directly to another and the parent component does not unmount.
     * We are ignoring tracking for the following paths since they are not technically
     * a "Class Details" page but rather a modal triggered from the Class page:
     * - 'application'
     * - 'join'
     */
  }, [workshopId, pathname]);
  return (
    <WorkshopDetailsLayout>
      <WorkshopDetailsScrollableContent>
        <WorkshopDetailsMainContent>
          <WorkshopDetailsHeroSection workshopId={workshopId} />
          <Container maxW="container.lg">
            <VStack spacing={12}>
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: 'column', md: 'row' }}
                mb={{ base: 4, md: 0 }}
              >
                {/* 60%/30% widths below were eyeballed to match the divide of Hero
                content and image */}
                <WorkshopDetailsAboutSection
                  workshopId={workshopId}
                  w={{ base: '100%', md: '60%' }}
                />
                <WorkshopIconHighlightsSection
                  workshopId={workshopId}
                  w={{ base: '35%', lg: '30%' }}
                  px={{ base: 0, lg: 6 }}
                />
              </Box>
              <WorkshopDetailsOccurrencesSection workshopId={workshopId} />
            </VStack>
          </Container>
        </WorkshopDetailsMainContent>
      </WorkshopDetailsScrollableContent>
      {
        // A simple CSS hide here does not suffice as it will still appear in the DOM
        // and be queryable by Cypress.
        isAboveMdBreakpoint ? null : (
          <WorkshopDetailsMobileStickyFooter>
            <WorkshopDetailsCtaSection workshopId={workshopId} p={4} />
          </WorkshopDetailsMobileStickyFooter>
        )
      }
    </WorkshopDetailsLayout>
  );
};
