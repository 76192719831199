import {
  CSSObject,
  SystemProps,
  ThemingProps,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';

export type PseudoStyleConfig = CSSObject & Record<keyof SystemProps, CSSObject>;
export type MultiPseudoStyleConfig = Record<string, PseudoStyleConfig>;

/*
 * Due to a Chakra TS bug, useStyleConfig (and useMultiStyleConfig)
 * do not allow for the referencing of PsuedoProps (i.e. _disabled,
 * _active, etc.), which creates typing problems for us. Here we
 * consolidate usage by overloading its return to allow for access
 * to PseudoProps, which are totally valid (and present).
 *
 * We consolidate here for ease of use, rather than having callers
 * assert the return type of useStyleConfig as the SystemStyleConfig
 * type defined above.
 */
export const usePseudoStyleConfig = (
  themeKey: string,
  props: ThemingProps & Dict
): PseudoStyleConfig => useStyleConfig(themeKey, props) as PseudoStyleConfig;

export const useMultiPseudoStyleConfig = (
  themeKey: string,
  props: ThemingProps & Dict
): MultiPseudoStyleConfig => useMultiStyleConfig(themeKey, props) as MultiPseudoStyleConfig;
