import { partitionObject } from '@coa/stdlib/object';
import { serverCase } from '@coa/stdlib/string';
import { AnyObject } from '@coa/types';
import _ from 'lodash';

type QueryParamValue = string | number; // This may not be exhaustive.
type AllowedQueryParams = {
  [s: string]: QueryParamValue | Array<QueryParamValue>;
};

function joinQueryStringSegments(...segments: Array<string>) {
  return _.filter(segments).join('&');
}

export function appendQueryParams(base: string, queryParams: AllowedQueryParams): string {
  if (_.isEmpty(queryParams)) return base;
  const [arrayParams, nonArrayParams] = partitionObject(queryParams, _.isArray);
  const nonArrayParamsQueryString = new URLSearchParams(nonArrayParams).toString();
  const arrayParamsQueryString = _.reduce(
    arrayParams,
    (accumulator, value, key) => {
      const arrEntries = value.map((arrEntry: QueryParamValue) => `${key}[]=${arrEntry}`);
      return joinQueryStringSegments(accumulator, ...arrEntries);
    },
    ''
  );
  const queryString = joinQueryStringSegments(nonArrayParamsQueryString, arrayParamsQueryString);

  return [base, queryString].join('?');
}

export function appendQueryParamsWithCaseTransform(base: string, clientCaseParams: AnyObject) {
  const serverCaseParams = _.mapKeys(clientCaseParams, (_val, key) => serverCase(key));
  return appendQueryParams(base, serverCaseParams);
}
