import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { EmptyObject } from '@coa/types';
import React, { useCallback } from 'react';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../../lib/react-query';
import {
  GetTeam,
  TeamMember,
  useDeleteTeamMembersMutation,
  useGetTeamQuery,
} from '../../../../resources/teams';

export const RemoveTeamMemberModal = ({
  isOpen,
  onClose,
  member = {},
  id,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & {
  id: string;
  member?: Pick<TeamMember, 'id' | 'name' | 'email'> | EmptyObject | undefined;
}) => {
  const rawDeleteTeamMembersMutation = useDeleteTeamMembersMutation({ id, memberId: member.id });
  const deleteTeamMembersMutation = useMutationWithTimer(rawDeleteTeamMembersMutation, {
    duration: 500,
  });
  const { isLoadingWithTimer } = deleteTeamMembersMutation;
  const teamQuery = useGetTeamQuery({ id });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    // If the user re-launches, the state will be clean.
    deleteTeamMembersMutation.resetWithTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: { attributes: team = {} as GetTeam.Response['data']['attributes'] } = {} } =
    teamQuery.data || {};

  if (teamQuery.isLoading) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" variant="coa-main">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Remove Member</Heading>
        </ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <ModalBody>
          <VStack spacing={2} alignItems="flex-start">
            <Text>
              Are you sure you wish to remove <strong>{member.name}</strong> from{' '}
              <strong>{team.title}</strong>?
            </Text>
            <Text>
              This will remove {member.name} from any sub-teams in the {team.title} organization as
              well.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              isLoading={isLoadingWithTimer}
              onClick={async () => {
                try {
                  await deleteTeamMembersMutation.mutateAsyncWithTimer();
                  handleClose();
                  toast({
                    title: 'Success!',
                    description: `You have successfully removed ${member.name} from ${team.title}.`,
                    duration: 2000,
                    isClosable: true,
                    status: 'success',
                  });
                } catch (err) {
                  toast({
                    title: 'Something went wrong.',
                    description: 'Please try again.',
                    duration: 2000,
                    isClosable: true,
                    status: 'error',
                  });
                }
              }}
            >
              Remove Member
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
