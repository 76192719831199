export const TAGS_REQUEST = 'TAGS_REQUEST';
export const TAGS_SUCCESS = 'TAGS_SUCCESS';
export const TAGS_FAILURE = 'TAGS_FAILURE';

export function list() {
  return {
    type: TAGS_REQUEST,
    api: {
      endpoint: 'tags',
      authenticated: true,
      types: [TAGS_REQUEST, TAGS_SUCCESS, TAGS_FAILURE],
      method: 'GET',
    },
  };
}
