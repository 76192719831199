import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../containers/routes';
import { routerPaths } from '../../routerPaths';
import { OnDemandHubView } from './hub/OnDemandHubView';
import { OnDemandVideoView } from './video/OnDemandVideoView';

export const OnDemandView = () => (
  <Switch>
    <AuthenticatedRoute
      path={[routerPaths.onDemand.videoCheckpoint, routerPaths.onDemand.video]}
      component={OnDemandVideoView}
    />
    <AuthenticatedRoute path={routerPaths.onDemand.apex} component={OnDemandHubView} />
  </Switch>
);
