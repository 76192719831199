import { createLegacyAuthAxiosClientWithCaseTransform } from '@coa/api/lib/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { GetBillingPortalSession } from './types';

const generatePostBillingPortalSession = () => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = '/v1/billing_portal_sessions';

  const fn = async () => {
    const { data } = await client.post<GetBillingPortalSession.Response>(path);
    return data;
  };

  return { path, fn };
};

export const usePostBillingPortalSessionMutation = (
  options: UseMutationOptions<GetBillingPortalSession.Response> = {}
) => {
  const { path: queryKey, fn } = generatePostBillingPortalSession();
  return useMutation<GetBillingPortalSession.Response>(queryKey, fn, options);
};
