import { AdminSerializedInstructor } from '../../serializers/admin/AdminSerializedInstructor';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from './cms';

const instructorsCmsParams = {
  basePath: '/admin/instructors',
  auth: true,
};

export const useAdminIndexInstructorsQuery = generateUseIndexQuery<
  AdminSerializedInstructor,
  AdminCmsIndexFilterParams<AdminSerializedInstructor>
>(instructorsCmsParams);
export const useAdminShowInstructorQuery = generateUseShowQuery<AdminSerializedInstructor>(
  instructorsCmsParams
);
export const useAdminCreateInstructorMutation = generateUseCreateMutation<
  AdminSerializedInstructor
>(instructorsCmsParams);
export const useAdminUpdateInstructorMutation = generateUseUpdateMutation<
  AdminSerializedInstructor
>(instructorsCmsParams);
