import React from 'react';
import { Route, Switch } from 'react-router';
import { AuthNav } from './components/AuthNav';
import { AuthOtherMethodCta } from './components/AuthOtherMethodCta';
import LogInContainer from './containers/LogInContainer';
import RegistrationContainer from './containers/RegistrationContainer';
import { AuthLayout } from './layouts/AuthLayout';

export function AuthView(): JSX.Element {
  return (
    <AuthLayout>
      <AuthNav />
      <Switch>
        <Route path="/join" component={RegistrationContainer} />
        <Route path="/log-in" component={LogInContainer} />
      </Switch>
      <AuthOtherMethodCta />
    </AuthLayout>
  );
}
