import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { useSession } from '../../../../resources/sessions';
import { GetWorkshop, useGetWorkshopQuery, Workshop } from '../../../../resources/workshops';

export type WorkshopIdProps = {
  workshopId: Workshop['id'];
};

export const WorkshopArchivedModal = ({ workshopId }: WorkshopIdProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { role } = useSession();

  if (getWorkshopQuery.isLoading || role === 'admin') return null;
  const { data: workshop } = getWorkshopQuery.isLoading
    ? { data: {} as GetWorkshop.Response }
    : getWorkshopQuery;

  const { status } = workshop;

  /*
   * We only want to show this modal if the class has been archived.
   */
  const isOpen = status === 'archived';

  return (
    <>
      <Modal isOpen={isOpen} onClose={_.noop}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Oops, it looks like this class has been canceled.</Heading>
          </ModalHeader>
          <ModalBody>
            <Text>Explore our other classes.</Text>
          </ModalBody>
          <ModalFooter>
            <Button as={RouterLink} variant="primary" to="/classes">
              Browse Classes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
