import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCreateRegistrationMutation } from '@coa/api/controllers/v1/registrations';
import { useQueryParams } from '@coa/react-utils';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AccountResendVerifyEmailModal } from '../../../../components/AccountResendEmailVerify';
import { ArrowLeftIcon } from '../../../../components/Icons';
import { teamMembershipAnalytics } from '../../../../lib/analytics/clients/teamMembershipAnalytics';
import { marketingSiteBaseUrl } from '../../../../lib/www';
import { useCreateSession, useSession } from '../../../../resources/sessions';
import { PersonalInfoValidationSchema } from '../../utils/validation';
import { TeamMembershipViewLayout } from '../layouts/TeamMembershipViewLayout';
import { useTeamMembershipRegistrationContext } from '../TeamMembershipContextProvider';
import {
  TeamMembershipCouponCodePromo,
  TeamMembershipRegistrationFormHeader,
} from '../TeamMembershipStaticCopy';

type TeamMembershipRegistrationFormValues = {
  name: string;
  jobTitle: string;
  email: string;
  password: string;
};

const INITIAL_VALUES = {
  name: '',
  jobTitle: '',
  email: '',
  password: '',
};

const getUserExists = (errorMessage: string) =>
  Boolean((errorMessage || '').match(/Existing member/));

const PersonalInfoForm = ({ userExistsError }: { userExistsError: boolean }) => {
  const formikContext = useFormikContext<TeamMembershipRegistrationFormValues>();
  const { dirty, errors, isSubmitting } = formikContext;

  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');
  const { isVGP, couponCodeIsActive } = useTeamMembershipRegistrationContext();

  const backToJoinCoaHref = Boolean(campaign)
    ? `${marketingSiteBaseUrl}/${campaign?.toLowerCase()}`
    : marketingSiteBaseUrl;

  const renderBackButton = () => (
    <Link height="20px" href={backToJoinCoaHref} justifyItems="center">
      <Button variant="link" fontWeight="light" color="blackAlpha.800">
        <ArrowLeftIcon height="16px" />
        back to joincoa.com
      </Button>
    </Link>
  );

  useEffect(() => {
    teamMembershipAnalytics.track('View Team Membership - Personal Info Form');
  }, []);

  return (
    <Flex flexFlow="column" justify="space-between" height="100%">
      <Flex flexDir="column">
        <Flex flexDir="column" marginTop={10}>
          <Flex marginBottom={5}>{renderBackButton()}</Flex>
          <TeamMembershipRegistrationFormHeader membershipStep="personal" />
        </Flex>
        <Flex>
          <VStack width="full" alignItems="left">
            <Text fontSize="24px" fontWeight="semi-bold">
              Personal Information
            </Text>
            <FormControl isInvalid={errors.name && dirty}>
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <Field
                name="name"
                type="text"
                as={Input}
                variant="coa-main"
                placeholder="Your first and last name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.jobTitle && dirty}>
              <FormLabel htmlFor="title">Role / Title</FormLabel>
              <Field
                name="jobTitle"
                type="text"
                as={Input}
                variant="coa-main"
                placeholder="Name of Role / Title"
              />
              <FormErrorMessage>{errors.jobTitle}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={(userExistsError || errors.email) && dirty}>
              <FormLabel htmlFor="email">Work Email Address</FormLabel>
              <Field
                name="email"
                type="email"
                as={Input}
                variant="coa-main"
                placeholder="you@email.com"
              />
              <FormErrorMessage>
                {userExistsError ? (
                  <VStack>
                    <Text color="red.400">
                      <Text as="span" fontWeight="bold">
                        User Already Exists!
                      </Text>
                      <br />
                      If you would like to create a team for an existing member, please use the
                      correct password for the associated email
                    </Text>
                    <HStack mb={4}>
                      <Text fontWeight="bold" mb={1}>
                        Please make sure you've verified your email to set up a password before you
                        create your team.
                      </Text>
                      <AccountResendVerifyEmailModal />
                    </HStack>
                  </VStack>
                ) : (
                  errors.email
                )}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password && dirty}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Field
                name="password"
                type="password"
                as={Input}
                variant="coa-main"
                placeholder="A secure password"
              />
            </FormControl>
          </VStack>
        </Flex>
      </Flex>
      {isVGP && couponCodeIsActive ? (
        <Flex width="full" my={4}>
          <TeamMembershipCouponCodePromo couponCode="VGPLAUNCH25" />
        </Flex>
      ) : null}
      <Flex width="full" marginTop={4}>
        <Button
          variant="solid"
          colorScheme="red"
          width="full"
          type="submit"
          isLoading={isSubmitting}
          isDisabled={!dirty}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

const TeamMembershipRegistrationFormInner = () => {
  const [userExistsError, setUserExistsError] = useState(null);
  const createRegistrationMutation = useCreateRegistrationMutation();

  const { createSession } = useCreateSession();
  const { setTeamAdminMemberId, teamAdminMemberId } = useTeamMembershipRegistrationContext();
  const initialValues: TeamMembershipRegistrationFormValues = { ...INITIAL_VALUES };

  // because we're in redux land, we can't await the createSession to confirm
  // a user is logged in. Once we create the registration, we capture the createdMemberId
  // here, and then we use the useEffect below to wait until this new member becomes the logged-in user
  const { memberId } = useSession();
  const history = useHistory();

  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');

  useEffect(() => {
    if (memberId && memberId === teamAdminMemberId) {
      const pathBase = '/team-membership/create-team';
      const fullpath = Boolean(campaign) ? `${pathBase}?campaign=${campaign}` : pathBase;
      history.push(fullpath);
    }
  }, [memberId, teamAdminMemberId]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={PersonalInfoValidationSchema}
      onSubmit={async ({ name, email, jobTitle, password }) => {
        try {
          const createdRegistration = await createRegistrationMutation.mutateAsync({
            email,
            password,
            role: 'client',
            name,
            jobTitle,
            signupKind: 'corporate_self_serve',
          });
          if (userExistsError) {
            setUserExistsError(false);
          }
          setTeamAdminMemberId(createdRegistration.id);
          createSession({ email, password });
          teamMembershipAnalytics.track('Submitted Team Membership - Personal Info Form', {
            email,
            name,
            jobTitle,
          });
        } catch (err: unknown) {
          if (err instanceof Error) {
            if (getUserExists(err.message)) {
              setUserExistsError(true);
            }
          }
        }
      }}
    >
      <Flex as={Form} flexDir="column" height="full" width="full">
        <PersonalInfoForm userExistsError={userExistsError} />
      </Flex>
    </Formik>
  );
};

export const TeamMembershipRegistrationForm = () => (
  <TeamMembershipViewLayout>
    <TeamMembershipRegistrationFormInner />
  </TeamMembershipViewLayout>
);
