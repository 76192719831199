import { ExerciseRecord } from '@coa/api/models/exercise';
import { AnyObject } from '@coa/types';
import { FormProps } from '@rjsf/core';
import _ from 'lodash';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';

const terminalFormQuestionsSchema = {
  title: 'Form Questions',
  type: formSchemaEntryTypes.array,
  items: {
    type: formSchemaEntryTypes.object,
    properties: {
      id: {
        type: formSchemaEntryTypes.string,
      },
      kind: {
        type: formSchemaEntryTypes.string,
        anyOf: [
          {
            title: 'Checkbox',
            enum: ['checkbox'],
          },
          {
            title: 'Radio',
            enum: ['radio'],
          },
          {
            title: 'Instruction',
            enum: ['instruction'],
          },
          {
            title: 'Weekly Rep Recap',
            enum: ['weekly_rep_recap'],
          },
          {
            title: 'Long Text',
            enum: ['long_text'],
          },
        ],
      },
    },
    dependencies: {
      kind: {
        oneOf: [
          {
            properties: {
              kind: {
                enum: ['checkbox', 'radio'],
              },
              options: {
                title: 'Question Options',
                type: formSchemaEntryTypes.array,
                items: {
                  type: formSchemaEntryTypes.object,
                  properties: {
                    label: {
                      type: formSchemaEntryTypes.string,
                      title: 'Option Label',
                    },
                    subLabel: {
                      type: [formSchemaEntryTypes.string, formSchemaEntryTypes.null],
                      title: 'Option Secondary Label',
                    },
                  },
                  required: ['label'],
                },
              },
            },
            required: ['options'],
          },
          {
            properties: {
              kind: {
                enum: ['instruction', 'weekly_rep_recap'],
              },
              label: {
                type: [formSchemaEntryTypes.string, formSchemaEntryTypes.null],
                title: 'Label',
              },
            },
          },
          {
            properties: {
              kind: {
                enum: ['long_text'],
              },
              label: {
                type: [formSchemaEntryTypes.string, formSchemaEntryTypes.null],
                title: 'Label',
              },
              placeholder: {
                type: [formSchemaEntryTypes.string, formSchemaEntryTypes.null],
                title: 'Placeholder',
              },
            },
          },
        ],
      },
    },
  },
};

/*
 * Ultimately RJSF gets confused if provided a schema that is self-
 * referential, and the Exercise data-model is just that. By this
 * we mean that Follow-Up Questions have the same schema as the parent
 * top-level questions.
 *
 * In order to navigate this complexity, we clone the nested schema
 * and mutate the clone to refer to the original. Woof.
 *
 * I am... sorry. -Scotty 💣
 * https://media.giphy.com/media/9M5jK4GXmD5o1irGrF/giphy.gif
 */
const createFormQuestionsSchema = () => {
  const clonedFormQuestionsSchema = _.cloneDeep(terminalFormQuestionsSchema);
  const checkboxOrRadioOptionEntryProperties = clonedFormQuestionsSchema.items.dependencies.kind.oneOf.find(
    (oneOfEntry) => {
      // This applies to both checkbox AND radio, which makes this check
      // sorta tricky, so we sort to decrease fragility.
      const sortedEnum = [...oneOfEntry.properties.kind.enum].sort();
      return _.isEqual(sortedEnum, ['checkbox', 'radio']);
    }
  ).properties.options.items.properties;
  if (!checkboxOrRadioOptionEntryProperties) {
    throw Error("Can't find insertion point for follow-up questions schema.");
  }
  ((checkboxOrRadioOptionEntryProperties as unknown) as AnyObject).followupQuestions = {
    ...terminalFormQuestionsSchema,
    title: 'Follow-Up Questions',
    default: [],
  };
  return clonedFormQuestionsSchema;
};
const formQuestionsSchema = createFormQuestionsSchema();

export const formSchema: FormProps<ExerciseRecord>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: ['title', 'adminTitle'],
  properties: {
    title: {
      title: 'Title',
      type: formSchemaEntryTypes.string,
    },
    adminTitle: {
      title: 'Admin Title',
      type: formSchemaEntryTypes.string,
    },
    exerciseSteps: {
      title: 'Steps',
      type: formSchemaEntryTypes.array,
      items: {
        type: formSchemaEntryTypes.object,
        required: ['title', 'headline', 'formQuestions'],
        properties: {
          title: {
            title: 'Title',
            type: formSchemaEntryTypes.string,
          },
          headline: {
            title: 'Headline',
            type: formSchemaEntryTypes.string,
          },
          weeklyRep: {
            title: 'Weekly Rep?',
            type: formSchemaEntryTypes.boolean,
          },
          unlockImmediately: {
            title: 'Unlock Immediately?',
            type: formSchemaEntryTypes.boolean,
          },
          formQuestions: formQuestionsSchema,
        },
      },
    },
  },
};

export const formUiSchema = {
  title: {
    'ui:placeholder': 'Self-Awareness Sandwich',
  },
  adminTitle: {
    'ui:placeholder': 'Self-Awareness Sandwich (1-hour)',
  },
  exerciseSteps: {
    items: {
      formQuestions: {
        items: {
          id: {
            // Hide the ID field so the user cannot change it
            // but it is still passed if present.
            'ui:widget': 'hidden',
          },
        },
      },
    },
  },
};
