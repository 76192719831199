import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { joinTimeLabels } from '@coa/stdlib/string';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ClassCard } from '../../../../components/molecules/ClassCard';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import {
  createGetIntroWorkshopsPlaceholderData,
  formatSingleClassTimeLabel,
  useIntroWorkshopQuery,
} from '../../../../resources/workshops';
import { useClassesFiltersContext } from '../../components/ClassesFilters/ClassesFiltersContextProvider';

export const UpcomingIntrosCardView = () => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const numberOfResults = 4;
  const introsCardsPlaceholderData = createGetIntroWorkshopsPlaceholderData({
    length: numberOfResults,
  });

  const queryParams = getWorkshopFilter();
  const introsQuery = useIntroWorkshopQuery(queryParams, {
    placeholderData: introsCardsPlaceholderData,
  });
  const { data: intros } = introsQuery;

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const isLoading =
    introsQuery.isLoading || introsQuery.isPlaceholderData || getSubscriptionsQuery.isLoading;

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      columnGap={{ base: 4, md: 6, lg: 8 }}
      rowGap={{ base: 4, md: 6, lg: 8 }}
    >
      {intros.slice(0, numberOfResults).map((intro) => {
        const timingLabel = joinTimeLabels(
          formatSingleClassTimeLabel(intro.workshopOccurrences[0]),
          `${intro.workshopOccurrences[0].durationInMinutes} min`
        );
        return (
          <GridItem colSpan={{ base: 6, lg: 3 }} key={intro.id}>
            <Skeleton isLoaded={!isLoading} height="100%" borderRadius="lg" fadeDuration={0}>
              <TransformUpLink
                as={RouterLink}
                to={`/classes/${intro.id}`}
                height="100%"
                width="100%"
              >
                <ClassCard
                  marketListingLabel={intro.marketListingLabel}
                  title={intro.title}
                  timingLabel={timingLabel}
                  instructorName={intro.therapist.name}
                  src={intro.imageUrl}
                  showClassLabel={!hasActiveSubscription}
                  workshopAccess={intro.access}
                  kind={intro.kind}
                />
              </TransformUpLink>
            </Skeleton>
          </GridItem>
        );
      })}
    </Grid>
  );
};
