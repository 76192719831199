import { usePrevious } from '@coa/react-utils';
import { useFormikContext } from 'formik';
import _, { DebounceSettings, Dictionary } from 'lodash';
import { useCallback, useEffect } from 'react';

// TODO: This type is WAY too restrictive. As it turns out
// Formik can support pretty much any value schema we'd like,
// so we should generalize this to directly accept the Values
// type of the FormikContext in which it's placed.
export type FormikEffectValuesDiff = {
  values: Dictionary<string | string[]>;
  prevValues: Dictionary<string | string[]>;
  changedKey: string;
};

type OnValuesChange = ({ values, prevValues }: FormikEffectValuesDiff) => void;

type FormikEffectProps = {
  onValuesChange: OnValuesChange;
  wait?: number;
  debounceSettings?: DebounceSettings;
};

/*
 * "Traditionally", given a duration, a debounced fn will only
 * fire at the trailing end. However, to promote a more snappy
 * UX, we want to...
 *   - fire immediately
 *   - debounce during the wait
 *   - clear the debounce after the wait so that the next call
 *     will... (go back to the beginning)
 * These _.debounce settings allow for this type of interaction.
 */
const DEFAULT_DEBOUNCE_WAIT = 250;
const DEFAULT_DEBOUNCE_SETTINGS = { leading: true, trailing: true };

/*
 * Component used to manage Formik side effects. Stores
 * a reference to to previous values so that they may
 * be used in comparisons.
 */
export const FormikEffect = ({
  onValuesChange,
  wait = DEFAULT_DEBOUNCE_WAIT,
  debounceSettings = DEFAULT_DEBOUNCE_SETTINGS,
}: FormikEffectProps) => {
  const { values } = useFormikContext();
  const prevValues = usePrevious(values) || {};
  const changedKey = Object.keys(values).find((key) => !_.isEqual(values[key], prevValues[key]));

  /*
   * This hook fires on component unmount to trigger a save (specifically.
   * The mobile app will attempt saving when navigating between screens)
   */
  useEffect(
    () => () => {
      if (changedKey) {
        onValuesChange({ values, prevValues } as FormikEffectValuesDiff);
      }
    },
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeOnChange = useCallback(
    _.debounce(
      (args) => {
        onValuesChange(args);
      },
      wait,
      debounceSettings
    ),
    []
  );

  useEffect(() => {
    if (changedKey && values[changedKey] && !_.isEmpty(prevValues)) {
      executeOnChange({ values, prevValues, changedKey });
    }
    /*
     * We don't need to track prevValues here as it's
     * inherently tied to values.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};
