import {
  formatOneTimeEventDateTimeLabel,
  formatOneTimeEventTimeLabel,
  formatPossiblyRecurringEventTimeLabel,
} from '@coa/stdlib/string';
import { IsoTime } from '@coa/types';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import { AsAttributesObj } from '../../lib/json-api';
import { getIsInThePast } from '../../lib/time';
import { notRemovedEnrollmentStatuses } from '../enrollments';
import {
  LegacySnakeWorkshop,
  LegacySnakeWorkshopOccurrence,
  TryWorkshopType,
  Workshop,
  WorkshopKind,
  workshopKind,
  WorkshopMeta,
  WorkshopOccurrence,
} from './types';

/*
 *  Get Enrollment information on user
 */

export function getIsEnrollmentApprovedForFullSeries({
  enrollmentStatus,
  enrollmentDropInIds,
}: Pick<WorkshopMeta, 'enrollmentStatus' | 'enrollmentDropInIds'>) {
  return enrollmentStatus === 'approved' && !enrollmentDropInIds;
}

export function getIsEnrollmentApprovedForPartialSeries({
  enrollmentStatus,
  enrollmentDropInIds,
}: Pick<WorkshopMeta, 'enrollmentStatus' | 'enrollmentDropInIds'>) {
  return enrollmentStatus === 'approved' && enrollmentDropInIds;
}

export function getIsEnrolledAndNotRemovedFromFullSeries({
  enrollmentStatus,
  enrollmentDropInIds,
}: Pick<WorkshopMeta, 'enrollmentStatus' | 'enrollmentDropInIds'>) {
  return (
    enrollmentStatus &&
    notRemovedEnrollmentStatuses.includes(enrollmentStatus) &&
    !enrollmentDropInIds
  );
}

export function getIsEnrolledAndNotRemovedFromPartialSeries({
  enrollmentStatus,
  enrollmentDropInIds,
}: Pick<WorkshopMeta, 'enrollmentStatus' | 'enrollmentDropInIds'>) {
  return (
    enrollmentStatus &&
    notRemovedEnrollmentStatuses.includes(enrollmentStatus) &&
    enrollmentDropInIds
  );
}

/*
 * Workshop timing heuristics
 */

export function getWorkshopOccurrenceEndsAt({
  startsAt,
  durationInMinutes,
}: WorkshopOccurrence): IsoTime {
  return dayjs(startsAt).add(durationInMinutes, 'minute').format();
}

export function getWorkshopOccurrenceHasStarted({ startsAt }: WorkshopOccurrence) {
  return getIsInThePast(startsAt);
}

export function getWorkshopOccurrenceHasEnded(occ: WorkshopOccurrence) {
  const endsAt = getWorkshopOccurrenceEndsAt(occ);
  return getIsInThePast(endsAt);
}

export function getNextAvailableWorkshopOccurrence(workshop: Workshop) {
  return workshop.workshopOccurrences.find(
    ({ id, startsAt }) =>
      (!workshop.meta.enrollmentDropInIds || workshop.meta.enrollmentDropInIds[id]) &&
      !getIsInThePast(startsAt)
  );
}

export function getWorkshopHasStarted({ workshopOccurrences }: Workshop) {
  return getWorkshopOccurrenceHasStarted(_.first(workshopOccurrences));
}

export function getWorkshopHasEnded({ workshopOccurrences }: Workshop) {
  return getWorkshopOccurrenceHasEnded(_.last(workshopOccurrences));
}

type canYouEnrollInWorkshopParams = {
  // TODO: extend this type is we use this beyond try workshops
  workShopType?: TryWorkshopType;
  minutesBeforeEnd?: number;
};
export function getCanYouEnrollInWorkshop(
  workshop: Workshop,
  {
    // # of minutes you can enroll before workshop ends
    minutesBeforeEnd = 0, // 0 = enrollment possible while ongoing
    workShopType,
  }: canYouEnrollInWorkshopParams
) {
  // is TryWorkshop Loaded
  const workshopIsLoaded = Boolean(workshop?.id);
  if (!workshopIsLoaded) return false;

  const { workshopOccurrences } = workshop;
  const tryWorkshopOccurence = workshopOccurrences[0];

  // ensure workshopOccurence enrollment period is still ongoing
  const { durationInMinutes, startsAt } = tryWorkshopOccurence;
  const startsAtDate = new Date(startsAt);
  const durationOffset = (durationInMinutes - minutesBeforeEnd) * 60000;
  const enrollmentCutoff = new Date(startsAtDate.getTime() + durationOffset);
  const now = new Date();
  const enrollmentPeriodIsValid = now < enrollmentCutoff;
  // workshop is the correct kind for page
  // TODO: this will have to change to work for pushups (category)
  const workshopIsCorrectKind = workShopType ? workshop.kind === workShopType : true;

  return workshopIsCorrectKind && enrollmentPeriodIsValid;
}
/*
 *  Format Time Label
 */

export function formatWorkshopOccurrenceTimeLabel(occ: WorkshopOccurrence) {
  return formatOneTimeEventTimeLabel(occ.startsAt);
}

export function formatSingleClassTimeLabel(occ: WorkshopOccurrence) {
  return formatOneTimeEventDateTimeLabel(occ.startsAt);
}

export function formatWorkshopTimeLabel(
  workshop: Workshop,
  workshopOccurrences: WorkshopOccurrence[]
) {
  const startsAts = workshopOccurrences.map(({ startsAt }) => startsAt);
  return formatPossiblyRecurringEventTimeLabel(startsAts, workshop.seriesTimingLabel);
}

/*
 * We have both "series" and "advanced_series" Workshop kinds, though
 * we treat them both like series. Naming here isn't great, so we centralize
 * a check that deals with the jank.
 */
const seriesLikeWorkshopKinds: WorkshopKind[] = [workshopKind.series, workshopKind.advancedSeries];
export function getIsSeriesLike(kind: WorkshopKind) {
  return seriesLikeWorkshopKinds.includes(kind);
}

/*
 * Return whether or not a user is enrolled in a Drop-In for a Series
 */
export const getIsEnrolledInWorkshopDropIn = (
  workshopMeta: WorkshopMeta,
  dropInId: WorkshopOccurrence['id']
) =>
  workshopMeta.enrollmentStatus === 'approved' && _.has(workshopMeta.enrollmentDropInIds, dropInId);

/*
 *  Helpers to deprecate
 */
export function formatLegacySnakeJsonApiWorkshopOccurrenceTimeLabel(
  workshopOccurrence: AsAttributesObj<LegacySnakeWorkshopOccurrence>
) {
  return formatOneTimeEventTimeLabel(workshopOccurrence.attributes.starts_at);
}

export function formatLegacySnakeJsonApiWorkshopTimeLabel(
  workshop: AsAttributesObj<LegacySnakeWorkshop>,
  workshopOccurrences: AsAttributesObj<LegacySnakeWorkshopOccurrence>[]
) {
  const startsAts = workshopOccurrences.map(({ attributes }) => attributes.starts_at);
  return formatPossiblyRecurringEventTimeLabel(startsAts, workshop.attributes.series_timing_label);
}
