import { Avatar, Flex, Td } from '@chakra-ui/react';
import {
  useAdminCreateInstructorMutation,
  useAdminIndexInstructorsQuery,
  useAdminShowInstructorQuery,
  useAdminUpdateInstructorMutation,
} from '@coa/api/controllers/admin/instructors';
import { Instructor } from '@coa/api/models';
import { AdminSerializedInstructor } from '@coa/api/serializers/admin/AdminSerializedInstructor';
import { FormProps } from '@rjsf/core';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';
import { AdminCmsImageUploadWidget } from '../shared/AdminCmsImageUploadWidget';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { instructorCmsHelperText } from './instructorsCmsContent';
import { AdminInstructorFormData } from './types';

const formSchema: FormProps<Instructor>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: ['name', 'headshot'],
  properties: {
    name: {
      type: formSchemaEntryTypes.string,
      format: 'instructorName',
    },
    headshot: {
      type: formSchemaEntryTypes.string,
    },
  },
};

const formUiSchema = {
  name: {
    'ui:placeholder': 'Jennifer K. Doe',
  },
  headshot: {
    'ui:widget': AdminCmsImageUploadWidget,
  },
};

export const AdminInstructorsCmsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <CmsContextProvider<Instructor, AdminSerializedInstructor, AdminInstructorFormData>
    value={{
      baseRouterPath: '/admin-dash/cms/instructors',
      contentTypeTitle: 'Instructor',
      contentHelperText: instructorCmsHelperText,
      searchPlaceholder: 'Search instructor by name, eg "Dr. Sigmund Freud"',

      formSchema,
      formUiSchema,

      useShowQuery: useAdminShowInstructorQuery,
      useIndexQuery: useAdminIndexInstructorsQuery,
      useUpdateMutation: useAdminUpdateInstructorMutation,
      useCreateMutation: useAdminCreateInstructorMutation,

      getEntryTitle: ({ name }) => name,
      indexThEntries: [{ name: 'name', label: 'Name ' }],
      renderIndexEntryTdContents: function InstructorIndexEntryTd({
        name,
        headshot,
      }: AdminSerializedInstructor) {
        return (
          <Td>
            <Flex alignItems="center">
              <Avatar name={name} src={headshot} mr={4} size="sm" />
              <strong>{name}</strong>
            </Flex>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminInstructorsView = () => (
  <AdminInstructorsCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/instructors/new">
        <AdminCmsCreateView<Instructor, AdminSerializedInstructor, AdminInstructorFormData> />
      </Route>
      <Route path="/admin-dash/cms/instructors/:id">
        <AdminCmsShowView<Instructor, AdminSerializedInstructor, AdminInstructorFormData> />
      </Route>
      <Route path="/admin-dash/cms/instructors">
        <AdminCmsIndexView<Instructor, AdminSerializedInstructor, AdminInstructorFormData> />
      </Route>
    </Switch>
  </AdminInstructorsCmsContextProvider>
);
