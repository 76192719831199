import { Fade, VStack } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { useOnMount, useQueryParams } from '@coa/react-utils';
import React from 'react';
import { useAnalytics } from '../../lib/analytics/AnalyticsProvider';
import { useCreateRegistration } from '../../resources/registrations';
import { GenericSubscribeSuccessState } from '../classes/subscribe/components/GenericSubscribeModalSuccessState';
import { SubscribeForm } from '../classes/subscribe/components/SubscribeForm';
import { SubscribeModalOrderSummaryProvider } from '../classes/subscribe/components/SubscribeModalOrderSummaryProvider';
import { SubscribeModalStaticContent } from '../classes/subscribe/components/SubscribeModalStaticContent';
import { useRemoveGlobalPaddingTop } from '../shared/layout/components/GlobalLayoutProvider';
import {
  TryLayout,
  TryLayoutMainContent,
  TryLayoutSecondaryContent,
  TryLayoutSuccessParent,
} from '../try/components/TryWorkshopLayout';
import { TryLogoContainer } from '../try/components/TryWorkshopLogoContainer';

const CLASSPASS_TITLE = 'Start your Emotional Fitness Practice with 1 Free Month of Coa';
const GENERIC_TITLE = 'Start a 7-day free trial to register for your emotional pushup';

export function MembershipView() {
  useRemoveGlobalPaddingTop();
  const { loggedIn } = useCreateRegistration();
  const { safeAnalyticsClient } = useAnalytics();
  const queryParams = useQueryParams();
  const fromPushupsRegistration = queryParams.get('pushup');
  const b2bPartner = queryParams.get('b2bPartner');
  const isClassPass = Boolean(b2bPartner) && b2bPartner.toLowerCase() === 'classpass';

  useOnMount(() => {
    safeAnalyticsClient.track('Viewed Join Coa');
  });

  const getSubscriptionsQuery = useGetSubscriptionsQuery({ enabled: loggedIn });
  const hasSubscription = getHasActiveSubscriptionFromQueryResponse(getSubscriptionsQuery.data);
  const title = isClassPass ? CLASSPASS_TITLE : GENERIC_TITLE;
  const height = isClassPass ? 750 : undefined;
  return (
    <SubscribeModalOrderSummaryProvider>
      <TryLayout>
        <TryLayoutMainContent height={height}>
          <VStack alignItems="start">
            <TryLogoContainer
              display={{ base: 'block', foo: 'inline-block' }}
              fill="white"
              fillOpacity={0.6}
              // Offset to account for SVG bounding box
              ml="-9px"
              mb={4}
            />
            {fromPushupsRegistration ? (
              <SubscribeModalStaticContent title={title} />
            ) : (
              <SubscribeModalStaticContent title={title} />
            )}
          </VStack>
        </TryLayoutMainContent>
        <TryLayoutSecondaryContent height={height}>
          <SubscribeForm />
          <Fade in={hasSubscription} unmountOnExit>
            <TryLayoutSuccessParent>
              <GenericSubscribeSuccessState borderRadius="md" />
            </TryLayoutSuccessParent>
          </Fade>
        </TryLayoutSecondaryContent>
      </TryLayout>
    </SubscribeModalOrderSummaryProvider>
  );
}
