import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import screenfull from 'screenfull';

/*
 * Somewhat "famously", browsers on iOS devices don't support the full
 * screen API, instead forcing you to rely on native controls. Because
 * we're using custom controls, we need to be fully aware of this.
 * https://github.com/sindresorhus/screenfull#screenfull-1
 */
const getSupportsFullScreen = _.memoize(() => screenfull.isEnabled);

export const useFullScreen = () => {
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const fullScreenParentElementRef = useRef(null);
  const supportsFullScreen = getSupportsFullScreen();

  const handleFullScreenChange = () => {
    if (!supportsFullScreen) return;
    /*
     * We manage the fullscreen state using react so that we may
     * rely on stateful renders, etc.
     */
    setFullScreen(screenfull.isFullscreen);
  };

  useEffect(() => {
    if (!supportsFullScreen) return null;
    screenfull.on('change', handleFullScreenChange);
    return () => {
      if (!supportsFullScreen) return null;
      // Remove event listeners on unmount
      screenfull.off('change', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (!supportsFullScreen) return null;
    screenfull.toggle(fullScreenParentElementRef.current);
  }, []);

  return {
    toggleFullScreen,
    isFullScreen,
    fullScreenParentElementRef,
    supportsFullScreen,
  };
};
