import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as discountActions from '../../actions/admin/discounts';
import Loading from '../Loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';

const CreateDiscountForm = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={{ kind: 'relative', code: '', discount: 10 }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(discountActions.create(values))
              .then((result) => {
                if (result.errors) {
                  const errorMessages = [];
                  Object.keys(result.errors).forEach((k) =>
                    errorMessages.push([k, result.errors[k]].join(' '))
                  );
                  toast({
                    title: errorMessages.join(' and '),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: 'Discount successfully created!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                  onClose();
                  dispatch(discountActions.list());
                }
              })
              .catch((error) => alert(JSON.stringify(error)))
              .finally(() => setSubmitting(false));
          }}
        >
          {(formikProps) => (
            <Form>
              <ModalHeader>
                <Heading>Transfer Class</Heading>
              </ModalHeader>
              <ModalCloseButton borderRadius="50%" bg="green.50" />
              <ModalBody>
                <Field name="code">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel mt={3} htmlFor="code">
                        Code
                      </FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="evergreen.500"
                        type="text"
                        placeholder="e.g. 'class-25'"
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="discount">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel mt={3} htmlFor="discount">
                        Discount Percentage (between 0 and 100)
                      </FormLabel>
                      <InputGroup>
                        <Input
                          {...field}
                          focusBorderColor="evergreen.500"
                          type="number"
                          placeholder="e.g. '50' for a 50% discount"
                        />
                        <InputRightElement pointerEvents="none">%</InputRightElement>
                      </InputGroup>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button isLoading={formikProps.isSubmitting} type="submit" colorScheme="evergreen">
                  {formikProps.isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

CreateDiscountForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const AdminDiscountCodes = () => {
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const { gettingDiscounts, discounts } = useSelector((state) => state.admin.discountCodes);
  useEffect(() => {
    dispatch(discountActions.list());
  }, [dispatch]);

  return (
    <>
      <Box width="stretch" maxWidth="80%">
        <Flex my={6} alignItems="center" justify="space-between" width="stretch">
          <Heading fontSize="lg">Discount Codes</Heading>
          <Button colorScheme="evergreen" onClick={onCreateModalOpen}>
            Add Code
          </Button>
        </Flex>
        <Center>
          {gettingDiscounts ? (
            <Loading />
          ) : (
            <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
              <Table aria-label="Discount Codes">
                <TableHead>
                  <TableRow>
                    <TableHeader>Code</TableHeader>
                    <TableHeader>Kind</TableHeader>
                    <TableHeader>Amount Off</TableHeader>
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(discounts).map((discount) => (
                    <TableRow key={discount.id}>
                      <TableCell>
                        <Stack direction="row" alignItems="center">
                          <Heading fontSize="md">{discount.attributes.code}</Heading>
                        </Stack>
                      </TableCell>
                      <TableCell>{discount.attributes.kind}</TableCell>
                      <TableCell>
                        {discount.attributes.kind === 'relative'
                          ? `${discount.attributes.discount_ratio * 100}%`
                          : `$${discount.attributes.amount_cents / 100}`}
                      </TableCell>
                      <TableCell>
                        <Button
                          colorScheme="red"
                          onClick={() => dispatch(discountActions.destroy(discount.id))}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Center>
      </Box>
      <CreateDiscountForm isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
    </>
  );
};

export default AdminDiscountCodes;
