import { AnyObject } from '@coa/types';
import _ from 'lodash';

export function partitionObject(object: AnyObject, fn: (value: unknown, key: string) => boolean) {
  return _.reduce(
    object,
    (acc, value, key) => {
      const result = fn(value, key);
      return result ? [{ ...acc[0], [key]: value }, acc[1]] : [acc[0], { ...acc[1], [key]: value }];
    },
    [[], []]
  );
}
