import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Collapse,
  Container,
  Flex,
  FlexProps,
  Link,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useGetAccountQuery } from '@coa/api/controllers/v1/accounts';
import { FormikEffect } from '@coa/formik-utils';
import { useOnMount, useRouteParams } from '@coa/react-utils';
import { Form, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { TakeoverModalHeading } from '../../../components/compositions/Takeover';
import { ChevronRightIcon } from '../../../components/Icons';
import { ClassListing } from '../../../components/molecules/ClassListing';
import { classMarketplaceAnalytics } from '../../../lib/analytics/clients';
import {
  formatWorkshopOccurrenceTimeLabel,
  formatWorkshopTimeLabel,
  useGetWorkshopQuery,
  usePostWorkshopEnrollmentMutation,
  Workshop,
  WorkshopFormQuestion,
  WorkshopOccurrence,
} from '../../../resources/workshops';
import { getRouterUrl, RouterPathParams } from '../../../routerPaths';
import { NumberedFormikQuestion } from './components/FormQuestion';

const getFormFieldValidationSchema = ({ kind, options }: WorkshopFormQuestion) => {
  switch (kind) {
    case 'text':
    case 'short_text':
    case 'radio':
      return Yup.string().min(1).required('Please answer the question provided.');
    case 'checkbox':
      return Yup.array()
        .of(
          Yup.string()
            .oneOf(options.map(({ label }) => label))
            .required()
        )
        .required('You must select this option to continue.');
    default:
      throw Error('Unable to identify validation schema.');
  }
};

const createFormValidationSchema = (questions: Workshop['questions']) =>
  Yup.object().shape({
    ...questions.reduce(
      (acc, question) => ({
        ...acc,
        [question.key]: getFormFieldValidationSchema(question),
      }),
      {}
    ),
  });

const SubmitButton = (props: Omit<ButtonProps, 'type' | 'onClick'>) => {
  const toast = useToast();
  const { isValid } = useFormikContext();

  return (
    <Button
      type={isValid ? 'submit' : 'button'}
      onClick={
        isValid
          ? undefined
          : () => {
              toast({
                title: `Please complete all of the provided fields`,
                duration: 5000,
                isClosable: true,
                status: 'error',
              });
            }
      }
      {...props}
    />
  );
};

const useSubmit = ({
  workshopId,
  dropInWorkshopOccurrenceId,
}: {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
}) => {
  // TODO: Routing conditions
  const postWorkshopEnrollmentMutation = usePostWorkshopEnrollmentMutation({ id: workshopId });
  const getAccountQuery = useGetAccountQuery();
  const toast = useToast();
  const history = useHistory();
  const handleSubmit = useCallback(async (answers) => {
    try {
      const body = {
        answers,
        ...(dropInWorkshopOccurrenceId ? { dropInWorkshopOccurrenceId } : {}),
      };
      await postWorkshopEnrollmentMutation.mutateAsync(body);
      /*
       * In theory we shouldn't need to infer the status of the application based
       * on the `completedProfile` attribute and instead should be able to look
       * at the enrollment directly. HOWEVER, we're currently not able to invalidate
       * the query corresponding to the workshop after an enrollment updates.
       *
       * As a temporary measure, we simply check the `completedProfile` as a heuristic
       * instead of application status. This is "annoying but ultimately not that
       * dangerous" since it's what the backend does anyway.
       */
      const destinationRouterUrl = getAccountQuery.data?.completedProfile
        ? getRouterUrl.classes.classJoinSuccess({ id: workshopId })
        : getRouterUrl.classes.classJoinCompleteProfile({ id: workshopId });
      history.push(destinationRouterUrl);
    } catch (err) {
      toast({
        title: 'Something went wrong.',
        description: 'Please try again',
        duration: 5000,
        isClosable: true,
        status: 'error',
      });
    }
  }, []);

  return {
    postWorkshopEnrollmentMutation,
    handleSubmit,
  };
};

const JoinWorkshopForm = ({
  workshopId,
  dropInWorkshopOccurrenceId,
}: {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
}) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { postWorkshopEnrollmentMutation, handleSubmit } = useSubmit({
    workshopId,
    dropInWorkshopOccurrenceId,
  });

  const validationSchema = useMemo(() => {
    if (getWorkshopQuery.isLoading) return null;
    return createFormValidationSchema(getWorkshopQuery.data.questions);
  }, [getWorkshopQuery.status]);

  // TODO: loading state
  if (getWorkshopQuery.isLoading) return null;
  const { questions } = getWorkshopQuery.data;

  // TODO: Need a back breadcrumb.
  return (
    <Formik
      initialValues={{}}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <>
        <FormikEffect
          onValuesChange={({ values, changedKey }) => {
            classMarketplaceAnalytics.track('Answered Application Question', {
              classId: workshopId,
              question: changedKey,
              answer: values[changedKey],
              ..._.pickBy({ dropInId: dropInWorkshopOccurrenceId }),
            });
          }}
        />
        <VStack as={Form} spacing={6} alignItems="flex-end">
          {questions.map(({ key, ...questionProps }, index) => (
            <NumberedFormikQuestion index={index} key={key} name={key} {...questionProps} />
          ))}
          <SubmitButton
            variant="primary"
            size="lg"
            isLoading={postWorkshopEnrollmentMutation.isLoading}
            isDisabled={postWorkshopEnrollmentMutation.isLoading}
            data-cy="join-workshop-submit"
          >
            Save My Spot
          </SubmitButton>
        </VStack>
      </>
    </Formik>
  );
};

const WorkshopCard = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
} & BoxProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  // TODO: Loading State
  if (getWorkshopQuery.isLoading) return null;
  const { data: workshop } = getWorkshopQuery;
  const dropIn = workshop.workshopOccurrences.find(({ id }) => id === dropInWorkshopOccurrenceId);
  return (
    <ClassListing
      label={workshop.marketListingLabel}
      title={dropInWorkshopOccurrenceId ? dropIn.title : workshop.title}
      date={
        dropInWorkshopOccurrenceId
          ? formatWorkshopOccurrenceTimeLabel(dropIn)
          : formatWorkshopTimeLabel(workshop, workshop.workshopOccurrences)
      }
      instructorName={workshop.therapist.name}
      instructorImage={workshop.therapist.imageUrl}
      colorScheme="gray"
      src={workshop.imageUrl}
      {...rest}
    />
  );
};

const CollapsibleExplainer = (props: FlexProps) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Flex {...props}>
      <Box w={6}>
        <ChevronRightIcon
          sx={{
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0)',
            transition: '0.2s ease transform',
          }}
        />
      </Box>
      <Box w="100%" flexBasis={0} flexGrow={999}>
        <Text>
          <Link onClick={onToggle} variant="coa-main">
            Why do I have to fill this out?
          </Link>
        </Text>
        <Collapse in={isOpen}>
          <Text fontSize="sm" m={0} pt={4}>
            We want to make sure this class is the right place for you to get the support you're
            looking for. This information helps us create the best possible experience for you and
            fellow class participants.
          </Text>
        </Collapse>
      </Box>
    </Flex>
  );
};

export const JoinWorkshopView = () => {
  const { id: workshopId, dropInWorkshopOccurrenceId } = useRouteParams<
    RouterPathParams.Classes['DropInJoin']
  >();
  useOnMount(() => {
    const params = { classId: workshopId, ..._.pickBy({ dropInId: dropInWorkshopOccurrenceId }) };
    classMarketplaceAnalytics.track('Viewed Application', params);
  });
  return (
    <VStack
      spacing={8}
      as={Container}
      maxW="container.sm"
      w="100%"
      py={{ sm: 8, md: 24 }}
      alignItems="stretch"
    >
      <TakeoverModalHeading>Save your spot in class</TakeoverModalHeading>
      <WorkshopCard
        workshopId={workshopId}
        dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
      />
      <CollapsibleExplainer />
      <JoinWorkshopForm
        workshopId={workshopId}
        dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
      />
    </VStack>
  );
};
