import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { PaginationControls } from '../../../components/molecules/PaginationControls';
import { CoaAppWindow } from '../../../lib/web';
import { copyToClipboard } from '../../../utils/Clipboard';
import { useAdminClassesFiltersContext } from './components/AdminClassesFiltersContextProvider';
import { DateRange, OrderRadioGroup } from './components/FilterComponents';

export const AdminClassesFilters = ({
  maxPages,
  isLoading,
}: {
  maxPages: number;
  isLoading: boolean;
}) => {
  const history = useHistory();
  const toast = useToast();
  const handleCopyUrlToClipboard = async () => {
    const { BASE_URL } = (window as unknown) as CoaAppWindow;
    const { location } = history;

    const url = `${BASE_URL}${location.pathname}${location.search}`;
    await copyToClipboard(url);
    toast({
      title: 'Copied to Clipboard!',
      description: url || 'YOU COPIED NOTHING!',
      status: url ? 'success' : 'error',
      duration: 1200,
      isClosable: true,
    });
  };
  const {
    filters,
    hasActiveFilters,
    toggleAccordion,
    clearFilters,
  } = useAdminClassesFiltersContext();
  return (
    <Accordion
      index={filters.accordion ? 0 : -1}
      width="full"
      variant="ghost"
      _focus={{
        outline: 'none',
      }}
    >
      <AccordionItem>
        <h2>
          <AccordionButton
            onClick={toggleAccordion}
            _focus={{
              outline: 'none',
            }}
            _expanded={{ backgroundColor: 'gray.50' }}
          >
            <Box flex="1" textAlign="left">
              <Heading fontSize="md">Filter:</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel borderTop="1px solid" borderColor="gray.200">
          <Flex width="full" justify="space-between" alignItems="center">
            <DateRange />
            <OrderRadioGroup />
            <PaginationControls maxPages={maxPages} isLoading={isLoading} />
            <VStack w="100px">
              <Button width="full" colorScheme="green" size="sm" onClick={handleCopyUrlToClipboard}>
                Copy URL
              </Button>
              <Button width="full" size="sm" onClick={clearFilters} disabled={!hasActiveFilters}>
                Clear Filters
              </Button>
            </VStack>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
