import { Flex, Td } from '@chakra-ui/react';
import {
  useAdminCreateExerciseMutation,
  useAdminIndexExercisesQuery,
  useAdminShowExerciseQuery,
  useAdminUpdateExerciseMutation,
} from '@coa/api/controllers/admin/exercises';
import { ExerciseRecord } from '@coa/api/models/exercise';
import { AdminSerializedExercise } from '@coa/api/serializers/admin/AdminSerializedExercise';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextProvider } from '../shared/useAdminCmsContext';
import { exerciseCmsHelperText } from './exercisesCmsContent';
import { formSchema, formUiSchema } from './formSchema';
import { AdminCmsExerciseFormData } from './types';
import { formatSerializedExerciseToCmsFormData } from './util/formatSerializedExerciseToCmsFormData';
import { parseCmsFormDataToSerializedExercise } from './util/parseCmsFormDataToSerializedExercise';

export const AdminExerciseCmsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <CmsContextProvider<ExerciseRecord, AdminSerializedExercise, AdminCmsExerciseFormData>
    value={{
      baseRouterPath: '/admin-dash/cms/exercises',
      contentTypeTitle: 'Exercise',
      contentHelperText: exerciseCmsHelperText,
      searchPlaceholder: 'Search exercises by title, eg "Leadership"',

      formSchema,
      formUiSchema,

      formatSerializedResourceToCmsFormData: formatSerializedExerciseToCmsFormData,
      parseCmsFormDataToSerializedResource: parseCmsFormDataToSerializedExercise,

      useShowQuery: useAdminShowExerciseQuery,
      useIndexQuery: useAdminIndexExercisesQuery,
      useUpdateMutation: useAdminUpdateExerciseMutation,
      useCreateMutation: useAdminCreateExerciseMutation,

      getEntryTitle: ({ title }) => title,
      indexThEntries: [{ name: 'title', label: 'Title ' }],
      renderIndexEntryTdContents: function ExerciseIndexEntryTd({
        adminTitle,
      }: AdminSerializedExercise) {
        return (
          <Td>
            <Flex alignItems="center">
              <strong>{adminTitle}</strong>
            </Flex>
          </Td>
        );
      },
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminExercisesView = () => (
  <AdminExerciseCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/exercises/new">
        <AdminCmsCreateView<ExerciseRecord, AdminSerializedExercise, AdminCmsExerciseFormData> />
      </Route>
      <Route path="/admin-dash/cms/exercises/:id">
        <AdminCmsShowView<ExerciseRecord, AdminSerializedExercise, AdminCmsExerciseFormData> />
      </Route>
      <Route path="/admin-dash/cms/exercises">
        <AdminCmsIndexView<ExerciseRecord, AdminSerializedExercise, AdminCmsExerciseFormData> />
      </Route>
    </Switch>
  </AdminExerciseCmsContextProvider>
);
