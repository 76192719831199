import { Box, Button, ButtonGroup, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { getIndefiniteArticle } from '@coa/stdlib/string';
import { AnyObject, UnpackArray } from '@coa/types';
import Form from '@rjsf/chakra-ui';
import { ArrayFieldTemplateProps, FieldProps, FormProps } from '@rjsf/core';
import React from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusSquareIcon,
  TrashIcon,
} from '../../../../components/Icons';

// Consolidate the schema entry types so that they
// don't need to be cast every time.
export const formSchemaEntryTypes = {
  array: 'array' as const,
  object: 'object' as const,
  string: 'string' as const,
  number: 'number' as const,
  boolean: 'boolean' as const,
  null: 'null' as const,
};

const REQUIRED_FIELD_SYMBOL = '*';

/*
 * Custom Array Item UI that provides some improved UX. Namely:
 * - Adding borders that help differentiate between entries
 *   vertically.
 * - Stacks the moveUp / moveDown / remove buttons vertically
 *   to provide more horizontal space for array item content.
 *
 * This is very tightly coupled to and based on the Rsjf
 * ArrayItem component in source.
 * @see https://github.com/rjsf-team/react-jsonschema-form/blob/8f9318ff8cc878275719e910e9c99be6af08d90f/packages/antd/src/templates/ArrayFieldTemplate/ArrayFieldTemplateItem.js#L18
 */
const CustomRjsfArrayFieldTemplateItem = ({
  children,
  className,
  disabled,
  hasToolbar,
  hasMoveUp,
  hasMoveDown,
  hasRemove,
  index,
  key,
  onDropIndexClick,
  onReorderClick,
  readonly,
}: UnpackArray<ArrayFieldTemplateProps['items']>) => (
  <Flex key={key} className={className} mt={4}>
    <Box
      width="100%"
      borderTopLeftRadius="base"
      borderBottomLeftRadius="base"
      borderColor="gray.50"
      borderWidth={2}
      p={4}
    >
      {children}
    </Box>

    {hasToolbar && (
      <Box p={4} bg="gray.50" borderTopRightRadius="base" borderBottomRightRadius="base">
        <ButtonGroup>
          {hasMoveUp || hasMoveDown ? (
            <IconButton
              icon={<ArrowUpIcon />}
              aria-label="Move up"
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onReorderClick(index, index - 1)}
            />
          ) : null}

          {hasMoveUp || hasMoveDown ? (
            <IconButton
              icon={<ArrowDownIcon />}
              aria-label="Move down"
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onReorderClick(index, index + 1)}
            />
          ) : null}

          {hasRemove ? (
            <IconButton
              type="button"
              icon={<TrashIcon />}
              aria-label="Remove"
              className="array-item-remove"
              disabled={disabled || readonly}
              onClick={onDropIndexClick(index)}
            />
          ) : null}
        </ButtonGroup>
      </Box>
    )}
  </Flex>
);

/*
 * Custom add entry button that allows us to customize the
 * text for better admin UX.
 */
const CustomRjsfAddButton = ({
  className,
  title = 'Item',
  onClick,
  disabled,
}: Pick<ArrayFieldTemplateProps, 'className' | 'disabled' | 'title'> & {
  onClick: ArrayFieldTemplateProps['onAddClick'];
}) => (
  <Button
    className={className}
    isDisabled={disabled}
    onClick={onClick}
    variant="secondary"
    leftIcon={<PlusSquareIcon />}
  >
    Add {getIndefiniteArticle(title)} {title.replace(/s$/, '')}
  </Button>
);

/*
 * Custom Array Field component that allows us to provide:
 * - a better empty state
 * - custom add button for a more straightforward UX
 *
 * This is very tightly coupled to and based on the Rsjf
 * ArrayFieldTemplate component in source.
 * @see https://github.com/rjsf-team/react-jsonschema-form/blob/8f9318ff8cc878275719e910e9c99be6af08d90f/packages/antd/src/templates/ArrayFieldTemplate/index.js#L18
 */
const CustomArrayFieldTemplate = ({
  canAdd,
  className,
  disabled,
  items,
  idSchema,
  onAddClick,
  readonly,
  required,
  title,
  TitleField,
}: ArrayFieldTemplateProps) => (
  <fieldset className={className} id={idSchema.$id}>
    <TitleField title={title} id={`${idSchema.$id}__title`} required={required} />
    {items.length ? (
      <>
        <>{items.map(CustomRjsfArrayFieldTemplateItem)}</>
        {canAdd && (
          <Flex mt={4} pl={4}>
            <CustomRjsfAddButton
              title={title}
              className="array-item-add"
              onClick={onAddClick}
              disabled={disabled || readonly}
            />
          </Flex>
        )}
      </>
    ) : (
      <Box px={4} py={4} borderWidth={2} borderColor="gray.50" mt={4} borderRadius="base">
        <Text size="md" mb={4}>
          There are no {title.toLowerCase()} yet.
        </Text>
        <CustomRjsfAddButton
          onClick={onAddClick}
          title={title}
          disabled={disabled}
          className="array-item-add"
        />
      </Box>
    )}
  </fieldset>
);

/*
 * Custom title field - particularly used for form sections (i.e. Arrays)
 * - because the rjsf default is quite large and I was struggling to
 * customize it.
 *
 * There's almost definitely a better way to do this, so feel free to
 * rip out and refactor.
 */
const CustomRsjfSectionTitleField = (props: FieldProps) => {
  const { id, title, required } = props;
  return (
    <legend id={id}>
      <Heading fontSize="2xl">
        {title}
        {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
      </Heading>
    </legend>
  );
};

const customFields = {
  TitleField: CustomRsjfSectionTitleField,
};

export const AdminCmsForm = <CmsFormData extends AnyObject>(props: FormProps<CmsFormData>) => (
  <Form
    // We omit extra data to filter out properties that are included
    // in a serialized response but will not be featured in a resource
    // form schema. (i.e. createdAt)
    omitExtraData
    liveOmit
    // Note that certain fields are configured via this `fields` prop
    // while others have discrete props. :shrug:
    fields={customFields}
    ArrayFieldTemplate={CustomArrayFieldTemplate}
    {...props}
  >
    <Button variant="primary" type="submit">
      Submit
    </Button>
  </Form>
);
