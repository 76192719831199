export const formatPriceDollars = (priceDollars: number) =>
  priceDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
export const formatPriceCents = (priceCents: number) => formatPriceDollars(priceCents / 100);

/*
 * Shorthand price formatting that removes ".00" from "$5.00"
 * for readability.
 */
export const formatPriceCentsShort = (priceCents: number) =>
  formatPriceCents(priceCents).replace(/\.00$/, '');
export const formatPriceDollarsShort = (priceDollars: number) =>
  formatPriceDollars(priceDollars).replace(/\.00$/, '');
