export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';

export function show() {
  return {
    type: ACCOUNT_REQUEST,
    api: {
      endpoint: `account`,
      authenticated: true,
      types: [ACCOUNT_REQUEST, ACCOUNT_SUCCESS, ACCOUNT_FAILURE],
      method: 'GET',
    },
  };
}

export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export function update(data) {
  return {
    type: ACCOUNT_UPDATE_REQUEST,
    api: {
      data,
      endpoint: `account`,
      authenticated: true,
      types: [ACCOUNT_UPDATE_REQUEST, ACCOUNT_UPDATE_SUCCESS, ACCOUNT_UPDATE_FAILURE],
      method: 'PUT',
    },
  };
}
