import { AdminSerializedExercise } from '@coa/api/serializers/admin/AdminSerializedExercise';
import { clientCase } from '@coa/stdlib/string';
import { UnpackArray } from '@coa/types';
import { AdminCmsExerciseFormData, AdminCmsExerciseStepQuestionOptionFormData } from '../types';

const getRandomQuestionId = ({ kind }: { kind: string }) =>
  `${kind}_${String(Math.random() * 1000000000).split('.')[0]}`;

/*
 * Pull out all follow-up questions defined inside the option
 * so that they may be hoisted into the parent step's formQuestions
 * block.
 */
const getFormQuestionsFromOption = (option: AdminCmsExerciseStepQuestionOptionFormData) => {
  const { followupQuestions, ...optionRest } = option;

  return option.followupQuestions.reduce(
    (optionAcc, { id: followupQuestionId, ...followupQuestionRest }) => ({
      option: {
        ...optionAcc.option,
        followupQuestions: [...optionAcc.option.followupQuestions, followupQuestionId],
      },
      formQuestions: {
        ...optionAcc.formQuestions,
        [clientCase(followupQuestionId)]: { ...followupQuestionRest },
      },
    }),
    {
      option: { ...optionRest, followupQuestions: [] },
      formQuestions: {},
    }
  );
};

const parseCmsFormDataOptions = (options = []) =>
  options.reduce(
    (acc, option) => {
      if (!option.followupQuestions) {
        return {
          options: [...acc.options, option],
          formQuestions: { ...acc.formQuestions },
        };
      }
      const reduceResult = getFormQuestionsFromOption(option);
      return {
        options: [...acc.options, reduceResult.option],
        formQuestions: { ...acc.formQuestions, ...reduceResult.formQuestions },
      };
    },
    {
      options: [],
      formQuestions: {},
    }
  );
export const parseCmsFormDataToSerializedExerciseStep = (
  exerciseStepFormData: UnpackArray<AdminCmsExerciseFormData['exerciseSteps']>,
  index: number
) => {
  const { formQuestions, ...rest } = exerciseStepFormData;
  return {
    ...rest,
    /*
     * Manually add sequence via the array order so that the user
     * doesn't need to type it in manually.
     */
    sequence: index,
    ...formQuestions.reduce(
      (acc, { id: rawId, options: rawOptions, ...questionRest }) => {
        const id = rawId || getRandomQuestionId(questionRest);
        const { formQuestions: followupQuestions, options } = parseCmsFormDataOptions(rawOptions);
        return {
          formQuestions: {
            ...acc.formQuestions,
            ...followupQuestions,
            [clientCase(id)]: { ...questionRest, ...(options.length > 0 ? { options } : {}) },
          },
          formQuestionSequence: [...acc.formQuestionSequence, id],
        };
      },
      {
        formQuestions: {},
        formQuestionSequence: [],
      }
    ),
  };
};

export const parseCmsFormDataToSerializedExercise = (
  cmsFormData: AdminCmsExerciseFormData
): AdminSerializedExercise => {
  const { exerciseSteps, ...rest } = cmsFormData;
  return {
    ...rest,
    exerciseSteps: exerciseSteps.map(parseCmsFormDataToSerializedExerciseStep),
  };
};
