import {
  CLIENT_PAYMENT_METHODS_DESTROY_FAILURE,
  CLIENT_PAYMENT_METHODS_DESTROY_REQUEST,
  CLIENT_PAYMENT_METHODS_DESTROY_SUCCESS,
  CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_FAILURE,
  CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST,
  CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_SUCCESS,
} from '../actions/clientPaymentMethods';
import {
  CLIENT_PAYMENT_PROFILES_SHOW_FAILURE,
  CLIENT_PAYMENT_PROFILES_SHOW_REQUEST,
  CLIENT_PAYMENT_PROFILES_SHOW_SUCCESS,
  CLIENT_PAYMENT_PROFILES_UPDATE_FAILURE,
  CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST,
  CLIENT_PAYMENT_PROFILES_UPDATE_SUCCESS,
} from '../actions/clientPaymentProfiles';

const DEFAULT_STATE = {
  loading: true,
  paymentProfile: null,
  errors: {},
};

export function clientPaymentProfiles(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CLIENT_PAYMENT_PROFILES_UPDATE_REQUEST:
    case CLIENT_PAYMENT_METHODS_DESTROY_REQUEST:
    case CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_REQUEST:
    case CLIENT_PAYMENT_PROFILES_SHOW_REQUEST: {
      return { ...state, errors: {}, loading: true };
    }
    case CLIENT_PAYMENT_PROFILES_UPDATE_SUCCESS:
    case CLIENT_PAYMENT_METHODS_DESTROY_SUCCESS:
    case CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_SUCCESS:
    case CLIENT_PAYMENT_PROFILES_SHOW_SUCCESS: {
      let paymentProfile = null;
      if (action.response.data) {
        const {
          response: {
            data: { id, attributes },
          },
        } = action;
        paymentProfile = { id, ...attributes };
      }
      return { ...state, paymentProfile, loading: false };
    }
    case CLIENT_PAYMENT_PROFILES_UPDATE_FAILURE:
    case CLIENT_PAYMENT_METHODS_DESTROY_FAILURE:
    case CLIENT_PAYMENT_METHODS_SET_AS_DEFAULT_FAILURE:
    case CLIENT_PAYMENT_PROFILES_SHOW_FAILURE: {
      const { errors } = action;
      return { ...state, errors, loading: false };
    }
    default:
      return state;
  }
}
