import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { create } from '../../../actions/registrations/create';

function Registration({ dispatch, name, role, email, password, loading }) {
  return (
    <VStack>
      <Box width="stretch">
        <Formik
          initialValues={{
            name,
            email,
            password,
            role,
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            // signupKind is a saved field for the corresponding Hubspot Contact
            dispatch(create({ signup_kind: 'consumer', ...values })).then((result) => {
              setSubmitting(false);
              if (result.errors) setErrors(result.errors);
            });
          }}
        >
          {() => (
            <Form>
              <Field name="name">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.name && form.touched.name}>
                    <FormLabel mt={3} htmlFor="name">
                      Full Name
                    </FormLabel>
                    <Input
                      {...field}
                      variant="coa-main"
                      type="text"
                      id="name"
                      data-cy="input-name"
                      placeholder="Your first and last name"
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel mt={3} htmlFor="email">
                      Email
                    </FormLabel>
                    <Input
                      {...field}
                      type="email"
                      id="email"
                      variant="coa-main"
                      placeholder="you@email.com"
                      data-cy="input-email"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel mt={3} htmlFor="password">
                      Password
                    </FormLabel>
                    <Input
                      {...field}
                      type="password"
                      id="password"
                      variant="coa-main"
                      placeholder="A secure password"
                      data-cy="input-password"
                    />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                type="submit"
                variant="primary"
                width="full"
                mt={8}
                my={4}
                disabled={loading}
                data-cy="submit"
              >
                {loading ? 'Joining...' : 'Join Coa'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
}

Registration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  redirectPath: PropTypes.string,
};

export default Registration;
