import { configureCoaAxiosAuth } from '@coa/api/lib/axios';
import { getAuthParamsFromLocalStorage, setAuthParamsToLocalStorage } from '../localStorage';

export const configureCoaAxiosAuthForWeb = () => {
  configureCoaAxiosAuth({
    getAuthParams: getAuthParamsFromLocalStorage,
    setAuthParams: setAuthParamsToLocalStorage,
    encodeStringifiedAuthParams: window.btoa.bind(window),
    // Here we set the Base Api Url as an empty string for web since
    // requests are made relative to the base url of the app
    getBaseApiUrl: () => '',
  });
};
