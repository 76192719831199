import { Link, Stack, Text } from '@chakra-ui/react';
import { useQueryParams } from '@coa/react-utils';
import React from 'react';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

function appendRedirectPath(path: string, queryParams: URLSearchParams): string {
  const redirectTo = queryParams.get('redirect_to');
  return redirectTo ? `${path}?redirect_to=${redirectTo}` : path;
}

export function AuthOtherMethodCta(): JSX.Element {
  const queryParams = useQueryParams();
  return (
    <Stack align="center">
      <Route path="/log-in">
        <Text>Don't have an account?</Text>
        <Link
          color="green.900"
          as={RouterLink}
          to={appendRedirectPath('/join', queryParams)}
          data-cy="registration-link"
        >
          Join Coa
        </Link>
      </Route>
      <Route path="/join">
        <Text>Already have an account?</Text>
        <Link
          color="green.900"
          as={RouterLink}
          to={appendRedirectPath('/log-in', queryParams)}
          data-cy="log-in-link"
        >
          Sign In
        </Link>
      </Route>
    </Stack>
  );
}
