import React, { createContext, useContext, useEffect } from 'react';

type GlobalStyleConfig = {
  appBlur: boolean;
};

const defaultGlobalStyleConfig = {
  appBlur: false,
};

const GlobalStylesContext = createContext(null);
export const GlobalStylesProvider = ({ children }: { children: React.ReactNode }) => {
  const [globalStyleConfig, setGlobalStyleConfig] = React.useState<GlobalStyleConfig>(
    defaultGlobalStyleConfig
  );

  return (
    <GlobalStylesContext.Provider value={{ globalStyleConfig, setGlobalStyleConfig }}>
      {children}
    </GlobalStylesContext.Provider>
  );
};

export const useGlobalStylesConfig = () => useContext(GlobalStylesContext);

export const useAppBlur = () => {
  const { setGlobalStyleConfig } = useGlobalStylesConfig();
  useEffect(() => {
    setGlobalStyleConfig({ appBlur: true });
    return () => {
      setGlobalStyleConfig({ appBlur: false });
    };
  }, []);
};
