import { ValueOf } from '@coa/types';
import { PollRecord } from '../poll';
import { Question, Questions } from './questions';
import { WorkoutSheet } from './sheets';

export type MaybeRichText = string; // Not actually string but we get it

const formWorkoutScreenKinds = {
  baseFormScreen: 'base_form_screen',
} as const;

export const nonFormWorkoutScreenKinds = {
  instructorCover: 'instructor_cover',
  fullScreenVideo: 'full_screen_video',
  restingHeartRate: 'resting_heart_rate',
  richText: 'rich_text',
  baseFormScreen: 'base_form_screen',
  pollEntry: 'poll_entry',
  pollResults: 'poll_results',
  completeRating: 'complete_rating',
  copyPrompt: 'copy_prompt',
} as const;

export const workoutScreenKinds = {
  ...formWorkoutScreenKinds,
  ...nonFormWorkoutScreenKinds,
} as const;

export type WorkoutScreenKind = ValueOf<typeof workoutScreenKinds>;
export type FormWorkoutScreenKind = Extract<WorkoutScreenKind, 'base_form_screen'>;
export type NonFormWorkoutScreenKind = Exclude<WorkoutScreenKind, 'base_form_screen'>;

export type InstructorCoverScreenParams = {
  backgroundMediaImageUrl: string;
};
export type FullScreenVideoScreenParams = {
  videoUrl: string;
};
export type RestingHeartRateScreenParams = {
  content: MaybeRichText;
};
export type RichTextScreenParams = {
  richTextContent: MaybeRichText;
};
export type PollEntryScreenParams = {
  pollId: PollRecord['id'];
};
export type PollResultsScreenParams = {
  pollId: PollRecord['id'];
};

export type BaseFormScreenParams = {
  questionSequence: Array<Question['id']>;
  questions: Questions;
};
export type CompleteRatingScreenParams = {
  SOMETHING_NEVER_EXISTING?: never;
};

export type CopyPromptScreenParams = {
  label: string;
  textToCopy: string;
};

export type WorkoutScreenKindToWorkoutScreenParams = {
  [workoutScreenKinds.instructorCover]: InstructorCoverScreenParams;
  [workoutScreenKinds.fullScreenVideo]: FullScreenVideoScreenParams;
  [workoutScreenKinds.restingHeartRate]: RestingHeartRateScreenParams;
  [workoutScreenKinds.richText]: RichTextScreenParams;
  [workoutScreenKinds.baseFormScreen]: BaseFormScreenParams;
  [workoutScreenKinds.pollEntry]: PollEntryScreenParams;
  [workoutScreenKinds.pollResults]: PollResultsScreenParams;
  [workoutScreenKinds.completeRating]: CompleteRatingScreenParams;
  [workoutScreenKinds.copyPrompt]: CopyPromptScreenParams;
};

export type WorkoutScreen<
  Kind extends keyof WorkoutScreenKindToWorkoutScreenParams = keyof WorkoutScreenKindToWorkoutScreenParams
> = (Kind extends keyof WorkoutScreenKindToWorkoutScreenParams
  ? WorkoutScreenKindToWorkoutScreenParams[Kind]
  : never) & {
  // Note that order here is important as these keys need to override keys
  // of possibly empty params objects above.
  id: string;
  sheet?: WorkoutSheet;
  kind: Kind;
};

/**
 * TODO: make these typeguards (isInstructorCover) generic and just use a single
 * typeguard
 */

export const isInstructorCover = (
  screen: WorkoutScreen
): screen is WorkoutScreen<'instructor_cover'> =>
  screen.kind === workoutScreenKinds.instructorCover;

export const isFullScreenVideo = (
  screen: WorkoutScreen
): screen is WorkoutScreen<'full_screen_video'> =>
  screen.kind === workoutScreenKinds.fullScreenVideo;

export const isRestingHeartRate = (
  screen: WorkoutScreen
): screen is WorkoutScreen<'resting_heart_rate'> =>
  screen.kind === workoutScreenKinds.restingHeartRate;

export const isRichTextScreen = (screen: WorkoutScreen): screen is WorkoutScreen<'rich_text'> =>
  screen.kind === workoutScreenKinds.richText;

export const isPollEntry = (screen: WorkoutScreen): screen is WorkoutScreen<'poll_entry'> =>
  screen.kind === workoutScreenKinds.pollEntry;

export const isPollResults = (screen: WorkoutScreen): screen is WorkoutScreen<'poll_results'> =>
  screen.kind === workoutScreenKinds.pollResults;

export const isBaseFormScreen = (
  screen: WorkoutScreen
): screen is WorkoutScreen<'base_form_screen'> => screen.kind === workoutScreenKinds.baseFormScreen;

export const isCompleteRatingScreen = (
  screen: WorkoutScreen
): screen is WorkoutScreen<'complete_rating'> => screen.kind === workoutScreenKinds.completeRating;

export const isNonBaseFormScreen = (
  screen: WorkoutScreen
): screen is WorkoutScreen<NonFormWorkoutScreenKind> =>
  screen.kind !== workoutScreenKinds.baseFormScreen;

export const isCopyPromptScreen = (screen: WorkoutScreen): screen is WorkoutScreen<'copy_prompt'> =>
  screen.kind === workoutScreenKinds.copyPrompt;

export type WorkoutScreens = {
  [workoutScreenId: string]: WorkoutScreen;
};
