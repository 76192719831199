import { HStack, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { TeamAccessKind } from '@coa/api/controllers/v1/teams/types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowRightIcon } from '../../../../components/Icons';
import { AsAttributesObj } from '../../../../lib/json-api';
import { Team } from '../../../../resources/teams/types';

export type TeamsTableProps = {
  getTo: (s: string, accessKind?: TeamAccessKind) => string;
  teams: Array<AsAttributesObj<Team>>;
};

export const TeamsTable = ({ getTo, teams }: TeamsTableProps) => (
  <Table variant="simple-hover">
    <Thead>
      <Tr>
        <Th>Name</Th>
        <Th w="1%" /* Fit cell-contents to right */ />
      </Tr>
    </Thead>
    <Tbody>
      {teams.map(({ id, attributes: { title, accessKind } }) => (
        <Tr key={id}>
          <Td>{title}</Td>
          <Td>
            <HStack>
              <IconButton
                icon={<ArrowRightIcon />}
                as={RouterLink}
                to={getTo(id, accessKind)}
                aria-label="Go to Team"
              />
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
