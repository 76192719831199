import { Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import { ClassListing } from '../../../components/molecules/ClassListing';
import { TransformUpLink } from '../../../components/molecules/TransformUpLink';
import { WorkshopCta } from '../../../components/WorkshopCta';
import { WorkshopOccurrenceExerciseCta } from '../../../components/WorkshopExerciseCta';
import { GetDropInWorkshopOccurrences, getIsSeriesLike } from '../../../resources/workshops';
import { formatWorkshopOccurrenceTimeLabel } from '../../../resources/workshops/utils';
import { getWorkshopRouterUrl } from '../../../routerPaths/helpers';

export type UpcomingWorkshopOccurrenceProps = {
  upcomingClasses: GetDropInWorkshopOccurrences.Response;
  isLoading: boolean;
  displayExerciseCta?: boolean;
  heading?: string;
};

export const UpcomingWorkshopOccurrence = ({
  upcomingClasses,
  isLoading,
  displayExerciseCta,
  heading,
}: UpcomingWorkshopOccurrenceProps) => {
  const firstUpcomingWorkshopOccurrence = upcomingClasses?.[0];
  const { kind: workshopKind } = firstUpcomingWorkshopOccurrence.workshop;
  const { title } = getIsSeriesLike(workshopKind)
    ? firstUpcomingWorkshopOccurrence
    : firstUpcomingWorkshopOccurrence.workshop;

  const workshopRouterUrl = getWorkshopRouterUrl({
    id: firstUpcomingWorkshopOccurrence.workshop.id,
    dropInWorkshopOccurrenceId: getIsSeriesLike(workshopKind)
      ? firstUpcomingWorkshopOccurrence.id
      : undefined,
  });

  return (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
      width="100%"
      backgroundColor="gray.50"
    >
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <Skeleton isLoaded={!isLoading} height="100%">
          <TransformUpLink as={RouterLink} to={workshopRouterUrl} height="100%" width="100%">
            <ClassListing
              label={firstUpcomingWorkshopOccurrence.workshop.marketListingLabel}
              title={title}
              date={formatWorkshopOccurrenceTimeLabel(firstUpcomingWorkshopOccurrence)}
              src={firstUpcomingWorkshopOccurrence.workshop.imageUrl}
              colorScheme="gray"
              instructorName={firstUpcomingWorkshopOccurrence.therapist.name}
              instructorImage={firstUpcomingWorkshopOccurrence.therapist.imageUrl}
            />
          </TransformUpLink>
        </Skeleton>
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 6, lg: 8 }} pl={{ base: 0, md: 8 }}>
        <Skeleton
          isLoaded={!isLoading}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          data-cy={`upcoming-workshop-occurrence-${isLoading ? 'loading' : 'loaded'}`}
        >
          {heading ? (
            <Heading size="md" mb={{ base: 0, md: 6 }} mt={{ base: 6, md: 0 }}>
              {heading}
            </Heading>
          ) : null}
          <Text fontSize="md" mb={8} mt={{ base: 6, md: 0 }}>
            {firstUpcomingWorkshopOccurrence.description}
          </Text>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            justifyContent="flex-end"
          >
            {displayExerciseCta ? (
              <WorkshopOccurrenceExerciseCta
                // This component manages its own loading state, so we can just render
                workshopId={firstUpcomingWorkshopOccurrence.workshop.id}
                exerciseId={firstUpcomingWorkshopOccurrence.exerciseId}
                occurrenceStartsAt={firstUpcomingWorkshopOccurrence.startsAt}
                isOnlyOccurrence
                variant="outline"
                size="md"
                mr={{ base: 0, lg: 4 }}
                mb={{ base: 4, lg: 0 }}
                width={{ base: '100%', lg: 'auto' }}
              />
            ) : null}

            <WorkshopCta
              isLoading={isLoading}
              variant="primary"
              workshopId={firstUpcomingWorkshopOccurrence.workshop.id}
              dropInWorkshopOccurrenceId={firstUpcomingWorkshopOccurrence.id}
              size="md"
              justifyContent="center"
              width={{ base: '100%', lg: 'auto' }}
            />
          </Flex>
        </Skeleton>
      </GridItem>
    </Grid>
  );
};
