import { deepMapKeys } from '@coa/stdlib/object';
import { AnyObject } from '@coa/types';
import _ from 'lodash';
import { exercisesAnalytics } from './client';
import { ExercisesAnalytics } from './types';

export type GenerateTrackEventParams<BaseAttributes extends AnyObject> = {
  name: ExercisesAnalytics.EventName;
  baseAttrs: BaseAttributes;
};

export const generateTrackExercisesEvent = <
  BaseAttributes extends ExercisesAnalytics.EventBodyBase,
  Attributes extends AnyObject | undefined = undefined
>({
  name,
  baseAttrs,
}: GenerateTrackEventParams<BaseAttributes>) => {
  const trackExerciseEvent = (eventAttrs: Attributes = {} as Attributes) => {
    const attrs = {
      ...baseAttrs,
      ...eventAttrs,
    };
    /*
     * TODO: For the sake of safety we are only snaking events
     * tracked here for exercises. We may wish to do this globally
     * in our central Analytics provider.
     */
    const snakeAttrs = deepMapKeys<typeof attrs>(attrs, _.snakeCase);

    exercisesAnalytics.track(name, snakeAttrs);
  };
  return trackExerciseEvent;
};
