import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import { joinTimeLabels } from '@coa/stdlib/string';
import React from 'react';
import { ClassCard } from '../../../components/molecules/ClassCard';
import { TransformUpLink } from '../../../components/molecules/TransformUpLink';
import {
  formatSingleClassTimeLabel,
  Workshop,
  WorkshopOccurrence,
  WorkshopOccurrenceResponse,
  WorkshopResponse,
} from '../../../resources/workshops';

type ClassCardGridItemProps = {
  workshopOrOccurrence: WorkshopResponse | WorkshopOccurrenceResponse;
  hasActiveSubscription: boolean;
  isLoading: boolean;
  kind?: Workshop['kind'];
  type?: Workshop['type'] | WorkshopOccurrence['type'];
};

const WorkshopPreviewCard = ({
  hasActiveSubscription,
  workshop,
}: {
  workshop: Workshop;
  hasActiveSubscription;
}) => {
  const timingLabel = joinTimeLabels(
    formatSingleClassTimeLabel(workshop.workshopOccurrences[0]),
    `${workshop.workshopOccurrences[0].durationInMinutes} min`
  );
  return (
    <TransformUpLink as={RouterLink} height="100%" to={`/classes/${workshop.id}`} width="100%">
      <ClassCard
        marketListingLabel={workshop.marketListingLabel}
        title={workshop.title}
        timingLabel={timingLabel}
        instructorName={workshop.therapist.name}
        src={workshop.imageUrl}
        type={workshop.type}
        kind={workshop.kind}
        showClassLabel={!hasActiveSubscription}
        workshopAccess={workshop.access}
        numberOfClasses={workshop.workshopOccurrences.length}
      />
    </TransformUpLink>
  );
};

const WorkshopOccurencePreviewCard = ({
  workshopOccurrence,
  hasActiveSubscription,
}: {
  workshopOccurrence: WorkshopOccurrenceResponse;
  hasActiveSubscription: boolean;
}) => {
  const linkTo = `/classes/${workshopOccurrence.workshopId}/drop-ins/${workshopOccurrence.id}`;
  const timingLabel = joinTimeLabels(
    formatSingleClassTimeLabel(workshopOccurrence),
    `${workshopOccurrence.durationInMinutes}min`
  );
  return (
    <TransformUpLink as={RouterLink} height="100%" to={linkTo} width="100%">
      <ClassCard
        marketListingLabel={workshopOccurrence.workshop.marketListingLabel}
        title={workshopOccurrence.title}
        timingLabel={timingLabel}
        instructorName={workshopOccurrence.therapist.name}
        src={workshopOccurrence.workshop.imageUrl}
        type={workshopOccurrence.type}
        showClassLabel={!hasActiveSubscription}
        workshopAccess={workshopOccurrence.workshop.access}
      />
    </TransformUpLink>
  );
};

export const ClassCardGridItem = ({
  workshopOrOccurrence,
  isLoading,
  ...rest
}: ClassCardGridItemProps) => (
  <GridItem colSpan={{ base: 6, lg: 3 }} key={workshopOrOccurrence.id}>
    <Skeleton isLoaded={!isLoading} height="100%" borderRadius="lg" fadeDuration={0}>
      {workshopOrOccurrence.type === 'workshop' ? (
        <WorkshopPreviewCard {...rest} workshop={workshopOrOccurrence as WorkshopResponse} />
      ) : (
        <WorkshopOccurencePreviewCard
          workshopOccurrence={workshopOrOccurrence as WorkshopOccurrenceResponse}
          {...rest}
        />
      )}
    </Skeleton>
  </GridItem>
);

// This should be a general grid that can accept
// a mixed list of workshops and render an appropriate grid
type ClassCardGridProps = {
  classes: (WorkshopResponse | WorkshopOccurrenceResponse)[];
  isLoading: boolean;
  hasActiveSubscription: boolean;
};
export const ClassCardGrid = ({
  classes,
  isLoading,
  hasActiveSubscription,
}: ClassCardGridProps) => (
  <Grid
    templateColumns="repeat(12, 1fr)"
    templateRows="repeat(1, 1fr)"
    autoRows="1fr"
    columnGap={{ base: 4, md: 6, lg: 8 }}
    rowGap={{ base: 4, md: 6, lg: 8 }}
  >
    {classes.map((workshopOrOccurrence) => {
      const { id, type } = workshopOrOccurrence;

      const props = {
        workshopOrOccurrence,
        isLoading,
        hasActiveSubscription,
      };
      return <ClassCardGridItem {...props} key={`${id} - ${type}`} />;
    })}
  </Grid>
);
