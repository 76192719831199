import { AnyObject } from '@coa/types';
import _ from 'lodash';
import { v1ApiPath } from '../../index';
import { resolvePath } from '../path';
import { appendQueryParams } from '../url';

export { v1ApiPath };

export const adminApiPath = (...tokens: string[]) => resolvePath('admin', ...tokens);

// TODO: Naming here is deliberately weird as I'm not sure how best
// to define all this stuff yet - particularly the idea of overloading
// every instance of this getter with support for both url-params AND
// query params feels heavy. For now we'll see how it feels keeping them
// separate.
export const createGetApiUrlWithQueryParams = <QueryParams extends AnyObject>(apiPath: string) => (
  queryParams: QueryParams = {} as QueryParams
) =>
  appendQueryParams(
    apiPath,
    _.mapKeys(queryParams, (_value, key: string) => _.snakeCase(key))
  );
