import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ValueOf } from '@coa/types';
import _ from 'lodash';
import React from 'react';
import {
  CalendarIcon,
  ChevronDownIcon,
  EyeIcon,
  MessageSquareIcon,
  MoonIcon,
  MusicIcon,
  SearchIcon,
  SunriseIcon,
  SunsetIcon,
  TrendingUpIcon,
  UmbrellaIcon,
  UsersIcon,
} from '../../../../components/Icons';
import { WorkshopFilter, workshopTimings, WorkshopTimings } from '../../../../resources/workshops';
import {
  ClassesFilterType,
  ClassesFilterValues,
  useClassesFiltersContext,
} from './ClassesFiltersContextProvider';

export const filterIconProps = {
  height: '20px',
  width: '20px',
};

// helper function to get non-trivial props for filter buttons in desktop vs mobile views
export const getFilterToggleButtonProps = (isActive: boolean, toggleActive: () => void) => ({
  color: isActive ? 'green.900' : 'blackAlpha.800',
  colorScheme: 'green',
  variant: 'outline',
  _focus: { outline: 'none' },
  isActive,
  onClick: toggleActive,
  borderColor: isActive ? 'green' : 'inherit',
});

export const ClassesFiltersClearFilterButton = () => {
  const { hasFiltersApplied, clearFilters } = useClassesFiltersContext();
  return (
    <Button
      visibility={hasFiltersApplied ? 'visible' : 'hidden'}
      variant="ghost"
      colorScheme="green"
      color="blackAlpha.800"
      onClick={clearFilters}
      _focus={{ outline: 'none' }}
    >
      Clear All
    </Button>
  );
};

export const ClassesFiltersEmptyState = () => (
  <VStack justify="center" align="center" height={{ sm: '180px' }}>
    <IconButton
      icon={<SearchIcon {...filterIconProps} />}
      isRound
      height="60px"
      width="60px"
      aria-label="classes-filter-empty-state"
      bg="gray.50"
    />
    <Heading fontSize="lg">We couldn't find any matching results</Heading>
    <Text textAlign="center">
      There currently aren't any classes matching your filter selections. Try searching again or
      check back later. We're always adding new classes!
    </Text>
  </VStack>
);

export type ClassesFiltersButtonToggleProps = ButtonProps & {
  icon: React.ReactNode;
  isActive: boolean;
  subtitle?: string;
  title: string;
  toggleActive: () => void;
};

export const ClassesFiltersButtonToggle = ({
  isActive,
  icon,
  subtitle,
  title,
  toggleActive,
  ...rest
}: ClassesFiltersButtonToggleProps) => (
  <Button
    {...getFilterToggleButtonProps(isActive, toggleActive)}
    height="92px"
    width="102px"
    {...rest}
  >
    <VStack height="full" spacing={0}>
      <Flex height="50%" alignItems="center">
        {icon}
      </Flex>
      <Flex height="50%" flexDir="column" justifyContent="center" mt={0}>
        <Text fontWeight="normal">{_.capitalize(`${title}`).split('_').join(' ')}</Text>
        {subtitle ? (
          <Text fontSize="12px" fontWeight="normal" mb={2}>
            {subtitle}
          </Text>
        ) : null}
      </Flex>
    </VStack>
  </Button>
);

type DropdownToggleTypes = WorkshopFilter['skills'] | WorkshopFilter['days'];
type DropdownToggleProps = {
  onMenuOptionClick: (type: ClassesFilterType, value: ClassesFilterValues) => () => void;
  labels: DropdownToggleTypes;
  type: ClassesFilterType;
  valueArr: DropdownToggleTypes;
  icon: React.ReactNode;
};

export type WorkshopTimingFilters = {
  title: WorkshopTimings;
  subtitle: string;
};

export const filterTitlesToSubtitles: { [key in ValueOf<typeof workshopTimings>]: string } = {
  morning: '9AM-12PM',
  afternoon: '12PM-5PM',
  evening: '5PM-9PM',
};

export const workshopTimingsFilters: WorkshopTimingFilters[] = [
  { title: 'morning', subtitle: '9AM-12PM' },
  { title: 'afternoon', subtitle: '12PM-5PM' },
  { title: 'evening', subtitle: '5PM-9PM' },
];

export const getIconFromFilterLabel = (label: ClassesFilterValues) => {
  let Icon;
  switch (label) {
    case 'morning':
      Icon = SunriseIcon;
      break;
    case 'afternoon':
      Icon = SunsetIcon;
      break;
    case 'evening':
      Icon = MoonIcon;
      break;
    case 'self_awareness':
      Icon = SearchIcon;
      break;
    case 'empathy':
      Icon = UsersIcon;
      break;
    case 'mindfulness':
      Icon = UmbrellaIcon;
      break;
    case 'curiosity':
      Icon = EyeIcon;
      break;
    case 'play':
      Icon = MusicIcon;
      break;
    case 'resilience':
      Icon = TrendingUpIcon;
      break;
    case 'communication':
      Icon = MessageSquareIcon;
      break;
    default:
      Icon = CalendarIcon;
      break;
  }
  return <Icon {...filterIconProps} />;
};

const formatDropdownLabel = (type: ClassesFilterType, labels: DropdownToggleTypes) => {
  if (!labels || !labels.length) {
    return type;
  }
  if (labels.length === 1) {
    return labels[0];
  }
  const lengthAfterFirstIndex = labels.length - 1;
  return `${labels[0]} + ${lengthAfterFirstIndex} more`;
};
export const ClassesFiltersDropdownToggleMenu = ({
  labels,
  onMenuOptionClick,
  type,
  valueArr,
  icon,
  width,
}: DropdownToggleProps & BoxProps) => {
  const isActive = valueArr && valueArr.length;
  const label = isActive ? formatDropdownLabel(type, valueArr) : type;
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        color={isActive ? 'green.900' : 'blackAlpha.800'}
        background={isActive ? 'green.100' : 'inherit'}
        as={Button}
        colorScheme="green"
        variant="outline"
        borderColor={isActive ? 'transparent' : 'inherit'}
        height="92px"
        width={width}
        mr={2}
      >
        <VStack spacing={0} height="92px">
          <Flex height="50%" alignItems="center" justifyItems="center">
            {icon}
          </Flex>
          <Flex
            height="50%"
            alignItems="center"
            justifyItems="center"
            flexDir="column"
            fontSize="16px"
          >
            <Flex alignItems="center">
              <Text fontWeight="normal">{_.capitalize(label)}</Text>
              <ChevronDownIcon ml={1} />
            </Flex>
          </Flex>
        </VStack>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="checkbox" value={valueArr}>
          {labels.map((name) => (
            <MenuItemOption
              key={name}
              value={name}
              onClick={onMenuOptionClick(type, name)}
              _checked={{
                backgroundColor: 'green.200',
              }}
            >
              <Flex align="center" justify="start">
                {getIconFromFilterLabel(name)}
                <Text fontSize="smalller" ml={2}>
                  {_.capitalize(name.split('_').join(' '))}
                </Text>
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
