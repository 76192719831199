import { useTimer } from '@coa/react-utils';
import { UseMutateAsyncFunction, UseMutateFunction, UseMutationResult } from 'react-query';

/*
 * In environments where network calls move really quickly, it's common to
 * show a loading state for a minimum duration. This is helpful when the user
 * will expect a loading state but the request moves so quickly that the
 * loading state flashes for an amount of time so short that it is jarring.
 *
 * This wrapper provides extra methods around react-query's `useMutation`
 * hook that automatically start / stop a timer alongside the mutation and
 * exposes timer-centric loading state info.
 *
 * Notably, we don't obscure any of the original methods from either the
 * timer OR the mutation, instead exposing them to the caller if they
 * wish to manage anything themselves.
 */
export const useMutationWithTimer = <TData, TError, TVariables, TContext>(
  mutation: UseMutationResult<TData, TError, TVariables, TContext>,
  options: { duration: number }
) => {
  const timer = useTimer();
  const { duration } = options;

  const mutateWithTimer: UseMutateFunction<TData, TError, TVariables, TContext> = (...args) => {
    timer.startTimer(duration);
    mutation.mutate(...args);
  };

  const mutateAsyncWithTimer: UseMutateAsyncFunction<TData, TError, TVariables, TContext> = async (
    ...args
  ) => {
    const [, mutationResult] = await Promise.all([
      timer.startTimerAsync(duration),
      mutation.mutateAsync(...args),
    ]);
    return mutationResult;
  };

  const resetWithTimer = () => {
    timer.resetTimer();
    mutation.reset();
  };

  return {
    ...mutation,
    ...timer,

    mutateWithTimer,
    mutateAsyncWithTimer,
    resetWithTimer,

    // TODO: Use of isIdle and not isLoading is probably bad?
    isLoadingWithTimer: !mutation.isIdle && !timer.timerElapsed,
    isSuccessWithTimer: mutation.isSuccess && timer.timerElapsed,
  };
};
