import { convertBase64ToBlob, getFileExtensionFromBase64Prefix, isBase64 } from '@coa/stdlib/file';
import { serverCase } from '@coa/stdlib/string';
import { AnyObject } from '@coa/types';
import flatten from 'keypather/flatten';
import _ from 'lodash';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { createJsonApiAxiosClient } from '../../../lib/axios';
import { appendQueryParams } from '../../../lib/url';
import { normalizeFlattenedKeypath } from './normalizeFlattenedKeypath';

type QueryOrMutationScaffoldBaseParams = {
  basePath: string;
  auth: boolean;
};

export type AdminCmsSortOrder = 'asc' | 'desc';
export type AdminCmsPageNum = number;

type QueryScaffoldParams = QueryOrMutationScaffoldBaseParams;
type MutationScaffoldParams = QueryOrMutationScaffoldBaseParams;

type PaginatedResponse<SerializedResource> = {
  page: SerializedResource[];
  meta: { page: AdminCmsPageNum; totalPages: number; pageSize: number; totalSize: number };
};

type BaseSerializedResource = {
  id: string;
  type: string;
} & {
  [s: string]: unknown;
};

export type AdminCmsIndexFilterParams<SerializedResource extends BaseSerializedResource> = {
  order_by: keyof Omit<SerializedResource, 'id' | 'type'>;
  order: AdminCmsSortOrder;
  page: AdminCmsPageNum;
  query?: string;
  filters?: string;
};

export type UseIndexQuery<SerializedResource extends BaseSerializedResource> = (
  params: AdminCmsIndexFilterParams<SerializedResource>,
  options?: UseQueryOptions
) => UseQueryResult<PaginatedResponse<SerializedResource>>;

export type UseShowQuery<SerializedResource extends BaseSerializedResource> = (
  params: { id: string },
  options?: UseQueryOptions
) => UseQueryResult<SerializedResource>;

type UnknownError = unknown;

export type UseCreateMutation<
  SerializedResource extends BaseSerializedResource
> = () => UseMutationResult<SerializedResource, UnknownError, Partial<SerializedResource>>;

export type UseUpdateMutation<SerializedResource extends BaseSerializedResource> = ({
  id,
}: {
  id: SerializedResource['id'];
}) => UseMutationResult<SerializedResource, UnknownError, Partial<SerializedResource>>;

export const generateUseIndexQuery = <
  SerializedResource extends BaseSerializedResource,
  QueryParams extends AdminCmsIndexFilterParams<SerializedResource>
>({
  basePath,
  auth,
}: QueryScaffoldParams): UseIndexQuery<SerializedResource> => {
  type Response = {
    page: SerializedResource[];
    meta: { page: AdminCmsPageNum; totalPages: number; pageSize: number; totalSize: number };
  };
  const generateIndexFetch = (params: QueryParams = {} as QueryParams) => {
    const client = createJsonApiAxiosClient({ auth, paginate: true });
    const path = appendQueryParams<QueryParams>(basePath, params);
    const fn = async () => {
      const { data } = await client.get<Response>(path);
      return data;
    };
    return { queryKey: path, fn };
  };

  const useIndexQuery = (
    params: QueryParams = {} as QueryParams,
    options: UseQueryOptions<Response> = {}
  ) => {
    const { queryKey, fn } = generateIndexFetch(params);
    return useQuery<Response>(queryKey, fn, options);
  };

  return useIndexQuery;
};

export const generateUseShowQuery = <SerializedResource extends BaseSerializedResource>({
  basePath,
  auth,
}: QueryScaffoldParams): UseShowQuery<SerializedResource> => {
  type Request = {
    pathParams: { id: string };
  };
  type Response = SerializedResource;

  const generateShowFetch = ({ id }: Request['pathParams']) => {
    const client = createJsonApiAxiosClient({ auth });
    // TODO: Prefer use of path.join
    const path = [basePath, id].join('/');
    const fn = async () => {
      const { data } = await client.get<Response>(path);
      return data;
    };
    return { queryKey: path, fn };
  };

  const defaultUseQueryOptions = {
    /*
     * Given that this query is used in a CMS-form context where file uploads
     * happen, we don't want to trigger a refetch on window focus (since a file
     * upload workflow involves using a File-selector window, which then triggers
     * a window focus). Refetching on window focus would destroy any ephemeral
     * form state.
     *
     * This isn't great in that we have form-centric UI logic leaking into our
     * controllers, but if we don't centralize this we risk fragility that might
     * bite us later, so the tradeoff is worth it IMO.
     */
    refetchOnWindowFocus: false,
  };

  const useShowQuery = ({ id }: Request['pathParams'], options: UseQueryOptions<Response> = {}) => {
    const { queryKey, fn } = generateShowFetch({ id });
    return useQuery<Response>(queryKey, fn, {
      ...defaultUseQueryOptions,
      ...options,
    });
  };

  return useShowQuery;
};

type FormDataAppendValueArgs = [value: string | Blob, fileName?: string];

/*
 * Transforms value to append to our FormData instance depending
 * on its type.
 */
const getFormDataAppendValueArgs = (value: unknown): FormDataAppendValueArgs => {
  if (_.isString(value) && isBase64(value)) {
    const fileBlob = convertBase64ToBlob(value);
    /*
     * We need to pass a filename explicitly as ImageMagick will not
     * preserve transparency of .pngs without an explicit filename.
     */
    const extension = getFileExtensionFromBase64Prefix(value);
    const fileName = [uuidv4(), extension].join();
    return [fileBlob, fileName];
  }
  if (_.isObject(value)) {
    return [new Blob([JSON.stringify(value)], { type: 'application/json' })];
  }
  return [String(value)];
};

/*
 * Converts a plain JS object-literal containing request data
 * to an instance of FormData to be sent using headers
 * `Content-Type: mutlipart/form-data`.
 *
 * We prefer this content-type on Create and Update as they provide
 * much better performance for file uploads.
 */
const convertPlainJsonToFormData = (data: AnyObject) =>
  _.reduce(
    data,
    (fd, topLevelValue, rawTopLevelKey) => {
      /*
       * TODO: Consider moving the case transform data outside of
       * formData transform to separate concerns.
       */
      const topLevelKey = serverCase(rawTopLevelKey);

      if (_.isPlainObject(topLevelValue) || _.isArray(topLevelValue)) {
        const flattenedValue = flatten(topLevelValue);
        Object.keys(flattenedValue).forEach((keyPath) => {
          /*
           * Tack the top-level key onto the keypath in the same
           * way that keypather expects it.
           */
          const fullKeyPath = [topLevelKey, keyPath].join(keyPath[0] === '[' ? '' : '.');
          const appendKey = normalizeFlattenedKeypath(fullKeyPath);
          if (flattenedValue[keyPath] !== null) {
            const appendValueArgs = getFormDataAppendValueArgs(flattenedValue[keyPath]);
            fd.append(appendKey, ...appendValueArgs);
          }
        });
      } else if (topLevelValue !== null) {
        const appendValueArgs = getFormDataAppendValueArgs(topLevelValue);
        fd.append(topLevelKey, ...appendValueArgs);
      }
      return fd;
    },
    new FormData()
  );

export const generateUseCreateMutation = <SerializedResource extends BaseSerializedResource>({
  basePath,
  auth,
}: MutationScaffoldParams): UseCreateMutation<SerializedResource> => {
  type Request = {
    body: SerializedResource;
  };
  type Response = SerializedResource;

  const generateCreateFetch = () => {
    const client = createJsonApiAxiosClient({ auth });
    const path = basePath;
    const fn = async (body: Request['body']) => {
      const formData = convertPlainJsonToFormData(body);
      const { data } = await client({
        method: 'post',
        url: path,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // TODO: Fix this so that we retain the type and don't need
      // the assertion.
      return data as Response;
    };
    return { mutationKey: path, queryKey: path, fn };
  };

  const useCreateMutation = () => {
    const { mutationKey, queryKey, fn } = generateCreateFetch();
    const queryClient = useQueryClient();
    return useMutation(mutationKey, {
      mutationFn: (body: Request['body']) => fn(body),
      onMutate: async () => {
        queryClient.cancelQueries(queryKey);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    });
  };

  return useCreateMutation;
};

export const generateUseUpdateMutation = <SerializedResource extends BaseSerializedResource>({
  basePath,
  auth,
}: MutationScaffoldParams): UseUpdateMutation<SerializedResource> => {
  type Request = {
    pathParams: { id: string };
    body: SerializedResource;
  };
  type Response = SerializedResource;

  const generateUpdateFetch = ({ id }: Request['pathParams']) => {
    const client = createJsonApiAxiosClient({ auth });
    // TODO: Prefer use of path.join
    const path = [basePath, id].join('/');
    const fn = async (body: Request['body']) => {
      const formData = convertPlainJsonToFormData(body);
      const { data } = await client({
        method: 'put',
        url: path,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // TODO: Fix this so that we retain the type and don't need
      // the assertion.
      return data as Response;
    };
    return { mutationKey: path, queryKey: path, fn };
  };

  const useUpdateMutation = ({ id }: Request['pathParams']) => {
    const { mutationKey, queryKey, fn } = generateUpdateFetch({ id });
    const queryClient = useQueryClient();
    return useMutation(mutationKey, {
      mutationFn: (body: Request['body']) => fn(body),
      onMutate: async () => {
        queryClient.cancelQueries(queryKey);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    });
  };

  return useUpdateMutation;
};
