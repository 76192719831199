export const WORKSHOP_TAGS_LIST_REQUEST = 'WORKSHOP_TAGS_LIST_REQUEST';
export const WORKSHOP_TAGS_LIST_SUCCESS = 'WORKSHOP_TAGS_LIST_SUCCESS';
export const WORKSHOP_TAGS_LIST_FAILURE = 'WORKSHOP_TAGS_LIST_FAILURE';

export function list(workshopId) {
  return {
    type: WORKSHOP_TAGS_LIST_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/tags`,
      authenticated: true,
      admin: true,
      types: [WORKSHOP_TAGS_LIST_REQUEST, WORKSHOP_TAGS_LIST_SUCCESS, WORKSHOP_TAGS_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export const WORKSHOP_TAGS_ADD_REQUEST = 'WORKSHOP_TAGS_ADD_REQUEST';
export const WORKSHOP_TAGS_ADD_SUCCESS = 'WORKSHOP_TAGS_ADD_SUCCESS';
export const WORKSHOP_TAGS_ADD_FAILURE = 'WORKSHOP_TAGS_ADD_FAILURE';

export function add(workshopId, id) {
  return {
    type: WORKSHOP_TAGS_ADD_REQUEST,
    api: {
      data: { id },
      endpoint: `workshops/${workshopId}/tags`,
      authenticated: true,
      admin: true,
      types: [WORKSHOP_TAGS_ADD_REQUEST, WORKSHOP_TAGS_ADD_SUCCESS, WORKSHOP_TAGS_ADD_FAILURE],
      method: 'POST',
    },
  };
}

export const WORKSHOP_TAGS_REMOVE_REQUEST = 'WORKSHOP_TAGS_REMOVE_REQUEST';
export const WORKSHOP_TAGS_REMOVE_SUCCESS = 'WORKSHOP_TAGS_REMOVE_SUCCESS';
export const WORKSHOP_TAGS_REMOVE_FAILURE = 'WORKSHOP_TAGS_REMOVE_FAILURE';

export function remove(workshopId, id) {
  return {
    type: WORKSHOP_TAGS_REMOVE_REQUEST,
    api: {
      data: { id },
      endpoint: `workshops/${workshopId}/tags/${id}`,
      authenticated: true,
      admin: true,
      types: [
        WORKSHOP_TAGS_REMOVE_REQUEST,
        WORKSHOP_TAGS_REMOVE_SUCCESS,
        WORKSHOP_TAGS_REMOVE_FAILURE,
      ],
      method: 'DELETE',
    },
  };
}
