import { Box, Divider, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import { useGetTeamMembershipsQuery } from '@coa/api/controllers/v1/teamMemberships';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';

export function AccountInfoTeams(): JSX.Element {
  const getTeamMembershipsQuery = useGetTeamMembershipsQuery();
  if (getTeamMembershipsQuery.isLoading) return null;

  const { data: teamMemberships } = getTeamMembershipsQuery;
  if (_.isEmpty(teamMemberships)) return null;

  const topLevelTeams = teamMemberships
    .filter(({ team }) => !team.parentTeamId)
    .map(({ team }) => team);

  return (
    <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
      <Text textStyle="earmark">Your Teams</Text>
      <Divider my={2} />
      {topLevelTeams.map((team) => {
        const membership = teamMemberships.find(
          ({ team: { id: membershipTeamId } }) => membershipTeamId === team.id
        );
        return (
          <Flex key={team.id}>
            <Text>{team.title}</Text>
            <Spacer />
            {membership.role === 'admin' ? (
              <Link
                as={RouterLink}
                color="green.900"
                to={`/account/teams/${team.id}`}
                data-cy="account-teams-link"
              >
                Manage
              </Link>
            ) : null}
          </Flex>
        );
      })}
    </Box>
  );
}
