import {
  Box,
  BoxProps,
  ButtonGroup,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Table,
  TableCellProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { MoreVerticalIcon } from '../../../../components/Icons';
import {
  Team,
  TeamMember,
  useGetTeamMembersQuery,
  useGetTeamQuery,
  usePostTeamMembersResendInviteMutation,
} from '../../../../resources/teams';
import { AddTeamMembersButton } from './AddTeamMembersModal';
import { ChangeTeamMemberRoleModal } from './ChangeTeamMemberRoleModal';
import { RemoveTeamMemberModal } from './RemoveTeamMemberModal';

type TeamMembersTableProps = { id: string };

function getStatusLabel({ status, role }: Pick<TeamMember, 'status' | 'role'>) {
  if (status === 'invited') return 'Invited';
  if (role === 'basic') return 'Member';
  return _.capitalize(role);
}

function StyledMemberTd({
  status,
  children,
  ...rest
}: {
  status: TeamMember['status'];
} & TableCellProps) {
  const color = status === 'invited' ? 'gray.400' : undefined;
  return (
    <Td {...rest} color={color}>
      {children}
    </Td>
  );
}

function ResendInviteMenuItem({
  id,
  memberId,
  children,
  ...rest
}: { id: Team['id']; memberId: TeamMember['id'] } & MenuItemProps) {
  const postTeamMembersResendInviteMutation = usePostTeamMembersResendInviteMutation({
    id,
    memberId,
  });
  const toast = useToast();
  const handleClick = async () => {
    try {
      await postTeamMembersResendInviteMutation.mutateAsync();
      toast({
        title: 'Success!',
        description: `Invite email successfully re-sent.`,
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something went wrong.',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  };
  return (
    <MenuItem {...rest} onClick={handleClick}>
      {children}
    </MenuItem>
  );
}

function TableEmptyState({ id, ...rest }: { id: string } & BoxProps): JSX.Element {
  return (
    <Box bg="gray.50" borderRadius="base" px={4} py={16} textAlign="center" {...rest}>
      <Heading size="lg">What's a class without classmates?</Heading>
      <Text>Invite some teammates to attend Coa classes together.</Text>
      <AddTeamMembersButton id={id} variant="primary" mt={4}>
        Invite Teammates
      </AddTeamMembersButton>
    </Box>
  );
}

export function TeamMembersTable({ id }: TeamMembersTableProps): JSX.Element {
  const getTeamMembersQuery = useGetTeamMembersQuery({ id });
  const getTeamQuery = useGetTeamQuery({ id });
  const {
    isOpen: isRemoveTeamMemberModalOpen,
    onOpen: onRemoveTeamMemberModalOpen,
    onClose: onRemoveTeamMemberModalClose,
  } = useDisclosure();

  const {
    isOpen: isChangeTeamMemberRoleModalOpen,
    onOpen: onChangeTeamMemberRoleModalOpen,
    onClose: onChangeTeamMemberRoleModalClose,
  } = useDisclosure();

  const [focusedMember, setTeamMemberToFocus] = useState<TeamMember | undefined>();

  if (getTeamMembersQuery.isLoading || getTeamQuery.isLoading) return null;

  const { data: members = [] } = getTeamMembersQuery.data;
  const { data: { attributes: { parentTeamId } } = {} } = getTeamQuery.data;

  const isTopLevel = !parentTeamId;

  if (_.isEmpty(members)) return <TableEmptyState id={id} />;

  return (
    <>
      <ButtonGroup justifyContent="flex-end" width="stretch">
        <AddTeamMembersButton id={id} variant="secondary">
          Invite Teammates
        </AddTeamMembersButton>
      </ButtonGroup>
      <Table variant="simple-hover">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {members.map(({ id: memberId, attributes }) => (
            <Tr key={memberId}>
              <StyledMemberTd {...attributes}>{attributes.name}</StyledMemberTd>
              <StyledMemberTd {...attributes}>{attributes.email}</StyledMemberTd>
              <StyledMemberTd {...attributes}>{getStatusLabel(attributes)}</StyledMemberTd>
              <Td isNumeric>
                <Menu>
                  <MenuButton as={IconButton} icon={<MoreVerticalIcon />} />
                  <MenuList>
                    {
                      /*
                       * We're currently only dealing with Admins for top-level
                       * orgs, so we disable this superficially.
                       */
                      isTopLevel ? (
                        <MenuItem
                          onClick={() => {
                            setTeamMemberToFocus({ id: memberId, ...attributes });
                            onChangeTeamMemberRoleModalOpen();
                          }}
                        >
                          Change Role
                        </MenuItem>
                      ) : null
                    }
                    <MenuItem
                      onClick={() => {
                        setTeamMemberToFocus({ id: memberId, ...attributes });
                        onRemoveTeamMemberModalOpen();
                      }}
                    >
                      Remove Member
                    </MenuItem>
                    {attributes.status === 'invited' ? (
                      <ResendInviteMenuItem id={id} memberId={memberId}>
                        Re-send Invite Email
                      </ResendInviteMenuItem>
                    ) : null}
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <RemoveTeamMemberModal
        isOpen={isRemoveTeamMemberModalOpen}
        onClose={() => {
          setTeamMemberToFocus(undefined);
          onRemoveTeamMemberModalClose();
        }}
        id={id}
        member={focusedMember}
      />
      {isTopLevel ? (
        <ChangeTeamMemberRoleModal
          isOpen={isChangeTeamMemberRoleModalOpen}
          onClose={() => {
            setTeamMemberToFocus(undefined);
            onChangeTeamMemberRoleModalClose();
          }}
          id={id}
          member={focusedMember}
        />
      ) : null}
    </>
  );
}
