import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQueryParams } from '@coa/react-utils';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftIcon } from '../../../../components/Icons';
import { teamMembershipAnalytics } from '../../../../lib/analytics/clients/teamMembershipAnalytics';
import { useCreateTeamMutation } from '../../../../resources/teams';
import { CompanyInfoValidationSchema } from '../../utils/validation';
import { TeamMembershipViewLayout } from '../layouts/TeamMembershipViewLayout';
import { useTeamMembershipRegistrationContext } from '../TeamMembershipContextProvider';
import {
  TeamMembershipCouponCodePromo,
  TeamMembershipRegistrationFormHeader,
} from '../TeamMembershipStaticCopy';

const CompanyInfoFormPriceEstimate = ({
  numberOfSeatsInputVal,
}: {
  numberOfSeatsInputVal: string;
}) => {
  let price = '--';

  const parsedNumberOfSeatsVal = Number(numberOfSeatsInputVal);
  if (parsedNumberOfSeatsVal) {
    const adjustedNumberOfSeatsVal = parsedNumberOfSeatsVal < 5 ? 5 : parsedNumberOfSeatsVal;
    price = (adjustedNumberOfSeatsVal * 19).toString();
  }
  return (
    <Flex fontSize="24px" width="full" justify="space-between">
      <Flex flexDir="column">
        <Text>Pricing</Text>
        <Text fontSize="sm">Promotions applied at checkout.</Text>
      </Flex>
      <Flex alignItems="center">
        <Text>$ {price}</Text>
        <Text as="span" fontSize="sm" ml={1}>
          per month
        </Text>
      </Flex>
    </Flex>
  );
};

type TeamMembershipCreateTeamFormValues = {
  companyTitle: string;
  numberOfSeats: string;
};

const INITIAL_VALUES = {
  companyTitle: '',
  numberOfSeats: '',
};

const CompanyInfoForm = () => {
  const history = useHistory();
  const { isSubmitting, dirty, errors, values } = useFormikContext<
    TeamMembershipCreateTeamFormValues
  >();

  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');
  const { isVGP, couponCodeIsActive } = useTeamMembershipRegistrationContext();

  const handleNavBackToRegistration = () => {
    // IS there an easier way to pass query params?
    // Not important right now, come back to this later
    const pathBase = '/team-membership/registration';
    const fullpath = Boolean(campaign) ? `${pathBase}?campaign=${campaign}` : pathBase;
    history.push(fullpath);
  };

  const renderBackButton = () => (
    <Button
      height="20px"
      variant="link"
      fontWeight="light"
      color="blackAlpha.800"
      onClick={handleNavBackToRegistration}
    >
      <ArrowLeftIcon height="16px" />
      back to personal info form
    </Button>
  );

  return (
    <Flex flexFlow="column" justify="space-between" height="100%">
      <Flex flexDir="column">
        <Flex flexDir="column" marginTop={10}>
          <Flex marginBottom={5}>{renderBackButton()}</Flex>
          <TeamMembershipRegistrationFormHeader membershipStep="company" />
        </Flex>
        <VStack width="full" alignItems="left" h="full">
          <Text fontSize="24px">Company Information</Text>
          <FormControl isInvalid={errors.companyTitle && dirty}>
            <FormLabel margin={{ sm: 'none' }} htmlFor="companyTitle">
              Name of Company or Practice
            </FormLabel>
            <Field
              name="companyTitle"
              type="text"
              as={Input}
              variant="coa-main"
              placeholder="Name of Company or Practice"
            />
            <FormErrorMessage>{errors.companyTitle}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.numberOfSeats && dirty}>
            <FormLabel htmlFor="num_employees">Number of Employees</FormLabel>
            <Field
              name="numberOfSeats"
              type="number"
              as={Input}
              variant="coa-main"
              placeholder="Number of Employees"
            />
            <FormErrorMessage>{errors.numberOfSeats}</FormErrorMessage>
          </FormControl>
        </VStack>
      </Flex>
      <Flex flexDir="column">
        <Flex>
          <CompanyInfoFormPriceEstimate numberOfSeatsInputVal={values.numberOfSeats} />
        </Flex>
        {isVGP && couponCodeIsActive ? (
          <Flex width="full" my={4}>
            <TeamMembershipCouponCodePromo couponCode="VGPLAUNCH25" />
          </Flex>
        ) : null}
        <Flex width="full" marginTop={4}>
          <Button
            variant="solid"
            colorScheme="red"
            width="full"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={!dirty}
          >
            Sign Up
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const TeamMembershipCreateTeamFormInner = () => {
  const toast = useToast();
  const initialValues: TeamMembershipCreateTeamFormValues = { ...INITIAL_VALUES };
  const createTeamMutation = useCreateTeamMutation();

  const history = useHistory();
  const queryParams = useQueryParams();
  const campaign = queryParams.get('campaign');

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={CompanyInfoValidationSchema}
      onSubmit={async ({ companyTitle, numberOfSeats }) => {
        try {
          const marketingCampaign = campaign ? campaign.toLowerCase() : null;
          const createdTeam = await createTeamMutation.mutateAsync({
            title: companyTitle,
            numberOfSeats: Number(numberOfSeats),
            marketingCampaign,
          });
          teamMembershipAnalytics.track('Submitted Team Membership - Company Info Form', {
            teamName: companyTitle,
            numberOfSeats: Number(numberOfSeats),
            marketingCampaign,
          });
          const pathBase = `/team-membership/${createdTeam.data.id}/payment`;
          const fullpath = Boolean(campaign) ? `${pathBase}?campaign=${campaign}` : pathBase;
          history.push(fullpath);
        } catch (err: unknown) {
          toast({
            title: 'Unable to Create Team',
            description: 'Please try again',
            duration: 3000,
            isClosable: true,
            status: 'error',
          });
        }
      }}
    >
      <Flex as={Form} flexDir="column" height="full" width="full">
        <CompanyInfoForm />
      </Flex>
    </Formik>
  );
};

export const TeamMembershipCreateTeamForm = () => {
  useEffect(() => {
    teamMembershipAnalytics.track('View Team Membership - Company Info Form');
  }, []);
  return (
    <TeamMembershipViewLayout>
      <TeamMembershipCreateTeamFormInner />
    </TeamMembershipViewLayout>
  );
};
