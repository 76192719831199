import { createAxiosClient, createJsonApiAxiosClient } from '@coa/api/lib/axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { GetHubspotTemplates, PostHubspotTemplates } from './types';

const generateGetHubspotTemplates = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/admin/hubspot_templates`;
  const fn = async () => {
    const { data } = await client.get<GetHubspotTemplates.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetHubspotTemplatesQuery = (
  options: UseQueryOptions<GetHubspotTemplates.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetHubspotTemplates();
  return useQuery<GetHubspotTemplates.Response>(queryKey, fn, options);
};

const generatePostHubspotTemplates = () => {
  const client = createAxiosClient({ auth: true, transformPropertyNameCase: true });
  const path = `/admin/hubspot_templates`;
  const fn = async (body: PostHubspotTemplates.Request['body']) => {
    const { data } = await client.post<PostHubspotTemplates.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const usePostHubspotTemplatesMutation = () => {
  const { path: queryKey, fn } = generatePostHubspotTemplates();
  const queryClient = useQueryClient();
  return useMutation(queryKey, {
    mutationFn: (body: PostHubspotTemplates.Request['body']) => fn(body),
    onMutate: async () => {
      queryClient.cancelQueries(`/admin/hubspot_templates`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`/admin/hubspot_templates`);
    },
  });
};
