import { useBackFn } from '@coa/react-utils';
import React from 'react';
import { matchPath, Route, Switch, useHistory, useLocation } from 'react-router';
import {
  TakeoverModal,
  TakeoverModalBody,
  TakeoverModalCloseButton,
  TakeoverModalContent,
} from '../../../components/compositions/Takeover';
import JoinWorkshopSuccess from '../../../components/JoinWorkshopSuccess';
import { routerPaths } from '../../../routerPaths';
import { getUniversalClassesRouterUrl } from '../../../routerPaths/classesRouterPaths';
import { JoinWorkshopView } from './JoinWorkshopView';
import { JoinWorkshopCompleteProfileView } from './profile/JoinWorkshopCompleteProfileView';

function JoinWorkshopRoutes(): JSX.Element {
  return (
    <Switch>
      <Route
        path={routerPaths.classes.classJoinCompleteProfile}
        component={JoinWorkshopCompleteProfileView}
      />
      <Route path={routerPaths.classes.classJoin} component={JoinWorkshopView} />
      <Route path={routerPaths.classes.classJoinSuccess} component={JoinWorkshopSuccess} />
      <Route
        path={routerPaths.classes.dropInJoinCompleteProfile}
        component={JoinWorkshopCompleteProfileView}
      />
      <Route path={routerPaths.classes.dropInJoin} component={JoinWorkshopView} />
      <Route path={routerPaths.classes.dropInJoinSuccess} component={JoinWorkshopSuccess} />
    </Switch>
  );
}

export function JoinWorkshopModal({
  id,
  dropInWorkshopOccurrenceId,
}: {
  id: string;
  dropInWorkshopOccurrenceId?: string;
}) {
  const location = useLocation();
  const history = useHistory();
  const backRouterUrl = getUniversalClassesRouterUrl.details({
    id,
    dropInWorkshopOccurrenceId,
  });
  const { backFn } = useBackFn({
    path: backRouterUrl,
    exact: true,
  });

  return (
    <TakeoverModal
      isOpen={Boolean(
        matchPath(location.pathname, {
          path: [
            routerPaths.classes.classJoin,
            routerPaths.classes.classJoinSuccess,
            routerPaths.classes.dropInJoin,
            routerPaths.classes.dropInJoinSuccess,
            routerPaths.classes.classJoinCompleteProfile,
            routerPaths.classes.dropInJoinCompleteProfile,

            // TODO: Remove these
            '/classes/:id/application',
            '/classes/:id/drop-ins/:workshopOccurrenceId/application',
          ],
          exact: true,
        })
      )}
      onClose={() => {
        /*
         * If the customer is on the /thanks route, then
         * they're done so we can send them away.
         */
        if (
          matchPath(location.pathname, {
            path: [routerPaths.classes.classJoinSuccess, routerPaths.classes.dropInJoinSuccess],
            exact: true,
          })
        ) {
          history.push('/classes');
          return;
        }

        backFn();
      }}
    >
      <TakeoverModalContent>
        <TakeoverModalBody>
          <TakeoverModalCloseButton data-cy="join-workshop-close" alignMobile="right" />
          <JoinWorkshopRoutes />
        </TakeoverModalBody>
      </TakeoverModalContent>
    </TakeoverModal>
  );
}
