import { ValueOf } from '@coa/types';
import _ from 'lodash';

/*
 * Exercise Step
 * =============================================
 */

const exerciseEntryStatus = {
  locked: 'locked',
  active: 'active',
  complete: 'complete',
} as const;

export const exerciseStepStatus = {
  ...exerciseEntryStatus,
} as const;

export type ExerciseStepStatus = ValueOf<typeof exerciseStepStatus>;

const exerciseStepQuestionKindWithOptions = {
  checkbox: 'checkbox',
  radio: 'radio',
} as const;

export const exerciseStepQuestionKindWithoutAnswer = {
  instruction: 'instruction',
  weeklyRepRecap: 'weekly_rep_recap',
} as const;

export const exerciseStepQuestionKindWithoutOptions = {
  ...exerciseStepQuestionKindWithoutAnswer,
  longText: 'long_text',
} as const;

export const exerciseStepQuestionKind = {
  ...exerciseStepQuestionKindWithOptions,
  ...exerciseStepQuestionKindWithoutOptions,
} as const;

export type ExerciseStepQuestionId = string;
export type ExerciseStepQuestionKind = ValueOf<typeof exerciseStepQuestionKind>;
export type ExerciseStepQuestionKindWithOptions = ValueOf<
  typeof exerciseStepQuestionKindWithOptions
>;

export type ExerciseStepQuestionOption = {
  label: string;
  subLabel?: string;
  // Points to additional questions in form_question_sequence
  // should be rendered under the option.
  followupQuestions?: ExerciseStepQuestionId[];
};

export type ExerciseStepQuestionBase = {
  kind: ExerciseStepQuestionKind;
  label?: string;
};

export type ExerciseStepQuestion<
  K extends ExerciseStepQuestionKind = ExerciseStepQuestionKind
> = K extends ExerciseStepQuestionKindWithOptions
  ? ExerciseStepQuestionBase & {
      options: ExerciseStepQuestionOption[];
      answer?: string[];
      placeholder?: never;
    }
  : ExerciseStepQuestionBase & {
      answer?: string;
      // For now this assumes that everything that doesn't
      // have options will be text-input like. Should be
      // safe in the short term.
      placeholder?: string;
      options?: never;
    };

export type ExerciseStep = {
  id: string;
  title: string;
  status: ExerciseStepStatus;
  headline: string;
  formQuestionSequence: ExerciseStepQuestionId[];
  formQuestions: {
    // Mapping of QuestionId to question content
    [s: string]: ExerciseStepQuestion;
  };
  weeklyRep: boolean;
};

export type ExerciseStepAnswer = ExerciseStepQuestion['answer'];

// Type guards

export const getIsExerciseStepQuestionWithOptions = (
  question: ExerciseStepQuestion
): question is ExerciseStepQuestion<ExerciseStepQuestionKindWithOptions> =>
  !_.isEmpty(exerciseStepQuestionKindWithOptions[question.kind]);

/*
 * Exercise
 * =============================================
 */

export const exerciseStatus = {
  ...exerciseEntryStatus,
} as const;

export type ExerciseStatus = ValueOf<typeof exerciseStatus>;

export type Exercise = {
  id: string;
  title: string;
  status: ExerciseStatus;
  workshopOccurrenceId: string;
  skillLabel: string;
};
