import { Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { ISubmitEvent } from '@rjsf/core';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useMutationWithTimer } from '../../../../lib/react-query';
import { AdminCmsForm } from './AdminCmsForm';
import { AdminCmsLayout } from './AdminCmsLayout';
import {
  BaseCmsFormData,
  BaseResource,
  BaseSerializedResource,
  useCmsContext,
} from './useAdminCmsContext';
import {
  AdminCmsRouterViewStateContextProvider,
  useAdminCmsViewStateContext,
} from './useAdminCmsViewStateContext';

export const AdminCmsShow = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => {
  const {
    useShowQuery,
    formSchema,
    formUiSchema,
    useUpdateMutation,
    getEntryTitle,
    formatSerializedResourceToCmsFormData,
    parseCmsFormDataToSerializedResource,
  } = useCmsContext<Resource, SerializedResource, CmsFormData>();

  const toast = useToast();

  const { cmsViewState } = useAdminCmsViewStateContext();
  const { id } = cmsViewState.meta;

  const showQuery = useShowQuery({ id });
  const rawUpdateMutation = useUpdateMutation({ id });
  const updateMutation = useMutationWithTimer(rawUpdateMutation, {
    duration: 500,
  });
  // Cache form data so that, in the event of an error, the form
  // doesn't reset and we can pick up where we left off.
  const [unsavedFormData, setUnsavedFormData] = useState<CmsFormData>();

  const handleSubmit = useCallback(
    async ({ formData: rawFormData }: ISubmitEvent<CmsFormData>) => {
      const unfilteredFormData = parseCmsFormDataToSerializedResource(rawFormData);
      /*
       * Filter out form-data that hasn't changed. We do this to limit the blast
       * radius of our updates, generally. In particular, though, this is helpful
       * particularly because a serialized attachment (i.e. Instructor['headshot'])
       * explicitly should not be updated.
       */
      const formData = (_.pickBy(
        unfilteredFormData,
        (_val, key) => !_.isEqual(unfilteredFormData[key], showQuery.data[key])
      ) as unknown) as Partial<typeof unfilteredFormData>; // Cast to preserve the type across lodash

      setUnsavedFormData(rawFormData);
      try {
        const updateResult = await updateMutation.mutateAsyncWithTimer({ ...formData, id });
        toast({
          title: `Successfully updated entry: ${getEntryTitle(
            /*
             * See note on shape discrepancies above.
             */
            updateResult
          )}`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } catch (err) {
        const { errors } = err.response.data;
        // if there are multiple errors
        const errorMessages = Object.values(errors).join('\n');
        toast({
          title: errorMessages,
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }
    },
    [showQuery.data]
  );

  if (showQuery.isLoading) return null;

  return (
    <>
      <AdminCmsForm<CmsFormData>
        schema={formSchema}
        uiSchema={formUiSchema}
        formData={unsavedFormData || formatSerializedResourceToCmsFormData(showQuery.data)}
        onSubmit={handleSubmit}
      />
      {updateMutation.isLoadingWithTimer ? (
        <Flex mt={4} alignItems="center">
          <Text>Saving...</Text>
          <Spinner size="sm" ml={2} />
        </Flex>
      ) : null}
    </>
  );
};

export const AdminCmsShowView = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => (
  <AdminCmsRouterViewStateContextProvider<SerializedResource>>
    <AdminCmsLayout>
      <AdminCmsShow<Resource, SerializedResource, CmsFormData> />
    </AdminCmsLayout>
  </AdminCmsRouterViewStateContextProvider>
);
