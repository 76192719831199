import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { EmptyObject, UnpackArray } from '@coa/types';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../../lib/react-query';
import { useDeleteTeamWorkshopsMutation, useGetTeamQuery } from '../../../../resources/teams';
import { GetTeam, GetTeamWorkshops } from '../../../../resources/teams/apis/types';

export const RemoveTeamWorkshopModal = ({
  isOpen,
  onClose,
  workshop = {},
  id,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & {
  id: string;
  workshop?: UnpackArray<GetTeamWorkshops.Response['data']> | EmptyObject;
}) => {
  const rawDeleteTeamWorkshopsMutation = useDeleteTeamWorkshopsMutation({
    id,
    workshopId: workshop.id,
  });
  const deleteTeamWorkshopsMutation = useMutationWithTimer(rawDeleteTeamWorkshopsMutation, {
    duration: 500,
  });
  const { isLoadingWithTimer } = deleteTeamWorkshopsMutation;
  const teamQuery = useGetTeamQuery({ id });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    // If the user re-launches, the state will be clean.
    deleteTeamWorkshopsMutation.resetWithTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: { attributes: team = {} as GetTeam.Response['data']['attributes'] } = {} } =
    teamQuery.data || {};

  if (teamQuery.isLoading || _.isEmpty(workshop)) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" variant="coa-main">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Remove Workshop</Heading>
        </ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <ModalBody>
          <VStack spacing={2} alignItems="flex-start">
            <Text>
              Are you sure you wish to remove <strong>{workshop.attributes.title}</strong> from{' '}
              <strong>{team.title}</strong>?
            </Text>
            <Text>Doing so will un-enroll members of {team.title} from this class.</Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              isLoading={isLoadingWithTimer}
              onClick={async () => {
                try {
                  await deleteTeamWorkshopsMutation.mutateAsyncWithTimer();
                  handleClose();
                  toast({
                    title: 'Success!',
                    description: `You have successfully removed the class from ${team.title}.`,
                    duration: 2000,
                    isClosable: true,
                    status: 'success',
                  });
                } catch (err) {
                  toast({
                    title: 'Something went wrong.',
                    description: 'Please try again.',
                    duration: 2000,
                    isClosable: true,
                    status: 'error',
                  });
                }
              }}
            >
              Remove Class
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
