import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from './types';

export * from './configureStore';
export * from './types';

/*
 * Typed versions of `useSelector` and `useDispatch` that allow
 * for safe use without typing arguments throughout the app.
 *
 * @see https://redux.js.org/recipes/usage-with-typescript
 */
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;
/*
 * TODO: Obviously this is not typed right now. We're setting
 * up this wrapper to allow for easier future-proofing.
 */
export const useAppDispatch = useDispatch;
