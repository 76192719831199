import path from 'path';
import { UseQueryResult } from 'react-query';
import { CoaAppWindow } from '../../../lib/web';
import {
  GetPushUpWorkshops,
  GetQAndAWorkshops,
  TryWorkshopType,
  Workshop,
} from '../../../resources/workshops';
import { getUniversalTryRouterUrl } from '../../../routerPaths/tryRouterPaths';

export type TryWorkshopResponse = GetPushUpWorkshops.Response | GetQAndAWorkshops.Response;

export type TryWorkshopContextProps = {
  tryWorkshopQuery: UseQueryResult<TryWorkshopResponse, unknown>;
  selectedTryWorkshopId?: string;
  setSelectedTryWorkshopId?: React.Dispatch<React.SetStateAction<string>>;
  enrolledInSelectedWorkshop?: boolean;
};
export function getTryWorkshopInfoFromUrlParam(urlParam: string) {
  const paramsArr = urlParam.split('-');
  return {
    title: paramsArr.slice(0, -1).join(' '),
    id: paramsArr.slice(-1)[0],
  };
}

export function getTryWorkshopDisplayNameFromType(
  type: TryWorkshopType,
  params = { pluralize: false }
) {
  const { pluralize } = params;
  switch (type) {
    case 'pushups':
      return pluralize ? 'Push-Ups' : 'Push-Up';
    case 'q_and_a':
      return pluralize ? "Q & A's" : 'Q & A';
    default:
      return null;
  }
}

export function createTryWorkshopURL(workshop: Workshop) {
  const { title, id } = workshop;
  const titleArr = title.split(' ');
  const formattedTitle = titleArr
    .map((titleWord) => titleWord.replace(/[^a-z0-9]/gi, '').toLowerCase())
    .filter(Boolean);
  const { BASE_URL } = (window as unknown) as CoaAppWindow;
  const workshopSlug = [...formattedTitle, id].join('-');
  const viewUrl = getUniversalTryRouterUrl.qAndA({ workshopSlug });
  return path.join(BASE_URL, viewUrl);
}
