import { useRouteParams } from '@coa/react-utils';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { UseQueryResult } from 'react-query';
import { useSession } from '../../../../resources/sessions';
import {
  GetMyWorkshops,
  GetQAndAWorkshops,
  GetWorkshop,
  useGetWorkshopNoAuthQuery,
  useMyWorkshopsQuery,
  useQAndAWorkshopsQuery,
  Workshop,
} from '../../../../resources/workshops';
import { getTryWorkshopInfoFromUrlParam } from '../../utils';

/*
 * This sort should happen on the backend, but for convenience
 * we manage it on the frontend for now.
 */
function sortQAndAs(qAndAs: GetQAndAWorkshops.Response) {
  return _.sortBy(qAndAs, (qAndA) => {
    const firstOccurrence = qAndA.workshopOccurrences[0];
    // Transform to Unix Timestamp so we can use arithmetic comparison.
    return dayjs(firstOccurrence.startsAt).unix();
  });
}

function getIsEnrolledInSelectedQAndA(
  workshops: GetMyWorkshops.Response,
  selectedQAndAId: Workshop['id']
) {
  return Boolean(workshops.find(({ id }) => id === selectedQAndAId));
}

type SelectedQAndALandingContext = {
  qAndAQuery: UseQueryResult<GetQAndAWorkshops.Response, unknown>;
  selectedQAndA: Workshop;
  setSelectedQAndAId: Dispatch<SetStateAction<string>>;
  enrolledInSelectedQAndA: boolean;
};

type SelectedQAndAInstanceContext = {
  qAndAQuery: UseQueryResult<GetWorkshop.Response, unknown>;
  selectedQAndA: Workshop;
  setSelectedQAndAId: Dispatch<SetStateAction<string>>;
  enrolledInSelectedQAndA: boolean;
};

export const SelectedQAndALandingContext = React.createContext<SelectedQAndALandingContext | null>(
  null
);
export const SelectedQAndAInstanceContext = React.createContext<SelectedQAndAInstanceContext | null>(
  null
);

export const SelectedQAndALandingProvider = ({ children }: { children: React.ReactNode }) => {
  const { loggedIn } = useSession();
  const unsortedQAndAQuery = useQAndAWorkshopsQuery();
  const [rawSelectedQAndAId, setSelectedQAndAId] = React.useState<string>();
  const myWorkshopsQuery = useMyWorkshopsQuery({ enabled: loggedIn });

  const qAndAQuery = {
    ...unsortedQAndAQuery,
    // For now we limit the number of q-and-a's to 4 while we
    // investigate scrolling issues on the Pushups LP.
    // @see https://linear.app/coa/issue/COA-717
    data: sortQAndAs(unsortedQAndAQuery.data).slice(0, 4),
  } as UseQueryResult<GetQAndAWorkshops.Response, unknown>;

  const { data: qAndAs } = qAndAQuery;
  const { data: myWorkshops = [] } = myWorkshopsQuery;

  const selectedQAndAId = rawSelectedQAndAId || qAndAs?.[0]?.id;
  const selectedQAndA = qAndAs.find((qAndA) => qAndA.id === selectedQAndAId);
  const enrolledInSelectedQAndA = getIsEnrolledInSelectedQAndA(myWorkshops, selectedQAndAId);

  return (
    <SelectedQAndALandingContext.Provider
      value={{ qAndAQuery, selectedQAndA, setSelectedQAndAId, enrolledInSelectedQAndA }}
    >
      {children}
    </SelectedQAndALandingContext.Provider>
  );
};

export const SelectedQAndAInstanceProvider = ({ children }: { children: React.ReactNode }) => {
  const { loggedIn } = useSession();

  const { workshopSlug } = useRouteParams<{ workshopSlug: string }>();

  const { id } = getTryWorkshopInfoFromUrlParam(workshopSlug);
  const unsortedQAndAQuery = useGetWorkshopNoAuthQuery({ id });
  // TODO: clean this up
  const [rawSelectedQAndAId, setSelectedQAndAId] = React.useState<string>(id);
  const myWorkshopsQuery = useMyWorkshopsQuery({ enabled: loggedIn });
  const qAndAQuery = {
    ...unsortedQAndAQuery,
    // For now we limit the number of q-and-a's to 4 while we
    // investigate scrolling issues on the Pushups LP.
    // @see https://linear.app/coa/issue/COA-717
    // data: sortQAndAs(unsortedQAndAQuery.data).slice(0, 4),
  } as UseQueryResult<GetWorkshop.Response, unknown>;

  const { data: qAndAs } = qAndAQuery;
  const { data: myWorkshops = [] } = myWorkshopsQuery;

  const selectedQAndAId = rawSelectedQAndAId || qAndAs?.[0]?.id;
  // const selectedQAndA = qAndAs.find((qAndA) => qAndA.id === selectedQAndAId);
  const enrolledInSelectedQAndA = getIsEnrolledInSelectedQAndA(myWorkshops, selectedQAndAId);

  return (
    <SelectedQAndAInstanceContext.Provider
      value={{ qAndAQuery, selectedQAndA: qAndAs, setSelectedQAndAId, enrolledInSelectedQAndA }}
    >
      {children}
    </SelectedQAndAInstanceContext.Provider>
  );
};

export const useSelectedQAndALanding = () => useContext(SelectedQAndALandingContext);
export const useSelectedQAndAInstance = () => useContext(SelectedQAndAInstanceContext);
