import _ from 'lodash';
import {
  THERAPISTS_LIST_FAILURE,
  THERAPISTS_LIST_REQUEST,
  THERAPISTS_LIST_SUCCESS,
} from '../../actions/admin/therapists';
import { WORKSHOPS_LIST_SUCCESS } from '../../actions/admin/workshops';

// type ReducerShape = {
//   gettingTherapists: ?boolean,
//   therapists: {
//     [id]: Therapist,
//   },
// }

const DEFAULT_STATE = {
  gettingTherapists: null,
  therapists: {},
};

export function therapists(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case THERAPISTS_LIST_REQUEST: {
      return { ...state, gettingTherapists: true };
    }
    case THERAPISTS_LIST_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, therapists: _.keyBy(data, 'id'), gettingTherapists: false };
    }
    case THERAPISTS_LIST_FAILURE: {
      return { ...state, gettingTherapists: false };
    }
    case WORKSHOPS_LIST_SUCCESS: {
      const {
        response: { included },
      } = action;
      const includedTherapists = _.chain(included)
        .filter((x) => x.type === 'therapist')
        .keyBy('id')
        .value();
      return { ...state, ...includedTherapists };
    }
    default:
      return state;
  }
}
