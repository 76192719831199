import {
  Button,
  Center,
  CircularProgress,
  Heading,
  Image,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FormikEffect, FormikEffectValuesDiff } from '@coa/formik-utils';
import { useBackFn, useQueryParams } from '@coa/react-utils';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { ArrowLeftIcon } from '../../components/Icons';
import { SplitIllustrationLayout } from '../../components/SplitIllustrationLayout';
import backgroundImageSrc from '../../images/background.png';
import {
  ExerciseStepQuestionKind,
  exerciseStepQuestionKindWithoutAnswer,
  exerciseStepQuestionKindWithoutOptions,
  GetWorkshopExerciseSteps,
  useExerciseAnalytics,
  useWorkshopExercisesQuery,
  useWorkshopExerciseStepAnswersMutation,
  useWorkshopExerciseStepsQuery,
} from '../../resources/exercises';
import { ExerciseFormCtas } from './components/ExerciseFormCtas';
import { FormQuestion, FormQuestionContext } from './components/ExerciseFormQuestion';
import {
  ExerciseLayoutFooter,
  ExerciseLayoutHeader,
  ExerciseLayoutParent,
  ExerciseLayoutScrollableContent,
} from './components/ExerciseLayout';
import {
  ClassPrepCompleteModal,
  ExerciseCompleteModal,
  StepLockedModal,
} from './components/ExerciseModals';
import { ExerciseNav } from './components/ExerciseNav';
import { formatQuestionId, getTrackExerciseEventParams } from './utils';

type MatchParams = {
  id: string; // workshopId
  exerciseId: string;
  stepId?: string;
};

type ExerciseViewProps = RouteComponentProps<MatchParams>;

const useWorkshopExerciseAttributes = ({ workshopId, exerciseId }) => {
  const exercisesQuery = useWorkshopExercisesQuery({ id: workshopId });

  if (!exercisesQuery.data) return { isLoading: true };

  const exercise =
    exercisesQuery.data?.data.find(({ id }) => id === exerciseId) ||
    ({} as GetWorkshopExerciseSteps.Response['data'][number]);
  return {
    exerciseTitle: exercise?.attributes?.title,
    exerciseStatus: exercise?.attributes?.status,
  };
};

const exerciseStepQuestionKindsWithoutAnswer: ExerciseStepQuestionKind[] = Object.values(
  exerciseStepQuestionKindWithoutAnswer
);
const exerciseStepQuestionKindsWithoutOptions: ExerciseStepQuestionKind[] = Object.values(
  exerciseStepQuestionKindWithoutOptions
);

const getDefaultAnswerForQuestionByKind = (kind: ExerciseStepQuestionKind) => {
  /*
   * If a question is of kind "instruction", we pass an answer as there is no
   * user interaction necessary. We maintain the "forminess" of this question
   * so that we can still track whether or not it is complete.
   */
  if (exerciseStepQuestionKindsWithoutAnswer.includes(kind)) return 'complete';
  return exerciseStepQuestionKindsWithoutOptions.includes(kind) ? '' : [];
};

const useWorkshopExerciseStepAttributes = ({
  workshopId,
  exerciseId,
  stepId: rawStepId,
}: {
  workshopId: string;
  exerciseId: string;
  stepId?: string;
}) => {
  const stepsQuery = useWorkshopExerciseStepsQuery({ id: workshopId, exerciseId });
  if (!stepsQuery.data) return { isLoading: true };

  const steps = stepsQuery.data?.data || [];
  const currentStepId = rawStepId || steps?.[0]?.id;
  const currentStepIndex = steps.findIndex(({ id }) => id === currentStepId);
  const currentStep = steps?.[currentStepIndex];

  const { id: nextStepId, attributes: { status: nextStepStatus = null } = {} } =
    steps?.[currentStepIndex + 1] || {};
  const { id: prevStepId, attributes: { status: prevStepStatus = null } = {} } =
    steps?.[currentStepIndex - 1] || {};
  const {
    attributes: {
      headline,
      formQuestionSequence,
      weeklyRep,
      formQuestions,
      status: currentStepStatus,
    },
  } = currentStep;

  /*
   * We run _.pickBy here to clean up orphaned answers in the event that nested
   * questions have been removed from the form.
   */
  const currentStepAnswers = _.pickBy(
    _.mapValues(
      formQuestions,
      ({ answer, kind }) => answer || getDefaultAnswerForQuestionByKind(kind)
    )
  );

  return {
    steps,
    currentStepId,
    currentStepIndex,
    currentStepAnswers,
    currentStepStatus,
    currentStep,
    nextStepId,
    nextStepStatus,
    prevStepId,
    prevStepStatus,
    headline,
    formQuestionSequence,
    formQuestions,
    weeklyRep,
  };
};

export const ExerciseView = ({ match }: ExerciseViewProps): JSX.Element => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { stepId: stepIdFromPath, exerciseId, id: workshopId } = match.params;

  const {
    exerciseTitle,
    exerciseStatus,
    isLoading: isWorkshopExerciseLoading,
  } = useWorkshopExerciseAttributes({
    workshopId,
    exerciseId,
  });
  const {
    isLoading: isWorkshopExerciseStepLoading,
    steps,
    currentStepId,
    currentStepAnswers,
    currentStepStatus,
    nextStepId,
    nextStepStatus,
    prevStepId,
    headline,
    formQuestionSequence,
    formQuestions,
    weeklyRep,
  } = useWorkshopExerciseStepAttributes({ workshopId, exerciseId, stepId: stepIdFromPath });

  const exerciseStepAnswersMutation = useWorkshopExerciseStepAnswersMutation({
    id: workshopId,
    exerciseId,
    stepId: currentStepId,
  });

  const { backFn } = useBackFn({
    path: `/classes/${workshopId}/exercises/${exerciseId}/steps/${prevStepId}`,
    exact: true,
  });

  const {
    onClose: onCloseExerciseCompleteModal,
    isOpen: isExerciseCompleteModalOpen,
    onOpen: onOpenExerciseCompleteModal,
  } = useDisclosure();

  const {
    onClose: onCloseClassPrepCompleteModal,
    isOpen: isClassPrepCompleteModalOpen,
    onOpen: onOpenClassPrepCompleteModal,
  } = useDisclosure();

  const {
    trackExerciseAnswer,
    trackCompleteExerciseStep,
    trackCompleteExercise,
    trackExerciseStepView,
  } = useExerciseAnalytics({ stepId: currentStepId, exerciseId, workshopId });

  // TODO: Refine this loading check.
  // TODO: Alternatively, don't even have the modal launch until the
  // exercise data is present to consolidate the loading logic on the
  // previous screen.
  const isLoading = isWorkshopExerciseLoading || isWorkshopExerciseStepLoading;

  const isStepLocked = currentStepStatus === 'locked';
  const isExerciseLocked = exerciseStatus === 'locked';

  useEffect(() => {
    trackExerciseStepView();
    /*
     * This tracker is dependent on these two values but also
     * others and we don't wish for it to change every time.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId, currentStepId]);

  return (
    <>
      <SplitIllustrationLayout
        backgroundComponent={
          <Image src={backgroundImageSrc} height="100%" width="100%" objectFit="cover" />
        }
      >
        <>
          <Formik
            // Specify a key to ensure that the Formik instance is re-initialized on route change for a new Step.
            key={currentStepId}
            initialValues={currentStepAnswers}
            onSubmit={(values, { resetForm }) => {
              if (!_.isEmpty(values)) {
                exerciseStepAnswersMutation.mutate({ answers: values });
              }
              trackCompleteExerciseStep();

              if (nextStepId) {
                if (nextStepStatus !== 'locked') {
                  history.push(
                    `/classes/${workshopId}/exercises/${exerciseId}/steps/${nextStepId}`
                  );
                } else {
                  /*
                   * The form loses its validation state when it is set to
                   * submitting, but because this part of the control flow
                   * returns the user back to the form, we need to retain
                   * this validation state. We reset the form to do so.
                   */
                  resetForm({ values });
                  onOpenClassPrepCompleteModal();
                }
              } else {
                // See comment on similar control flow above.
                resetForm({ values });
                onOpenExerciseCompleteModal();
                trackCompleteExercise();
              }
            }}
          >
            <ExerciseLayoutParent as={Form}>
              {isLoading ? (
                <Center>
                  <CircularProgress isIndeterminate color="green.900" mt={16} />
                </Center>
              ) : (
                <>
                  <FormikEffect
                    onValuesChange={({ values, prevValues }: FormikEffectValuesDiff) => {
                      /*
                       * Save the new answers. Note that we don't omit empties
                       * here because the server currently does not accept an
                       * empty answer set, so we persist the empty answers so
                       * that we can save an "undo" actions (e.g. user empties
                       * a text-field).
                       */
                      exerciseStepAnswersMutation.mutate({ answers: values });

                      // Format and fire the answer event.
                      const params = getTrackExerciseEventParams({
                        values,
                        prevValues,
                        formQuestions,
                      });
                      if (params) trackExerciseAnswer(params);
                    }}
                  />
                  <ExerciseLayoutHeader>
                    <Heading
                      size="xl"
                      align="center"
                      pt={{ base: 6, md: 10 }}
                      mb={{ base: 4, md: 8 }}
                      px={{
                        /*
                         * Horizontal padding applied because this title can other-wise
                         * be hidden by the Takeover close.
                         * @see https://linear.app/coa/issue/COA-257
                         */
                        base: 16, // 64px
                        md: 0,
                      }}
                    >
                      {exerciseTitle}
                    </Heading>
                    <ExerciseNav
                      steps={steps}
                      workshopId={workshopId}
                      exerciseId={exerciseId}
                      currentStepId={currentStepId}
                    />
                  </ExerciseLayoutHeader>
                  <ExerciseLayoutScrollableContent px={{ base: 4, md: 0 }} py={{ base: 4, md: 8 }}>
                    <Text fontSize="2xl" mb={{ base: 4, md: 8 }}>
                      {headline}
                    </Text>
                    {
                      /*
                       * To indicate that we're coming from a Recap exercise, we route
                       * to this view with the "context" query param so that we can
                       * render accordingly.
                       *
                       * This is fragile since we can't 100% guarantee that history.goBack
                       * will take us where we want to go.
                       */
                      queryParams.get('context') === 'recap' ? (
                        <Button
                          variant="primary"
                          leftIcon={<ArrowLeftIcon />}
                          onClick={history.goBack}
                          size="sm"
                          mt={-4}
                          mb={4}
                        >
                          My Reflection
                        </Button>
                      ) : null
                    }
                    <>
                      {/* TODO: We shouldn't need this context as we should be able */}
                      {/* to just pull the data directly from the query cache. */}
                      <FormQuestionContext.Provider value={{ formQuestions }}>
                        <VStack spacing={4} alignItems="start">
                          {formQuestionSequence.map((rawQuestionId) => {
                            const questionId = formatQuestionId(rawQuestionId);
                            return (
                              // We only specify questionId here rather than spreading the entire
                              // question so that we don't require drilling to provide props to
                              // nested questions.
                              <FormQuestion key={questionId} questionId={questionId} />
                            );
                          })}
                        </VStack>
                      </FormQuestionContext.Provider>
                    </>
                  </ExerciseLayoutScrollableContent>
                </>
              )}
              <ExerciseLayoutFooter>
                <ExerciseFormCtas
                  prevStepId={prevStepId}
                  backFn={backFn}
                  currentStepStatus={currentStepStatus}
                  nextStepId={nextStepId}
                  nextStepStatus={nextStepStatus}
                  weeklyRep={weeklyRep}
                />
              </ExerciseLayoutFooter>
            </ExerciseLayoutParent>
          </Formik>
        </>
      </SplitIllustrationLayout>
      <ExerciseCompleteModal
        workshopId={workshopId}
        isOpen={isExerciseCompleteModalOpen}
        onClose={onCloseExerciseCompleteModal}
      />
      <ClassPrepCompleteModal
        workshopId={workshopId}
        isOpen={isClassPrepCompleteModalOpen}
        onClose={onCloseClassPrepCompleteModal}
      />
      <StepLockedModal isOpen={isStepLocked || isExerciseLocked} />
    </>
  );
};
