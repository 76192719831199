import { useGetTeamMembershipsQuery } from '@coa/api/controllers/v1/teamMemberships';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { useSession } from '../../../../resources/sessions';

export function useTeamsPathnameBase() {
  const { pathname } = useLocation();
  const [pathnameBase] = _.filter(pathname.split('/'));
  return { pathnameBase };
}

export function useTeamsRole({
  id,
}: {
  id: string;
}): { isTeamAdmin: boolean; isCoaAdmin: boolean } {
  const getTeamMembershipsQuery = useGetTeamMembershipsQuery();
  const { role: sessionRole } = useSession();

  const isCoaAdmin = sessionRole === 'admin';

  if (isCoaAdmin || getTeamMembershipsQuery.isLoading) {
    return { isCoaAdmin, isTeamAdmin: isCoaAdmin };
  }

  const { data: memberships } = getTeamMembershipsQuery;

  const membership = memberships?.find(
    ({ team: { id: membershipTeamId } }) => membershipTeamId === id
  );

  const isTeamAdmin = membership?.role === 'admin';
  return { isTeamAdmin, isCoaAdmin };
}
