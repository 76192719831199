import { AnyObject, IsoTime, ValueOf } from '@coa/types';
import { EnrollmentStatus } from '../enrollments';
import { Therapist } from '../therapists';

export const workshopKind = {
  series: 'series',
  intro: 'intro',
  advancedSeries: 'advanced_series',
  qAndA: 'q_and_a',
} as const;

export const workshopStatus = {
  archived: 'archived',
  hidden: 'hidden',
  visible: 'visible',
  exclusive: 'exclusive',
  // deprecated_sold_out: deprecated_sold_out
} as const;

export const workshopAccess = {
  everyone: 'everyone',
  members: 'members',
};

export type WorkshopStatus = ValueOf<typeof workshopStatus>;
export type WorkshopKind = ValueOf<typeof workshopKind>;
export type WorkshopAccess = ValueOf<typeof workshopAccess>;

export const workshopFormQuestionKinds = {
  shortText: 'short_text',
  text: 'text',
  checkbox: 'checkbox',
  radio: 'radio',
} as const;

export type WorkshopFormQuestionKind = ValueOf<typeof workshopFormQuestionKinds>;

export type WorkshopFormQuestion = {
  key: string;
  kind: WorkshopFormQuestionKind;
  question: string;
  placeholder?: string;
  options: Array<{ label: string }>;
};

export type WorkshopMeta = {
  // It's not quite clear why joinUrl is exposed both
  // in Workshop & via meta, but we have it here so
  // it's worth indicating!
  joinUrl: Workshop['joinUrl'] | null;
  enrollmentStatus: EnrollmentStatus | null;
  enrollmentDropInIds: WorkshopEnrollmentDropInIds | null;
};

export type WorkshopEnrollmentDropInIds = Record<WorkshopOccurrence['id'], true>;

// TODO: Rename this to "Workshop" and rename the existing "Workshop"
// to "LegacySnakeWorkshop" or something. Camel should be considered
// the default.
export type Workshop<K extends WorkshopKind = WorkshopKind> = {
  access: WorkshopAccess;
  approvedEnrolleeCount: number;
  autoApprove: boolean;
  category: string;
  description: string;
  featured: boolean;
  isB2B: boolean;
  hasSoldOutSiblings: boolean;
  id: string;
  imageUrl: string;
  joinUrl: string;
  kind: K;
  marketListingLabel?: string;
  meta?: WorkshopMeta;
  minParticipants: number;
  maxParticipants: number;
  priceCents: number;
  questions: WorkshopFormQuestion[];
  soldOut: boolean;
  seriesTimingLabel: string;
  shortBlurb?: string;
  status: WorkshopStatus;
  therapist?: Therapist;
  title: string;
  requiresMembership: boolean;

  // TODO: These are artifacts from before we deserialized JSON-API responses.
  // We should consider cleaning it up.
  workshopOccurrences: WorkshopOccurrence[];
  type: 'workshop';
};

export type WorkshopOccurrence = {
  description: string;
  durationInMinutes: number;
  startsAt: IsoTime;
  title: string;
  workshopId: Workshop['id'];
  exerciseId: string;
  id: string;
  position: number;
  maxPosition: number;
  skill: WorkshopSkills;

  // TODO: This is an artifact from before we deserialized JSON-API responses.
  // We should consider cleaning it up.
  type: 'workshop_occurrence';
};

export type WorkshopTemplate = {
  id: string;

  // TODO: This is an artifact from before we deserialized JSON-API responses.
  // We should consider cleaning it up.
  type: 'workshop_template';
};

// Promo codes
export const workshopPromoStatus = {
  none: 'none',
  expired: 'expired',
  applied: 'applied',
  invalid: 'invalid',
} as const;
export type WorkshopPromoStatus = ValueOf<typeof workshopPromoStatus>;

export const workshopTimings = {
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
} as const;

export type WorkshopTimings = ValueOf<typeof workshopTimings>;
export const workshopDays = {
  sunday: 'sunday',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
} as const;
export type WorkshopDays = ValueOf<typeof workshopDays>;

export const workshopTopics = {
  mentalHealth: 'mental_health',
  impostorSyndrome: 'impostor_syndrome',
  leadership: 'leadership',
  restaurantMentalHealth: 'restaurant_mental_health',
  romanticRelationships: 'romantic_relationships',
  burnout: 'burnout',
  femaleLeadership: 'female_leadership',
  jobSeeking: 'job_seeking',
  friendships: 'friendships',
  procrastination: 'procrastination',
  bipoc: 'bipoc',
} as const;
export type WorkshopTopics = ValueOf<typeof workshopTopics>;

type WorkshopTopicToWorkshopTopicLabel = {
  [topic in WorkshopTopics]: string;
};

export const workshopTopicLabels: WorkshopTopicToWorkshopTopicLabel = {
  mental_health: 'For Mental Wellness',
  impostor_syndrome: 'For Impostor Syndrome',
  leadership: 'For Leaders',
  restaurant_mental_health: 'For Restaurant Workers',
  romantic_relationships: 'For Romantic Relationships',
  burnout: 'For Burnout',
  female_leadership: 'For Female Leaders',
  job_seeking: 'For Job Seekers',
  friendships: 'For Friendships',
  procrastination: 'For Procrastination',
  bipoc: 'For BIPOC',
} as const;

export const workshopSkills = {
  selfAwareness: 'self_awareness',
  empathy: 'empathy',
  mindfulness: 'mindfulness',
  curiosity: 'curiosity',
  play: 'play',
  communication: 'communication',
  resilience: 'resilience',
} as const;
export type WorkshopSkills = ValueOf<typeof workshopSkills>;

export type WorkshopFilter = {
  timings?: Array<WorkshopTimings>;
  topics?: Array<WorkshopTopics>;
  skills?: Array<WorkshopSkills>;
  days?: Array<WorkshopDays>;
  timezone_offset?: number;
};

export type WorkshopFilterKind = keyof WorkshopFilter;
export type WorkshopFilterValue = (WorkshopTimings | WorkshopTopics | WorkshopSkills)[];

export type AdminWorkshopFilter = {
  query: string;
  inTheFuture?: boolean;
  notEnded?: boolean;
};

export type TransientWorkshopFactoryAttributes = {
  firstOccStartsAt: WorkshopOccurrence['startsAt'];
};

// TODO: Should this be called TryWorkshopKind?
// I chose 'Type' because currently (4-28-22) pushups are a :category
// and 'q_and_a' is a :kind
export type TryWorkshopType = 'q_and_a' | 'pushups';
/*
 * ***LEGACY*** SNAKE-CASED WORKSHOP TYPES
 * =============================================
 *
 * These types were defined before the `createAxiosClientWithCaseTransform` was
 * made available. Ultimately we should attempt to phase them out, but in the
 * short term we can at least name them defensively.
 */

export type LegacySnakeWorkshop<K extends WorkshopKind = WorkshopKind> = {
  approved_enrollee_count: number;
  enrollment_status: EnrollmentStatus;
  enrollment_drop_in_ids: AnyObject;
  description: string;
  has_sold_out_siblings: boolean;
  image_url: string;
  join_url: string;
  kind: K;
  min_participants: number;
  max_participants: number;
  market_listing_label: string;
  topic: string;
  price_cents: number;
  questions: WorkshopFormQuestion[];
  id: string;
  sold_out: boolean;
  series_timing_label: string;
  short_blurb?: string;
  status: WorkshopStatus;
  title: string;
  type: 'workshop';
};

export type LegacySnakeIntroWorkshop = LegacySnakeWorkshop<'intro'>;
export type LegacySnakeSeriesWorkshop = LegacySnakeWorkshop<'series'>;
export type LegacySnakeAdvancedSeriesWorkshop = LegacySnakeWorkshop<'advanced_series'>;

export type LegacySnakeWorkshopOccurrence = {
  description: string;
  duration_in_minutes: number;
  starts_at: IsoTime;
  title: string;
  workshop_id: LegacySnakeWorkshop['id'];
  exercise_id: string;
  skill: string;
  id: string;
  type: 'workshop_occurrence';
  position: number;
  max_position: number;
};
