import {
  Button,
  ButtonProps,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { getIsInThePast } from '../../../../lib/time';
import { useSession } from '../../../../resources/sessions';
import {
  GetWorkshop,
  useGetWorkshopOccurrenceSiblingsQuery,
  useGetWorkshopQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../../resources/workshops';

export type WorkshopIdProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId: WorkshopOccurrence['id'];
};

const NextWorkshopOccurrenceButton = ({
  dropInWorkshopOccurrenceId,
  ...rest
}: Omit<WorkshopIdProps, 'workshopId'> & ButtonProps) => {
  const getWorkshopOccurrenceSiblingsQuery = useGetWorkshopOccurrenceSiblingsQuery({
    id: dropInWorkshopOccurrenceId,
  });
  if (getWorkshopOccurrenceSiblingsQuery.isLoading) {
    return (
      <Skeleton>
        <Button {...rest}>Next Workshop</Button>
      </Skeleton>
    );
  }

  const { data: workshopOccurrenceSiblings } = getWorkshopOccurrenceSiblingsQuery;
  const nextWorkshopOccurrence = workshopOccurrenceSiblings[0];
  return (
    <Button
      as={RouterLink}
      to={
        nextWorkshopOccurrence
          ? `/classes/${nextWorkshopOccurrence.workshop.id}/drop-ins/${nextWorkshopOccurrence.id}`
          : '/classes/drop-ins'
      }
      {...rest}
    >
      {nextWorkshopOccurrence ? 'See Next Upcoming Class' : 'See More Drop-Ins'}
    </Button>
  );
};

export const WorkshopOccurrenceUnavailableModal = ({
  workshopId,
  dropInWorkshopOccurrenceId,
}: WorkshopIdProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { role } = useSession();

  if (getWorkshopQuery.isLoading || role === 'admin') return null;
  const { data: workshop } = getWorkshopQuery.isLoading
    ? { data: {} as GetWorkshop.Response }
    : getWorkshopQuery;

  const {
    meta: { enrollmentStatus },
    workshopOccurrences,
  } = workshop;

  const dropInWorkshopOccurrence =
    dropInWorkshopOccurrenceId && !getWorkshopQuery.isLoading
      ? workshopOccurrences.find(({ id: _id }) => _id === dropInWorkshopOccurrenceId)
      : null;

  const { startsAt } = dropInWorkshopOccurrence;

  /*
   * We only want to show this modal if the user has not enrolled
   * in this workshop occurrence AND it is in the past.
   */
  const isOpen = !enrollmentStatus && getIsInThePast(startsAt);

  return (
    <>
      <Modal isOpen={isOpen} onClose={_.noop}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Sorry, this class has already started.</Heading>
          </ModalHeader>
          <ModalBody>
            <Text>Please choose another date. 😅</Text>
          </ModalBody>
          <ModalFooter>
            <NextWorkshopOccurrenceButton
              variant="primary"
              dropInWorkshopOccurrenceId={dropInWorkshopOccurrenceId}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
