import { Workshop, WorkshopOccurrence } from '../resources/workshops';
import { getClassesRouterUrl } from './classesRouterPaths';

/*
 * Helper intended to assist with routing to a workshop's target URL
 * depending on whether or not it is a drop-in
 */
export const getWorkshopRouterUrl = ({
  id,
  dropInWorkshopOccurrenceId,
}: {
  id: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
}) =>
  dropInWorkshopOccurrenceId
    ? getClassesRouterUrl.dropInDetails({
        id,
        dropInWorkshopOccurrenceId,
      })
    : getClassesRouterUrl.classDetails({
        id,
      });
