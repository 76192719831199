import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { createAxiosClient, createJsonApiAxiosClient } from '../../../../lib/axios';
import { getAccountApiUrl } from './apiPaths';
import { GetAccount, UpdateAccount } from './types';

/*
 * GET /v1/account
 */
const generateGetAccount = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAccountApiUrl.show();

  const fn = async () => {
    const { data } = await client.get<GetAccount.Response>(path);
    return data;
  };

  return { path, fn };
};

export const useGetAccountQuery = (options: UseQueryOptions<GetAccount.Response> = {}) => {
  const { path: queryKey, fn } = generateGetAccount();
  return useQuery<GetAccount.Response>(queryKey, fn, options);
};

/*
 * PUT /v1/account
 */

const generateUpdateAccount = () => {
  const client = createAxiosClient({ auth: true, transformPropertyNameCase: true });
  const path = getAccountApiUrl.update();
  const fn = async (body: UpdateAccount.Request['body']) => {
    const { data } = await client.put<UpdateAccount.Response>(path, body);
    return data;
  };
  return { path, fn };
};

export const useUpdateAccountMutation = () => {
  const { path: putQueryKey, fn } = generateUpdateAccount();
  const queryClient = useQueryClient();

  // TODO: We probably want to expose options here.
  return useMutation(putQueryKey, {
    mutationFn: (body: UpdateAccount.Request['body']) => fn(body),
    onMutate: async () => {
      await queryClient.cancelQueries('/v1/account');
    },
    onSettled: () => {
      queryClient.invalidateQueries('/v1/account');
    },
  });
};

/*
 * DELETE /v1/account
 */
export const generateDeleteAccount = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getAccountApiUrl.destroy();

  const fn = async () => {
    const { data } = await client.delete(path);
    return data;
  };

  return { path, fn };
};

export const useAccountDeleteMutation = () => {
  const { path: deleteQueryKey, fn: mutationFn } = generateDeleteAccount();
  return useMutation(deleteQueryKey, {
    mutationFn,
  });
};
