import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { RouterLink, useTimer } from '@coa/react-utils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OnDemandVideoCheckpoint } from '../../../../../../resources/onDemandVideoCheckpoints';
import { OnDemandVideo } from '../../../../../../resources/onDemandVideos';
import { getRouterUrl } from '../../../../../../routerPaths';

type NextVideoCheckpointProps = {
  videoId: OnDemandVideo['id'];
  nextCheckpoint: OnDemandVideoCheckpoint;
};

/*
 * We are memoizing the `nextVideoCheckpoint` component to avoid re-rendering the
 * component when a user hovers over the video player component (this feature is
 * in place to manage the UI for video controls and video slider). This was causing a
 * bug where this timer would reset every render which is an unintended re-render.
 */

export const NextVideoCheckpoint = React.memo(
  ({ videoId, nextCheckpoint }: NextVideoCheckpointProps) => {
    const timer = useTimer();
    const history = useHistory();

    useEffect(() => {
      timer.resetTimer();
    }, []);

    useEffect(() => {
      if (nextCheckpoint) {
        timer.startTimer(5000);

        const nextCheckpointUrl = getRouterUrl.onDemand.videoCheckpoint({
          videoId,
          checkpointId: nextCheckpoint.id,
        });

        if (timer.timerElapsed) {
          history.push(nextCheckpointUrl);
          timer.resetTimer();
        }
      }
    }, [timer]);

    if (nextCheckpoint) {
      return (
        <Box position="absolute" top="0" left="0" height="100%" width="100%" bg="grayAlpha.900">
          <Flex
            flexDirection="column"
            justifyContent="center"
            height="100%"
            p={12}
            alignItems="baseline"
          >
            <Text fontSize="sm" mb="4" color="white">
              UP NEXT:
            </Text>
            <Text fontSize="2xl" color="white" mb="6">
              {nextCheckpoint.title}
            </Text>
            <Flex alignItems="center">
              {/* This speed has nothing to do with the useTimer hook declared above. 
              It was arbitrarily chosen by feel of the spinner speed */}
              <Spinner color="white" mr={6} speed="0.6s" />
              <Button
                colorScheme="gray"
                as={RouterLink}
                to={getRouterUrl.onDemand.videoCheckpoint({
                  videoId,
                  checkpointId: nextCheckpoint.id,
                })}
                width="auto"
              >
                Next
              </Button>
            </Flex>
          </Flex>
        </Box>
      );
    }
    return null;
  }
);
