import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

function NewPassword({ claimingAccount, createPassword, updateAttr, password, loading, errors }) {
  const btnText = claimingAccount ? 'Complete Account Setup' : 'Reset Password';
  return (
    <>
      {claimingAccount ? (
        <Box textAlign="center">
          <Text mb={4}>Complete your account setup by creating your secure password:</Text>
        </Box>
      ) : null}
      {errors.token ? (
        <Grid item xs>
          <Link as={RouterLink} to="/forgot-password" variant="body2" color="error">
            This link has expired. Click here to start over.
          </Link>
        </Grid>
      ) : null}
      <form noValidate onSubmit={createPassword}>
        <FormControl noValidate>
          {claimingAccount ? null : <FormLabel htmlFor="password">Your New Password</FormLabel>}
          <FormErrorMessage>{errors.password}</FormErrorMessage>
          <Input
            type="password"
            name="password"
            label="Your New Password"
            variant="coa-main"
            placeholder="A Secure, Random Password"
            onChange={(e) => updateAttr(e, 'password')}
            value={password}
            errors={errors}
            autoFocus
            data-cy="input-new-password"
            mb={2}
          />
        </FormControl>
        <Button
          type="submit"
          variant="primary"
          width="full"
          my={4}
          isLoading={loading}
          disabled={loading}
          data-cy="submit"
        >
          {btnText}
        </Button>
      </form>
    </>
  );
}

NewPassword.propTypes = {
  claimingAccount: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  updateAttr: PropTypes.func.isRequired,
  createPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default NewPassword;
