import { goBack } from '@coa/react-utils';
import { UnpackArray } from '@coa/types';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { matchPath, Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import {
  TakeoverModal,
  TakeoverModalBody,
  TakeoverModalCloseButton,
  TakeoverModalContent,
} from '../../../components/compositions/Takeover';
import {
  createGetWorkshopPlaceholderData,
  useGetWorkshopQuery,
} from '../../../resources/workshops';
import { getWorkshopRouterUrl } from '../../../routerPaths/helpers';
import { ExerciseView } from '../../exercises/ExerciseView';

function WorkshopExercisesRoutes() {
  return (
    <Switch>
      <Route path="/classes/:id/exercises/:exerciseId/steps/:stepId" component={ExerciseView} />
      <Route path="/classes/:id/exercises/:exerciseId" component={ExerciseView} />
    </Switch>
  );
}

export function WorkshopExercisesModal({ id }: { id: string }): JSX.Element {
  const location = useLocation<{ id: string }>();
  const history = useHistory();
  const params = useParams<{ id: string; exerciseId: string }>();
  /*
   * Unfortunately we can't simply close this Modal, as it can be launched
   * (or rather, navigated to) from several places. In each of those places,
   * when it closes, we want to send the user "back where they came from".
   */
  const prevPathOnModalOpenRef = useRef<Location['pathname']>(`/my-coa`);
  const lastLocation = (useLastLocation() as unknown) as Location;
  const getWorkshopQuery = useGetWorkshopQuery(
    { id },
    {
      // Use placeholder data solely so that calls below can be
      // made without caution.
      placeholderData: createGetWorkshopPlaceholderData({ id }),
    }
  );

  const { exerciseId } = params;
  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences } = workshop;
  const exerciseOccurrence =
    workshopOccurrences.find(
      ({ exerciseId: _exerciseId }) => _.toNumber(exerciseId) === _.toNumber(_exerciseId)
    ) || ({} as UnpackArray<typeof workshopOccurrences>);

  const isOpen = Boolean(
    matchPath(location.pathname, {
      path: [
        // TODO: Consider lib for paths.
        '/classes/:id/exercises',
        '/classes/:id/exercises/:exerciseId',
        '/classes/:id/exercises/:exerciseId/steps/:stepId',
      ],
      exact: true,
    })
  );

  useEffect(() => {
    if (isOpen && lastLocation) {
      prevPathOnModalOpenRef.current = lastLocation.pathname;
    } else {
      prevPathOnModalOpenRef.current = getWorkshopRouterUrl({
        id,
        dropInWorkshopOccurrenceId: workshopOccurrences.length > 1 ? exerciseOccurrence.id : null,
      });
    }
  }, [isOpen, workshopOccurrences]);

  return (
    <TakeoverModal
      isOpen={isOpen}
      onClose={() =>
        goBack({ history, lastLocation, path: prevPathOnModalOpenRef.current, exact: true })
      }
    >
      <TakeoverModalContent>
        <TakeoverModalBody>
          <TakeoverModalCloseButton />
          <WorkshopExercisesRoutes />
        </TakeoverModalBody>
      </TakeoverModalContent>
    </TakeoverModal>
  );
}
