import { useEffect, useRef } from 'react';

/*
 * Stores a the initial value of a prop so that future values
 * of that prop can be compared against it.
 */
export const useInitial = (value) => {
  const ref = useRef();
  useEffect(
    () => {
      ref.current = value;
    },

    /*
     * Because we are explicitly not looking for changes in the
     * value of this particular prop, the value of the ref will
     * be preserved.
     */
    []
  );
  return ref.current;
};
