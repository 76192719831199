import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const Fader = ({ fadeStart, ...rest }: BoxProps & { fadeStart: 'top' | 'bottom' }) => (
  <Box
    left={0}
    height={{ base: 4, md: 8 }}
    width="100%"
    zIndex="docked"
    pointerEvents="none"
    // Note use of rgba below rather than simply "transparent". We use this
    // to compensate for a Safari bug that requires that we "use color stops
    // that utilize the transparent version of the color you are interpolating
    // to/from".
    // @see https://stackoverflow.com/a/56548711
    bg={`linear-gradient( to ${fadeStart}, rgba(255,255,255,0), white)`}
    {...rest}
  />
);

/*
 * The parent of our Layout. It simply renders its children
 * using flex properties.
 */
export const ExerciseLayoutParent = ({ children, ...rest }: FlexProps) => (
  <Flex direction="column" height="100%" {...rest}>
    {children}
  </Flex>
);

export const ExerciseLayoutChildWrapper = ({ children, ...rest }: BoxProps) => (
  <Box
    // Constrains all children of our various layout
    // areas to the same max width and centers them.
    maxWidth="38em"
    mx="auto"
    {...rest}
  >
    {children}
  </Box>
);

/*
 * Note that this header does nothing but is maintained here for
 * consistency.
 */
export const ExerciseLayoutHeader = ({ children, ...rest }: BoxProps) => (
  <Box position="relative" {...rest}>
    <ExerciseLayoutChildWrapper>{children}</ExerciseLayoutChildWrapper>
    <Fader fadeStart="top" position="absolute" top="100%" />
  </Box>
);

export const ExerciseLayoutScrollableContent = ({ children, ...rest }: BoxProps) => (
  <Box
    // Ensures that the content takes up all available space.
    height="100%"
    // The max-height property was chosen
    // by eyeballing on a desktop view (as it's not all that helpful
    // on mobile) and we may wish to change it.
    maxHeight="33em"
    // An "auto" overflow is chosen so that the region will be
    // scrollable when necessary but, if it is not, no scrollbar
    // will appear, as it's sorta unsightly.
    overflow="auto"
    {...rest}
  >
    <ExerciseLayoutChildWrapper>{children}</ExerciseLayoutChildWrapper>
  </Box>
);

export const ExerciseLayoutFooter = ({ children, ...rest }: BoxProps) => (
  <Box
    // These flex properties prevent the footer from growing or
    // shrinking beyond the space left when the Content reaches
    // its max-height.
    flexGrow={1}
    flexShrink={1}
    borderTopColor="gray.50"
    borderTopWidth="1px"
    px={{ base: 4, md: 0 }}
    py={{ base: 4, md: 8 }}
    position="relative"
    {...rest}
  >
    <Fader
      fadeStart="bottom"
      position="absolute"
      // Add 1 pixel to accommodate border.
      bottom="calc(100% + 1px)"
    />
    <ExerciseLayoutChildWrapper>{children}</ExerciseLayoutChildWrapper>
  </Box>
);
