import { createLegacyAuthAxiosClientWithCaseTransform } from '@coa/api/lib/axios';
import { setDeepImmutable } from '@coa/stdlib/object';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AsAttributesObj } from '../../../lib/json-api';
import { ExerciseStep } from '../types';
import { getIsExerciseStepIncomplete } from '../utils/getIsExerciseStepIncomplete';
import {
  GetWorkshopExercises,
  GetWorkshopExerciseSteps,
  GetWorkshopWeeklyRepRecap,
  PutWorkshopExerciseStepAnswers,
} from './types';

/*
 * Urls
 */

const getWorkshopExercisesUrl = (id: GetWorkshopExercises.Request['pathParams']['id']) =>
  `/v1/workshops/${id}/exercises`;
const getWorkshopExerciseStepsUrl = (
  id: GetWorkshopExerciseSteps.Request['pathParams']['id'],
  exerciseId: GetWorkshopExerciseSteps.Request['pathParams']['exerciseId']
) => `/v1/workshops/${id}/exercises/${exerciseId}/steps`;
const putWorkshopExerciseStepAnswersUrl = (
  id: PutWorkshopExerciseStepAnswers.Request['pathParams']['id'],
  exerciseId: PutWorkshopExerciseStepAnswers.Request['pathParams']['exerciseId'],
  stepId: PutWorkshopExerciseStepAnswers.Request['pathParams']['stepId']
) => `/v1/workshops/${id}/exercises/${exerciseId}/steps/${stepId}/answers`;
const getWorkshopWeeklyRepRecapUrl = (id: GetWorkshopWeeklyRepRecap.Request['pathParams']['id']) =>
  `/v1/workshops/${id}/weekly_rep_recap`;

/*
 * GET /workshops/:workshopId/exercises
 */

const generateGetWorkshopExercises = ({ id }: GetWorkshopExercises.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = getWorkshopExercisesUrl(id);
  const fn = async () => {
    const { data } = await client.get<GetWorkshopExercises.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useWorkshopExercisesQuery = (params: GetWorkshopExercises.Request['pathParams']) => {
  const { path: queryKey, fn } = generateGetWorkshopExercises(params);
  return useQuery<GetWorkshopExercises.Response>(queryKey, fn, { enabled: Boolean(params.id) });
};

/*
 * GET /workshops/:workshopId/exercises/:exerciseId/steps
 */

const generateGetWorkshopExerciseSteps = ({
  id,
  exerciseId,
}: GetWorkshopExerciseSteps.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = getWorkshopExerciseStepsUrl(id, exerciseId);
  const fn = async () => {
    const { data } = await client.get<GetWorkshopExerciseSteps.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useWorkshopExerciseStepsQuery = (
  params: GetWorkshopExerciseSteps.Request['pathParams']
) => {
  const { path: queryKey, fn } = generateGetWorkshopExerciseSteps(params);
  return useQuery<GetWorkshopExerciseSteps.Response>(queryKey, fn, {
    enabled: Boolean(params.id && params.exerciseId),
  });
};

/*
 * PUT /workshops/:workshopId/exercises/:exerciseId/steps/:stepId/answers
 */

const generatePutWorkshopExerciseStepsAnswers = ({ id, exerciseId, stepId }) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = putWorkshopExerciseStepAnswersUrl(id, exerciseId, stepId);
  const fn = async (answers: PutWorkshopExerciseStepAnswers.Request['body']) => {
    const { data } = await client.put<PutWorkshopExerciseStepAnswers.Response>(path, answers);
    return data;
  };
  return { path, fn };
};

export const useWorkshopExerciseStepAnswersMutation = (
  params: PutWorkshopExerciseStepAnswers.Request['pathParams']
) => {
  const { path: putQueryKey, fn } = generatePutWorkshopExerciseStepsAnswers(params);

  const { id, exerciseId, stepId } = params;
  const getWorkshopExerciseStepsQueryKey = getWorkshopExerciseStepsUrl(id, exerciseId);
  const queryClient = useQueryClient();

  return useMutation(putQueryKey, {
    mutationFn: (answers: PutWorkshopExerciseStepAnswers.Request['body']) => fn(answers),
    onMutate: async ({ answers }) => {
      // Cancel any outgoing GETs that could cause collisions.
      await queryClient.cancelQueries(getWorkshopExerciseStepsQueryKey);

      // Store the cache value in the event we need to return
      const previousCacheValue = queryClient.getQueryData<GetWorkshopExerciseSteps.Response>(
        getWorkshopExerciseStepsQueryKey
      );

      // Optimistically update the query cache.
      queryClient.setQueryData(
        getWorkshopExerciseStepsQueryKey,
        (old: GetWorkshopExerciseSteps.Response) => {
          const stepIndex = old.data.findIndex(({ id: _stepId }) => _stepId === stepId);
          return setDeepImmutable(
            old,
            ['data', stepIndex, 'attributes'],
            (attributes: AsAttributesObj<ExerciseStep>['attributes']) => {
              const formQuestions = _.reduce(
                answers,
                (acc, answer, questionId) => setDeepImmutable(acc, [questionId, 'answer'], answer),
                attributes.formQuestions
              );
              const { formQuestionSequence } = attributes;
              const isIncomplete = getIsExerciseStepIncomplete(formQuestionSequence, formQuestions);
              return {
                ...attributes,
                formQuestions,
                status: isIncomplete ? 'active' : 'complete',
              };
            }
          );
        }
      );

      // Provide the original value to the cache context so that
      // we may return to it in the event of an error.
      return { previousCacheValue };
    },
    onError: (err, value, context: { previousCacheValue: GetWorkshopExerciseSteps.Response }) => {
      queryClient.setQueryData(getWorkshopExerciseStepsQueryKey, context.previousCacheValue);
    },
    onSettled: () => {
      // The Weekly Rep Recap contains answers as well, so we should
      // re-fetch if we make changes.
      queryClient.invalidateQueries(getWorkshopWeeklyRepRecapUrl(id));
    },
  });
};

/*
 * GET /v1/workshops/:id/weekly_rep_recap
 */

const generateGetWorkshopWeeklyRepRecap = ({
  id,
}: GetWorkshopWeeklyRepRecap.Request['pathParams']) => {
  const client = createLegacyAuthAxiosClientWithCaseTransform();
  const path = getWorkshopWeeklyRepRecapUrl(id);
  const fn = async () => {
    const { data } = await client.get<GetWorkshopWeeklyRepRecap.Response>(path);
    return data;
  };

  return {
    path,
    fn,
  };
};

export const useGetWorkshopWeeklyRepRecapQuery = (
  { id }: GetWorkshopWeeklyRepRecap.Request['pathParams'],
  ops: UseQueryOptions<GetWorkshopWeeklyRepRecap.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetWorkshopWeeklyRepRecap({ id });
  return useQuery<GetWorkshopWeeklyRepRecap.Response>(queryKey, fn, ops);
};
