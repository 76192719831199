import { serializeJsonApiObject } from '../../lib/json-api';

export const ENROLLEES_LIST_REQUEST = 'ENROLLEES_LIST_REQUEST';
export const ENROLLEES_LIST_SUCCESS = 'ENROLLEES_LIST_SUCCESS';
export const ENROLLEES_LIST_FAILURE = 'ENROLLEES_LIST_FAILURE';

export function list(workshopId) {
  return {
    type: ENROLLEES_LIST_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_LIST_REQUEST, ENROLLEES_LIST_SUCCESS, ENROLLEES_LIST_FAILURE],
      method: 'GET',
    },
  };
}

export const ENROLLEES_APPROVE_REQUEST = 'ENROLLEES_APPROVE_REQUEST';
export const ENROLLEES_APPROVE_SUCCESS = 'ENROLLEES_APPROVE_SUCCESS';
export const ENROLLEES_APPROVE_FAILURE = 'ENROLLEES_APPROVE_FAILURE';

export function approve(workshopId, enrolleeId) {
  return {
    type: ENROLLEES_APPROVE_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/approve`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_APPROVE_REQUEST, ENROLLEES_APPROVE_SUCCESS, ENROLLEES_APPROVE_FAILURE],
      method: 'POST',
    },
  };
}

export const ENROLLEES_REJECT_REQUEST = 'ENROLLEES_REJECT_REQUEST';
export const ENROLLEES_REJECT_SUCCESS = 'ENROLLEES_REJECT_SUCCESS';
export const ENROLLEES_REJECT_FAILURE = 'ENROLLEES_REJECT_FAILURE';

export function reject(workshopId, enrolleeId) {
  return {
    type: ENROLLEES_REJECT_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/reject`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_REJECT_REQUEST, ENROLLEES_REJECT_SUCCESS, ENROLLEES_REJECT_FAILURE],
      method: 'POST',
    },
  };
}

export const ENROLLEES_REQUEST_INFO_REQUEST = 'ENROLLEES_REQUEST_INFO_REQUEST';
export const ENROLLEES_REQUEST_INFO_SUCCESS = 'ENROLLEES_REQUEST_INFO_SUCCESS';
export const ENROLLEES_REQUEST_INFO_FAILURE = 'ENROLLEES_REQUEST_INFO_FAILURE';

export function requestInfo(workshopId, enrolleeId) {
  return {
    type: ENROLLEES_REQUEST_INFO_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/request_info`,
      authenticated: true,
      admin: true,
      types: [
        ENROLLEES_REQUEST_INFO_REQUEST,
        ENROLLEES_REQUEST_INFO_SUCCESS,
        ENROLLEES_REQUEST_INFO_FAILURE,
      ],
      method: 'POST',
    },
  };
}

// new WorkshopEnrollmentForm uses react-query to POST enrollment
// but WorkshopEnrollees view is managed by redux
// This action simply accepts newly created enrollee and adds to state.enrollees
// downstream UI expects serialized JSON data
export const ENROLLEES_ADD_TO_VIEW = 'ENROLLEES_ADD_TO_VIEW';

export function addToView(enrollee) {
  const serializedEnrollee = serializeJsonApiObject(enrollee);
  return {
    type: ENROLLEES_ADD_TO_VIEW,
    payload: serializedEnrollee,
  };
}

export const ENROLLEES_REMOVE_REQUEST = 'ENROLLEES_REMOVE_REQUEST';
export const ENROLLEES_REMOVE_SUCCESS = 'ENROLLEES_REMOVE_SUCCESS';
export const ENROLLEES_REMOVE_FAILURE = 'ENROLLEES_REMOVE_FAILURE';

export function remove(workshopId, enrolleeId) {
  return {
    type: ENROLLEES_REMOVE_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/remove`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_REMOVE_REQUEST, ENROLLEES_REMOVE_SUCCESS, ENROLLEES_REMOVE_FAILURE],
      method: 'POST',
    },
  };
}

export const ENROLLEES_REMOVE_WITH_REFUND_REQUEST = 'ENROLLEES_REMOVE_WITH_REFUND_REQUEST';
export const ENROLLEES_REMOVE_WITH_REFUND_SUCCESS = 'ENROLLEES_REMOVE_WITH_REFUND_SUCCESS';
export const ENROLLEES_REMOVE_WITH_REFUND_FAILURE = 'ENROLLEES_REMOVE_WITH_REFUND_FAILURE';

export function removeWithRefund(workshopId, enrolleeId) {
  return {
    type: ENROLLEES_REMOVE_WITH_REFUND_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/remove_with_refund`,
      authenticated: true,
      admin: true,
      types: [
        ENROLLEES_REMOVE_WITH_REFUND_REQUEST,
        ENROLLEES_REMOVE_WITH_REFUND_SUCCESS,
        ENROLLEES_REMOVE_WITH_REFUND_FAILURE,
      ],
      method: 'POST',
    },
  };
}

export const ENROLLEES_TRANSFER_REQUEST = 'ENROLLEES_TRANSFER_REQUEST';
export const ENROLLEES_TRANSFER_SUCCESS = 'ENROLLEES_TRANSFER_SUCCESS';
export const ENROLLEES_TRANSFER_FAILURE = 'ENROLLEES_TRANSFER_FAILURE';

export function transfer(workshopId, enrolleeId, toWorkshopId, chargeTheDifference) {
  return {
    type: ENROLLEES_TRANSFER_REQUEST,
    api: {
      data: { to_workshop_id: toWorkshopId, charge_difference: chargeTheDifference },
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}/transfer`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_TRANSFER_REQUEST, ENROLLEES_TRANSFER_SUCCESS, ENROLLEES_TRANSFER_FAILURE],
      method: 'POST',
    },
  };
}

export const ENROLLEES_UPDATE_REQUEST = 'ENROLLEES_UPDATE_REQUEST';
export const ENROLLEES_UPDATE_SUCCESS = 'ENROLLEES_UPDATE_SUCCESS';
export const ENROLLEES_UPDATE_FAILURE = 'ENROLLEES_UPDATE_FAILURE';

export function update(workshopId, enrolleeId, fitAssessment) {
  return {
    type: ENROLLEES_UPDATE_REQUEST,
    api: {
      data: { fit_assessment: fitAssessment },
      endpoint: `workshops/${workshopId}/enrollees/${enrolleeId}`,
      authenticated: true,
      admin: true,
      types: [ENROLLEES_UPDATE_REQUEST, ENROLLEES_UPDATE_SUCCESS, ENROLLEES_UPDATE_FAILURE],
      method: 'PUT',
    },
  };
}
