import {
  Button,
  ButtonProps,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackProps,
  Text,
  TextProps,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AnyObject, UnpackArray } from '@coa/types';
import _ from 'lodash';
import React from 'react';
import { IconBadge } from '../../../components/atoms/IconBadge';
import { CheckIcon, ChevronDownIcon } from '../../../components/Icons';
import { tryPushupsAnalytics } from '../../../lib/analytics/clients';
import { tryQAndAAnalytics } from '../../../lib/analytics/clients/tryQAndAAnalytics';
import { boundChakra } from '../../../lib/chakra-ui';
import { formatWorkshopOccurrenceTimeLabel, TryWorkshopType } from '../../../resources/workshops';
import { useAppBlur } from '../../shared/layout/components/GlobalStylesProvider';
import { TryWorkshopContextProps, TryWorkshopResponse } from '../utils';

type WithActive<P extends AnyObject = AnyObject> = P & { active?: boolean };

function EntryParent({ active, ...rest }: WithActive<StackProps>): JSX.Element {
  return (
    <VStack {...{ spacing: 1, alignItems: 'start', textAlign: 'left', width: '100%' }} {...rest} />
  );
}

export function TryWorkshopPickerTitle({ active, ...rest }: WithActive<TextProps>) {
  return <Text color={active ? 'green.900' : 'warm.700'} fontWeight="bold" {...rest} />;
}

export function TryWorkshopPickerText({ active, ...rest }: WithActive<TextProps>) {
  return <Text color={active ? 'green.700' : 'warm.500'} {...rest} />;
}

export const TryWorkshopAvatarParent = boundChakra.HStack('EntryAvatarParent', {
  spacing: 1,
  alignItems: 'center',
  width: '100%',
});
export const TryWorkshopAvatar = boundChakra.Avatar('EntryAvatar', { boxSize: '22px' });
export const TryWorkshopAvatarLabel = TryWorkshopPickerText;

function TryPickerEntryButton({ active, ...rest }: ButtonProps & { active?: boolean }) {
  return (
    <Button
      height="auto"
      color="black"
      p={4}
      borderRadius="base"
      as={Button}
      // Overrides default styles from the Button component
      fontWeight="normal"
      w="100%"
      overflow="hidden"
      {...(active
        ? {
            bg: 'green.300',
            _hover: { bg: 'green.300' },
          }
        : {
            bg: 'warm.50',
            _hover: { bg: 'warm.100' },
          })}
      /*
       * Resolves an issue where Button components in Chakra don't
       * manage long-text well.
       * @see https=//github.com/chakra-ui/chakra-ui/issues/576#issuecomment-609697076
       */
      style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
      {...rest}
    />
  );
}

type TryPickerMenuEntryProps = {
  workshop: UnpackArray<TryWorkshopResponse>;
  active?: boolean;
} & StackProps;

function TryPickerMenuEntry({ workshop, active, ...rest }: TryPickerMenuEntryProps) {
  const { title, shortBlurb, workshopOccurrences, therapist } = workshop;
  return (
    <EntryParent active={active} {...rest}>
      <TryWorkshopPickerTitle active={active}>{title}</TryWorkshopPickerTitle>
      <TryWorkshopPickerText active={active}>
        {formatWorkshopOccurrenceTimeLabel(workshopOccurrences[0])}
      </TryWorkshopPickerText>
      <TryWorkshopPickerText active={active}>{shortBlurb}</TryWorkshopPickerText>
      <TryWorkshopAvatarParent>
        <TryWorkshopAvatar name={therapist.name} src={therapist.imageUrl} />
        <TryWorkshopAvatarLabel active={active}>{therapist.name}</TryWorkshopAvatarLabel>
      </TryWorkshopAvatarParent>
    </EntryParent>
  );
}

export function TryPickerList({
  onSelectEntry = _.noop,
  type,
  tryWorkshopQuery,
  selectedTryWorkshopId,
  setSelectedTryWorkshopId,
}: {
  onSelectEntry: () => void;
  type: TryWorkshopType;
} & TryWorkshopContextProps) {
  if (tryWorkshopQuery.isLoading) return null;

  const { data: queryData } = tryWorkshopQuery;
  const trackSelectWorkshopAnalytics = (workshopId: string) =>
    // TODO: clean this up if we need > 2 try types
    type === 'pushups'
      ? tryPushupsAnalytics.track('Selected Pushup', { workshopId })
      : tryQAndAAnalytics.track('Selected Q&A', { workshopId });

  return (
    <VStack spacing={4}>
      {queryData.map((workshopEntry: UnpackArray<TryWorkshopResponse>, index: number) => {
        const active = workshopEntry.id === selectedTryWorkshopId && index < queryData.length;
        return (
          <TryPickerEntryButton
            key={workshopEntry.id}
            data-cy={`${type}-menu-entry-${index}`}
            active={active}
            onClick={() => {
              setSelectedTryWorkshopId(workshopEntry.id);
              // pass in display label or create helper
              trackSelectWorkshopAnalytics(workshopEntry.id);
              onSelectEntry();
            }}
            position="relative"
          >
            <TryPickerMenuEntry workshop={workshopEntry} active={active} />
            {active ? (
              <IconBadge
                position="absolute"
                top={4}
                right={4}
                background="green.700"
                icon={<CheckIcon color="white" />}
                variant="solidDarkRound"
                borderRadius={1000}
              />
            ) : null}
          </TryPickerEntryButton>
        );
      })}
    </VStack>
  );
}

function ModalOverlayWithBlur() {
  useAppBlur();
  return <ModalOverlay />;
}

export function TryWorkshopPicker({
  type,
  tryWorkshopQuery,
  selectedTryWorkshopId,
  ...props
}: { type: TryWorkshopType } & TryWorkshopContextProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (tryWorkshopQuery.isLoading) return null;

  const { data: queryData } = tryWorkshopQuery;
  const selectedTryWorkshop = queryData.find(({ id }) => id === selectedTryWorkshopId);

  return (
    <>
      <TryPickerEntryButton
        onClick={onOpen}
        rightIcon={<ChevronDownIcon />}
        data-cy="pushups-menu-button"
      >
        <TryPickerMenuEntry workshop={selectedTryWorkshop} />
      </TryPickerEntryButton>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlayWithBlur />
        <ModalContent background="transparent" boxShadow="none">
          <ModalHeader />
          <ModalCloseButton variant="coa-main" />
          <ModalBody>
            <Heading size="lg" mb={8} color="white">
              Choose your topic:
            </Heading>
            <TryPickerList
              onSelectEntry={onClose}
              type={type}
              selectedTryWorkshopId={selectedTryWorkshopId}
              tryWorkshopQuery={tryWorkshopQuery}
              {...props}
            />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}
