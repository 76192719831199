import { BadgeProps, BoxProps, extendTheme, TableProps, TabsProps, Theme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { AnyObject, ValueOf } from '@coa/types';
import _ from 'lodash';

// TODO: Remove siblings in this directory
// TODO: THIS ENTIRE FILE needs to be removed and references across web app
// need to be updated to use @coa/esthetic
// Until then, please add any updates here to respective @coa/esthetic variables
// to keep themes in sync

export const navHeight = 94; // Value pulled from Dev Tools
export const navOffset = navHeight + 26; // Extra offset pulled from Design Spec
export const navHeightPx = `${navHeight}px`;
export const navOffsetPx = `${navOffset}px`;

/*
 * Global box shadow styles
 */
export const boxShadowStyles = {
  boxShadow: '0px 3px 10px 0px rgba(40, 40, 40, 0.12)',
};

/*
 * These font-weights reflect the settings configured in the
 * @font-face declarations in application.css
 */
export const fontWeights = {
  heading: {
    medium: 700,
  },
  text: {
    semibold: 700,
  },
};

const customBreakpoints = createBreakpoints({
  /*
   * Note that the order in which these breakpoints are listed
   * will be reflected in the CSS Cascade, meaning that later
   * breakpoints will take priority. As such, we need to ensure
   * they are listed in order, hence why "standard" (i.e. sm, md)
   * entries are intermingled amongst "non-standard".
   */
  sm: '20em',
  md: '46em',

  // TODO: Need to give this breakpoint a real name
  foo: '54em',

  lg: '62em',
  xl: '80em',
  xxl: '90em',
});

export const colorScheme = {
  gray: 'gray',
  evergreen: 'evergreen',
  green: 'green',
  lime: 'lime',
  orange: 'orange',
  red: 'red',
  warm: 'warm',
  yellow: 'yellow',
} as const;

export const alphaColorScheme = {
  evergreenAlpha: 'evergreenAlpha',
  grayAlpha: 'grayAlpha',
  warmAlpha: 'warmAlpha',
  whiteAlpha: 'whiteAlpha',
};

export type ColorScheme = ValueOf<typeof colorScheme>;
export type AlphaColorScheme = ValueOf<typeof alphaColorScheme>;

export const colorSchemes = Object.values(colorScheme);
export const alphaColorSchemes = Object.values(alphaColorScheme);

type ColorSchemeEntry = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

type ColorSchemeConfig<T extends string> = {
  [key in T]: ColorSchemeEntry;
};

export const colors: ColorSchemeConfig<ColorScheme> = {
  gray: {
    50: '#EDEEF0',
    100: '#DCE0E5',
    200: '#C9CED6',
    300: '#B1B7C2',
    400: '#9EA6B3', // - Fog
    500: '#848F9A', // - Ocean
    600: '#67757E',
    700: '#4B5B63', // - Slate
    800: '#394144',
    900: '#282828', // - Charcoal
  },
  evergreen: {
    50: '#83A699',
    100: '#5D8C82',
    200: '#35726E', // - Forest
    300: '#275E5D',
    400: '#194B4B',
    500: '#0C383A', // - Evergreen
    600: '#0A3234',
    700: '#092C2E',
    800: '#082728',
    900: '#072122',
  },
  green: {
    50: '#E9F2EC',
    100: '#D3E3D9',
    200: '#BED4C7',
    300: '#A8C1B2', // - Seafoam
    400: '#83A699',
    500: '#5D8C82',
    600: '#35726E', // - Forest
    700: '#275E5D',
    800: '#194B4B',
    900: '#0C383A', // - Evergreen
  },
  lime: {
    50: '#EAF2DF',
    100: '#D0E3B8',
    200: '#BBD898',
    300: '#A3CB76', // - Fern
    400: '#8CB064',
    500: '#769652',
    600: '#617D41',
    700: '#4D6530',
    800: '#394D21',
    900: '#263712',
  },
  orange: {
    50: '#FCEBE3',
    100: '#F7CEBA',
    200: '#F0B698',
    300: '#E79E78', // - Melon
    400: '#E1885A',
    500: '#D9713B',
    600: '#B75C32',
    700: '#954929',
    800: '#743620',
    900: '#542516',
  },
  red: {
    50: '#FFEAE5',
    100: '#FDCCC4',
    200: '#F8B3A8',
    300: '#F39A8D',
    400: '#EB8072', // - Coral
    500: '#E3685B',
    600: '#C2534A',
    700: '#A13E3A',
    800: '#812A2A',
    900: '#63171B',
  },
  warm: {
    50: '#F5EEE8',
    100: '#EADDD1', // - Stone
    200: '#DEC6B0',
    300: '#D1AE91',
    400: '#C49773',
    500: '#B78057', // - Sand
    600: '#9C6949',
    700: '#81523B',
    800: '#673E2D',
    900: '#4D2A1F',
  },
  yellow: {
    50: '#F9F1CC',
    100: '#EFE18F', // - Lemon
    200: '#E7CE74',
    300: '#DEB75B',
    400: '#D49E43',
    500: '#C38132',
    600: '#A36630',
    700: '#854F2C',
    800: '#683B26',
    900: '#4D2A1F',
  },
};

export const alphaColors: ColorSchemeConfig<AlphaColorScheme> = {
  warmAlpha: {
    // warm.500
    50: 'rgba(183, 129, 87, 0.04)',
    100: 'rgba(183, 129, 87, 0.06)',
    200: 'rgba(183, 129, 87, 0.08)',
    300: 'rgba(183, 129, 87, 0.16)',
    400: 'rgba(183, 129, 87, 0.24)',
    500: 'rgba(183, 129, 87, 0.36)',
    600: 'rgba(183, 129, 87, 0.48)',
    700: 'rgba(183, 129, 87, 0.64)',
    800: 'rgba(183, 129, 87, 0.80)',
    900: 'rgba(183, 129, 87, 0.92)',
  },
  evergreenAlpha: {
    // evergreen.500
    50: 'rgba(12, 56, 58, 0.04)',
    100: 'rgba(12, 56, 58, 0.06)',
    200: 'rgba(12, 56, 58, 0.08)',
    300: 'rgba(12, 56, 58, 0.16)',
    400: 'rgba(12, 56, 58, 0.24)',
    500: 'rgba(12, 56, 58, 0.36)',
    600: 'rgba(12, 56, 58, 0.48)',
    700: 'rgba(12, 56, 58, 0.64)',
    800: 'rgba(12, 56, 58, 0.80)',
    900: 'rgba(12, 56, 58, 0.92)',
  },
  grayAlpha: {
    50: 'rgba(39, 40, 40, 0.04)',
    100: 'rgba(39, 40, 40, 0.06)',
    200: 'rgba(39, 40, 40, 0.08)',
    300: 'rgba(39, 40, 40, 0.16)',
    400: 'rgba(39, 40, 40, 0.24)',
    500: 'rgba(39, 40, 40, 0.36)',
    600: 'rgba(39, 40, 40, 0.48)',
    700: 'rgba(39, 40, 40, 0.64)',
    800: 'rgba(39, 40, 40, 0.80)',
    900: 'rgba(39, 40, 40, 0.92)',
  },
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
};

export const brandColors = {
  brand: {
    fog: colors.gray[400],
    ocean: colors.gray[500],
    slate: colors.gray[700],
    charcoal: colors.gray[900],
    seafoam: colors.green[300],
    forest: colors.green[600],
    evergreen: colors.green[900],
    fern: colors.lime[300],
    melon: colors.orange[300],
    coral: colors.red[400],
    stone: colors.warm[100],
    sand: colors.warm[500],
    lemon: colors.yellow[100],
  },
};

// TODO: this is a temp variable to store new coaTheme
// colors to be eventually encorporated into broader coaTheme
const newCoaThemeColors = {
  warmCotton: '#E5CCB0',
};

type WithTheme<Props extends AnyObject> = Props & {
  theme: Theme;
};

export const coaTheme = extendTheme({
  breakpoints: customBreakpoints,
  useSystemColorMode: false,
  initialColorMode: 'light',
  fonts: {
    heading: 'Nib',
    body: 'Founders',
  },
  colors: {
    ...colors,
    ...alphaColors,
    ...brandColors,
    ...newCoaThemeColors,
  },

  zIndices: {
    // TODO: Maybe this should be dynamic?
    modalButton: 1450,
  },

  sizes: {
    container: {
      xxl: '90em',
    },
  },

  textStyles: {
    earmark: {
      fontSize: 'xs',
      fontWeight: 'semibold',
      letterSpacing: 'widest',
      textTransform: 'uppercase',
    },
    /*
     * Having a textStyle called "heading" when there is
     * a component called Heading is sorta confusing, not
     * to mention the fact that this only looks like a particular
     * type of heading - an earmark! Going forward let's
     * prefer textStyle="earmark" and try to phase this out.
     */
    heading: {
      fontSize: 'xs',
      fontWeight: 'semibold',
      letterSpacing: 'widest',
      textTransform: 'uppercase',
    },
  },

  /*
   * Extend additional component APIs to fit Coa's needs.
   *
   * SHOULD YOU WISH TO MAKE CHANGES HERE please ensure that
   * the variants are included in the component's corresponding
   * Storybook entry and that those variants are indicated to
   * be Coa-specific rather than native to Chakra.
   *
   * @see https://chakra-ui.com/docs/theming/customize-theme#customizing-single-components
   */
  // TODO: Type this components object.
  components: {
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 'base',
        },
      },
    },
    Badge: {
      /*
       * We require additional Badge sizes, particularly to
       * render labels on inside EventCard components.
       */
      sizes: {
        md: {
          fontSize: 'md',
        },
        lg: {
          fontSize: 'lg',
        },
      },
      variants: {
        /*
         * Composes the existing solid variant with a slight
         * opacity change - we may wish to move this variant
         * helper pattern into its own utility in the future.
         */
        solidSemiTransparent: (props: WithTheme<BadgeProps>) => {
          const { solid } = props.theme.components.Badge.variants;
          return {
            ...solid(props),
            opacity: 0.7,
          };
        },
      },
    },
    Link: {
      baseStyle: {
        /*
         * I'm not sure why I set the theme to default to not-underlining,
         * but I've regretted it many times since, since the underline can
         * be very important for usability in many use cases.
         *
         * This is challenging to chase down, but we should consider removing
         * this default and auditing usage. In the meantime, the "underline"
         * variant should help manage these cases.
         */
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        'coa-main': {
          color: 'evergreen.600',
          _hover: {
            textDecoration: 'underline',
          },
        },
        underline: {
          textDecoration: 'underline',
          _active: {
            textDecoration: 'underline',
          },
          _hover: {
            textDecoration: 'underline',
          },
        },
      },
    },
    Button: {
      variants: {
        primary: () => {
          const bg = 'evergreen.500';
          return {
            bg,
            color: 'white',
            _hover: {
              bg: 'evergreen.400',
              _disabled: { bg },
            },
            active: {
              bg: 'evergreen.300',
              _disabled: { bg },
            },
          };
        },
        secondary: () => {
          const bg = 'green.50';
          return {
            bg,
            color: 'green.700',
            _hover: {
              bg: 'green.100',
              _disabled: { bg },
            },
            _active: {
              bg: 'green.200',
            },
          };
        },
        ctaGray: () => {
          const bg = 'gray.900';
          return {
            bg,
            color: 'white',
            _hover: {
              bg: 'gray.800',
              _disabled: { bg },
            },
            _active: {
              bg: 'gray.700',
            },
          };
        },
        ctaOrange: () => {
          const bg = 'orange.900';
          return {
            bg,
            color: 'white',
            _hover: {
              bg: 'orange.800',
              _disabled: { bg },
            },
            _active: {
              bg: 'orange.700',
            },
          };
        },
        ctaOrangeLight: () => {
          const bg = 'orange.50';
          return {
            bg,
            color: 'gray.900',
            _hover: {
              bg: 'orange.100',
              _disabled: { bg },
            },
            _active: {
              bg: 'orange.100',
            },
          };
        },
        ctaRedLight: () => {
          const bg = 'red.200';
          return {
            bg,
            color: 'white',
            _hover: {
              bg: 'red.300',
              _disabled: { bg },
            },
            _active: {
              bg: 'red.300',
            },
          };
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'heading',
        // We set font-weight as Chakra's default is "bold", and the numbers
        // will give us greater levels of flexibility.
        fontWeight: fontWeights.heading.medium,

        // Antialiasing is very inexact. The antialiasing settings below were
        // chosen simply by eyeballing design spec against render results
        // for various settings.
        fontSmoothing: 'subpixel-antialiased',
        WebkitFontSmoothing: 'subpixel-antialiased',

        // We use "em" here as it'll be relative to the font-size of the em
        // so that the letter-spacing will scale as the font grows / shrinks.
        // The value chosen is also the result of some inexact eyeballing.
        letterSpacing: '-0.04em',
      },
    },
    Select: {
      variants: {
        'coa-main': {
          field: {
            borderRadius: 'base',
            _placeholder: { color: 'warm.300' },
            border: '2px solid',
            // By default, we make the border color the same as
            // the background color so it *appears* transparent.
            bg: 'warm.50',
            borderColor: 'warm.50',
            _focus: {
              // TODO: Clean this up
              boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
            },
          },
        },
      },
    },
    Input: {
      variants: {
        'coa-main': {
          field: {
            borderRadius: 'base',
            _placeholder: { color: 'warm.300' },
            bg: 'warm.50',
            border: '2px solid',
            borderColor: 'transparent',
            _focus: {
              // TODO: Clean this up
              boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
            },
            _disabled: {
              color: 'grayAlpha.400',
            },
            _invalid: {
              borderColor: 'red.300',
            },
          },
        },
        'coa-secondary': {
          field: {
            borderRadius: 'base',
            _placeholder: { color: 'whiteAlpha.500' },
            bg: 'green.200',
            color: 'white',
            border: 0,
            _focus: {
              // TODO: Clean this up
              boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
            },
            _disabled: {
              color: 'grayAlpha.400',
            },
            _invalid: {
              borderColor: 'red.300',
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        'coa-main': {
          minHeight: 120,
          borderRadius: 'base',
          _placeholder: { color: 'warm.300' },
          bg: 'warm.50',
          border: '2px solid',
          borderColor: 'transparent',
          _focus: {
            // TODO: Clean this up
            boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
          },
          _invalid: {
            borderColor: 'red.300',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        'coa-main': {
          label: {
            width: 'stretch',
            cursor: 'pointer',
          },
          control: {
            alignSelf: 'start',
            marginTop: '4px',
            borderColor: 'green.500',
            cursor: 'pointer',
            _disabled: {
              borderColor: 'gray.200',
              bg: 'gray.200',
              color: 'gray.500',
            },
            _hover: {
              borderColor: 'green.500',
              bg: 'green.50',
              _disabled: {
                borderColor: 'gray.200',
                bg: 'gray.200',
                color: 'gray.500',
              },
            },
            _active: {
              borderColor: 'green.900',
              bg: 'green.900',
              _disabled: {
                borderColor: 'gray.200',
                bg: 'gray.200',
                color: 'gray.500',
              },
            },
            _focus: {
              // TODO: Clean this up
              boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
            },
            _checked: {
              borderColor: 'green.900',
              bg: 'green.900',
              _hover: {
                borderColor: 'green.900',
                bg: 'green.900',
              },
            },
          },
        },
      },
    },
    Radio: {
      variants: {
        'coa-main': {
          label: {
            width: 'stretch',
            cursor: 'pointer',
          },
          control: {
            alignSelf: 'start',
            marginTop: '4px',
            borderColor: 'green.500',
            cursor: 'pointer',
            _hover: {
              borderColor: 'green.500',
              bg: 'green.50',
            },
            _active: {
              borderColor: 'green.900',
              bg: 'green.900',
            },
            _focus: {
              // TODO: Clean this up
              boxShadow: 'rgb(66 153 225 / 60%) 0px 0px 0px 3px',
            },
            _checked: {
              borderColor: 'green.900',
              bg: 'green.900',
              _hover: {
                borderColor: 'green.900',
                bg: 'green.900',
              },
            },
          },
        },
      },
    },
    CloseButton: {
      sizes: {
        xl: {
          height: '48px',
          width: '48px',
          fontSize: '12px',
        },
      },
      variants: {
        'coa-main': {
          color: 'green.700',
          borderRadius: 'full',
          bg: 'green.50',
          _hover: {
            bg: 'green.100',
          },
          _active: {
            bg: 'green.200',
          },
        },
      },
    },
    Tabs: {
      variants: {
        'coa-line': ({ theme, ...rest }: WithTheme<TabsProps>) => {
          const { line } = theme.components.Tabs.variants;
          const lineVariant = _.isFunction(line) ? line(rest) : line;

          /*
           * Override bottom border to make it thicker.
           * @see https://github.com/chakra-ui/chakra-ui/blob/be65f92e9e352bf809eadfc7cdfd368b3e580a02/packages/theme/src/components/tabs.ts#L78
           */
          const { orientation } = rest;
          const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderBottom';
          return _.merge({}, lineVariant, {
            tablist: { [borderProp]: '4px solid' },
            tab: { [borderProp]: '4px solid' },
          });
        },
      },
      defaultProps: {
        colorScheme: 'green',
      },
    },
    Table: {
      variants: {
        borderless: ({ theme, ...rest }: WithTheme<TableProps>) => {
          const { simple } = theme.components.Table.variants;
          const simpleVariant = _.isFunction(simple) ? simple(rest) : simple;
          return _.merge({}, simpleVariant, {
            td: {
              borderColor: 'transparent',
            },
          });
        },
        'simple-hover': ({ theme, ...rest }: WithTheme<TableProps>) => {
          const { simple } = theme.components.Table.variants;
          const simpleVariant = _.isFunction(simple) ? simple(rest) : simple;
          return _.merge({}, simpleVariant, {
            tr: {
              _hover: {
                bg: 'green.50',
              },
            },
            thead: {
              th: {
                fontFamily: 'body',
                fontWeight: 'normal',
              },
              tr: {
                _hover: {
                  bg: 'transparent',
                },
              },
            },
          });
        },
      },
    },
    Modal: {
      sizes: {
        /*
         * By default, Chakra renders Modals with height: 100vh. This is fine
         * except when we need the Modal to occupy full-screen, in which case
         * the viewport size will not be computed accurately. We have several
         * scenarios where we depend on this computation in order to render
         * flex layouts where buttons are fixed to the bottom of the modal.
         *
         * Here we take the Chrome team's suggestion to replace the usage of
         * 100vh with 100%.
         *
         * @see https://stackoverflow.com/a/59020698
         * @see https://developers.google.com/web/updates/2016/12/url-bar-resizing
         */
        takeover: {
          dialogContainer: {
            h: '100%',
          },
          dialog: {
            h: '100%',
          },
        },
      },
      variants: {
        'coa-main': ({ size }) => ({
          overlay: {
            bg: 'evergreenAlpha.600',
          },
          header: {
            fontFamily: 'Nib',
            pt: 10,
            px: 8,
            pb: 0,
          },
          body: {
            px: 8,
            py: ['xs', 'sm'].includes(size) ? 4 : 8,
          },
          footer: {
            pt: 2,
            px: 8,
            pb: 10,
            justifyContent: 'flex-start',
          },
          closeButton: {
            sx: {
              top: 8,
              right: 8,
            },
          },
        }),
      },
    },
    ToggleButton: {
      baseStyle: {
        borderRadius: 'full',
        bg: 'transparent',
        fontWeight: 'normal',

        // Prevents collapse when displayed in a Group to
        // allow for horizontal scroll where necessary.
        flex: '0 0 auto',
        _disabled: {
          cursor: 'auto',
        },
      },
      defaultProps: {
        variant: 'light',
        colorScheme: 'green',
      },
      variants: {
        light: ({ colorScheme: c }: { colorScheme: string }) => ({
          color: 'gray.500',
          _hover: {
            color: 'gray.600',
          },
          _active: {
            color: 'gray.700',
          },
          _disabled: {
            color: 'gray.200',
          },
          _selected: {
            bg: `${c}.50`,
            color: `${c}.700`,
          },
        }),
      },
    },
    ToggleButtonGroup: {
      baseStyle: {
        borderTop: '1px solid',
        borderBottom: '1px solid',
        px: {
          base: 4,
          md: 0,
        },
        py: 4,
        justifyContent: {
          base: undefined,
          md: 'start',
        },
        // Allow for horizontal scroll.
        overflowX: 'auto',
        flexWrap: 'nowrap',
        display: 'flex',
        width: 'auto',
      },
      defaultProps: {
        variant: 'light',
        wrap: 'no-wrap',
      },
      variants: {
        light: () => ({
          borderColor: 'gray.50',
        }),
      },
    },
    // TODO: This would be FAR more readable were it to live
    // alongside the component itself.
    IconBadge: {
      baseStyle: {
        borderRadius: 'base',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'gray.50',
        color: 'gray.900',
        // TODO: These should be in sizes yeah?
        height: 6,
        width: 6,
      },
      variants: {
        solid: ({ colorScheme: _colorScheme = 'gray' }) => ({
          bg: `${_colorScheme}.50`,
          color: `${_colorScheme}.900`,
          _disabled: {
            bg: 'gray.50',
            color: 'gray.300',
          },
        }),
        /*
         * This component is particularly inflexible. Because it is
         * using "sx" under the hood rather than style-props, overriding
         * it is extremely tedious. This extremely specific variant
         * allows us to get around this - take this as a lesson as to
         * the disadvantages of the "sx" prop!
         */
        solidDarkRound: ({ colorScheme: _colorScheme = 'gray' }) => ({
          bg: `${_colorScheme}.700`,
          color: `white`,
          _disabled: {
            bg: 'gray.50',
            color: 'gray.300',
          },
          borderRadius: 'full',
        }),
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    IconListItem: {
      parts: ['parent', 'title', 'subtitle'],
      baseStyle: {
        title: {
          fontSize: 'xl',
          fontWeight: 'bold',
        },
        subtitle: {
          fontSize: 'sm',
        },
      },
      variants: {
        solid: {
          title: {
            _disabled: { color: 'gray.300' },
          },
          subtitle: {
            color: 'gray.500',
            _disabled: { color: 'gray.300' },
          },
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    EventCardLabelBanner: {
      parts: ['banner', 'text'],
      baseStyle: {
        labelBanner: {},
      },
      variants: {
        solid: (props: WithTheme<{ colorScheme?: string }>) => {
          const {
            colorScheme: _colorScheme = 'evergreen',
            theme: { fonts, fontWeights: _fontWeights },
          } = props;
          return {
            banner: { bg: `${_colorScheme}.200` },
            text: { color: 'white', fontFamily: fonts.heading, fontWeight: _fontWeights.bold },
          };
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    Ribbon: {
      parts: ['parent', 'ribbon', 'child'],
      baseStyle: ({ placement }) => {
        const [yPlacement, xPlacement] = placement.split('-');
        return {
          ribbon: { position: 'absolute' },
          parent: { position: 'absolute', [xPlacement]: 0, [yPlacement]: 0 },
          child: {
            width: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
          },
        };
      },
      sizes: {
        md: (props: WithTheme<{ placement: string } & BoxProps>) => {
          // Note: For now we hardcode width / height but we
          // can supply alternate widths in the future.
          const { placement, height = 70, width = 100 } = props;

          if (!_.isNumber(width) || !_.isNumber(height)) {
            throw Error('Height and width must be numbers.');
          }

          const [yPlacement, xPlacement] = placement.split('-');
          const xOppoPlacement = xPlacement === 'right' ? 'left' : 'right';
          const yOppoPlacement = yPlacement === 'top' ? 'bottom' : 'top';

          /*
           * Use trig to compute the rotation of the children.
           */
          const hypotenuse = Math.sqrt(height ** 2 + width ** 2);
          const angleInRadians = Math.asin(height / hypotenuse);
          const angleInDegrees = (angleInRadians * 180) / Math.PI;
          const rotation = ['top-left', 'bottom-right'].includes(placement)
            ? angleInDegrees * -1
            : angleInDegrees;

          return {
            parent: {
              height,
              width,
            },
            ribbon: {
              // Set height / width as zero so borders create the
              // triangle effect.
              // @see https://css-tricks.com/snippets/css/css-triangle/
              width: 0,
              height: 0,
              // Non-base triangle side uses transparent color to create
              // triangle effect.
              [`border${_.capitalize(xOppoPlacement)}`]: `${width}px solid transparent`,
              [`border${_.capitalize(yPlacement)}`]: `${height}px solid`,
            },
            child: {
              // Rotate inward from the vertical tip of the triangle.
              transform: `rotate(${rotation}deg)`,
              transformOrigin: `${yOppoPlacement} ${xPlacement}`,
              [yOppoPlacement]: 0,
              [xPlacement]: 0,

              // Provide some breathing room.
              [`padding${_.capitalize(xPlacement)}`]: 1,
              [`padding${_.capitalize(yOppoPlacement)}`]: 1,
            },
          };
        },
      },
      variants: {
        solid: (props: WithTheme<{ colorScheme?: string; placement: 'bottom-right' }>) => {
          const { colorScheme: _colorScheme, placement } = props;
          const [yPlacement] = placement.split('-');
          return {
            ribbon: {
              [`border${_.capitalize(yPlacement)}Color`]: `${_colorScheme}.500`,
            },
          };
        },
      },
      defaultProps: {
        variant: 'solid',
        size: 'md',
      },
    },
  },
});
