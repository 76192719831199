/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { update as accountUpdate } from '../actions/accounts';

dayjs.extend(utc);

const DEFAULT_CSS = { '::placeholder': { color: '#D1AE91' } };

const AccountInfoForm = ({ dispatch, account, beforeTherapySurvey, onSubmission }) => {
  const { name, pronouns, birthday, city, email, phone } = account.attributes;
  const toast = useToast();
  const utcBirthday = dayjs(birthday).utc();
  const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  const DetailsSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    phone: Yup.string().matches(phoneRegex, 'Phone number is not valid').nullable(),
  });

  return (
    <Box width="stretch">
      <Formik
        initialValues={{
          name,
          pronouns,
          city,
          email,
          phone,
          birthday: utcBirthday.format('YYYY-MM-DD'),
        }}
        validationSchema={DetailsSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          dispatch(accountUpdate(values)).then((result) => {
            setSubmitting(false);
            if (result.errors) {
              setErrors(result.errors);
              toast({
                title: 'Failed to save. See errors above.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            } else {
              if (onSubmission) onSubmission();
              if (!beforeTherapySurvey) {
                toast({
                  title: 'Saved!',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
              }
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <Field name="name">
              {({ field, form }) => (
                <FormControl p={1} isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel htmlFor="name">Full Name</FormLabel>
                  <Input
                    backgroundColor="warm.50"
                    css={DEFAULT_CSS}
                    {...field}
                    value={field.value ? field.value : ''}
                    id="name"
                    placeholder="your first and last name"
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="pronouns">
              {({ field, form }) => (
                <FormControl p={1} isInvalid={form.errors.pronoun && form.touched.pronoun}>
                  <FormLabel htmlFor="pronouns">Pronouns</FormLabel>
                  <Select
                    backgroundColor="warm.50"
                    css={DEFAULT_CSS}
                    {...field}
                    value={field.value ? field.value : ''}
                    placeholder="Not Specified"
                  >
                    <option value="(he/him)">he/him</option>
                    <option value="(she/her)">she/her</option>
                    <option value="(they/them)">they/them</option>
                  </Select>
                  <FormErrorMessage>{form.errors.pronouns}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="birthday">
              {({ field, form }) => (
                <FormControl p={1} isInvalid={form.errors.birthday && form.touched.birthday}>
                  <FormLabel htmlFor="birthday">Birthday</FormLabel>
                  <Input
                    backgroundColor="warm.50"
                    css={DEFAULT_CSS}
                    {...field}
                    type="date"
                    required={beforeTherapySurvey}
                    id="birthday"
                    placeholder="birthday"
                  />
                  <FormErrorMessage>{form.errors.birthday}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {!beforeTherapySurvey && (
              <Field name="city">
                {({ field, form }) => (
                  <FormControl p={1} isInvalid={form.errors.city && form.touched.city}>
                    <FormLabel htmlFor="city">City</FormLabel>
                    <Input
                      backgroundColor="warm.50"
                      css={DEFAULT_CSS}
                      {...field}
                      value={field.value ? field.value : ''}
                      id="city"
                      placeholder="city"
                    />
                    <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            {!beforeTherapySurvey && (
              <Field name="email">
                {({ field, form }) => (
                  <FormControl p={1} isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel htmlFor="email">Account Email Address</FormLabel>
                    <Input
                      backgroundColor="warm.50"
                      css={DEFAULT_CSS}
                      {...field}
                      value={field.value ? field.value : ''}
                      id="email"
                      placeholder="email"
                      type="email"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            {!beforeTherapySurvey && (
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl p={1} isInvalid={form.errors.phone && form.touched.phone}>
                    <FormLabel htmlFor="phone">Phone Number</FormLabel>
                    <Input
                      backgroundColor="warm.50"
                      css={DEFAULT_CSS}
                      {...field}
                      value={field.value ? field.value : ''}
                      id="phone"
                      type="tel"
                      placeholder="phone"
                    />
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Button
              my={4}
              width={{ base: 'auto', md: '20%' }}
              isLoading={props.isSubmitting}
              type="submit"
              colorScheme="evergreen"
            >
              {beforeTherapySurvey ? "Let's go!" : 'Update'}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AccountInfoForm;
