import React, { useContext, useEffect, useReducer } from 'react';
import { classMarketplaceAnalytics } from '../../../../lib/analytics/clients';
import {
  WorkshopDays,
  WorkshopFilter,
  WorkshopKind,
  WorkshopSkills,
  WorkshopTimings,
} from '../../../../resources/workshops';

export type ClassesFilterType = 'timings' | 'days' | 'skills';
export type ClassesFilterValues = WorkshopTimings | WorkshopSkills | WorkshopDays;

// REDUCER STATE
type ClassesFiltersContext = WorkshopFilter & {
  clearFilters: () => void;
  hasFiltersApplied: boolean;
  getWorkshopFilter: () => WorkshopFilter & { kind?: WorkshopKind };
  toggleFilter: (type: ClassesFilterType, filterValue: ClassesFilterValues) => () => void;
  kind?: WorkshopKind;
};

// CONTEXT VALUE HELPER FUNC
// eslint-disable-next-line arrow-body-style
const stateHasClassFilters = (state: ReducerState): boolean => {
  return Object.keys(state).some((key) =>
    Array.isArray(state[key]) ? state[key].length > 0 : false
  );
};

const getInitialContextValues = () => {
  const today = new Date();
  const timezoneOffset = today.getTimezoneOffset();
  return {
    timings: [],
    days: [],
    skills: [],
    timezone_offset: timezoneOffset,
  };
};

const toggleStringInArray = <T,>(array: T[], str: T): T[] => {
  if (array.includes(str)) {
    return array.filter((idx) => idx !== str);
  }
  return [str, ...array];
};

type ReducerState = Pick<ClassesFiltersContext, 'timings' | 'days' | 'skills' | 'timezone_offset'>;
type ReducerAction =
  | { type: 'TOGGLE_TIME_OF_DAY'; time: WorkshopTimings }
  | { type: 'TOGGLE_DAY_OF_WEEK'; day: WorkshopDays }
  | { type: 'TOGGLE_SKILL'; skill: WorkshopSkills }
  | { type: 'CLEAR_FILTERS' };

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'TOGGLE_TIME_OF_DAY':
      return {
        ...state,
        timings: toggleStringInArray<WorkshopTimings>(state.timings, action.time),
      };
    case 'TOGGLE_DAY_OF_WEEK':
      return {
        ...state,
        days: toggleStringInArray<WorkshopDays>(state.days, action.day),
      };
    case 'TOGGLE_SKILL':
      return {
        ...state,
        skills: toggleStringInArray<WorkshopSkills>(state.skills, action.skill),
      };
    case 'CLEAR_FILTERS':
      return getInitialContextValues();
    default:
      throw new Error(`[INVALID CLASSES FILTERS ACTION TYPE]: ${action}`);
  }
};

export const ClassesFiltersContext = React.createContext<ClassesFiltersContext | null>(null);

const ClassesFiltersContextProvider = ({
  children,
  kind = null,
}: {
  children: React.ReactNode;
  kind?: WorkshopKind;
}) => {
  const [state, dispatch] = useReducer<React.Reducer<ReducerState, ReducerAction>>(
    reducer,
    getInitialContextValues()
  );

  const toggleFilter = (type: ClassesFilterType, toggleValue: ClassesFilterValues) => () => {
    switch (type) {
      case 'timings':
        dispatch({ type: 'TOGGLE_TIME_OF_DAY', time: toggleValue as WorkshopTimings });
        break;
      case 'days':
        dispatch({ type: 'TOGGLE_DAY_OF_WEEK', day: toggleValue as WorkshopDays });
        break;
      case 'skills':
        dispatch({ type: 'TOGGLE_SKILL', skill: toggleValue as WorkshopSkills });
        break;
      default:
    }
  };

  const { days, timings, skills } = state;
  const filterValuesArr = [...days, ...skills, ...timings];
  useEffect(() => {
    if (filterValuesArr.length > 0) {
      classMarketplaceAnalytics.track('Filtered Class Marketplace', {
        filters: {
          days,
          skills,
          timings,
        },
      });
    }
  }, [filterValuesArr]);

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const hasFiltersApplied = stateHasClassFilters(state);

  const getWorkshopFilter = () => {
    const { timezone_offset } = state;
    const params: WorkshopFilter & { kind?: WorkshopKind } = {
      timings,
      skills,
      days,
      timezone_offset,
    };
    if (kind) {
      params.kind = kind;
    }
    return params;
  };

  const contextValue: ClassesFiltersContext = {
    clearFilters,
    toggleFilter,
    hasFiltersApplied,
    getWorkshopFilter,
    ...state,
  };

  return (
    <ClassesFiltersContext.Provider value={contextValue}>{children}</ClassesFiltersContext.Provider>
  );
};

export default ClassesFiltersContextProvider;

export const useClassesFiltersContext = () =>
  useContext<ClassesFiltersContext>(ClassesFiltersContext);
