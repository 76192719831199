import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { LOG_OUT } from './actions/sessions';
import { sessionLocalStorage } from './lib/localStorage/sessionLocalStorage';
import { account } from './reducers/accounts';
import { discountCodes as adminDiscountCodes } from './reducers/admin/discountCodes';
import { enrollees } from './reducers/admin/enrollees';
import { exercises as adminExercises } from './reducers/admin/exercises';
import { therapists } from './reducers/admin/therapists';
import { therapyMatches } from './reducers/admin/therapyMatches';
import { workshops as adminWorkshops } from './reducers/admin/workshops';
import { workshopTags } from './reducers/admin/workshopTags';
import { workshopTemplates } from './reducers/admin/workshopTemplates';
import { appointments } from './reducers/appointments';
import { clientPaymentProfiles } from './reducers/clientPaymentProfiles';
import { clients } from './reducers/clients';
import { enrollments } from './reducers/enrollments';
import { invoices } from './reducers/invoices';
import { matching } from './reducers/matching';
import { payouts } from './reducers/payouts';
import { session } from './reducers/session';
import { tags } from './reducers/tags';
import { therapistBillingProfiles } from './reducers/therapistBillingProfiles';
import { workshopOccurrences } from './reducers/workshopOccurrences';
import { workshops } from './reducers/workshops';

const createCombinedReducers = ({ history }) =>
  combineReducers({
    router: connectRouter(history),
    session,
    therapistBillingProfiles,
    clientPaymentProfiles,
    appointments,
    invoices,
    payouts,
    clients,
    workshops,
    workshopOccurrences,
    tags,
    account,
    matching,
    enrollments,
    admin: combineReducers({
      enrollees,
      therapists,
      workshopTemplates,
      workshopTags,
      therapyMatches,
      discountCodes: adminDiscountCodes,
      workshops: adminWorkshops,
      exercises: adminExercises,
    }),
  });

const createExecuteLogoutSideEffects = ({ queryClient }) => () => {
  sessionLocalStorage.destroy();
  queryClient.clear();
};

export const createRootReducer = ({ history, queryClient }) => {
  const combinedReducers = createCombinedReducers({ history });
  const executeLogoutSideEffects = createExecuteLogoutSideEffects({ queryClient });
  return (state, action) => {
    // If the action is a log-out action, we should destroy the entire
    // store so that it doesn't carry over after a login.
    const nextState =
      action.type === LOG_OUT
        ? {
            // Note that we DO want to retain router storage since history
            // is deeply-coupled to it. Destroying it results in a throw.
            router: state.router,
          }
        : state;

    // In the event of a log-out, we also want to manage several side-
    // effects to clear "non-reduxy" storage. In theory this happens
    // outside of redux in a custom hook in React, but because our
    // LogOut logic heavily relies on redux - particularly in the context
    // of our ApiMiddleware - we execute these side-effects here for now.
    if (action.type === LOG_OUT) executeLogoutSideEffects();

    return combinedReducers(nextState, action);
  };
};
