import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftIcon } from '../../../../components/Icons';
import { useCmsContext } from './useAdminCmsContext';
import { useAdminCmsViewStateContext } from './useAdminCmsViewStateContext';

const CmsEntryNavHeading = () => {
  const { contentTypeTitle, contentHelperText, useShowQuery, getEntryTitle } = useCmsContext();
  const { cmsViewState } = useAdminCmsViewStateContext();
  const { id } = cmsViewState.meta || {};
  const showQuery = useShowQuery({ id }, { enabled: Boolean(id) });

  if (showQuery.isLoading) return <Heading size="lg">Loading...</Heading>;

  if (cmsViewState.view === 'create') {
    return <Heading size="lg">New {contentTypeTitle}</Heading>;
  }
  if (cmsViewState.view === 'show') {
    return (
      <>
        <Heading size="lg" display="inline">
          {showQuery.isLoading ? 'Loading' : getEntryTitle(showQuery.data)}
        </Heading>
        <Text ml={2} color="gray.500" display="inline">
          {contentTypeTitle}
        </Text>
      </>
    );
  }
  // TODO: Implement actual pluralization
  return (
    <>
      <Heading size="lg">{contentTypeTitle}s</Heading>
      <Text color="gray.500">{contentHelperText}</Text>
    </>
  );
};

export const CmsEntryNav = ({
  showNavigateHome,
  renderNavCta = () => null,
}: {
  showNavigateHome?: boolean;

  renderNavCta?: () => React.ReactNode;
}) => {
  const { contentTypeTitle } = useCmsContext();
  const { setCmsViewState, cmsViewState } = useAdminCmsViewStateContext();
  const isIndexView = cmsViewState.view === 'index';
  const history = useHistory();
  return (
    <Box>
      {isIndexView && !showNavigateHome ? null : (
        <Button
          variant="link"
          leftIcon={<ArrowLeftIcon />}
          onClick={() => {
            if (isIndexView) {
              history.push('/admin-dash/cms');
            } else {
              setCmsViewState({ view: 'index' });
            }
          }}
          mb={4}
        >
          {isIndexView
            ? 'Content Home'
            : `All ${
                // TODO: Proper plurals
                contentTypeTitle
              }s`}
        </Button>
      )}
      <Flex>
        <Box width="100%" mb={8} alignItems="baseline">
          <CmsEntryNavHeading />
        </Box>
        {renderNavCta()}
      </Flex>
    </Box>
  );
};

export const AdminCmsLayout = ({ children }: { children: React.ReactNode }) => (
  <Container maxW="container.lg">
    <CmsEntryNav showNavigateHome />
    <>{children}</>
  </Container>
);
