import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleTextField from './SimpleTextField';

class NameSection extends React.Component {
  render() {
    const { firstName, updateFirstName, lastName, updateLastName } = this.props;
    return (
      <Box>
        <SimpleTextField
          type="name"
          name="firstname"
          label="Billing First Name"
          updateAttr={updateFirstName}
          data-cy="billing-first-name"
          value={firstName}
          errors={{}}
        />
        <SimpleTextField
          type="name"
          name="lastname"
          label="Billing Last Name"
          data-cy="billing-last-name"
          updateAttr={updateLastName}
          value={lastName}
          errors={{}}
        />
      </Box>
    );
  }
}

NameSection.propTypes = {
  firstName: PropTypes.string,
  updateFirstName: PropTypes.func,
  lastName: PropTypes.string,
  updateLastName: PropTypes.func,
};

export default NameSection;
