import { Box, Button, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

function InvoiceSent({ backToDashboard }) {
  return (
    <Box textAlign="center">
      <Text>Success! We'll let you know when the invoice gets paid.</Text>
      <Button m={3} colorScheme="evergreen" onClick={backToDashboard}>
        Back to Dashboard
      </Button>
    </Box>
  );
}

InvoiceSent.propTypes = {
  backToDashboard: PropTypes.func.isRequired,
};

export default InvoiceSent;
