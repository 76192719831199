import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { AddSmallIcon, ArrowRightIcon } from './Icons';
import SimpleTextField from './SimpleTextField';

function CreateAppointment({
  loading,
  errors,
  existingClients,
  clientMemberName,
  clientMemberEmail,
  createAppointment,
  updateAttr,
  createAppointmentForClient,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box width="stretch" maxWidth="80%">
      <Box>
        <Flex py={8}>
          <Flex flexGrow={1} align="center">
            <Heading>Clients</Heading>
          </Flex>
          <Button colorScheme="evergreen" leftIcon={<AddSmallIcon />} onClick={onOpen}>
            New Client
          </Button>
        </Flex>
        <Divider />
        <VStack>
          {existingClients.map((client) => (
            <React.Fragment key={client.id}>
              <Box width="stretch" py={5}>
                <Flex alignItems="center" width="stretch">
                  <Box flexGrow={1}>
                    <Text fontSize="2xl">{client.attributes.name}</Text>
                    <Text fontSize="md">{client.attributes.email}</Text>
                  </Box>
                  <Box>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => createAppointmentForClient(client)}
                      icon={<ArrowRightIcon />}
                    />
                  </Box>
                </Flex>
              </Box>
              <Divider />
            </React.Fragment>
          ))}
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading>New Client</Heading>
            </ModalHeader>
            <ModalCloseButton borderRadius="50%" bg="green.50" />
            <form noValidate onSubmit={createAppointment}>
              <ModalBody>
                <SimpleTextField
                  type="name"
                  name="client_member_name"
                  label="Your Client's Name"
                  updateAttr={updateAttr}
                  value={clientMemberName}
                  placeholder="name"
                  autoFocus
                  errors={{ client_member_name: errors.name }}
                />
                <SimpleTextField
                  type="email"
                  name="client_member_email"
                  label="Your Client's Email"
                  placeholder="email"
                  updateAttr={updateAttr}
                  value={clientMemberEmail}
                  errors={{ client_member_email: errors.email }}
                />
              </ModalBody>
              <ModalFooter>
                <Button type="submit" colorScheme="evergreen" disabled={loading}>
                  {loading ? 'Adding Client...' : 'Add Client'}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

CreateAppointment.propTypes = {
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  existingClients: PropTypes.array.isRequired,
  clientMemberName: PropTypes.string.isRequired,
  clientMemberEmail: PropTypes.string.isRequired,
  createAppointment: PropTypes.func.isRequired,
  updateAttr: PropTypes.func.isRequired,
  createAppointmentForClient: PropTypes.func.isRequired,
};

export default CreateAppointment;
