import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Table,
  Tbody,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Instructor, MediaImageRecord } from '@coa/api/models';
import { PollRecord } from '@coa/api/models/poll';
import { AdminSerializedInstructor } from '@coa/api/serializers/admin/AdminSerializedInstructor';
import { AdminSerializedMediaImage } from '@coa/api/serializers/admin/AdminSerializedMediaImage';
import { AdminSerializedPoll } from '@coa/api/serializers/admin/AdminSerializedPoll';
import { getIndefiniteArticle } from '@coa/stdlib/string';
import { WidgetProps } from '@rjsf/core';
import React from 'react';
import {
  CheckCircleIcon,
  LinkIcon,
  MoreHorizontalIcon,
  SearchIcon,
} from '../../../../components/Icons';
import { AdminInstructorsCmsContextProvider } from '../instructors/AdminInstructorsView';
import { AdminInstructorFormData } from '../instructors/types';
import { AdminMediaImagesCmsContextProvider } from '../media/AdminMediaImageView';
import { AdminMediaImageFormData } from '../media/types';
import { AdminPollsCmsContextProvider } from '../polls/AdminPollsView';
import { AdminCmsPollFormData } from '../polls/types';
import { AdminCmsCreate } from './AdminCmsCreateView';
import { AdminCmsIndex } from './AdminCmsIndexView';
import { CmsEntryNav } from './AdminCmsLayout';
import { AdminCmsShow } from './AdminCmsShowView';
import {
  BaseCmsFormData,
  BaseResource,
  BaseSerializedResource,
  useCmsContext,
} from './useAdminCmsContext';
import {
  AdminCmsManualViewStateContextProvider,
  useAdminCmsViewStateContext,
} from './useAdminCmsViewStateContext';

const AdminCmsReferenceIdWidgetTitleText = () => {
  const { contentTypeTitle } = useCmsContext();
  return (
    <>
      Select {getIndefiniteArticle(contentTypeTitle)} {contentTypeTitle}
    </>
  );
};

const AdminCmsReferenceIdWidgetPreview = ({
  id,
  onRequestChooseNewValue,
  ...rest
}: Pick<BaseSerializedResource, 'id'> &
  BoxProps & {
    onRequestChooseNewValue: () => void;
  }) => {
  const { useShowQuery, renderIndexEntryTdContents, contentTypeTitle } = useCmsContext();
  const showQuery = useShowQuery({ id });
  if (showQuery.isLoading) return null; // TODO: Proper loading state
  return (
    <Box boxShadow="md" borderWidth="1px" borderColor="gray.50" rounded="md" {...rest}>
      <Flex
        py={2}
        justifyContent="flex-end"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor="gray.50"
      >
        <Text mb={0} textStyle="earmark" fontWeight="normal" color="gray.400">
          {contentTypeTitle}
        </Text>
        <Menu>
          <MenuButton as={IconButton} icon={<MoreHorizontalIcon />} variant="link" />
          <MenuList>
            <MenuItem icon={<SearchIcon />} onClick={onRequestChooseNewValue}>
              Select New Value
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Table variant="borderless">
        <Tbody>
          <Tr>{renderIndexEntryTdContents(showQuery.data)}</Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export type AdminCmsReferenceIdModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  onChange: (_val: string) => void;
};

const AdminCmsReferenceIdModalContents = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>({
  onChange,
  onClose,
}: Pick<AdminCmsReferenceIdModalProps, 'onChange' | 'onClose'>) => {
  const { cmsViewState, setCmsViewState } = useAdminCmsViewStateContext();
  switch (cmsViewState.view) {
    case 'index':
      return (
        <AdminCmsIndex<Resource, SerializedResource, CmsFormData>
          renderIndexEntryCtaTdContents={({ pageEntry }) => (
            <>
              <Menu>
                <MenuButton as={IconButton} icon={<MoreHorizontalIcon />} variant="link" />
                <MenuList>
                  <MenuItem
                    icon={<CheckCircleIcon />}
                    onClick={() => {
                      onChange(pageEntry.id);
                      onClose();
                    }}
                  >
                    Select
                  </MenuItem>
                  <MenuItem
                    icon={<LinkIcon />}
                    onClick={() => {
                      setCmsViewState({ view: 'show', meta: { id: pageEntry.id } });
                    }}
                  >
                    View Entry
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
        />
      );
    case 'create':
      return <AdminCmsCreate<Resource, SerializedResource, CmsFormData> />;
    case 'show':
      return <AdminCmsShow<Resource, SerializedResource, CmsFormData> />;
    default:
      throw Error('Cannot render view');
  }
};

const AdminCmsReferenceIdModalNav = ({
  onChange,
  onClose,
}: Pick<AdminCmsReferenceIdModalProps, 'onChange' | 'onClose'>) => {
  const { cmsViewState, setCmsViewState } = useAdminCmsViewStateContext();
  const { contentTypeTitle } = useCmsContext();
  return (
    <CmsEntryNav
      renderNavCta={() =>
        cmsViewState.view === 'show' ? (
          <Button
            px={10}
            variant="primary"
            onClick={() => {
              onChange(cmsViewState.meta.id);
              // We need to reset the view state - otherwise upon relaunch
              // the UI will render the show view again.
              setCmsViewState({ view: 'index' });
              onClose();
            }}
          >
            Select {contentTypeTitle}
          </Button>
        ) : null
      }
    />
  );
};

export const AdminCmsReferenceIdModal = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>({
  isOpen,
  onClose,
  onChange,
}: AdminCmsReferenceIdModalProps) => (
  <AdminCmsManualViewStateContextProvider<SerializedResource>>
    <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <AdminCmsReferenceIdModalNav onChange={onChange} onClose={onClose} />
          <AdminCmsReferenceIdModalContents<Resource, SerializedResource, CmsFormData>
            onChange={onChange}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  </AdminCmsManualViewStateContextProvider>
);
export const AdminCmsReferenceIdWidget = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>(
  props: WidgetProps
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onChange, label, schema, id, value } = props;
  return (
    <>
      <AdminCmsReferenceIdModal<Resource, SerializedResource, CmsFormData>
        isOpen={isOpen}
        onClose={onClose}
        onChange={onChange}
      />
      <FormLabel>{schema.title || label || id.replace(/^root_/, '')}</FormLabel>
      {value ? (
        <AdminCmsReferenceIdWidgetPreview id={value} mb={4} onRequestChooseNewValue={onOpen} />
      ) : (
        <Button onClick={onOpen} type="button" variant="secondary">
          <AdminCmsReferenceIdWidgetTitleText />
        </Button>
      )}
    </>
  );
};

export const AdminCmsInstructorIdReferenceWidget = (props: WidgetProps) => (
  <AdminInstructorsCmsContextProvider>
    <AdminCmsReferenceIdWidget<Instructor, AdminSerializedInstructor, AdminInstructorFormData>
      {...props}
    />
  </AdminInstructorsCmsContextProvider>
);

export const AdminCmsPollIdReferenceWidget = (props: WidgetProps): JSX.Element => (
  <AdminPollsCmsContextProvider>
    <AdminCmsReferenceIdWidget<PollRecord, AdminSerializedPoll, AdminCmsPollFormData> {...props} />
  </AdminPollsCmsContextProvider>
);

export const AdminCmsVectorMediaImageIdReferenceWidget = (props: WidgetProps): JSX.Element => (
  <AdminMediaImagesCmsContextProvider
    contextOverrides={{
      staticSearchParams: {
        asset_content_kind: 'vector',
      },
    }}
  >
    <AdminCmsReferenceIdWidget<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData>
      {...props}
    />
  </AdminMediaImagesCmsContextProvider>
);

export const AdminCmsRasterMediaImageIdReferenceWidget = (props: WidgetProps): JSX.Element => (
  <AdminMediaImagesCmsContextProvider
    contextOverrides={{
      staticSearchParams: {
        asset_content_kind: 'raster',
      },
    }}
  >
    <AdminCmsReferenceIdWidget<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData>
      {...props}
    />
  </AdminMediaImagesCmsContextProvider>
);

export const AdminCmsMediaImageIdModal = (props: AdminCmsReferenceIdModalProps): JSX.Element => (
  <AdminMediaImagesCmsContextProvider>
    <AdminCmsReferenceIdModal<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData>
      {...props}
    />
  </AdminMediaImagesCmsContextProvider>
);
