import { MenuButtonProps } from '@chakra-ui/react';
import React from 'react';
import { EventListingMenu } from '../../../../components/compositions/EventListingMenu';
import {
  formatWorkshopOccurrenceTimeLabel,
  useGetWorkshopOccurrenceSiblingsQuery,
  useGetWorkshopQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../../resources/workshops';
import { usePickAndNavigateToAlternateWorkshopRouterPath } from '../hooks/usePickAndNavigateToAlternateWorkshopRouterPath';

type AlternateWorkshopOccurrenceProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId: WorkshopOccurrence['id'];
  getLinkRoute: (_params: { id: string; dropInWorkshopOccurrenceId: string }) => string;
};

type DropInSiblingsPickerProps = AlternateWorkshopOccurrenceProps & MenuButtonProps;

export const DropInSiblingsPicker = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  getLinkRoute,
  ...rest
}: DropInSiblingsPickerProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const getWorkshopOccurrenceSiblingsQuery = useGetWorkshopOccurrenceSiblingsQuery({
    id: dropInWorkshopOccurrenceId,
  });
  const {
    pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath,
    getPickedAlternateWorkshopQuery,
    getPickedAlternateSiblingsQuery,
  } = usePickAndNavigateToAlternateWorkshopRouterPath();

  if (getWorkshopQuery.isLoading || getWorkshopOccurrenceSiblingsQuery.isLoading) {
    return <EventListingMenu isLoading {...rest} />;
  }

  const { data: workshop } = getWorkshopQuery;
  const { therapist, workshopOccurrences } = workshop;
  const { data: workshopOccurrenceSiblings = [] } = getWorkshopOccurrenceSiblingsQuery;

  const workshopOccurrence = workshopOccurrences.find(
    ({ id }) => id === dropInWorkshopOccurrenceId
  );

  const active = {
    label: formatWorkshopOccurrenceTimeLabel(workshopOccurrence),
    instructorName: therapist.name,
    instructorImage: therapist.imageUrl,
  };

  const options = workshopOccurrenceSiblings.map((occEntry) => ({
    label: formatWorkshopOccurrenceTimeLabel(occEntry),
    instructorName: occEntry.therapist.name,
    instructorImage: occEntry.therapist.imageUrl,
    ...(occEntry.workshop.soldOut
      ? {
          disabled: true,
          badgeProps: {
            colorScheme: 'red',
            variant: 'solid',
            children: 'FULL',
          },
        }
      : {
          onClick: () => {
            pickAndNavigateToAlternateWorkshopOrOccurrenceRouterPath({
              workshopId: occEntry.workshop.id,
              dropInWorkshopOccurrenceId: occEntry.id,
              linkRoute: getLinkRoute({
                id: occEntry.workshop.id,
                dropInWorkshopOccurrenceId: occEntry.id,
              }),
            });
          },
        }),
  }));

  return (
    <EventListingMenu
      active={active}
      options={options}
      isPendingNavigation={
        getPickedAlternateWorkshopQuery.isLoading || getPickedAlternateSiblingsQuery.isLoading
      }
      {...rest}
    />
  );
};
