import {
  PAYOUTS_LIST_FAILURE,
  PAYOUTS_LIST_REQUEST,
  PAYOUTS_LIST_SUCCESS,
} from '../actions/payouts';

const DEFAULT_STATE = {
  payouts: [],
  gettingPayouts: false,
};

export function payouts(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case PAYOUTS_LIST_REQUEST: {
      return { ...state, payouts: [], gettingPayouts: true };
    }
    case PAYOUTS_LIST_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, payouts: data, gettingPayouts: false };
    }
    case PAYOUTS_LIST_FAILURE: {
      return { ...state, payouts: [], gettingPayouts: false };
    }
    default:
      return state;
  }
}
