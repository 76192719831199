import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  FormControl,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { AdminCmsIndexFilterParams, AdminCmsPageNum } from '@coa/api/controllers/admin/cms';
import { RouterLink } from '@coa/react-utils';
import {
  formatEditTime,
  getIndefiniteArticle,
  pluralizeIfPlural,
  serverCase,
} from '@coa/stdlib/string';
import { AnyObject } from '@coa/types';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusSquareIcon,
  SearchIcon,
  TrashIcon,
} from '../../../../components/Icons';
import { AdminCmsLayout } from './AdminCmsLayout';
import {
  BaseCmsFormData,
  BaseResource,
  BaseSerializedResource,
  CmsContextParams,
  useCmsContext,
} from './useAdminCmsContext';
import {
  AdminCmsRouterViewStateContextProvider,
  useAdminCmsViewStateContext,
} from './useAdminCmsViewStateContext';

const invertOrder = (order: string) => (order === 'asc' ? 'desc' : 'asc');

export const IndexTableHeading = ({
  name,
  params,
  children,
  setParams,
}: {
  name: string;
  params: { order_by: string; order: string };
  children: React.ReactNode;
  setParams: (_params: AnyObject) => void;
}) => {
  // see order_by note below*
  const serverCaseName = serverCase(name);
  const headerSelected = params.order_by === serverCaseName;
  const order = headerSelected ? invertOrder(params.order) : 'desc';
  return (
    <Link
      onClick={() => {
        // "name" needs to match w/ backend resource key (serverCase)
        // order_by Url param value is 1:1 with resource key (ex: scheduled_at, not scheduledAt)
        setParams({ order, order_by: serverCaseName });
      }}
    >
      {headerSelected ? (
        <strong>
          {children} {params.order === 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </strong>
      ) : (
        <>{children}</>
      )}
    </Link>
  );
};

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  handleClickPageButton: (pageNum: AdminCmsPageNum) => void;
} & FlexProps;

const CmsPagination = ({
  currentPage,
  totalPages,
  handleClickPageButton,
  ...rest
}: PaginationProps) => (
  <Flex flexDirection="row" {...rest}>
    <IconButton
      borderRadius="full"
      icon={<ChevronLeftIcon />}
      aria-label="Previous"
      variant="outline"
      disabled={currentPage <= 1}
      onClick={() => {
        handleClickPageButton(currentPage - 1);
      }}
      mr={1}
    />
    {_.range(1, totalPages + 1).map((pageNum) => (
      <Button
        key={pageNum}
        borderRadius="full"
        variant={pageNum === currentPage ? 'secondary' : 'ghost'}
        onClick={() => handleClickPageButton(pageNum)}
        mx={1}
      >
        {pageNum}
      </Button>
    ))}
    <IconButton
      borderRadius="full"
      icon={<ChevronRightIcon />}
      aria-label="Next"
      variant="outline"
      disabled={currentPage >= totalPages}
      onClick={() => {
        handleClickPageButton(currentPage + 1);
      }}
      ml={1}
    />
  </Flex>
);
const CmsSearchBar = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource
>({
  searchTermFromParams,
  handleSubmit,
  ...rest
}: {
  searchTermFromParams: string;
  handleSubmit: (s: string) => void;
} & BoxProps) => {
  const { searchPlaceholder } = useCmsContext<Resource, SerializedResource>();
  const [searchTerm, setSearchTerm] = useState(searchTermFromParams);
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(searchTerm);
  };
  useEffect(() => {
    if (searchTerm !== searchTermFromParams) setSearchTerm(searchTermFromParams);
  }, [searchTermFromParams]);
  return (
    <Box as="form" onSubmit={onSubmit} {...rest}>
      <FormControl display="flex" w="100%">
        <InputGroup>
          <Input
            variant="coa-main"
            type="text"
            aria-label="Search Input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={searchPlaceholder}
            size="lg"
          />
          <InputRightElement height="100%" width="4rem">
            <Button
              aria-label="Search Submit"
              type="submit"
              borderLeftRadius={0}
              colorScheme="warm"
              variant="link"
              height="100%"
              width="100%"
            >
              <SearchIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Box>
  );
};

const EntryCountLabel = ({
  totalSize,
  currentPage,
  pageSize,
  totalPages,
  ...rest
}: {
  totalPages: number;
  totalSize: number;
  currentPage: number;
  pageSize: number;
} & TextProps) => {
  if (totalPages === 1) {
    return (
      <Text {...rest}>
        <strong>{totalSize}</strong> {pluralizeIfPlural('entry', totalSize)} found
      </Text>
    );
  }
  const pageIndex = currentPage - 1;
  const rangeStart = pageIndex * pageSize + 1;
  const rangeEnd = currentPage === totalPages ? totalSize : (pageIndex + 1) * pageSize;
  const rangeString = `${rangeStart}-${rangeEnd}`;
  return (
    <Text {...rest}>
      Showing <strong>{rangeString}</strong> of <strong>{totalSize}</strong>{' '}
      {pluralizeIfPlural('entry', totalSize)} found
    </Text>
  );
};

const TableEmptyState = (): JSX.Element => {
  const { contentTypeTitle, createRouterPath } = useCmsContext();
  return (
    <Box bg="gray.50" borderRadius="base" px={4} py={16} textAlign="center">
      <Heading size="md">There's nothing here.</Heading>
      <Text>
        <Link as={RouterLink} to={createRouterPath} variant="underline">
          Add {getIndefiniteArticle(contentTypeTitle)} {contentTypeTitle}
        </Link>{' '}
        to get started.
      </Text>
    </Box>
  );
};

export const AdminCmsIndex = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>({
  renderIndexEntryCtaTdContents,
}: {
  renderIndexEntryCtaTdContents: ({
    pageEntry,
    cmsContext: _cmsContext,
  }: {
    pageEntry: SerializedResource;
    cmsContext: CmsContextParams<Resource, SerializedResource, CmsFormData>;
  }) => React.ReactNode;
}) => {
  const cmsContext = useCmsContext<Resource, SerializedResource, CmsFormData>();
  const {
    useIndexQuery,
    indexThEntries,
    renderIndexEntryTdContents,
    contentTypeTitle,
    staticSearchParams = {},
  } = cmsContext;
  const { params, setParams, setCmsViewState } = useAdminCmsViewStateContext();

  const indexQuery = useIndexQuery(
    // TODO: Asserting this type here defeats the purpose - it's not quite clear how we
    // should type URLSearchParams, but this at least gives us some sense of safety
    // in the meantime.
    ({
      // Supports cases where we wish to add static params to every index request.
      ...staticSearchParams,
      ...params,
    } as unknown) as AdminCmsIndexFilterParams<SerializedResource>,
    { keepPreviousData: true }
  );
  if (indexQuery.isLoading) return null;

  const {
    data: { page: pageEntries, meta },
  } = indexQuery;
  return (
    <>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} width="100%" mb={4}>
        <GridItem colSpan={3}>
          <CmsSearchBar<Resource, SerializedResource>
            searchTermFromParams={params.query || ''}
            handleSubmit={(_query: string) => {
              setParams({ query: _query });
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Button
            variant="secondary"
            width="100%"
            height="100%"
            leftIcon={<PlusSquareIcon />}
            onClick={() => setCmsViewState({ view: 'create' })}
          >
            Add {contentTypeTitle}
          </Button>
        </GridItem>
      </Grid>
      <Flex mb={4}>
        <EntryCountLabel
          mr={4}
          totalPages={meta.totalPages}
          totalSize={meta.totalSize}
          currentPage={meta.page}
          pageSize={meta.pageSize}
        />
        {params.query ? (
          <Button
            size="xs"
            leftIcon={<TrashIcon />}
            onClick={() => {
              setParams({ query: '' });
            }}
          >
            Clear search
          </Button>
        ) : null}
      </Flex>
      <Box rounded="md" overflow="hidden" boxShadow="md" bg="white">
        {pageEntries.length > 0 ? (
          <>
            <Table variant="simple-hover">
              <Thead>
                <Tr>
                  {indexThEntries.map(({ name, label }) => (
                    <Th key={String(name)}>
                      <IndexTableHeading
                        params={params}
                        name={
                          // Resource extends AnyObject, which is indexed by string, so it's
                          // not clear why we need this assertion.
                          name as string
                        }
                        setParams={setParams}
                      >
                        {label}
                      </IndexTableHeading>
                    </Th>
                  ))}
                  <Th>
                    <IndexTableHeading params={params} name="createdAt" setParams={setParams}>
                      Created
                    </IndexTableHeading>
                  </Th>
                  <Th>
                    <IndexTableHeading params={params} name="updatedAt" setParams={setParams}>
                      Updated
                    </IndexTableHeading>
                  </Th>
                  <Th width="1%" />
                </Tr>
              </Thead>
              <Tbody>
                {pageEntries.map((pageEntry: SerializedResource) => (
                  <Tr key={pageEntry.id}>
                    {renderIndexEntryTdContents(pageEntry)}
                    <Td>{formatEditTime(pageEntry.createdAt)}</Td>
                    <Td>{formatEditTime(pageEntry.updatedAt)}</Td>
                    <Td>{renderIndexEntryCtaTdContents({ pageEntry, cmsContext })}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : (
          <TableEmptyState />
        )}
      </Box>
      {pageEntries.length > 0 ? (
        <CmsPagination
          mt={6}
          currentPage={meta.page}
          totalPages={meta.totalPages}
          handleClickPageButton={(page: AdminCmsPageNum) => {
            setParams({ page });
          }}
        />
      ) : null}
    </>
  );
};

const IndexEntryCtaIconButton = ({ id }: { id: string }) => {
  const { setCmsViewState } = useAdminCmsViewStateContext();
  const { contentTypeTitle } = useCmsContext();
  return (
    <IconButton
      onClick={() => setCmsViewState({ view: 'show', meta: { id } })}
      icon={<ArrowRightIcon />}
      aria-label={`${contentTypeTitle} Details`}
      bg="transparent"
    />
  );
};

export const AdminCmsIndexView = <
  Resource extends BaseResource,
  SerializedResource extends BaseSerializedResource,
  CmsFormData extends BaseCmsFormData
>() => (
  <AdminCmsRouterViewStateContextProvider<SerializedResource>>
    <AdminCmsLayout>
      <AdminCmsIndex<Resource, SerializedResource, CmsFormData>
        renderIndexEntryCtaTdContents={({ pageEntry }) => (
          <IndexEntryCtaIconButton id={pageEntry.id} />
        )}
      />
    </AdminCmsLayout>
  </AdminCmsRouterViewStateContextProvider>
);
