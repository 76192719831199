import { AxiosRequestConfig, AxiosTransformer } from 'axios';
import _ from 'lodash';

type AxiosTransformerFnOrArray = AxiosTransformer | AxiosTransformer[];

/*
 * Helper that  manages some flexibility in the Axios API that
 * allows for these transforms to be supplied as singleton fns
 * or as arrays of functions intended to be composed via a
 * functional flow. We  this complexity for
 */
const composeAxiosTransforms = (
  rawTransform1: AxiosTransformerFnOrArray,
  rawTransform2: AxiosTransformerFnOrArray
): AxiosTransformer[] => {
  const transform1Arr = _.isArray(rawTransform1) ? rawTransform1 : [rawTransform1];
  const transform2Arr = _.isArray(rawTransform2) ? rawTransform2 : [rawTransform2];
  return [...transform1Arr, ...transform2Arr];
};

/**
 * Generator that assists in creating helpers that add
 * transform middleware utilities used to configure axios
 * clients. In particular, manages the API flexibility that
 * allows for these transforms to be supplied as singleton
 * functions or as arrays of functions intended to be
 * composed via a functional flow. This is solely for DRY.
 */
const generateAddTransform = (key: 'transformRequest' | 'transformResponse') => (
  config: AxiosRequestConfig,
  additionalTransform: AxiosRequestConfig[typeof key]
): AxiosRequestConfig => {
  const existingTransform = config[key] || [];
  const newTransform = composeAxiosTransforms(existingTransform, additionalTransform);
  config[key] = newTransform;
  return config;
};

export const addTransformRequest = generateAddTransform('transformRequest');
export const addTransformResponse = generateAddTransform('transformResponse');
