import React, { createContext, useContext, useEffect } from 'react';

type GlobalLayoutConfig = {
  hideNavMobile: boolean;
  hideGlobalLegalLinks: boolean;
  removeGlobalPaddingTop: boolean;
};

const defaultGlobalLayoutConfig = {
  hideNavMobile: false,
  hideGlobalLegalLinks: false,
  removeGlobalPaddingTop: false,
};

const GlobalLayoutContext = createContext(null);
export const GlobalLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [globalLayoutConfig, setGlobalLayoutConfig] = React.useState<GlobalLayoutConfig>(
    defaultGlobalLayoutConfig
  );

  return (
    <GlobalLayoutContext.Provider value={{ globalLayoutConfig, setGlobalLayoutConfig }}>
      {children}
    </GlobalLayoutContext.Provider>
  );
};

export const useGlobalLayoutConfig = () => useContext(GlobalLayoutContext);

export const useHideNavMobile = () => {
  const { setGlobalLayoutConfig } = useGlobalLayoutConfig();
  useEffect(() => {
    setGlobalLayoutConfig({ hideNavMobile: true });
    return () => {
      setGlobalLayoutConfig({ hideNavMobile: false });
    };
  }, []);
};

/* There's some globally applied paddingTop originally in AppLayout.tsx
 * Also, we're always displaying the <LegaLinks /> as a full width <div> at the bottom
 *  of the page, but using this hook will remove the following from AppLayout.tsx
 *  - the globally applied paddingTop && the full width
 * - the full width <LegalLinks />
 *
 * Why?: the TeamMembership has a new layout that
 * required flexibility and removing the padding and legal links completely
 * was the fastest way forward. Ideally we'd have the ability to give individual screens
 * their own layout if we continue to require additional flexibility
 */
export const useFullScreenLayout = () => {
  const { setGlobalLayoutConfig } = useGlobalLayoutConfig();
  useEffect(() => {
    setGlobalLayoutConfig({
      hideGlobalLegalLinks: true,
      removeGlobalPaddingTop: true,
    });
    return () => {
      setGlobalLayoutConfig({
        hideGlobalLegalLinks: false,
        removeGlobalPaddingTop: false,
      });
    };
  }, []);
};

export const useHideLegalLinks = () => {
  const { setGlobalLayoutConfig } = useGlobalLayoutConfig();
  useEffect(() => {
    setGlobalLayoutConfig({ hideGlobalLegalLinks: true });
    return () => {
      setGlobalLayoutConfig({ hideGlobalLegalLinks: false });
    };
  }, []);
};

export const useRemoveGlobalPaddingTop = () => {
  const { setGlobalLayoutConfig } = useGlobalLayoutConfig();
  useEffect(() => {
    setGlobalLayoutConfig({ removeGlobalPaddingTop: true });
    return () => {
      setGlobalLayoutConfig({ useRemoveGlobalPaddingTop: false });
    };
  }, []);
};
