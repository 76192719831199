import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';

export const VideoComplete = () => (
  <Box position="absolute" top="0" left="0" height="100%" width="100%" bg="black">
    <Flex flexDirection="column" justifyContent="center" height="100%" p={12} alignItems="baseline">
      <Text fontSize="2xl" color="white" mb="6">
        End of video
      </Text>
      <Flex alignItems="center">
        <Button colorScheme="gray" as={RouterLink} to="/on-demand" width="auto">
          Browse On Demand Content
        </Button>
      </Flex>
    </Flex>
  </Box>
);
