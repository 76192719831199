import _ from 'lodash';
import {
  WORKSHOP_TEMPLATES_LIST_FAILURE,
  WORKSHOP_TEMPLATES_LIST_REQUEST,
  WORKSHOP_TEMPLATES_LIST_SUCCESS,
} from '../../actions/admin/workshopTemplates';

// type ReducerShape = {
//   gettingWorkshopTemplates: ?boolean,
//   workshopTemplates: {
//     [id]: WorkshopTemplate,
//   },
// }

const DEFAULT_STATE = {
  gettingWorkshopTemplates: null,
  workshopTemplates: {},
};

export function workshopTemplates(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case WORKSHOP_TEMPLATES_LIST_REQUEST: {
      return { ...state, gettingWorkshopTemplates: true };
    }
    case WORKSHOP_TEMPLATES_LIST_SUCCESS: {
      const {
        response: { data },
      } = action;
      return {
        ...state,
        workshopTemplates: _(data).filter('attributes.title').keyBy('id').value(),
        gettingWorkshopTemplates: false,
      };
    }
    case WORKSHOP_TEMPLATES_LIST_FAILURE: {
      return { ...state, gettingWorkshopTemplates: false };
    }
    default:
      return state;
  }
}
