import {
  Button,
  ButtonGroup,
  Collapse,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { AlertCircleIcon } from '../../../../components/Icons';
import { useCreateSession } from '../../../../resources/sessions';
import { FormikQuestion } from '../../join/components/FormQuestion';

export function ExistingEmailPasswordDialog({
  isOpen,
  handleClose,
  email,
}: {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
}) {
  const { createSession, loggingIn, errors: createSessionErrors } = useCreateSession();
  return (
    <Modal isOpen={isOpen} onClose={handleClose} variant="coa-main">
      <ModalOverlay />
      <Formik
        initialValues={{ password: '' }}
        isInitialValid={false}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(1).required(),
        })}
        onSubmit={({ password }) => {
          createSession({ password, email });
        }}
      >
        {({ isValid }) => (
          <Form>
            <ModalContent>
              <ModalBody>
                <Text fontSize="lg" mb={4}>
                  A user with the email you provided already exists. Please enter your password to
                  log in.
                </Text>
                <FormikQuestion
                  question="Confirm password"
                  hideErrMessage
                  name="password"
                  kind="password"
                />
                <Collapse in={Boolean(createSessionErrors?.email)} unmountOnExit>
                  <Text width="100%" color="red.500" mt={2}>
                    <AlertCircleIcon mr={1} color="red.500" />{' '}
                    {createSessionErrors?.email || Object.values(createSessionErrors)[0]}
                  </Text>
                </Collapse>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="primary"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={loggingIn}
                    data-cy="confirm-password-submit"
                  >
                    Continue
                  </Button>
                  <Button variant="ghost" onClick={handleClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
