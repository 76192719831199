/* eslint-disable react/prop-types */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { create } from '../../../actions/sessions';
import { SafeAnalytics } from '../../../lib/analytics';
import { sessionLocalStorage } from '../../../lib/localStorage/sessionLocalStorage';
import { DefaultRoute } from '../../../utils/DefaultRoute';
import { Redirector } from '../../../utils/Redirector';
import LogIn from '../components/LogIn';

class LogInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', password: '' };
    this.updateAttr = this.updateAttr.bind(this);
    this.logIn = this.logIn.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const currentMember = sessionLocalStorage.getCurrentMember();
    if (currentMember) {
      history.push(DefaultRoute.for(currentMember));
    } else {
      SafeAnalytics.track('Viewed Sign In');
    }
  }

  componentDidUpdate() {
    const { history, loggedIn } = this.props;
    if (loggedIn) {
      const currentMember = sessionLocalStorage.getCurrentMember();
      const path = Redirector.path() || DefaultRoute.for(currentMember);
      history.push(path);
    }
  }

  updateAttr(e, attr) {
    const newState = {};
    newState[attr] = e.target.value;
    this.setState(newState);
  }

  logIn(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(create(this.state));
  }

  render() {
    const { loggingIn, errors, dispatch } = this.props;
    const { email, password } = this.state;
    return (
      <LogIn
        email={email}
        password={password}
        updateAttr={this.updateAttr}
        logIn={this.logIn}
        loading={loggingIn}
        errors={errors}
        dispatch={dispatch}
      />
    );
  }
}

LogInContainer.propTypes = {
  history: PropTypes.object.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loggingIn, errors, loggedIn } = state.session;
  return { loggingIn, errors, loggedIn };
};

export default _.flow([withRouter, connect(mapStateToProps)])(LogInContainer);
