import { WorkoutScheduleEventRecord } from '@coa/api/models/workoutScheduleEvent';
import { FormProps } from '@rjsf/core';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';
import { AdminCmsWorkoutIdReferenceWidget } from '../shared/widgets/AdminCmsWorkoutIdReferenceWidget';

export const formSchema: FormProps<WorkoutScheduleEventRecord>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: ['workoutId', 'scheduledDate'],
  properties: {
    workoutId: {
      title: 'Workout',
      type: formSchemaEntryTypes.string,
    },
    scheduledDate: {
      title: 'Scheduled Date',
      type: formSchemaEntryTypes.string,
    },
  },
};

export const formUiSchema = {
  workoutId: {
    'ui:widget': AdminCmsWorkoutIdReferenceWidget,
  },
  scheduledDate: {
    'ui:widget': 'date',
    'ui:options': {
      yearsRange: [2022, 2030],
    },
  },
};
