import { Box, Grid, GridItem, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink, useQueryParams } from '@coa/react-utils';
import { UnpackArray } from '@coa/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import {
  OnDemandCard,
  OnDemandCardCategoryLabel,
  OnDemandCardContentCreatorLabel,
  OnDemandCardTitle,
  OnDemandCardTitleContainer,
} from '../../../../components/OnDemandCard';
import Background from '../../../../images/background.png';
import { onDemandAnalytics } from '../../../../lib/analytics/clients';
import {
  OnDemandVideoCheckpoint,
  SearchOnDemandVideoCheckpoints,
  useSearchOnDemandVideoCheckpoints,
} from '../../../../resources/onDemandVideoCheckpoints';
import { createGetOnDemandVideoCheckpointsPlaceholderData } from '../../../../resources/onDemandVideoCheckpoints/apis/placeholder';
import { getRouterUrl } from '../../../../routerPaths';

const placeholderData = createGetOnDemandVideoCheckpointsPlaceholderData({ length: 3 });

const OnDemandSearchResult = (result: UnpackArray<SearchOnDemandVideoCheckpoints.Response>) => (
  <OnDemandCard imgSrc={result.onDemandVideo.thumbnailUrl || Background}>
    <OnDemandCardTitleContainer>
      <OnDemandCardCategoryLabel minHeight="36px">
        {result.onDemandVideo.title}
      </OnDemandCardCategoryLabel>
      <OnDemandCardTitle
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {result.title}
      </OnDemandCardTitle>
    </OnDemandCardTitleContainer>
    <OnDemandCardContentCreatorLabel
      name={result.therapist.name}
      imgSrc={result.therapist.imageUrl}
    />
  </OnDemandCard>
);

export const SearchResults = () => {
  const queryParams = useQueryParams();
  const searchParams = queryParams.get('q');

  const searchOnDemandVideosQuery = useSearchOnDemandVideoCheckpoints(
    {
      q: searchParams,
    },
    { placeholderData }
  );

  const { data: searchResults } = searchOnDemandVideosQuery;

  const isLoading =
    searchOnDemandVideosQuery.isLoading || searchOnDemandVideosQuery.isPlaceholderData;

  useEffect(() => {
    if (!isLoading && searchParams) {
      onDemandAnalytics.track('Searched On-Demand Videos', {
        query: searchParams,
        numOfResults: searchResults.length,
      });
    }
  }, [searchResults]);

  const trackCheckpointClicked = (checkpoint: OnDemandVideoCheckpoint) => {
    onDemandAnalytics.track('Clicked On-Demand Checkpoint', {
      checkpointQuestion: checkpoint.title,
      checkpointId: checkpoint.id,
      videoId: checkpoint.onDemandVideo.id,
    });
  };

  return searchParams && !_.isEmpty(searchResults) ? (
    <Box pt={6}>
      <Skeleton isLoaded={!isLoading}>
        <Text mb={6}>{searchResults.length} results</Text>
      </Skeleton>
      <Grid
        templateColumns="repeat(12, 1fr)"
        templateRows="repeat(1, 1fr)"
        autoRows="1fr"
        width="100%"
        columnGap={{ base: 0, md: 8 }}
        rowGap={8}
      >
        {searchResults.map((result) => (
          <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} key={result.id}>
            <Skeleton isLoaded={!isLoading} height="100%">
              <TransformUpLink
                as={RouterLink}
                to={getRouterUrl.onDemand.videoCheckpoint({
                  videoId: result.onDemandVideo.id,
                  checkpointId: result.id,
                })}
                height="100%"
                width="100%"
                onClick={() => {
                  trackCheckpointClicked(result);
                }}
              >
                <OnDemandSearchResult {...result} />
              </TransformUpLink>
            </Skeleton>
          </GridItem>
        ))}
      </Grid>
    </Box>
  ) : searchParams && _.isEmpty(searchResults) ? (
    <Box pt={6}>
      <Skeleton isLoaded={!isLoading}>
        <Text mb={6}>No matching results. Check the spelling or try a new search term.</Text>
      </Skeleton>
    </Box>
  ) : null;
};
