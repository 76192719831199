import {
  CREATE_THERAPY_MATCH_SUCCESS,
  THERAPY_MATCHING_ANSWERS_FAILURE,
  THERAPY_MATCHING_ANSWERS_SUCCESS,
  THERAPY_MATCHING_QUESTIONS_FAILURE,
  THERAPY_MATCHING_QUESTIONS_REQUEST,
  THERAPY_MATCHING_QUESTIONS_SUCCESS,
} from '../actions/therapyMatchingQuestions';

const DEFAULT_STATE = {
  currentMatch: null,
  questions: [],
  matches: [],
  loadingQuestions: true,
  loadingMatches: true,
};

export function matching(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case THERAPY_MATCHING_QUESTIONS_REQUEST: {
      return DEFAULT_STATE;
    }
    case THERAPY_MATCHING_QUESTIONS_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, questions: data, loadingQuestions: false };
    }
    case THERAPY_MATCHING_ANSWERS_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, matches: data, loadingMatches: false };
    }
    case THERAPY_MATCHING_ANSWERS_FAILURE: {
      return { ...state, matches: [], loadingMatches: false };
    }
    case THERAPY_MATCHING_QUESTIONS_FAILURE: {
      return { ...state, questions: [], loadingQuestions: false };
    }
    case CREATE_THERAPY_MATCH_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, currentMatch: data };
    }
    default:
      return state;
  }
}
