import _ from 'lodash';
import { create as createRegistrationAction } from '../../actions/registrations/create';
import { useAppDispatch, useAppSelector } from '../../store';

export type CreateRegistrationParams = {
  email: string;
  name: string;
  password?: string;
  company_name?: string;
  // TODO: Don't want to create a circular dependency, but this should be a SessionRole
  role: string;
};

export const useCreateRegistration = () => {
  const dispatch = useAppDispatch();
  // TODO: Errors
  const { registering, loggedIn, errors } = useAppSelector((state) =>
    _.pick(state.session, ['registering', 'errors', 'loggedIn'])
  );
  const createRegistration = (params: CreateRegistrationParams) => {
    dispatch(createRegistrationAction(params));
  };
  return {
    errors,
    registering,
    loggedIn,
    createRegistration,
  };
};

export const getUserExists = (errors: ReturnType<typeof useCreateRegistration>['errors'] = {}) =>
  Boolean((errors.password || '').match(/Existing member/));
