import _ from 'lodash';
import {
  ADVANCED_WORKSHOPS_FAILURE,
  ADVANCED_WORKSHOPS_REQUEST,
  ADVANCED_WORKSHOPS_SUCCESS,
  DESTROY_SELECTED_WORKSHOP_ORDER_SUMMARY,
  DISCUSSION_WORKSHOPS_FAILURE,
  DISCUSSION_WORKSHOPS_REQUEST,
  DISCUSSION_WORKSHOPS_SUCCESS,
  FILTERED_WORKSHOPS_FAILURE,
  FILTERED_WORKSHOPS_REQUEST,
  FILTERED_WORKSHOPS_SUCCESS,
  GET_SELECTED_WORKSHOP_ORDER_SUMMARY_FAILURE,
  GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST,
  GET_SELECTED_WORKSHOP_ORDER_SUMMARY_SUCCESS,
  INTRO_WORKSHOPS_FAILURE,
  INTRO_WORKSHOPS_REQUEST,
  INTRO_WORKSHOPS_SUCCESS,
  JOINED_WORKSHOP_CLEAROUT,
  JOIN_WORKSHOP_FAILURE,
  JOIN_WORKSHOP_REQUEST,
  JOIN_WORKSHOP_SUCCESS,
  LEADERSHIP_WORKSHOP_DETAILS_REQUEST,
  LEADERSHIP_WORKSHOP_DETAILS_SUCCESS,
  MY_WORKSHOPS_FAILURE,
  MY_WORKSHOPS_REQUEST,
  MY_WORKSHOPS_SUCCESS,
  SERIES_WORKSHOPS_FAILURE,
  SERIES_WORKSHOPS_REQUEST,
  SERIES_WORKSHOPS_SUCCESS,
  SIBLING_WORKSHOPS_FAILURE,
  SIBLING_WORKSHOPS_REQUEST,
  SIBLING_WORKSHOPS_SUCCESS,
  STANDARD_WORKSHOP_DETAILS_REQUEST,
  STANDARD_WORKSHOP_DETAILS_SUCCESS,
  WORKSHOP_DETAILS_FAILURE,
  WORKSHOP_DETAILS_REQUEST,
  WORKSHOP_DETAILS_SUCCESS,
} from '../actions/workshops';

const DEFAULT_STATE = {
  myWorkshops: [],
  gettingMyWorkshops: true,
  introWorkshops: [],
  gettingIntroWorkshops: true,
  seriesWorkshops: [],
  gettingSeriesWorkshops: true,
  advancedWorkshops: [],
  gettingAdvancedWorkshops: true,
  discussionWorkshops: [],
  gettingDiscussionWorkshops: true,
  filteredWorkshops: [],
  gettingFilteredWorkshops: false,
  selectedWorkshopTherapist: null,
  gettingSelectedWorkshopOrderSummary: false,
  includedWorkshopOccurrences: {},
  selectedWorkshop: null,
  gettingSelectedWorkshop: true,
  siblingWorkshops: [],
  siblingTherapists: [],
  gettingSiblingWorkshops: true,
  joiningWorkshop: false,
  joinedWorkshop: null,
  joinWorkshopErrors: {},
  selectedWorkshopOrderSummary: null,
  getSelectedWorkshopOrderSummaryErrors: {},
  onboardingWorkshop: null,
};

const newIncludedWorkshopOccurencesFrom = (state, included) => {
  const { includedWorkshopOccurrences } = state;
  _.filter(included, (x) => x.type === 'workshop_occurrence').forEach((occurrence) => {
    const id = occurrence.attributes.workshop_id;
    includedWorkshopOccurrences[id] = includedWorkshopOccurrences[id] || [];
    includedWorkshopOccurrences[id].push(occurrence);
    includedWorkshopOccurrences[id] = _.uniqBy(includedWorkshopOccurrences[id], 'id');
  });
  return includedWorkshopOccurrences;
};

export function workshops(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case MY_WORKSHOPS_REQUEST: {
      return { ...state, myWorkshops: [], gettingMyWorkshops: true };
    }
    case MY_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        myWorkshops: data,
        gettingMyWorkshops: false,
      };
    }
    case MY_WORKSHOPS_FAILURE: {
      return { ...state, myWorkshops: [], gettingMyWorkshops: false };
    }
    case INTRO_WORKSHOPS_REQUEST: {
      const newState = {};
      if (state.introWorkshops.length === 0) newState.gettingIntroWorkshops = true;
      return { ...state, ...newState };
    }
    case INTRO_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        introWorkshops: data,
        gettingIntroWorkshops: false,
      };
    }
    case INTRO_WORKSHOPS_FAILURE: {
      return { ...state, introWorkshops: [], gettingIntroWorkshops: false };
    }
    case SERIES_WORKSHOPS_REQUEST: {
      const newState = {};
      if (state.seriesWorkshops.length === 0) newState.gettingSeriesWorkshops = true;
      return { ...state, ...newState };
    }
    case SERIES_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        seriesWorkshops: data,
        gettingSeriesWorkshops: false,
      };
    }
    case SERIES_WORKSHOPS_FAILURE: {
      return { ...state, seriesWorkshops: [], gettingSeriesWorkshops: false };
    }
    case ADVANCED_WORKSHOPS_REQUEST: {
      const newState = {};
      if (state.advancedWorkshops.length === 0) newState.gettingAdvancedWorkshops = true;
      return { ...state, ...newState };
    }
    case ADVANCED_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        advancedWorkshops: data,
        gettingAdvancedWorkshops: false,
      };
    }
    case ADVANCED_WORKSHOPS_FAILURE: {
      return { ...state, advancedWorkshops: [], gettingAdvancedWorkshops: false };
    }
    case DISCUSSION_WORKSHOPS_REQUEST: {
      const newState = {};
      if (state.discussionWorkshops.length === 0) newState.gettingDiscussionWorkshops = true;
      return { ...state, ...newState };
    }
    case DISCUSSION_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        discussionWorkshops: data,
        gettingDiscussionWorkshops: false,
      };
    }
    case DISCUSSION_WORKSHOPS_FAILURE: {
      return { ...state, discussionWorkshops: [], gettingDiscussionWorkshops: false };
    }
    case FILTERED_WORKSHOPS_REQUEST: {
      return { ...state, filteredWorkshops: [], gettingFilteredWorkshops: true };
    }
    case FILTERED_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        filteredWorkshops: data,
        gettingFilteredWorkshops: false,
      };
    }
    case FILTERED_WORKSHOPS_FAILURE: {
      return { ...state, filteredWorkshops: [], gettingFilteredWorkshops: false };
    }
    case WORKSHOP_DETAILS_REQUEST: {
      const newState = {
        selectedWorkshop: null,
        selectedWorkshopTherapist: null,
        selectedWorkshopOrderSummary: null,
        gettingSelectedWorkshop: true,
        joinWorkshopErrors: {},
        getSelectedWorkshopOrderSummaryErrors: {},
      };
      const allWorkshops = _.flatten([
        state.introWorkshops,
        state.seriesWorkshops,
        state.discussionWorkshops,
      ]);
      const workshop = _.find(allWorkshops, (w) => w.id === action.payload);
      if (workshop) {
        newState.selectedWorkshop = workshop;
        newState.gettingSelectedWorkshop = false;
      }
      return {
        ...state,
        ...newState,
      };
    }
    case WORKSHOP_DETAILS_SUCCESS: {
      const {
        response: { data, included, meta },
      } = action;
      data.attributes = { ...data.attributes, ...meta };
      const selectedWorkshopTherapist = included.find((d) => d.type === 'therapist');
      const { includedWorkshopOccurrences } = state;
      includedWorkshopOccurrences[data.id] = included.filter(
        (d) => d.type === 'workshop_occurrence'
      );
      return {
        ...state,
        selectedWorkshop: data,
        selectedWorkshopTherapist,
        includedWorkshopOccurrences,
        gettingSelectedWorkshop: false,
      };
    }
    case LEADERSHIP_WORKSHOP_DETAILS_REQUEST:
    case STANDARD_WORKSHOP_DETAILS_REQUEST: {
      return {
        ...state,
        onboardingWorkshop: null,
      };
    }
    case LEADERSHIP_WORKSHOP_DETAILS_SUCCESS:
    case STANDARD_WORKSHOP_DETAILS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      const { includedWorkshopOccurrences } = state;
      includedWorkshopOccurrences[data.id] = included.filter(
        (d) => d.type === 'workshop_occurrence'
      );
      return {
        ...state,
        onboardingWorkshop: data,
        includedWorkshopOccurrences,
      };
    }
    case WORKSHOP_DETAILS_FAILURE: {
      return {
        ...state,
        selectedWorkshop: null,
        selectedWorkshopTherapist: null,
        selectedWorkshopOccurrences: [],
      };
    }
    case SIBLING_WORKSHOPS_REQUEST: {
      return {
        ...state,
        siblingWorkshops: [],
        siblingTherapists: [],
        gettingSiblingWorkshops: true,
      };
    }
    case SIBLING_WORKSHOPS_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      const siblingTherapists = included.filter((d) => d.type === 'therapist');
      return {
        ...state,
        includedWorkshopOccurrences: newIncludedWorkshopOccurencesFrom(state, included),
        siblingWorkshops: data,
        siblingTherapists,
        gettingSiblingWorkshops: false,
      };
    }
    case SIBLING_WORKSHOPS_FAILURE: {
      return {
        ...state,
        siblingWorkshops: [],
        siblingTherapists: [],
        gettingSiblingWorkshops: false,
      };
    }
    case JOINED_WORKSHOP_CLEAROUT: {
      return { ...state, joiningWorkshop: false, joinedWorkshop: null };
    }
    case JOIN_WORKSHOP_REQUEST: {
      return { ...state, joiningWorkshop: true, joinedWorkshop: null, joinWorkshopErrors: {} };
    }
    case JOIN_WORKSHOP_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, joiningWorkshop: false, joinedWorkshop: data, joinWorkshopErrors: {} };
    }
    case JOIN_WORKSHOP_FAILURE: {
      const { errors } = action;
      return { ...state, joiningWorkshop: false, joinedWorkshop: null, joinWorkshopErrors: errors };
    }
    case GET_SELECTED_WORKSHOP_ORDER_SUMMARY_REQUEST: {
      return {
        ...state,
        gettingSelectedWorkshopOrderSummary: true,
        selectedWorkshopOrderSummary: null,
        getSelectedWorkshopOrderSummaryErrors: {},
      };
    }
    case GET_SELECTED_WORKSHOP_ORDER_SUMMARY_SUCCESS: {
      const {
        response: { data },
      } = action;
      return {
        ...state,
        gettingSelectedWorkshopOrderSummary: false,
        selectedWorkshopOrderSummary: data,
        getSelectedWorkshopOrderSummaryErrors: {},
      };
    }
    case GET_SELECTED_WORKSHOP_ORDER_SUMMARY_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        gettingSelectedWorkshopOrderSummary: false,
        selectedWorkshopOrderSummary: null,
        /*
         * Note - for now this is likely a no-op as we lack generic
         * 5xx handling on the backend. This is likely a non-issue
         * as this API is extremely dumb, but notable in that we'll
         * ignore this class of error in the UI.
         */
        getSelectedWorkshopOrderSummaryErrors: errors,
      };
    }
    case DESTROY_SELECTED_WORKSHOP_ORDER_SUMMARY: {
      return {
        ...state,
        gettingSelectedWorkshopOrderSummary: false,
        selectedWorkshopOrderSummary: null,
        getSelectedWorkshopOrderSummaryErrors: {},
      };
    }
    default:
      return state;
  }
}
