import { matchPath, RouteProps, useHistory } from 'react-router';
import { useLastLocation } from 'react-router-last-location';

export const goBack = ({ history, lastLocation, path, exact }) => {
  if (matchPath(lastLocation?.pathname, { path, exact })) {
    history.goBack();
  } else {
    history.push(path);
  }
};

export const useBackFn = ({ path, exact }: RouteProps & { path: string }) => {
  const lastLocation = useLastLocation();
  const history = useHistory();

  /*
   * Try to use history.goBack() to navigate to the place we wish
   * to go, but otherwise just go there directly.
   */
  const backFn = () => {
    goBack({ history, lastLocation, path, exact });
  };
  return { backFn };
};
