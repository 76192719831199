import { Image, Td, Text } from '@chakra-ui/react';
import {
  useAdminCreateMediaImageMutation,
  useAdminIndexMediaImagesQuery,
  useAdminShowMediaImageQuery,
  useAdminUpdateMediaImageMutation,
} from '@coa/api/controllers/admin/mediaImages';
import { MediaImageRecord } from '@coa/api/models';
import { AdminSerializedMediaImage } from '@coa/api/serializers/admin/AdminSerializedMediaImage';
import { FormProps } from '@rjsf/core';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AdminCmsCreateView } from '../shared/AdminCmsCreateView';
import { formSchemaEntryTypes } from '../shared/AdminCmsForm';
import { AdminCmsImageUploadWidget } from '../shared/AdminCmsImageUploadWidget';
import { AdminCmsIndexView } from '../shared/AdminCmsIndexView';
import { AdminCmsShowView } from '../shared/AdminCmsShowView';
import { CmsContextParams, CmsContextProvider } from '../shared/useAdminCmsContext';
import { mediaImageCmsHelperText } from './mediaImageCmsContent';
import { AdminMediaImageFormData } from './types';

const formSchema: FormProps<MediaImageRecord>['schema'] = {
  type: formSchemaEntryTypes.object,
  required: ['adminLabel'],
  properties: {
    adminLabel: {
      type: formSchemaEntryTypes.string,
    },
    asset: {
      type: formSchemaEntryTypes.string,
    },
  },
};

const formUiSchema = {
  asset: {
    'ui:widget': AdminCmsImageUploadWidget,
  },
};

export const AdminMediaImagesCmsContextProvider = ({
  children,
  contextOverrides = {},
}: {
  children: React.ReactNode;
  contextOverrides?: Partial<
    CmsContextParams<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData>
  >;
}) => (
  <CmsContextProvider<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData>
    value={{
      baseRouterPath: '/admin-dash/cms/media-images',
      contentTypeTitle: 'Media Image',
      contentHelperText: mediaImageCmsHelperText,
      searchPlaceholder: 'Search images by label, eg "Karin Photoshoot"',

      formSchema,
      formUiSchema,

      useShowQuery: useAdminShowMediaImageQuery,
      useIndexQuery: useAdminIndexMediaImagesQuery,
      useUpdateMutation: useAdminUpdateMediaImageMutation,
      useCreateMutation: useAdminCreateMediaImageMutation,

      getEntryTitle: ({ adminLabel }) => adminLabel,
      indexThEntries: [
        { name: 'adminLabel', label: 'Entry' },
        { name: 'assetContentType', label: 'Content Type' },
      ],
      renderIndexEntryTdContents: function MediaImageIndexEntryTd({
        adminLabel,
        assetContentType,
        asset,
      }: AdminSerializedMediaImage) {
        return (
          <>
            <Td>
              <Image maxHeight={120} src={asset} />
              <Text>
                <em>{adminLabel}</em>
              </Text>
            </Td>
            <Td>
              <Text>{assetContentType}</Text>
            </Td>
          </>
        );
      },
      ...contextOverrides,
    }}
  >
    {children}
  </CmsContextProvider>
);

export const AdminMediaImagesView = () => (
  <AdminMediaImagesCmsContextProvider>
    <Switch>
      <Route path="/admin-dash/cms/media-images/new">
        <AdminCmsCreateView<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData> />
      </Route>
      <Route path="/admin-dash/cms/media-images/:id">
        <AdminCmsShowView<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData> />
      </Route>
      <Route path="/admin-dash/cms/media-images">
        <AdminCmsIndexView<MediaImageRecord, AdminSerializedMediaImage, AdminMediaImageFormData> />
      </Route>
    </Switch>
  </AdminMediaImagesCmsContextProvider>
);
