import { EXERCISES_LIST_SUCCESS } from '../../actions/admin/exercises';

const DEFAULT_STATE = {
  exercises: [],
};

export function exercises(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case EXERCISES_LIST_SUCCESS: {
      return { ...state, exercises: action.response.data };
    }
    default:
      return state;
  }
}
