import { v1ApiPath } from '../../../lib/api';
import { OnDemandVideo } from '../types';

// TODO: Conventionally these are strings and not functions.
export const onDemandVideosShowApiPath = (id: OnDemandVideo['id']) =>
  v1ApiPath('on_demand_videos', id);

export const onDemandVideosIndexApiPath = () => v1ApiPath('on_demand_videos');

// TODO: Even though the above are strings, these are functions
export const onDemandVideosApiPaths = {
  show: onDemandVideosShowApiPath,
  index: onDemandVideosIndexApiPath,
};
