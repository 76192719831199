/* eslint-disable react/prop-types */
import { Box, Button, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { MdAccessTime, MdBlock, MdDone, MdQueryBuilder, MdReplay } from 'react-icons/md';

const LABELS = {
  awaiting_payment: { label: 'Awaiting Payment', icon: <MdQueryBuilder /> },
  in_process: { label: 'In Process', icon: <MdAccessTime /> },
  card_declined: { label: 'Card Declined', icon: <MdBlock /> },
  paid: { label: 'Paid', icon: <MdDone /> },
  uncollectible: { label: 'Uncollectible', icon: <MdBlock /> },
  void: { label: 'Voided', icon: <MdBlock /> },
  refunded: { label: 'Refunded', icon: <MdReplay /> },
};

const BillingHistoryDetails = ({ viewerRole, invoices, invoiceMembers, payInvoice, canPay }) => {
  const getRelevantMembersFromInvoice = (invoice) => {
    if (viewerRole === 'therapist') {
      const clientIds = invoice.relationships.clients.data.map((c) => c.id);
      return invoiceMembers.filter((c) => clientIds.indexOf(c.id) !== -1);
    }
    const therapistId = invoice.relationships.therapist.data.id;
    return invoiceMembers.filter((c) => therapistId === c.id);
  };

  return (
    <Box width="stretch" m={4} p={4} height="100%" maxHeight="100vh">
      <Text fontWeight="bold">Invoices</Text>
      <Divider />
      <VStack divider={<Divider />} align="stretch">
        {invoices.map((invoice) => {
          const status = LABELS[invoice.attributes.status];
          const relevantMembers = getRelevantMembersFromInvoice(invoice);
          return (
            <Box key={invoice.id}>
              <Flex justify="space-between" mt={2}>
                <Text>{relevantMembers.map((c) => c.attributes.name).join(' and ')}</Text>
                <Text>${(invoice.attributes.amount.cents / 100).toFixed(2)}</Text>
              </Flex>
              <Flex justify="flex-start">
                <Text fontSize="sm" color="gray.500">
                  {status.label} &nbsp;
                  {moment(invoice.attributes.created_at).format('MMM Do, YYYY')}
                </Text>
              </Flex>
              {invoice.attributes.status === 'awaiting_payment' && canPay && (
                <Button
                  mt={2}
                  colorScheme="evergreen"
                  width="100%"
                  onClick={() => payInvoice(invoice)}
                >
                  Pay Invoice
                </Button>
              )}
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default BillingHistoryDetails;
