import _ from 'lodash';
import { useMemo } from 'react';
import { AsArrDataEntryAttributes } from '../../../lib/json-api';
import { Workshop } from '../../workshops';
import {
  GetWorkshopExercises,
  GetWorkshopExerciseSteps,
  useWorkshopExercisesQuery,
  useWorkshopExerciseStepsQuery,
} from '../apis';
import { Exercise, ExerciseStep, ExerciseStepQuestionKind } from '../types';
import { generateTrackExercisesEvent } from './generateTrackExercisesEvent';
import { ExercisesAnalytics } from './types';

type UseTrackExerciseBaseAttrsParams = {
  stepId: ExerciseStep['id'];
  exerciseId: Exercise['id'];
  workshopId: Workshop['id'];
};

const useTrackExerciseBaseAttrs = ({
  stepId,
  exerciseId,
  workshopId,
}: UseTrackExerciseBaseAttrsParams) => {
  const exercisesQuery = useWorkshopExercisesQuery({ id: workshopId });
  const exerciseStepsQuery = useWorkshopExerciseStepsQuery({ id: workshopId, exerciseId });

  const { data: exercisesQueryResult } = exercisesQuery;
  const { data: exerciseStepsQueryResult } = exerciseStepsQuery;

  const exerciseAttributes =
    exercisesQueryResult?.data.find(({ id }) => id === exerciseId)?.attributes ||
    ({} as AsArrDataEntryAttributes<GetWorkshopExercises.Response>);
  const exerciseStepAttributes =
    exerciseStepsQueryResult?.data.find(({ id }) => id === stepId)?.attributes ||
    ({} as AsArrDataEntryAttributes<GetWorkshopExerciseSteps.Response>);

  const { title: exerciseTitle } = exerciseAttributes;
  const { title: stepTitle } = exerciseStepAttributes;
  const isClassPrep = Boolean((stepTitle || '').match(/Class Prep/));

  // TODO: Consider use of "enabled" per convention
  const resolved = !(_.isEmpty(exercisesQueryResult) || _.isEmpty(exerciseStepsQueryResult));
  return {
    resolved,
    baseAttrs: { workshopId, exerciseId, stepId, exerciseTitle, stepTitle, isClassPrep },
  };
};

export const useExerciseAnalytics = ({
  workshopId,
  exerciseId,
  stepId,
}: {
  workshopId: string;
  exerciseId: string;
  // Step Id may be undefined in the event that it was not specified
  // via the path and the corresponding query to fetch the first step
  // is still processing.
  stepId: string | undefined;
}) => {
  const { resolved, baseAttrs } = useTrackExerciseBaseAttrs({ workshopId, exerciseId, stepId });

  return useMemo(
    () =>
      resolved
        ? {
            trackExerciseAnswer: generateTrackExercisesEvent<
              ExercisesAnalytics.EventBodyBase,
              {
                answerPresent: boolean;
                questionId: string;
                questionKind: ExerciseStepQuestionKind;
              }
            >({
              name: 'Exercise Entry Step',
              baseAttrs,
            }),
            trackCompleteExerciseStep: generateTrackExercisesEvent<
              ExercisesAnalytics.EventBodyBase,
              never
            >({
              name: 'Complete Exercise Step',
              baseAttrs,
            }),
            trackCompleteExercise: generateTrackExercisesEvent<
              ExercisesAnalytics.EventBodyBase,
              never
            >({
              name: 'Complete Exercise',
              baseAttrs,
            }),
            trackExerciseStepView: generateTrackExercisesEvent<
              ExercisesAnalytics.EventBodyBase,
              never
            >({
              name: 'View Exercise Step',
              baseAttrs,
            }),
          }
        : {
            trackExerciseAnswer: () => {},
            trackCompleteExercise: () => {},
            trackCompleteExerciseStep: () => {},
            trackExerciseStepView: () => {},
          },
    [workshopId, exerciseId, stepId, resolved]
  );
};
