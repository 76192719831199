const vowels = ['a', 'e', 'i', 'o', 'u'];
const getIsVowel = (letter: string) => vowels.includes(letter.toLowerCase());

/*
 * Helper to decide whether "a" or "an" is the appropriate indefinite
 * article for a subject.
 */
export const getIndefiniteArticle = (subject: string) => (getIsVowel(subject[0]) ? 'an' : 'a');

/*
 * Ultimately we probably want to bring in something like `pluralize` to solve
 * for this.
 *
 * @see https://github.com/blakeembrey/pluralize
 *
 * In the meantime, I'd prefer not to install the whole library when we're
 * not using it all over the place, so we just restrict and map explicitly. We
 * can monitor and implement when we feel the time is right.
 */
const supportedPluralizedNouns = {
  entry: 'entries',
} as const;
type SupportedPluralizedNoun = keyof typeof supportedPluralizedNouns;
export const pluralizeIfPlural = (singularNoun: SupportedPluralizedNoun, size: number) =>
  size === 1
    ? singularNoun
    : supportedPluralizedNouns[singularNoun] ||
      // If by some chance the pluralized version isn't in the mapping,
      // we fallback to the singular as it we prefer a grammatically
      // incorrect experience to a broken UI.
      singularNoun;
