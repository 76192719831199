import {
  Box,
  Button,
  ButtonGroupProps,
  ButtonProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import { usePseudoStyleConfig } from '../../../lib/chakra-ui';

export type ToggleButtonProps = ButtonProps & {
  selected?: boolean;
  colorScheme?: string;
};

export const ToggleButton = (props: ToggleButtonProps) => {
  const { colorScheme, selected, ...rest } = props;
  const { _selected: selectedStyles, ...baseStyles } = usePseudoStyleConfig('ToggleButton', props);
  const styles = {
    ...baseStyles,
    ...(selected ? selectedStyles : {}),
  };

  return <Button sx={styles} {...rest} />;
};

export type ToggleButtonGroupProps = ButtonGroupProps & ThemingProps;

export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const styles = useStyleConfig('ToggleButtonGroup', props);
  return <Box sx={styles} {...props} />;
};
