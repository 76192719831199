export const THERAPIST_BILLING_PROFILES_SHOW_REQUEST = 'THERAPIST_BILLING_PROFILES_SHOW_REQUEST';
export const THERAPIST_BILLING_PROFILES_SHOW_SUCCESS = 'THERAPIST_BILLING_PROFILES_SHOW_SUCCESS';
export const THERAPIST_BILLING_PROFILES_SHOW_FAILURE = 'THERAPIST_BILLING_PROFILES_SHOW_FAILURE';

export function show(id) {
  return {
    type: THERAPIST_BILLING_PROFILES_SHOW_REQUEST,
    api: {
      endpoint: `therapists/${id}/billing_profile`,
      authenticated: true,
      types: [
        THERAPIST_BILLING_PROFILES_SHOW_REQUEST,
        THERAPIST_BILLING_PROFILES_SHOW_SUCCESS,
        THERAPIST_BILLING_PROFILES_SHOW_FAILURE,
      ],
      method: 'GET',
    },
  };
}
