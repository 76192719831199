import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { createSelector } from 'reselect';
import * as therapyMatchActions from '../../actions/admin/therapyMatches';
import { StatusLabel, STATUS_COLORS } from '../../utils/StatusLabel';
import { ArrowRightIcon } from '../Icons';
import Loading from '../Loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';

const ALLOWED_TRANSITIONS = {
  brand_new: ['awaiting_consult_schedule', 'dismissed'],
  awaiting_consult_schedule: ['consult_scheduled'],
  consult_scheduled: ['consult_missed', 'consult_completed'],
  consult_completed: ['match_found', 'no_match'],
  match_found: ['planning_to_start_session', 'fit_rejected'],
  planning_to_start_session: ['in_session'],
  in_session: ['churned'],
  dismissed: [],
  consult_missed: ['consult_scheduled'],
  no_match: [],
  fit_rejected: ['match_found'],
  churned: [],
};

const NOTE_UPDATE_DEBOUNCE_MILLI = 1000;

const AdminTherapyMatchEvents = () => {
  const dispatch = useDispatch();
  const {
    gettingTherapyMatchEvents,
    therapyMatches,
    includedClients,
    includedPerformedBy,
  } = useSelector((state) => state.admin.therapyMatches);
  const { id: therapyMatchId } = useParams();
  const [savingNotes, setSavingNotes] = useState(false);

  const eventSelector = createSelector(
    (state) => state.admin.therapyMatches.includedEvents,
    (includedEvents) => {
      if (includedEvents[therapyMatchId])
        return _.sortBy(includedEvents[therapyMatchId], 'attributes.created_at').reverse();
    }
  );

  const events = useSelector(eventSelector);

  const therapyMatch = therapyMatches[therapyMatchId];
  const performedBy = includedPerformedBy[therapyMatchId];
  useEffect(() => {
    if (!therapyMatch) dispatch(therapyMatchActions.show(therapyMatchId));
    if (!events) dispatch(therapyMatchActions.listEvents(therapyMatchId));
  }, [dispatch, events, therapyMatch, therapyMatchId]);

  if (!therapyMatch || !events || gettingTherapyMatchEvents) return <Loading />;
  const client = includedClients[therapyMatch.relationships.client.data.id];

  const handleNotesChange = _.debounce((notes) => {
    setSavingNotes(true);
    dispatch(therapyMatchActions.updateNotes(therapyMatchId, notes)).then(() =>
      setSavingNotes(false)
    );
  }, NOTE_UPDATE_DEBOUNCE_MILLI);

  const arbitraryTransition = (e) => {
    dispatch(therapyMatchActions.updateStatus(therapyMatchId, e.target.value)).then(() => {
      dispatch(therapyMatchActions.listEvents(therapyMatchId));
    });
  };

  return (
    <Box width={{ sm: '90%', md: '80%' }}>
      <Flex my={6} alignItems="center" justify="space-between" width="stretch">
        <Stack>
          <Heading>{client.attributes.name}</Heading>
          <Text>{client.attributes.email}</Text>
        </Stack>
        <Box>
          Current Status
          <Badge
            ml={2}
            fontSize="1.25em"
            color={`${STATUS_COLORS[therapyMatch.attributes.status]}.500`}
          >
            {therapyMatch.attributes.status.replace('_', ' ')}
          </Badge>
        </Box>
      </Flex>
      <Box mb={6}>
        {!!therapyMatch.attributes.answers && (
          <Table aria-label="Answers">
            <TableHead>
              <TableRow>
                <TableHeader>Question</TableHeader>
                <TableHeader>Answer</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(therapyMatch.attributes.answers).map((key) => (
                <TableRow key={key}>
                  <TableCell>{StatusLabel.for(key)}</TableCell>
                  {Array.isArray(therapyMatch.attributes.answers[key]) ? (
                    <TableCell>
                      <ul>
                        {therapyMatch.attributes.answers[key].map((answer) => (
                          <li key={answer}>{answer}</li>
                        ))}
                      </ul>
                    </TableCell>
                  ) : (
                    <TableCell key={key}>{therapyMatch.attributes.answers[key]}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <FormControl mt={5}>
          <FormLabel htmlFor="notes">Notes</FormLabel>
          <Textarea
            focusBorderColor="evergreen.500"
            type="text"
            placeholder="Add notes..."
            defaultValue={therapyMatch.attributes.notes}
            onChange={(e) => handleNotesChange(e.target.value)}
          />
        </FormControl>
        <Text color="gray.100" align="right">
          {savingNotes ? 'Saving...' : 'Auto-saved.'}
        </Text>
      </Box>

      <Flex width="stretch" alignItems="center" p={5} bgColor="gray.100" borderRadius={5}>
        <Heading width="stretch" fontSize="md">
          Transition to
        </Heading>
        {ALLOWED_TRANSITIONS[therapyMatch.attributes.status].length > 0 && (
          <ButtonGroup justifyContent="flex-end" width="stretch">
            {ALLOWED_TRANSITIONS[therapyMatch.attributes.status].map((nextStatus) => (
              <Button
                key={nextStatus}
                colorScheme={STATUS_COLORS[nextStatus]}
                onClick={() =>
                  dispatch(therapyMatchActions.transitionTo(therapyMatchId, { status: nextStatus }))
                }
              >
                {StatusLabel.for(nextStatus)}
              </Button>
            ))}
          </ButtonGroup>
        )}
        <FormControl p={1} w="lg">
          <Select
            onChange={arbitraryTransition}
            backgroundColor="warm.50"
            placeholder="Pick Another Status"
          >
            {Object.keys(ALLOWED_TRANSITIONS).map((k) => (
              <option key={k} value={k}>
                {StatusLabel.for(k)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>
      {gettingTherapyMatchEvents ? (
        <Loading />
      ) : (
        <Box width="stretch" my={4} px={1}>
          {events.map((therapyMatchEvent) => {
            const member = performedBy[therapyMatchEvent.relationships.performed_by.data.id];
            const { name } = member.attributes;
            const { status_before, status_after, created_at } = therapyMatchEvent.attributes;
            return (
              <HStack key={therapyMatchEvent.id} my={2} justifyContent="space-between">
                <Text>{name}</Text>
                {status_before ? (
                  <HStack width="50%" justifyContent="space-between">
                    <Badge colorScheme={STATUS_COLORS[status_before]}>
                      {status_before.replace('_', ' ')}
                    </Badge>
                    <ArrowRightIcon />
                    <Badge colorScheme={STATUS_COLORS[status_after]}>
                      {status_after.replace('_', ' ')}
                    </Badge>
                  </HStack>
                ) : (
                  <Text>submitted the survey</Text>
                )}
                <Text>at {dayjs(created_at).format('hh:mma on MMM DD')}</Text>
              </HStack>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default AdminTherapyMatchEvents;
