export const ENROLLMENT_REQUEST = 'ENROLLMENT_REQUEST';
export const ENROLLMENT_SUCCESS = 'ENROLLMENT_SUCCESS';
export const ENROLLMENT_FAILURE = 'ENROLLMENT_FAILURE';

export function show(workshopId) {
  return {
    type: ENROLLMENT_REQUEST,
    api: {
      endpoint: `workshops/${workshopId}/enrollment`,
      authenticated: true,
      types: [ENROLLMENT_REQUEST, ENROLLMENT_SUCCESS, ENROLLMENT_FAILURE],
      method: 'GET',
    },
  };
}

export const ENROLLMENT_UPDATE_REQUEST = 'ENROLLMENT_UPDATE_REQUEST';
export const ENROLLMENT_UPDATE_SUCCESS = 'ENROLLMENT_UPDATE_SUCCESS';
export const ENROLLMENT_UPDATE_FAILURE = 'ENROLLMENT_UPDATE_FAILURE';

export function update(workshopId, answers) {
  return {
    type: ENROLLMENT_UPDATE_REQUEST,
    api: {
      data: { answers },
      endpoint: `workshops/${workshopId}/enrollment`,
      authenticated: true,
      types: [ENROLLMENT_UPDATE_REQUEST, ENROLLMENT_UPDATE_SUCCESS, ENROLLMENT_UPDATE_FAILURE],
      method: 'PUT',
    },
  };
}
