import { IsoTime } from '@coa/types';
import dayjs from 'dayjs';
import { WorkshopOccurrence } from '../workshops';

export function getIsOccurrenceInSession({
  startsAt,
  durationInMinutes,
  bufferBeforeInMinutes = 60,
}: Pick<WorkshopOccurrence, 'startsAt' | 'durationInMinutes'> & {
  bufferBeforeInMinutes?: number;
}) {
  const now = dayjs();
  return (
    now.isAfter(dayjs(startsAt).subtract(bufferBeforeInMinutes, 'minute')) &&
    now.isBefore(dayjs(startsAt).add(durationInMinutes, 'minute'))
  );
}

export function getHasClassEnded(
  isoTimeStr: IsoTime,
  durationInMinutes: WorkshopOccurrence['durationInMinutes']
) {
  const now = dayjs();
  return (
    dayjs(isoTimeStr).isBefore(now) && dayjs(isoTimeStr).diff(now, 'minute') < -durationInMinutes
  );
}
