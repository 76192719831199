import { useQuery, UseQueryOptions } from 'react-query';
import { createJsonApiAxiosClient } from '../../../../lib/axios';
import { GetTeamMemberships } from './types';

const generateGetTeamMemberships = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = `/v1/team_memberships`;
  const fn = async () => {
    const { data } = await client.get<GetTeamMemberships.Response>(path);
    return data;
  };
  return { path, fn };
};

export const useGetTeamMembershipsQuery = (
  options: UseQueryOptions<GetTeamMemberships.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetTeamMemberships();
  return useQuery<GetTeamMemberships.Response>(queryKey, fn, options);
};
