import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  Heading,
  HStack,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export const OnDemandCard = ({ imgSrc, children, ...rest }: FlexProps & { imgSrc }) => (
  <Flex
    p={6}
    bg="green.100"
    borderRadius="base"
    height="100%"
    width="100%"
    position="relative" // To anchor any absolutely positioned children, i.e. a progress-bar
    {...rest}
  >
    <Box minWidth="6px" bgImage={imgSrc} bgSize="cover" mr={6} borderRadius="base" />
    <VStack spacing={4} alignItems="flex-start" justifyContent="space-between" py={2}>
      {children}
    </VStack>
  </Flex>
);

export const OnDemandCardCategoryLabel = ({ children, ...rest }: TextProps) => (
  <Text textStyle="earmark" color="green.500" {...rest}>
    {children}
  </Text>
);

export const OnDemandCardTitle = ({ children, ...rest }: TextProps) => (
  <Heading fontSize="lg" color="green.800" display="inline" {...rest}>
    {children}
  </Heading>
);

export const OnDemandCardTitleContainer = ({ children, ...rest }: TextProps) => (
  <VStack spacing={4} alignItems="flex-start" justifyContent="space-between" py={2} {...rest}>
    {children}
  </VStack>
);

export const OnDemandCardDurationLabel = ({ children, ...rest }: TextProps) => (
  <Text display="inline-block" fontSize="sm" color="green.500" ml={2} {...rest}>
    {children}
  </Text>
);

export const OnDemandCardContentCreatorLabel = ({
  name,
  imgSrc,
  children,
  ...rest
}: StackProps & { name: string; imgSrc: string }) => (
  <HStack spacing={2} {...rest}>
    <Avatar size="sm" name={name} src={imgSrc} />
    <Text color="green.700">{name}</Text>
  </HStack>
);
