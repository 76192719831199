import { ValueOf } from '@coa/types';
import { Question, Questions } from './questions';

export const workoutSheetKinds = {
  baseFormSheet: 'base_form_sheet',
  richTextSheet: 'rich_text_sheet',
} as const;

export type BaseFormSheetParams = {
  questionSequence: Array<Question['id']>;
  questions: Questions;
};

export type RichTextSheetParams = {
  richTextContent: string;
};

export type WorkoutSheetKindToWorkoutSheetParams = {
  [workoutSheetKinds.baseFormSheet]: BaseFormSheetParams;
  [workoutSheetKinds.richTextSheet]: RichTextSheetParams;
};

export type WorkoutSheetKind = ValueOf<typeof workoutSheetKinds>;

export type WorkoutSheet<
  Kind extends keyof WorkoutSheetKindToWorkoutSheetParams = keyof WorkoutSheetKindToWorkoutSheetParams
> = (Kind extends keyof WorkoutSheetKindToWorkoutSheetParams
  ? WorkoutSheetKindToWorkoutSheetParams[Kind]
  : never) & {
  // Note that order here is important as these keys need to override keys
  // of possibly empty params objects above.
  kind: Kind;
};

export const isBaseFormSheet = (sheet: WorkoutSheet): sheet is WorkoutSheet<'base_form_sheet'> =>
  sheet?.kind === workoutSheetKinds.baseFormSheet;

export const isRichTextSheet = (sheet: WorkoutSheet): sheet is WorkoutSheet<'rich_text_sheet'> =>
  sheet?.kind === workoutSheetKinds.richTextSheet;
