import { Box, Button, Center, Flex, Heading } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as workshopTagActions from '../../actions/admin/workshopTags';
import * as tagActions from '../../actions/tags';
import Loading from '../Loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';

const WorkshopTags = () => {
  const dispatch = useDispatch();
  const { id: workshopId } = useParams();

  const { tags, gettingTags } = useSelector((state) => state.tags);
  const { workshopTags, gettingWorkshopTags } = useSelector((state) => state.admin.workshopTags);

  useEffect(() => {
    dispatch(tagActions.list());
    dispatch(workshopTagActions.list(workshopId));
  }, [dispatch, workshopId]);
  return (
    <Box width="stretch" maxWidth="80%">
      <Flex my={6} alignItems="center" justify="space-between" width="stretch">
        <Heading>Tags</Heading>
      </Flex>
      <Center>
        {gettingTags || gettingWorkshopTags ? (
          <Loading />
        ) : (
          <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
            <Table aria-label="Invoices">
              <TableHead>
                <TableRow>
                  <TableHeader>Tag</TableHeader>
                  <TableHeader>Category</TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((tag) => (
                  <TableRow key={tag.id}>
                    <TableCell>
                      <Heading fontSize="md">{tag.attributes.label}</Heading>
                    </TableCell>
                    <TableCell>{tag.attributes.kind}</TableCell>
                    <TableCell>
                      <Flex justifyContent="flex-end">
                        {_.findIndex(workshopTags, (t) => t.id === tag.id) === -1 ? (
                          <Button
                            colorScheme="evergreen"
                            width="200px"
                            onClick={() => dispatch(workshopTagActions.add(workshopId, tag.id))}
                          >
                            Add to Class
                          </Button>
                        ) : (
                          <Button
                            colorScheme="orange"
                            width="200px"
                            onClick={() => dispatch(workshopTagActions.remove(workshopId, tag.id))}
                          >
                            Remove from Class
                          </Button>
                        )}
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Center>
    </Box>
  );
};

export default WorkshopTags;
