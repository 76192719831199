import { AnyObject } from '@coa/types';
import { createValidate } from '@coa/yup-utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

const createGetIsValid = <T>(schema: Yup.Schema<T>) => async (
  value: unknown,
  validateOptions?: Yup.ValidateOptions
) => {
  const validationResult = createValidate(schema)(value, validateOptions);
  // If invalid, (truthy) error message will be returned,
  // so we cast AND negate.
  return !validationResult;
};

/*
 * Helper used to determine if a Formik form's initial values are
 * valid by re-using the specified validationSchema.
 */
export const useIsInitialValid = <T>({
  schema,
  validationOptions,
  initialValues,
}: {
  schema: Yup.Schema<T>;
  validationOptions?: Yup.ValidateOptions;
  initialValues: AnyObject;
}) => {
  const [isInitialValid, setIsInitialValid] = useState<boolean | undefined>();

  const getIsValid = createGetIsValid(schema);

  useEffect(() => {
    if (_.isUndefined(isInitialValid)) {
      const getIsInitialValid = async () => {
        const result = await getIsValid(initialValues, validationOptions);
        setIsInitialValid(result);
      };

      getIsInitialValid();
    }
  }, [initialValues, isInitialValid, getIsValid, validationOptions]);

  return { isInitialValid };
};
