import React from 'react';
import { Switch } from 'react-router';
import { AdminRoute } from '../../../containers/routes';
import { AdminCmsView } from './AdminCmsView';
import { AdminExercisesView } from './exercises/AdminExercisesView';
import { AdminInstructorsView } from './instructors/AdminInstructorsView';
import { AdminMediaImagesView } from './media/AdminMediaImageView';
import { AdminOnDemandVideosView } from './onDemandVideos/AdminOnDemandVideosView';
import { AdminPollsView } from './polls/AdminPollsView';
import { AdminWorkoutsView } from './workouts/AdminCmsWorkoutsView';
import { AdminCmsWorkoutScheduleEventsView } from './workoutScheduleEvents/AdminCmsWorkoutScheduleEventsView';

export const AdminCmsRoutes = () => (
  <Switch>
    <AdminRoute path="/admin-dash/cms/exercises" component={AdminExercisesView} />
    <AdminRoute path="/admin-dash/cms/instructors" component={AdminInstructorsView} />
    <AdminRoute path="/admin-dash/cms/media-images" component={AdminMediaImagesView} />
    <AdminRoute path="/admin-dash/cms/on-demand-videos" component={AdminOnDemandVideosView} />
    <AdminRoute path="/admin-dash/cms/polls" component={AdminPollsView} />
    <AdminRoute path="/admin-dash/cms/workouts" component={AdminWorkoutsView} />
    <AdminRoute
      path="/admin-dash/cms/workout-schedule-events"
      component={AdminCmsWorkoutScheduleEventsView}
    />
    <AdminRoute path="/admin-dash/cms" component={AdminCmsView} />
  </Switch>
);
