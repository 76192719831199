import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  UseDisclosureProps,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FormikEffect } from '@coa/formik-utils';
import { useTimer } from '@coa/react-utils';
import { isNonEmpty } from '@coa/stdlib/object';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { TrashIcon } from '../../../../components/Icons';
import { teamMembershipAnalytics } from '../../../../lib/analytics/clients/teamMembershipAnalytics';
import { useSession } from '../../../../resources/sessions';
import {
  TeamMember,
  teamMemberRole,
  useGetTeamQuery,
  usePostTeamMembersMutation,
} from '../../../../resources/teams';
import { TeamMembersCsvUploadInput } from './TeamMembersCsvUploadInput';
import { addTeamMembersFormValidationSchema } from './validation/addTeamMembersValidation';

// TODO: Consider using a proper random ID
const createId = () => Math.random().toString().slice(2);
const createMemberField = () => ({ name: '', email: '', id: createId() });

export const AddTeamMembersForm = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { name: nameForAnalytics, email: emailForAnalytics } = useSession();
  const getTeamQuery = useGetTeamQuery({ id });
  const { data: { attributes: { title = '', accessKind = '' } } = { attributes: {} } } =
    getTeamQuery.data || {};

  const postTeamMembersMutation = usePostTeamMembersMutation({ id });
  const timer = useTimer();
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    postTeamMembersMutation.reset();
    timer.resetTimer();
  }, []);

  const showLoading = !postTeamMembersMutation.isIdle && !timer.timerElapsed;

  // HUBSPOT FIELD we're currently saving on the Member model
  // corporate: would be for Team.access_kind === 'a_la_carte'
  const signupKind = accessKind && accessKind === 'a_la_carte' ? 'corporate' : null;
  return (
    <Formik
      initialValues={{
        members: [createMemberField()],
      }}
      validationSchema={addTeamMembersFormValidationSchema}
      onSubmit={async ({ members }) => {
        // Filter out empty rows.
        const memberEntriesToSubmit = members.filter(({ name, email }) =>
          [name, email].every(isNonEmpty)
        );

        try {
          // The timer assumes a single call, so we manage it manually.
          await Promise.all([
            ...memberEntriesToSubmit.map(({ name, email }) =>
              postTeamMembersMutation.mutateAsync({
                name,
                email,
                role: teamMemberRole.basic,
                signupKind,
              })
            ),
            timer.startTimerAsync(500),
          ]);

          handleClose();
          toast({
            title: 'Success!',
            description: 'Team members successfully added.',
            duration: 2000,
            isClosable: true,
            status: 'success',
          });
          teamMembershipAnalytics.track('Team Membership - Admin Invited Team-mates', {
            teamId: id,
            teamName: title,
            invitedTeammates: memberEntriesToSubmit.map(({ email }) => email),
            email: emailForAnalytics,
            name: nameForAnalytics,
          });
        } catch (err) {
          toast({
            title: 'Something weng wrong!',
            description: 'Please try again.',
            duration: 2000,
            isClosable: true,
            status: 'error',
          });
        }
      }}
    >
      {({ values, isValid, setValues }) => (
        <Form>
          <FormikEffect
            wait={100}
            onValuesChange={({ values: currentValues, prevValues }) => {
              const currentIsValid = addTeamMembersFormValidationSchema.isValidSync(currentValues);
              const prevIsValid = addTeamMembersFormValidationSchema.isValidSync(prevValues);

              if (currentIsValid && !prevIsValid) {
                const hasExistingEmptyRow = ((currentValues.members as unknown) as Array<{
                  name: string;
                  email: string;
                }>).find(({ name, email }) => _.every([name, email], _.isEmpty));
                if (!hasExistingEmptyRow) {
                  setValues({
                    ...currentValues,
                    members: [
                      ...((currentValues.members as unknown) as TeamMember[]),
                      createMemberField(),
                    ],
                  });
                }
              }
            }}
          />
          <Text mb={4}>
            These new teammates will receive an email notification indicating that they have been
            added to your team. They will be asked to create a Coa account if they have not yet done
            so.
          </Text>
          <VStack width="stretch">
            {values.members.map((member, index) => (
              <Box key={member.id} width="stretch">
                <HStack width="stretch">
                  <Field
                    name={`members.${index}.name`}
                    type="text"
                    as={Input}
                    variant="coa-main"
                    placeholder="Name"
                  />
                  <Field
                    name={`members.${index}.email`}
                    type="text"
                    as={Input}
                    variant="coa-main"
                    placeholder="Email"
                  />
                  <IconButton
                    aria-label="Remove"
                    icon={<TrashIcon />}
                    isLoading={
                      // Only show loading state if the row has values (and
                      // is therefore being submitted).
                      values.members[index].name && showLoading
                    }
                    isDisabled={values.members.length === 1}
                    onClick={() => {
                      setValues({
                        ...values,
                        members: values.members.filter(
                          (memberEntry) => memberEntry.id !== member.id
                        ),
                      });
                    }}
                  >
                    Delete
                  </IconButton>
                </HStack>
              </Box>
            ))}
          </VStack>
          <ButtonGroup mt={8}>
            <Button variant="primary" type="submit" isDisabled={!isValid} isLoading={showLoading}>
              Invite Teammates
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

type AddTeamMembersModalProps = Pick<UseDisclosureProps, 'isOpen' | 'onClose'> & { id: string };
const AddTeamMembersModal = ({ isOpen, onClose, id }: AddTeamMembersModalProps) => {
  const session = useSession();
  const { role } = session;
  const addTeamMembersTabs = [
    { component: <AddTeamMembersForm id={id} onClose={onClose} />, label: 'By Email' },
  ];

  if (role === 'admin') {
    addTeamMembersTabs.push({
      component: <TeamMembersCsvUploadInput id={id} onCancel={onClose} />,
      label: 'Bulk',
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Teammates</ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <ModalBody>
          <Tabs>
            <TabList>
              {addTeamMembersTabs.map(({ label }) => (
                <Tab key={label}>{label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {addTeamMembersTabs.map(({ component, label }) => (
                <TabPanel key={label}>{component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const AddTeamMembersButton = ({ id, ...rest }: ButtonProps & { id: string }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button {...rest} onClick={onOpen} />
      <AddTeamMembersModal isOpen={isOpen} onClose={onClose} id={id} />
    </>
  );
};
