import { createGetApiUrlWithQueryParams, v1ApiPath } from '../../../../lib/api';
import { resolvePath } from '../../../../lib/path';
import { GetSubscriptionOrderSummary } from './types';

const subscriptionsIndexApiPath = v1ApiPath('subscriptions');
const subscriptionsOrderSummaryApiPath = resolvePath(subscriptionsIndexApiPath, 'order_summary');
const b2bSubscriptionsOrderSummaryApiPath = resolvePath(
  subscriptionsIndexApiPath,
  'b2b_order_summary'
);

export const getSubscriptionsApiUrl = {
  index: () => subscriptionsIndexApiPath,
  orderSummary: createGetApiUrlWithQueryParams<GetSubscriptionOrderSummary.Request['queryParams']>(
    subscriptionsOrderSummaryApiPath
  ),
  b2bOrderSummary: createGetApiUrlWithQueryParams<
    GetSubscriptionOrderSummary.Request['queryParams']
  >(b2bSubscriptionsOrderSummaryApiPath),
};
