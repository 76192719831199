import { Box } from '@chakra-ui/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { MdDone, MdQueryBuilder } from 'react-icons/md';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';

const LABELS = {
  in_transit: { label: 'In Transit', icon: <MdQueryBuilder /> },
  paid: { label: 'Paid', icon: <MdDone /> },
};

function Payouts({ payouts }) {
  return (
    <Box>
      <Table aria-label="Payouts">
        <TableHead>
          <TableRow>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Arrival</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {payouts.map((payout) => {
            const now = moment();
            const arrives = moment.unix(payout.attributes.arrival_date);
            const status = LABELS[payout.attributes.status];
            return (
              <TableRow key={payout.id}>
                <TableCell>${(payout.attributes.amount / 100).toFixed(2)}</TableCell>
                <TableCell>
                  Arrive{arrives > now ? 's' : 'd'} on {arrives.format('MMM Do, YYYY')}
                </TableCell>
                <TableCell>
                  <Box style={{ display: 'flex' }}>
                    {status.icon} &nbsp; {status.label}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

Payouts.propTypes = {
  payouts: PropTypes.array.isRequired,
};

export default Payouts;
