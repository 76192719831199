/*
 * Exposes `window.attribution`
 */
import '@pearmill/attribution.js';
import { AttributionParams, WindowWithAttribution } from './types';

const getDefaultAttributionParams = (): AttributionParams => ({
  cookies: {},
  page: '',
  query: {},
  referrer: '',
  time: new Date().toUTCString(),
});

const { attribution } = (window as unknown) as WindowWithAttribution;
export const safeAttribution = {
  ...attribution,
  /*
   * Pearmill's default attribution functionality returns
   * undefined when there's nothing present. This forces
   * the caller to inspect the result before passing, which
   * they shouldn't have to. Here we protect the caller by
   * providing a default result.
   */
  save: () => attribution.save() || getDefaultAttributionParams(),
};
