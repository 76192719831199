import { Box, BoxProps, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import Faces from '../../../../images/doodle-faces.png';
import Home from '../../../../images/doodle-home.png';
import { getClassesRouterUrl } from '../../../../routerPaths/classesRouterPaths';

export const GenericSubscribeSuccessState = ({
  invertColors,
  ...rest
}: { invertColors?: boolean } & BoxProps) => (
  <Box
    bgColor={invertColors ? 'brand.evergreen' : 'white'}
    w="100%"
    h="100%"
    position="absolute"
    display="flex"
    {...rest}
  >
    <Flex
      padding={{ base: 8, lg: 12 }}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly"
      color={invertColors ? 'white' : undefined}
      flexGrow={1}
    >
      <Heading textAlign="center">Welcome to the Coa community!</Heading>
      <Text
        textAlign="center"
        fontSize={{ base: 'xl', md: '2xl' }}
        marginBottom="30px"
        maxW={{ base: '100%', md: '65%' }}
      >
        Welcome to Coa, your gym for mental health. You're now a part of a supportive community of
        people investing in their emotional fitness. We're so excited to grow together.
      </Text>
      <Button
        as={RouterLink}
        to={getClassesRouterUrl.apex}
        w={{ base: '100%', md: 'auto' }}
        colorScheme="red"
        size="lg"
      >
        Explore Coa Classes
      </Button>
    </Flex>
    <Image
      position="absolute"
      src={Faces}
      left={{ base: '2%', md: '5%' }}
      top={{ base: '2%', md: '10%' }}
      opacity="0.35"
      transform="rotate(-10deg)"
      boxSize={{ base: 20, md: 40 }}
    />
    <Image
      position="absolute"
      src={Home}
      right={{ base: '2%', md: '5%' }}
      bottom={{ base: '2%', md: '10%' }}
      opacity="0.35"
      transform="rotate(10deg)"
      boxSize={{ base: 20, md: 40 }}
    />
  </Box>
);
