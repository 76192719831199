import {
  Button,
  ButtonGroup,
  ButtonProps,
  CloseButton,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UseModalProps,
  useToast,
} from '@chakra-ui/react';
import { UnpackArray } from '@coa/types';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { ChakraReactSelect } from '../../../../components/ChakraReactSelect';
import { EditIcon } from '../../../../components/Icons';
import {
  EventCardDescription,
  EventCardHeading,
  SecondaryEventCard,
} from '../../../../components/molecules/EventCard';
import { useMutationWithTimer } from '../../../../lib/react-query';
import {
  Team,
  useAdminPostTeamWorkshopMutation,
  useGetTeamWorkshopsQuery,
} from '../../../../resources/teams';
import {
  AdminGetWorkshopsSearch,
  useAdminGetWorkshopsSearch,
  useGetWorkshopQuery,
} from '../../../../resources/workshops';

export type AddTeamWorkshopModalProps = UseModalProps & { id: Team['id'] };

export const AddTeamWorkshopModal = ({
  onClose,
  isOpen,
  id,
}: AddTeamWorkshopModalProps): JSX.Element => {
  const rawAdminPostTeamWorkshopMutation = useAdminPostTeamWorkshopMutation({ id });
  const adminPostTeamWorkshopMutation = useMutationWithTimer(rawAdminPostTeamWorkshopMutation, {
    duration: 500,
  });
  const getTeamWorkshopsQuery = useGetTeamWorkshopsQuery({ id });
  const [searchQuery, setSearchQuery] = useState('');
  const [workshopSearchResult, setWorkshopSearchResult] = useState<UnpackArray<
    AdminGetWorkshopsSearch.Response
  > | null>();

  const adminGetWorkshopsSearchQuery = useAdminGetWorkshopsSearch(
    { query: searchQuery, notEnded: true },
    { enabled: searchQuery.length > 0, keepPreviousData: searchQuery.length > 0 }
  );
  const getWorkshopQuery = useGetWorkshopQuery(
    { id: workshopSearchResult?.id },
    { enabled: !_.isEmpty(workshopSearchResult) }
  );

  const workshopSearchResults = adminGetWorkshopsSearchQuery.data || [];
  const { data: teamWorkshops = [] } = getTeamWorkshopsQuery.data || {};
  const { data: selectedWorkshopDetails } = getWorkshopQuery || {};

  const toast = useToast();
  const teamWorkshopIds = useMemo(() => teamWorkshops.map(({ id: _id }) => _id), [teamWorkshops]);

  const resetWorkshopSearch = useCallback(() => {
    setWorkshopSearchResult(null);
    setSearchQuery('');
  }, []);

  const handleClose = useCallback(() => {
    adminPostTeamWorkshopMutation.resetWithTimer();
    resetWorkshopSearch();
    onClose();
  }, []);

  const handleClick = async () => {
    try {
      await adminPostTeamWorkshopMutation.mutateAsyncWithTimer({
        workshopId: workshopSearchResult.id,
      });
      handleClose();
      toast({
        title: 'Success!',
        description: 'Class successfully added.',
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something weng wrong!',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  };

  const handleInputChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleChange = ({ id: workshopId }) => {
    const selectedWorkshop = workshopSearchResults.find(
      ({ id: workshopEntryId }) => workshopId === workshopEntryId
    );
    setWorkshopSearchResult(selectedWorkshop);
    setSearchQuery('');
  };

  const { isLoadingWithTimer } = adminPostTeamWorkshopMutation;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="md">Add Class</Heading>
          </ModalHeader>
          <ChakraFwdModalCloseButton variant="coa-main" />
          <ModalBody>
            <Text mb={4}>Search for a Class to add below.</Text>
            <ChakraReactSelect
              variant="coa-main"
              placeholder="Search for a class"
              noOptionsMessage={() =>
                searchQuery ? 'No class found' : 'Search classes by title to add to this team.'
              }
              isDisabled={!_.isEmpty(workshopSearchResult)}
              value={workshopSearchResult}
              inputValue={searchQuery}
              onInputChange={handleInputChange}
              onChange={handleChange}
              isLoading={adminGetWorkshopsSearchQuery.isLoading}
              options={workshopSearchResults}
              isOptionDisabled={({ id: _id }) => teamWorkshopIds.includes(_id)}
              getOptionLabel={({ title }) => title}
            />
            {workshopSearchResult && getWorkshopQuery.isSuccess ? (
              <>
                <SecondaryEventCard
                  src={selectedWorkshopDetails.imageUrl}
                  mt={8}
                  bg="gray.50"
                  borderRadius="base"
                  padding={4}
                  position="relative"
                >
                  <CloseButton
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={resetWorkshopSearch}
                  />
                  <EventCardHeading>{selectedWorkshopDetails.title}</EventCardHeading>
                  <EventCardDescription mb={2}>
                    {selectedWorkshopDetails.shortBlurb}
                  </EventCardDescription>

                  <ButtonGroup>
                    <Button
                      leftIcon={<EditIcon />}
                      size="sm"
                      aria-label="Edit Class"
                      // Open on a new page to preserve the search.
                      as="a"
                      href={`/admin-dash/classes/${selectedWorkshopDetails.id}`}
                      target="_blank"
                      {...(selectedWorkshopDetails.status !== 'exclusive'
                        ? { variant: 'primary' }
                        : {})}
                    >
                      Edit Class Details
                    </Button>
                  </ButtonGroup>
                </SecondaryEventCard>
                {selectedWorkshopDetails.status !== 'exclusive' ? (
                  <Text color="red.700" mt={4}>
                    Corporate classes must have a status of "Exclusive", but this class has a status
                    of "{_.capitalize(selectedWorkshopDetails.status)}". Please edit the class
                    details to update the status.{' '}
                    <Link
                      onClick={() => {
                        getWorkshopQuery.refetch();
                      }}
                    >
                      Refresh
                    </Link>
                  </Text>
                ) : null}
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleClick}
                isLoading={isLoadingWithTimer}
                isDisabled={
                  isLoadingWithTimer ||
                  _.isEmpty(workshopSearchResult) ||
                  selectedWorkshopDetails?.status !== 'exclusive'
                }
              >
                Add Class
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const AddTeamWorkshopButton = ({ id, ...rest }: ButtonProps & { id: Team['id'] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} {...rest} />
      <AddTeamWorkshopModal id={id} onClose={onClose} isOpen={isOpen} />
    </>
  );
};
