import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import Logo from '../../../images/logo.svg';
import { marketingSiteBaseUrl } from '../../../lib/www';

export const TryLogoContainer = ({
  fill,
  fillOpacity = 1,
  ...props
}: FlexProps & { fill?: string; fillOpacity?: number }) => (
  <Flex as="a" href={marketingSiteBaseUrl} align="center" {...props}>
    <Logo fill={fill} fillOpacity={fillOpacity} width="92.36px" />
  </Flex>
);
