import { AuthParams } from '@coa/api/lib/axios';
import { sessionLocalStorage } from './sessionLocalStorage';

export const getAuthParamsFromLocalStorage = async (): Promise<AuthParams> => {
  const member = sessionLocalStorage.getCurrentMember();
  if (!member) {
    // eslint-disable-next-line no-console
    console.warn('No member saved.');
    return null;
  }

  const { id, token } = member;
  if (!token) {
    // eslint-disable-next-line no-console
    console.warn('No token saved.');
  }
  return { id, token };
};
