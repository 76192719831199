import _ from 'lodash';
import {
  createPlaceholderOnDemandVideo,
  createPlaceholderOnDemandVideoCheckpoint,
} from '../../onDemandVideos/placeholder';
import { createPlaceholderTherapist } from '../../workshops';
import { SearchOnDemandVideoCheckpoints } from './types';

type CreateGetOnDemandVideoCheckpointsPlaceholderData = {
  length: number;
};

export const createGetOnDemandVideoCheckpointsPlaceholderData = _.memoize(
  ({
    length,
  }: CreateGetOnDemandVideoCheckpointsPlaceholderData): SearchOnDemandVideoCheckpoints.Response =>
    _.range(length).map(() => ({
      ...createPlaceholderOnDemandVideoCheckpoint(),
      therapist: createPlaceholderTherapist(),
      onDemandVideo: createPlaceholderOnDemandVideo(),
    }))
);
