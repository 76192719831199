import {
  Badge,
  BadgeProps,
  Box,
  ComponentWithAs,
  Flex,
  HStack,
  IconButton,
  IconProps,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { useGetTeamMembershipsQuery } from '@coa/api/controllers/v1/teamMemberships';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import {
  CalendarIcon,
  EditIcon,
  HeartIcon,
  InvoiceIcon,
  PayoutsIcon,
  PlaylistIcon,
  SearchIcon,
  SofaIcon,
  SunIcon,
  UserIcon,
  UsersIcon,
} from '../../../../components/Icons';
import Logo from '../../../../images/logo.svg';
import { useAnalytics } from '../../../../lib/analytics/AnalyticsProvider';
import { SessionRole, useSession } from '../../../../resources/sessions';
import { navHeightPx } from '../../../../themes/coaTheme';
import { useNotificationsQueries } from '../../../../utils/notifications';

type NavEntryProps = {
  path: string;
  label: string;
  iconComponent: ComponentWithAs<'svg', IconProps>;
  hiddenOnDesktop?: boolean;
};

const ACCOUNT_TAB = {
  path: '/account',
  label: 'Account',
  iconComponent: UserIcon,
  hiddenOnDesktop: true,
};

/**
 * WISEPOPS Bug Fix Explanation:
 * - when we added wisepops, we were flood with the
 *   value should be an HTMLElement error because when we Clicked
 *   on the CLIENT_TABS[X].iconComponent and other Icons in the Navbar, it would also trigger
 *   the wisepops modal. The get rid of the errors we added pointerEvents='none' props
 *   to the components that use these iconComponents (see below)
 */
const CLIENT_TABS: NavEntryProps[] = _.filter([
  { path: '/my-coa', label: 'My Coa', iconComponent: HeartIcon },
  { path: '/classes', label: 'Classes', iconComponent: SearchIcon },
  { path: '/on-demand', label: 'On-Demand', iconComponent: PlaylistIcon },
  { path: '/account/teams', label: 'Teams', iconComponent: UsersIcon },
  // keep if we ever bring back therapy matching
  // {
  //   path: '/therapy-matching',
  //   label: 'Therapy',
  //   iconComponent: SofaIcon,
  // },
  ACCOUNT_TAB,
]);

const THERAPIST_TABS: NavEntryProps[] = [
  {
    path: '/clients',
    label: 'Clients',
    iconComponent: SofaIcon,
  },
  {
    path: '/invoices',
    label: 'Invoices',
    iconComponent: InvoiceIcon,
  },
  {
    path: '/payouts',
    label: 'Payouts',
    iconComponent: PayoutsIcon,
  },
  ACCOUNT_TAB,
];

const ADMIN_TABS: NavEntryProps[] = [
  {
    path: '/admin-dash/therapists',
    label: 'Therapists',
    iconComponent: SunIcon,
  },
  {
    path: '/admin-dash/classes',
    label: 'Classes',
    iconComponent: CalendarIcon,
  },
  {
    path: '/admin-dash/therapy-matches',
    label: 'Therapy Matching',
    iconComponent: SofaIcon,
  },
  {
    path: '/admin-dash/teams',
    label: 'Teams',
    iconComponent: UsersIcon,
  },
  {
    path: '/admin-dash/cms',
    label: 'Content',
    iconComponent: EditIcon,
  },
  {
    path: '/admin-dash/crm',
    label: 'Customers',
    iconComponent: UsersIcon,
  },
  ACCOUNT_TAB,
];

const MenuItem = ({ display, text }) => (
  <Text fontSize={{ sm: 'xs', md: 'xl' }} m={2} display={display}>
    {text}
  </Text>
);

MenuItem.propTypes = {
  display: PropTypes.object,
  text: PropTypes.string,
};

const LogoContainer = () => (
  <Flex as="a" href="/" align="center" display={{ sm: 'none', md: 'block' }}>
    <Logo width="92.36px" />
  </Flex>
);

const NotificationIndicator = (props: BadgeProps) => {
  const notificationsQueries = useNotificationsQueries();
  if (notificationsQueries.isLoading) return null;
  const { count } = notificationsQueries;

  if (count < 1) return null;
  return (
    <Badge
      variant="solid"
      colorScheme="red"
      height="18px"
      width="18px"
      textAlign="center"
      borderRadius="1000px"
      {...props}
    >
      {count}
    </Badge>
  );
};

const VerifyEmailNotificationHighlight = (props: BadgeProps) => {
  const notificationsQueries = useNotificationsQueries();
  if (notificationsQueries.isLoading) return null;
  const { notifications } = notificationsQueries;
  if (!notifications.requireVerifyEmail?.active) return null;
  return (
    <Badge
      colorScheme="orange"
      variant="solid"
      minWidth="18px"
      textAlign="center"
      borderRadius="1000px"
      {...props}
    >
      !
    </Badge>
  );
};

const StaticNotificationHighlight = (props: BadgeProps) => (
  <Badge
    colorScheme="orange"
    variant="solid"
    minWidth="18px"
    textAlign="center"
    borderRadius="1000px"
    {...props}
  >
    !
  </Badge>
);

const AccountDropdown = () => {
  const { email, name, logOut } = useSession();

  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );
  const { safeAnalyticsClient } = useAnalytics();
  const trackProfileMembershipClick = () => {
    safeAnalyticsClient.track('Clicked Membership from Profile Notification');
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Box
          // To position the indicator
          position="relative"
        >
          <IconButton
            icon={<UserIcon boxSize={6} pointerEvents="none" />}
            isRound
            display={{ sm: 'none', md: 'block' }}
            aria-label="My Account"
            data-cy="account-menu-launcher"
            bg="gray.50"
          />
          <NotificationIndicator position="absolute" top="-4px" right="-4px" />
        </Box>
      </PopoverTrigger>
      <PopoverContent mx={6}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text fontSize="xl">{name}</Text>
          <Text>{email}</Text>
        </PopoverHeader>
        <PopoverBody>
          <Stack>
            <HStack spacing={2}>
              <VerifyEmailNotificationHighlight />
              <Link as={RouterLink} to="/account" data-cy="account-menu-link-account">
                Account Settings
              </Link>
            </HStack>
            {!hasActiveSubscription ? (
              <HStack spacing={2}>
                <StaticNotificationHighlight />
                <Link
                  as={RouterLink}
                  data-cy="account-menu-link-membership"
                  to="/my-coa/sign-up"
                  onClick={trackProfileMembershipClick}
                >
                  Membership
                </Link>
              </HStack>
            ) : null}
            <Link as={RouterLink} to="/billing-history" data-cy="account-menu-link-billing">
              Billing History
            </Link>
          </Stack>
        </PopoverBody>
        <PopoverFooter>
          <Link onClick={logOut} data-cy="account-menu-logout">
            Sign Out
          </Link>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

const NavBarIconButtonLink = ({ navEntry }: { navEntry: NavEntryProps }) => {
  const { path, label, hiddenOnDesktop, iconComponent: IconComponent } = navEntry;
  return (
    <Tab
      key={path}
      as={RouterLink}
      to={path}
      fontWeight="400"
      display={{ base: 'flex', md: hiddenOnDesktop ? 'none' : undefined }}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      {/* see wisepops comment at top of tile regarding pointerEvents='none' */}
      <IconComponent boxSize={6} mr={{ sm: 0, md: 2 }} pointerEvents="none" />
      <Text fontSize={{ sm: 'xs', md: 'lg' }} display={{ base: 'none', md: 'block' }}>
        {label}
      </Text>
    </Tab>
  );
};

const NavBarTeamAdminDropdown = ({ navEntry }: { navEntry: NavEntryProps }) => {
  const { path, label, hiddenOnDesktop, iconComponent: IconComponent } = navEntry;
  const getTeamMembershipsQuery = useGetTeamMembershipsQuery();
  const { data: teamMemberships = [] } = getTeamMembershipsQuery;
  const adminTeamMemberships = teamMemberships.filter(
    (teamMembership) => teamMembership.role === 'admin'
  );
  // hide for non-admin members, even if they belong to a team (for now)
  if (adminTeamMemberships.length === 0) return null;

  return (
    <Tab
      paddingY={2}
      paddingX={4}
      key={path}
      fontWeight="400"
      flexDirection={{ base: 'column', md: 'row' }}
      variant="soft-rounded"
      colorScheme="green"
    >
      <Popover>
        <PopoverTrigger>
          <Box
            // To position the indicator
            position="relative"
            color="gray.600"
            display={{ base: 'flex', md: hiddenOnDesktop ? 'none' : undefined }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {/* see wisepops comment at top of tile regarding pointerEvents='none' */}
            <IconComponent boxSize={6} mr={{ sm: 0, md: 2 }} pointerEvents="none" />
            <Text fontSize={{ sm: 'xs', md: 'lg' }} display={{ base: 'none', md: 'block' }}>
              {label}
            </Text>
            <NotificationIndicator position="absolute" top="-4px" right="-4px" />
          </Box>
        </PopoverTrigger>
        <PopoverContent textAlign="left" mx={6} color="gray.700">
          <PopoverArrow />
          {/* see wisepops comment at top of tile regarding pointerEvents='none' */}
          <PopoverCloseButton pointerEvents="none" />
          <PopoverHeader>
            <Text fontSize="xl">Manage Your Teams:</Text>
          </PopoverHeader>
          <PopoverBody>
            <Stack>
              {adminTeamMemberships.map(({ team }) => {
                let to = `/account/teams/${team.id}`;
                if (team.accessKind === 'membership') {
                  to += '/members';
                }
                return (
                  <Link key={team.id} as={RouterLink} to={to}>
                    {team.title}
                  </Link>
                );
              })}
            </Stack>
          </PopoverBody>
          <PopoverFooter>
            <Link as={RouterLink} to="/team-membership/registration">
              + Create New Team
            </Link>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Tab>
  );
};

const roleToTabEntries: { [s in SessionRole]: NavEntryProps[] } = {
  client: CLIENT_TABS,
  admin: ADMIN_TABS,
  therapist: THERAPIST_TABS,
};

function NavTabs() {
  const { pathname } = useLocation();
  const { role } = useSession();
  const tabEntries = roleToTabEntries[role];

  const renderNavBarEntry = (navEntry: NavEntryProps) => {
    const { path } = navEntry;
    const navEntryProps = { key: path, navEntry };
    switch (path) {
      case '/account/teams':
        return <NavBarTeamAdminDropdown {...navEntryProps} />;
      default:
        return <NavBarIconButtonLink {...navEntryProps} />;
    }
  };
  return (
    <Tabs
      variant="soft-rounded"
      colorScheme="green"
      index={_.findIndex(tabEntries, ({ path }) => Boolean(matchPath(pathname, { path })))}
      width={{ base: '100%', md: 'auto' }}
    >
      <TabList justifyContent={{ base: 'space-between', md: 'flex-start' }}>
        {tabEntries.map(renderNavBarEntry)}
      </TabList>
    </Tabs>
  );
}

export function NavBar() {
  const { loggedIn } = useSession();

  /*
   * We'll likely want to programatically show/hide the nav
   * based on routing in a far more prescriptive way than this
   * but for now we just hide based on a simple match.
   */
  const isPreAuth = useRouteMatch([
    '/try',
    '/forgot-password',
    '/claim-account',
    '/log-in',
    '/join',
    '/membership',
    '/team-membership',
  ]);

  if (!loggedIn || isPreAuth) return null;

  return (
    <Flex
      as="nav"
      justify={{ sm: 'space-around', md: 'space-between' }}
      wrap="nowrap"
      padding={{ base: '1rem', md: '1.5rem' }}
      borderTopStyle={{ sm: 'solid', md: 'none' }}
      borderTopColor="gray.100"
      borderTopWidth="thin"
      width="100%"
      bg="white"
      height={{
        // We don't *explicitly* need this height here, but
        // since it is currently referenced all over the app,
        // we add it anyhow.
        md: navHeightPx,
      }}
    >
      <HStack
        spacing={{ base: 0, md: 2 }}
        justify={{ sm: 'space-evenly', md: 'flex-start' }}
        width={{ sm: 'stretch' }}
      >
        <LogoContainer />
        <NavTabs />
      </HStack>
      <AccountDropdown />
    </Flex>
  );
}
