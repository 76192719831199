import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useQueryParams } from '@coa/react-utils';
import _ from 'lodash';
import React from 'react';
import { matchPath } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const TABS = [
  { path: '/log-in', label: 'Sign In' },
  { path: '/join', label: 'Join Coa' },
];

export function AuthNav() {
  const location = useLocation();
  const queryParams = useQueryParams();
  const redirectTo = queryParams.get('redirect_to');
  return (
    <Tabs
      isFitted
      index={_.findIndex(TABS, ({ path }) => Boolean(matchPath(location.pathname, { path })))}
      variant="coa-line"
      size="lg"
    >
      <TabList borderColor="transparent">
        {TABS.map(({ path, label }) => (
          <Tab
            key={path}
            as={RouterLink}
            to={redirectTo ? `${path}?redirect_to=${redirectTo}` : path}
            // Apply alt-link selector to the other button.
            {...(matchPath(location.pathname, { path }) ? {} : { 'data-cy': 'alt-auth-link' })}
          >
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
