import { useGetAccountQuery } from '@coa/api/controllers/v1/accounts';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import _ from 'lodash';
import { UseQueryResult } from 'react-query';
import { sessionLocalStorage } from '../../lib/localStorage';
import { NotificationKind } from './types';

type UseNotificationResult = {
  isLoading: boolean;
  queries: {
    // We may wish to indicate this explicitly as a QueryResultName or QueryResultKey
    // in the future, but for now string is sufficient.
    [s: string]: UseQueryResult;
  };
  notifications?: {
    [Kind in NotificationKind]?: {
      active: boolean;
    };
  };
};

type UseNotificationHook = () => UseNotificationResult;

/*
 * Hook that fetches and processes data for a particular notification kind.
 */
const useVerifyEmailNotification: UseNotificationHook = () => {
  const getAccountQuery = useGetAccountQuery();
  const { isLoading } = getAccountQuery;

  const baseResult = {
    isLoading,
    queries: {
      getAccountQuery,
    },
  };

  if (isLoading) return baseResult;
  const { data: account } = getAccountQuery;

  return {
    ...baseResult,
    notifications: {
      requireVerifyEmail: { active: account.status === 'invited' },
    },
  };
};

const useMembershipNotification: UseNotificationHook = () => {
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const currentMember = sessionLocalStorage.getCurrentMember();
  const { isLoading } = getSubscriptionsQuery;
  const baseResult = {
    isLoading,
    queries: {
      getSubscriptionsQuery,
    },
  };

  if (isLoading) return baseResult;
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  return {
    ...baseResult,
    notifications: {
      membership: { active: !hasActiveSubscription && currentMember?.role !== 'admin' },
    },
  };
};

const countActiveNotifications = (notifications: UseNotificationResult['notifications']) =>
  // Lodash's _.sumBy and _.countBy don't do what we want, so we just reduce. :shrug:
  _.reduce(notifications, (acc, { active }) => acc + (active ? 1 : 0), 0);

/*
 *  This interface exists so that we may easily assemble and organize
 *  data pertaining to several notification kinds in one place.
 */
export const useNotificationsQueries: () => UseNotificationResult & { count: number } = () => {
  const verifyEmailNotification = useVerifyEmailNotification();
  const membershipNotification = useMembershipNotification();

  // In the future we can list additional notification interfaces here
  // and combine them.
  const baseResult = {
    isLoading: verifyEmailNotification.isLoading || membershipNotification.isLoading,
    queries: {
      ...(verifyEmailNotification.queries || {}),
      ...(membershipNotification.queries || {}),
    },
    notifications: {
      ...(verifyEmailNotification.notifications || {}),
      ...(membershipNotification.notifications || {}),
    },
  };

  return {
    ...baseResult,
    count: countActiveNotifications(baseResult.notifications),
  };
};
