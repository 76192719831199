import { OnDemandVideoCheckpoint } from '../../../resources/onDemandVideoCheckpoints';
import { OnDemandVideo } from '../../../resources/onDemandVideos';
import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace OnDemandAnalytics {
  type EventName =
    | 'Viewed On-Demand Homepage'
    | 'Searched On-Demand Videos'
    | 'Clicked On-Demand Topic'
    | 'Clicked On-Demand Checkpoint'
    | 'Played On-Demand Video'
    | 'Paused On-Demand Video';

  type EventNameToEventBody = {
    'Viewed On-Demand Homepage': never;
    'Searched On-Demand Videos': {
      query: string;
      numOfResults: number;
    };
    'Clicked On-Demand Topic': {
      topic: OnDemandVideo['title'];
      videoId: OnDemandVideo['id'];
    };
    'Clicked On-Demand Checkpoint': {
      checkpointQuestion: OnDemandVideoCheckpoint['title'];
      checkpointId: OnDemandVideoCheckpoint['id'];
      videoId: OnDemandVideo['id'];
    };
    'Played On-Demand Video': never;
    'Paused On-Demand Video': never;
  };
}

export const onDemandAnalytics = createSafeAnalyticsClient<
  OnDemandAnalytics.EventName,
  OnDemandAnalytics.EventNameToEventBody
>();
