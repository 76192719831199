/* eslint-disable react/prop-types */
import { Box, Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { list } from '../actions/payouts';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import Payouts from '../components/Payouts';

class PayoutsContainer extends Component {
  constructor(props) {
    super(props);
    this.getPayouts = this.getPayouts.bind(this);
  }

  componentDidMount() {
    this.getPayouts();
  }

  getPayouts() {
    const { dispatch, memberId } = this.props;
    dispatch(list(memberId));
  }

  render() {
    const { gettingPayouts, payouts } = this.props;
    if (gettingPayouts) {
      return <Loading />;
    }
    if (payouts.length === 0) {
      return (
        <Box maxWidth="80%" width="stretch">
          <Heading my={4}>Payments</Heading>
          <EmptyState
            message="You have no incoming payouts yet. When your clients pay their invoice you’ll be able to track the status of your payouts here."
            type="payments"
          />
        </Box>
      );
    }
    return <Payouts payouts={payouts} />;
  }
}

PayoutsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { memberId } = state.session;
  const { payouts, gettingPayouts } = state.payouts;
  return { memberId, payouts, gettingPayouts };
};

export default connect(mapStateToProps)(PayoutsContainer);
