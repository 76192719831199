import { Button, Flex, FormControl, Textarea, useToast } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { useUpdateWorkshopEnrollmentMutation } from '../../../../resources/workshops';

type QuestionFormValues = {
  // share type?
  question: string;
};

const createValidationSchema = () =>
  Yup.object().shape({
    question: Yup.string().required(),
  });

export function TryQAndAQuestionSubmissionForm({ qAndAId }: { qAndAId: string }) {
  const history = useHistory();
  const toast = useToast();
  const initialValues: QuestionFormValues = { question: '' };
  const updateEnrollmentMutation = useUpdateWorkshopEnrollmentMutation({ id: qAndAId });

  const onSubmit = useCallback(async ({ question }) => {
    const answers = {
      question_from_audience: question,
    };
    try {
      await updateEnrollmentMutation.mutateAsync({
        answers,
      });
      history.push('/my-coa');
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={createValidationSchema()}
      onSubmit={onSubmit}
    >
      <Flex width="100%" justifyContent="center" as={Form} direction="column">
        <FormControl>
          <Field
            // disabled={userExists}
            name="question"
            type="textarea"
            as={Textarea}
            variant="coa-main"
            placeholder="Submit your questions for the Q&A here!"
            data-cy="q-and-a-question-field"
          />
        </FormControl>
        <Flex width="100%" justifyContent="center" marginTop="12px">
          <Button type="submit" size="lg" variant="secondary" data-cy="q_and_a-success-cta">
            Submit & Go to my Q & A!
          </Button>
        </Flex>
      </Flex>
    </Formik>
  );
}
