import path from 'path';
import { generatePath } from 'react-router';
import { createGetRouterUrl } from './utils';

const tryApexRouterPath = path.resolve('try');

const tryQAndARouterPath = path.resolve(tryApexRouterPath, 'q-and-a');
const tryQAndAInstanceRouterPath = path.resolve(tryQAndARouterPath, ':workshopSlug');

const tryPushupRouterPath = path.resolve(tryApexRouterPath, 'pushups');

export const tryRouterPaths = {
  apex: tryApexRouterPath,
  qAndA: tryQAndARouterPath,
  qAndAInstance: tryQAndAInstanceRouterPath,
  pushups: tryPushupRouterPath,
};

type TryQAndAInstanceRouterPathParams = { workshopSlug: string };

export type TryRouterPathParams = {
  QAndAInstance: TryQAndAInstanceRouterPathParams;
};

export const getTryRouterUrl = {
  apex: createGetRouterUrl(tryApexRouterPath),
  qAndA: createGetRouterUrl(tryQAndARouterPath),
  qAndAInstance: createGetRouterUrl<TryQAndAInstanceRouterPathParams>(tryQAndAInstanceRouterPath),
  pushups: createGetRouterUrl(tryPushupRouterPath),
};

const createGetUniversalTryRouterUrl = <RouterPathParams extends TryQAndAInstanceRouterPathParams>({
  tryRouterPath,
  tryInstanceRouterPath,
}: {
  tryRouterPath: string;
  tryInstanceRouterPath?: string;
}) => (routeParams: RouterPathParams = {} as RouterPathParams) =>
  generatePath(routeParams.workshopSlug ? tryInstanceRouterPath : tryRouterPath, routeParams);

export const getUniversalTryRouterUrl = {
  qAndA: createGetUniversalTryRouterUrl({
    tryRouterPath: tryQAndARouterPath,
    tryInstanceRouterPath: tryQAndAInstanceRouterPath,
  }),
  pushups: createGetUniversalTryRouterUrl({
    tryRouterPath: tryPushupRouterPath,
  }),
};
