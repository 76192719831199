import { Box, Button, ButtonGroup, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  GetWorkshop,
  useGetWorkshopQuery,
  Workshop,
  WorkshopOccurrence,
} from '../../../../resources/workshops';
import { createTryWorkshopURL } from '../../../try/utils';
import { AdminWorkshopUrlPicker } from './AdminWorkshopUrlPicker';

/*
 * TODO: Add Edit Org Access action
 */

type AdminWorkshopActionsProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
} & FlexProps;

export const AdminWorkshopActions = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: AdminWorkshopActionsProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { data: workshop } = getWorkshopQuery.isLoading
    ? { data: {} as GetWorkshop.Response }
    : getWorkshopQuery;
  const showAdminUrlPicker = workshop.kind === 'q_and_a';
  const workshopUrl = getWorkshopQuery.isLoading ? '' : createTryWorkshopURL(workshop);

  return (
    <Flex
      width="stretch"
      alignItems="center"
      p={4}
      bgColor="gray.100"
      borderRadius="base"
      {...rest}
    >
      <Heading width="stretch" fontSize="md">
        Admin Actions
      </Heading>
      {showAdminUrlPicker && <AdminWorkshopUrlPicker initialValue={workshopUrl} />}
      <Box>
        <ButtonGroup justifyContent="flex-end" width="stretch">
          <Button
            colorScheme="gray"
            as={RouterLink}
            to={`/admin-dash/classes/${workshopId}/enrollees`}
          >
            See Enrollees
          </Button>
          <Button
            colorScheme="evergreen"
            as={RouterLink}
            to={`/admin-dash/classes/${workshopId}/tags`}
          >
            Add/Remove Tags
          </Button>
          <Button colorScheme="orange" as={RouterLink} to={`/admin-dash/classes/${workshopId}`}>
            Edit Details
          </Button>
        </ButtonGroup>
        {dropInWorkshopOccurrenceId ? (
          <>
            <Text mt={2}>You may edit this Drop-In class via the Series in which it occurs.</Text>
          </>
        ) : null}
      </Box>
    </Flex>
  );
};
