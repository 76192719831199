import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { joinTimeLabels } from '@coa/stdlib/string';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ClassCard } from '../../../../components/molecules/ClassCard';
import { TransformUpLink } from '../../../../components/molecules/TransformUpLink';
import {
  createGetQAndAWorkshopsPlaceholderData,
  formatSingleClassTimeLabel,
  useQAndAWorkshopsQuery,
} from '../../../../resources/workshops';

export const UpcomingQAndAsCardView = () => {
  const numberOfResults = 4;
  const qAndACardsPlaceholderData = createGetQAndAWorkshopsPlaceholderData({
    length: numberOfResults,
  });
  const qAndAQuery = useQAndAWorkshopsQuery({ placeholderData: qAndACardsPlaceholderData });
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const { data: qAndAs } = qAndAQuery;
  const isLoading = qAndAQuery.isLoading || qAndAQuery.isPlaceholderData;

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      columnGap={{ base: 4, md: 6, lg: 8 }}
      rowGap={{ base: 4, md: 6, lg: 8 }}
    >
      {qAndAs.slice(0, numberOfResults).map((qAndA) => {
        const timingLabel = joinTimeLabels(
          formatSingleClassTimeLabel(qAndA.workshopOccurrences[0]),
          `${qAndA.workshopOccurrences[0].durationInMinutes}min`
        );

        return (
          <GridItem colSpan={{ base: 6, lg: 3 }} key={qAndA.id}>
            <Skeleton isLoaded={!isLoading} height="100%" borderRadius="lg" fadeDuration={0}>
              <TransformUpLink
                as={RouterLink}
                to={`/classes/${qAndA.id}`}
                height="100%"
                width="100%"
              >
                <ClassCard
                  marketListingLabel={qAndA.marketListingLabel}
                  title={qAndA.title}
                  timingLabel={timingLabel}
                  instructorName={qAndA.therapist.name}
                  src={qAndA.imageUrl}
                  showClassLabel={!hasActiveSubscription}
                  workshopAccess={qAndA.access}
                  kind={qAndA.kind}
                />
              </TransformUpLink>
            </Skeleton>
          </GridItem>
        );
      })}
    </Grid>
  );
};
