import { AxiosRequestConfig } from 'axios';
import { InvalidApiTokenError } from '../errors/InvalidApiTokenError';
import { addTransformResponse } from './util/addTransform';

/**
 * An interceptor that catches 401s and logs members out
 * Will expand this interceptor if/when we have more specific error cases
 * to handle
 */

type ErrorMessages = {
  invalidAPIToken: 'Invalid API Token';
};

const INVALID_API_TOKEN_MESSAGE: ErrorMessages['invalidAPIToken'] = 'Invalid API Token';

export const customExceptionInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  addTransformResponse(config, (data) => {
    if (data?.errors && data?.errors.base === INVALID_API_TOKEN_MESSAGE) {
      throw new InvalidApiTokenError();
    }
    return data;
  });
  return config;
};
