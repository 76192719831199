import { useEffect } from 'react';
import { appConfig } from '../config';

export const CoaDevTools = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Coa development environment launched. Using feature flag configuration',
      appConfig
    );
  }, []);
  return null;
};
