import { useMediaQuery, useQuery } from '@chakra-ui/react';

export * from './boundChakraComponentFactory';
export * from './hooks';
export * from './pickSharedProps';
export * from './types';

/*
 * Chakra's useQuery hook allows access to themed breakpoints and
 * returns the suffix of a media-query rule (i.e. "min-width: 500px").
 * This helper prefixes it with `@media` for ease-of-use so it can
 * be used in the sx prop to customize the behavior of non-standard
 * style-props using media queries.
 *
 * Usage:
 *
 *    const My Component = () => {
 *        const [largerThanMdMediaQueryStr] = useThemedMediaQueryString({ above: 'md' });
 *        return (
 *          <Box
 *            sx={{
 *              [largerThanMdMediaQueryStr]: {
 *                // The "direction" prop, for example, is not a native style-prop and,
 *                // can only be changed using sx, so we need to declare the media-query
 *                // value explicitly.
 *                direction: 'rtl'
 *              }
 *            }}
 *          />
 *        );
 *    }
 */
export const useThemedMediaQueryRuleStr = (...args: Parameters<typeof useQuery>) => {
  const mediaQueryStr = useQuery(...args);
  return `@media ${mediaQueryStr}`;
};

/*
 * Chakra's useMediaQuery hook tells us whether or not a set of media
 * query rules are active (i.e. Is the viewport wider than X?) but does
 * not allow access to the theme. This helper translates the themed breakpoints
 * into media-query rule suffixes that can be consumed by useMediaQuery
 * for ease-of-use.
 *
 * Usage:
 *
 *    const MyComponent = () => {
 *       const [isLargerThanMd] = useThemedBreakpoint({ above: 'md' });
 *       return isLargerThanMd
 *         ? <p>bigger screen</p>
 *         : <p>smaller screen</p>;
 *    }
 *
 */
export const useThemedBreakpoint = (...args: Parameters<typeof useQuery>) => {
  const mediaQueryStr = useQuery(...args);
  return useMediaQuery(mediaQueryStr);
};
