import { Box, Button, ButtonGroup, Center, Flex, Heading, IconButton } from '@chakra-ui/react';
import { useAdminIndexWorkshopsQuery } from '@coa/api/controllers/admin/workshops';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import { BsPeople } from 'react-icons/bs';
import { ArrowRightIcon, EditIcon } from '../../../components/Icons';
import Loading from '../../../components/Loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table';
import { formatWorkshopTimeLabel } from '../../../resources/workshops';
import { AdminClassesFilters } from './AdminClassesFilters';
import {
  AdminClassesFiltersContextProvider,
  useAdminClassesFiltersContext,
} from './components/AdminClassesFiltersContextProvider';

const AdminClassesViewInner = () => {
  const { filters } = useAdminClassesFiltersContext();
  const { data, isLoading } = useAdminIndexWorkshopsQuery(
    {
      order_by: 'firstOccStartsAt', // TODO: actually use this on backend
      page: filters.page,
      order: filters.order,
      filters: JSON.stringify({ date_range: filters.dateRange }),
    },
    { staleTime: 0 }
  );

  return (
    <AdminClassesFiltersContextProvider>
      <Box width="stretch" maxWidth="80%">
        <Flex my={6} alignItems="center" justify="space-between" width="stretch">
          <Heading>Classes</Heading>
          <Flex>
            <ButtonGroup ml={2}>
              <Button as={RouterLink} to="/admin-dash/classes/new" data-cy="new-workshop-button">
                + New Class
              </Button>
              <Button as={RouterLink} to="/admin-dash/enrollments/new">
                + New Enrollment
              </Button>
              <Button as={RouterLink} to="/admin-dash/hubspot-templates/new">
                + New Email Template
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex my={6} alignItems="center" justify="space-between" width="full">
          <AdminClassesFilters maxPages={data?.meta.totalPages} isLoading={isLoading} />
        </Flex>
        <Center>
          {isLoading ? (
            <Loading />
          ) : (
            <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
              <Table aria-label="Classes">
                <TableHead>
                  <TableRow>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Timing</TableHeader>
                    <TableHeader>Enrollees</TableHeader>
                    <TableHeader>Kind</TableHeader>
                    <TableHeader>Therapist</TableHeader>
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.page.map((workshop) => (
                    <TableRow key={workshop.id}>
                      <TableCell>
                        <Heading fontSize="md">{workshop.title}</Heading>
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                        {formatWorkshopTimeLabel(workshop, workshop.workshopOccurrences)}
                      </TableCell>
                      <TableCell>{workshop.approvedEnrolleeCount} Approved</TableCell>
                      <TableCell>{workshop.kind}</TableCell>
                      <TableCell>{workshop.therapist.name}</TableCell>
                      <TableCell>
                        <Flex justifyContent="flex-end">
                          <IconButton
                            as={RouterLink}
                            to={`/admin-dash/classes/${workshop.id}/enrollees`}
                            borderRadius="50%"
                            color="gray"
                            bgColor="green.50"
                            variant="outline"
                            icon={<BsPeople />}
                            aria-label=""
                          />
                          <IconButton
                            as={RouterLink}
                            to={`/admin-dash/classes/${workshop.id}`}
                            borderRadius="50%"
                            color="gray"
                            mx={5}
                            bgColor="green.50"
                            variant="outline"
                            icon={<EditIcon />}
                            aria-label=""
                          />
                          <IconButton
                            as={RouterLink}
                            to={`/classes/${workshop.id}`}
                            borderRadius="50%"
                            color="gray"
                            bgColor="green.50"
                            variant="outline"
                            icon={<ArrowRightIcon />}
                            aria-label=""
                          />
                        </Flex>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Center>
      </Box>
    </AdminClassesFiltersContextProvider>
  );
};

export const AdminClassesView = () => (
  <AdminClassesFiltersContextProvider>
    <AdminClassesViewInner />
  </AdminClassesFiltersContextProvider>
);
