import { Text, TextProps } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

const padZero = (digit: number): string => _.padStart(String(digit), 2, '0');

/*
 * Based primarily on FormattedTime from alexanderwallin/react-player-controls.
 * @see https://github.com/alexanderwallin/react-player-controls/blob/f49bb4d9a189df424ff3220abceb4dfb77df5afe/src/components/FormattedTime.js
 */
export const formatTimestamp = (numSeconds: number) => {
  const prefix = numSeconds < 0 ? '-' : '';
  const absNumSeconds = Math.abs(numSeconds);

  const hours = Math.floor(absNumSeconds / 3600);
  const minutes = Math.floor((absNumSeconds % 3600) / 60);
  const seconds = Math.floor(absNumSeconds) % 60;

  return hours > 0
    ? `${prefix}${hours}:${padZero(minutes)}:${padZero(seconds)}`
    : `${prefix}${minutes}:${padZero(seconds)}`;
};

export const TimestampText = ({ numSeconds }: { numSeconds: number }) => {
  const formattedTimestamp = formatTimestamp(numSeconds);
  /*
   * We have to "stabilize the width of a timestamp", particularly
   * since digits are different widths (i.e. 1 is narrower than 0)
   * resulting in a timestamp that appears to "jump around".
   *
   * The easiest thing to do here is to use a monospaced font, but
   * we don't have one in our theme right now and various system
   * monospaced fonts look pretty ugly. Ho hum.
   *
   * In the meantime, we declare a fixed width when possible and
   * center inside of it.
   */
  const width = formattedTimestamp.length < 5 ? '34px' : 'auto';
  return (
    <span style={{ display: 'inline-block', textAlign: 'center', width }}>
      {formatTimestamp(numSeconds)}
    </span>
  );
};

export const FormattedTimeText = ({
  playedSeconds,
  durationSeconds,
  ...rest
}: TextProps & {
  playedSeconds: number;
  durationSeconds: number;
}) => (
  <Text {...rest}>
    <TimestampText numSeconds={playedSeconds} />
    {' / '}
    <TimestampText numSeconds={durationSeconds} />
  </Text>
);
