/*
 * The FileReader interface applies a prefix to base64-encoded
 * file results, but this needs to be removed in order for it to
 * be consumable via atob.
 *
 * For example:
 * - Input:  "data:image/png;base64,iVBORw0KGgoAAAANS"
 * - Output: "iVBORw0KGgoAAAANS"
 */
const removeBase64Prefix = (base64DataWithPrefix: string) => {
  const tokens = base64DataWithPrefix.split(',');
  return tokens.length > 1 ? tokens[1] : tokens[0];
};

export const getFileExtensionFromBase64Prefix = (base64DataWithPrefix: string) => {
  const [prefix] = base64DataWithPrefix.split(',');
  if (prefix.match(/image\/png/)) return '.png';
  if (prefix.match(/image\/jpe?g/)) return '.jpg';
  if (prefix.match(/image\/svg\+xml/)) return '.svg';
  throw Error(
    `Invalid filetype detected - cannot provide extension for base64 contents with prefix: "${prefix}"`
  );
};

/*
 * Convert base64-encoded data to a binary blob.
 * @see https://stackoverflow.com/a/16245768
 *
 * We need this for cases where file uploads are managed in browser by an interface
 * that assumes the outcome will be a base64 enocded string - (particularly
 * relevant when using tools like react-jsonschema-form) - since our APIs assume the
 * use of `Content-Type: form-data/multipart`, which assumes that the file will
 * be passed as a blob.
 */
export const convertBase64ToBlob = (base64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(removeBase64Prefix(base64Data));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/*
 * We use this mostly in the context of the CMS in order to dynamically
 * determine whether or not we need to convert values to Binary data.
 * IT IS LIKELY NOT EXHAUSTIVE but gets the job done for now.
 */
const base64ImagePrefixRegex = /^data:image/;
const isBase64Image = (maybeBase64ImageData: string) =>
  Boolean(maybeBase64ImageData.match(base64ImagePrefixRegex));

/*
 * Right now we only support images but define the check generically
 * regardless. We can expand as necessary.
 */
export const isBase64 = (maybeBase64Data: string) => isBase64Image(maybeBase64Data);
