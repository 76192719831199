import { Checkbox, CheckboxGroup, VStack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';

// The thought is to move all formik components to this directory
// A lot of the current implementations are organized at the spcific view level
// moving them here centralizes them for easier reuse

// type FormikQuestionKind = 'short_text' | 'text' | 'checkbox' | 'select' | 'date' | 'radio';
type FormikQuestionFieldProps = {
  name: string;
  // kind: FormikQuestionKind;
  // question?: string;
  // // placeholder?: string;
  options?: Array<{ label: string; value?: string }>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  // hideErrMessage?: boolean;
};

export const CheckboxField = ({
  isDisabled = false,
  options,
  name,
  isInvalid,
}: FormikQuestionFieldProps) => {
  const { values } = useFormikContext();
  return (
    <CheckboxGroup isDisabled={isDisabled} variant="coa-main" value={values[name]}>
      <VStack align="left" width="stretch" justifyContent="start">
        {options.map(({ label, value }) => (
          <Field
            key={label}
            type="checkbox"
            as={Checkbox}
            name={name}
            value={value || label}
            isInvalid={isInvalid}
            data-cy={`form-field-checkbox-${_.kebabCase(name)}-${_.kebabCase(value || label)}`}
          >
            {label}
          </Field>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};
