import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Progress,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { RouterLink, useRouteParams } from '@coa/react-utils';
import React from 'react';
import { useHistory } from 'react-router';
import { PlayCircleIcon, PlaylistIcon } from '../../../components/Icons';
import { onDemandAnalytics } from '../../../lib/analytics/clients';
import { OnDemandVideoCheckpoint } from '../../../resources/onDemandVideoCheckpoints';
import { OnDemandVideo, useGetOnDemandVideoQuery } from '../../../resources/onDemandVideos';
import { createGetOnDemandVideoPlaceholderData } from '../../../resources/onDemandVideos/apis/placeholder';
import { getRouterUrl, RouterPathParams } from '../../../routerPaths';
import { getUniversalOnDemandRouterUrl } from '../../../routerPaths/onDemandRouterPaths';
import { OnDemandVideoSubscribeModal } from './components/OnDemandVideoSubscribeModal';
import { VideoPlayer } from './components/VideoPlayer';
import { NextVideoCheckpoint } from './components/VideoPlayer/components/NextVideoCheckpoint';
import { VideoCheckpointTitleScreen } from './components/VideoPlayer/components/VideoCheckpointTitleScreen';
import { VideoComplete } from './components/VideoPlayer/components/VideoComplete';
import { OnDemandBreadcrumb } from './OnDemandBreadcrumb';

const OnDemandVideoPlayer = () => {
  const history = useHistory();
  const { videoId, checkpointId: checkpointIdRouteParam } = useRouteParams<
    RouterPathParams.OnDemand['VideoCheckpoint']
  >();
  const placeholderData = createGetOnDemandVideoPlaceholderData({ id: videoId });
  const getOnDemandVideoQuery = useGetOnDemandVideoQuery({ id: videoId }, { placeholderData });
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const { data: subscription } = getSubscriptionsQuery;
  const { data: onDemandVideo } = getOnDemandVideoQuery;

  const isLoading =
    getOnDemandVideoQuery.isLoading ||
    getOnDemandVideoQuery.isPlaceholderData ||
    getSubscriptionsQuery.isLoading;

  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(subscription);

  const checkpointIndex =
    !isLoading && checkpointIdRouteParam
      ? onDemandVideo.onDemandVideoCheckpoints.findIndex(({ id }) => id === checkpointIdRouteParam)
      : 0;
  const checkpoint = onDemandVideo.onDemandVideoCheckpoints[checkpointIndex];
  const nextCheckpoint = onDemandVideo.onDemandVideoCheckpoints[checkpointIndex + 1];

  const checkpointDurationSeconds =
    !isLoading && nextCheckpoint
      ? nextCheckpoint.timestampSeconds - checkpoint.timestampSeconds
      : undefined;

  return (
    <VideoPlayer
      url={onDemandVideo.hostedEmbedUrl}
      segmentId={checkpoint.id}
      videoSegmentStartSeconds={checkpoint.timestampSeconds}
      videoSegmentDurationSeconds={checkpointDurationSeconds}
      renderSegmentStart={() => <VideoCheckpointTitleScreen checkpoint={checkpoint} />}
      renderSegmentComplete={() => (
        <NextVideoCheckpoint videoId={videoId} nextCheckpoint={nextCheckpoint} />
      )}
      renderVideoComplete={() => <VideoComplete />}
      isLoading={isLoading}
      preventPlayCondition={!hasActiveSubscription}
      handlePlayPrevented={() => {
        history.push(
          getUniversalOnDemandRouterUrl.videoSubscribe({
            videoId,
            checkpointId: checkpointIdRouteParam,
          })
        );
      }}
    />
  );
};

type OnDemandVideoDetailsProgressProps = {
  progress: number;
  numOfCheckpoints: number;
  videoTitle: OnDemandVideo['title'];
};

const OnDemandVideoDetailsProgress = ({
  progress,
  numOfCheckpoints,
  videoTitle,
}: OnDemandVideoDetailsProgressProps) => (
  <Box mb={6}>
    <Flex alignItems="flex-end" justifyContent="space-between">
      <Flex>
        <PlaylistIcon mr={2} color="gray.300" />
        <Heading size="xs" pr={4}>
          {videoTitle}
        </Heading>
      </Flex>
      <Text fontSize="sm" textAlign="end" whiteSpace="nowrap">
        Watching video {progress} of {numOfCheckpoints}
      </Text>
    </Flex>
    <Progress my={4} size="xs" value={progress} max={numOfCheckpoints} colorScheme="green" />
  </Box>
);

const OnDemandVideoDetailsCheckpoints = () => {
  const { videoId, checkpointId: checkpointIdRouteParam } = useRouteParams<
    RouterPathParams.OnDemand['VideoCheckpoint']
  >();
  const placeholderData = createGetOnDemandVideoPlaceholderData({ id: videoId });
  const getOnDemandVideoQuery = useGetOnDemandVideoQuery({ id: videoId }, { placeholderData });

  const { data: onDemandVideo } = getOnDemandVideoQuery;

  const isLoading = getOnDemandVideoQuery.isLoading || getOnDemandVideoQuery.isPlaceholderData;
  const checkpointIndex =
    !isLoading && checkpointIdRouteParam
      ? onDemandVideo.onDemandVideoCheckpoints.findIndex(({ id }) => id === checkpointIdRouteParam)
      : 0;

  const trackCheckpointClicked = (checkpoint: OnDemandVideoCheckpoint) => {
    onDemandAnalytics.track('Clicked On-Demand Checkpoint', {
      checkpointQuestion: checkpoint.title,
      checkpointId: checkpoint.id,
      videoId: checkpoint.onDemandVideo.id,
    });
  };

  return (
    <Box>
      <Skeleton isLoaded={!isLoading}>
        <Heading size="md" display={{ base: 'block', md: 'none' }} mb={2}>
          Video Checkpoints
        </Heading>
      </Skeleton>
      <Flex flexDirection="column">
        {onDemandVideo.onDemandVideoCheckpoints.map((checkpoint) => (
          <Skeleton isLoaded={!isLoading} w="100%" mb={4} key={checkpoint.id}>
            <Link
              display="flex"
              color={
                checkpoint.id === onDemandVideo.onDemandVideoCheckpoints[checkpointIndex].id
                  ? 'green.700'
                  : 'gray.400'
              }
              _hover={{ color: 'green.700' }}
              as={RouterLink}
              to={getRouterUrl.onDemand.videoCheckpoint({ videoId, checkpointId: checkpoint.id })}
              onClick={() => {
                trackCheckpointClicked(checkpoint);
              }}
            >
              <PlayCircleIcon boxSize={5} mr={2} mt={1} />
              {checkpoint.title}
            </Link>
          </Skeleton>
        ))}
      </Flex>
    </Box>
  );
};
const OnDemandVideoDetails = () => {
  const { videoId, checkpointId: checkpointIdRouteParam } = useRouteParams<
    RouterPathParams.OnDemand['VideoCheckpoint']
  >();
  const placeholderData = createGetOnDemandVideoPlaceholderData({ id: videoId });
  const getOnDemandVideoQuery = useGetOnDemandVideoQuery({ id: videoId }, { placeholderData });

  const { data: onDemandVideo } = getOnDemandVideoQuery;

  const isLoading = getOnDemandVideoQuery.isLoading || getOnDemandVideoQuery.isPlaceholderData;
  const checkpointIndex =
    !isLoading && checkpointIdRouteParam
      ? onDemandVideo.onDemandVideoCheckpoints.findIndex(({ id }) => id === checkpointIdRouteParam)
      : 0;
  const checkpoint = onDemandVideo.onDemandVideoCheckpoints[checkpointIndex];

  // Checkpoint progress is a 1-based index, so we can use the Checkpoint index to calculate the progress.
  const checkpointProgress = checkpointIndex + 1;

  return (
    <Box>
      <Skeleton isLoaded={!isLoading}>
        <Heading size="md" mb={6}>
          {checkpoint.title}
        </Heading>
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        <OnDemandVideoDetailsProgress
          videoTitle={onDemandVideo.title}
          progress={checkpointProgress}
          numOfCheckpoints={onDemandVideo.onDemandVideoCheckpoints.length}
        />
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        <Text mt={4}>{onDemandVideo.description}</Text>
      </Skeleton>
    </Box>
  );
};

export const OnDemandVideoView = () => (
  <Box pt={{ base: 2, md: 8 }}>
    <OnDemandBreadcrumb
      maxW="container.md"
      w="100%"
      px={{ base: 4, md: 8, xl: 0 }}
      mb={4}
      mt={{ base: 2, md: 0 }}
    />
    <Container
      maxW="100%"
      backgroundColor="gray.50"
      py={{ base: 4, md: 8 }}
      px={0}
      mb={{ base: 4, md: 8 }}
      display="flex"
      justifyContent="center"
    >
      <Container maxW="container.md" px={{ base: 4, md: 8, xl: 0 }}>
        <OnDemandVideoPlayer />
      </Container>
    </Container>
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={8}
      maxW="container.md"
      w="100%"
      px={{ base: 4, md: 8, xl: 0 }}
    >
      <GridItem colSpan={{ base: 12, md: 6, lg: 8 }}>
        <OnDemandVideoDetails />
      </GridItem>
      <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
        <OnDemandVideoDetailsCheckpoints />
      </GridItem>
    </Grid>
    <OnDemandVideoSubscribeModal />
  </Box>
);
