import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Background from '../images/background.png';
import { ArrowLeftIcon, ArrowRightIcon, MailIcon } from './Icons';

const answerComponentFor = ({
  question: { kind, answers, header, paragraph, cta, link },
  updateAnswer,
  answerValue,
  answerValues,
  updateAnswers,
}) => {
  switch (kind) {
    case 'radio': {
      return (
        <RadioGroup onChange={updateAnswer} value={answerValue}>
          <Stack>
            {answers.map((answer) => (
              <Box key={answer.key} py={2}>
                <Radio
                  colorScheme="evergreen"
                  value={answer.key}
                  isChecked={answer.key === answerValue}
                >
                  {answer.label}
                </Radio>
              </Box>
            ))}
          </Stack>
        </RadioGroup>
      );
    }
    case 'checkbox': {
      const handleUpdateAnswers = (e) => {
        const { value, checked } = e.target;
        if (checked) {
          if (answerValues.indexOf(value) === -1) answerValues.push(value);
        } else {
          const index = answerValues.indexOf(value);
          if (index !== -1) answerValues.splice(index, 1);
        }
        updateAnswers(answerValues);
      };
      return (
        <>
          <Text fontSize="sm" fontWeight="bold" color="gray.400" mb={2}>
            SELECT ALL THAT APPLY
          </Text>
          {answers.length > 10 ? (
            <Grid templateColumns="repeat(2, 1fr)">
              {answers.map((answer) => (
                <Checkbox
                  colorScheme="evergreen"
                  isChecked={answerValues.indexOf(answer.key) !== -1}
                  value={answer.key}
                  onChange={handleUpdateAnswers}
                  key={answer.key}
                >
                  {answer.label}
                </Checkbox>
              ))}
            </Grid>
          ) : (
            <>
              {answers.map((answer) => (
                <Box key={answer.key} py={2}>
                  <Checkbox
                    colorScheme="evergreen"
                    isChecked={answerValues.indexOf(answer.key) !== -1}
                    value={answer.key}
                    onChange={handleUpdateAnswers}
                  >
                    {answer.label}
                  </Checkbox>
                </Box>
              ))}
            </>
          )}
        </>
      );
    }
    case 'long_text': {
      return (
        <Textarea
          mb={4}
          defaultValue={answerValue}
          onChange={(e) => updateAnswer(e.target.value)}
          bg="warm.50"
          borderWidth="0px"
          css={{ '::placeholder': { color: '#D1AE91' } }}
          borderRadius="0.25em"
        />
      );
    }
    case 'short_text': {
      return (
        <Input
          mb={4}
          defaultValue={answerValue}
          onChange={(e) => updateAnswer(e.target.value)}
          bg="warm.50"
          borderWidth="0px"
          css={{ '::placeholder': { color: '#D1AE91' } }}
          borderRadius="0.25em"
        />
      );
    }
    case 'exit': {
      return (
        <VStack spacing={8}>
          <MailIcon variant="ghost" color="orange" boxSize={24} />
          <Box>
            {header && <Heading align="center">{header}</Heading>}
            <Text align="center">{paragraph}</Text>
          </Box>
          <Button as={Link} target="_blank" colorScheme="evergreen" href={link}>
            {cta}
          </Button>
        </VStack>
      );
    }
    default: {
      return null;
    }
  }
};

const TherapyMatchingQuestion = ({
  question,
  prev,
  save,
  updateAnswer,
  updateAnswers,
  answerValue,
  answerValues,
  idx,
  length,
}) => {
  const percentComplete = (idx / length) * 100;
  return (
    <Grid
      templateColumns="3fr 2fr"
      templateRows="4fr 1fr"
      templateAreas={{ sm: `"main main" "footer footer"`, md: `"main img" "footer img"` }}
      width="stretch"
      minHeight={window.innerHeight - 100}
    >
      <Grid area="main" justify="center" alignItems="flex-start">
        <Flex direction="column" justify="center" width="stretch" m={{ sm: 4, md: 8 }}>
          {question.question && <Text fontSize="xl">{question.question}</Text>}
          <br />
          {answerComponentFor({
            question,
            updateAnswer,
            updateAnswers,
            answerValue,
            answerValues,
          })}
          <br />
        </Flex>
      </Grid>
      {question.kind !== 'exit' && (
        <Grid area="footer" bgColor="warm.50" justify="center" alignContent="center">
          <Flex direction="column" justify="center" width="stretch" align="center">
            <HStack>
              {!!prev && (
                <IconButton
                  bgColor="green.50"
                  color="evergreen"
                  onClick={prev}
                  icon={<ArrowLeftIcon />}
                />
              )}
              <Button
                colorScheme="evergreen"
                onClick={save}
                width={{ sm: '16rem', md: '20rem' }}
                disabled={question.kind === 'checkbox' ? answerValues.length === 0 : !answerValue}
                rightIcon={<ArrowRightIcon />}
              >
                Next
              </Button>
            </HStack>
            {!!length && (
              <Box width={{ sm: '18rem', md: '22rem' }} mt={4}>
                <Progress
                  bgColor="stone.500"
                  colorScheme="orange"
                  value={percentComplete}
                  height="5px"
                />
                <Text color="warm.300">{parseInt(percentComplete)}% complete</Text>
              </Box>
            )}
          </Flex>
        </Grid>
      )}
      <Grid
        display={{ sm: 'none', md: 'grid' }}
        area="img"
        backgroundImage={`url(${Background})`}
        bgSize="cover"
      />
    </Grid>
  );
};

TherapyMatchingQuestion.propTypes = {
  idx: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  question: PropTypes.shape({
    key: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    question: PropTypes.string,
    answers: PropTypes.array,
  }),
  updateAnswer: PropTypes.func,
  updateAnswers: PropTypes.func,
  answerValue: PropTypes.string,
  answerValues: PropTypes.array,
  prev: PropTypes.func,
  save: PropTypes.func.isRequired,
};

export default TherapyMatchingQuestion;
