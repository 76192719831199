import { createJsonApiAxiosClient } from '@coa/api/lib/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { appendQueryParams } from '../../../lib/url';
import { onDemandVideoCheckpointsApiPaths } from './apiPaths';
import type { GetOnDemandVideoCheckpoints, SearchOnDemandVideoCheckpoints } from './types';

const generateGetOnDemandVideoCheckpoints = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const url = onDemandVideoCheckpointsApiPaths.index();
  const fn = async () => {
    const { data } = await client.get<GetOnDemandVideoCheckpoints.Response>(url);
    return data;
  };

  return {
    path: url,
    fn,
  };
};

export const useGetOnDemandVideoQuery = (
  ops: UseQueryOptions<GetOnDemandVideoCheckpoints.Response> = {}
) => {
  const { path: queryKey, fn } = generateGetOnDemandVideoCheckpoints();
  return useQuery<GetOnDemandVideoCheckpoints.Response>(queryKey, fn, ops);
};

const generateSearchOnDemandVideoCheckpoints = (
  queryParams: SearchOnDemandVideoCheckpoints.Request['queryParams']
) => {
  const client = createJsonApiAxiosClient({ auth: true });
  const url = appendQueryParams(onDemandVideoCheckpointsApiPaths.search(), queryParams);
  const fn = async () => {
    const { data } = await client.get<SearchOnDemandVideoCheckpoints.Response>(url);
    return data;
  };

  return {
    path: url,
    fn,
  };
};

export const useSearchOnDemandVideoCheckpoints = (
  params: SearchOnDemandVideoCheckpoints.Request['queryParams'],
  ops: UseQueryOptions<SearchOnDemandVideoCheckpoints.Response> = {}
) => {
  const { path: queryKey, fn } = generateSearchOnDemandVideoCheckpoints(params);
  return useQuery<SearchOnDemandVideoCheckpoints.Response>(queryKey, fn, ops);
};
