import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { ArrowRightIcon } from '../../../components/Icons';
import { marketingSiteBaseUrl } from '../../../lib/www';
import { TryWorkshopType } from '../../../resources/workshops';

const getNoWorkshopInfoByType = (
  type: TryWorkshopType
): {
  redirectUrl: string;
  buttonLabel: string;
  headerText: string;
} => {
  const defaults = {
    buttonLabel: 'Browse Classes',
    headerText: "Something went wrong! Click here to view all of Coa's classes!",
    redirectUrl: `${marketingSiteBaseUrl}/classes`,
  };
  switch (type) {
    case 'pushups':
      return {
        ...defaults,
        headerText:
          'It looks like there are no upcoming emotional pushups at this time. Check out our other available classes below.',
      };
    case 'q_and_a':
      return {
        ...defaults,
        headerText: "Click here to view all of Coa's other classes!",
      };
    default:
      return { ...defaults };
  }
};

export function TryWorkshopNoUpcomingBtn({ type }: { type: TryWorkshopType }) {
  const { headerText, redirectUrl, buttonLabel } = getNoWorkshopInfoByType(type);
  return (
    <Box mb={8}>
      <Text fontSize="lg" mb={4} textAlign="center">
        {headerText}
      </Text>
      <Button
        href={redirectUrl}
        as="a"
        rightIcon={<ArrowRightIcon />}
        variant="primary"
        width={{ base: '100%', md: '100%' }}
        data-cy={`${type}-empty-state-cta`}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
}
