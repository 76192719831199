import {
  Box,
  Button,
  ButtonProps,
  Center,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { pickSharedLayoutProps } from '../../lib/chakra-ui';
import { ColorScheme } from '../../themes/coaTheme';
import { ChevronDownIcon } from '../Icons';
import { EventListing, EventListingProps } from '../molecules/EventListing';

type EventListingOptionProps = EventListingProps &
  Pick<MenuItemProps, 'disabled' | 'value'> & {
    selected?: boolean;
  };

export type EventListingMenuEntriesProps = {
  active: EventListingProps;
  options: EventListingOptionProps[];
};

export type EventListingMenuProps = MenuButtonProps &
  ButtonProps & {
    colorScheme?: ColorScheme;
    isPendingNavigation?: boolean;
  } & (
    | ({ isLoading?: false } & EventListingMenuEntriesProps)
    | ({ isLoading: true } & Partial<EventListingMenuEntriesProps>)
  );

export const EventListingMenu = ({
  active,
  options,
  isLoading,
  isPendingNavigation,
  ...rest
}: EventListingMenuProps) => {
  if (isLoading) {
    return (
      <Skeleton
        // Height chosen by eyeballing common usage.
        height="78px"
        borderRadius="md"
        {...pickSharedLayoutProps<ButtonProps>(rest)}
      />
    );
  }

  return (
    <>
      <Menu
        /*
         * We position the pop-up as "fixed" rather than "absolute"
         * to ensure it does not interfere with the scroll positioning
         * of the outer document.
         */
        strategy="fixed"
      >
        <MenuButton
          isDisabled={isPendingNavigation}
          fontWeight={undefined}
          height="auto"
          color="brand.charcoal"
          bg="white"
          py={4}
          px={4}
          borderRadius="md"
          {...(options.length > 0
            ? {
                as: Button,
                rightIcon: <ChevronDownIcon />,
              }
            : {
                as: Box,
              })}
          {...rest}
        >
          <EventListing {...active} />
          {isPendingNavigation ? (
            <Center position="absolute" top={0} left={0} height="100%" width="100%">
              <Spinner />
            </Center>
          ) : null}
        </MenuButton>
        {options.length > 0 ? (
          <MenuList width="100%">
            {options.map((option, i) => (
              <MenuItem key={String(option.value || i)} w="stretch" disabled={option.disabled}>
                <EventListing {...option} colorScheme="gray" minWidth="290px" />
              </MenuItem>
            ))}
          </MenuList>
        ) : null}
      </Menu>
    </>
  );
};
