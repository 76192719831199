import { useMutation } from 'react-query';
import { createJsonApiAxiosClient } from '../../../lib/axios';
import { getRegistrationsApiUrl } from './apiPaths';
import { PostRegistration } from './types';

/*
 * POST /v1/registration
 */
const generateCreateRegistrationMutation = () => {
  const client = createJsonApiAxiosClient({ auth: true });
  const path = getRegistrationsApiUrl.create();

  const fn = async (body: PostRegistration.Request['body']) => {
    const { data } = await client.post<PostRegistration.Response>(path, body);
    return data;
  };

  return { path, fn };
};

export const useCreateRegistrationMutation = () => {
  const { path: postQueryKey, fn } = generateCreateRegistrationMutation();

  return useMutation<
    PostRegistration.Response,
    PostRegistration.Error,
    PostRegistration.Request['body']
  >(postQueryKey, {
    mutationFn: (body: PostRegistration.Request['body']) => fn(body),
    onError: (err) => {
      const { password, base } = err.response.data.errors;
      throw new Error(password || base);
    },
  });
};
