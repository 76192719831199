import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router';
import { GlobalAnalyticsEventName, GlobalAnalyticsEventNameToEventBody } from './clients/types';
import { createSafeAnalyticsClient, SafeAnalyticsClient } from './SafeAnalytics';

type AnalyticsContextParams = {
  safeAnalyticsClient: SafeAnalyticsClient<
    GlobalAnalyticsEventName,
    GlobalAnalyticsEventNameToEventBody
  >;
};

const AnalyticsContext = createContext<AnalyticsContextParams>({} as AnalyticsContextParams);

export const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname: currentAppPath } = useLocation();
  const getBaseAttrs = () => ({
    currentAppPath,
  });
  const safeAnalyticsClient = createSafeAnalyticsClient<
    GlobalAnalyticsEventName,
    GlobalAnalyticsEventNameToEventBody
  >({ getBaseAttrs });
  return (
    <AnalyticsContext.Provider value={{ safeAnalyticsClient }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
