import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Container,
  Flex,
  Heading,
  HeadingProps,
  Tab,
  TabList,
  Tabs,
  TabsProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { TeamAccessKind } from '@coa/api/controllers/v1/teams/types';
import { useBackFn } from '@coa/react-utils';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import {
  generatePath,
  matchPath,
  Redirect,
  Route,
  RouteChildrenProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowLeftIcon, EditIcon } from '../../../components/Icons';
import {
  Team,
  useGetTeamMembersQuery,
  useGetTeamQuery,
  useGetTeamTeamsQuery,
  useGetTeamWorkshopsQuery,
} from '../../../resources/teams';
import { CreateSubTeamButton, editableTeamAccessKindValues } from './components/CreateTeamModal';
import { EditTeamAccessKindButton } from './components/EditTeamAccessKindModal';
import { EditTeamNameButton } from './components/EditTeamNameModal';
import { TeamMembersTable } from './components/TeamMembersTable';
import { TeamsTable } from './components/TeamsTable';
import { TeamWorkshopsTable } from './components/TeamWorkshopsTable';
import { useTeamsPathnameBase, useTeamsRole } from './utils';

type AdminTeamViewProps = RouteChildrenProps<{ id: string }>;

const getNavEntries = ({
  isTopLevel,
  pathnameBase,
  hasWorkshops,
  hasMembers,
  pathname,
  accessKind,
}: {
  isTopLevel: boolean;
  pathnameBase: string;
  hasWorkshops: boolean;
  hasMembers: boolean;
  pathname: string;
  accessKind: TeamAccessKind;
}) => {
  const accessKindIsMembership = accessKind === 'membership';
  const accessKindIsALaCarte = accessKind === 'a_la_carte';
  const accessKindIncludesMembership = accessKind.includes('membership');

  const isClassesTabDisabled = accessKindIsMembership;
  const isTeammatesTabDisabled = accessKindIsALaCarte && !hasWorkshops;

  const doesALaCarteTeamNeedMembers = accessKindIsALaCarte && hasWorkshops && !hasMembers;
  return _.filter([
    {
      path: `/${pathnameBase}/teams/:id`,
      children: <>Classes</>,
      isDisabled: isClassesTabDisabled,
    },
    {
      path: `/${pathnameBase}/teams/:id/members`,
      children:
        doesALaCarteTeamNeedMembers || accessKindIsMembership ? (
          Boolean(matchPath(pathname, { path: '*/members' })) ? (
            <>Teammates</>
          ) : (
            <Tooltip
              label="This team has no teammates. Invite teammates to continue."
              defaultIsOpen
              placement="bottom-start"
              hasArrow
            >
              Teammates
            </Tooltip>
          )
        ) : !accessKindIncludesMembership ? (
          <Tooltip
            label="A team must be assigned a class before you can invite teammates."
            isDisabled={hasWorkshops}
            placement="bottom-start"
            hasArrow
          >
            Teammates
          </Tooltip>
        ) : (
          <>Teammates</>
        ),
      isDisabled: isTeammatesTabDisabled,
    },
    isTopLevel && null,
    /*
     * Currently, we're not enabling the sub-teams feature as
     * it's not yet used, but when we need it, it's below!
     */
    // isTopLevel && {
    //   path: `/${pathnameBase}/teams/:id/teams`,
    //   children: <>Teams</>,
    // },
  ]);
};

export const AdminTeamNav = ({ id, ...rest }: { id: string } & Omit<TabsProps, 'children'>) => {
  const { pathname } = useLocation();
  const { pathnameBase } = useTeamsPathnameBase();

  const getTeamQuery = useGetTeamQuery({ id });
  const getTeamWorkshopsQuery = useGetTeamWorkshopsQuery({ id });
  const getTeamMembersQuery = useGetTeamMembersQuery({ id });

  if (getTeamQuery.isLoading || getTeamWorkshopsQuery.isLoading || getTeamMembersQuery.isLoading)
    return null;

  const {
    data: { attributes: { parentTeamId = null, accessKind = 'a_la_carte' } } = { attributes: {} },
  } = getTeamQuery.data || {};
  const { data: workshops } = getTeamWorkshopsQuery.data;
  const { data: members = [] } = getTeamMembersQuery.data;

  const isTopLevel = !parentTeamId;
  const hasWorkshops = workshops.length > 0;
  const hasMembers = members.length > 0;
  const navEntries = getNavEntries({
    isTopLevel,
    pathnameBase,
    hasWorkshops,
    hasMembers,
    pathname,
    accessKind,
  });

  return (
    <Tabs
      index={_.findIndex(navEntries, ({ path }) =>
        Boolean(matchPath(pathname, { path, exact: true }))
      )}
      {...rest}
    >
      <TabList>
        {navEntries.map(({ path, children, isDisabled }) => (
          <Tab key={path} as={RouterLink} to={generatePath(path, { id })} isDisabled={isDisabled}>
            {children}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

const ParentTeamBreadcrumb = ({ id, ...rest }: ButtonProps & Pick<Team, 'id'>) => {
  const teamQuery = useGetTeamQuery({ id });
  const { pathnameBase } = useTeamsPathnameBase();

  const { data: { attributes: { title = '' } } = { attributes: {} } } = teamQuery.data || {};

  const { backFn } = useBackFn({ path: `/${pathnameBase}/teams/${id}` });
  return (
    <Button onClick={backFn} {...rest}>
      {title}
    </Button>
  );
};

export const TeamsBreadcrumbs = ({ id, ...rest }: ButtonGroupProps & { id: string }) => {
  const teamQuery = useGetTeamQuery({ id });
  const { isCoaAdmin } = useTeamsRole({ id });

  const { pathnameBase } = useTeamsPathnameBase();
  const { backFn: backToOrgsFn } = useBackFn({ path: `/${pathnameBase}/teams`, exact: true });

  const { data: { attributes: { parentTeamId = null } } = { attributes: {} } } =
    teamQuery.data || {};

  if (teamQuery.isLoading) return null;

  return (
    <ButtonGroup {...rest}>
      {isCoaAdmin ? (
        <Button variant="link" leftIcon={<ArrowLeftIcon />} onClick={backToOrgsFn}>
          Organizations
        </Button>
      ) : null}
      {parentTeamId ? (
        <ParentTeamBreadcrumb variant="link" leftIcon={<ArrowLeftIcon />} id={parentTeamId} />
      ) : null}
    </ButtonGroup>
  );
};

export const AdminTeamHeading = ({ id, children, ...rest }: HeadingProps & { id: string }) => {
  const history = useHistory();
  const teamQuery = useGetTeamQuery({ id });
  if (teamQuery.isLoading) return null;
  const { data: { attributes: { title = null, accessKind = 'a_la_carte' } } = { attributes: {} } } =
    teamQuery.data || {};

  return (
    <Flex w="full" justifyContent="space-between">
      <Heading {...rest}>
        <span data-cy="team-heading">{title}</span>
        <EditTeamNameButton id={id} icon={<EditIcon />} aria-label="Edit team name" ml={4} />
      </Heading>
      <VStack>
        {accessKind === 'a_la_carte' ? (
          <Button
            variant="secondary"
            onClick={() => history.push(`/team-membership/${id}/payment`)}
            ml="2"
          >
            Purchase Membership
          </Button>
        ) : null}
        <Flex alignItems="center">
          <Text
            textAlign="center"
            display="inline"
            size="md"
            fontWeight="bold"
            mr="2"
            verticalAlign="middle"
          >
            Access Kind:
          </Text>
          <Text textAlign="center" size="sm">
            {_.startCase(accessKind)}
          </Text>
          {editableTeamAccessKindValues.includes(accessKind) && (
            <EditTeamAccessKindButton
              id={id}
              icon={<EditIcon />}
              aria-label="Edit team access kind"
              ml={4}
            />
          )}
        </Flex>
      </VStack>
    </Flex>
  );
};

export const SubTeamsTable = ({ id }: Pick<Team, 'id'>) => {
  const { data, isLoading } = useGetTeamTeamsQuery({ id });
  const { pathnameBase } = useTeamsPathnameBase();
  if (isLoading) return null;

  const { data: teams = [] } = data;

  const handleGetTo = (subTeamId: string, subTeamAccessKind: TeamAccessKind) => {
    let url = `/${pathnameBase}/teams/${subTeamId}`;
    if (subTeamAccessKind === 'membership') {
      url += '/members';
    }
    return url;
  };
  return <TeamsTable getTo={handleGetTo} teams={teams} />;
};

export const AdminTeamView = ({ match }: AdminTeamViewProps): ReactElement => {
  const { id } = match.params;
  return (
    <Container maxW="container.xl">
      <Flex alignItems="flex-start" flexDirection="column">
        <TeamsBreadcrumbs id={id} mb={8} />
        <AdminTeamHeading id={id} mb={4} />
        <AdminTeamNav id={id} mb={4} />
      </Flex>
      <Switch>
        <Route
          path="/admin-dash/teams/:id/classes"
          render={(props) => <Redirect to={`/admin-dash/teams/${props.match.params.id}`} />}
        />
        <Route path="/admin-dash/teams/:id/members">
          <TeamMembersTable id={id} />
        </Route>
        <Route path="/admin-dash/teams/:id/teams">
          <ButtonGroup justifyContent="flex-end" width="stretch">
            <CreateSubTeamButton id={id} variant="secondary">
              Add Team
            </CreateSubTeamButton>
          </ButtonGroup>
          <SubTeamsTable id={id} />
        </Route>
        <Route path="/admin-dash/teams/:id">
          <TeamWorkshopsTable id={id} />
        </Route>
      </Switch>
    </Container>
  );
};
