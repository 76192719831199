import { Box, Button, Flex, FormControl, Link, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MailIcon } from './Icons';
import SimpleTextField from './SimpleTextField';

function ForgotPassword({ resetPassword, updateAttr, email, sentReset }) {
  return (
    <Flex width="full" align="center" justifyContent="center" direction="column">
      <Box p={2} maxWidth="500px">
        <Box textAlign="center">
          {sentReset ? (
            <Box my={4}>
              <MailIcon color="orange" w="64px" h="64px" my={2} />
              <Text>
                We’ve sent you an email with a link to reset your password. Contact us if you’re
                still having trouble.
              </Text>
            </Box>
          ) : (
            <Text my={4}>
              Enter the email address associated with your account and we'll send you a link to
              reset your password.
            </Text>
          )}

          {!sentReset && (
            <form onSubmit={resetPassword}>
              <FormControl noValidate>
                <SimpleTextField
                  type="email"
                  name="email"
                  label="Your Email"
                  updateAttr={updateAttr}
                  value={email}
                  autoFocus
                />
                <Button
                  type="submit"
                  backgroundColor="green.900"
                  color="white"
                  variant="solid"
                  width="full"
                  my={4}
                >
                  Continue
                </Button>
                <Stack align="center">
                  <Text>Don't have an account?</Text>
                  <Link color="green.900" as={RouterLink} to="/join" variant="body2">
                    Join Coa
                  </Link>
                </Stack>
              </FormControl>
            </form>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

ForgotPassword.propTypes = {
  email: PropTypes.string.isRequired,
  updateAttr: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  sentReset: PropTypes.bool.isRequired,
};

export default ForgotPassword;
