import { AnyObject } from '@coa/types';
import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { jsonApiFormatter, TJsonApiBody } from '../../json-api';
import { addTransformResponse } from './util/addTransform';

export const generateTransformJsonApiResponse = ({ paginate }) => (
  data: TJsonApiBody & { meta?: AnyObject; errors?: AnyObject }
) => {
  /*
   * =============
   * NB: The case logic here has grown extremely unwieldy. PLEASE DO NOT
   * ADD ANY ADDITIONAL CASE LOGIC WITHOUT ALSO ADDING TESTS AND / OR
   * REFACTORING FOR SIMPLICITY. -SE
   * =============
   */

  if (!_.isObject(data)) return data;
  // There's no need to deserialize if... there's nothing to deserialize.
  if (_.isEqual(data, { data: null })) return null;

  // by convention, we dont serialize errors,
  // so we don't need to deserialize either.
  if (data.errors) {
    return data;
  }

  /*
   *  In the case where multiple data are returned by our backend,
   *  we add special cases where we wish to extract metadata.
   */
  if (_.isArray(data.data)) {
    const page = jsonApiFormatter.deserialize(data);
    if (!paginate) return page;
    const { meta: _meta } = data;
    return {
      ...(_meta ? { meta: _meta } : {}),
      page,
    };
  }

  const { meta } = data;
  return {
    ...(meta ? { meta } : {}),
    ...jsonApiFormatter.deserialize(data),
  };
};

/**
 * An interceptor that deserializes responses conforming
 * to the JSON-API spec to be more ergonomic for frontend
 * consumption.
 *
 * In a future state, we should be able to manage our
 * case massage here as well, but for the sake of velocity,
 * we keep these responsibilities separate for now.
 */

type GenerateJsonApiInterceptorOps = {
  paginate?: boolean;
};

export const generateJsonApiInterceptor = ({ paginate }: GenerateJsonApiInterceptorOps = {}) => (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const transformJsonApiResponse = generateTransformJsonApiResponse({ paginate });
  addTransformResponse(config, transformJsonApiResponse);
  return config;
};
