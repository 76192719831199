import _ from 'lodash';
import {
  ENROLLEES_ADD_TO_VIEW,
  ENROLLEES_APPROVE_SUCCESS,
  ENROLLEES_LIST_FAILURE,
  ENROLLEES_LIST_REQUEST,
  ENROLLEES_LIST_SUCCESS,
  ENROLLEES_REJECT_SUCCESS,
  ENROLLEES_REMOVE_SUCCESS,
  ENROLLEES_REMOVE_WITH_REFUND_SUCCESS,
  ENROLLEES_REQUEST_INFO_SUCCESS,
  ENROLLEES_UPDATE_SUCCESS,
} from '../../actions/admin/enrollees';

const DEFAULT_STATE = {
  gettingEnrollees: null,
  enrollees: {},
};

/*
 * We have to perform some basic JSON-API deserialization here. In
 * particular, we need to grab an enrollment's corresponding
 * `member` attribute with a member's profile data.
 *
 * This comes for free in our react-query world where it is
 * embedded into the axios client intercepters that we've
 * configured. We've chosen not to refactor the admin enrollees
 * UI for the time being as we're not sure what will be needed
 * in the membership world, so for now we live with this
 * discomfort.
 */
const transformEnrolleeDataWithIncludedRelationship = (data, included) => ({
  ...data,
  attributes: {
    ...data.attributes,
    member: included.find(
      (entry) => entry.id === data.relationships.member.data.id && entry.type === 'member'
    ),
  },
});

export function enrollees(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ENROLLEES_ADD_TO_VIEW: {
      const newEnrollee = action.payload;
      const result = {
        ...state.enrollees,
        [newEnrollee.id]: newEnrollee,
      };
      return {
        ...state,
        enrollees: result,
      };
    }
    case ENROLLEES_LIST_REQUEST: {
      return { ...state, enrollees: {}, gettingEnrollees: true };
    }
    case ENROLLEES_LIST_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      const result = _.reduce(
        _.keyBy(data, 'id'),
        (acc, value, key) => ({
          ...acc,
          [key]: transformEnrolleeDataWithIncludedRelationship(value, included),
        }),
        {}
      );
      return { ...state, enrollees: result, gettingEnrollees: false };
    }
    case ENROLLEES_LIST_FAILURE: {
      return { ...state, gettingEnrollees: false };
    }
    case ENROLLEES_REMOVE_SUCCESS:
    case ENROLLEES_REMOVE_WITH_REFUND_SUCCESS:
    case ENROLLEES_REQUEST_INFO_SUCCESS:
    case ENROLLEES_REJECT_SUCCESS:
    case ENROLLEES_UPDATE_SUCCESS:
    case ENROLLEES_APPROVE_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      const updatedEnrollees = {
        ...state.enrollees,
        [data.id]: transformEnrolleeDataWithIncludedRelationship(data, included),
      };
      return { ...state, enrollees: updatedEnrollees };
    }
    default:
      return state;
  }
}
