import { v1ApiPath } from '../../../../lib/api';

export const accountShowApiPath = v1ApiPath('account');
export const accountUpdateApiPath = v1ApiPath('account');
export const accountDestroyApiPath = v1ApiPath('account');

export const getAccountApiUrl = {
  show: () => accountShowApiPath,
  update: () => accountUpdateApiPath,
  destroy: () => accountDestroyApiPath,
};
