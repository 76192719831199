import { clientCase } from '@coa/stdlib/string';

export * from './getFieldValidationFn';
export * from './getTrackExerciseEventParams';

/*
 * Our goal is to eliminate all casing logic from the application layer,
 * instead having all of that transform logic at the query layer.
 *
 * TODO: Use deepTransform util at the query layer to transform values
 * of `formQuestionSequence` and `followupQuestions` keys to correct
 * case, eliminating the need to format question ids.
 */
export const formatQuestionId = clientCase;
