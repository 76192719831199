import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import * as therapyMatchActions from '../../actions/admin/therapyMatches';
import { STATUS_COLORS } from '../../utils/StatusLabel';
import { EditIcon, SearchIcon } from '../Icons';
import Loading from '../Loading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';

const TABS = [
  { path: '', label: 'All' },
  { path: 'brand_new', label: 'New' },
  { path: 'requiring_admin_action', label: 'Requiring Action' },
  { path: 'in_session', label: 'In Session' },
  { path: 'lost', label: 'Lost' },
];

const AdminTherapyMatches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gettingTherapyMatches, therapyMatches, includedClients, therapyMatchPages } = useSelector(
    (state) => state.admin.therapyMatches
  );

  const urlParams = new URLSearchParams(window.location.search);
  const defaultPage = parseInt(urlParams.get('currentPage') || 1);
  const defaultTabIndex = parseInt(urlParams.get('tabIndex') || 0);
  const defaultSearchQuery = urlParams.get('searchQuery') || '';

  const [currentPage, setCurrentPage] = React.useState(defaultPage);
  const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);
  const [searchQuery, setSearchQuery] = React.useState(defaultSearchQuery);

  const setTabIndexAndPage = (index) => {
    setTabIndex(index);
    setCurrentPage(1);
  };

  const therapyMatchPageNumbers = [];
  let i = 1;
  while (i <= therapyMatchPages) {
    therapyMatchPageNumbers.push(i);
    i += 1;
  }

  useEffect(() => {
    dispatch(therapyMatchActions.list(TABS[tabIndex].path, currentPage, searchQuery));
    history.replace(
      `/admin-dash/therapy-matches?tabIndex=${tabIndex}&currentPage=${currentPage}&searchQuery=${searchQuery}`
    );
  }, [dispatch, currentPage, tabIndex, searchQuery, history]);
  return (
    <Box width="stretch" maxWidth="80%">
      <Flex my={6} alignItems="center" justify="space-between" width="stretch">
        <Heading>Therapy Matching</Heading>
        <Tabs
          defaultIndex={defaultTabIndex}
          variant="soft-rounded"
          colorScheme="green"
          onChange={setTabIndexAndPage}
        >
          <TabList>
            {TABS.map((tab) => (
              <Tab key={tab.label}>{tab.label}</Tab>
            ))}
          </TabList>
        </Tabs>
        <Formik
          initialValues={{ searchQuery: defaultSearchQuery }}
          onSubmit={(values) => setSearchQuery(values.searchQuery)}
        >
          {() => (
            <Form>
              <Field name="searchQuery">
                {({ field }) => (
                  <FormControl>
                    <InputGroup width="100">
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        {...field}
                        focusBorderColor="evergreen.500"
                        type="text"
                        placeholder="Search..."
                      />
                    </InputGroup>
                  </FormControl>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </Flex>
      <Center>
        {gettingTherapyMatches ? (
          <Loading />
        ) : (
          <Box width="stretch" display={{ sm: 'none', md: 'block' }}>
            <Table aria-label="Therapists">
              <TableHead>
                <TableRow>
                  <TableHeader>Client</TableHeader>
                  <TableHeader>Email</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(therapyMatches).map((match) => {
                  const client = includedClients[match.relationships.client.data.id];
                  return (
                    <TableRow key={match.id}>
                      <TableCell>
                        <Stack direction="row" alignItems="center">
                          <Avatar name={client.attributes.name} src={client.attributes.image_url} />
                          <Heading fontSize="md">{client.attributes.name}</Heading>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Text>{client.attributes.email}</Text>
                      </TableCell>
                      <TableCell>
                        <Badge colorScheme={STATUS_COLORS[match.attributes.status]}>
                          {match.attributes.status.replace('_', ' ')}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Flex justifyContent="flex-end">
                          <IconButton
                            as={RouterLink}
                            to={`/admin-dash/therapy-matches/${match.id}`}
                            borderRadius="50%"
                            color="gray"
                            bgColor="green.50"
                            variant="outline"
                            icon={<EditIcon />}
                          />
                        </Flex>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Center>
      <ButtonGroup size="sm" isAttached p={4}>
        {therapyMatchPageNumbers.map((index) => (
          <Button
            key={index}
            colorScheme="warm"
            disabled={currentPage === index}
            onClick={() => setCurrentPage(index)}
          >
            {index}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default AdminTherapyMatches;
