import { Flex } from '@chakra-ui/react';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import React from 'react';
import {
  createGetIntroWorkshopsPlaceholderData,
  useGetWorkshopsQuery,
  useIntroWorkshopQuery,
} from '../../../../resources/workshops';
import { ClassCardGrid } from '../../components/ClassCardGrid';
import { ClassesFilters } from '../../components/ClassesFilters';
import ClassesFiltersContextProvider, {
  useClassesFiltersContext,
} from '../../components/ClassesFilters/ClassesFiltersContextProvider';
import { ClassesFiltersEmptyState } from '../../components/ClassesFilters/FilterComponents';

const placeholderData = createGetIntroWorkshopsPlaceholderData({ length: 4 });

const FilteredIntrosList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const { getWorkshopFilter } = useClassesFiltersContext();
  const queryParams = getWorkshopFilter();
  const {
    data: intros,
    isLoading: isIntrosLoading,
    isFetched: isIntrosFetched,
    isPlaceholderData,
  } = useGetWorkshopsQuery(queryParams, {
    placeholderData,
  });

  const isLoading = isIntrosLoading || isPlaceholderData || parentIsLoading;
  if (isIntrosFetched && intros.length === 0) {
    return <ClassesFiltersEmptyState />;
  }

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={intros}
    />
  );
};

const UnfilteredIntrosList = ({
  parentIsLoading,
  hasActiveSubscription,
}: {
  hasActiveSubscription: boolean;
  parentIsLoading: boolean;
}) => {
  const introsQuery = useIntroWorkshopQuery({}, { placeholderData });
  const { data: intros } = introsQuery;
  const isLoading = introsQuery.isLoading || introsQuery.isPlaceholderData || parentIsLoading;

  return (
    <ClassCardGrid
      hasActiveSubscription={hasActiveSubscription}
      isLoading={isLoading}
      classes={intros}
    />
  );
};

const IntrosListViewInner = () => {
  const { hasFiltersApplied } = useClassesFiltersContext();
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const props = { hasActiveSubscription, parentIsLoading: getSubscriptionsQuery.isLoading };
  return hasFiltersApplied ? (
    <FilteredIntrosList {...props} />
  ) : (
    <UnfilteredIntrosList {...props} />
  );
};

export const IntrosListView = () => (
  <ClassesFiltersContextProvider kind="intro">
    <Flex width="full" mb={4}>
      <ClassesFilters showFilterText={false} />
    </Flex>
    <IntrosListViewInner />
  </ClassesFiltersContextProvider>
);
