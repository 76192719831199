import { IsoTime } from '@coa/types';
import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export const joinTimeLabels = (...tokens: string[]) => tokens.join(' • ');

export const dateFormats = {
  proseDateLong: 'MMMM D, YYYY',
  htmlDateInput: 'YYYY-MM-DD',
  distantEditTime: 'MMM D, YYYY',
  recentEditTime: 'ddd h:mm A',
};

/*
 * A date format uil used primarily for admin purposes for showing
 * when internal data last changed.
 * > Tues 4:40 PM (if less than a week ago)
 * > Dec 8, 2021 (if more than a week ago)
 */
export function formatEditTime(time: IsoTime) {
  const dayjsTime = dayjs(time);
  const now = dayjs();
  const diff = dayjsTime.diff(now, 'day');
  // Rough heuristic that equates recency with "in the past week"
  const format = diff >= -6 ? dateFormats.recentEditTime : dateFormats.distantEditTime;
  const result = dayjs(time).format(format);
  return result;
}

/*
 * A date format util intended to be used for event start
 * times in presentational settings (i.e. data-cards, etc.)
 * > Dec 8 • 9:00am EST
 */
export function formatOneTimeEventTimeLabel(startsAt: IsoTime) {
  return dayjs(startsAt).format(joinTimeLabels('MMM D', 'h:mma z'));
}

/**
 * Prints a message
 * @param startsAt the message that will be printed in following format
 * > Wed Nov 16 • 9:00am EST
 */

export function formatOneTimeEventDateTimeLabel(startsAt: IsoTime) {
  return dayjs(startsAt).format(joinTimeLabels('ddd MMM D', 'h:mma z'));
}

/*
 * A date format util intended to be used for recurring event start
 * times in presentational settings (i.e. data-cards, etc.),
 * possibly with a prefix.
 * > Wednesdays | Starting Dec 8 • 9:00am EST
 */
export function formatPossiblyRecurringEventTimeLabel(startsAts: IsoTime[], prefix?: string) {
  const rawTimeLabel = formatOneTimeEventTimeLabel(startsAts[0]);
  const possiblyRecurringTimeLabel =
    startsAts.length > 1 ? `Starting ${rawTimeLabel}` : rawTimeLabel;
  return joinTimeLabels(..._.filter([prefix, possiblyRecurringTimeLabel]));
}

/*
 * A date format util intended to be used for short dates
 * in prose.
 * > "We'll see you in class on 11/2!"
 */
export function formatProseDateShort(isoDateString: IsoTime) {
  return dayjs(isoDateString).format('M/D');
}

/*
 * A date format util intended to be used for short dates
 * in prose.
 * > "We'll see you in class on 11/2 at 8:00pm!"
 */
export function formatProseStartTimeShort(isoDateString: IsoTime) {
  return dayjs(isoDateString).format('M/D [at] h:mma');
}

/*
 * A date format util intended to be used for long dates
 * in prose. Particularly helpful for birthdays.
 * > "October 17, 1989"
 */
export function formatProseDateLong(isoDateString: IsoTime) {
  return dayjs(isoDateString).format(dateFormats.proseDateLong);
}

/*
 * A date format util intended to transform an ISO-formatted
 * date into a format respected by the browser's native
 * form field: <input type="date" />
 */
export function formatHtmlDateInputValue(isoDateString: IsoTime) {
  return dayjs(isoDateString).format(dateFormats.htmlDateInput);
}
