import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import Background from '../images/background.png';

const TooYoungForTherapyMatching = () => (
  <Flex width="stretch" maxW={{ md: '80%' }} minHeight={{ sm: '50rem', md: 'auto' }}>
    <Box
      m={4}
      p={4}
      width="stretch"
      flexGrow="1"
      backgroundImage={`url(${Background})`}
      bgSize="cover"
      display={{ sm: 'none', md: 'grid' }}
    />
    <Box flexGrow="2" m={4} p={4}>
      <Heading>Therapy</Heading>
      <Box
        height={200}
        width="stretch"
        flexGrow="1"
        backgroundImage={`url(${Background})`}
        bgSize="cover"
        display={{ sm: 'block', md: 'none' }}
      />
      <Text fontSize="2xl" mt={2}>
        Thanks for reaching out!
      </Text>
      <Text my={4}>
        At this time, Coa is only able to provide therapy matches for adults (18 and over). For
        those looking to connect with a therapist who works with people under the age of 18 check
        out The Bay Area Center for Adolescents.
      </Text>
      <Button
        as={Link}
        target="_blank"
        colorScheme="evergreen"
        href="https://www.bayareaadolescent.com/"
      >
        Learn More
      </Button>
    </Box>
  </Flex>
);

export default TooYoungForTherapyMatching;
