import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { IsoTime } from '@coa/types';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  exerciseStepStatus,
  GetWorkshopExerciseSteps,
  useWorkshopExerciseStepsQuery,
} from '../resources/exercises';
import { EditIcon, FileTextIcon, LockIcon } from './Icons';

type WorkshopExerciseCtaContentsProps = { workshopId: string; exerciseId: string } & (
  | {
      epoch: 'past' | 'future';
      children?: React.ReactNode;
    }
  | {
      // Label is required if the Occurrence is upcoming because
      // we cannot infer it solely based on the time.
      epoch: 'upcoming';
      children: React.ReactNode;
    }
) &
  ButtonProps;

const WorkshopOccurrenceExerciseCtaTooltipParent = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Tooltip
    label="This exercise will be unlocked when class begins."
    /*
     * If we set the `isDisabled` prop, then Chakra will intentionally render a
     * <button disabled /> tag into the DOM, which will instruct the browser to
     * swallow mouse events - this will prevent the :hover that triggers showing
     * the tooltip from firing.
     *
     * To get around this, `Tooltip` exposes the `shouldWrapChildren` prop that
     * will wrap children in a <span>. The mouse-events will be on the <span> and
     * not the Button, meaning the mouse-events won't get swallowed.
     *
     * @see https://github.com/chakra-ui/chakra-ui/issues/500#issuecomment-916782006
     */
    shouldWrapChildren
  >
    {children}
  </Tooltip>
);

const WorkshopOccurrenceExerciseCtaContents = ({
  epoch,
  children,
  workshopId,
  exerciseId,
  isDisabled,
  ...rest
}: WorkshopExerciseCtaContentsProps) => {
  const exerciseAccessible = ['past', 'upcoming'].includes(epoch);
  const Parent =
    isDisabled || !exerciseAccessible ? WorkshopOccurrenceExerciseCtaTooltipParent : Fragment;
  return (
    <Parent>
      <Button
        {...(isDisabled || !exerciseAccessible
          ? {
              leftIcon: <LockIcon />,
              isDisabled: true,
            }
          : {
              variant: 'secondary',
              leftIcon: epoch === 'past' ? <FileTextIcon /> : <EditIcon />,
              as: RouterLink,
              to: `/classes/${workshopId}/exercises/${exerciseId}`,
            })}
        width={{ base: '100%', md: 'auto' }}
        size="lg"
        {...rest}
      >
        {children ||
          (() => {
            switch (epoch) {
              case 'past':
                return 'Review Class Exercises';
              case 'future':
                return 'Begin Class Exercises';
              default:
                throw Error('Need to provide children for an upcoming exercise.');
            }
          })()}
      </Button>
    </Parent>
  );
};

type UpcomingWorkshopOccurrenceExerciseCtaProps = {
  workshopId: string;
  exerciseId: string;
} & ButtonProps;

const getCtaProperties = (exerciseSteps: GetWorkshopExerciseSteps.Response['data'] = []) => {
  const exerciseStepStatuses = exerciseSteps.map(({ attributes }) => attributes.status);
  const uniqueStatuses = _.uniq(exerciseStepStatuses);
  if (uniqueStatuses.includes(exerciseStepStatus.complete)) {
    // If at least one step has been completed but others
    // are available to complete...
    if (uniqueStatuses.includes(exerciseStepStatus.active)) {
      return {
        label: 'Continue Class Exercise',
      };
    }
    // Otherwise all steps have been completed.
    return {
      label: 'Review Class Exercise',
    };
  }
  // Otherwise the exercise has not yet been started.
  return {
    isDisabled: exerciseStepStatuses[0] === 'locked',
    label: 'Begin Class Exercise',
  };
};

const UpcomingWorkshopOccurrenceExerciseCta = ({
  workshopId,
  exerciseId,
  ...rest
}: UpcomingWorkshopOccurrenceExerciseCtaProps) => {
  const exerciseStepsQuery = useWorkshopExerciseStepsQuery({
    id: workshopId,
    exerciseId,
  });

  if (!exerciseStepsQuery.data) {
    return null;
  }
  // TODO: Need better loading state.
  const { label, isDisabled } = getCtaProperties(exerciseStepsQuery.data.data);

  return (
    <WorkshopOccurrenceExerciseCtaContents
      epoch="upcoming"
      workshopId={workshopId}
      exerciseId={exerciseId}
      isDisabled={isDisabled}
      {...rest}
    >
      {label}
    </WorkshopOccurrenceExerciseCtaContents>
  );
};

export type WorkshopOccurrenceExerciseCtaProps = ButtonProps & {
  workshopId: string;
  exerciseId: string;
  isOnlyOccurrence?: boolean;
} & (
    | {
        // We only need to know the startsAt time if the
        // Occurrence is not upcoming.
        isUpcoming: true;
        occurrenceStartsAt?: IsoTime;
      }
    | { isUpcoming?: boolean; occurrenceStartsAt: IsoTime }
  );

const getIsInThePast = (startsAt: IsoTime) => dayjs(startsAt) < dayjs();

/*
 * Smart component that renders correct content
 * for an Exercise CTA depending on the corresponding
 * Workshop Occurrence. We break up the Upcoming /
 * Not-upcoming logic into separate components as
 * one requires much more query-intensive operations.
 */
export const WorkshopOccurrenceExerciseCta = ({
  workshopId,
  exerciseId,
  occurrenceStartsAt,
  isUpcoming,
  isOnlyOccurrence,
  ...rest
}: WorkshopOccurrenceExerciseCtaProps) => {
  const props = { workshopId, exerciseId, ...rest };
  const isInThePast = getIsInThePast(occurrenceStartsAt);

  /*
   * Exercise ID on Occurrence can be null or zero. Since both are present
   * in the data we check for either one here. If either is true, we do
   * not display the Exercise CTA.
   */
  if (_.isNull(exerciseId) || _.isEqual(exerciseId, 0)) {
    return null;
  }
  return isUpcoming || (isOnlyOccurrence && !isInThePast) ? (
    <UpcomingWorkshopOccurrenceExerciseCta {...props} />
  ) : (
    <WorkshopOccurrenceExerciseCtaContents epoch={isInThePast ? 'past' : 'future'} {...props} />
  );
};
