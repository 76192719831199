import { Button, FormLabel, Image, ImageProps } from '@chakra-ui/react';
import { WidgetProps } from '@rjsf/core';
import _ from 'lodash';
import React, { useRef, useState } from 'react';

const AdminCmsImageUploadWidgetPreview = ({ value, ...rest }: { value: string } & ImageProps) => (
  <Image
    src={value}
    maxH="100px"
    minH="100px"
    minW="100px"
    objectFit="contain"
    cursor="pointer"
    {...rest}
  />
);

const AdminCmsImageUploadInput = ({
  imageInputRef,
  onFileReaderLoad,
}: {
  imageInputRef: React.MutableRefObject<HTMLInputElement>;
  onFileReaderLoad: (s: string) => void;
}) => (
  <input
    hidden
    ref={imageInputRef}
    aria-label="image file"
    type="file"
    accept="image/*"
    onChange={(inputChangeEvent) => {
      // For now we're assuming that we're only uploading one file - fragile!
      const file = inputChangeEvent.currentTarget.files?.[0];
      if (file) {
        /*
         * Use a FileReader to access base64-encoded image content that
         * we can subsequently (1) use to render an <img> and (2) submit
         * to the backend in our PUT / POST
         */
        const reader = new FileReader();
        reader.onload = (fileReadEvent) => {
          const { result } = fileReadEvent.target;
          if (!_.isString(result)) throw Error('need a string');
          onFileReaderLoad(result);
        };
        reader.readAsDataURL(file);
      }
    }}
  />
);
export const AdminCmsImageUploadWidget = (props: WidgetProps) => {
  const { onChange, label, schema, id, value } = props;
  const [imagePreviewData, setImagePreviewData] = useState(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const handleFileReaderLoad = (base64FileContents: string) => {
    onChange(base64FileContents);
    setImagePreviewData(base64FileContents);
  };
  return (
    <>
      <FormLabel>{schema.title || label || id.replace(/^root_/, '')}</FormLabel>
      <AdminCmsImageUploadInput
        onFileReaderLoad={handleFileReaderLoad}
        imageInputRef={imageInputRef}
      />
      {imagePreviewData || value ? (
        <AdminCmsImageUploadWidgetPreview
          value={imagePreviewData || value}
          cursor="pointer"
          onClick={() => {
            imageInputRef.current?.click();
          }}
        />
      ) : (
        <Button onClick={() => imageInputRef.current?.click()} type="button" variant="secondary">
          Select Image
        </Button>
      )}
    </>
  );
};
