import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import HeaderImage from '../../../components/HeaderImage';

export function AuthLayout({ children, ...rest }: FlexProps) {
  return (
    <Flex direction="column" width="stretch" maxW={360} p={4} {...rest}>
      <HeaderImage />
      {children}
    </Flex>
  );
}
