export const DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST =
  'DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST';
export const DROP_IN_WORKSHOP_OCCURRENCES_LIST_SUCCESS =
  'DROP_IN_WORKSHOP_OCCURRENCES_LIST_SUCCESS';
export const DROP_IN_WORKSHOP_OCCURRENCES_LIST_FAILURE =
  'DROP_IN_WORKSHOP_OCCURRENCES_LIST_FAILURE';

export function dropInWorkshopOccurrencesList() {
  return {
    type: DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST,
    api: {
      endpoint: `workshop_occurrences/drop_ins`,
      authenticated: true,
      types: [
        DROP_IN_WORKSHOP_OCCURRENCES_LIST_REQUEST,
        DROP_IN_WORKSHOP_OCCURRENCES_LIST_SUCCESS,
        DROP_IN_WORKSHOP_OCCURRENCES_LIST_FAILURE,
      ],
      method: 'GET',
    },
  };
}
