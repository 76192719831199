import { Box, Container, Flex, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { CalendarIcon, DumbellIcon } from '../../../../components/Icons';
import { useThemedBreakpoint } from '../../../../lib/chakra-ui';
import { workshopDays, WorkshopFilter, workshopSkills } from '../../../../resources/workshops';
import { useClassesFiltersContext } from './ClassesFiltersContextProvider';
import { ClassesFiltersMobile } from './ClassesFiltersMobileView';
import {
  ClassesFiltersButtonToggle,
  ClassesFiltersClearFilterButton,
  ClassesFiltersDropdownToggleMenu,
  filterIconProps,
  getIconFromFilterLabel,
  workshopTimingsFilters,
} from './FilterComponents';

const ClassesFiltersDesktop = ({ showFilterText = true }: { showFilterText?: boolean }) => {
  const { days, timings, skills, toggleFilter } = useClassesFiltersContext();
  const [belowLarge] = useThemedBreakpoint({ below: 'lg' });
  return (
    <Container maxW="100%" p="0">
      {showFilterText ? (
        <Text fontSize="16px" textAlign="center" mb={2} mx={{ base: 6, md: 0 }}>
          Build your routine alongside our trained instructors, one class at a time. Find classes
          that fit your schedule.
        </Text>
      ) : null}
      <Box p="0">
        <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
          <Flex width="150px" />
          <Wrap justify="center" align="center">
            <WrapItem>
              {workshopTimingsFilters.map(({ title, ...rest }, index: number) => (
                <ClassesFiltersButtonToggle
                  key={title}
                  isActive={timings.includes(title)}
                  toggleActive={toggleFilter('timings', title)}
                  title={title}
                  mr={index < workshopTimingsFilters.length - 1 ? 2 : 0}
                  icon={getIconFromFilterLabel(title)}
                  {...rest}
                />
              ))}
            </WrapItem>
            <WrapItem width={belowLarge ? 'full' : null} justifyContent="center">
              <ClassesFiltersDropdownToggleMenu
                labels={Object.values(workshopDays) as WorkshopFilter['days']}
                type="days"
                valueArr={days}
                onMenuOptionClick={toggleFilter}
                icon={<CalendarIcon {...filterIconProps} />}
                width="182px"
              />
              <ClassesFiltersDropdownToggleMenu
                labels={Object.values(workshopSkills) as WorkshopFilter['skills']}
                type="skills"
                valueArr={skills}
                onMenuOptionClick={toggleFilter}
                icon={
                  <DumbellIcon
                    width="24px"
                    height="24px"
                    strokeWidth={0.3}
                    transform="rotate(45deg)"
                  />
                }
                width="182px"
              />
            </WrapItem>
            <WrapItem width="150px" justifyContent="center">
              <ClassesFiltersClearFilterButton />
            </WrapItem>
          </Wrap>
        </Flex>
      </Box>
    </Container>
  );
};

export const ClassesFilters = (props) => {
  const [belowMed] = useThemedBreakpoint({ below: 'md' });
  return belowMed ? <ClassesFiltersMobile /> : <ClassesFiltersDesktop {...props} />;
};
