import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CloseButtonProps,
  Flex,
  FlexProps,
  Heading,
  HStack,
  MenuButton,
  MenuButtonProps,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalProps,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

// NOTE: this pattern is deprecated post scotty
// This feels like a way to resuse similar components that shared prop typings
// but feels like overkill
/*
 * While not specific to Chakra, it's helpful here, so we
 * leave this factory generator in the Chakra lib for now.
 */
function createBoundComponentFactory<Props>(Component: React.FC<Props>) {
  function componentFactory(displayName: string, baseProps: Props) {
    function BoundComponent(props: Props) {
      return React.createElement(Component, { ...baseProps, ...props });
    }
    BoundComponent.displayName = displayName;
    return BoundComponent;
  }
  return componentFactory;
}

export const boundChakra = {
  Avatar: createBoundComponentFactory<AvatarProps>(Avatar),
  Box: createBoundComponentFactory<BoxProps>(Box),
  Button: createBoundComponentFactory<ButtonProps>(Button),
  Flex: createBoundComponentFactory<FlexProps>(Flex),
  Heading: createBoundComponentFactory<StackProps>(Heading),
  HStack: createBoundComponentFactory<StackProps>(HStack),
  Modal: createBoundComponentFactory<ModalProps>(Modal),
  ModalBody: createBoundComponentFactory<ModalBodyProps>(ModalBody),
  ModalContent: createBoundComponentFactory<ModalContentProps>(ModalContent),
  ModalCloseButton: createBoundComponentFactory<CloseButtonProps>(ModalCloseButton),
  MenuButton: createBoundComponentFactory<MenuButtonProps>(MenuButton),
  Text: createBoundComponentFactory<TextProps>(Text),
  VStack: createBoundComponentFactory<StackProps>(VStack),
};
