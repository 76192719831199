import { serverCase } from '@coa/stdlib/string';

type NormalizeFlattenedKeypathOps = {
  transformCase?: (_str: string) => string;
};

const defaultNoramlizeFlattenedKeypathOps = {
  transformCase: (str: string) => str,
};

/*
 * Manages transformation of flattened keypaths.
 * - Ensures that all path tokens are surrounded by brackets, as
 *   this is only certain when the token is a number.
 * - Ensures taht path tokens are cased correctly.
 */
export const generateNormalizeFlattenedKeypath = (
  ops: NormalizeFlattenedKeypathOps = defaultNoramlizeFlattenedKeypathOps
) => (keyPath: string) => {
  const { transformCase } = ops;
  const result = keyPath
    // Array indicies are indicated using [], so we stick a period
    // in front so we can properly split.
    .replace(/\[/g, '.[')
    .split('.')
    .map((token, index) => {
      if (token.match(/^\[\d+\]$/)) return '[]';
      // Remove brackets on the front and back.
      const unbracketedToken = token.replace(/^\[|\]$/g, '');
      /*
       * TODO: Consider moving the case transform data outside of
       * formData transform to separate concerns.
       */
      const newToken = transformCase(unbracketedToken);
      return index === 0 ? newToken : `[${newToken}]`;
    })
    .join('');
  return result;
};

export const normalizeFlattenedKeypath = generateNormalizeFlattenedKeypath({
  transformCase: serverCase,
});
