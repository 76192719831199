export const PASSWORDS_REQUEST = 'PASSWORDS_REQUEST';
export const PASSWORDS_SUCCESS = 'PASSWORDS_SUCCESS';
export const PASSWORDS_FAILURE = 'PASSWORDS_FAILURE';

export function create(data) {
  return {
    type: PASSWORDS_REQUEST,
    api: {
      data,
      endpoint: 'passwords',
      authenticated: false,
      types: [PASSWORDS_REQUEST, PASSWORDS_SUCCESS, PASSWORDS_FAILURE],
      method: 'POST',
    },
  };
}
