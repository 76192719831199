import { Box, Container, Grid, GridItem, Heading, HeadingProps } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import { ArrowRightIcon } from '../../../components/Icons';
import { TransformUpLink } from '../../../components/molecules/TransformUpLink';
import { boundChakra } from '../../../lib/chakra-ui';
import { exerciseCmsHelperText } from './exercises/exercisesCmsContent';
import { instructorCmsHelperText } from './instructors/instructorsCmsContent';
import { mediaImageCmsHelperText } from './media/mediaImageCmsContent';
import { onDemandVideosCmsHelperText } from './onDemandVideos/onDemandVideosCmsContent';
import { pollsCmsHelperText } from './polls/pollsCmsContent';
import { workoutsCmsHelperText } from './workouts/workoutCmsContent';
import { workoutScheduleEventCmsHelperText } from './workoutScheduleEvents/workoutScheduleEventCmsContent';

const CmsGridLinkHeading = ({ children, ...rest }: HeadingProps) => (
  <Heading fontSize={{ base: 'md', md: 'lg' }} mb={2} {...rest}>
    {children} <ArrowRightIcon ml={2} />
  </Heading>
);

const CmsGridLinkText = boundChakra.Text('CmsGridLinkText', { color: 'gray.500' });

const CmsLinkGridItem = ({ path, children }: { path: string; children: React.ReactNode }) => (
  <GridItem colSpan={{ base: 3, md: 2 }} key={String(children)}>
    <TransformUpLink as={RouterLink} to={path} width="100%" height="100%">
      <Box
        backgroundColor="gray.50"
        height="100%"
        boxShadow="base"
        borderRadius="base"
        py={{ base: 6, md: 10 }}
        px={{ base: 6, md: 8 }}
      >
        {children}
      </Box>
    </TransformUpLink>
  </GridItem>
);

export const AdminCmsView = () => (
  <Container maxW="container.lg" pt={{ base: 2, md: 8 }}>
    <Heading mb={8}>Content</Heading>
    <Grid
      templateColumns="repeat(6, 1fr)"
      templateRows="repeat(1, 1fr)"
      autoRows="1fr"
      gap={{ base: 6, md: 8 }}
    >
      <CmsLinkGridItem path="/admin-dash/cms/instructors">
        <CmsGridLinkHeading>Instructors</CmsGridLinkHeading>
        <CmsGridLinkText>{instructorCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/exercises">
        <CmsGridLinkHeading>Exercises</CmsGridLinkHeading>
        <CmsGridLinkText>{exerciseCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/media-images">
        <CmsGridLinkHeading>Media Images</CmsGridLinkHeading>
        <CmsGridLinkText>{mediaImageCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/on-demand-videos">
        <CmsGridLinkHeading>On Demand Videos</CmsGridLinkHeading>
        <CmsGridLinkText>{onDemandVideosCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/polls">
        <CmsGridLinkHeading>Polls</CmsGridLinkHeading>
        <CmsGridLinkText>{pollsCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/workouts">
        <CmsGridLinkHeading>Workouts</CmsGridLinkHeading>
        <CmsGridLinkText>{workoutsCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
      <CmsLinkGridItem path="/admin-dash/cms/workout-schedule-events">
        <CmsGridLinkHeading>Workout Schedule Events</CmsGridLinkHeading>
        <CmsGridLinkText>{workoutScheduleEventCmsHelperText}</CmsGridLinkText>
      </CmsLinkGridItem>
    </Grid>
  </Container>
);
