import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { create } from '../actions/passwordResets';
import ForgotPassword from '../components/ForgotPassword';
import HeaderImage from '../components/HeaderImage';

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', sentReset: false };
    this.updateAttr = this.updateAttr.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  updateAttr(e, attr) {
    const newState = {};
    newState[attr] = e.target.value;
    this.setState(newState);
  }

  resetPassword(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(create(this.state));
    this.setState({ sentReset: true });
  }

  render() {
    const { sentReset, email } = this.state;
    return (
      <Flex direction="column" width="stretch" maxW={{ md: '35%' }}>
        <HeaderImage />
        <ForgotPassword
          email={email}
          updateAttr={this.updateAttr}
          resetPassword={this.resetPassword}
          sentReset={sentReset}
        />
      </Flex>
    );
  }
}

ForgotPasswordContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ForgotPasswordContainer);
