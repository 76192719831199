import {
  Button,
  ButtonProps,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {
  GetAccount,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from '@coa/api/controllers/v1/accounts';
import { FormikEffect } from '@coa/formik-utils';
import { useOnMount, useRouteParams } from '@coa/react-utils';
import { dateFormats } from '@coa/stdlib/string';
import * as dayjs from 'dayjs';
import { Form, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { TakeoverModalHeading } from '../../../../components/compositions/Takeover';
import { classMarketplaceAnalytics } from '../../../../lib/analytics/clients';
import {
  diversitySurveyAnswer,
  getReadableDiversitySurveyAnswer,
} from '../../../../resources/member';
import {
  useGetWorkshopEnrollmentQuery,
  useUpdateWorkshopEnrollmentMutation,
} from '../../../../resources/workshops';
import { getRouterUrl, RouterPathParams } from '../../../../routerPaths';
import { FormikQuestion } from '../components/FormQuestion';

const SubmitButton = (props: Omit<ButtonProps, 'type' | 'onClick'>) => {
  const toast = useToast();
  const { isValid } = useFormikContext();

  return (
    <Button
      type={isValid ? 'submit' : 'button'}
      onClick={
        isValid
          ? undefined
          : () => {
              toast({
                title: `Please complete all of the provided fields`,
                duration: 5000,
                isClosable: true,
                status: 'error',
              });
            }
      }
      {...props}
    />
  );
};

const transformInitialValues = (accountData: GetAccount.Response) => {
  const { birthday, ...rest } = _.pick(accountData, [
    'birthday',
    'occupation',
    'city',
    'pronouns',
    'diversity',
    'channel',
  ]);
  const utcBirthday = dayjs(birthday).utc();
  const formattedBirthday = utcBirthday.format(dateFormats.htmlDateInput);
  return {
    ...rest,
    birthday: formattedBirthday,
  };
};

const profileFormValidationSchema = Yup.object().shape({
  // TODO proper date regex
  birthday: Yup.date().required('This answer is required.'),
  occupation: Yup.string()
    .typeError('This answer is required.')
    .min(1)
    .required('This answer is required.'),
  city: Yup.string()
    .typeError('This answer is required.')
    .min(1)
    .required('This answer is required'),
  // TODO: This shouldn't be nullable
  pronouns: Yup.string().nullable(),
  diversity: Yup.array().of(Yup.string()).required('Please select an option.'),
});

export const JoinWorkshopCompleteProfileView = () => {
  const getAccountQuery = useGetAccountQuery();
  const updateAccountMutation = useUpdateAccountMutation();
  const { id: workshopId, dropInWorkshopOccurrenceId } = useRouteParams<
    RouterPathParams.Classes['DropInJoinCompleteProfile']
  >();
  const getWorkshopEnrollmentQuery = useGetWorkshopEnrollmentQuery({ id: workshopId });
  const updateEnrollmentMutation = useUpdateWorkshopEnrollmentMutation({ id: workshopId });
  const history = useHistory();

  useOnMount(() => {
    const params = { classId: workshopId, ..._.pickBy({ dropInId: dropInWorkshopOccurrenceId }) };
    classMarketplaceAnalytics.track('Viewed Complete Profile', params);
  });

  const isMutating = updateAccountMutation.isLoading || updateEnrollmentMutation.isLoading;

  const initialFormState = useMemo(() => {
    const transformedInitialValues = transformInitialValues(getAccountQuery.data);
    return {
      initialValues: transformedInitialValues,
      isInitialValid: profileFormValidationSchema.isValidSync(transformedInitialValues),
    };
  }, [getAccountQuery.data]);

  if (getAccountQuery.isLoading) return null;

  const { initialValues, isInitialValid } = initialFormState;
  return (
    <Container maxW="container.sm" w="100%" py={{ sm: 8, md: '24' }}>
      <TakeoverModalHeading mb={8}>Reserve your spot in class</TakeoverModalHeading>
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        validationSchema={profileFormValidationSchema}
        onSubmit={async (values) => {
          await updateAccountMutation.mutateAsync({
            ...values,
            completedProfile: true,
          });
          const { answers } = getWorkshopEnrollmentQuery.data || {};
          /*
           * Here we're basically "retrying" the enrollment with the existing
           * answer set. This will trigger a side-effect where the enrollment
           * will re-infer whether or not the application is complete based
           * on the completeness of the user's profile.
           *
           * It's not the cleanest, but it gets the job done.
           */
          await updateEnrollmentMutation.mutateAsync({
            answers,
          });
          history.push(getRouterUrl.classes.classJoinSuccess({ id: workshopId }));
        }}
      >
        <>
          <FormikEffect
            onValuesChange={({ values, changedKey }) => {
              classMarketplaceAnalytics.track('Answered Profile Question', {
                classId: workshopId,
                question: changedKey,
                answer: values[changedKey],
                ..._.pickBy({ dropInId: dropInWorkshopOccurrenceId }),
              });
            }}
          />
          <Form>
            <Heading size="md" mb={4}>
              About you
            </Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={8}>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormikQuestion
                  kind="date"
                  placeholder="01/02/1990"
                  name="birthday"
                  question="Birthday"
                />
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormikQuestion
                  kind="short_text"
                  name="occupation"
                  question="Occupation"
                  placeholder="Your job title"
                />
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormikQuestion
                  kind="short_text"
                  name="city"
                  question="City"
                  placeholder={'e.g. "Topeka"'}
                />
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormikQuestion
                  // TODO: Not required
                  kind="select"
                  placeholder="Not specified"
                  name="pronouns"
                  question="Your pronouns"
                  options={[
                    { label: '(he/him)' },
                    { label: '(she/her)' },
                    { label: '(they/them)' },
                    { label: 'other' },
                  ]}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FormikQuestion
                  kind="short_text"
                  placeholder={'e.g. "From a friend"'}
                  name="channel"
                  question="How did you find out about Coa?"
                />
              </GridItem>
            </Grid>
            <Heading size="md" mb={4}>
              Confidential Demographic Survey
            </Heading>
            <FormikQuestion
              kind="checkbox"
              name="diversity"
              question="Ensuring diversity of perspectives and voices in our Coa classes is extremely important to us. Please let us know if you belong to any of the following communities."
              options={Object.values(diversitySurveyAnswer).map((value) => ({
                value,
                label: getReadableDiversitySurveyAnswer(value),
              }))}
            />
            <Flex justifyContent="flex-end">
              <SubmitButton
                variant="primary"
                isLoading={isMutating}
                size="lg"
                data-cy="workshop-profile-submit"
              >
                Save and Reserve Spot
              </SubmitButton>
            </Flex>
          </Form>
        </>
      </Formik>
    </Container>
  );
};
