import { Account } from '@coa/api/controllers/v1/accounts';
import { Team } from '../../../resources/teams';
import { createSafeAnalyticsClient } from '../SafeAnalytics';

export declare namespace TeamMembershipAnalytics {
  type EventName =
    | 'View Team Membership - Company Info Form'
    | 'View Team Membership - Personal Info Form'
    | 'View Team Membership - Payment Form'
    | 'Submitted Team Membership - Company Info Form'
    | 'Submitted Team Membership - Personal Info Form'
    | 'Submitted Team Membership - Payment Form'
    | 'Team Membership - Admin Invited Team-mates'
    | 'Team Membership - Team-mate Invitation Claimed';

  type EventNameToEventBody = {
    'View Team Membership - Company Info Form': never;
    'View Team Membership - Personal Info Form': never;
    'View Team Membership - Payment Form': never;
    'Submitted Team Membership - Company Info Form': {
      // TODO: when we refactor the team membership signup flow, create team then fire off this event
      // right now we're waiting for the personal info form to be submitted, which is the point a team is created
      // We currently do not have teamId at the point of this form submission
      // teamId: string;
      teamName: Team['title'];
      numberOfSeats: Team['numberOfSeats'];
      marketingCampaign: Team['marketingCampaign'];
    };
    'Submitted Team Membership - Personal Info Form': {
      email: Account['email'];
      name: Account['name'];
      jobTitle: string;
    };
    'Submitted Team Membership - Payment Form': {
      teamId: Team['id'];
      totalPayment: number;
      email: Account['email'];
      name: Account['name'];
    };
    'Team Membership - Admin Invited Team-mates': {
      teamId: Team['id'];
      teamName: Team['title'];
      invitedTeammates: string[];
      email: Account['email'];
      name: Account['email'];
    };
    'Team Membership - Team-mate Invitation Claimed': {
      teamId: Team['id'];
      teamName: Team['title'];
      email: Account['email'];
      name: Account['name'];
    };
  };
}

export const teamMembershipAnalytics = createSafeAnalyticsClient<
  TeamMembershipAnalytics.EventName,
  TeamMembershipAnalytics.EventNameToEventBody
>();
