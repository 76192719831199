export const formatPercentInt = (percentInt: number) => `${percentInt}%`;
export const formatPercentDecimal = (percentDecimal: number) =>
  formatPercentInt(percentDecimal * 100);

/*
 * In prose, single-digit positive numbers should be written out
 * rather than numeralized.
 *
 * Bad:       "You are approved for 2 classes."
 * Good:      "You are approved for two classes."
 * Also good: "You are approved for 25 classes."
 */
const proseInts = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
export const formatProseInt = (int: number): string => proseInts[int] || String(int);
