import {
  Checkbox,
  CheckboxGroup,
  Collapse,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { Fragment, useContext } from 'react';
import {
  ExerciseStep,
  ExerciseStepQuestion,
  exerciseStepQuestionKind,
  ExerciseStepQuestionKind,
} from '../../../resources/exercises';
import { formatQuestionId, getFieldValidationFn } from '../utils';
import { WeeklyRepRecap } from './ExerciseWeeklyRepRecap';

export const FormQuestionContext = React.createContext<{
  formQuestions: ExerciseStep['formQuestions'];
}>({
  formQuestions: {},
});

type ExerciseStepQuestionComponentProps<
  Kind extends ExerciseStepQuestionKind
> = ExerciseStepQuestion<Kind> & { questionId: string };

const CheckboxFormQuestion = ({
  options,
  questionId,
}: ExerciseStepQuestionComponentProps<'checkbox'>) => {
  const { values } = useFormikContext();
  const questionValues = values?.[questionId] || [];
  return (
    <CheckboxGroup variant="coa-main" defaultValue={questionValues}>
      <VStack align="left" width="stretch" justifyContent="start">
        {options.map(({ label, subLabel, followupQuestions }) => (
          <Fragment key={label}>
            <Field
              type="checkbox"
              as={Checkbox}
              name={questionId}
              value={label}
              validate={getFieldValidationFn(exerciseStepQuestionKind.checkbox)}
              data-cy={`exercise-form-q-checkbox-${_.kebabCase(questionId)}-${_.kebabCase(label)}`}
            >
              {label}
            </Field>
            <VStack pl={6} spacing={0} align="left">
              {subLabel ? <FormLabel color="gray.500">{subLabel}</FormLabel> : null}
              {followupQuestions ? (
                <Collapse in={questionValues.includes(label)} unmountOnExit>
                  <>
                    {followupQuestions.map((rawFollowupQuestionId) => {
                      const followupQuestionId = formatQuestionId(rawFollowupQuestionId);
                      return (
                        /*
                         * This no-use-before-define would be easily resolved via
                         * a higher-order function pattern that generated this
                         * component, but at the expense of readability. As such,
                         * we just ignore.
                         */
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        <FormQuestion questionId={followupQuestionId} key={followupQuestionId} />
                      );
                    })}
                  </>
                </Collapse>
              ) : null}
            </VStack>
          </Fragment>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};

const RadioFormQuestion = ({
  options,
  questionId,
}: ExerciseStepQuestionComponentProps<'radio'>) => {
  const { values } = useFormikContext();
  const questionValue = values?.[questionId];
  return (
    <RadioGroup variant="coa-main" defaultValue={questionValue} width="stretch">
      <VStack align="left" width="stretch" justifyContent="start">
        {options.map(({ label, subLabel, followupQuestions }) => (
          <Fragment key={label}>
            <Field
              type="radio"
              as={Radio}
              name={questionId}
              value={label}
              validate={getFieldValidationFn(exerciseStepQuestionKind.radio)}
              data-cy={`exercise-form-q-checkbox-${_.kebabCase(questionId)}-${_.kebabCase(label)}`}
            >
              {label}
            </Field>
            <VStack pl={6} spacing={0} align="left">
              {subLabel ? <FormLabel color="gray.500">{subLabel}</FormLabel> : null}
              {followupQuestions && questionValue === label
                ? followupQuestions.map((rawFollowupQuestionId) => {
                    const followupQuestionId = formatQuestionId(rawFollowupQuestionId);
                    return (
                      /*
                       * This no-use-before-define would be easily resolved via
                       * a higher-order function pattern that generated this
                       * component, but at the expense of readability. As such,
                       * we just ignore.
                       */
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      <FormQuestion questionId={followupQuestionId} key={followupQuestionId} />
                    );
                  })
                : null}
            </VStack>
          </Fragment>
        ))}
      </VStack>
    </RadioGroup>
  );
};

const LongTextFormQuestion = ({
  label,
  questionId,
  placeholder,
}: ExerciseStepQuestionComponentProps<'long_text'>) => (
  <FormControl width="stretch">
    {label ? <FormLabel>{label}</FormLabel> : null}
    <Field
      name={questionId}
      type="textarea"
      as={Textarea}
      variant="coa-main"
      validate={getFieldValidationFn(exerciseStepQuestionKind.longText)}
      placeholder={placeholder}
      data-cy={`exercise-form-q-textarea-${_.kebabCase(questionId)}`}
    />
  </FormControl>
);

const InstructionFormQuestion = ({
  label,
  questionId,
}: ExerciseStepQuestionComponentProps<'instruction'>) => {
  const { values } = useFormikContext();
  const questionValue = values?.[questionId];

  return (
    <>
      {label ? <Text>{label}</Text> : null}
      <RadioGroup variant="coa-main" defaultValue="complete">
        <VStack align="left" width="stretch" justifyContent="start">
          <Field
            type="radio"
            as={Radio}
            name={questionId}
            value={questionValue}
            validate={getFieldValidationFn(exerciseStepQuestionKind.instruction)}
            display="none"
          >
            {label}
          </Field>
        </VStack>
      </RadioGroup>
    </>
  );
};

const WeeklyRepRecapFormQuestion = ({
  label,
  questionId,
}: ExerciseStepQuestionComponentProps<'weekly_rep_recap'>) => {
  const { values } = useFormikContext();
  const questionValue = values?.[questionId];
  return (
    <>
      {label ? <Text>{label}</Text> : null}
      <WeeklyRepRecap />
      <RadioGroup variant="coa-main" defaultValue="complete">
        <VStack align="left" width="stretch" justifyContent="start">
          <Field
            type="radio"
            as={Radio}
            name={questionId}
            value={questionValue}
            validate={getFieldValidationFn(exerciseStepQuestionKind.weeklyRepRecap)}
            display="none"
          >
            {label}
          </Field>
        </VStack>
      </RadioGroup>
    </>
  );
};

const formQuestionKindToQuestionComponent: {
  [key in ExerciseStepQuestionKind]: (
    props: ExerciseStepQuestionComponentProps<ExerciseStepQuestionKind>
  ) => React.ReactElement;
} = {
  [exerciseStepQuestionKind.checkbox]: CheckboxFormQuestion,
  [exerciseStepQuestionKind.radio]: RadioFormQuestion,
  [exerciseStepQuestionKind.longText]: LongTextFormQuestion,

  // Non-standard, "custom" question types
  [exerciseStepQuestionKind.instruction]: InstructionFormQuestion,
  [exerciseStepQuestionKind.weeklyRepRecap]: WeeklyRepRecapFormQuestion,
};

const getFormQuestionById = (formQuestions: ExerciseStep['formQuestions'], questionId: string) =>
  formQuestions[formatQuestionId(questionId)];

export const FormQuestion = ({ questionId }: { questionId: string }) => {
  const { formQuestions } = useContext(FormQuestionContext);
  const formQuestionProps = getFormQuestionById(formQuestions, questionId);
  const { kind } = formQuestionProps;

  const FormQuestionComponent = formQuestionKindToQuestionComponent[kind];
  if (!FormQuestionComponent) {
    throw Error(`Could not identify FormQuestionComponent for question with kind: "${kind}"`);
  }

  return <FormQuestionComponent questionId={questionId} {...formQuestionProps} />;
};
