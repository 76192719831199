import {
  AspectRatio,
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Image,
  ImageProps,
  Text,
  TextProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import { Textfit } from 'react-textfit';
import { ColorScheme, ComponentWithTo } from '../../lib/chakra-ui';

export type EventCardProps = Pick<ImageProps, 'src'> &
  BoxProps & {
    graphicChildren?: React.ReactElement;
  };

const baseStyleProps = {
  boxShadow: 'base',
  borderRadius: 'base',
  overflow: 'hidden',
};

/*
 * Parent components
 */

export const EventCard = ({ src, children, graphicChildren = null, ...rest }: EventCardProps) => (
  <Box {...baseStyleProps} {...rest}>
    <AspectRatio ratio={2}>
      <>
        <Image objectFit="cover" src={src} />
        {graphicChildren ? <Box>{graphicChildren}</Box> : null}
      </>
    </AspectRatio>
    <Box p={4}>{children}</Box>
  </Box>
);

export const FeaturedEventCard = ({ src, children, ...rest }: EventCardProps) => (
  <Box
    {...baseStyleProps}
    display="flex"
    flexDir={{ base: 'column', md: 'row' }}
    minHeight={{ md: 288 }}
    {...rest}
  >
    {/* Desktop-only Image on the side */}
    <Box
      display={{ base: 'none', md: 'block' }}
      width={{ md: 208 }}
      height="auto"
      flex="1 0 auto"
      position="relative"
    >
      <Image
        position="absolute"
        left={0}
        top={0}
        height="100%"
        width="100%"
        objectFit="cover"
        src={src}
      />
    </Box>
    {/* Mobile-only Image on the top */}
    <AspectRatio display={{ md: 'none' }} ratio={16 / 9}>
      <Image objectFit="cover" src={src} />
    </AspectRatio>
    <Flex flexDir="column" p={{ base: 4, md: 6 }} justifyContent="space-between">
      {children}
    </Flex>
  </Box>
);

export const SecondaryEventCard = ({ src, children, ...rest }: EventCardProps) => (
  <Box overflow="hidden" display="flex" flexDir="row" {...rest}>
    <AspectRatio
      ratio={1}
      width={100}
      height={100}
      borderRadius="base"
      overflow="hidden"
      flex="1 0 auto"
    >
      <Image objectFit="cover" src={src} />
    </AspectRatio>
    <Box pl={4}>{children}</Box>
  </Box>
);

/*
 * Base sub-components
 */

export const EventCardCategory = ({
  children,
  colorScheme = 'warm',
  ...rest
}: ComponentWithTo<TextProps>) => (
  <Text
    sx={{ textTransform: 'uppercase' }}
    textStyle="earmark"
    color={`${colorScheme}.500`}
    {...rest}
  >
    {children}
  </Text>
);

export const EventCardHeading = ({ children, ...rest }: ComponentWithTo<TextProps>) => (
  <Text fontWeight="bold" {...rest}>
    {children}
  </Text>
);

export const EventCardSubHeading = ({ children, ...rest }: ComponentWithTo<TextProps>) => (
  <Text fontSize="xs" color="gray.500" {...rest}>
    {children}
  </Text>
);

export const EventCardDescription = ({ children, ...rest }: ComponentWithTo<TextProps>) => (
  <Text fontSize="sm" {...rest}>
    {children}
  </Text>
);

export const EventCardInstructorContainer = ({ children, ...rest }: ComponentWithTo<BoxProps>) => (
  <Flex {...rest}>{children}</Flex>
);

export const EventCardInstructorName = ({ children, ...rest }: ComponentWithTo<TextProps>) => (
  <Text fontSize="md" color="gray.500" {...rest}>
    {children}
  </Text>
);

export const EventCardInstructorAvatar = ({
  name,
  imgSrc,
  children,
  ...rest
}: ComponentWithTo<AvatarProps> & { name: string; imgSrc: string }) => (
  <Avatar {...rest} size="xs" name={name} src={imgSrc} />
);

export const FeaturedEventCardHeading = ({ children, ...rest }: ComponentWithTo<HeadingProps>) => (
  <Heading size="lg" {...rest}>
    {children}
  </Heading>
);

/*
 * Feature-specific layout components
 */

export const FeaturedEventCardHeader = ({ children, ...rest }: BoxProps) => (
  <Box pb={4} {...rest}>
    {children}
  </Box>
);
export const FeaturedEventCardContent = ({ children, ...rest }: BoxProps) => (
  <Box {...rest}>{children}</Box>
);
export const FeaturedEventCardFooter = ({ children, ...rest }: BoxProps) => (
  <Box pt={4} {...rest}>
    {children}
  </Box>
);

type EventCardLabelBannerProps = FlexProps & {
  colorScheme?: ColorScheme;
};

/*
 * Optional sub-components
 */
export const EventCardLabelBanner = ({ children, ...rest }: EventCardLabelBannerProps) => {
  const styles = useMultiStyleConfig('EventCardLabelBanner', rest);
  return (
    <Flex
      position="absolute"
      height="100%"
      width="50%"
      left={0}
      top={0}
      alignItems="center"
      px={4}
      py={{ base: 4, sm: 6 }}
      sx={styles.banner}
      {...rest}
    >
      <Textfit
        mode="multi"
        max={20}
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          ...styles.text,
        }}
      >
        {children}
      </Textfit>
    </Flex>
  );
};
