import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';
import {
  EventCardDescription,
  EventCardInstructorAvatar,
  EventCardInstructorContainer,
  EventCardInstructorName,
  EventCardSubHeading,
  FeaturedEventCard,
  FeaturedEventCardContent,
  FeaturedEventCardFooter,
  FeaturedEventCardHeader,
  FeaturedEventCardHeading,
} from '../../../../components/molecules/EventCard';
import {
  formatWorkshopOccurrenceTimeLabel,
  formatWorkshopTimeLabel,
  useGetWorkshopQuery,
} from '../../../../resources/workshops';
import { getClassesRouterUrl } from '../../../../routerPaths/classesRouterPaths';

export function SubscribeSuccessState({
  id: workshopId,
  dropInWorkshopOccurrenceId,
}: {
  id: string;
  dropInWorkshopOccurrenceId: string;
}): JSX.Element {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });

  // TODO: Proper loading state
  if (getWorkshopQuery.isLoading) return null;

  const { data: workshop } = getWorkshopQuery;
  const { workshopOccurrences } = workshop;

  const timeLabel = formatWorkshopTimeLabel(workshop, workshopOccurrences);
  const dropInWorkshopOccurrence = dropInWorkshopOccurrenceId
    ? workshopOccurrences.find((o) => o.id === dropInWorkshopOccurrenceId)
    : null;

  return (
    <VStack
      padding={{ base: 8, lg: 12 }}
      maxW={900}
      mx="auto"
      justifyContent={{ lg: 'center' }}
      height="100%"
      spacing={{ base: 4, lg: 8 }}
    >
      <Heading textAlign="center">Welcome to the Coa community!</Heading>
      <Text textAlign="center" fontSize="xl">
        Let's reserve your spot.
      </Text>
      {dropInWorkshopOccurrence ? (
        <FeaturedEventCard src={workshop.imageUrl}>
          <FeaturedEventCardHeader />
          <FeaturedEventCardContent>
            <FeaturedEventCardHeading mb={2}>
              {dropInWorkshopOccurrence.title}
            </FeaturedEventCardHeading>
            <EventCardSubHeading>
              {formatWorkshopOccurrenceTimeLabel(dropInWorkshopOccurrence)}
            </EventCardSubHeading>
            <EventCardDescription>{dropInWorkshopOccurrence.description}</EventCardDescription>
            <EventCardInstructorContainer mt={4}>
              <EventCardInstructorAvatar
                name={workshop.therapist.name}
                imgSrc={workshop.therapist.imageUrl}
                mr={2}
              />
              <EventCardInstructorName>{workshop.therapist.name}</EventCardInstructorName>
            </EventCardInstructorContainer>
          </FeaturedEventCardContent>
          <FeaturedEventCardFooter>
            <Button
              size="lg"
              variant="primary"
              as={RouterLink}
              to={getClassesRouterUrl.dropInJoin({
                id: workshop.id,
                dropInWorkshopOccurrenceId,
              })}
            >
              Save My spot
            </Button>
          </FeaturedEventCardFooter>
        </FeaturedEventCard>
      ) : (
        <FeaturedEventCard src={workshop.imageUrl}>
          <FeaturedEventCardHeader />
          <FeaturedEventCardContent>
            <FeaturedEventCardHeading marginBottom={2}>{workshop.title}</FeaturedEventCardHeading>
            <EventCardSubHeading>{timeLabel}</EventCardSubHeading>
            <EventCardDescription>{workshop.description}</EventCardDescription>
            <EventCardInstructorContainer mt={4}>
              <EventCardInstructorAvatar
                name={workshop.therapist.name}
                imgSrc={workshop.therapist.imageUrl}
                mr={2}
              />
              <EventCardInstructorName>{workshop.therapist.name}</EventCardInstructorName>
            </EventCardInstructorContainer>
          </FeaturedEventCardContent>
          <FeaturedEventCardFooter>
            <Button
              size="lg"
              variant="primary"
              as={RouterLink}
              to={getClassesRouterUrl.classJoin({ id: workshop.id })}
            >
              Save My spot
            </Button>
          </FeaturedEventCardFooter>
        </FeaturedEventCard>
      )}
    </VStack>
  );
}
