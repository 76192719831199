import { Link, LinkProps } from '@chakra-ui/react';
import React from 'react';

// TODO: This to prop type is bad

export const TransformUpLink = ({ children, ...rest }: LinkProps & { to?: string }) => (
  <Link
    display="inline-block"
    transition="0.2s ease"
    _hover={{
      transform: 'translateY(-0.125rem)',
    }}
    {...rest}
  >
    {children}
  </Link>
);
