import { Container, Grid, GridItem, Heading, Link, Skeleton, Text } from '@chakra-ui/react';
import { RouterLink, useOnMount } from '@coa/react-utils';
import React from 'react';
import { FullClassListing } from '../../components/molecules/ClassListing';
import { TransformUpLink } from '../../components/molecules/TransformUpLink';
import { WorkshopOccurrenceExerciseCta } from '../../components/WorkshopExerciseCta';
import { classMarketplaceAnalytics } from '../../lib/analytics/clients';
import { reverseImmutable } from '../../lib/array';
import {
  createGetDropInWorkshopOccurrencesPlaceholderData,
  formatWorkshopOccurrenceTimeLabel,
  getIsSeriesLike,
  usePastWorkshopOccurrencesQuery,
} from '../../resources/workshops';
import { getWorkshopRouterUrl } from '../../routerPaths/helpers';

const placeholderData = createGetDropInWorkshopOccurrencesPlaceholderData({ length: 6 });

export const PastClassesView = () => {
  useOnMount(() => {
    classMarketplaceAnalytics.track('Viewed My Coa Page', { tab: 'Past Classes' });
  });

  const pastWorkshopOccurrencesQuery = usePastWorkshopOccurrencesQuery({ placeholderData });
  const { data: pastWorkshopOccurrences } = pastWorkshopOccurrencesQuery;
  const isLoading =
    pastWorkshopOccurrencesQuery.isLoading || pastWorkshopOccurrencesQuery.isPlaceholderData;

  return (
    <Container maxW="100%" p="0" mb={10}>
      <Heading size="md" mb={6}>
        Past Classes
      </Heading>
      <Grid templateRows="repeat(1, 1fr)" width="100%" columnGap={{ base: 0, md: 8 }} rowGap={8}>
        {pastWorkshopOccurrences.length > 0 ? (
          reverseImmutable(pastWorkshopOccurrences).map((occurrence) => {
            const workshopRouterUrl = getWorkshopRouterUrl({
              id: occurrence.workshop.id,
              dropInWorkshopOccurrenceId: getIsSeriesLike(occurrence.workshop.kind)
                ? occurrence.id
                : undefined,
            });
            return (
              <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} key={occurrence.id}>
                <Skeleton isLoaded={!isLoading}>
                  <TransformUpLink as={RouterLink} to={workshopRouterUrl} width="100%">
                    <FullClassListing
                      label={occurrence.workshop.marketListingLabel}
                      title={occurrence.title}
                      date={formatWorkshopOccurrenceTimeLabel(occurrence)}
                      instructorName={occurrence.therapist.name}
                      instructorImage={occurrence.therapist.imageUrl}
                      src={occurrence.workshop.imageUrl}
                      colorScheme="gray"
                      description={occurrence.description}
                      renderCta={(props) => (
                        <WorkshopOccurrenceExerciseCta
                          workshopId={occurrence.workshop.id}
                          exerciseId={occurrence.exerciseId}
                          occurrenceStartsAt={occurrence.startsAt}
                          isOnlyOccurrence
                          {...props}
                        />
                      )}
                    />
                  </TransformUpLink>
                </Skeleton>
              </GridItem>
            );
          })
        ) : (
          <Text data-cy="my-coa-past-classes-empty">
            You haven't attended a class yet. Browse all classes{' '}
            <Link as={RouterLink} to="/classes" _hover={{ textDecoration: 'underline' }}>
              here
            </Link>
            .
          </Text>
        )}
      </Grid>
    </Container>
  );
};
