/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CLEAROUT_APPOINTMENT,
  create as appointmentCreate,
  destroy,
} from '../actions/appointments';
import { list } from '../actions/clients';
import { CLEAROUT_INVOICE, clientList, create as invoiceCreate } from '../actions/invoices';
import { show } from '../actions/therapistBillingProfiles';
import CreateAppointment from '../components/CreateAppointment';
import InvoiceSent from '../components/InvoiceSent';
import Loading from '../components/Loading';
import SendInvoice from '../components/SendInvoice';
import SetupBillingProfile from '../components/SetupBillingProfile';

const DEFAULT_STATE = {
  appointment: null,
  client_member_name: '',
  client_member_email: '',
  client_member_id: '',
  amount_cents: '',
};

class SendInvoiceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.updateAttr = this.updateAttr.bind(this);
    this.sendInvoice = this.sendInvoice.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
    this.createAppointmentForClient = this.createAppointmentForClient.bind(this);
    this.destroyAppointment = this.destroyAppointment.bind(this);
    this.backToDashboard = this.backToDashboard.bind(this);
    this.getClients = this.getClients.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.getBillingProfile = this.getBillingProfile.bind(this);
  }

  componentDidMount() {
    this.getClients();
    this.getBillingProfile();
  }

  componentDidUpdate() {
    const { appointment } = this.props;
    const { appointment: appointmentState } = this.state;
    if (appointment && !appointmentState) {
      this.setState({ appointment });
    }
  }

  getInvoices() {
    const { dispatch, memberId } = this.props;
    dispatch(clientList(memberId));
  }

  getClients() {
    const { dispatch, memberId } = this.props;
    dispatch(list(memberId));
  }

  getBillingProfile() {
    const { dispatch, memberId } = this.props;
    dispatch(show(memberId));
  }

  updateAttr(e, attr) {
    const newState = {};
    newState[attr] = e.target.value;
    this.setState(newState);
  }

  sendInvoice(e) {
    e.preventDefault();
    const { dispatch, memberId } = this.props;
    const { appointment, amount_cents } = this.state;
    dispatch(
      invoiceCreate(memberId, appointment.id, {
        amount_cents: parseInt(parseFloat(amount_cents) * 100),
      })
    );
  }

  createAppointment(e) {
    e.preventDefault();
    const { dispatch, memberId } = this.props;
    const { client_member_name, client_member_email } = this.state;
    dispatch(
      appointmentCreate(memberId, {
        client_member_name,
        client_member_email,
        status: 'completed',
      })
    );
  }

  CreateAppointment(values) {
    const { dispatch, memberId } = this.props;
    dispatch(appointmentCreate(memberId, values));
  }

  createAppointmentForClient(client) {
    this.setState({
      client_member_name: client.attributes.name,
      client_member_email: client.attributes.email,
      /*
       * Leaving this for sake of safety despite eslint
       * reporting it as being unused.
       */
      // eslint-disable-next-line react/no-unused-state
      client_member_id: client.id,
    });
    const { dispatch, memberId } = this.props;
    dispatch(
      appointmentCreate(memberId, {
        client_member_id: client.id,
        status: 'completed',
      })
    );
    dispatch(clientList(client.id));
  }

  destroyAppointment() {
    const { dispatch, appointment, memberId } = this.props;
    dispatch(destroy(memberId, appointment.id));
    this.setState({ ...DEFAULT_STATE });
  }

  backToDashboard() {
    const { dispatch } = this.props;
    this.getClients();
    this.setState({ ...DEFAULT_STATE });
    dispatch({ type: CLEAROUT_INVOICE });
    dispatch({ type: CLEAROUT_APPOINTMENT });
  }

  render() {
    /*
     * Disabling prop-types as fixing won't be helpful when we
     * prefer Typescript prop definitions.
     */
    /* eslint-disable react/prop-types */
    const {
      memberId,
      billingProfile,
      loadingBillingProfile,
      gettingClients,
      existingClients,
      appointment,
      appointmentErrors,
      creatingAppointment,
      invoice,
      invoiceErrors,
      creatingInvoice,
    } = this.props;
    const { client_member_name, client_member_email, amount_cents } = this.state;
    if (gettingClients || loadingBillingProfile) return <Loading />;
    if (!billingProfile) return <SetupBillingProfile memberId={memberId} />;
    if (invoice) {
      return <InvoiceSent backToDashboard={this.backToDashboard} />;
    }
    if (appointment) {
      return (
        <SendInvoice
          back={this.destroyAppointment}
          clientMemberName={client_member_name}
          clientMemberEmail={client_member_email}
          updateAttr={this.updateAttr}
          sendInvoice={this.sendInvoice}
          loading={creatingInvoice}
          errors={invoiceErrors}
          amountCents={amount_cents}
        />
      );
    }
    return (
      <CreateAppointment
        existingClients={existingClients}
        updateAttr={this.updateAttr}
        createAppointment={this.createAppointment}
        createAppointmentForClient={this.createAppointmentForClient}
        loading={creatingAppointment}
        errors={appointmentErrors}
        clientMemberName={client_member_name}
        clientMemberEmail={client_member_email}
        /*
         * Uncertain as to what this is so disabling
         * to be safe.
         */
        /* eslint-disable-next-line */
        CreateAppointment={this.CreateAppointment}
      />
    );
    /* eslint-enable react/prop-types */
  }
}

SendInvoiceContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
  appointment: PropTypes.object,
  appointmentErrors: PropTypes.object.isRequired,
  creatingAppointment: PropTypes.bool.isRequired,
  invoice: PropTypes.object,
  invoiceErrors: PropTypes.object.isRequired,
  creatingInvoice: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { memberId } = state.session;
  const { gettingClients, existingClients } = state.clients;
  const { appointment, appointmentErrors, creatingAppointment } = state.appointments;
  const { billingProfile, loading } = state.therapistBillingProfiles;
  const { invoice, invoiceErrors, creatingInvoice, invoices, gettingInvoices } = state.invoices;
  return {
    memberId,
    billingProfile,
    loadingBillingProfile: loading,
    gettingClients,
    existingClients,
    appointment,
    appointmentErrors,
    creatingAppointment,
    invoice,
    invoiceErrors,
    creatingInvoice,
    invoices,
    gettingInvoices,
  };
};

export default connect(mapStateToProps)(SendInvoiceContainer);
