import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';

export const ExerciseCompleteModal = ({
  isOpen,
  onClose,
  workshopId,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { workshopId: string }): JSX.Element => (
  <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="sm">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="md">Great work!</Heading>
      </ModalHeader>
      <ModalBody>
        <Text>
          You're all ready for class. You can return to your Class Prep materials at any time to
          update your work.
        </Text>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Continue my work
          </Button>
          <Button as={RouterLink} to={`/classes/${workshopId}`} variant="primary">
            Return to Class
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export const ClassPrepCompleteModal = ({
  isOpen,
  onClose,
  workshopId,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { workshopId: string }) => (
  <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="sm">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="md">Great work!</Heading>
      </ModalHeader>
      <ModalBody>
        <Text>
          You're all ready for class. You can return to your Class Prep materials at any time to
          update your work.
        </Text>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Continue my work
          </Button>
          <Button as={RouterLink} to={`/classes/${workshopId}`} variant="primary">
            Return to Class
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export const StepLockedModal = ({ isOpen }: Pick<ModalProps, 'isOpen'>) => (
  <Modal
    isOpen={isOpen}
    // There's no onClose logic required here as it's simpler
    // to manage the routing manually via the RouterLink below.
    onClose={() => null}
    variant="coa-main"
    size="sm"
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="md">Little lost?</Heading>
      </ModalHeader>
      <ModalBody>
        <Text>We're not ready for you to complete this part of the exercise.</Text>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button as={RouterLink} to="/my-coa" variant="primary">
            Return to My Coa
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
