import { Container, Flex, Heading, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowRightIcon } from '../../../components/Icons';

type CardViewPreviewContainerProps = {
  children: React.ReactNode;
  heading: string;
  linkUrl: string;
};

// we use this in ClassesHubView (maybe it should just go in there?)
// to quickly wrap the different 'UpcomingXCardViews'
export const UpcomingCardViewPreviewContainer = ({
  children,
  heading,
  linkUrl,
}: CardViewPreviewContainerProps) => (
  <Container maxW="100%" p="0" mb={10}>
    <Flex alignItems="baseline" py={8} justifyContent={{ base: 'space-between', md: 'flex-start' }}>
      <Heading fontSize="2xl">{heading}</Heading>
      <Link
        as={RouterLink}
        to={linkUrl}
        ml={8}
        fontSize="md"
        _hover={{ textDecoration: 'underline' }}
        display="flex"
        alignItems="center"
      >
        See All <ArrowRightIcon ml={2} />
      </Link>
    </Flex>
    {children}
  </Container>
);
