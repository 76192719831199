import { Button, ButtonProps, Flex, FlexProps, Link, Skeleton, Text } from '@chakra-ui/react';
import { useGetAccountQuery } from '@coa/api/controllers/v1/accounts';
import {
  getHasActiveSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { pickSharedLayoutProps } from '../lib/chakra-ui';
import { enrollmentStatuses } from '../resources/enrollments';
import { getHasClassEnded } from '../resources/workshopOccurrences';
import {
  getIsEnrollmentApprovedForPartialSeries,
  getIsSeriesLike,
  useGetWorkshopQuery,
  usePostWorkshopEnrollmentMutation,
  Workshop,
  WorkshopOccurrence,
} from '../resources/workshops';
import { getUniversalClassesRouterUrl } from '../routerPaths/classesRouterPaths';
import { VideoIcon } from './Icons';

/*
 * This component has way too much responsibility and too much going on - it's
 * responsible for complex stateful user logic AND consequently renders entirely
 * different components (Flex and Button). This makes reusability *very*
 * unpredictable and challenging.
 *
 * In the short term we just "live with it" but we should refactor this component
 * to lessen cognitive load. In the meantime, the type we assign is extreeeemely
 * forgiving.
 *
 * @see https://linear.app/coa/issue/COA-902/look-to-refactor-workshopcta-and-workshopexercisecta-components-to
 *
 */
type WorkshopCtaParentComponentProps = ButtonProps & FlexProps;

type WorkshopCtaProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
  isLoading?: boolean;
} & WorkshopCtaParentComponentProps;

export const WorkshopCta = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  isLoading,
  ...rest
}: WorkshopCtaProps): JSX.Element => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId }, { enabled: !isLoading });
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const getAccountQuery = useGetAccountQuery();

  if (
    getSubscriptionsQuery.isLoading ||
    getWorkshopQuery.isLoading ||
    getAccountQuery.isLoading ||
    isLoading
  )
    return (
      <Skeleton {...pickSharedLayoutProps<ButtonProps>(rest)}>
        <Button>Workshop Button</Button>
      </Skeleton>
    );

  const hasActiveSubscription = getHasActiveSubscriptionFromQueryResponse(
    getSubscriptionsQuery.data
  );

  const { data: workshop } = getWorkshopQuery;

  const { id, soldOut, workshopOccurrences, meta: { enrollmentStatus, joinUrl } = {} } = workshop;

  // TODO: We should maybe move this into workshop utils for DRY? :shrug:
  const dropInWorkshopOccurrence: WorkshopOccurrence = dropInWorkshopOccurrenceId
    ? workshopOccurrences[_.findKey(workshopOccurrences, { id: dropInWorkshopOccurrenceId })]
    : null;

  const lastWorkshopOccurrence = workshopOccurrences[workshopOccurrences.length - 1];

  const baseUrl = dropInWorkshopOccurrence
    ? `/classes/${id}/drop-ins/${dropInWorkshopOccurrence.id}`
    : `/classes/${id}`;
  const applyUrl = `${baseUrl}/join`;
  const subscribeUrl = `${baseUrl}/subscribe`;

  const saveMySeatButton = (
    <Button
      variant="primary"
      to={hasActiveSubscription ? applyUrl : subscribeUrl}
      as={RouterLink}
      width={{ base: '100%', md: 'auto' }}
      data-cy="workshop-cta"
      {...rest}
    >
      Save My Spot
    </Button>
  );

  if (
    (dropInWorkshopOccurrence &&
      getHasClassEnded(
        dropInWorkshopOccurrence.startsAt,
        dropInWorkshopOccurrence.durationInMinutes
      )) ||
    getHasClassEnded(lastWorkshopOccurrence.startsAt, lastWorkshopOccurrence.durationInMinutes)
  ) {
    return null;
  }

  if (enrollmentStatus) {
    const cta = {
      [enrollmentStatuses.approved]: (
        <Button
          variant="primary"
          as={Link}
          target="_blank"
          href={joinUrl}
          leftIcon={<VideoIcon />}
          width={{ base: '100%', md: 'auto' }}
          data-cy="workshop-cta"
          {...rest}
        >
          Join Class
        </Button>
      ),
      [enrollmentStatuses.rejected]: (
        <Flex
          alignItems="center"
          p={5}
          bgColor="gray.100"
          borderRadius={5}
          data-cy="join-workshop-status"
          {...rest}
        >
          <Text>
            We are not able to offer you a spot in class at this time. We’ve issued a full refund to
            your account.
          </Text>
        </Flex>
      ),
      [enrollmentStatuses.pending]: (
        <Flex
          alignItems="center"
          p={5}
          bgColor="gray.100"
          borderRadius={5}
          data-cy="join-workshop-status"
          {...rest}
        >
          <Text>Your application is being reviewed.</Text>
        </Flex>
      ),
      [enrollmentStatuses.applicationIncomplete]: (
        <Button
          variant="primary"
          as={RouterLink}
          to={`${baseUrl}/join/complete-profile`}
          width={{ base: '100%', md: 'auto' }}
          data-cy="workshop-cta"
          {...rest}
        >
          Finish Application
        </Button>
      ),
      [enrollmentStatuses.awaitingInfo]: (
        <Flex
          alignItems="center"
          p={5}
          bgColor="yellow.100"
          borderRadius={5}
          data-cy="join-workshop-status"
          {...rest}
        >
          <Text>We've requested more info on your application. Please check your email!</Text>
        </Flex>
      ),
    }[enrollmentStatus];
    return cta || null;
  }
  if (soldOut) {
    return (
      <Flex
        alignItems="center"
        p={5}
        bgColor="gray.100"
        borderRadius={5}
        data-cy="join-workshop-status"
        {...rest}
      >
        <Text>Sorry! This class is sold out.</Text>
      </Flex>
    );
  }

  return saveMySeatButton;
};

/*
 * CTA Button component that will auto-enroll a user in a Pushup class (requires no answers).
 * The workshopEnrollmentMutation solution involves an identical approach to how users
 * can enroll in "push ups" elsewhere in the app since pushup workshops don't require
 * answers. (TryPushupsView.tsx).
 */
const WorkshopAutoEnrollCta = ({ workshopId, ...rest }: WorkshopCtaContentProps & ButtonProps) => {
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { data: workshop } = getWorkshopQuery;
  const { meta: workshopMeta } = workshop;
  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;
  const hasPartialEnrollment = getIsEnrollmentApprovedForPartialSeries({
    enrollmentStatus,
    enrollmentDropInIds,
  });
  const isLoaded = !getSubscriptionsQuery.isLoading && !getWorkshopQuery.isLoading;

  const workshopEnrollmentMutation = usePostWorkshopEnrollmentMutation({ id: workshopId });
  const enrollmentSuccessful = workshopEnrollmentMutation.isSuccess;
  const enrollmentLoading = workshopEnrollmentMutation.isLoading;

  const handleClick = () => {
    workshopEnrollmentMutation.mutate(
      workshop.autoApprove
        ? // For most auto-enroll cases (Pushup workshops), we require no answers
          // If we're auto-enrolling a user in a Series that they're partially enrolled in
          // We do not want to override any existing answers.
          {
            answers: {},
          }
        : {}
    );
  };

  return (
    <Skeleton isLoaded={isLoaded} width="100%" display="flex" justifyContent="flex-end">
      {/* Split this into 2 buttons even though very similar functionality. Didn't want
      to swiss-army knife it.
      We explicitly check if the Workshop is a Series that has Partial Enrollment, if so
      we apply a different cypress data attr, CTA copy and variant.
       */}
      {getIsSeriesLike(workshop.kind) && hasPartialEnrollment ? (
        <Button
          variant="secondary"
          onClick={handleClick}
          data-cy="workshop-apply-remaining-series-cta"
          isLoading={enrollmentLoading}
          loadingText="Saving your spot"
          {...rest}
        >
          Enroll in Series
        </Button>
      ) : (
        <Button
          colorScheme="red"
          variant="solid"
          onClick={handleClick}
          data-cy="workshop-apply-cta"
          isLoading={enrollmentLoading}
          loadingText="Saving your spot"
          {...rest}
        >
          Save My Spot
        </Button>
      )}
      {
        // We refresh the page to display accurate enrollment information.
        // This is a hack, invalidating queries should solve for this but
        // currently not working as intended -_-
        enrollmentSuccessful ? window.location.reload() : null
      }
    </Skeleton>
  );
};

type WorkshopCtaContentProps = {
  workshopId: Workshop['id'];
  dropInWorkshopOccurrenceId?: WorkshopOccurrence['id'];
};

export const WorkshopFinishApplicationCta = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopCtaContentProps & ButtonProps) => (
  <Button
    colorScheme="red"
    variant="solid"
    as={RouterLink}
    to={getUniversalClassesRouterUrl.joinCompleteProfile({
      id: workshopId,
      dropInWorkshopOccurrenceId,
    })}
    data-cy="workshop-finish-application-cta"
    {...rest}
  >
    Finish Application
  </Button>
);

export const WorkshopApplyCta = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopCtaContentProps & ButtonProps) => {
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { data: subscription } = getSubscriptionsQuery;
  const { data: workshop } = getWorkshopQuery;
  const { meta: workshopMeta } = workshop;
  const { enrollmentStatus, enrollmentDropInIds } = workshopMeta;
  const hasPartialEnrollment = getIsEnrollmentApprovedForPartialSeries({
    enrollmentStatus,
    enrollmentDropInIds,
  });
  const classIsJoinable =
    getHasActiveSubscriptionFromQueryResponse(subscription) || workshop.access === 'everyone';

  const isLoaded = !getSubscriptionsQuery.isLoading && !getWorkshopQuery.isLoading;
  const getCtaUrl = classIsJoinable
    ? getUniversalClassesRouterUrl.join
    : getUniversalClassesRouterUrl.subscribe;

  if (
    (workshop.autoApprove && classIsJoinable) ||
    (hasPartialEnrollment && !dropInWorkshopOccurrenceId)
  ) {
    return <WorkshopAutoEnrollCta workshopId={workshopId} {...rest} />;
  }
  return (
    <Skeleton isLoaded={isLoaded} width="100%">
      <Button
        colorScheme="red"
        variant="solid"
        to={getCtaUrl({
          id: workshopId,
          dropInWorkshopOccurrenceId,
        })}
        as={RouterLink}
        data-cy="workshop-apply-cta"
        {...rest}
      >
        Save My Spot
      </Button>
    </Skeleton>
  );
};

export const WorkshopJoinClassCta = ({
  workshopId,
  dropInWorkshopOccurrenceId,
  ...rest
}: WorkshopCtaContentProps & ButtonProps) => {
  const getWorkshopQuery = useGetWorkshopQuery({ id: workshopId });
  const { isLoading } = getWorkshopQuery;
  const { data: workshop } = getWorkshopQuery;
  const { meta: { joinUrl } = {} } = workshop;
  return (
    <Skeleton isLoaded={!isLoading} width="100%">
      <Button
        variant="primary"
        as={Link}
        target="_blank"
        href={joinUrl}
        leftIcon={<VideoIcon />}
        data-cy="workshop-join-class-cta"
        {...rest}
      >
        Join Class
      </Button>
    </Skeleton>
  );
};
