import { Box, Flex, Heading, IconButton, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { show } from '../actions/accounts';
import AccountInfoForm from './AccountInfoForm';
import { ArrowLeftIcon } from './Icons';
import Loading from './Loading';

const EditAccountInfo = () => {
  const dispatch = useDispatch();
  const { account, gettingAccount } = useSelector((state) => state.account);
  useEffect(() => {
    dispatch(show());
  }, [dispatch]);
  if (gettingAccount) return <Loading fillPage />;
  return (
    <Stack width={{ sm: '90%', md: '50%' }}>
      <Flex direction="row" alignItems="flex-start" mt={{ sm: 4 }}>
        <IconButton
          as={RouterLink}
          to="/account"
          borderRadius="50%"
          color="gray"
          bgColor="green.50"
          variant="outline"
          icon={<ArrowLeftIcon />}
        />
        <Box width="stretch">
          <Heading mx={4} mb={4}>
            My Info
          </Heading>
          <Box mx={4}>
            <AccountInfoForm dispatch={dispatch} account={account} />
          </Box>
        </Box>
      </Flex>
    </Stack>
  );
};

export default EditAccountInfo;
