import { Global } from '@emotion/react';
import React from 'react';
import { quillSnowThemeCss } from './quillSnowThemeCss';

export const ReactQuillStylesProvider = ({ children }: { children: React.ReactNode }) => (
  <>
    <Global styles={quillSnowThemeCss} />
    {children}
  </>
);
