import {
  Button,
  ButtonGroup,
  ButtonProps,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Email } from '@coa/types';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { ChakraFwdModalCloseButton } from '../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../lib/react-query';
import { useAdminCreateMemberMutation } from '../../../resources/member';

const inviteUserFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).required(),
  email: Yup.string().email().required(),
});

type InviteUserFormValues = {
  name: string;
  email: Email;
};

const InviteUserModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
  const rawAdminCreateMemberMutation = useAdminCreateMemberMutation();
  const adminCreateMemberMutation = useMutationWithTimer(rawAdminCreateMemberMutation, {
    duration: 500,
  });

  const isSubmitting = adminCreateMemberMutation.isLoadingWithTimer;

  const toast = useToast();
  const handleSubmit = useCallback(async (params) => {
    try {
      await adminCreateMemberMutation.mutateAsyncWithTimer(params);
      onClose();
      toast({
        title: 'Success!',
        description: `We sent an invite to ${params.email}`,
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something weng wrong!',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="coa-main">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Invite Member</Heading>
        </ModalHeader>
        <ChakraFwdModalCloseButton variant="coa-main" />
        <Formik
          validationSchema={inviteUserFormValidationSchema}
          isInitialValid={false}
          initialValues={{} as InviteUserFormValues}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <ModalBody>
                <VStack spacing={4}>
                  <Text>
                    Invite a standard member (i.e., not a Therapist or Admin) to the Coa platform.
                  </Text>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Field
                      name="name"
                      type="text"
                      as={Input}
                      variant="coa-main"
                      placeholder="Jane Coamember"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Field
                      name="email"
                      type="email"
                      as={Input}
                      variant="coa-main"
                      placeholder="jane@domain.com"
                    />
                  </FormControl>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    isDisabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Send Invite
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

const InviteUserButton = (props: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} {...props} />
      <InviteUserModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export const AdminCrmView = () => (
  <Container maxW="container.xl">
    <Flex my={6} justifyContent="space-between">
      <Heading>CRM</Heading>
      <InviteUserButton variant="secondary">+ Invite User</InviteUserButton>
    </Flex>
  </Container>
);
