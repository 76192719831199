import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { AddSmallIcon, ArrowLeftIcon } from './Icons';
import SimpleTextField from './SimpleTextField';

function SendInvoice({
  loading,
  errors,
  clientMemberName,
  clientMemberEmail,
  amountCents,
  sendInvoice,
  updateAttr,
  back,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box width="stretch" maxWidth="80%">
      <Box>
        <Flex>
          <IconButton
            mx={4}
            borderRadius="50%"
            color="gray"
            variant="outline"
            icon={<ArrowLeftIcon />}
            onClick={back}
          />
          <Box flexGrow={1}>
            <Heading>{clientMemberName}</Heading>
            <Text>{clientMemberEmail}</Text>
          </Box>
          <Button colorScheme="evergreen" leftIcon={<AddSmallIcon />} onClick={onOpen}>
            New Invoice
          </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={sendInvoice}>
              <ModalHeader>
                <Heading>New Invoice</Heading>
              </ModalHeader>
              <ModalCloseButton borderRadius="50%" bg="green.50" />
              <ModalBody>
                <FormControl>
                  <SimpleTextField
                    type="number"
                    name="amount_cents"
                    label="Total Amount"
                    updateAttr={updateAttr}
                    value={amountCents}
                    errors={errors}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" colorScheme="evergreen" disabled={loading}>
                  {loading ? 'Sending...' : 'Send Invoice'}
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

SendInvoice.propTypes = {
  clientMemberName: PropTypes.string,
  clientMemberEmail: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  amountCents: PropTypes.string.isRequired,
  sendInvoice: PropTypes.func.isRequired,
  updateAttr: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
};

export default SendInvoice;
