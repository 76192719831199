import { Box } from '@chakra-ui/react';
import React from 'react';

export function Table(props) {
  return <Box as="table" width="stretch" {...props} />;
}

export function TableHead(props) {
  return <Box as="thead" {...props} />;
}

export function TableRow(props) {
  return <Box as="tr" borderBottomWidth="1px" {...props} />;
}

export function TableHeader(props) {
  return (
    <Box
      as="th"
      px="6"
      py="3"
      borderBottomWidth="1px"
      borderTopWidth="1px"
      textAlign="left"
      fontSize="xs"
      textTransform="uppercase"
      letterSpacing="wider"
      lineHeight="1rem"
      fontWeight="bold"
      {...props}
    />
  );
}

export function TableBody(props) {
  return <Box as="tbody" {...props} />;
}

export function TableCell(props) {
  return <Box as="td" px="6" py="4" lineHeight="1.25rem" whiteSpace="nowrap" {...props} />;
}
