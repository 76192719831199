import { Box, Container, Flex, Heading, Tab, TabList, Tabs } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { matchPath, Route, useLocation } from 'react-router';
import { classMarketplaceAnalytics } from '../../../lib/analytics/clients';
import { WorkshopsBreadcrumb } from '../WorkshopsBreadcrumb';
import { DropInsListView } from './drop-ins/DropInsListView';
import { IntrosListView } from './intros/IntrosListView';
import { QAndAsListView } from './q-and-a/QAndAsListView';
import { SeriesListView } from './series/SeriesListView';

export const BrowseClassesView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    classMarketplaceAnalytics.track('Viewed Browse Classes Page', { path: pathname });
  }, [pathname]);

  const navEntries = _.filter([
    { children: 'Intros', path: '/classes/intros' },
    { children: 'Drop-Ins', path: '/classes/drop-ins' },
    { children: 'Series', path: '/classes/series' },
    { children: 'Q&A', path: '/classes/q-and-a' },
  ]);

  return (
    <Container maxWidth="container.xl" pt={{ base: 2, md: 8 }} px={{ base: 4, md: 8, xl: 0 }}>
      <WorkshopsBreadcrumb mb={4} mt={{ base: 2, md: 0 }} />
      <Box>
        <Heading>Browse Classes</Heading>
        <Tabs
          display="flex"
          index={_.findIndex(navEntries, ({ path }) =>
            Boolean(matchPath(pathname, { path, exact: true }))
          )}
          mb={6}
          size="lg"
        >
          <TabList width="100%">
            {navEntries.map((tab, index) => (
              <Tab
                mr={index === navEntries.length - 1 ? 0 : 8}
                px={0}
                fontSize={{ base: 'md', md: 'lg' }}
                key={tab.children}
                as={RouterLink}
                to={tab.path}
              >
                {tab.children}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>

      <Flex minHeight="60vh" display="flex" flexDirection="column" alignItems="center">
        <Route path="/classes/drop-ins" component={DropInsListView} />
        <Route path="/classes/intros" component={IntrosListView} />
        <Route path="/classes/series" component={SeriesListView} />
        <Route path="/classes/q-and-a" component={QAndAsListView} />
      </Flex>
    </Container>
  );
};
