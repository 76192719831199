import { Button, Heading, VStack } from '@chakra-ui/react';
import { RouterLink, useRouteParams } from '@coa/react-utils';
import React from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { RouterPathParams } from '../../../../routerPaths';
import {
  getUniversalOnDemandRouterUrl,
  onDemandRouterPaths,
} from '../../../../routerPaths/onDemandRouterPaths';
import { SubscribeModal } from '../../../classes/subscribe/components/SubscribeModal';

const OnDemandVideoSubscribeSuccess = ({ ctaTo }: { ctaTo: string }) => (
  <VStack
    padding={{ base: 8, lg: 12 }}
    maxW={900}
    mx="auto"
    justifyContent={{ lg: 'center' }}
    height="100%"
    spacing={{ base: 4, lg: 8 }}
  >
    <Heading textAlign="center">Welcome to the Coa community!</Heading>
    <Button size="lg" variant="primary" as={RouterLink} to={ctaTo}>
      Back to On-Demand
    </Button>
  </VStack>
);

export const OnDemandVideoSubscribeModal = () => {
  const { videoId, checkpointId } = useRouteParams<RouterPathParams.OnDemand['VideoCheckpoint']>();
  const backRouterUrl = getUniversalOnDemandRouterUrl.video({
    videoId,
    checkpointId,
  });
  const location = useLocation();
  const isOpen = Boolean(
    matchPath(location.pathname, {
      path: [onDemandRouterPaths.videoSubscribe, onDemandRouterPaths.videoCheckpointSubscribe],
      exact: true,
    })
  );

  return (
    <SubscribeModal
      backRouterUrl={backRouterUrl}
      isOpen={isOpen}
      renderSubscribeSuccessState={() => <OnDemandVideoSubscribeSuccess ctaTo={backRouterUrl} />}
      title="Coa is your gym for mental health."
    />
  );
};
