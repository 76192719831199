import { Flex, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import PaymentIcon, { PaymentIconBrand } from 'react-payment-icons';

export type PaymentDisplayProps = {
  brand: PaymentIconBrand;
  lastFour: string;
  expirationMonth: number;
  expirationYear: number;
  isLoading?: boolean;
};

const paymentIconWidth = '80px';

export function PaymentDisplay({
  brand,
  lastFour,
  expirationMonth,
  expirationYear,
  isLoading,
}: PaymentDisplayProps) {
  return (
    <HStack>
      <Skeleton width={paymentIconWidth} isLoaded={!isLoading}>
        <PaymentIcon style={{ width: paymentIconWidth }} id={brand} />
      </Skeleton>
      <Stack>
        <Skeleton isLoaded={!isLoading}>
          <Text>****_****_****_{lastFour}</Text>
        </Skeleton>
        <Flex align="center" justify="space-between">
          <Skeleton isLoaded={!isLoading}>
            <Text fontSize="sm">
              Expires {_.padStart(String(expirationMonth), 2, '0')}/
              {String(expirationYear).slice(-2)}
            </Text>
          </Skeleton>
        </Flex>
      </Stack>
    </HStack>
  );
}
