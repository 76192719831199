import {
  Button,
  ButtonGroup,
  ButtonProps,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  useDisclosure,
  UseModalProps,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { TeamAccessKind } from '@coa/api/controllers/v1/teams/types';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { ChakraFwdModalCloseButton } from '../../../../components/ChakraFwdModalCloseButton';
import { useMutationWithTimer } from '../../../../lib/react-query';
import { useAdminPostTeamMutation, usePostTeamTeamsMutation } from '../../../../resources/teams';

// we omit access_kind === membership because it is tied to an ongoing stripe subscription
// we currently do not have an automated way to cancel membership if we edit Team.access_kind
// either to / away from membership
export const editableTeamAccessKindValues: TeamAccessKind[] = [
  'a_la_carte',
  'membership_and_classes',
];

const createTeamFormInitialValues = {
  title: '',
  accessKind: editableTeamAccessKindValues[0],
};

const createTeamFormValidationSchema = Yup.object({
  title: Yup.string().min(1).required(),
  accessKind: Yup.string().oneOf(['a_la_carte', 'membership_and_classes']),
});

const CreateTeamModal = ({
  onClose,
  isOpen,
  onSubmit,
  isLoading,
  teamLabel,
}: UseModalProps & {
  onSubmit: ({ title, accessKind }: { title: string; accessKind: TeamAccessKind }) => Promise<void>;
  isLoading: boolean;
  teamLabel: string;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} variant="coa-main" size="sm">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="md">Create {teamLabel}</Heading>
      </ModalHeader>
      <ChakraFwdModalCloseButton variant="coa-main" />
      <Formik
        validationSchema={createTeamFormValidationSchema}
        initialValues={createTeamFormInitialValues}
        isInitialValid={false}
        onSubmit={onSubmit}
      >
        {({ isValid, values }) => (
          <Form>
            <ModalBody>
              <>
                <Field
                  name="title"
                  type="text"
                  as={Input}
                  variant="coa-main"
                  placeholder={`${teamLabel} name`}
                />
                <FormControl as="fieldset" mt="4">
                  <FormLabel as="legend" fontWeight="bold">
                    <Heading size="xs">Access Kind:</Heading>
                  </FormLabel>
                  <RadioGroup value={values.accessKind} variant="coa-main">
                    <VStack align="start" spacing="2">
                      {editableTeamAccessKindValues.map((ak) => (
                        <Field key={ak} as={Radio} value={ak} name="accessKind">
                          {_.startCase(ak)}
                        </Field>
                      ))}
                    </VStack>
                  </RadioGroup>
                </FormControl>
              </>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isDisabled={!isValid || isLoading}
                  isLoading={isLoading}
                >
                  Create {teamLabel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContent>
  </Modal>
);

export const CreateTopLevelTeamModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const rawPostAdminTeamsMutation = useAdminPostTeamMutation();
  const postAdminTeamsMutation = useMutationWithTimer(rawPostAdminTeamsMutation, { duration: 500 });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    // If the user re-launches, the state will be clean.
    postAdminTeamsMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoadingWithTimer } = postAdminTeamsMutation;

  const handleSubmit = useCallback(async ({ title, accessKind }) => {
    try {
      await postAdminTeamsMutation.mutateAsyncWithTimer({ title, accessKind });
      handleClose();
      toast({
        title: 'Success!',
        description: 'The new team was successfully created.',
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something weng wrong!',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  }, []);

  const props = {
    isLoading: isLoadingWithTimer,
    isOpen,
    onClose: handleClose,
    onSubmit: handleSubmit,
  };

  return <CreateTeamModal {...props} teamLabel="Organization" />;
};

export const CreateSubTeamModal = ({
  isOpen,
  onClose,
  id,
}: {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}) => {
  const rawPostTeamTeamsMutation = usePostTeamTeamsMutation({ id });
  const postTeamTeamsMutation = useMutationWithTimer(rawPostTeamTeamsMutation, { duration: 500 });
  const toast = useToast();

  const handleClose = useCallback(() => {
    onClose();
    // If the user re-launches, the state will be clean.
    postTeamTeamsMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoadingWithTimer } = postTeamTeamsMutation;

  const handleSubmit = useCallback(async ({ title, accessKind }) => {
    try {
      await postTeamTeamsMutation.mutateAsyncWithTimer({ title, accessKind });
      handleClose();
      toast({
        title: 'Success!',
        description: 'The new team was successfully created.',
        duration: 2000,
        isClosable: true,
        status: 'success',
      });
    } catch (err) {
      toast({
        title: 'Something weng wrong!',
        description: 'Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  }, []);

  const props = {
    isLoading: isLoadingWithTimer,
    isOpen,
    onClose: handleClose,
    onSubmit: handleSubmit,
  };

  return <CreateTeamModal {...props} teamLabel="Team" />;
};

export const CreateTopLevelTeamButton = (props: ButtonProps) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} {...props} />
      <CreateTopLevelTeamModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export const CreateSubTeamButton = ({ id, ...rest }: ButtonProps & { id: string }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} {...rest} />
      <CreateSubTeamModal isOpen={isOpen} onClose={onClose} id={id} />
    </>
  );
};
