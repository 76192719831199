import { ACCOUNT_REQUEST, ACCOUNT_SUCCESS, ACCOUNT_UPDATE_SUCCESS } from '../actions/accounts';

const DEFAULT_STATE = {
  gettingAccount: true,
  account: {},
};

export function account(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ACCOUNT_REQUEST: {
      return { ...state, gettingAccount: true };
    }
    case ACCOUNT_SUCCESS:
    case ACCOUNT_UPDATE_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, account: data, gettingAccount: false };
    }
    default:
      return state;
  }
}
