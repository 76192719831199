import {
  THERAPIST_BILLING_PROFILES_SHOW_FAILURE,
  THERAPIST_BILLING_PROFILES_SHOW_REQUEST,
  THERAPIST_BILLING_PROFILES_SHOW_SUCCESS,
} from '../actions/therapistBillingProfiles';

const DEFAULT_STATE = {
  loading: false,
  billingProfile: null,
  errors: {},
};

export function therapistBillingProfiles(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case THERAPIST_BILLING_PROFILES_SHOW_REQUEST: {
      return { ...state, errors: {}, loading: true };
    }
    case THERAPIST_BILLING_PROFILES_SHOW_SUCCESS: {
      let billingProfile = null;
      if (action.response.data) {
        const {
          response: {
            data: { id, attributes },
          },
        } = action;
        billingProfile = { id, ...attributes };
      }
      return { ...state, billingProfile, loading: false };
    }
    case THERAPIST_BILLING_PROFILES_SHOW_FAILURE: {
      const { errors } = action;
      return { ...state, errors, loading: false };
    }
    default:
      return state;
  }
}
