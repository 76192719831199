import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useRouteParams } from '@coa/react-utils';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  EnrollmentFormikValues,
  WorkshopEnrollmentForm,
} from '../../../components/admin/forms/WorkshopEnrollmentForm';
import { MoreVerticalIcon, TrashIcon } from '../../../components/Icons';
import { useMutationWithTimer } from '../../../lib/react-query';
import {
  useAdminEnrollmentDeleteMutation,
  useAdminEnrollmentUpdateMutation,
  useAdminGetEnrollmentQuery,
} from '../../../resources/adminEnrollments/apis/hooks';
import { RouterPathParams } from '../../../routerPaths';

const DeleteEnrollmentMenuItem = ({
  handleConfirm,
  isDeleting,
  ...rest
}: { handleConfirm: () => void; isDeleting: boolean } & MenuItemProps) => {
  const { isOpen, onOpen, onClose: handleClose } = useDisclosure();
  return (
    <>
      <MenuItem onClick={onOpen} {...rest} />
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="md">Delete Enrollment</Heading>
          </ModalHeader>
          <ModalCloseButton variant="coa-main" />
          <ModalBody>
            <Text>
              Are you sure you wish to delete the enrollment? This action cannot be reversed.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button isLoading={isDeleting} variant="primary" onClick={handleConfirm}>
                Delete Enrollment
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EnrollmentActionsMenu = ({
  handleConfirmDelete,
  isDeleting,
  ...rest
}: {
  handleConfirmDelete: () => void;
  isDeleting: boolean;
} & Omit<MenuProps, 'children'>) => (
  <Menu {...rest}>
    <MenuButton as={IconButton} icon={<MoreVerticalIcon />} />
    <MenuList>
      <DeleteEnrollmentMenuItem
        handleConfirm={handleConfirmDelete}
        isDeleting={isDeleting}
        icon={<TrashIcon />}
      >
        Delete Enrollment
      </DeleteEnrollmentMenuItem>
    </MenuList>
  </Menu>
);

export const EditWorkshopEnrollmentChildren = () => {
  const toast = useToast();
  const history = useHistory();
  const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);
  const routeParams = useRouteParams<RouterPathParams.AdminDash['Enrollment']>();
  const { id: enrollmentId } = routeParams;
  const getAdminEnrollmentQuery = useAdminGetEnrollmentQuery(
    { id: enrollmentId },
    {
      enabled: !deletionInProgress,

      // A query not found is a simple 404, so we don't need to retry.
      // Can simply just render not found state.
      retry: false,
    }
  );

  const rawAdminUpdateEnrollmentMutation = useAdminEnrollmentUpdateMutation({ id: enrollmentId });
  const adminUpdateEnrollmentMutation = useMutationWithTimer(rawAdminUpdateEnrollmentMutation, {
    duration: 500,
  });
  const rawAdminDeleteEnrollmentMutation = useAdminEnrollmentDeleteMutation({ id: enrollmentId });
  const adminDeleteEnrollmentMutation = useMutationWithTimer(rawAdminDeleteEnrollmentMutation, {
    duration: 500,
  });

  const handleSubmit = async (formValues: EnrollmentFormikValues) => {
    const dropInObj = formValues.dropInIds.length ? {} : null;
    if (dropInObj) {
      formValues.dropInIds.forEach((dropInId: string) => (dropInObj[dropInId] = true));
    }
    try {
      const params = {
        ...formValues,
        dropInIds: dropInObj,
      };
      delete params.fullSeries;
      await adminUpdateEnrollmentMutation.mutateAsyncWithTimer(params);
      toast({
        title: 'Saved!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Something went wrong!',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setDeletionInProgress(true);
      await adminDeleteEnrollmentMutation.mutateAsync();
      history.push('/admin-dash/enrollments/new');
      toast({
        title: 'Enrollment deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setDeletionInProgress(false);
      toast({ title: 'Something went wrong!', status: 'error' });
    }
  };

  if (getAdminEnrollmentQuery.isLoading) {
    return <Text>Loading</Text>;
  }

  if (getAdminEnrollmentQuery.isError && _.isEmpty(getAdminEnrollmentQuery.data)) {
    // TODO: Need a CTA here.
    return <Text>Enrollment not found.</Text>;
  }

  const { dropInIds = {}, memberId, workshopId } = getAdminEnrollmentQuery.data;
  const enrollmentProps = {
    memberId,
    workshopId,
    dropInIds: dropInIds ? Object.keys(dropInIds) : [],
  };
  return (
    <>
      <Flex width="100%" justifyContent="flex-end">
        <EnrollmentActionsMenu
          handleConfirmDelete={handleConfirmDelete}
          isDeleting={adminDeleteEnrollmentMutation.isLoadingWithTimer}
        />
      </Flex>
      <WorkshopEnrollmentForm
        submitBtnLabel="Update"
        onSubmit={handleSubmit}
        disableSelectMember
        disableSelectWorkshop
        {...enrollmentProps}
      />
    </>
  );
};

export const EditWorkshopEnrollment = () => (
  <Container maxW="container.lg">
    <Heading mb={8}>Update Enrollment:</Heading>
    <EditWorkshopEnrollmentChildren />
  </Container>
);
