import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import backgroundImageSrc from '../../../images/illustration-05.jpg';
import { boundChakra } from '../../../lib/chakra-ui';

export const TryLayout = boundChakra.Flex('SplitIllustrationLayout', {
  width: '100%',
  height: '100%',
  position: 'relative',
  flexDir: 'row',
  display: { base: 'block', foo: 'flex' },
});

const spaceBetween = 9;
const heightAtDesktop = 660;

export const TryLayoutMainContent = ({
  children,
  height = heightAtDesktop,
}: {
  children: React.ReactNode;
  height?: number;
}) => (
  <Flex
    bg="evergreen.500"
    pt={{ foo: 120 }}
    pb={{ foo: 400 }}
    width={{ base: '100%', foo: `calc(50% + ${spaceBetween / 2}em)` }}
    height={{
      base: 'auto',
      foo: '100%',
    }}
    top={0}
    left={0}
    justifyContent={{
      base: 'center',
      foo: 'flex-end',
    }}
  >
    <Flex width="100%" maxWidth="35em" height="100%" flexDirection="column" justifyContent="center">
      <Flex
        p={{ base: 8, foo: 12 }}
        bg={{ foo: 'whiteAlpha.400' }}
        height={{ foo: height }}
        borderTopLeftRadius={{ foo: 'md' }}
        borderBottomLeftRadius={{ foo: 'md' }}
        // For positioning navigation breadcrumb
        position="relative"
      >
        {children}
      </Flex>
    </Flex>
  </Flex>
);

export const TryLayoutSuccessParent = ({ children }: { children: React.ReactNode }) => (
  <Box
    top={0}
    right={0}
    width={{
      base: '100%',

      // TODO: Where does this number come from?
      foo: 416 + 560,
    }}
    height={{
      base: '100%',
      foo: heightAtDesktop,
    }}
    minHeight={{
      base: '100%',
      foo: heightAtDesktop,
    }}
    borderRadius="base"
    position={{
      base: 'fixed',
      foo: 'absolute',
    }}
  >
    {children}
  </Box>
);

export const TryLayoutSecondaryContent = ({
  children,
  height = heightAtDesktop,
}: {
  children: React.ReactNode;
  height?: number;
}) => (
  <Flex
    pt={{ foo: 120 }}
    pb={{ foo: 400 }}
    backgroundImage={{
      base: undefined,
      foo: backgroundImageSrc,
    }}
    backgroundSize="cover"
    backgroundPosition="top left"
    width={{ base: '100%', foo: `calc(50% - ${spaceBetween / 2}em)` }}
    height={{
      base: 'auto',
      foo: '100%',
    }}
    position={{
      base: 'static',
    }}
    top={0}
    left={{
      foo: `calc(50% + ${spaceBetween / 2}em)`,
    }}
    justifyContent={{
      base: 'center',
      foo: 'flex-start',
    }}
  >
    <Box
      bg="whiteAlpha.600"
      position="absolute"
      top={0}
      right={0}
      height="100%"
      width="100%"
      zIndex="-1"
    />
    <Flex width="100%" maxWidth="26em" height="100%" flexDir="column" justifyContent="center">
      <Flex
        bg="white"
        position="relative" // To anchor success state
        p={{ base: 8, foo: 12 }}
        height={{ foo: height }}
        justifyContent="center"
        flexDir="column"
        borderTopRightRadius={{ foo: 'md' }}
        borderBottomRightRadius={{ foo: 'md' }}
      >
        {children}
      </Flex>
    </Flex>
  </Flex>
);
