import path from 'path';
import { v1ApiPath } from '../../../lib/api';

const onDemandVideoCheckpointsIndexApiPath = () => v1ApiPath('on_demand_video_checkpoints');

const onDemandVideoCheckpointsSearchApiPath = () =>
  path.resolve(onDemandVideoCheckpointsIndexApiPath(), 'search');

export const onDemandVideoCheckpointsApiPaths = {
  index: onDemandVideoCheckpointsIndexApiPath,
  search: onDemandVideoCheckpointsSearchApiPath,
};
