import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ActivityIcon, CoffeeIcon, HeartIcon } from '../../../../components/Icons';

export function TryPushupStaticCopyList() {
  return (
    <VStack spacing={4} alignItems="start" mb={8}>
      <Flex alignItems="center">
        <Icon as={HeartIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>Led by a therapist, in live time</Text>
      </Flex>
      <Flex alignItems="center">
        <Icon as={ActivityIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>Actionable takeways in just 15 mins</Text>
      </Flex>
      <Flex alignItems="center">
        <Icon as={CoffeeIcon} boxSize={6} mr={4} color="whiteAlpha.700" />
        <Text fontSize={{ base: 'md', md: 'xl' }}>Less time than your morning coffee</Text>
      </Flex>
    </VStack>
  );
}
