import _ from 'lodash';
import {
  THERAPY_MATCHES_LIST_FAILURE,
  THERAPY_MATCHES_LIST_REQUEST,
  THERAPY_MATCHES_LIST_SUCCESS,
  THERAPY_MATCH_EVENTS_LIST_FAILURE,
  THERAPY_MATCH_EVENTS_LIST_REQUEST,
  THERAPY_MATCH_EVENTS_LIST_SUCCESS,
  THERAPY_MATCH_SHOW_SUCCESS,
  THERAPY_MATCH_TRANSITION_SUCCESS,
  THERAPY_MATCH_UPDATE_STATUS_SUCCESS,
} from '../../actions/admin/therapyMatches';

const DEFAULT_STATE = {
  gettingTherapyMatches: null,
  therapyMatches: {},
  includedClients: {},
  gettingTherapyMatchEvents: null,
  includedEvents: {},
  includedPerformedBy: {},
  therapyMatchPages: 0,
};

export function therapyMatches(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case THERAPY_MATCHES_LIST_REQUEST: {
      return { ...state, gettingTherapyMatches: true, therapyMatches: {}, includedClients: {} };
    }
    case THERAPY_MATCHES_LIST_SUCCESS: {
      const {
        response: { data, included, meta },
      } = action;
      const includedClients = _.keyBy(
        included.filter((d) => d.type === 'client'),
        'id'
      );
      return {
        ...state,
        therapyMatches: _.keyBy(data, 'id'),
        includedClients,
        gettingTherapyMatches: false,
        therapyMatchPages: meta.total_pages,
      };
    }
    case THERAPY_MATCH_UPDATE_STATUS_SUCCESS:
    case THERAPY_MATCH_SHOW_SUCCESS: {
      const {
        response: { data, included },
      } = action;
      const newIncludedClients = _.keyBy(
        included.filter((d) => d.type === 'client'),
        'id'
      );
      const { therapyMatches: therapyMatchesState, includedClients } = state;
      therapyMatchesState[data.id] = data;
      return {
        ...state,
        therapyMatches: therapyMatchesState,
        includedClients: { ...includedClients, ...newIncludedClients },
      };
    }
    case THERAPY_MATCHES_LIST_FAILURE: {
      return { ...state, gettingTherapyMatches: false };
    }
    case THERAPY_MATCH_EVENTS_LIST_REQUEST: {
      return { ...state, gettingTherapyMatchEvents: true };
    }
    case THERAPY_MATCH_EVENTS_LIST_SUCCESS: {
      const {
        payload,
        response: { data, included },
      } = action;
      const { includedEvents, includedPerformedBy } = state;
      includedEvents[payload.therapyMatchId] = data;
      includedPerformedBy[payload.therapyMatchId] = _.keyBy(
        included.filter((d) => d.type === 'performed_by'),
        'id'
      );
      return {
        ...state,
        includedEvents,
        includedPerformedBy,
        gettingTherapyMatchEvents: false,
      };
    }
    case THERAPY_MATCH_TRANSITION_SUCCESS: {
      const {
        payload,
        response: { data, included },
      } = action;
      const { therapyMatches: therapyMatchesState, includedEvents, includedPerformedBy } = state;
      const newTherapyMatches = included.filter((d) => d.type === 'therapy_match');
      newTherapyMatches.forEach((match) => (therapyMatchesState[payload.therapyMatchId] = match));
      const newPerformedBy = included.filter((d) => d.type === 'performed_by');
      newPerformedBy.forEach(
        (performedBy) => (includedPerformedBy[payload.therapyMatchId][performedBy.id] = performedBy)
      );
      includedEvents[payload.therapyMatchId] = includedEvents[payload.therapyMatchId] || [];
      includedEvents[payload.therapyMatchId].push(data);
      return {
        ...state,
        therapyMatches: therapyMatchesState,
        includedEvents,
        includedPerformedBy,
        gettingTherapyMatchEvents: false,
      };
    }
    case THERAPY_MATCH_EVENTS_LIST_FAILURE: {
      return { ...state, gettingTherapyMatchEvents: false };
    }
    default:
      return state;
  }
}
