import { TAGS_FAILURE, TAGS_REQUEST, TAGS_SUCCESS } from '../actions/tags';

const DEFAULT_STATE = {
  tags: [],
  gettingTags: false,
};

export function tags(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case TAGS_REQUEST: {
      return { ...state, tags: [], gettingTags: true };
    }
    case TAGS_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, tags: data, gettingTags: false };
    }
    case TAGS_FAILURE: {
      return { ...state, tags: [], gettingTags: false };
    }
    default:
      return state;
  }
}
