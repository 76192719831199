import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { PickPartial } from '@coa/types';
import React from 'react';

export type VideoPlayerControlButtonProps = PickPartial<IconButtonProps, 'aria-label'> & {
  label: string;
};

// TODO: Make buttons appear larger on mobile devices
// TODO: Also just add a big play / pause button mobile devices
// @see https://linear.app/coa/issue/COA-975/improve-video-player-controls-on-mobile-devices

export const VideoPlayerControlButton = ({ label, ...rest }: VideoPlayerControlButtonProps) => (
  <Tooltip label={label} placement="top">
    <IconButton
      aria-label={label}
      variant="link"
      color="white"
      {...rest}
      sx={{
        transition: '0.2s transform ease',
        _hover: { transform: 'scale(1.2)' },
      }}
    />
  </Tooltip>
);
