import path from 'path';
import { generatePath } from 'react-router';
import { OnDemandVideoCheckpoint } from '../resources/onDemandVideoCheckpoints';
import { OnDemandVideo } from '../resources/onDemandVideos';
import { createGetRouterUrl } from './utils';

const onDemandApexRouterPath = path.resolve('on-demand');
const onDemandVideoRouterPath = path.resolve(onDemandApexRouterPath, 'video', ':videoId');
const onDemandVideoCheckpointRouterPath = path.resolve(
  onDemandVideoRouterPath,
  'checkpoint',
  ':checkpointId'
);
const onDemandVideoSubscribeRouterPath = path.resolve(onDemandVideoRouterPath, 'subscribe');
const onDemandVideoCheckpointSubscribeRouterPath = path.resolve(
  onDemandVideoCheckpointRouterPath,
  'subscribe'
);
const onDemandVideosSearchPath = path.resolve(onDemandApexRouterPath, 'search?q=:searchQuery');

export const onDemandRouterPaths = {
  apex: onDemandApexRouterPath,
  video: onDemandVideoRouterPath,
  videoCheckpoint: onDemandVideoCheckpointRouterPath,
  videoSubscribe: onDemandVideoSubscribeRouterPath,
  videoCheckpointSubscribe: onDemandVideoCheckpointSubscribeRouterPath,
};

type OnDemandVideoRouterPathParams = { videoId: OnDemandVideo['id'] };
type OnDemandVideoCheckpointRouterPathParams = OnDemandVideoRouterPathParams & {
  checkpointId?: OnDemandVideoCheckpoint['id'];
};

export type OnDemandRouterPathParams = {
  Video: OnDemandVideoRouterPathParams;
  VideoCheckpoint: OnDemandVideoCheckpointRouterPathParams;
};

export const getOnDemandRouterUrl = {
  apex: createGetRouterUrl(onDemandApexRouterPath),
  video: createGetRouterUrl<OnDemandVideoRouterPathParams>(onDemandVideoRouterPath),
  videoCheckpoint: createGetRouterUrl<OnDemandVideoCheckpointRouterPathParams>(
    onDemandVideoCheckpointRouterPath
  ),
  videoSubscribe: createGetRouterUrl<OnDemandVideoRouterPathParams>(
    onDemandVideoSubscribeRouterPath
  ),
  videoCheckpointSubscribe: createGetRouterUrl<OnDemandVideoCheckpointRouterPathParams>(
    onDemandVideoCheckpointSubscribeRouterPath
  ),
  search: createGetRouterUrl(onDemandVideosSearchPath),
};

const createGetUniversalOnDemandVideoRouterUrl = <
  RouterPathParams extends OnDemandVideoCheckpointRouterPathParams
>({
  videoRouterPath,
  videoCheckpointRouterPath,
}: {
  videoRouterPath: string;
  videoCheckpointRouterPath: string;
}) => (routeParams: RouterPathParams = {} as RouterPathParams) =>
  generatePath(routeParams.checkpointId ? videoCheckpointRouterPath : videoRouterPath, routeParams);

export const getUniversalOnDemandRouterUrl = {
  video: createGetUniversalOnDemandVideoRouterUrl({
    videoRouterPath: onDemandVideoRouterPath,
    videoCheckpointRouterPath: onDemandVideoCheckpointRouterPath,
  }),
  videoSubscribe: createGetUniversalOnDemandVideoRouterUrl({
    videoRouterPath: onDemandVideoSubscribeRouterPath,
    videoCheckpointRouterPath: onDemandVideoCheckpointSubscribeRouterPath,
  }),
};
