import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbProps } from '@chakra-ui/react';
import { RouterLink } from '@coa/react-utils';
import React from 'react';

export const OnDemandBreadcrumb = (props: BreadcrumbProps) => (
  <Breadcrumb alignItems="start" {...props}>
    <BreadcrumbItem>
      <BreadcrumbLink as={RouterLink} to="/on-demand">
        On-Demand Home
      </BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>
);
