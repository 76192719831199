import { AnyObject } from '@coa/types';
import { generatePath } from 'react-router';

/*
 * Helper that creates an association between a routerPath and its corresponding
 * RouteParams type and hands off to react-router to generate the actual URL.
 *
 * For example, given...
 *
 *    const classesRouterPath = path.resolve('classes', ':workshopId');
 *    const ClassesRouteParams = { workshopId: string };
 *
 * we can generate...
 *
 *    const getClassesRouterUrl = createGetRouterUrl<ClassesRouteParams>(classesRouterPath);
 *
 * and then use like so...
 *
 *    getClassesRouterUrl({ workshopId: '1' })                //  "/classes/1"
 *    getClassesRouterUrl({ incorrectPropertyName: 'foo' })   //  TypeError :)
 */
export const createGetRouterUrl = <RouterPathParams extends AnyObject>(routerPath: string) => (
  routeParams: RouterPathParams = {} as RouterPathParams
) => generatePath(routerPath, routeParams);
