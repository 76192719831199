import _ from 'lodash';
import {
  DISCOUNTS_DESTROY_SUCCESS,
  DISCOUNTS_LIST_FAILURE,
  DISCOUNTS_LIST_REQUEST,
  DISCOUNTS_LIST_SUCCESS,
} from '../../actions/admin/discounts';

const DEFAULT_STATE = {
  gettingDiscounts: null,
  discounts: {},
};

export function discountCodes(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case DISCOUNTS_LIST_REQUEST: {
      return { ...state, discounts: {}, gettingDiscounts: true };
    }
    case DISCOUNTS_LIST_SUCCESS: {
      const {
        response: { data },
      } = action;
      return { ...state, discounts: _.keyBy(data, 'id'), gettingDiscounts: false };
    }
    case DISCOUNTS_LIST_FAILURE: {
      return { ...state, gettingDiscounts: false };
    }
    case DISCOUNTS_DESTROY_SUCCESS: {
      const { discounts } = state;
      delete discounts[action.response.data.id];
      return { ...state, discounts };
    }
    default:
      return state;
  }
}
