import {
  Box,
  Divider,
  Link,
  LinkProps,
  Spinner,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import {
  getHasSubscriptionFromQueryResponse,
  useGetSubscriptionsQuery,
} from '@coa/api/controllers/v1/subscriptions';
import { useGetTeamMembershipsQuery } from '@coa/api/controllers/v1/teamMemberships';
import React from 'react';
import { usePostBillingPortalSessionMutation } from '../../../resources/billingPortalSessions/apis/hooks';

function LinkWithSpinner({ children, isLoading, ...rest }: LinkProps & { isLoading?: boolean }) {
  return (
    <Link {...rest}>
      {children}
      {isLoading ? (
        <Spinner size="sm" color="green.300" ml={2} position="relative" top="2px" />
      ) : null}
    </Link>
  );
}

function ManageSubscriptionLink(props: LinkProps) {
  const [isRedirecting, setIsRedirecting] = useBoolean();
  const postBillingPortalSessionMutation = usePostBillingPortalSessionMutation();
  const toast = useToast();

  async function handleClick() {
    setIsRedirecting.on();
    try {
      const mutationResult = await postBillingPortalSessionMutation.mutateAsync();
      const {
        data: {
          attributes: { url },
        },
      } = mutationResult;
      // Send user to session URL.
      window.location.href = url;
      // Note that we don't unset the isRedirecting flag because we
      // want the spinner to continue while the browser is redirecting.
    } catch (err) {
      setIsRedirecting.off();
      toast({
        title: 'Something went wrong.',
        description: 'We were unable to take you to your Billing Portal. Please try again.',
        duration: 2000,
        isClosable: true,
        status: 'error',
      });
    }
  }

  const { children, ...rest } = props;
  return (
    <LinkWithSpinner
      {...rest}
      isLoading={isRedirecting}
      onClick={async (e) => {
        if (props.onClick) props.onClick(e);
        await handleClick();
      }}
    >
      {children}
    </LinkWithSpinner>
  );
}

export function AccountInfoSubscription(): JSX.Element {
  const getSubscriptionsQuery = useGetSubscriptionsQuery();
  const getTeamMembershipsQuery = useGetTeamMembershipsQuery();
  const { data: teamMemberships = [] } = getTeamMembershipsQuery;

  if (getSubscriptionsQuery.isLoading || getTeamMembershipsQuery.isLoading) return null;
  const hasSubscription = getHasSubscriptionFromQueryResponse(getSubscriptionsQuery.data);
  const teamWithActiveMembership = teamMemberships.find(
    ({ team: { accessStatus, accessKind } }) =>
      accessStatus === 'active' && accessKind !== 'a_la_carte'
  );
  //  Part of the TeamMembership AccessKind permissions role out:
  //  Q: Why return null here is teamWithActiveMembership?
  // -  We assume that if a team has any other accessKind than a_la_carte (currently only: membership && membership_with_classes),
  //    then they have a membership.
  // -  If a user is on any team that has an active membership (denoted by not having a 'a_la_carte' accessKind)
  //    then they do not need to manage their own subscription. It is managed at the team level, by the team admin
  if (!hasSubscription || teamWithActiveMembership) return null;

  return (
    <Box p={4} m={3} width="fill-available" borderWidth="1px" borderRadius="4px">
      <Text textStyle="earmark">Your Subscription</Text>
      <Divider my={2} />
      <ManageSubscriptionLink>Manage Subscription</ManageSubscriptionLink>
    </Box>
  );
}
