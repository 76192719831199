import { Box, Button, Container, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ArrowLeftIcon } from '../../../components/Icons';
import {
  formatWorkshopOccurrenceTimeLabel,
  GetWorkshop,
  TryWorkshopType,
} from '../../../resources/workshops';
import { MarketingSiteNavigationBreadcrumb } from '../../shared/fakeModal/MarketingSiteNavigationBreadcrumb';
import { TryPushupStaticCopyList } from '../pushups/components/TryPushupStaticContent';
import { TryQAndAStaticCopyList } from '../q-and-a/components/TryQAndAStaticContent';
import { TryLogoContainer } from './TryWorkshopLogoContainer';
import {
  TryWorkshopAvatar,
  TryWorkshopAvatarLabel,
  TryWorkshopAvatarParent,
  TryWorkshopPickerText,
  TryWorkshopPickerTitle,
} from './TryWorkshopPicker';

export function TryWorkshopInstanceInner({
  workshop,
  type,
}: {
  workshop: GetWorkshop.Response;
  type: TryWorkshopType;
}) {
  const { therapist, workshopOccurrences } = workshop;
  const occurence = workshopOccurrences[0];
  const renderTryWorkshopStaticCopyList = () => {
    switch (type) {
      case 'pushups':
        return <TryPushupStaticCopyList />;
      case 'q_and_a':
        return <TryQAndAStaticCopyList />;
      default:
        return null;
    }
  };
  return (
    <VStack spacing={4} alignItems="start" justifyContent="space-between" height="90%">
      <Container maxW="container.lg" px={{ base: 0, md: 0, xl: 0 }} py={{ base: 1, md: 2 }}>
        <Heading fontSize={{ base: '2xl', md: '3xl' }}>{workshop.title}</Heading>
        <Text my={{ base: 1, md: 2 }} fontSize={{ base: 'lg', md: 'xl' }}>
          {workshop.shortBlurb}
        </Text>
        <Box
          w="100%"
          backgroundColor="evergreen.300"
          color="white"
          borderRadius="base"
          my={{ base: 1, md: 2 }}
          p={{ base: 1, md: 4 }}
        >
          <Flex alignItems="start" paddingBottom="7px">
            <TryWorkshopPickerTitle active={false} width="50%" color="white">
              {formatWorkshopOccurrenceTimeLabel(occurence)}
            </TryWorkshopPickerTitle>
            <TryWorkshopAvatarParent width="50%" justifyContent="flex-end">
              <TryWorkshopAvatar name={therapist.name} src={therapist.imageUrl} color="white" />
              <TryWorkshopAvatarLabel color="white" active={false}>
                {therapist.name}
              </TryWorkshopAvatarLabel>
            </TryWorkshopAvatarParent>
          </Flex>
          <TryWorkshopPickerText color="white">{workshop.description}</TryWorkshopPickerText>
        </Box>
      </Container>
      {renderTryWorkshopStaticCopyList()}
    </VStack>
  );
}

function TryWorkshopInstanceInnerError() {
  return (
    <VStack spacing={4} alignItems="start" height="50%" justifyContent="center">
      <Heading fontSize={{ base: '2xl', md: '3xl' }}>Q&A: Not Found!</Heading>
      <Flex width="100%" justifyContent="center" direction="column" alignItems="start" mt="48px">
        <Text fontSize={{ base: 'lg', md: 'xl' }} mb="16px">
          {' '}
          We couldn't find the Q&A class you were looking for!
        </Text>
        <Button
          href="/try/q-and-a"
          as="a"
          leftIcon={<ArrowLeftIcon />}
          variant="primary"
          width={{ base: '80%', md: '80%' }}
          // data-cy={`${type}-empty-state-cta`}
        >
          Browse Q&As
        </Button>
      </Flex>
    </VStack>
  );
}

export function TryWorkshopInstanceStaticContent({
  // simplify into single prop
  workshop,
  workshopIsValid,
  type,
}: {
  workshop: GetWorkshop.Response;
  workshopIsValid: boolean;
  type: TryWorkshopType;
}) {
  return (
    <Box color="white" width="100%">
      <MarketingSiteNavigationBreadcrumb
        position={{ foo: 'absolute' }}
        bottom="100%"
        left={0}
        pb={{ base: 4, foo: 2 }}
        color="white"
      />
      <VStack spacing={4} alignItems="start" mb={8} height="100%">
        <TryLogoContainer
          display={{ base: 'block', foo: 'inline-block' }}
          fill="white"
          fillOpacity={0.6}
          // Offset to account for SVG bounding box
          ml="-9px"
        />
        {workshopIsValid ? (
          <TryWorkshopInstanceInner workshop={workshop} type={type} />
        ) : (
          <TryWorkshopInstanceInnerError />
        )}
      </VStack>
    </Box>
  );
}
