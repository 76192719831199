import { AdminSerializedPoll } from '../../../serializers/admin/AdminSerializedPoll';
import {
  AdminCmsIndexFilterParams,
  generateUseCreateMutation,
  generateUseIndexQuery,
  generateUseShowQuery,
  generateUseUpdateMutation,
} from '../cms';

const pollsCmsParams = {
  basePath: '/admin/polls',
  auth: true,
};

export const useAdminIndexPollsQuery = generateUseIndexQuery<
  AdminSerializedPoll,
  AdminCmsIndexFilterParams<AdminSerializedPoll>
>(pollsCmsParams);
export const useAdminShowPollQuery = generateUseShowQuery<AdminSerializedPoll>(pollsCmsParams);
export const useAdminCreatePollMutation = generateUseCreateMutation<AdminSerializedPoll>(
  pollsCmsParams
);
export const useAdminUpdatePollMutation = generateUseUpdateMutation<AdminSerializedPoll>(
  pollsCmsParams
);
