import { UnpackArray } from '@coa/types';
import React, { useState } from 'react';
import { AdminGetMembersSearch, Member, useAdminGetMembersSearch } from '../../resources/member';
import { ChakraReactSelect } from '../ChakraReactSelect';
import { AdminMemberDetailsCard } from './AdminMemberDetailsCard';

export function MemberSearchField({
  onSelect,
  value,
  isDisabled = false,
  isResettable = false,
}: {
  onSelect: (member: Member) => void;
  value: Member;
  isDisabled?: boolean;
  isResettable?: boolean;
}) {
  // state for search value in select field
  const [searchQuery, setSearchQuery] = useState('');
  const adminGetMembersSearchQuery = useAdminGetMembersSearch(
    { query: searchQuery },
    { enabled: searchQuery.length > 0, keepPreviousData: searchQuery.length > 0 }
  );
  const memberSearchResults = adminGetMembersSearchQuery.data || [];

  const handleInputChange = (inputValue: string) => {
    setSearchQuery(inputValue);
  };
  // handler when selecting option
  const handleChange = ({ id: memberId }: UnpackArray<AdminGetMembersSearch.Response>) => {
    const selectedMember = memberSearchResults.find(
      ({ id: memberEntryId }) => memberId === memberEntryId
    );
    onSelect(selectedMember);
    setSearchQuery('');
  };

  const resetMemberSearch = () => {
    onSelect(null);
    setSearchQuery('');
  };

  return (
    <>
      <ChakraReactSelect
        isDisabled={isDisabled}
        variant="coa-main"
        options={memberSearchResults}
        onInputChange={handleInputChange}
        onChange={handleChange}
        blurInputOnSelect
        placeholder="Search for a member"
        noOptionsMessage={() => (searchQuery ? 'No member found' : 'Search members by email')}
        value={value}
        inputValue={searchQuery}
        isLoading={adminGetMembersSearchQuery.isLoading}
        getOptionLabel={({ email }) => email}
      />
      {value ? (
        <AdminMemberDetailsCard
          member={value}
          resetSearch={resetMemberSearch}
          isResettable={isResettable}
          mt={8}
        />
      ) : null}
    </>
  );
}
