import { Flex, FlexProps, ThemingProps } from '@chakra-ui/react';
import React from 'react';
import { usePseudoStyleConfig } from '../../lib/chakra-ui';

export type IconBadgeProps = Pick<ThemingProps, 'colorScheme' | 'size' | 'variant'> & {
  icon: React.ReactNode;
  disabled?: boolean;
} & FlexProps;

export const IconBadge = (props: IconBadgeProps) => {
  const { disabled, icon, ...rest } = props;
  const { _disabled: disabledStyles, ...baseStyles } = usePseudoStyleConfig('IconBadge', rest);
  const styles = {
    ...baseStyles,
    ...(disabled ? disabledStyles : {}),
  };
  const { colorScheme, ...propsToPass } = rest;
  return (
    <Flex sx={styles} {...propsToPass}>
      {icon}
    </Flex>
  );
};
